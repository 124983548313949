import api from "../axios/axios-config";

export const getKolOptions = async () => {
  try {
    let response = await api.get("kol/listOfKOL");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addKolOption = async (name, description) => {
  try {
    let response = await api.post("kol/createKOL", {
      name,
      description,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateKolOption = async (dataId, name, description) => {
  try {
    let response = await api.post("kol/updateKOL", {
      dataId,
      name,
      description,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteKolOption = async (dataId) => {
  try {
    let response = await api.post("kol/updateKOL", {
      dataId,
      isDeleted: true,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
