import { useState } from "react";

import "./marketing-style.scss";

import CostOfSellingReport from "./CostOfSellingReport";
import CustomerCostReport from "./CustomerCostReport";
import MediaResponseReport from "./MediaResponseReport";

export const filterTypes = [
  {
    label: "Date Range",
    value: "R",
  },
  {
    label: "By Month",
    value: "M",
  },
  {
    label: "By Quarter",
    value: "Q",
  },
];

export const costFilterTypes = [
  {
    label: "KOL Sponsorship",
    value: "kolSponsorship",
  },
  {
    label: "sponsorship",
    value: "sponsorship",
  },
  {
    label: "KOL",
    value: "kol",
  },
];

const MarketingReportsPage = () => {
  const [selectedTab, setSelectedTab] = useState("costOfSelling");

  return (
    <div className="marketing-reports-page min-vh-100">
      <div className="px-4 py-2">
        <div className="mainHeading">
          <h1>Dashboard / Marketing</h1>
        </div>
      </div>
      <div className="page-header col-9 d-flex px-4 w-100">
        <div className="sections-tabs">
          <div
            className={`tab-item ${
              selectedTab === "costOfSelling" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("costOfSelling")}
          >
            Cost of Selling
          </div>
          <div
            className={`tab-item ${
              selectedTab === "customerCost" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("customerCost")}
          >
            Customer Acquisition Cost
          </div>
          <div
            className={`tab-item ${
              selectedTab === "mediaResponse" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("mediaResponse")}
          >
            Media Response
          </div>
        </div>
      </div>
      <div className="content-block">
        {selectedTab === "costOfSelling" ? (
          <CostOfSellingReport />
        ) : selectedTab === "customerCost" ? (
          <CustomerCostReport />
        )  : selectedTab === "mediaResponse" ? (
          <MediaResponseReport />
        ) : null}
      </div>
    </div>
  );
};

export default MarketingReportsPage;
