import { useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { setData, setForm } from "../../../redux/slices/adminSlices";

export default function AddReminderTemplate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { externalDrugForm } = useSelector((state) => state.adminData);

  useEffect(() => {
    authorizedRequests({
      url: "externalDrugTemplate/listOfExternals",
      method: "get",
    }).then((res) => {
      console.log("Res Data", res);
      if (res?.result?.data && res?.result?.data?.length > 0) {
        dispatch(
          setData({
            parent: "externalDrugForm",
            value: res?.result?.data[0],
          })
        );
      }
    });
  }, []);

  const onFormChange = (e) => {
    const { value, name } = e.target;
    dispatch(setForm({ parent: "externalDrugForm", child: name, value }));
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!externalDrugForm._id) {
      const { result, error } = await authorizedRequests({
        url: "externalDrugTemplate/createExternal",
        method: "post",
        data: {
          template: externalDrugForm.template,
          size: externalDrugForm.size,
        },
      });

      if (error) {
        toastify(error.message ?? "Unknown error occured", "error");
        return;
      }
      toastify("Added succesfully", "success");
    } else {
      const { result, error } = await authorizedRequests({
        url: "externalDrugTemplate/updateExternal",
        method: "post",
        data: {
          template: externalDrugForm.template,
          size: externalDrugForm.size,
          dataId: externalDrugForm._id,
        },
      });

      if (error) {
        toastify(error.message ?? "Unknown error occured", "error");
        return;
      }

      toastify("Added succesfully", "success");
    }
  };

  return (
    <>
      <div className="mainHeading px-4">
        <h1>External Drug Template</h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <Form.Group className="col-md-3">
                  <Form.Label className="black-label-text">Size</Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name={"size"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onFormChange(e);
                      }}
                      value={externalDrugForm.size}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text">Template</Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name={"template"}
                      as={"textarea"}
                      rows={3}
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => {
                        onFormChange(e);
                      }}
                      value={externalDrugForm.template}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <Button
              className="addItem mt-2"
              style={{ width: "250px" }}
              onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
