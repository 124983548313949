import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getProducts } from "../../../../apis/products";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../../helperFunctions/toastify";
import { calcTotalWithDisc } from "../../../../helperFunctions/utils";
import { SearchIcon } from "../../../../assets/images/index";
import DataTable from "react-data-table-component";
import { Switch } from "antd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { addPackage, updatePackage } from "../../../../apis/packages";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { getBranches } from "../../../../apis/branch";
import SelectComponent from "../../../SharedComponents/SelectComponent";
import { getTreatments } from "../../../../apis/treatment";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditPackages() {
  const { state } = useLocation();
  // console.log('state package', state.package);
  const navigate = useNavigate();

  const [allTreatments, setAllTreatments] = useState();
  const [products, setProducts] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [code, setCode] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchType, setSearchType] = useState("");
  const [discount, setDiscount] = useState("");
  const [qty, setQty] = useState(0);
  const [packagePrice, setPackagePrice] = useState("");
  const [packageQty, setPackageQty] = useState(0);
  const [codeShow, setCodeShow] = useState(false);
  const [nameShow, setNameShow] = useState(false);
  const [typeShow, setTypeShow] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredCode, setFilteredCode] = useState([]);
  const [filteredName, setFilteredName] = useState([]);
  const [filteredType, setFilteredType] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [isDiscountable, setIsDiscountable] = useState(false);
  const [isPromoable, setIsPromoable] = useState(false);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [showED, setShowED] = useState(false);
  const [showED2, setShowED2] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [location, setLocation] = useState();
  const [branch, setBranch] = useState();
  const [editingProduct, setEditingProduct] = useState("");
  const [itemType, setItemType] = useState();
  const [tempTreatmentId, setTempTreatmentId] = useState("");
  const [tempTreatmentCode, setTempTreatmentCode] = useState("");
  const [tempTreatmentName, setTempTreatmentName] = useState("");
  const [tempTreatmentPrice, setTempTreatmentPrice] = useState(0);
  const [tempTreatmentDisc, setTempTreatmentDisc] = useState(0);
  const [tempTreatmentDiscType, setTempTreatmentDiscType] = useState("Amount");
  const [addedTreatments, setAddedTreatments] = useState([]);
  const [treatmentBeingEdited, setTreatmentBeingEdited] = useState();
  const [tempTreatmentLimit, setTempTreatmentLimit] = useState(0);
  const [discountType, setDiscountType] = useState("Amount");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalCost, setTotalCost] = useState(0);

  const numberRegex = new RegExp(`^[0-9]*$`);
  const percentRegex = new RegExp(
    `(0*100{1,1}\\.?((?<=\\.)0*)??$)|(^0*\\d{0,2}\\.?((?<=\\.)\\d*)??)$`
  );

  const populateData = async () => {
    setCode(state?.package?.code);
    setName(state?.package?.name);
    setDescription(state?.package?.description);
    setEffectiveDate(state?.package?.effectiveDate);
    setExpiryDate(state?.package?.expiredDate);
    setStatus(state?.package?.isActive === true ? "Active" : "Not Active");
    setIsDiscountable(state?.package?.isDiscountable);
    setIsPromoable(state?.package?.isPromoable);
    setPackagePrice(state?.package?.packagePrice);
    setPackageQty(state?.package?.packageQty ? state?.package?.packageQty : 0);
    setProducts(state?.package?.products);
    setBranch(state?.package?.branch);
    setLocation(state?.package?.location);
    setTotalPrice(state?.package?.totalPrice);
    setTotalCost(state?.package?.totalCost);

    const response2 = await getBranches();
    if (response2?.status !== "Fail") {
      let _branch = [...response2];
      _branch.forEach(function (item, i) {
        if (item?._id === state?.package?.branch) {
          _branch.splice(i, 1);
          _branch.unshift(item);
        }
      });
      setAllBranches(_branch);

      let _data = response2.find((e) => e._id === state?.package?.branch);

      if (_data?.location) {
        setAllLocations(_data.location);
      }
    } else {
      toastify(response2?.message, "error");
    }
  };

  // populateData();

  const savePackage = async () => {
    if (!code) {
      missingPopup("Code");
    } else if (!name) {
      missingPopup("Name");
    } else if (status === "" || status === undefined) {
      missingPopup("Status");
    } else if (!branch) {
      missingPopup("Branch");
    } else if (!location) {
      missingPopup("Location");
    } else if (products.length === 0 && addedTreatments.length === 0) {
      toastify("No Items added in Package", "error");
    } else {
      // let _products = [...products].map((e) => {
      //   return {
      //     product: e.product?._id,
      //     qty: e.qty,
      //     discount: e.discount,
      //   };
      // });
      let myData = {
        name,
        code,
        description,
        effectiveDate,
        expiredDate: expiryDate,
        isActive: status === "Active" ? true : false,
        packageQty,
        packagePrice,
        totalPrice: totalPrice === 0 ? getTotalPrice() : totalPrice,
        totalCost: totalCost === 0 ? getTotalCost() : totalCost,
        products: products,
        isDiscountable,
        isPromoable,
        branch,
        location,
        isExtanded: false,
        treatments: addedTreatments,
      };
      if (!state?.package) {
        const response = await addPackage(myData);
        if (response?.status !== "Fail") {
          toastify("Package added successfully!", "success");
          navigate("/packages");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updatePackage(state?.package?._id, myData);
        if (response?.status !== "Fail") {
          toastify("Package updated successfully!", "success");
          navigate("/packages");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const saveTreatment = () => {
    if (!tempTreatmentCode) {
      missingPopup("Treatment Code");
    } else if (!tempTreatmentName) {
      missingPopup("Treatment Name");
    } else {
      const treatmentDetail = allTreatments?.find(
        (e) => e._id === tempTreatmentId
      );
      let tempArr = [...addedTreatments];
      if (treatmentBeingEdited !== 0 && !treatmentBeingEdited) {
        tempArr.push({
          treatment: treatmentDetail._id,
          discount: tempTreatmentDisc,
          discountType: tempTreatmentDiscType,
          code: treatmentDetail?.code,
          name: treatmentDetail?.name,
          price: tempTreatmentPrice,
          limit: tempTreatmentLimit,
          disc_price: calcTotalWithDisc(
            tempTreatmentPrice * tempTreatmentLimit,
            tempTreatmentDisc,
            tempTreatmentDiscType
          ),
        });
        setAddedTreatments(tempArr);
        setTempTreatmentLimit();
        setTempTreatmentCode(null);
        setTempTreatmentName(null);
        setTempTreatmentId("");
        setTempTreatmentPrice(0);
        setTempTreatmentDisc(0);
        setTempTreatmentDiscType("Amount");
        setTreatmentBeingEdited();
        setShowAdd(false);
        setItemType();
      } else {
        tempArr = tempArr.map((e, i) => {
          if (i == treatmentBeingEdited) {
            return {
              treatment: treatmentDetail._id,
              discount: tempTreatmentDisc,
              discountType: tempTreatmentDiscType,
              code: treatmentDetail?.code,
              name: treatmentDetail?.name,
              limit: tempTreatmentLimit,
              price: tempTreatmentPrice,
              disc_price: calcTotalWithDisc(
                tempTreatmentPrice * tempTreatmentLimit,
                tempTreatmentDisc,
                tempTreatmentDiscType
              ),
            };
          }
          return e;
        });
        setAddedTreatments(tempArr);
        setTempTreatmentCode(null);
        setTempTreatmentLimit();
        setTempTreatmentName(null);
        setTempTreatmentId("");
        setTempTreatmentPrice(0);
        setTempTreatmentDisc(0);
        setTempTreatmentDiscType("Amount");
        setTreatmentBeingEdited();
        setShowAdd(false);
        setItemType();
      }
    }
  };
  const saveProduct = () => {
    if (!searchCode) {
      missingPopup("Item");
    } else {
      let myData = [...products];
      console.log("DEBUG", selectedProduct);
      if (editingProduct !== "") {
        myData = [...myData].filter((_e, i) => i !== editingProduct);
      }

      let found = products.find((e) => e.product._id === selectedProduct._id);

      if (found) {
        let _data = products.filter(
          (e) => e.product._id !== selectedProduct._id
        );
        const totalQty = parseInt(qty);
        _data.push({
          qty: totalQty.toString(),
          discountType: discountType,
          discount,
          product: selectedProduct,
          code: selectedProduct.code,
          name: selectedProduct.name,
          price: selectedProduct.unitSellingPrice,
          cost: selectedProduct.averageUnitCost,
          totalCost: totalQty * selectedProduct.averageUnitCost,
          totalPrice: calcTotalWithDisc(
            totalQty * selectedProduct.unitSellingPrice,
            discount,
            discountType
          ),
        });
        setProducts(_data);
        console.log('products saved', _data);
      } else {
        myData.push({
          qty,
          discount,
          discountType,
          product: selectedProduct,
          code: selectedProduct.code,
          name: selectedProduct.name,
          price: selectedProduct.unitSellingPrice,
          cost: selectedProduct.averageUnitCost,
          totalCost: qty * selectedProduct.averageUnitCost,
          totalPrice: calcTotalWithDisc(
            qty * selectedProduct.unitSellingPrice,
            discount,
            discountType
          ),
        });
        setProducts(myData);
        console.log('my data', myData);
      }
      setSelectedProduct();
      setSearchCode("");
      setSearchName("");
      setSearchType("");
      setQty(0);
      setDiscountType("Amount");
      setDiscount("");
      setEditingProduct("");
      setItemType();
      setShowAdd(false);
    }
  };

  const extendPackage = async () => {
    if (!code) {
      missingPopup("Code");
    } else if (!name) {
      missingPopup("Name");
    } else if (status === "" || status === undefined) {
      missingPopup("Status");
    } else if (!branch) {
      missingPopup("Branch");
    } else if (!location) {
      missingPopup("Location");
    } else if (!packagePrice) {
      missingPopup("Package Price");
    } else if (products.length === 0) {
      toastify("No Items added in Package", "error");
    } else {
      let _products = [...products].map((e) => {
        return {
          product: e.product?._id,
          qty: e.qty,
          discount: e.discount,
        };
      });
      let myData = {
        name,
        code,
        description,
        effectiveDate,
        expiredDate: expiryDate,
        isActive: status === "Active" ? true : false,
        packagePrice,
        totalPrice: getTotalPrice(),
        totalCost: getTotalCost(),
        products: _products,
        isDiscountable,
        isPromoable,
        branch,
        location,
        isExtanded: true,
        treatments: addedTreatments?.map((e) => {
          return {
            discount: e.discount,
            treatment: e.code,
          };
        }),
      };
      const response = await updatePackage(state?.package?._id, myData);
      if (response?.status !== "Fail") {
        toastify("Package Extended successfully!", "success");
        navigate("/packages");
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const populateOptions = async () => {
    const response = await getProducts();
    if (response?.status !== "Fail") {
      // const productsWithUppercaseNames = response.map((product) => ({
      //   ...product,
      //   name: product.name.toUpperCase(), // Convert name to uppercase
      // }));
      setAllProducts(response);
    } else {
      toastify(response?.message, "error");
    }

  

    const response2 = await getBranches();
    if (response2?.status !== "Fail") {
      setAllBranches(response2);
    } else {
      toastify(response2?.message, "error");
    }
  };

  const editProduct = (prodToEdit, index) => {
    // let _products = [...products].filter((e) => {
    //   if (e.product._id !== prodToEdit.product._id) {
    //     return e;
    //   }
    // });
    // setProducts(_products);

    setShowAdd(true);
    setSelectedProduct(prodToEdit.product);
    setDiscount(prodToEdit.discount);
    setDiscountType(prodToEdit.discountType);
    setQty(prodToEdit.qty);
    setEditingProduct(index);
  };

  const columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      wrap: true,
      width: "160px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
      width: "170px",
    },
    // {
    // 	name: "Type",
    // 	selector: (row) => row.type,
    // 	wrap: true,
    // 	width: "170px",
    // },
    {
      name: "Quantity",
      selector: (row) => row.qty,
      wrap: true,
      width: "170px",
    },
    {
      name: "Discount",
      selector: (row) =>
        row.discountType === "Percentage"
          ? `${row.discount}%`
          : `$${row.discount ?? 0}`,
      wrap: true,
      width: "170px",
    },
    {
      name: "Unit Cost",
      selector: (row) => row.unitCost,
      wrap: true,
      width: "170px",
    },
    {
      name: "Unit Price",
      selector: (row) => row.unitPrice,
      wrap: true,
      width: "170px",
    },
    {
      name: "Total Cost",
      selector: (row) => row.totalCost,
      wrap: true,
      width: "170px",
    },
    {
      name: "Total Price",
      selector: (row) => row.totalPrice,
      wrap: true,
      width: "170px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "160px",
    },
  ];

  const data = products?.map((e, index) => {
    return {
      index: index,
      id: e._id,
      code: e?.code,
      name: e?.name,
      qty: e.qty,
      discount: e.discount,
      discountType: e.discountType,
      unitCost: e?.cost,
      unitPrice: e?.price,
      totalCost: e?.totalCost,
      totalPrice: e?.totalPrice,
      isActive:
        e.isActive === true ? (
          <div className="active-status">Active</div>
        ) : (
          <div className="inactive-status">In Active</div>
        ),
      action: (
        <div className="d-flex">
          <div
            className="mx-3  cursor-pointer icon-width"
            onClick={() => {
              setItemType("Product");
              editProduct(e, index);
            }}
          >
            <i className="fa fa-pen"></i>
          </div>
          <div
            className="mx-3  cursor-pointer icon-width"
            onClick={() => {
              setDeleting(e.product._id);
              setShow(true);
            }}
          >
            <i className="fa fa-trash"></i>
          </div>
        </div>
      ),
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  const getTotalCost = () => {
    let total = 0;
    products.forEach((e) => (total += e.product?.averageUnitCost * e.qty));
    
    if (!total)
      total = 0;

    return total?.toLocaleString();
  };

  const getTotalPrice = () => {
    let total = 0;
    products.forEach((e) => (total += e.totalPrice));
    addedTreatments.forEach((e) => (total += e.disc_price));
    return total;
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const removeProduct = () => {
    let _products = [...products].filter((e) => e.product._id !== deleting);
    setProducts(_products);
    setShow(false);
  };

  useEffect(() => {
    if (selectedProduct) {
      setSearchCode(selectedProduct?.code);
      setSearchName(selectedProduct?.name);
      setSearchType(selectedProduct?.type?.name);
    }
  }, [selectedProduct]);

  // useEffect(() => {
  //   setTotalSellingPrice(qty * unitSellingPrice);
  // }, [qty, unitSellingPrice]);

  // useEffect(() => {
  //   setTotalCost(qty * averageUnitCost);
  // }, [qty, averageUnitCost]);

  useEffect(() => {
    setFilteredCode(allProducts.filter((e) => e.code.includes(searchCode)));
  }, [searchCode]);

  useEffect(() => {
    setFilteredName(allProducts.filter((e) => e.name.includes(searchName)));
  }, [searchName]);

  useEffect(() => {
    setFilteredType(
      allProducts.filter((e) => e.type.name.includes(searchType))
    );
  }, [searchType]);

  useEffect(() => {
    if (branch) {
      let _locations = allBranches?.find((e) => e._id === branch);
      setAllLocations(_locations?.location);
    }
  }, [branch]);

  useEffect(() => {
    populateOptions();
    if (state?.package) populateData();
  }, []);
  useEffect(() => {
    (async () => {
      const response = await getTreatments();
      if (response?.status !== "Fail") {
        setAllTreatments(response);
        console.log("DEBUG", state.package?.treatments);
        if (state.package?.treatments?.length > 0) {
          // const treatmentData = state.package?.treatments?.map(
          // 	(e) => {
          // 		return {
          // 			code: e.treatment?.code,
          // 			name: e.treatment?.name,
          // 			price: e.treatment?.price,
          // 			discount: e.discount,
          // 			limit: e.limit,
          // 			disc_price:
          // 				e.treatment?.price -
          // 				e.treatment?.price * (e.discount / 100),
          // 		};
          // 	},
          // );
          setAddedTreatments(state.package?.treatments);
        }
      } else {
        toastify("Failed to get treatments", "error");
      }
    })();
  }, []);
  const handleClickAway1 = () => {
    setShowED(false);
  };

  const handleClickAway2 = () => {
    setShowED2(false);
  };
  const treatment_columns = [
    {
      name: "Code",
      selector: (row) => row.code,
      wrap: true,
      // width: "160px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
      // width: "170px",
    },
    {
      name: "Quantity",
      selector: (row) => row.limit?.toLocaleString(),
      wrap: true,
      // width: "170px",
    },
    {
      name: "Discount",
      selector: (row) =>
        row.discountType === "Percentage"
          ? `${row.discount}%`
          : `$${row.discount}`,
      wrap: true,
      width: "170px",
    },
    {
      name: "Price",
      selector: (row) => row.price?.toLocaleString(),
      wrap: true,
      // width: "170px",
    },
    {
      name: "Total Price",
      selector: (row) =>
        calcTotalWithDisc(row.disc_price, row.discount, row.discountType),
      wrap: true,
      width: "170px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "160px",
    },
  ];

  const treatment_data = addedTreatments?.map((o, index) => {
    const treatment = allTreatments?.find((e) => e._id === o.name);
    return {
      name: o?.name,
      code: o?.code,
      price: o.price,
      limit: o?.limit,
      discount: o.discount || 0,
      disc_price: o.disc_price || o.price,
      action: (
        <div className="d-flex">
          <div
            className="mx-3  cursor-pointer icon-width"
            onClick={() => {
              setItemType("Treatment");
              setShowAdd(true);
              setTempTreatmentCode(
                o?.treatment?._id ? o?.treatment?._id : o?.treatment
              );
              setTempTreatmentName(
                o?.treatment?._id ? o?.treatment?._id : o?.treatment
              );
              setTempTreatmentId(
                o?.treatment?._id ? o?.treatment?._id : o?.treatment
              );
              setTempTreatmentDisc(o.discount);
              setTempTreatmentDiscType(o.discountType);
              setTempTreatmentPrice(o?.price);
              setTreatmentBeingEdited(index);
              setTempTreatmentLimit(o?.limit);
              // editProduct(e, index);
            }}
          >
            <i className="fa fa-pen"></i>
          </div>
          <div
            className="mx-3  cursor-pointer icon-width"
            onClick={() => {
              const tempArr = addedTreatments.filter((o, i) => i !== index);
              setAddedTreatments([...tempArr]);
              // setDeleting(e.product._id);
              // setShow(true);
            }}
          >
            <i className="fa fa-trash"></i>
          </div>
        </div>
      ),
    };
  });

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state.package._id,
      type: "Package",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.package ? "Edit/Extend " : "New "}`}
            Package
          </h1>
          {state?.package && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex">
                <div className="col-5">
                  <Form.Group>
                    <Form.Label className="headings2">Code *</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setCode(e.target.value)}
                        value={code}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="headings2">Name *</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="headings2">Status *</Form.Label>
                    <SelectComponent
                      searchEnabled
                      nullDisabled
                      singleSelect
                      selectedValue={status}
                      onChange={(e) => setStatus(e.value)}
                      options={[
                        {
                          value: "Active",
                          label: "Active",
                        },
                        {
                          value: "Inactive",
                          label: "Inactive",
                        },
                      ]}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="headings2">Branch *</Form.Label>
                    <SelectComponent
                      searchEnabled
                      nullDisabled
                      singleSelect
                      selectedValue={branch}
                      onChange={(e) => setBranch(e.value)}
                      options={
                        allBranches?.length > 0
                          ? [
                              ...allBranches?.map((o) => {
                                return {
                                  value: o._id,
                                  label: o.name,
                                };
                              }),
                            ]
                          : []
                      }
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="headings2">Location *</Form.Label>
                    <SelectComponent
                      searchEnabled
                      nullDisabled
                      singleSelect
                      selectedValue={location}
                      onChange={(e) => setLocation(e.value)}
                      options={
                        allLocations?.length > 0
                          ? [
                              ...allLocations?.map((o) => {
                                return {
                                  value: o._id,
                                  label: o.name,
                                };
                              }),
                            ]
                          : []
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-1"></div>
                <div className="col-5">
                  <Form.Group className="d-flex flex-row">
                    <div className="col-6">
                      <ClickAwayListener onClickAway={handleClickAway1}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={effectiveDate}
                            onChange={(newValue) => {
                              const dateValue = newValue?.$d;
                              dateValue.setHours(0, 0, 0, 0);
                              setEffectiveDate(dateValue.toISOString());
                              setShowED(false);
                            }}
                            componentsProps={{
                              actionBar: { actions: ["today"] },
                            }}
                            open={showED}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Form.Group className="col-11">
                                <Form.Label className="headings2">
                                  Effective Date
                                </Form.Label>
                                <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                  <Form.Control
                                    ref={inputRef}
                                    // {...inputProps}
                                    value={
                                      effectiveDate
                                        ? new Date(
                                            effectiveDate
                                          ).toLocaleDateString("en-GB", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                          })
                                        : null
                                    }
                                    readOnly
                                    onClick={() => setShowED(!showED)}
                                    placeholder="Select Date"
                                  />
                                  <div onClick={() => setShowED(!showED)}>
                                    {InputProps?.endAdornment}
                                  </div>
                                </InputGroup>
                              </Form.Group>
                            )}
                          />
                        </LocalizationProvider>
                      </ClickAwayListener>
                    </div>

                    <div className="col-6">
                      <ClickAwayListener onClickAway={handleClickAway2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={expiryDate}
                            onChange={(newValue) => {
                              const dateValue = newValue?.$d;
                              dateValue.setHours(23, 59, 59, 999);
                              setExpiryDate(dateValue.toISOString());
                              setShowED2(false);
                            }}
                            componentsProps={{
                              actionBar: { actions: ["today"] },
                            }}
                            open={showED2}
                            renderInput={({
                              inputRef,
                              inputProps,
                              InputProps,
                            }) => (
                              <Form.Group className="col-12">
                                <Form.Label className="headings2">
                                  Expired Date
                                </Form.Label>
                                <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                  <Form.Control
                                    ref={inputRef}
                                    // {...inputProps}
                                    value={
                                      expiryDate
                                        ? new Date(
                                            expiryDate
                                          ).toLocaleDateString("en-GB", {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                          })
                                        : null
                                    }
                                    onClick={() => setShowED2(!showED2)}
                                    readOnly
                                    placeholder="Select Date"
                                  />
                                  <div onClick={() => setShowED2(!showED2)}>
                                    {InputProps?.endAdornment}
                                  </div>
                                </InputGroup>
                              </Form.Group>
                            )}
                          />
                        </LocalizationProvider>
                      </ClickAwayListener>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="headings2">Package Qty *</Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => {
                          if (
                            numberRegex.test(e.target.value) ||
                            e.target.value === ""
                          ) {
                            setPackageQty(e.target.value);
                          }
                        }}
                        value={packageQty}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="headings2">Description</Form.Label>
                    <InputGroup>
                      <Form.Control
                        as="textarea"
                        placeholder="Write..."
                        style={{ resize: "none" }}
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                        className="large-text-area"
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>

              <div className="ms-1 mt-3">
                <button
                  className="round-button"
                  style={{ width: "220px" }}
                  onClick={() => {
                    setShowAdd(!showAdd);
                    setCodeShow(false);
                    setNameShow(false);
                    setTypeShow(false);
                    setEditingProduct("");
                  }}
                >
                  +Add Product/Treatment
                </button>
              </div>

              <DeleteModal
                content="Confirm Product Deletion?"
                onYes={removeProduct}
                onNo={handleClose}
                show={show}
              />
              {showAdd && (
                <>
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Type *</Form.Label>
                    <SelectComponent
                      searchEnabled
                      nullDisabled
                      singleSelect
                      selectedValue={itemType}
                      onChange={(e) => setItemType(e.value)}
                      options={[
                        {
                          value: "Product",
                          label: "Product",
                        },
                        {
                          value: "Treatment",
                          label: "Treatment",
                        },
                      ]}
                    />
                  </Form.Group>
                </>
              )}
              {itemType == "Treatment" && (
                <div className="d-flex my-3 px-3 py-3 prod-box">
                  <div className="col-5">
                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Code *</Form.Label>
                      <SelectComponent
                        searchEnabled
                        // nullDisabled
                        singleSelect
                        selectedValue={tempTreatmentId}
                        onChange={(e) => {
                          if (addedTreatments.find((o) => o?.code == e.value)) {
                            toastify(
                              "Treatment has already been added",
                              "error"
                            );
                            return;
                          }
                          setTempTreatmentId(e.value);
                          setTempTreatmentCode(e.value);
                          setTempTreatmentName(e.value);
                          setTempTreatmentPrice(
                            allTreatments.find((o) => o._id == e.value)?.price
                          );
                        }}
                        options={
                          allTreatments?.length > 0
                            ? [
                                ...allTreatments.map((o) => {
                                  return {
                                    value: o._id,
                                    label: o.code,
                                  };
                                }),
                              ]
                            : []
                        }
                      />
                    </Form.Group>
                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Name *</Form.Label>
                      <SelectComponent
                        searchEnabled
                        // nullDisabled
                        singleSelect
                        selectedValue={tempTreatmentId}
                        onChange={(e) => {
                          if (
                            addedTreatments.findIndex(
                              (o) => o.code == e.value
                            ) !== -1
                          ) {
                            toastify(
                              "Treatment has already been added",
                              "error"
                            );
                            return;
                          }
                          setTempTreatmentId(e.value);
                          setTempTreatmentCode(e.value);
                          setTempTreatmentName(e.value);
                          setTempTreatmentPrice(
                            allTreatments.find((o) => o._id == e.value)?.price
                          );
                        }}
                        options={
                          allTreatments?.length > 0
                            ? [
                                ...allTreatments.map((o) => {
                                  return {
                                    value: o._id,
                                    label: o.name,
                                  };
                                }),
                              ]
                            : []
                        }
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="headings2">Quantity *</Form.Label>
                      <InputGroup className="mb-3">
                        <Form.Control
                          placeholder="Write..."
                          type="Number"
                          className="hideArrows"
                          onChange={(e) =>
                            setTempTreatmentLimit(e.target.value)
                          }
                          value={tempTreatmentLimit}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Discount Type
                      </Form.Label>
                      <SelectComponent
                        singleSelect
                        searchEnabled
                        defaultValue={tempTreatmentDiscType}
                        selectedValue={tempTreatmentDiscType}
                        options={[
                          { value: "Percentage", label: "Percentage" },
                          { value: "Amount", label: "Amount" },
                        ]}
                        onChange={(e) => {
                          setTempTreatmentDiscType(e.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Discount{" "}
                        {tempTreatmentDiscType === "Percentage" ? "(%)" : "($)"}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          onChange={(e) => {
                            setTempTreatmentDisc(e.target.value);
                          }}
                          value={tempTreatmentDisc}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Button
                      className="my-save-button mt-3"
                      onClick={saveTreatment}
                    >
                      Save
                    </Button>
                  </div>
                  {tempTreatmentCode && (
                    <div className="ms-5 ps-5">
                      <div className="shadow-box-2">
                        <div className="a">Unit Price</div>
                        <div className="b">${tempTreatmentPrice}</div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {itemType == "Product" && (
                <div className="d-flex my-3 px-3 py-3 prod-box">
                  <div className="col-5">
                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Code *</Form.Label>
                      {/* <InputGroup
                        className="mb-3 p-0 search-group"
                        onClick={() => {
                          setCodeShow(!codeShow);
                          if (!codeShow) {
                            setNameShow(false);
                            setTypeShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchCode}
                          onChange={(e) => {
                            setSearchCode(e.target.value);
                            if (!codeShow) {
                              setCodeShow(true); // Show the dropdown when typing
                            }
                          }}
                        />
                        <Button
                          onClick={() => {
                            const foundProduct = filteredCode.find((e) => e.code === searchCode);
                            if (foundProduct) {
                              setSelectedProduct(foundProduct); // Set selected product from filteredCode
                            } else {
                              setSelectedProduct(null); // Set selectedProduct to null if not found
                            }
                          }}
                        >
                          <img src={SearchIcon} alt="Search" />
                        </Button>
                      </InputGroup>
                      {codeShow && (
                        <div className="searchbox-packages">
                          {(filteredCode.length > 0
                            ? filteredCode
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchCode(e.code);
                                setCodeShow(false);
                                setSelectedProduct(e);
                              }}
                            >
                              {e.code}
                            </div>
                          ))}
                        </div>
                      )} */}
                      <SelectComponent
                        searchEnabled
                        singleSelect
                        selectedValue={searchCode} // Use the selected value
                        onChange={(e) => {
                          const foundProduct = allProducts.find((product) => product.code === e.value);
                          console.log('product found', foundProduct);
                          setSearchCode(e.value); // Set the selected code
                          // setSearchName(e.label);
                          setSelectedProduct(foundProduct); // Set the selected product based on the code
                        }}

                        options={
                          allProducts?.length > 0
                            ? [
                                ...allProducts.map((o) => {
                                  return {
                                    value: o.code,
                                    label: o.code,
                                  };
                                }),
                              ]
                            : []
                        }
                      />
                    </Form.Group>

                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Name *</Form.Label>
                      {/* <InputGroup
                        className="mb-3 p-0 search-group"
                        onClick={() => {
                          setNameShow(!nameShow);
                          if (!nameShow) {
                            setCodeShow(false);
                            setTypeShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchName}
                          onChange={(e) => {
                            // const upperCaseValue = e.target.value.toUpperCase();
                            setSearchName(e.target.value);
                            if (!nameShow) {
                              setNameShow(true);
                            }
                          }}
                        />
                        <Button
                          onClick={() => {
                            const foundProduct = filteredName.find((e) => e.name === searchName);
                            if (foundProduct) {
                              setSelectedProduct(foundProduct); // Set selected product from filteredCode
                            } else {
                              setSelectedProduct(null); // Set selectedProduct to null if not found
                            }
                          }}
                        >
                          <img src={SearchIcon} />
                        </Button>
                      </InputGroup>
                      {nameShow && (
                        <div className="searchbox-packages">
                          {(filteredName.length > 0
                            ? filteredName
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchName(e.name);
                                setNameShow(false);
                                setSelectedProduct(e);
                              }}
                            >
                              {e.name}
                            </div>
                          ))}
                        </div>
                      )} */}
                      <SelectComponent
                        searchEnabled
                        singleSelect
                        selectedValue={searchName} // Use the selected value
                        onChange={(e) => {
                          const foundProduct = allProducts.find((product) => product.name === e.value);
                          console.log('product found', foundProduct);
                          setSearchName(e.value); // Set the selected code
                          // setSearchName(e.label);
                          setSelectedProduct(foundProduct); // Set the selected product based on the code
                        }}

                        options={
                          allProducts?.length > 0
                            ? [
                                ...allProducts.map((o) => {
                                  return {
                                    value: o.name,
                                    label: o.name,
                                  };
                                }),
                              ]
                            : []
                        }
                      />
                    </Form.Group>

                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Type *</Form.Label>
                      <InputGroup
                        className="mb-3 p-0 search-group disabled-input"
                        onClick={() => {
                          setTypeShow(!typeShow);
                          if (!typeShow) {
                            setCodeShow(false);
                            setNameShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchType}
                          disabled={true}
                          onChange={(e) => setSearchType(e.target.value)}
                        />
                        {/* <Button>
                          <img src={SearchIcon} />
                        </Button> */}
                      </InputGroup>
                      {/* {typeShow && (
                        <div className="searchbox-packages">
                          {(filteredType.length > 0
                            ? filteredType
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchType(e.type.name);
                                setTypeShow(false);
                                setSelectedProduct(e);
                              }}
                            >
                              {e.type.name}
                            </div>
                          ))}
                        </div>
                      )} */}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2">Quantity *</Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          onChange={(e) => {
                            if (
                              numberRegex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setQty(e.target.value);
                            }
                          }}
                          value={qty}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-3">
                        Discount Type
                      </Form.Label>
                      <SelectComponent
                        singleSelect
                        searchEnabled
                        defaultValue={discountType}
                        selectedValue={discountType}
                        options={[
                          { value: "Percentage", label: "Percentage" },
                          { value: "Amount", label: "Amount" },
                        ]}
                        onChange={(e) => {
                          setDiscountType(e.value);
                        }}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-1">
                        Discount {discountType === "Percentage" ? "(%)" : "($)"}
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          onChange={(e) => {
                            setDiscount(e.target.value);
                          }}
                          value={discount}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Button
                      className="my-save-button mt-3"
                      onClick={saveProduct}
                    >
                      Save
                    </Button>
                  </div>
                  {selectedProduct && (
                    <div className="ms-5 ps-5">
                      <div className="shadow-box-2">
                        <div className="a">UOM</div>
                        <div className="b">{selectedProduct?.uom?.name}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Available Stock</div>
                        <div className="b">{selectedProduct?.qty}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Unit Cost</div>
                        <div className="b">
                          ${selectedProduct?.averageUnitCost}
                        </div>
                      </div>
                      {/* <div className="shadow-box-2">
												<div className="a">
													Total Cost
												</div>
												<div className="b">
													$
													{selectedProduct?.totalCost}
												</div>
											</div> */}
                      <div className="shadow-box-2">
                        <div className="a">Unit Price</div>
                        <div className="b">
                          ${selectedProduct?.unitSellingPrice}
                        </div>
                      </div>
                      {/* <div className="shadow-box-2">
												<div className="a">
													Total Price
												</div>
												<div className="b">
													$
													{
														selectedProduct?.totalSellingPrice
													}
												</div>
											</div> */}
                    </div>
                  )}
                </div>
              )}

              {products.length > 0 && (
                <>
                  <p className="font-400-20 my-3">Products</p>
                  <div className="">
                    <DataTable
                      columns={columns}
                      customStyles={customStyles}
                      data={data || []}
                      pagination
                      conditionalRowStyles={conditionalRowStyles}
                    />
                  </div>
                </>
              )}
              {addedTreatments.length > 0 && (
                <>
                  <p className="font-400-20 my-3">Treatments</p>
                  <div className="">
                    <DataTable
                      columns={treatment_columns}
                      customStyles={customStyles}
                      data={treatment_data || []}
                      pagination
                      conditionalRowStyles={conditionalRowStyles}
                    />
                  </div>
                </>
              )}
              <div className="col-8 mt-3">
                <Form.Label className="headings2 gray-font">
                  Cost and Price
                </Form.Label>
                <div className="shadow-text-box">
                  <span>Total Cost</span>

                  <div style={{ display: "flex", alignItems: "center" }}>
                    $
                    <input
                      className="input-group"
                      style={{paddingLeft: "5px", marginLeft: "5px"}}
                      value={`${totalCost === 0 ? getTotalCost() : (totalCost ? totalCost : "0")}`}
                      onChange={(e) => setTotalCost(e.target.value)}
                    />
                  </div>
                </div>
                <div className="shadow-text-box">
                  <span>Total Price</span>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    $
                    <input
                      className="input-group"
                      style={{ paddingLeft: "5px", marginLeft: "5px" }}
                      value={`${
                        totalPrice === 0
                          ? getTotalPrice()
                            ? getTotalPrice()?.toLocaleString()
                            : "0"
                          : totalPrice
                      }`}
                      onChange={(e) => setTotalPrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-8">
								<div className="shadow-text-box">
									<span>Discountable</span>
									<Switch
										checked={isDiscountable}
										onChange={(e) => setIsDiscountable(e)}
									/>
								</div>
								<div className="shadow-text-box">
									<span>Promoable</span>
									<Switch
										checked={isPromoable}
										onChange={(e) => setIsPromoable(e)}
									/>
								</div>
							</div> */}
            </div>
          </div>

          <div className="addEdit d-flex flex-row">
            <Button className="addItem" onClick={savePackage}>
              Save
            </Button>
            {/* {state?.package && (
							<Button
								className="addItem ms-4"
								onClick={extendPackage}
							>
								Extend Package
							</Button>
						)} */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
