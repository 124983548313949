import api from "../axios/axios-config";

export const convertFileToBase64 = async (data) => {
  try {
    let response = await api.post("document/convertToBase64", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
