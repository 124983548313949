import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getDotPhrases, updateDotPhrase } from "../../../apis/dotPhrases";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";
import AddEditModel from "./AddEditModel";
import {
  craete,
  update,
  get,
  deleteData,
} from "../../../apis/prescriptionConstant";
function PriscriptionSheetConstants() {
  let navigate = useNavigate();
  const [phrases, setPhrases] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [prescriptionData, setprescriptionData] = useState({
    title: "",
    select_type: "",
  });
  const [show, setShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getList = async () => {
    const response = await get();
    if (response?.status !== "Fail") {
      setPhrases(response);
    } else {
      if (response?.message === "No data Found") {
        setPhrases([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removePhrase = async () => {
    setShow(false);
    const response = await deleteData({ id: deleting });
    if (response) {
      getList();
      toastify("Constant deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const handleCreate = async () => {
    const response = await craete(prescriptionData);
    if (response?.status !== "Fail") {
      getList();
      setIsOpen(false);
      setprescriptionData({
        title: "",
        select_type: "",
      });
      toastify("Constant added successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const handleUpdate = async () => {
    const response = await update(prescriptionData?._id, {
      title: prescriptionData?.title,
      select_type: prescriptionData?.select_type,
    });
    if (response?.status !== "Fail") {
      getList();
      setIsOpen2(false);
      setprescriptionData({
        title: "",
        select_type: "",
      });
      toastify("Constant Updated successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly5">
          <span className="me-2">Title</span>
          {
            <FilterComponent
              type="search"
              array={phrases}
              setArray={setFilteredData}
              value="DotPhrases"
            />
          }
        </div>
      ),

      selector: (row) => row.title,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "title"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly5">
          <span className="me-2">Type</span>
          {
            <FilterComponent
              type="search"
              array={phrases}
              setArray={setFilteredData}
              value="DotPhrases"
            />
          }
        </div>
      ),

      selector: (row) => row.type,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "title"),
    },

    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? phrases : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        index: index,
        title: e.title,
        type: e.select_type,
        action: (
          <div className="d-flex">
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                setprescriptionData(e);
                setIsOpen2(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <div className="container p-0">
      <div className="mainHeading px-4">
        <h1>Prescription Sheet Constant</h1>
        <Button className="addItem" onClick={() => setIsOpen(true)}>
          + Add New
        </Button>
      </div>
      <AddEditModel
        show={isOpen}
        data={prescriptionData}
        onChange={setprescriptionData}
        setIsOpen={setIsOpen}
        onAction={handleCreate}
      />
      <AddEditModel
        show={isOpen2}
        data={prescriptionData}
        onChange={setprescriptionData}
        setIsOpen={setIsOpen2}
        onAction={handleUpdate}
        isUpdate={true}
      />
      <DeleteModal
        content="Confirm Dot Phrase Deletion?"
        onYes={removePhrase}
        onNo={handleClose}
        show={show}
      />

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default PriscriptionSheetConstants;
