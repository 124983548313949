import Papa from "papaparse";
import * as XLSX from "xlsx";
import moment from "moment";
import packageJson from "../../package.json";
import ExcelJS from "exceljs";
const shortMonths = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

const colors = [
  "#1F77B4",
  "#F16F33",
  "#9B9B9B",
  "#FFB628",
  "#2D69B9",
  "#65A345",
];

export const adjustDateFormat = (date) => {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const formatter = new Intl.DateTimeFormat("en-GB", options);
  const formattedDate = formatter.format(date);
  // Split the formatted date by slashes and reformat it as 'YYYY-MM-DD'
  const parts = formattedDate.split("/");
  return `${parts[2]}-${parts[1]}-${parts[0]}`;

  // let currentDate = date.toLocaleDateString().split("/");
  // if (currentDate[0] < 10) {
  // 	currentDate[0] = `0${currentDate[0]}`;
  // }
  // if (currentDate[1] < 10) {
  // 	currentDate[1] = `0${currentDate[1]}`;
  // }
  // currentDate = currentDate[2] + "-" + currentDate[0] + "-" + currentDate[1];
  // console.log("1", currentDate)

  // return currentDate;
};

export const convertTimeIntoSeconds = (time) => {
  if (!time) return 0;
  let t = time.split(":");
  let h = parseInt(t[0]) * 3600;
  let m = parseInt(t[1]) * 60;
  return h + m;
};

export const getFormatedTime = (time) => {
  if (!time?.includes(":")) {
    return time;
  }
  let t = time.split(":");
  let h = t[0];
  let m = t[1];

  let ampm = h >= 12 ? "PM" : "AM";
  h = h % 12 || 12;
  h = JSON.stringify(h);
  if (h.length == 1) {
    h = "0" + h;
  }
  if (m.length == 1) {
    m = "0" + m;
  }
  let convertedTime = h + ":" + m + " " + ampm;
  return convertedTime;
};
function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff;
  }
  return buf;
}
export const exportData = (data, keyTypes, filename, type) => {
  if (type === "date") {
    const workbook = XLSX.utils.book_new();

    // Split data by day
    const dataByDay = {};
    keyTypes.forEach((item, index) => {
      const valueSheet = {};
      // const day = date.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }); // Get day in format MM-DD-YYYY
      const day = item.toString().replaceAll("/", "-");

      if (!dataByDay[day]) {
        dataByDay[day] = [];
      }
      dataByDay[day] = data[index];
    });
    Object.keys(dataByDay).map((day) => {
      const sheetName = day; // Format sheet name as needed
      const sheetData = dataByDay[day];
      const worksheet = XLSX.utils.json_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    // Create a file and download it
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", `${filename ? filename : "epion"}.xlsx`);
    pom.click();
  } else if (type === "month") {
    const workbook = XLSX.utils.book_new();

    // Split data by month
    const dataByMonth = {};
    keyTypes.forEach((item, index) => {
      const date = new Date(item);
      const month = date.getMonth() + 1; // Get month (1-12)

      if (!dataByMonth[month]) {
        dataByMonth[month] = [];
      }
      console.log("dataByMonth[month]", dataByMonth[month]);
      dataByMonth[month] = data[index];
    });

    console.log(dataByMonth);

    // Add each month's data to a separate sheet
    Object.keys(dataByMonth).forEach((month) => {
      const sheetName = `${month < 10 ? "0" : ""}${month}_2023`.replace(
        /[:\\/?*\[\]]/g,
        ""
      ); // Format sheet name as needed
      const sheetData = dataByMonth[month];
      const worksheet = XLSX.utils.json_to_sheet(sheetData);
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    });

    // Create a file and download it
    const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", `${filename ?? "epion"}.xlsx`);
    pom.click();
  } else {
    const currentDate = new Date();
    // Check if data array is empty
    if (data.length === 0) {
      console.error("No data to export.");
      return; // Exit the function if there's no data
    }

    // Create a new ExcelJS Workbook
    const workbook = new ExcelJS.Workbook();

    // Add a worksheet to the workbook
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add headers to the worksheet
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);

    // Apply styling to the header row
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFC0C0C0" }, // Gray background color
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = { vertical: "middle", horizontal: "center" }; // Align content in the center
    });

    // Set height for header row
    headerRow.height = 20; // Adjust the height as needed

    // Set column width for all columns
    worksheet.columns.forEach((column) => {
      column.width = 15; // Adjust the width as needed
    });

    // Add data rows to the worksheet
    data.forEach((rowData) => {
      const transformedRow = Object.values(rowData).map((value) => {
        // Handle array values
        if (Array.isArray(value)) {
          return value.filter(Boolean).join("\n");
        }
        // Handle non-string values
        if (typeof value !== "string") {
          return value;
        }
        // Remove empty strings and replace commas with newline characters for string values
        return value.replace(/,|""/g, "\n").trim();
      });
      const newRow = worksheet.addRow(transformedRow);
      newRow.eachCell((cell) => {
        cell.alignment = { vertical: "middle", horizontal: "center" }; // Align content in the center
      });
      // Set height for data rows
      newRow.height = 20; // Adjust the height as needed
    });

    // Hide gridlines
    worksheet.views = [{ showGridLines: false }];

    // Generate a Blob from the workbook
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: "application/octet-stream" });
        const url = URL.createObjectURL(blob);
        const pom = document.createElement("a");
        pom.href = url;
        pom.setAttribute(
          "download",
          `${filename ?? "epion"}_${currentDate.getTime()}.xlsx`
        );
        pom.click();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
};
//   export const exportData = (data, filename, type) => {
// 	console.log("Type",type)
// 	console.log("Data",data)
// 	const workbook = XLSX.utils.book_new();

// 	// Split data by day
// 	const dataByDay = {};
// 	data.forEach(item => {
// 	  const date = new Date(item.date);
// 	  const day = date.getDate(); // Get day (1-31)

// 	  if (!dataByDay[day]) {
// 		dataByDay[day] = [];
// 	  }
// 	  console.log("dataByDay[day]",dataByDay[day])
// 	  dataByDay[day].push(item);
// 	});

// 	// Add each day's data to a separate sheet
// 	Object.keys(dataByDay).forEach(day => {
// 	  const sheetName = `${day < 10 ? '0' : ''}${day}_2023`.replace(/[:\\/?*\[\]]/g, ''); // Format sheet name as needed
// 	  const sheetData = dataByDay[day];
// 	  const worksheet = XLSX.utils.json_to_sheet(sheetData);
// 	  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
// 	});

// 	// Create a file and download it
// 	const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
// 	const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
// 	const url = URL.createObjectURL(blob);
// 	const pom = document.createElement('a');
// 	pom.href = url;
// 	pom.setAttribute('download', `${filename ?? 'epion'}.xlsx`);
// 	pom.click();
//   };
//   export const exportData = (data, filename) => {
// 	const workbook = XLSX.utils.book_new();

// 	// Split data by month
// 	const dataByMonth = {};
// 	data.forEach(item => {
// 	  const date = new Date(item.date);
// 	  const month = date.getMonth() + 1; // Get month (1-12)

// 	  if (!dataByMonth[month]) {
// 		dataByMonth[month] = [];
// 	  }

// 	  dataByMonth[month].push(item);
// 	});

// 	// Add each month's data to a separate sheet
// 	Object.keys(dataByMonth).forEach(month => {
// 	  const sheetName = `${month}/1/2023`.replace(/[:\\/?*\[\]]/g, '') // Format sheet name as needed
// 	  const sheetData = dataByMonth[month];
// 	  const worksheet = XLSX.utils.json_to_sheet(sheetData);
// 	  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
// 	});

// 	// Create a file and download it
// 	const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
// 	const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
// 	const url = URL.createObjectURL(blob);
// 	const pom = document.createElement('a');
// 	pom.href = url;
// 	pom.setAttribute('download', `${filename ?? 'epion'}.xlsx`);
// 	pom.click();
//   };
// export const exportData = (data, filename) => {
// console.log("final-data",data)
// var csv = Papa.unparse(data, [
// 	{
// 		quotes: false,
// 		quoteChar: '"',
// 		escapeChar: '"',
// 		delimiter: ",",
// 		header: true,
// 		newline: "\r\n",
// 		skipEmptyLines: false,
// 		columns: null,
// 	},
// ]);
// var blob = new Blob([csv], { type: "text/csv" });
// var url = URL.createObjectURL(blob);
// var pom = document.createElement("a");
// pom.href = url;
// pom.setAttribute("download", `${filename ?? "epion"}.csv`);
// pom.click();
// };

export const convertPdfToByte = (dataURI) => {
  var BASE64_MARKER = ";base64,";
  var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  var base64 = dataURI.substring(base64Index);
  var raw = window.atob(base64);
  var rawLength = raw.length;
  var array = new Uint8Array(new ArrayBuffer(rawLength));

  for (var i = 0; i < rawLength; i++) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export const getAgeFromDob = (dob) => {
  const date = new Date(dob);
  const diff = new Date() - date;
  return Math.floor(diff / 3.154e10);
};

export const formatNumber = (e) => {
  if (e >= 1000000000) {
    return (e / 1000000000).toFixed(1).replace(/\.0$/, "") + "B";
  }
  if (e >= 1000000) {
    return (e / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (e >= 1000) {
    return (e / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return e;
};

export const getInvoiceId = (number) => {
  if (!isNaN(number)) {
    let num = number;
    num = num.toString().padStart(5, "0");
    return `IV-${num}`;
  }
  return;
};

export const generateRandomStr = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const getRandomColor = (index) => {
  if (colors.length - 1 >= index) {
    return colors[index];
  }

  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  return randomColor;
};

export const customDateSort = (rowA, rowB, title, isFormatted) => {
  let a = rowA[title];
  let b = rowB[title];
  if (!a || !b) {
    return 0;
  }

  if (isFormatted) {
    a = new Date(rowA[title]).getTime();
    b = new Date(rowB[title]).getTime();
  } else {
    let splitA = a.split("/");
    a = new Date(splitA[2], splitA[1], splitA[0]).getTime();
    let splitB = b.split("/");
    b = new Date(splitB[2], splitB[1], splitB[0]).getTime();
  }

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const customStringSort = (str1, str2, title, isNested) => {
  let s1;
  let s2;

  if (isNested) {
    let value = title.split(".");
    s1 = str1;
    s2 = str2;
    for (let val of value) {
      s1 = s1[val];
      s2 = s2[val];
    }
  } else {
    s1 = str1[title];
    s2 = str2[title];
  }

  if (!s1) return -1;
  if (!s2) return 1;

  console.log(s1);
  const result = s1.localeCompare(s2);

  if (result < 0) {
    return -1;
  } else if (result > 0) {
    return 1;
  } else {
    return 0;
  }
};

function timeToSeconds(time) {
  // Parse the time string into hours, minutes, and AM/PM components
  var match = time.match(/(\d+):(\d+)\s*(AM|PM)/);
  var hours = parseInt(match[1]);
  var minutes = parseInt(match[2]);
  var isPM = match[3] === "PM";

  // Adjust the hours component if the time is in the PM
  if (isPM && hours !== 12) {
    hours += 12;
  }

  if (!isPM && hours == 12) {
    hours = 0;
  }
  // Convert the time to seconds
  var totalSeconds = hours * 3600 + minutes * 60;

  return totalSeconds;
}

export const customTimeSort = (rowA, rowB, title) => {
  const a = timeToSeconds(rowA[title]);
  const b = timeToSeconds(rowB[title]);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const customNumberSort = (rowA, rowB, title) => {
  const a = parseInt(rowA[title]);
  const b = parseInt(rowB[title]);

  if (a > b) {
    return 1;
  }

  if (b > a) {
    return -1;
  }

  return 0;
};

export const customArrayDateSort = (array) => {
  const sortedArray = array.sort((a, b) => {
    return new Date(b) - new Date(a);
  });

  return sortedArray;
};

export const AddOrSubractDays = (startingDate, number, add) => {
  if (add) {
    return new Date(
      startingDate.getFullYear(),
      startingDate.getMonth(),
      startingDate.getDate() + 1
    );
  } else {
    return new Date(
      startingDate.getFullYear(),
      startingDate.getMonth(),
      startingDate.getDate() - 1
    );
  }
};

export const base64ToFile = (base64, filename) => {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const removeDuplicatesFromArray = (arr) => {
  if (!arr) {
    return [];
  }

  if (arr.length === 0) {
    return arr;
  }

  // Create a Set to store unique elements
  const uniqueSet = new Set(arr);

  // Convert the Set back to an array
  const uniqueArray = Array.from(uniqueSet);

  return uniqueArray;
};

export const removeDuplicatesByPatientId = (arr) => {
  if (!arr) {
    return [];
  }

  if (arr.length === 0) {
    return arr;
  }

  const uniqueArray = [];
  const uniqueValues = new Set();

  for (const obj of arr) {
    const value = obj.patient?._id;
    if (!uniqueValues.has(value)) {
      uniqueArray.push(obj);
      uniqueValues.add(value);
    }
  }

  return uniqueArray;
};

export const getVersion = () => {
  return packageJson?.version ? packageJson.version : "1.0.0";
};

export const calcTotalWithDisc = (total, disc, discType) => {
  let totalDisc = 0;
  if (!disc || !discType) {
    return total;
  }

  if (discType === "Amount" || discType === "Exact Value") {
    totalDisc = disc;
  }

  if (discType === "Percentage") {
    totalDisc = total * (disc / 100);
  }

  return Math.round((total - totalDisc) * 100) / 100;
};

export const formatDate = (value) => {
  if (!value) {
    return value;
  }

  return moment(value).format("DD/MM/YYYY");
};

export const capCaseString = (value) => {
  if (!value || value === "") {
    return value;
  }

  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const convertDateTime = (value) => {
  if (!value) {
    return value;
  }

  return moment(value).format("DD-MM-YYYY HH:mm:ss");
};

export function secondsToHMS(secs) {
  function z(n) {
    return (n < 10 ? "0" : "") + n.toFixed(0);
  }

  var sign = secs < 0 ? "-" : "";
  secs = Math.abs(secs);
  return (
    sign +
    z((secs / 3600) | 0) +
    ":" +
    z(((secs % 3600) / 60) | 0) +
    ":" +
    z(secs % 60)
  );
}

export const calculateTimeDiff = (endDateTimeISO, startDateTimeISO) => {
  const endDateTime = new Date(endDateTimeISO);
  const startDateTime = new Date(startDateTimeISO);
  const diffInMilliSeconds = endDateTime - startDateTime;
  return secondsToHMS(diffInMilliSeconds / 1000);
};

export const convertToDefaultDate = (value) => {
  if (!value) return value;

  return moment(value).format("DD-MM-YYYY");
};

export const downloadCsv = (data, name) => {
  const csv = Papa.unparse(data, [
    {
      quotes: false,
      quoteChar: '"',
      escapeChar: '"',
      delimiter: ",",
      header: true,
      newline: "\r\n",
      skipEmptyLines: false,
      columns: null,
    },
  ]);
  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", `${name ? name : "export"}.csv`);
  pom.click();
};

export const downloadCustomCsv = (csvContent, name) => {
  var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const pom = document.createElement("a");
  pom.href = url;
  pom.setAttribute("download", `${name ? name : "export"}.csv`);
  pom.click();
};

export const getMonthFromRange = (start, end) => {
  let months = [];
  let currentDate = new Date(start);
  let currentEnd = new Date(end);

  while (currentDate <= currentEnd) {
    months.push({
      year: currentDate.getFullYear(),
      month: currentDate.getMonth(),
    });
    currentDate.setMonth(currentDate.getMonth() + 1);
  }

  return months;
};

export const getYearFromRange = (start, end) => {
  let years = [];
  let currentYear = new Date(start).getFullYear();
  const endYear = new Date(end).getFullYear();

  while (currentYear <= endYear) {
    years.push(currentYear);
    currentYear++;
  }

  return years;
};

export const getShortMonth = (index) => {
  return shortMonths[index];
};
export const filterTypes = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last Week",
    value: "lastWeek",
  },
  {
    label: "Last Month",
    value: "lastMonth",
  },
  {
    label: "Last Quarter",
    value: "lastQuarter",
  },
  {
    label: "Last 6 Months",
    value: "last6Months",
  },
  {
    label: "Last Year",
    value: "lastYear",
  },
  /*
  {
    label: "Last 2 Years",
    value: "last2Years",
  },
  {
    label: "Last 3 Years",
    value: "last3Years",
  },
  {
    label: "Last 5 Years",
    value: "last5Years",
  },
  */
];
export const getCurrentYear = () => {
  return new Date().getFullYear();
};

export const getFilterDates = (filterType) => {
  const currentYear = new Date().getFullYear();

  const pad = (n) => (n < 10 ? '0' + n : n);
  if (filterType === "today") {
    const todayDate = new Date();
    const yesterdayDate = new Date(todayDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);
    const today = `${todayDate.getFullYear()}-${pad(todayDate.getMonth() + 1)}-${pad(todayDate.getDate())}`;
    const yesterday = `${yesterdayDate.getFullYear()}-${pad(yesterdayDate.getMonth() + 1)}-${pad(yesterdayDate.getDate())}`;
    return {
      r1_start_date: yesterday,
      r1_end_date: yesterday,
      r2_start_date: today,
      r2_end_date: today,
    };
  } else if (filterType === "lastWeek") {
    const today = new Date();
    const currentDate = today.getDate();
    const currentDay = today.getDay();
    const lastWeekEnd = new Date(today);
    lastWeekEnd.setDate(currentDate - currentDay - 1);
    const lastWeekStart = new Date(lastWeekEnd);
    lastWeekStart.setDate(lastWeekEnd.getDate() - 6);
    const thisWeekStart = new Date(lastWeekEnd);
    thisWeekStart.setDate(lastWeekEnd.getDate() + 1);
    const thisWeekEnd = new Date(thisWeekStart);
    thisWeekEnd.setDate(thisWeekStart.getDate() + 6);
    return {
      r1_start_date: `${lastWeekStart.getFullYear()}-${pad(lastWeekStart.getMonth() + 1)}-${pad(lastWeekStart.getDate())}`,
      r1_end_date: `${lastWeekEnd.getFullYear()}-${pad(lastWeekEnd.getMonth() + 1)}-${pad(lastWeekEnd.getDate())}`,
      r2_start_date: `${thisWeekStart.getFullYear()}-${pad(thisWeekStart.getMonth() + 1)}-${pad(thisWeekStart.getDate())}`,
      r2_end_date: `${thisWeekEnd.getFullYear()}-${pad(thisWeekEnd.getMonth() + 1)}-${pad(thisWeekEnd.getDate())}`,
    };
  } else if (filterType === "lastMonth") {
    const thisMonth = new Date();
    const thisMonthLast = new Date(thisMonth.getFullYear(), thisMonth.getMonth() + 1, 0);
    const lastMonth = new Date(thisMonth);
    const lastMonthLast = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
    lastMonth.setMonth(lastMonth.getMonth() - 1);
    return {
      r1_start_date: `${currentYear}-${pad(lastMonth.getMonth() + 1)}-01`,
      r1_end_date: `${currentYear}-${pad(lastMonth.getMonth() + 1)}-${lastMonthLast.getDate()}`,
      r2_start_date: `${thisMonth.getFullYear()}-${pad(thisMonth.getMonth() + 1)}-01`,
      r2_end_date: `${thisMonth.getFullYear()}-${pad(thisMonth.getMonth() + 1)}-${thisMonthLast.getDate()}`,
    };
  } else if (filterType === "lastQuarter") {
    const today = new Date();
    const currentQuarter = Math.floor(today.getMonth() / 3) + 1;
    let lastQuarterStart, lastQuarterEnd, thisQuarterStart, thisQuarterEnd;
    if (currentQuarter === 1) {
      lastQuarterStart = new Date(currentYear - 1, 9, 1);
      lastQuarterEnd = new Date(currentYear - 1, 11, 31);
      thisQuarterStart = new Date(currentYear, 0, 1);
      thisQuarterEnd = new Date(currentYear, 2, 31);
    } else {
      lastQuarterStart = new Date(currentYear, (currentQuarter - 2) * 3, 1);
      lastQuarterEnd = new Date(currentYear, (currentQuarter - 1) * 3, 0);
      thisQuarterStart = new Date(currentYear, (currentQuarter - 1) * 3, 1);
      thisQuarterEnd = new Date(currentYear, currentQuarter * 3, 0);
    }
    return {
      r1_start_date: `${lastQuarterStart.getFullYear()}-${pad(lastQuarterStart.getMonth() + 1)}-${pad(lastQuarterStart.getDate())}`,
      r1_end_date: `${lastQuarterEnd.getFullYear()}-${pad(lastQuarterEnd.getMonth() + 1)}-${pad(lastQuarterEnd.getDate())}`,
      r2_start_date: `${thisQuarterStart.getFullYear()}-${pad(thisQuarterStart.getMonth() + 1)}-${pad(thisQuarterStart.getDate())}`,
      r2_end_date: `${thisQuarterEnd.getFullYear()}-${pad(thisQuarterEnd.getMonth() + 1)}-${pad(thisQuarterEnd.getDate())}`,
    };
  } else if (filterType === "last6Months") {
    return {
      r1_start_date: `${currentYear}-01-01`,
      r1_end_date: `${currentYear}-06-30`,
      r2_start_date: `${currentYear}-07-01`,
      r2_end_date: `${currentYear}-12-31`,
    };
  } else if (filterType === "lastYear") {
    return {
      r1_start_date: `${currentYear - 1}-01-01`,
      r1_end_date: `${currentYear - 1}-12-31`,
      r2_start_date: `${currentYear}-01-01`,
      r2_end_date: `${currentYear}-12-31`,
    };
  } else if (filterType === "last2Years") {
    return {
      r1_start_date: `${currentYear - 3}-01-01`,
      r1_end_date: `${currentYear - 2}-12-31`,
      r2_start_date: `${currentYear - 1}-01-01`,
      r2_end_date: `${currentYear}-12-31`,
    };
  } else if (filterType === "last3Years") {
    return {
      r1_start_date: `${currentYear - 4}-01-01`,
      r1_end_date: `${currentYear - 3}-12-31`,
      r2_start_date: `${currentYear - 2}-01-01`,
      r2_end_date: `${currentYear}-12-31`,
    };
  } else if (filterType === "last5Years") {
    return {
      r1_start_date: `${currentYear - 6}-01-01`,
      r1_end_date: `${currentYear - 5}-12-31`,
      r2_start_date: `${currentYear - 4}-01-01`,
      r2_end_date: `${currentYear}-12-31`,
    };
  } else {
    return {
      r1_start_date: `${currentYear}-01-01`,
      r1_end_date: `${currentYear}-06-30`,
      r2_start_date: `${currentYear}-07-01`,
      r2_end_date: `${currentYear}-12-31`,
    };
  }
};

// function to update percentage label for revenue and profit label
export const updatePercentageLabel = (period, values) => {
  if (!values || !Array.isArray(values)) {
    console.error("Invalid or undefined 'values' array.");
    return values; // Return the original values if undefined or not an array
  }

  if (period === 'percentage') {
    const revenuePeriodExists = values.some((item) => item.period === 'revenue');
    const profitPeriodExists = values.some((item) => item.period === 'profit');

    return values.map((item) => {
      if (item.period === 'percentage') {
        let updatedLabel = 'percentage';
        if (revenuePeriodExists) {
          updatedLabel = 'Percentage of Total Revenue';
        } else if (profitPeriodExists) {
          updatedLabel = 'Percentage of Total Profit';
        }
        return { ...item, period: updatedLabel };
      }
      return item;
    });
  }

  return values;
}

// array of postal code and address names in singapore
const arrayOfPostalCodeAvailable = [
    { code: ["01", "02", "03", "04", "05", "06"], name: "Raffles Place, Cecil, Marina, People's Park" },
    { code: ["07", "08"], name: "Anson, Tanjong Pagar" },
    { code: ["14", "15", "16"], name: "Queenstown, Tiong Bahru" },
    { code: ["09", "10"], name: "Telok Blangah, Harbourfront" },
    { code: ["11", "12", "13"], name: "Pasir Panjang, Hong Leong Garden, Clementi New Town" },
    { code: ["17"], name: "High Street, Beach Road (part)" },
    { code: ["18", "19"], name: "Middle Road, Golden Mile" },
    { code: ["20", "21"], name: "Little India" },
    { code: ["22", "23"], name: "Orchard, Cairnhill, River Valley" },
    { code: ["24", "25", "26", "27"], name: "Ardmore, Bukit Timah, Holland Road, Tanglin" },
    { code: ["28", "29", "30"], name: "Watten Estate, Novena, Thomson" },
    { code: ["31", "32", "33"], name: "Balestier, Toa Payoh, Serangoon" },
    { code: ["34", "35", "36", "37"], name: "Macpherson, Braddell" },
    { code: ["38", "39", "40", "41"], name: "Geylang, Eunos" },
    { code: ["42", "43", "44", "45"], name: "Katong, Joo Chiat, Amber Road" },
    { code: ["46", "47", "48"], name: "Bedok, Upper East Coast, Eastwood, Kew Drive" },
    { code: ["49", "50", "81"], name: "Loyang, Changgi" },
    { code: ["51", "52"], name: "Tampines, Pasir Ris" },
    { code: ["53", "54", "55", "82"], name: "Serangoon Garden, Hougang, Punggol" },
    { code: ["56", "57"], name: "Bishan, Ang Mo Kio" },
    { code: ["58", "59"], name: "Upper Bukit Timah, Clementi Park, Ulu Pandan" },
    { code: ["60", "61", "62", "63", "64"], name: "Jurong" },
    { code: ["65", "66", "67", "68"], name: "Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang" },
    { code: ["69", "70", "71"], name: "Lim Chu Kang, Tengah" },
    { code: ["72", "73"], name: "Kranji, Woodgrove" },
    { code: ["77", "78"], name: "Upper Thomson, Springleaf" },
    { code: ["75", "76"], name: "Yishun, Sembawang" },
    { code: ["79", "80"], name: "Seletar" },
];

// // function for finding and returning address based on area postal code
// export const getPostalArea = (input) => {
//   // Ensure the input is a string and has at least 2 characters
//   if (typeof input !== "string" || input.length < 2) {
//     return null;
//   }

//   // Extract the first two characters
//   const firstTwoChars = input.slice(0, 2);

//   // Find the area that matches the postal code
//   const area = arrayOfPostalCodeAvailable.find((area) =>
//     area.code.includes(firstTwoChars)
//   );

//   // Return the name of the area if found, otherwise return null
//   return area ? area.name : null;
// };

export const getPostalArea = async (postalCode, setLoading) => {
  try {
    setLoading(true);
    const response = await fetch(`https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y&pageNum=1`);
    const data = await response.json();

    setLoading(false);

    if (data.found > 0) {
      // Return the first result's address details
      return data.results[0].ADDRESS;
    } else {
      return ""; // No address found for the postal code
    }
  } catch (error) {
    setLoading(false);
    console.error('Error fetching from OneMap API:', error);
    return ""; // In case of any error, return an empty string
  }
};