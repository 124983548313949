import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updatePatientProfileImage } from "../../../apis/patients";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { setData } from "../../../redux/slices/StaffFormsSlices";
import AddEditTemplate from "../Appointments/AddEditAppointment";
import loader from "../../../assets/gif/loader.gif";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { createMemos, getMemos, updateMemo } from "../../../apis/memo";
import DataTable from "react-data-table-component";
import DeleteModal from "../../SharedComponents/DeleteModal";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { Flag } from "../../../assets/images";
import { setPatientData } from "../../../redux/slices/patientSlice";

export default function AppointmentModal({
	updatedData,
	onCancel,
	setIsModalOpen,
	isModalOpen,
	isAppointment,
	setSchedularData,
	schedularData,
	waitingData,
	setRefreshAppointments,
	setRefreshWaiting
}) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const imageRef = useRef();

	const { appointmentForm } = useSelector((state) => state.staffForm);
	const [patient, setPatient] = useState();
	const [dp, setDp] = useState("/imgs/user.png");
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const [deleting, setDeleting] = useState("");
	const [memos, setMemos] = useState([]);
	const [isFlaged, setFlagged] = useState(false);

	const [description, setDescription] = useState("");

	const [filteredData, setFilteredData] = useState([]);

	const getList = async () => {
		if (updatedData.id) {
			const { result, error } = await authorizedRequests({
				url: "appointment/listOfAppointments",
				method: "get",
				params: {
					_id: updatedData.id
				}
			});
			if (error) {
				toastify(error.message ?? "unknown error occured", "error");
				return;
			}
	
			var tempArr = result?.data
				?.map((o) => {
					return {
						patient: o.patient._id,
						branch: updatedData.branch,
						date: o.date,
						location: updatedData.locationId,
						startTime: updatedData.startTime,
						endTime: updatedData.endTime,
						// package: o.package._id,
						staff: [
							...o.staff.map((o) => {
								return o._id;
							}),
						],
						tag: o.tag,
						otherTags: o.otherTags,
						// treatments: o.treatments.map((o) => o._id),
						products: o.products.map((o) => o._id),
						status: o.status,
						remarks: o.remarks,
						formStatus: "Edit",
						editId: o._id,
					};
				});
			if (tempArr.length > 0) {
				dispatch(
					setData({
						parent: "appointmentForm",
						value: {
							patient: tempArr[0].patient,
							branch: tempArr[0].branch._id,
							date: tempArr[0].date,
							location: tempArr[0].location,
							startTime: tempArr[0].startTime,
							endTime: tempArr[0].endTime,
							package: tempArr[0].package,
							staff: tempArr[0].staff,
							tag: tempArr[0].tag,
							otherTags: tempArr[0].otherTags,
							status: tempArr[0].status,
							treatments: tempArr[0].treatments,
							products: tempArr[0].products,
							remarks: tempArr[0].remarks,
							formStatus: "Edit",
							editId: tempArr[0].editId,
						},
					}),
				);
			}
		}

		// setFormData(result.data);

		return;
	};

	const uploadImage = async (imgToUpload) => {
		setLoading(true);
		const response = await updatePatientProfileImage(
			patient?._id,
			imgToUpload,
		);
		if (response && response?.status !== "Fail") {
			toastify("Image uploaded successfully.", "success");
			setDp(response?.data?.profilePhoto);
			imageRef.current.src = response?.data?.profilePhoto;
		} else {
			toastify(`Error uploading image, ${response.message}`, "error");
		}
		setLoading(false);
	};
	const populateMemos = async () => {
		let data = { patientId: patient?._id };
		const response = await getMemos(data);
		if (response?.status !== "Fail") {
			setMemos(response?.reverse());
		} else {
			toastify(response?.error, "error");
		}
	};
	const addMemo = async () => {
		if (!description) {
			missingPopup("Description");
		} else {
			let data = { description, isFlaged, patientId: patient?._id };

			const response = await createMemos(data);
			if (response?.status !== "Fail") {
				setShow2(false);
				populateMemos();
				toastify("Memo added successfully!", "success");
			} else {
				toastify(response?.message, "error");
			}
			setShow(true);
			setDescription("");
		}
	};

	const removeMemo = async () => {
		setShow3(false);
		const response = await updateMemo(deleting, { isDeleted: true });
		if (response.status !== "Fail") {
			populateMemos();
			toastify("Memo deleted!", "success");
		} else {
			toastify(response?.message, "error");
		}
		setShow(true);
	};
	const handleClose = () => {
		setShow3(false);
		setShow2(false);
		setShow(true);
		setDeleting("");
	};
	useEffect(() => {
		populateMemos();
	}, []);
	useEffect(() => {
		if (isAppointment) {
			getList();
		}
	}, [isAppointment]);
	useEffect(() => {
		if (patient) {
			setDp(patient?.profilePhoto || "/imgs/user.png");
		}
	}, [patient]);
	useEffect(() => {
		if (appointmentForm.patient && !waitingData.isFromWaiting) {
			(async () => {
				const { result, error } = await authorizedRequests({
					url: "patient/getPatientDeatilsById",
					method: "post",
					data: {
						patientID: appointmentForm.patient,
					},
				});
				if (error) {
					toastify(error.message || "Failed to get patient", "error");
					setIsModalOpen(false);
					return;
				}
				setPatient(result.data);
			})();
		}
	}, [appointmentForm.patient, waitingData.isFromWaiting]);

	const data = (filteredData.length === 0 ? memos : filteredData)?.map(
		(e, index) => {
			return {
				index: index,
				id: e._id,
				description: e.description,
				createdAt: e.created_at,
				createdBy: e.createdBy?.username,
				isFlaged: e.isFlaged,
				action: (
					<div className="d-flex">
						<div
							className="mx-3 cursor-pointer icon-width"
							onClick={() => {
								setShow(false);
								setShow2(false);
								setDeleting(e._id);
								setShow3(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);
	const appointments_data = patient?.appointments?.map((e, index) => {
		return {
			index: index,
			id: e?._id,
			date: new Date(e.date).toLocaleDateString(),
			package: e.package?.name,
			staff: (
				<>
					{e?.staff?.map((o) => {
						return <div className="">{o.username}</div>;
					})}
				</>
			),
			status: (
				<>
					<div
						className={`${e.status == "Confirmed"
							? "green-rounded-button"
							: e.status == "Planned"
								? "brown-rounded-button"
								: "red-rounded-button"
							}`}
						style={{ width: 110 }}
					>
						{e.status}
					</div>
				</>
			),
		};
	});

	const columns = [
		{
			name: "Description",
			selector: (row) => (
				<div className="text-center">{row.description}</div>
			),
			wrap: true,
		},

		{
			name: "Flagged Status",
			selector: (row) => {
				return row.isFlaged && <img src={Flag} className="flag" />;
			},
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];
	const appointments_columns = [
		{
			name: "Date",
			selector: (row) => <div className="text-center">{row.date}</div>,
			wrap: true,
		},
		{
			name: "Service / Package",
			selector: (row) => <div className="text-center">{row.package}</div>,
			wrap: true,
		},
		{
			name: "Staff",
			selector: (row) => <div className="text-center">{row.staff}</div>,
			wrap: true,
		},
		{
			name: "Status",
			selector: (row) => <div className="text-center">{row.status}</div>,
			wrap: true,
		},
	];
	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];
	return (
		<>
			<div className="d-flex" style={{ zIndex: 99 }}>
				<div className="col-12 col-md-6">
					<div className="patient-card">
						<div className="grey font-400-20 font-bold appoint">
							Appointment
						</div>
						<div
							className="card-container d-flex"
							style={
								{
									// width: "30%",
								}
							}
						>
							<div
								className="profile-container px-5 d-flex justify-content-center py-4"
								style={{
									border: "1px solid #E9E9E9",
								}}
							>
								<div className="patientImg w-100 text-center grey">
									<p>{patient?.accountNumber}</p>

									<label className="myLabel d-flex justify-content-center ">
										<input
											type="file"
											required
											accept={[".png", ".jpg"]}
											// accept="image/*"
											onChange={(e) => {
												// uploadFileS3(e.target.files[0]);
												uploadImage(e.target.files[0]);
											}}
											id="upload-photo"
										/>
										<div className="dp-div">
											{loading && (
												<img
													className="cursor-pointer"
													style={{
														width: loading
															? "80%"
															: "100%",
													}}
													alt="loading"
													src={loader}
												/>
											)}

											<img
												className="cursor-pointer"
												style={{
													width:
														dp === "/imgs/user.png"
															? "50px"
															: "100%",
													display: loading
														? "none"
														: "flex",
												}}
												alt="user"
												key={dp}
												src={dp}
												ref={imageRef}
											/>
										</div>
									</label>

									<h5
										className="name w-100 text-center"
										onClick={() => {
											if (patient?._id) {
												dispatch(
													setPatientData(patient),
												);
												navigate("/add-patient");
											}
										}}
										style={{ cursor: "pointer", textDecoration: "underline" }}
									>
										{patient?.patientProfile?.fullName ||
											waitingData?.patient}
									</h5>
									<div className="alertBtn">
										<Button onClick={() => setShow(true)}>
											Patient Alert
										</Button>
									</div>
								</div>
							</div>
							<div
								className="details-container px-2 align-self-stretch "
								style={{
									width: "100%",
									border: "1px solid #E9E9E9",
								}}
							>
								<div className="addressDetail">
									<div className="detailPadding d-flex">
										<i className="fa fa-envelope"></i>
										<h6 className="ms-2">
											{patient?.patientProfile?.email
												? patient?.patientProfile?.email
												: "-"}
										</h6>
									</div>
									<div className="detailPadding d-flex">
										<i className="fa fa-phone"></i>
										<h6 className="ms-2">
											{patient?.patientProfile?.contactNo
												? patient?.patientProfile
													?.contactNo
												: "-"}
										</h6>
									</div>
									<div className="detailPadding d-flex">
										<i className="fa fa-book"></i>
										<h6 className="ms-2">
											{patient?.patientProfile?.fullName
												? patient?.patientProfile
													?.fullName
												: waitingData?.patient ?? "-"}
										</h6>
									</div>
									<div className="detailPadding d-flex">
										<i className="fa fa-map-marker"></i>
										<p className="ms-2">
											{patient?.patientProfile?.country &&
												patient?.patientProfile?.address
												? `${patient?.patientProfile?.country?.name}, ${patient?.patientProfile?.address}`
												: "-"}
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="pre_appointment">
						<div className="grey font-400-20 appoint">
							Previous Appointments
						</div>
						<div>
							<DataTable
								columns={appointments_columns}
								customStyles={customStyles}
								data={appointments_data || []}
								pagination
								conditionalRowStyles={conditionalRowStyles}
							/>
						</div>
					</div>
				</div>
				<Modal
					className="mt-5"
					size="lg"
					show={show}
					onHide={() => {
						setShow(false);
						// setFilteredData([]);
					}}
					centered
				>
					<Modal.Header
						closeButton
						className="pb-0"
						style={{ borderWidth: 0 }}
					>
						<div className="d-flex w-100 justify-content-center p-0 alert-heading">
							Patient Alert
						</div>
					</Modal.Header>
					<Modal.Body style={{ overflowY: "scroll", maxHeight: 400 }}>
						<div>
							<div className="bold mt-2">Patient Name</div>
							<div>{patient?.patientProfile?.fullName}</div>
							<div className="bold mt-2">Gender</div>
							<div>{patient?.patientProfile?.gender}</div>
							<div className="bold mt-2">National ID</div>
							<div>{patient?.patientProfile?.nationID}</div>
						</div>

						<Button
							className="my-3 px-4 memo-button"
							onClick={() => {
								setShow(false);
								setShow2(true);
							}}
						>
							+ Add Memo
						</Button>

						<div className="d-flex align-items-center mb-4">
							<input
								type="checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										setFilteredData([
											...data.filter((e) => e.isFlaged),
										]);
									} else {
										setFilteredData([]);
									}
								}}
							/>
							<label className="flag-check m-0">
								On show flagged
							</label>
						</div>
						<div>
							<DataTable
								columns={columns}
								customStyles={customStyles}
								data={data || []}
								pagination
								// dense
								conditionalRowStyles={conditionalRowStyles}
							/>
						</div>
					</Modal.Body>
				</Modal>

				<Modal
					size="sm"
					centered
					show={show2}
					onHide={() => {
						setShow2(false);
						setDescription("");
					}}
				>
					<Modal.Body>
						<div>
							<Form.Group className="mb-3">
								<Form.Label className="color-black">
									Description *
								</Form.Label>
								<InputGroup className="">
									<Form.Control
										placeholder="Write..."
										onChange={(e) =>
											setDescription(e.target.value)
										}
										value={description}
									/>
								</InputGroup>
							</Form.Group>
							<input
								type="checkbox"
								onChange={(e) => {
									if (e.target.checked) {
										setFlagged(true);
									} else {
										setFlagged(false);
									}
								}}
								checked={isFlaged}
							/>
							<label className="flag-check mb-4-0">Flagged</label>

							<Button
								className="memo-save-button mt-3"
								onClick={addMemo}
							>
								Save
							</Button>
						</div>
					</Modal.Body>
				</Modal>

				<DeleteModal
					content="Confirm Memo Deletion?"
					onYes={removeMemo}
					onNo={handleClose}
					show={show3}
				/>
				<div className="col-12 col-md-6 mx-2 patientDetails">
					<AddEditTemplate
						isSubComponent
						isModalOpen={isModalOpen}
						setRefreshAppointments={setRefreshAppointments}
						setRefreshWaiting={setRefreshWaiting}
						waitingData={waitingData}
						updatedDataId={updatedData?.id}
						onCancel={onCancel}
						setIsModalOpen={setIsModalOpen}
						setSchedularData={setSchedularData}
						schedularData={schedularData}
					/>
				</div>
			</div>
		</>
	);
}
