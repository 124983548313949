import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBranches } from "../../../../apis/branch";
import { getConsents } from "../../../../apis/consents";
import { createTreatments, updateTreatments } from "../../../../apis/treatment";
import { authorizedRequests } from "../../../../axios/axios-config";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../../helperFunctions/toastify";
import SelectComponent from "../../../SharedComponents/SelectComponent";
import { setFormData } from "../../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditTreatments() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState();
  const [consents, setConsents] = useState([]);
  const [selectedConsents, setSelectedConsents] = useState([]);
  const [isIncludeMembership, setIncludeMembership] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [location, setLocation] = useState();
  const [branch, setBranch] = useState();
  const [tagType, setTagType] = useState();
  const [tagOptions, setTagOptions] = useState([]);

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  useEffect(() => {
    if (!state?.treatment?._id) {
      formDataChangedRef.current = {
        name,
        description,
        price: price,
        code,
        consents: selectedConsents,
        isIncludeMembership,
        location,
        branch,
        tag: tagType,
      }
    }
  }, [name, description, price, code, selectedConsents, isIncludeMembership, 
    location, branch, tagType]);

  useEffect(() => {
    return () => {
      if (!state?.treatment?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.treatment?._id) {
      populateData();
    }
  }, []);

  const populateData = async () => {
    const reduxData = formData?.[pathname];
    if (reduxData && !state?.treatment?._id) {
      setName(reduxData?.name);
      setDescription(reduxData?.description);
      setPrice(reduxData?.price ?? 0);
      setCode(reduxData?.code);
      setIncludeMembership(reduxData?.isIncludeMembership);
      setBranch(reduxData?.branch);
      setLocation(reduxData?.location);
      setTagType(reduxData?.tag);

      setBranchLocation(reduxData?.branch);
      setSelectedConsents(reduxData?.consents ?? []);
    } else {
      setName(state?.treatment?.name);
      setDescription(state?.treatment?.description);
      setPrice(state?.treatment?.price);
      setCode(state?.treatment?.code);
      setIncludeMembership(state?.treatment?.isIncludeMembership);
      setBranch(state?.treatment?.branch);
      setLocation(state?.treatment?.location);
      setTagType(state?.treatment?.tag?._id);

      setBranchLocation(state?.treatment?.branch);

      let idStrings = [];
      state?.treatment?.consents?.forEach((e) => {
        idStrings.push(e._id);
      });
      setSelectedConsents(idStrings);
    }
  };

  const setBranchLocation = async(dataBranch) => {
    const response2 = await getBranches();
    if (response2?.status !== "Fail") {
    } else {
      toastify(response2?.message, "error");
    }

    let _branch = [...response2];
    _branch.forEach(function (item, i) {
      if (item?._id === dataBranch) {
        _branch.splice(i, 1);
        _branch.unshift(item);
      }
    });
    setAllBranches(_branch);

    let _data = response2?.find((e) => e._id === dataBranch);

    setAllLocations(_data?.location);
  };

  const saveTreatment = async () => {
    if (!name) {
      missingPopup("Name");
    } else if (!code) {
      missingPopup("Code");
    } else if (!branch) {
      missingPopup("Branch");
    } else if (!location) {
      missingPopup("Location");
    } else {
      if (!state?.treatment) {
        const response = await createTreatments({
          name,
          description,
          price: Number(price),
          code,
          consents: selectedConsents,
          isIncludeMembership,
          location,
          branch,
          tag: tagType,
        });
        if (response?.status !== "Fail") {
          formDataChangedRef.current = null;
          dispatch(setFormData({
            ...formData,
            [pathname]: null
          }));

          toastify("Treatment added successfully!", "success");
          navigate("/inventory-treatments");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateTreatments(state?.treatment?._id, {
          name,
          description,
          price,
          code,
          consents: selectedConsents,
          isIncludeMembership,
          location,
          branch,
          tag: tagType ? tagType : "",
        });
        if (response?.status !== "Fail") {
          toastify("Treatment updated successfully!", "success");
          navigate("/inventory-treatments");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const populateConsents = async () => {
    const response = await getConsents();
    if (response?.status !== "Fail") {
      setConsents(response);
    } else {
      toastify(response?.message, "error");
    }

    const response2 = await getBranches();
    if (response2?.status !== "Fail") {
      setAllBranches(response2);
    } else {
      toastify(response2?.message, "error");
    }
  };

  const getTitle = (id) => {
    let data = consents.find((e) => e._id === id);
    return data?.title;
  };

  useEffect(() => {
    populateConsents();
    if (state?.treatment) populateData();
  }, []);

  useEffect(() => {
    if (branch) {
      let _locations = allBranches?.find((e) => e._id === branch);
      setAllLocations(_locations?.location);
    }
  }, [branch]);
  useEffect(() => {
    (async () => {
      const { result, error } = await authorizedRequests({
        url: "tags/listOfTags",
        method: "get",
      });
      if (error) {
        toastify(error.message ?? "", "error");
        return;
      }

      const tempArr = result?.data?.map((o) => {
        return {
          value: o?._id,
          label: o.name,
        };
      });

      setTagOptions([...tempArr]);
    })();
  }, []);

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.treatment?._id,
      type: "Treatment",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.treatment ? "Edit " : "New "}`}
            Treatment
          </h1>
          {state?.treatment && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="headings2">Code *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="">
                  <Form.Label className="black-label-text">
                    Treatment Types
                  </Form.Label>
                  <SelectComponent
                    // isNullEnabled
                    // nullNotHidden
                    singleSelect
                    searchEnabled
                    selectedValue={tagType}
                    options={tagOptions}
                    onChange={(e) => {
                      setTagType(e.value);
                    }}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2 mt-3">Price *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setPrice(e.target.value)}
                      value={price}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Consents</Form.Label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val !== "" && !selectedConsents.includes(val)) {
                        setSelectedConsents((oldArr) => [...oldArr, val]);
                      }
                    }}
                  >
                    <option value={""}>Select</option>
                    {consents?.map((e) => (
                      <option value={e._id}>{e.title}</option>
                    ))}
                  </select>
                  <div className="d-flex flex-wrap">
                    {selectedConsents?.map((e) => (
                      <div className="multi-roles-parent">
                        {getTitle(e)}
                        <div className="multi-roles-child">
                          <i
                            className="fa-solid fa-xmark"
                            onClick={() => {
                              let _data = [...selectedConsents].filter(
                                (f) => f !== e
                              );
                              setSelectedConsents(_data);
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2">Branch *</Form.Label>
                  <SelectComponent
                    // isNullEnabled
                    // nullNotHidden
                    singleSelect
                    searchEnabled
                    adjustValues
                    selectedValue={branch}
                    options={allBranches}
                    onChange={(e) => {
                      setLocation(null);
                      setBranch(e.value);
                    }}
                  />
                  {/* <select
										className="form-select mb-3"
										onChange={(e) => {
											setBranch(e.target.value);
										}}
									>
										{!state?.treatment?.branch && (
											<option value="" selected disabled>
												Select Branch
											</option>
										)}
										{allBranches?.map((e) => (
											<option
												value={e._id}
												selected={branch === e._id}
											>
												{e.name}
											</option>
										))}
									</select> */}
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2">Location *</Form.Label>
                  <SelectComponent
                    // isNullEnabled
                    // nullNotHidden
                    singleSelect
                    searchEnabled
                    adjustValues
                    selectedValue={location}
                    options={allLocations?.length > 0 ? allLocations : []}
                    onChange={(e) => {
                      setLocation(e.value);
                    }}
                  />
                  {/* <select
										className="form-select mb-3"
										onChange={(e) => {
											setLocation(e.target.value);
										}}
									>
										{!state?.treatment?.location && (
											<option value="" selected disabled>
												Select Location
											</option>
										)}
										{allLocations?.map((e) => (
											<option
												value={e._id}
												selected={location === e._id}
											>
												{e.name}
											</option>
										))}
									</select> */}
                </Form.Group>
              </div>
              {/* <div className="ms-1 mt-3">
								<Form.Check
									type={"checkbox"}
									label="Include Membership"
									onClick={() =>
										setIncludeMembership(
											!isIncludeMembership,
										)
									}
									checked={isIncludeMembership === true}
								/>
							</div> */}
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveTreatment}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
