import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { adjustDateFormat } from "../../helperFunctions/utils";

const initialState = {
	allStaff: [],
	bucketList: [],
	appointmentsForRostering: [],
	appointmentReminderData: [],
	appointmentDataForSession: [],
	rosteringCardData: [],
	waitingListForRostering: [],
	reminderTemplates: [],
	selectedSession: null,
	allDataTableTemplates: [],
	currentDate: adjustDateFormat(new Date()),
	appointmentForm: {
		patient: "",
		branch: "",
		location: "",
		startTime: "09:00",
		endTime: "10:30",
		// package: "",
		staff: [],
		tag: [],
		status: "Planned",
		remarks: "",
		formStatus: "New",
		editId: null,
		products: [],
		// treatments: [],
		date: `${new Date()}`,
	},
	appointmentData: [],
	waitingListForm: {
		patientName: "",
		startTime: "09:00",
		endTime: "09:00",
		contactNumber: "",
		remarks: "",
		date: `${new Date()}`,
		gender: "Male",
		formStatus: "New",
	},
	waitingData: [],
	sessionForm: {
		appointment: null,
		branch: null,
		location: null,
		room: null,
		consents: [],
		staff: null,
		status: null,
		startTime: "09:00",
		endTime: "09:00",
		formStatus: "New",
		date: `${new Date()}`,
		dataId: null,
		patientMedicalHistory: {
			pastMedicalHistory: "",
			pastCosmeticHistory: "",
			pastAdverseCosmeticHistory: "",
			socialHistory: "",
			sideEffects: [
				{
					name: "sunExposure",
					isChecked: false,
					value: null,
				},
				{
					name: "smoking",
					isChecked: false,
					value: "",
				},
				{
					name: "alcohol",
					isChecked: false,
					value: "",
				},
			],
		},
		patientSOAP: {
			subjective: "",
			objective: "",
			assesment: "",
			plan: "",
			recommendedTreatment: "",
			treatmentDone: "",
		},
		patientConsents: [],
		patientPrescriptionSheet: [],
	},
	sessionData: [],
	billingForm: {
		formStatus: "New",
		isNew: true,
		branch: "",
		location: "",
		patient: "",
		session: "",
		categoryCost: [],
		recommended: null,
		campaign: null,
		remarks: "",
		campaignRemarks: "",
		showInvoice: false,
		dataId: null,
	},
	extraForms: {
		packages: [],
		products: [],
		services: [],
	},
	// extraBillingItems: {
	// 	products: [],
	// 	treatments: [],
	// 	services: [],
	// },
};

export const staffFormSlice = createSlice({
	name: "templateSlice",
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			state[payload.parent] = {
				...state[payload.parent],
				[payload.child]: payload.value,
			};
		},
		setAppointmentForm: (state, { payload }) => {
			state["appointmentForm"] = payload;
		},
		setWaitingListFormData: (state, { payload }) => {
			state["waitingListForm"] = payload;
		},
		setSubChild: (state, { payload }) => {
			state[payload.parent] = {
				...state[payload.parent],
				[payload.child]: {
					...state[payload.parent][payload.child],
					[payload.subChild]: payload.value,
				},
			};
		},
		setData: (state, { payload }) => {
			state[payload.parent] = payload.value;
		},
		resetValue: (state, { payload }) => {
			state[payload] = initialState[payload];
		},
	},
});

export const { setForm, setData, resetValue, setSubChild, setAppointmentForm, setWaitingListFormData } =
	staffFormSlice.actions;

export default staffFormSlice.reducer;
