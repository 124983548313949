import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../assets/images/index";
import { getPatients } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { createInfluencer, updateInfluencer } from "../../../apis/influencers";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function AddEditKol() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [joinDate, setJoinDate] = useState();
  const [patients, setPatients] = useState([]);
  const [show, setShow] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [selectedPatient, setSelectedPatient] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [show2, setShow2] = useState(false);
  const [objective, setObjective] = useState("");
  const [type, setType] = useState("");
  const [remarks, setRemarks] = useState("");

  const getPatientsList = async () => {
    const response = await getPatients();
    if (response.status !== "Fail") {
      setPatients(response);
    } else {
      toastify(response?.message, "error");
    }
  };

  const saveKol = async () => {
    if (!searchString) missingPopup("Name");
    else if (!type) missingPopup("Type");
    else if (!joinDate) missingPopup("Join Date");
    else {
      let data = {
        name: selectedPatient.patientProfile.fullName,
        type,
        joinDate,
        objective,
        remarks,
      };
      if (!state?.kol) {
        const response = await createInfluencer(data);
        if (response?.status !== "Fail") {
          toastify("KOL added successfully!", "success");
          navigate("/kol");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateInfluencer(state?.kol?._id, data);
        if (response?.status !== "Fail") {
          toastify("KOL updated successfully!", "success");
          navigate("/kol");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const populateKol = async () => {
    let data = state?.kol;

    const response = await getPatients();

    if (response?.list?.length > 0) {
      let _patient = response?.list?.find(
        (e) => e.patientProfile.fullName === data.name
      );
      if (_patient) {
        setSearchString(_patient?.patientProfile?.fullName);
        setSelectedPatient(_patient);
      }

      setObjective(data.objective);
      setType(data.type);
      setJoinDate(new Date(data.joinDate));
      setRemarks(data.remarks);
    }
  };

  useEffect(() => {
    if (searchString) {
      let f = patients.filter((e) =>
        e?.patientProfile?.fullName?.includes(searchString)
      );

      setFilteredData(f);
    } else {
      setFilteredData([]);
    }
  }, [searchString]);

  useEffect(() => {
    getPatientsList();
    if (state?.kol) populateKol();
  }, []);

  const handleClickAway = () => {
    if (show2) {
      setShow2(false);
    }
  };

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.kol ? "Edit " : "New "}`}
          Influencer
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex ">
                <Form.Group className="col-md-5 position-relative">
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3 p-0 search-group">
                    <Form.Control
                      onClick={() => setShow(true)}
                      placeholder="Select"
                      value={searchString}
                      onChange={(e) => setSearchString(e.target.value)}
                    />
                    <Button onClick={() => setShow(!show)}>
                      <img src={SearchIcon} />
                    </Button>
                  </InputGroup>
                  {show && (
                    <div className="search-box">
                      {(filteredData.length === 0
                        ? patients
                        : filteredData
                      )?.map((e) => (
                        <div
                          onClick={() => {
                            setSearchString(e?.patientProfile?.fullName);
                            setSelectedPatient(e);
                            setShow(false);
                          }}
                        >
                          {e?.patientProfile?.fullName}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Group>

                <span className="col-md-1"></span>

                <Form.Group className="col-md-5">
                  <Form.Label className="headings2">Objective</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Write..."
                      value={objective}
                      onChange={(e) => setObjective(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="d-flex my-3">
                <Form.Group className="col-md-5 mt-3">
                  <Form.Label className="headings2">Type *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-md-1"></span>

                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="col-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={joinDate}
                        onChange={(newValue) => {
                          setJoinDate(newValue?.$d);
                          setShow2(false);
                        }}
                        open={show2}
                        maxDate={new Date()}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                          <Form.Group className="col-5 mt-3">
                            <Form.Label className="headings2">
                              Join Date *
                            </Form.Label>
                            <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                              <Form.Control
                                ref={inputRef}
                                // {...inputProps}
                                value={
                                  joinDate
                                    ? new Date(joinDate).toLocaleDateString(
                                        "en-GB",
                                        {
                                          day: "numeric",
                                          month: "numeric",
                                          year: "numeric",
                                        }
                                      )
                                    : null
                                }
                                onClick={() => setShow2(!show2)}
                                readOnly
                                placeholder="Write..."
                              />
                              <div onClick={() => setShow2(!show2)}>
                                {InputProps?.endAdornment}
                              </div>
                            </InputGroup>
                          </Form.Group>
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </ClickAwayListener>
              </div>
              <Form.Group className="col-5">
                <Form.Label className="headings2">Remarks</Form.Label>
                <InputGroup>
                  <Form.Control
                    as="textarea"
                    style={{ resize: "none" }}
                    placeholder="Write..."
                    className="large-text-area"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                  />
                </InputGroup>
              </Form.Group>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={() => saveKol()}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
