import React, { useEffect, useMemo, useRef } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { Switch } from "antd";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import {
  setData,
  setForm,
  setAppointmentForm,
} from "../../../redux/slices/StaffFormsSlices";
import SelectComponent from "../../SharedComponents/SelectComponent";
import _ from "lodash";
import TimePicker from "react-bootstrap-time-picker";
import { requiredFieldValidation } from "../../../helperFunctions/validation";
import {
  adjustDateFormat,
  convertTimeIntoSeconds,
} from "../../../helperFunctions/utils";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactComponent as Exclamatory } from "../../../assets/images/exclamatory.svg";
import DateInput from "../../SharedComponents/DateInput";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import {
  loadOptionPatients,
  loadSelectedPatient,
} from "../../../apis/patients";
import { setFormData } from "../../../redux/slices/commonSlice";

export default function AddEditTemplate({
  isSubComponent,
  isModalOpen,
  onCancel,
  setIsModalOpen,
  updatedDataId,
  setSchedularData,
  schedularData,
  waitingData,
  setRefreshAppointments,
  setRefreshWaiting,
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const formDataChangedRef = useRef();
  const { state } = location;
  /////////////////Declarations\\\\\\\\\\\\\\\\

  const pathname = location.pathname;
  const { formData } = useSelector((state) => state.commonData);

  const { appointmentForm, rosteringCardData } = useSelector(
    (state) => state.staffForm
  );

  const [appointmentFormData, setAppointmentFormData] = useState({
    ...appointmentForm,
  });
  const [localState, setLocalStates] = useState({
    // patients: [null],
    staff: appointmentFormData.staff || [],
    treatments: appointmentFormData.treatments || [],
    products: appointmentFormData.products || [],
    tags: appointmentFormData.tag || [],
    otherTags: appointmentFormData.otherTags || [],
  });
  const [allAppointments, setAllAppointments] = useState([]);
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [warning, setWarning] = useState([]);
  const [options, setOptions] = useState({
    patients: [],
    locations: [],
    branches: [],
    services: [],
    tags: [],
    staff: [],
    medications: [],
    treatments: [],
    statuses: [
      {
        label: "Planned",
        value: "Planned",
      },
      {
        label: "Confirmed",
        value: "Confirmed",
      },
      {
        label: "Cancelled",
        value: "Cancelled",
      },
      {
        label: "Not Show",
        value: "Not Show",
      },
    ],
  });
  const [showED, setShowED] = useState(false);
  const [holidayDates, setHolidayDates] = useState([]);
  const [selectedOptPatient, setSelectedOptPatient] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (!state?.data?.editId && !isModalOpen) {
      formDataChangedRef.current = {
        ...appointmentFormData,
        staff: localState.staff
      }
    }
  }, [appointmentFormData, localState.staff]);

  useEffect(() => {
    return () => {
      if (!state?.data?.editId && !isModalOpen && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.data?.editId && !isModalOpen) {
      const reduxData = formData?.[pathname];
      if (reduxData) {
        dispatch(
          setData({
            parent: "appointmentForm",
            value: reduxData,
          })
        );
      }
    }
  }, []);

  const shouldDisabledDate = (date) => {
    if (!date?.$d) {
      return true;
    }
    if (!(date?.$d instanceof Date && !isNaN(date?.$d))) {
      return true;
    }
    const dates = holidayDates?.some(
      (disabledDate) =>
        disabledDate.getDate() === date?.$d?.getDate() &&
        disabledDate.getMonth() === date?.$d?.getMonth() &&
        disabledDate.getFullYear() === date?.$d?.getFullYear()
    );
    return dates;
  };

  /////////////////functions\\\\\\\\\\\\\\\\\\\\
  const onFormChange = (child, value) => {
    // const { name, value } = e.target;
    setAppointmentFormData((prev) => ({ ...prev, [child]: value }));
    if (child === "patient") {
      dispatch(setForm({ parent: "appointmentForm", child, value }));
    }
  };
  const onLocalChange = (state, value) => {
    var tempArr = [...localState[state]];
    tempArr = value;

    setLocalStates({ ...localState, [state]: [...tempArr] });
  };
  // const onObjChange = (state, name, value, index) => {
  // 	var tempObj = [...localState[state]][index];
  // 	tempObj = { ...tempObj, [name]: value };
  // 	onLocalChange(state, tempObj, index);
  // };

  // const addNewRow = (state, value) => {
  // 	const tempArr = [...localState[state]];
  // 	tempArr.push(value);
  // 	setLocalStates({ ...localState, [state]: [...tempArr] });
  // };
  const checkTimeValidation = () => {
    const startTime = convertTimeIntoSeconds(appointmentFormData.startTime);
    if (appointmentFormData.startTime == appointmentFormData.endTime) {
      return;
    }
    const findApp = allAppointments.find((o) => {
      if (
        startTime == o.startTime ||
        (startTime > o.startTime &&
          startTime < o.endTime &&
          o.date == new Date(appointmentFormData.date).toLocaleDateString())
      ) {
        const findStaff = o.staff.find((k) => {
          if (localState?.staff?.find((m) => m == k._id)) {
            return true;
          }
        });
        const tempArr = [...warning];

        if (findStaff) {
          tempArr.push(
            `${findStaff.username} got appointment at ${o.startTimeFormat} - ${o.endTimeFormat}`
          );
          setWarning([...new Set([...tempArr])]);
        }
        if (appointmentFormData?.patient == o.patient) {
          tempArr.push(
            `${o.patientName} got appointment at ${o.startTimeFormat} - ${o.endTimeFormat}`
          );
          setWarning([...new Set([...tempArr])]);
        }
      }
    });
  };
  const formValidation = (toVerify) => {
    // if (
    // 	!toVerify.branch ||
    // 	!toVerify.patient ||
    // 	!toVerify.location ||
    // 	!toVerify.tag ||
    // 	!toVerify.startTime ||
    // 	!toVerify.endTime ||
    // 	!toVerify.package
    // )
    // 	return { status: false, message: "required fields can't be empty" };
    if (toVerify.staff.length < 1)
      return { status: false, message: "Atleast 1 staff is required" };

    return { status: true };
  };

  const formatTime = (timeStr) => {
    let timeArray = timeStr.split(":");
    let hours = timeArray[0];
    let minutes = timeArray[1];

    if (hours.length === 1) {
      hours = "0" + hours;
    }

    return hours + ":" + minutes;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    dispatch(setAppointmentForm(appointmentFormData));
    const formObj = {
      ...appointmentFormData,
      // patient: localState.patients.filter((o) => o),
      staff:
        appointmentFormData?.staff?.length === 0
          ? localState.staff?.filter((o) => o)
          : appointmentFormData?.staff?.filter((o) => o),
      treatments: appointmentFormData?.treatments?.filter((o) => o),
      products: appointmentFormData?.products?.filter((o) => o),
      tag: appointmentFormData?.tag?.map((o) => o?.value),
      otherTags: appointmentFormData?.otherTags?.filter((o) => o),
      startTime: formatTime(appointmentFormData.startTime),
      endTime: formatTime(appointmentFormData.endTime),
    };
    delete formObj.formStatus;
    // 	!toVerify.branch ||
    // 	!toVerify.patient ||
    // 	!toVerify.location ||
    // 	!toVerify.tag ||
    // 	!toVerify.startTime ||
    // 	!toVerify.endTime ||
    // 	!toVerify.package
    if (formObj.startTime == formObj.endTime) {
      toastify("Time in can't be equal to Time out", "error");
      return;
    }
    if (!formObj.status) {
      toastify("Please select status", "error");
      return;
    }

    if (formObj.endTime <= formObj.startTime) {
      toastify("Start time can't be greater than end time");
      return;
    }
    if (formObj.startTime >= formObj.endTime) {
      toastify("End time can't be less than start time");
      return;
    }

    const checkRequiredFields = requiredFieldValidation(
      {
        name: "Branch",
        value: formObj.branch,
      },
      {
        name: "Patient",
        value: isNewPatient ? "New Patient" : formObj.patient,
      },
      {
        name: "Location",
        value: formObj.location,
      },
      {
        name: "Tag",
        value: formObj.tag,
      },
      {
        name: "Start Time",
        value: formObj.startTime,
      },
      {
        name: "End Time",
        value: formObj.endTime,
      },
      // {
      // 	name: "Package",
      // 	value: formObj.package,
      // },
      {
        name: "Date",
        value: formObj.date,
      }
    );

    if (!checkRequiredFields.status) {
      toastify(checkRequiredFields.message, "error");
      return;
    }

    const validation = formValidation(formObj);
    if (!validation.status) {
      toastify(validation.message, "error");
      return;
    }

    // create patient, if a new patient
    if (isNewPatient) {
      const { result, error } = await authorizedRequests({
        url: "patient/addPatient",
        method: "post",
        data: {
          isBooking: true,
          needQuestionnaire: true,
          patientProfile: {
            fullName: appointmentFormData.patientName,
            contactNo: appointmentFormData.contactNo,
          },
        },
      });
      if (error) {
        toastify(error.message ?? "unknown error occured", "error");
        return;
      }
      formObj.patient = result?.data?._id;
    }

    if (
      (location?.state?.isFromWaiting || waitingData?.isFromWaiting) &&
      formObj.patient == -1
    ) {
      const { result, error } = await authorizedRequests({
        url: "patient/addPatient",
        method: "post",
        data: {
          isBooking: true,
          needQuestionnaire: true,
          patientProfile: {
            fullName: location?.state?.isFromWaiting
              ? location?.state?.patient
              : waitingData?.patient,
            contactNo: location?.state?.contactNo
              ? location?.state?.contactNo
              : waitingData?.contactNo,
          },
        },
      });
      if (error) {
        toastify(error.message ?? "unknown error occured", "error");
        return;
      }
      formObj.patient = result?.data?._id;
      const { result: delWaitingRes, error: delWaitingErr } =
        await authorizedRequests({
          url: "waitingList/updateWaitingList",
          method: "post",
          data: {
            dataId: location?.state?.isFromWaiting
              ? location?.state?.waitingId
              : waitingData.waitingId,
            isRegisterForAppointment: true,
          },
        });
      if (delWaitingErr) {
        toastify(delWaitingErr.message ?? "unknown error occured", "error");
      }
    }
    if (appointmentFormData.editId) {
      const { result, error } = await authorizedRequests({
        url: "appointment/updateAppointment",
        method: "post",
        data: { ...formObj, dataId: appointmentFormData.editId },
      });
      if (error) {
        toastify(error.message ?? "unknown error occured", "error");
        return;
      }
      if (isSubComponent) {
        const newDataArr = rosteringCardData.map((o) => {
          if (o._id == updatedDataId) {
            return {
              ...o,
              startTime: formObj.startTime,
              endTime: formObj.endTime,
              remarks: formObj.remarks,
              patientName: selectedOptPatient?.patientProfile?.fullName,
              completeStaffList: options.staff
                .filter((k) => formObj.staff.find((m) => m == k.value))
                .map((k) => {
                  return {
                    username: k.label.split("(")[0],
                    id: k.value,
                  };
                }),
            };
          } else return o;
        });
        const schedularArr = schedularData.map((o) => {
          const s_time = formObj.startTime.split(":");
          const s_h = s_time[0];
          const s_m = s_time[1];

          const e_time = formObj.endTime.split(":");
          const e_h = e_time[0];
          const e_m = e_time[1];
          if (o.id == appointmentFormData.editId) {
            return {
              ...o,
              startDate: new Date(new Date(formObj.date).setHours(s_h, s_m)),
              endDate: new Date(new Date(formObj.date).setHours(e_h, e_m)),
            };
          }
          return o;
        });
        setSchedularData(schedularArr);
        dispatch(
          setData({
            parent: "rosteringCardData",
            value: newDataArr,
          })
        );
        if (setRefreshAppointments) {
          setRefreshAppointments((val) => !val);
        }
        setIsModalOpen(false);

        return;
      }
      navigate(-1);
      return;
    }
    const { result, error } = await authorizedRequests({
      url: "appointment/createAppointment",
      method: "post",
      data: formObj,
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    formDataChangedRef.current = null;
    dispatch(setFormData({
      ...formData,
      [pathname]: null
    }));

    if (isSubComponent) {
      const newDataArr = rosteringCardData.map((o) => {
        if (o._id == updatedDataId) {
          return {
            ...o,
            startTime: formObj.startTime,
            endTime: formObj.endTime,
            patientName: selectedOptPatient?.patientProfile?.fullName,
            remarks: formObj.remarks,
            completeStaffList: options.staff
              .filter((k) => formObj.staff.find((m) => m == k.value))
              .map((k) => {
                return {
                  username: k.label,
                  id: k.value,
                };
              }),
          };
        } else return o;
      });
      const schedularArr = schedularData.map((o) => {
        const s_time = formObj.startTime.split(":");
        const s_h = s_time[0];
        const s_m = s_time[1];

        const e_time = formObj.endTime.split(":");
        const e_h = e_time[0];
        const e_m = e_time[1];
        if (o.id == updatedDataId) {
          return {
            ...o,
            startDate: new Date(new Date(formObj.date).setHours(s_h, s_m)),
            endDate: new Date(new Date(formObj.date).setHours(e_h, e_m)),
          };
        }
        return o;
      });
      setSchedularData(schedularArr);
      dispatch(
        setData({
          parent: "rosteringCardData",
          value: newDataArr,
        })
      );
      if (setRefreshAppointments) {
        setRefreshAppointments((val) => !val);
      }
      if (setRefreshWaiting) {
        setRefreshWaiting((val) => !val);
      }
      setIsModalOpen(false);

      return;
    }
    navigate(-1);
  };

  const getPatientsList = async () => {
    // const { result, error } = await authorizedRequests({
    //   url: "patient/listOfPatient",
    //   method: "get",
    //   params: { isBooking: true },
    // });
    // if (error) {
    //   toastify(error.message ?? "");
    //   return;
    // }
    // let tempArr = result?.data?.list?.map((patient) => {
    //   return {
    //     label: patient?.patientProfile?.fullName,
    //     value: patient?._id,
    //   };
    // });
    // if (location?.state?.isFromWaiting || waitingData?.isFromWaiting) {
    //   tempArr.push({
    //     label: location?.state?.isFromWaiting
    //       ? location?.state?.patient
    //       : waitingData.patient,
    //     value: -1,
    //   });
    //   // onFormChange("patient", -1);
    // }
    // setOptions((val) => {
    //   return { ...val, patients: tempArr };
    // });
  };
  const getLocationsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "branch/listOfBranch",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "");
      return;
    }
    var tempArr = result.data.map((o) => {
      return {
        value: o._id,
        label: o.name,
        location: o.location,
      };
    });

    setOptions((val) => {
      return { ...val, branches: tempArr };
    });
  };

  const getServicesList = async () => {
    const { result, error } = await authorizedRequests({
      url: "package/listOfPackages",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "");
      return;
    }
    const tempArr = result?.data?.map((o) => {
      return {
        label: o.name,
        value: o?._id,
      };
    });
    setOptions((val) => {
      return { ...val, services: tempArr };
    });
  };

  const getTagsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "tags/listOfTags",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }

    const tempArr = result?.data?.map((o) => {
      return {
        value: o?._id,
        label: o.name,
      };
    });

    setOptions((val) => {
      return { ...val, tags: tempArr };
    });
  };
  const getMedicationsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "product/listOfProducts",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get products list", "error");
      return;
    }
    setOptions((val) => {
      return {
        ...val,
        medications: result.data.map((o) => {
          return {
            value: o._id,
            label: o.name,
          };
        }),
      };
    });
  };
  const getTreatmentsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "treatment/listOfTreatment",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "Failed to get products list", "error");
      return;
    }
    setOptions((val) => {
      return {
        ...val,
        treatments: result.data.map((o) => {
          return {
            value: o._id,
            label: o.name,
          };
        }),
      };
    });
  };
  const getStaffList = async () => {
    const { result, error } = await authorizedRequests({
      url: "users/staffList",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }

    const defaultStaff = result?.data
      ?.filter((o) => o.defaultAppt === true)
      ?.map((o) => o._id);

    const tempArr = result?.data
      ?.filter((o) => o.role?.length !== 1 || o.role.indexOf("admin") == -1)
      ?.map((o) => {
        return {
          value: o?._id,
          label: `${o.username} (${o.role})`,
        };
      });

    // if (!location.state?.valueObj?.patient) {
    //   setLocalStates({ ...localState, staff: defaultStaff });
    // }

    setOptions((val) => {
      return { ...val, staff: tempArr };
    });
  };

  const getAppointments = async () => {
    const { result, error } = await authorizedRequests({
      url: "appointment/listOfAppointments",
      method: "get",
      params: {
        date: new Date(),
        status: "Planned",
      },
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }
    const tempArr = result.data.map((o) => {
      return {
        startTimeFormat: o.startTime,
        endTimeFormat: o.endTime,
        startTime: convertTimeIntoSeconds(o.startTime),
        endTime: convertTimeIntoSeconds(o.endTime),
        patient: o?.patient?._id,
        patientName: o?.patient?.patientProfile?.fullName,
        staff: o.staff,
        date: new Date(o.date).toLocaleDateString(),
      };
    });

    setAllAppointments(tempArr);
  };
  const handleClickAway1 = () => {
    setShowED(false);
  };

  const getListOfHolidays = async () => {
    const { result, error } = await authorizedRequests({
      url: "holidays/listOfHolidays",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }
    const dates = result.data?.map((d) => {
      return new Date(d.eventDate);
    });
    setHolidayDates(dates);
  };
  // const deleteRow = (state, index) => {
  // 	var tempArr = localState[state];
  // 	if (tempArr.length == 1) {
  // 		toastify("This field requires atleast 1 item", "error");
  // 		return;
  // 	}

  // 	tempArr = tempArr.filter((_, i) => {
  // 		return i !== index;
  // 	});
  // 	setLocalStates({ ...localState, [state]: tempArr });
  // };
  ////////////////////hooks\\\\\\\\\\\\\\\\\\\\
  useEffect(() => {
    getPatientsList();
    getLocationsList();
    getServicesList();
    getTagsList();
    getStaffList();
    getMedicationsList();
    getTreatmentsList();
    getAppointments();
    getListOfHolidays();
  }, []);

  useEffect(() => {
    if (!state?.data?.editId && !isModalOpen) {
      setAppointmentFormData({
        ...appointmentForm,
        tag: appointmentForm.tag,
        otherTags: appointmentForm.otherTags,
      });
    } else {
      setAppointmentFormData({
        ...appointmentForm,
        tag: (appointmentForm?.tag ? appointmentForm?.tag : [])?.map((x) => ({
          value: x._id,
          label: x.name,
        })),
        otherTags: appointmentForm.otherTags?.map((x) => x?._id),
      });
    }
  }, [appointmentForm]);

  useMemo(() => {
    if (appointmentFormData?.staff?.length > 0) {
      setLocalStates((val) => {
        return { ...val, staff: appointmentFormData.staff };
      });
    }
  }, [appointmentFormData.staff]);
  useMemo(() => {
    if (appointmentFormData?.treatments) {
      setLocalStates((val) => {
        return { ...val, treatments: appointmentFormData.treatments };
      });
    }
  }, [appointmentFormData.treatments]);
  useMemo(() => {
    if (appointmentFormData?.products) {
      setLocalStates((val) => {
        return { ...val, products: appointmentFormData.products };
      });
    }
  }, [appointmentFormData.products]);
  useEffect(() => {
    if (appointmentFormData.branch) {
      var tempArr = options.branches.find(
        (o) => o.value == appointmentFormData.branch
      );

      if (tempArr) {
        tempArr = tempArr.location.map((o) => {
          return {
            value: o._id,
            label: o.name,
            staff: o.staff
          };
        });
        setOptions((val) => {
          return { ...val, locations: tempArr || [] };
        });
      }
    }
  }, [appointmentFormData.branch, options.branches]);
  useEffect(() => {
    if (location?.state?.valueObj) {
      dispatch(
        setData({
          parent: "appointmentFormData",
          value: location.state.valueObj,
        })
      );
    }
  }, [location?.state?.valueObj]);
  useMemo(() => {
    if (
      (appointmentFormData.patient &&
        appointmentFormData.startTime &&
        appointmentFormData.date) ||
      (appointmentFormData.staff.length > 0 &&
        appointmentFormData.startTime &&
        appointmentFormData.date)
    )
      checkTimeValidation();
  }, [
    appointmentFormData.startTime,
    appointmentFormData.endTime,
    localState.staff,
    appointmentFormData.patient,
    appointmentFormData.date,
  ]);

  useEffect(() => {
    if (options.branches?.length > 0) {
      if (appointmentForm.branch === "") {
        const selectedBranch = options.branches[0];
        dispatch(
          setForm({
            parent: "appointmentForm",
            child: "branch",
            value: selectedBranch?.value,
          })
        );

        if (selectedBranch?.location?.length > 0) {
          setLocalStates({ ...localState, staff: selectedBranch?.location[0]?.staff ? selectedBranch?.location[0]?.staff : [] });

          dispatch(
            setForm({
              parent: "appointmentForm",
              child: "location",
              value: selectedBranch?.location[0]?._id,
            })
          );
        }
      }
    }
  }, [options.branches]);

  return (
    <>
      <div className="mainHeading px-4">
        {!isSubComponent && (
          <>
            <h1>
              <i
                className="fa fa-arrow-left cursor-pointer"
                onClick={() => {
                  navigate(-1);
                }}
              ></i>
              {`${appointmentFormData.formStatus + " "}`}
              Appointment
            </h1>
          </>
        )}
        {isSubComponent && (
          <div
            className="w-100 d-flex justify-content-end "
            style={{ marginBottom: "-30px" }}
          >
            <i
              className="fa fa-xmark cursor-pointer"
              style={{ fontSize: "28px" }}
              onClick={() => {
                onCancel();
              }}
            ></i>
          </div>
        )}
      </div>
      <div className={isSubComponent ? "" : "pb-4 px-4"}>
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <Form.Group className="col-md-12">
                <Form.Label className="black-label-text">
                  Is New Patient ?
                </Form.Label>
                <div style={{ marginLeft: "10px", display: "inline-block" }}>
                  <Switch
                    checked={isNewPatient}
                    onChange={() => setIsNewPatient(!isNewPatient)}
                  />
                </div>
              </Form.Group>

              {!isNewPatient ? (
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text required">
                    Patient
                  </Form.Label>
                  <div style={{}}>
                    <AsyncSelect
                      value={appointmentForm.patient}
                      setValue={(value) => {
                        onFormChange("patient", value._id);
                        setSelectedOptPatient(value);
                      }}
                      loadOptions={(search, loadedOptions, { page }) =>
                        loadOptionPatients(search, loadedOptions, { page })
                      }
                      loadSelectedOption={loadSelectedPatient}
                    />
                  </div>
                </Form.Group>
              ) : (
                <React.Fragment>
                  <Form.Group className={`col-md-6`}>
                    <Form.Label className="black-label-text">
                      Patient Name *
                    </Form.Label>
                    <InputGroup className="mb-3 ">
                      <Form.Control
                        name={"patientName"}
                        placeholder="Write..."
                        style={{ resize: "none" }}
                        onChange={(e) => {
                          onFormChange("patientName", e.target.value);
                        }}
                        value={appointmentFormData.patientName}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className={`col-md-6`}>
                    <Form.Label className="black-label-text">
                      Contact No *
                    </Form.Label>
                    <InputGroup className="mb-3 ">
                      <Form.Control
                        name={"contactNo"}
                        placeholder="Write..."
                        style={{ resize: "none" }}
                        onChange={(e) => {
                          onFormChange("contactNo", e.target.value);
                        }}
                        value={appointmentFormData.contactNo}
                      />
                    </InputGroup>
                  </Form.Group>
                </React.Fragment>
              )}
              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Branch
                </Form.Label>
                <SelectComponent
                  // isNullEnabled
                  singleSelect
                  searchEnabled
                  className=""
                  selectedValue={appointmentFormData.branch}
                  options={options.branches}
                  onChange={(e) => {
                    onFormChange("branch", e.value);
                    onFormChange("location", null);
                  }}
                  // style={{ width: "75%" }}
                  name={"branch"}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Location
                </Form.Label>

                <SelectComponent
                  // isNullEnabled
                  singleSelect
                  searchEnabled
                  className=""
                  selectedValue={appointmentFormData.location}
                  options={options.locations}
                  onChange={(e) => {
                    onFormChange("location", e.value);
                    setLocalStates({ ...localState, staff: e.staff ? e.staff : [] });
                  }}
                  // style={{ width: "75%" }}
                  name={"location"}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <div className="">
                  <label>Date *</label>
                  <div className="date align-self-center white cursor-pointer mb-3">
                    <div>
                      <DateInput
                        inputClassName="themeInput"
                        value={appointmentFormData.date}
                        onChange={(newValue) => {
                          onFormChange("date", newValue);
                          // setSValidity(newValue?.$d);
                          setShowED(false);
                        }}
                        shouldDisableDates={true}
                      />
                    </div>
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Time Start
                </Form.Label>
                <div
                  className="d-flex mb-3"
                  // style={{ width: "75%" }}
                >
                  <TimePicker
                    start="09:00"
                    end="21:00"
                    step={30}
                    selectedValue={appointmentFormData.startTime}
                    // disabled={appointmentFormData.startTime}
                    className="modalTextField"
                    style={{
                      paddingRight: "6px",
                      height: "50px",
                      border: "1.4px solid #939393",
                    }}
                    value={appointmentFormData.startTime}
                    onChange={(e) => {
                      let h = Math.floor(e / 3600);
                      h = h < 10 ? "0" + h : h;

                      let m = Math.floor((e % 3600) / 60);
                      m = m < 10 ? "0" + m : m;

                      onFormChange("startTime", `${h}:${m}`);

                      // Adding 1 hour and 30 minutes to the input time
                      let totalSeconds = e + (1 * 3600) + (30 * 60);

                      let h_end = Math.floor(totalSeconds / 3600);
                      h_end = h_end < 10 ? "0" + h_end : h_end;

                      let m_end = Math.floor((totalSeconds % 3600) / 60);
                      m_end = m_end < 10 ? "0" + m_end : m_end;

                      // Update end time
                      if (h_end >= 21 && m_end >= 0) {
                        onFormChange("endTime", `21:00`);
                      } else {
                        onFormChange("endTime", `${h_end}:${m_end}`);
                      }
                    }}
                  />
                </div>
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Time End
                </Form.Label>
                <div
                  className="d-flex mb-3"
                  // style={{ width: "75%" }}
                >
                  <TimePicker
                    start="09:00"
                    end="21:00"
                    step={30}
                    selectedValue={appointmentFormData.endTime}
                    // disabled={appointmentFormData.startTime}
                    className="modalTextField"
                    style={{
                      paddingRight: "6px",
                      height: "50px",
                      border: "1.4px solid #939393",
                    }}
                    value={appointmentFormData.endTime}
                    onChange={(e) => {
                      let h = Math.floor(e / 3600);
                      h = h < 10 ? "0" + h : h;
                      let m = Math.floor((e % 3600) / 60);
                      m = m < 10 ? "0" + m : m;
                      onFormChange("endTime", `${h}:${m}`);
                    }}
                  />
                </div>
              </Form.Group>
              <div className="mb-3 col-12">
                {warning.length > 0 &&
                  warning?.map((o, i) => {
                    return (
                      <>
                        <div className="d-flex  mb-1 ">
                          <Exclamatory
                            className={`align-self-center ${
                              i != 0 ? "invisible" : ""
                            }`}
                          />
                          <div
                            className="ms-2 w-100 py-2 px-2"
                            style={{
                              color: "#E7653D",
                              background: "#F8F8F8",
                            }}
                          >
                            {o}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>

              {/* <Form.Group
									className={`${
										isSubComponent
											? "col-md-12"
											: "col-md-6"
									}`}
								>
									<Form.Label className="black-label-text required">
										Services/Packages
									</Form.Label>
									<SelectComponent
										isNullEnabled
										options={options.services}
										selectedValue={appointmentFormData.package}
										onChange={(e) => {
											onFormChange(
												"package",
												e.target.value,
											);
										}}
										style={{ width: "75%" }}
										name={"package"}
									/>
								</Form.Group> */}
              {/* <Form.Group
									className="col-md-6"
								>
									<Form.Label className="black-label-text">
										Medications
									</Form.Label>
									<div className="d-flex ">
										<div
											className=" "
											style={{
												width: "75%",
												flexDirection: "column",
											}}
										>
											<>
												<SelectComponent
													isNullEnabled
													searchEnabled
													options={
														options.medications
													}
													onChange={(value) => {
														onLocalChange(
															"products",
															value,
														);
													}}
													array={localState.products}
													name={"products"}
												/>
											</>
										</div>
									</div>
								</Form.Group> */}
              {/* <Form.Group
									className={`${
										isSubComponent
											? "col-md-12"
											: "col-md-6"
									}`}
								>
									<Form.Label className="black-label-text">
										Treatments
									</Form.Label>
									<div className="d-flex ">
										<div
											className=" "
											style={{
												width: "75%",
												flexDirection: "column",
											}}
										>
											<>
												<SelectComponent
													isNullEnabled
													searchEnabled
													options={options.treatments}
													onChange={(value) => {
														onLocalChange(
															"treatments",
															value,
														);
													}}
													array={
														localState.treatments
													}
													name={"treatments"}
												/>
											</>
										</div>
									</div>
								</Form.Group> */}
              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Staff
                </Form.Label>
                <div className="d-flex ">
                  <div
                    className=" "
                    style={{
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <>
                      <SelectComponent
                        isNullEnabled
                        searchEnabled
                        options={options.staff}
                        onChange={(value) => {
                          onFormChange("staff", value);
                        }}
                        array={
                          appointmentFormData?.staff?.length === 0
                            ? localState.staff
                            : appointmentFormData?.staff
                        }
                        name={"staff"}
                      />
                    </>
                  </div>

                  {/* <Button
											className="my-add-button mb-2 "
											style={{ marginLeft: "2%", maxHeight: "45px" }}
											onClick={() => {
												addNewRow("staff", null);
											}}
										>
											+ Add Staff
										</Button> */}
                </div>
              </Form.Group>

              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Main Treatment Types
                </Form.Label>
                <SelectComponent
                  // isNullEnabled
                  singleSelect
                  searchEnabled
                  className=""
                  selectedValue={
                    appointmentFormData.tag?.length > 0
                      ? appointmentFormData.tag[0].value
                      : null
                  }
                  options={options.tags}
                  onChange={(value) => {
                    onFormChange("tags", [value]);
                    onFormChange("tag", [value]);
                  }}
                  // style={{ width: "75%" }}
                  name={"tag"}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text">
                  Other Treatment Types
                </Form.Label>
                <SelectComponent
                  isNullEnabled
                  searchEnabled
                  options={options.tags}
                  className=""
                  onChange={(value) => {
                    onFormChange("otherTags", value);
                  }}
                  array={appointmentFormData?.otherTags ?? []}
                  // style={{ width: "75%" }}
                  name={"tag"}
                />
              </Form.Group>
              <Form.Group className="col-md-6">
                <Form.Label className="black-label-text required">
                  Add Status
                </Form.Label>
                <SelectComponent
                  // isNullEnabled
                  singleSelect
                  searchEnabled
                  className=""
                  options={options.statuses}
                  onChange={(e) => {
                    onFormChange("status", e.value);
                  }}
                  name={"status"}
                  selectedValue={appointmentFormData.status}
                />
              </Form.Group>
              <Form.Group className="col-md-12">
                <Form.Label className="black-label-text">Remarks</Form.Label>
                <InputGroup className="mb-3 ">
                  <Form.Control
                    name={"remarks"}
                    as="textarea"
                    rows={3}
                    placeholder="Write..."
                    style={{ resize: "none" }}
                    onChange={(e) => {
                      onFormChange("remarks", e.target.value);
                    }}
                    value={appointmentFormData.remarks}
                  />
                </InputGroup>
              </Form.Group>
              {/* <Button
									className="my-add-button mb-4 "
									style={{
										height: "35px",
										fontSize: "14px",
										fontWeight: 600,
									}}
									onClick={() => {
										if (appointmentFormData?.patient) {
											window
												.open(
													`/questions/${appointmentFormData?.patient}`,
													"_blank",
												)
												.focus();
										} else {
											toastify(
												"Please select a patient first",
											);
										}
									}}
								>
									Fill Questionnaire
								</Button> */}
            </div>
            <div
              className=" d-flex align-self-center"
              style={{ marginTop: "10px" }}
            >
              <Button
                className="addItem"
                style={{ width: "250px" }}
                onClick={submitForm}
              >
                Save
              </Button>
              {isSubComponent && (
                <>
                  <Button
                    className="addItem ms-2"
                    style={{
                      width: "250px",
                      background: "#FFFFFF",
                      border: "2px solid #939393",
                      borderRadius: "10px",
                      color: "#939393",
                    }}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
