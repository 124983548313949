import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { deletePatient, getPatients, uploadCsv } from "../../../apis/patients";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { setTableSearch, setTablePaginate } from "../../../redux/slices/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { toastify } from "../../../helperFunctions/toastify";
import { checkPermission } from "../../../helperFunctions/checkPermission";
// import CsvDownloadButton from "react-json-to-csv";
import Papa from "papaparse";
import moment from "moment";

import FilterPaginate from "../../SharedComponents/FilterPaginate";
import DeleteModal from "../../SharedComponents/DeleteModal";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
} from "../../../helperFunctions/utils";

function Patient() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.value);
  const { tableSearch, tablePaginate } = useSelector((state) => state.commonData);

  const pathname = location.pathname;

  const getDefaultFilteredData = () => {
    let newQuery = {};
    const fieldPathname = pathname.replace("/", "");

    if (tableSearch[fieldPathname]) {
      if (tableSearch[fieldPathname].AccountNumber) {
        newQuery.accountNumber = tableSearch[fieldPathname].AccountNumber;
      }
      if (tableSearch[fieldPathname].PatientName) {
        newQuery['patientProfile.fullName'] = tableSearch[fieldPathname].PatientName;
      }
      if (tableSearch[fieldPathname].ContactNo) {
        newQuery['patientProfile.contactNo'] = tableSearch[fieldPathname].ContactNo;
      }
    }

    return newQuery;
  }

  const [patients, setPatients] = useState([]);
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [filteredData, setFilteredData] = useState(getDefaultFilteredData());
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDoc, setTotalDoc] = useState(0);

  const getData = () => {
    let _data = [...patients].map((e) => {
      return {
        accountNumber: e.accountNumber,
        ...e.patientProfile,
        emergencyContact: e.emergencyContact
          ? `${e.emergencyContact.name || "Unknown Name"} - ${
              e.emergencyContact.relation || "Unknown Relation"
            } - ${e.emergencyContact.contactNo || "Unknown Contact"}`
          : "null",
        createdAt: e.created_at,
        isActive: e.isActive,
        isDeleted: e.isDeleted,
        updatedAt: e.updated_at,
      };
    });

    return _data;
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  // const populatePatients = async () => {
  //   const query = {
  //     skip: page === 0 ? page : (page - 1) * rowsPerPage,
  //     limit: page === 0 ? rowsPerPage : rowsPerPage * page,
  //     ...filteredData,
  //   };

  //   const response = await getPatients(query);
  //   console.log("DEBUG", response)
  //   if (response.status !== "Fail") {
  //     if (response?.list?.length === 0) {
  //       onResetRedux();
  //       // setFilteredData(getDefaultFilteredData());
  //     }
  //     setTotalDoc(response?.totalDoc);
  //     if (Array.isArray(response?.list)) {
  //       setPatients(response?.list);
  //     }
  //   } else {
  //     if (response?.message === "No data Found") {
  //       // setPatients([]);
  //       onResetRedux();
  //     } else {
  //       toastify(response?.message, "error");
  //     }
  //   }
  // };

  // const populatePatients = async () => {
  //   const query = {
  //     skip: page * rowsPerPage, 
  //     limit: (page * rowsPerPage) + rowsPerPage,
  //     ...filteredData,
  //   };
  
  //   const response = await getPatients(query);
  //   console.log("DEBUG", response);
  //   if (response.status !== "Fail") {
  //     if (response?.list?.length === 0 && page > 0) {
  //       // If no data found and not on the first page, go to the previous page
  //       setPage(page - 1);
  //     } else if (response?.list?.length === 0) {
  //       onResetRedux();
  //     } else {
  //       setTotalDoc(response?.totalDoc);
  //       setPatients(response?.list || []);
  //     }
  //   } else {
  //     if (response?.message === "No data Found") {
  //       onResetRedux();
  //     } else {
  //       toastify(response?.message, "error");
  //     }
  //   }
  // };

  const populatePatients = async () => {
    const totalPages = Math.ceil(totalDoc / rowsPerPage); // Calculate the total number of pages
    const isLastPage = page + 1 >= totalPages; // Check if you are on the last page
  
    const query = {
      skip: page * rowsPerPage, // Correctly calculate skip value
      limit: (page * rowsPerPage) + rowsPerPage,// Use the rowsPerPage value directly
      ...filteredData,
    };
  
    const response = await getPatients(query);
    if (response.status !== "Fail") {
      if (response?.list?.length === 0 && page > 0) {
        // If no data found and not on the first page, handle last page logic
        if (isLastPage) {
          // If on the last page and no data is found, check the previous page
          setPage(page - 1);
        } else {
          // If not on the last page, reset to the first page
          setPage(0);
        }
      } else if (response?.list?.length === 0) {
        // Handle case where no data is found even on the first page
        onResetRedux();
      } else {
        // Successfully received data
        setTotalDoc(response?.totalDoc);
        setPatients(response?.list || []);
  
        // Ensure page is within valid range after fetching data
        // if (page > 0 && response?.list?.length < rowsPerPage) {
        //   setPage(page - 1);
        // }
      }
    } else {
      if (response?.message === "No data Found") {
        onResetRedux();
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const onResetRedux = () => {
    const fieldPathname = pathname.replace("/", "");
    dispatch(setTableSearch({
      ...tableSearch,
      [fieldPathname]: {
        ...(tableSearch[fieldPathname] ? tableSearch[fieldPathname] : {}),
        AccountNumber: null,
        ContactNo: null,
        PatientName: null
      }
    }))
  }

  const handleUpload = async () => {
    if (file) {
      let formData = new FormData();
      formData.append("file", file);
      const response = await uploadCsv(formData);
      if (response?.status !== "Fail") {
        toastify("Patient(s) imported successfully!", "success");
        populatePatients();
      } else {
        console.log({ response });
        toastify(response?.message, "error");
      }
      setUpload(false);
    } else {
      setUpload(false);
    }
  };

  const addPatient = () => {
    dispatch(setPatientData());
    navigate("/add-patient");
  };

  const editPatient = (e) => {
    dispatch(setPatientData(e));
    navigate("/add-patient");
  };

  const removePatient = async () => {
    setShow(false);
    const response = await deletePatient(deleting);
    if (response) {
      populatePatients();
      toastify("Patient deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const onSubmitRedux = (type, value) => {
    const fieldPathname = pathname.replace("/", "");
    if (!fieldPathname.includes("report")) {
      dispatch(setTablePaginate({
        ...tablePaginate,
        [fieldPathname]: {
          [type]: value
        }
      }))
    }
  }

  // useEffect(() => {
  //   let newQuery = {};
  //   const fieldPathname = pathname.replace("/", "");

  //   if (tableSearch[fieldPathname]) {
  //     if (tableSearch[fieldPathname].AccountNumber) {
  //       newQuery.accountNumber = tableSearch[fieldPathname].AccountNumber;
  //     }
  //     if (tableSearch[fieldPathname].PatientName) {
  //       newQuery['patientProfile.fullName'] = tableSearch[fieldPathname].PatientName;
  //     }
  //     if (tableSearch[fieldPathname].AccountNumber) {
  //       newQuery['patientProfile.contactNo'] = tableSearch[fieldPathname].ContactNo;
  //     }
  //   }

  //   if (Object.keys(newQuery).length > 0) {
  //     setFilteredData(newQuery);
  //   }
  // }, [tableSearch, pathname]);

  useEffect(() => {
    populatePatients();
  }, [page, rowsPerPage, filteredData]);

  const columns = [
    {
      name: "No.",
      selector: (row) => row.no,
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "index"),
    },

    {
      name: (
        <div className="d-flex align-items-center" id="ovly31">
          <span className="me-2">Account Number</span>
          {
            <FilterPaginate
              type="search"
              array={[]}
              setArray={() => setFilteredData([])}
              setFilter={(searchValue) =>
                setFilteredData({ accountNumber: searchValue })
              }
              searchValue={filteredData.accountNumber}
              value="AccountNumber"
            />
          }
        </div>
      ),
      selector: (row) => row.accountNumber,
      wrap: true,
      width: "200px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "accountNumberText"),
    },

    {
      name: (
        <div className="d-flex align-items-center" id="ovly32">
          <span className="me-2">Patient Name</span>
          {
            <FilterPaginate
              type="search"
              array={[]}
              setArray={() => setFilteredData([])}
              setFilter={(searchValue) =>
                setFilteredData({ "patientProfile.fullName": searchValue })
              }
              searchValue={filteredData["patientProfile.fullName"]}
              value="PatientName"
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "nameText"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly35">
          <span className="px-2">Contact No</span>
          {
            <FilterPaginate
              type="search"
              array={[]}
              setArray={() => setFilteredData([])}
              setFilter={(searchValue) =>
                setFilteredData({ "patientProfile.contactNo": searchValue })
              }
              searchValue={filteredData["patientProfile.contactNo"]}
              value="ContactNo"
            />
          }
        </div>
      ),
      selector: (row) => row.contact,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "nameText"),
    },

    {
      name: "Last Visit Date",
      selector: (row) =>
        row.lastPayment ? moment(row.lastPayment).format("DD/MM/YYYY") : "-",
      width: "160px",
      sortable: true,
      // sortFunction: (a, b) => customDateSort(a, b, "accountNumber"),
    },

    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "gender"),
      width: "150px",
    },
    {
      name: "Age",
      selector: (row) =>
        row.dob
          ? new Date().getFullYear() - new Date(row.dob).getFullYear()
          : "-",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "dob"),
    },
    // {
    //   name: "DOB",
    //   selector: (row) =>
    //     row.dob && row.dob !== ""
    //       ? new Date(row.dob).toLocaleDateString()
    //       : "NaN",
    // },
    {
      name: "Membership",
      selector: (row) => row.isMember,
      width: "150px",
    },
    {
      name: "Status",
      selector: (row) => row.isActive,
      width: "150px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = patients?.map((e, index) => {
    return {
      id: e._id,
      index: index,
      nameText: e?.patientProfile?.fullName,
      name: (
        <div className="cursor-pointer" onClick={() => editPatient(e)}>
          {e?.patientProfile?.fullName}
        </div>
      ),
      contact: e?.patientProfile?.contactNo,
      gender: e?.patientProfile?.gender,
      dob: e?.patientProfile?.dob,
      isMember: e?.isMember === true ? "Member" : "Non Member",
      lastPayment: e?.lastPayment,
      isActive:
        e.isActive === true ? (
          <div className="active-status">Active</div>
        ) : (
          <div className="inactive-status">In Active</div>
        ),
      accountNumberText: e?.accountNumber,
      accountNumber: (
        <div className="cursor-pointer" onClick={() => editPatient(e)}>
          {e?.accountNumber}
        </div>
      ),
      createdAt: e?.created_at,
      no: (
        <div className="cursor-pointer" onClick={() => editPatient(e)}>
          {index + 1}
        </div>
      ),

      action: (
        <div className="d-flex">
          {checkPermission(userData, "Patient-Database", "update") && (
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                editPatient(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
          )}
          {/* {checkPermission(userData, "Patient-Database", "delete") && (
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          )} */}
        </div>
      ),
    };
  });

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
    {
      when: (row) => {
        let days = Math.round(
          Math.abs(new Date() - new Date(row.createdAt)) / (1000 * 60 * 60 * 24)
        );
        if (days < 2) {
          return true;
        } else {
          return false;
        }
      },
      style: {
        backgroundColor: "#eaeaff",
        borderWidth: "0px",
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  return (
    <div className="container p-0">
      <div className="mainHeading px-4">
        <div>
          <h1>Patient Database</h1>
          <p className="total-font">Total Result: {patients?.length}</p>
        </div>
        <DeleteModal
          content="Confirm Patient Deletion?"
          onYes={removePatient}
          onNo={handleClose}
          show={show}
        />
        {checkPermission(userData, "Patient-Database", "create") && (
          <div className="d-flex align-items-center">
            {/* <Button
							className="addItem-new-pat mx-4"
							onClick={() => window.open(`/questions/new`, "_blank").focus()}
						>
							Questionnaire
						</Button> */}
            {!upload ? (
              <Button
                className="addItem-new-pat"
                onClick={() => setUpload(true)}
              >
                Upload Patients
              </Button>
            ) : (
              <>
                <input
                  type={"file"}
                  accept={".csv"}
                  onChange={(e) => {
                    setFile(e.target.files[0]);
                  }}
                />
                <Button className="upload-button" onClick={handleUpload}>
                  {file === null ? "Cancel" : "Upload"}
                </Button>
              </>
            )}

            <Button
              className="addItem-new-pat mx-4"
              onClick={() => {
                var csv = Papa.unparse(getData(), [
                  {
                    quotes: false,
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ",",
                    header: true,
                    newline: "\r\n",
                    skipEmptyLines: false,
                    columns: null,
                  },
                ]);
                console.log({ csv });
                var blob = new Blob([csv], {
                  type: "text/csv",
                });
                var url = URL.createObjectURL(blob);
                var pom = document.createElement("a");
                pom.href = url;
                pom.setAttribute("download", "patients.csv");
                pom.click();
              }}
            >
              Download Patients
            </Button>

            <Button className="addItem-new-pat" onClick={addPatient}>
              + New Patient
            </Button>
          </div>
        )}
      </div>
      {checkPermission(userData, "Patient-Database", "view") && (
        <DataTable
          columns={columns}
          data={data || []}
          pagination
          paginationServer={true} 
          paginationTotalRows={totalDoc}
          // onChangeRowsPerPage={(value) => {
          //   setRowsPerPage(value);
          //   // onSubmitRedux(rowsPerPage, value);
          // }}
          // onChangePage={(value) => {
          //   setPage(value);
          //   // onSubmitRedux(page, value);
          // }}
          onChangeRowsPerPage={(value) => {
            setRowsPerPage(value);
            setPage(0); // Reset to first page when rows per page changes
          }}
          // onChangePage={(value) => {
          //   setPage(value - 1); // Adjust for zero-indexing
          // }}
          onChangePage={(page) => {
            const lastPage = Math.ceil(totalDoc / rowsPerPage) - 1; // Calculate the last page index
            if (page <= lastPage) {
              setPage(page - 1); // Subtract 1 for zero-indexing
            } else {
              setPage(lastPage); // Set to last page if page number exceeds
            }
          }}
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          responsive="true"
        />
      )}
    </div>
  );
}

export default Patient;
