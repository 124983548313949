import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getListOfHolidays, updateHoliday } from "../../../apis/holidays";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customDateSort,
	customStringSort,
	exportData,
} from "../../../helperFunctions/utils";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function HolidaysList() {
	const navigate = useNavigate();

	const [list, setList] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [itemToDel, setItemToDel] = useState([]);
	const [openModal, setOpenModal] = useState(false);

	const getList = async () => {
		const { result, error } = await getListOfHolidays();
		if (error) {
			toastify(error.message ?? "Failed to fetch Holidays", "error");
			return;
		}
		setList(result.data);
	};

	const onRowEdit = (id) => {
		navigate(`/add-holiday/${id}`);
	};

	const onRowDelete = async (id) => {
		const { result, error } = await updateHoliday({
			dataId: id,
			isDeleted: true,
		});
		if (error) {
			toastify(error.message ?? "Failed to delete Holiday", "error");
			return;
		}
		getList();
	};

	useEffect(() => {
		getList();
	}, []);
	const columns = [
		{
			name: (
				<>
					<div className="d-flex align-items-center" id="ovly2">
						<span className="me-2">Name</span>
						{
							<FilterComponent
								type="search"
								array={list}
								setArray={setFilteredData}
								value="name"
							/>
						}
					</div>
				</>
			),
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: (
				<>
					<div className="d-flex align-items-center" id="ovly2">
						<span className="me-2">Description</span>
						{
							<FilterComponent
								type="search"
								array={list}
								setArray={setFilteredData}
								value="description"
							/>
						}
					</div>
				</>
			),
			selector: (row) => row.description,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "description"),
		},
		{
			name: (
				<>
					<div className="d-flex align-items-center" id="ovly2">
						<span className="me-2">Start Date</span>
						{
							<FilterComponent
								type="date"
								array={list}
								setArray={setFilteredData}
								value="startDate"
							/>
						}
					</div>
				</>
			),
			selector: (row) => row.startDate,
			wrap: true,
			sortable: true,
		},
		{
			name: (
				<>
					<div className="d-flex align-items-center" id="ovly2">
						<span className="me-2">End Date</span>
						{
							<FilterComponent
								type="date"
								array={list}
								setArray={setFilteredData}
								value="endDate"
							/>
						}
					</div>
				</>
			),
			selector: (row) => row.endDate,
			wrap: true,
			sortable: true,
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? list : filteredData)?.map(
		(e, index) => {
			return {
				index,
				_id: e._id,
				name: e?.name,
				description: e?.description,
				startDate: new Date(e?.eventStartDate).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
				endDate: new Date(e?.eventEndDate).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
				action: (
					<div className="d-flex">
						<div
							className="cursor-pointer"
							onClick={() => {
								onRowEdit(e._id);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								setItemToDel(e._id);
								setOpenModal(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);
	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>
						<i
							className="fa fa-arrow-left cursor-pointer"
							onClick={() => {
								navigate(-1);
							}}
						></i>
						Holidays
					</h1>
					<div className="d-flex">
						<Button
							className="addItem"
							onClick={() => {
								navigate("/add-holiday");
							}}
						>
							+ Add New
						</Button>
					</div>
				</div>
				<DataTable
					columns={columns}
					data={data}
					customStyles={customStyles}
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
			{openModal && (
				<>
					<Modal
						centered
						show={openModal}
						onHide={() => {
							setItemToDel(null);
							setOpenModal(!openModal);
						}}
					>
						<Modal.Body className="font-18">
							Confirm Holiday Deletion
							<div className="align-self-end m-3 d-flex">
								<Button
									className="me-3 delete-no ms-auto"
									onClick={() => {
										setOpenModal(false);
									}}
								>
									No
								</Button>
								<Button
									className="delete-yes"
									onClick={() => {
										onRowDelete(itemToDel);
										setOpenModal(false);
									}}
								>
									Yes
								</Button>
							</div>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
}
