import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import { getSetting, updateSetting } from "../../../apis/generalSetting";

export default function GeneralSetting() {
    const navigate = useNavigate();

	const [stateSetting, setStateSetting] = useState({});

    const saveSetting = async () => {
		const response = await updateSetting({
			...stateSetting,
		});
		if (response?.status !== "Fail") {
			toastify("Setting updated successfully!", "success");
			navigate("");
		} else {
			toastify(response?.message, "error");
		}
    };

    useEffect(() => {
		getSetting()
		.then((res) => {
			if (res.status !== "Fail") {
				setStateSetting(res);
			}
		})
    }, []);
    
    const handleChange = (value, field) => {
        setStateSetting({
            ...stateSetting,
            [field]: value
        })
    }

    return (
        <React.Fragment>
            <div className="mainHeading px-4">
                <h1>
                    <i
                        className="fa fa-arrow-left cursor-pointer"
                        onClick={() => navigate(-1)}
                    ></i>
                    Company Information
                </h1>
            </div>

            <div className="pb-4 px-4">
                <div className="content">
                    <div className="addEdit pb-4">
                        <div className="d-flex">
                            <div className="col-5" style={{marginRight: "20px"}}> 
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company UEN
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"companyUEN"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companyUEN")}
                                            value={stateSetting?.companyUEN}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company GST
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"companyGST"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companyGST")}
                                            value={stateSetting?.companyGST}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company Address
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"companyAddress"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companyAddress")}
                                            value={stateSetting?.companyAddress}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company Contact No
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"companyContactNo"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companyContactNo")}
                                            value={stateSetting?.companyContactNo}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company Invoice Template
                                    </Form.Label>
									<InputGroup className="mb-3">
										<Form.Control
											as={"textarea"}
											rows={3}
											name={"companyInvoiceTemplate"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companyInvoiceTemplate")}
                                            value={stateSetting?.companyInvoiceTemplate}
										/>
									</InputGroup>
								</Form.Group>
                            </div>
                            <div className="col-5">
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company Email
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"companyEmail"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companyEmail")}
                                            value={stateSetting?.companyEmail}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Company Site
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"companySite"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "companySite")}
                                            value={stateSetting?.companySite}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Sendgrid Email
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"sengridEmail"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "sendgridEmail")}
                                            value={stateSetting?.sendgridEmail}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Sendgrid Template Id
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            name={"templateId"}
                                            placeholder="Write..."
                                            onChange={(e) => handleChange(e.target.value, "templateId")}
                                            value={stateSetting?.templateId}
                                        />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>
                    </div>

                    <div className="addEdit">
                        <Button className="addItem" onClick={saveSetting}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
