import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import SaveIcon from "@mui/icons-material/Save";
import {
  getPackages,
  updatePackage,
  migratePackage,
} from "../../../../apis/packages";
import { toastify } from "../../../../helperFunctions/toastify";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import FilterComponent from "../../../SharedComponents/FilterComponent";
import { ExpandableIcon } from "../../../../assets/images/index";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";

import { styled } from "@mui/material/styles";
import { Button as MuiButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Packages() {
  const navigate = useNavigate();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [packages, setPackages] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [loading, setLoading] = React.useState(false);

  const getDefaultFilteredData = (values) => {
    let list = [...values];
    const fieldPathname = pathname.replace("/", "");

    if (tableSearch[fieldPathname]) {
      if (tableSearch[fieldPathname].Code) {
        list = values.filter(v => v.code?.toLowerCase()?.includes(tableSearch[fieldPathname].Code.toLowerCase()));
      }
      if (tableSearch[fieldPathname].Name) {
        list = values.filter(v => v.name?.toLowerCase()?.includes(tableSearch[fieldPathname].Name.toLowerCase()));
      }
    }

    return list;
  } 

  const handleFileChange = (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    let formData = new FormData();
    formData.append("csvFile", selectedFile);
    migratePackage(formData).then((res) => {
      populateData();
      setLoading(false);
      toastify(res, "success");
    });
  };
  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const populateData = async () => {
    const response = await getPackages({ isDeleted: false });
    if (response?.status !== "Fail") {
      setPackages(response.filter((e) => !e.isDeleted).reverse());
      console.log({ response });
    } else {
      if (response?.message === "No data Found") {
        setPackages([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removePackage = async () => {
    setShow(false);
    let response = await updatePackage(deleting, { isDeleted: true });
    if (response?.status !== "Fail") {
      populateData();
      setFilteredData((prev) => prev.filter((e) => e._id !== deleting));
      toastify("Package deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editPackage = (e) => {
    navigate("/add-packages", {
      state: {
        package: e,
      },
    });
  };

  const addProduct = () => {
    navigate("/add-packages");
  };

  const getDiscountablePrice = (a, b, c) => {
    let x = a * b;
    let y = 100 - c;
    let z = x * (y / 100);

    return z;
  };

  const getMonthDif = (date2, date1) => {
    // let months;
    // months = (d2.getFullYear() - d1.getFullYear()) * 12;
    // months -= d1.getMonth();
    // months += d2.getMonth();
    // return months <= 0 ? `0 months` : `${months} months`;

    const dt_date1 = new Date(date1);
    const dt_date2 = new Date(date2);

    //Get the Timestamp
    const date1_time_stamp = dt_date1.getTime();
    const date2_time_stamp = dt_date2.getTime();

    let calc;

    //Check which timestamp is greater
    if (date1_time_stamp > date2_time_stamp) {
      calc = new Date(date1_time_stamp - date2_time_stamp);
    } else {
      calc = new Date(date2_time_stamp - date1_time_stamp);
    }
    //Retrieve the date, month and year
    const calcFormatTmp =
      calc.getDate() + "-" + (calc.getMonth() + 1) + "-" + calc.getFullYear();
    //Convert to an array and store
    const calcFormat = calcFormatTmp.split("-");
    //Subtract each member of our array from the default date
    const days_passed = Number(Math.abs(calcFormat[0]) - 1);
    const months_passed = Number(Math.abs(calcFormat[1]) - 1);
    const years_passed = Number(Math.abs(calcFormat[2]) - 1970);

    //Set up custom text
    const yrsTxt = ["year", "years"];
    const mnthsTxt = ["month", "months"];
    const daysTxt = ["day", "days"];

    //Convert to days and sum together
    const total_days =
      years_passed * 365 + months_passed * 30.417 + days_passed;

    //display result with custom text
    const result =
      (years_passed == 1
        ? years_passed + " " + yrsTxt[0] + " "
        : years_passed > 1
        ? years_passed + " " + yrsTxt[1] + " "
        : "") +
      (months_passed == 1
        ? months_passed + " " + mnthsTxt[0]
        : months_passed > 1
        ? months_passed + " " + mnthsTxt[1] + " "
        : "") +
      (days_passed == 1
        ? days_passed + " " + daysTxt[0]
        : days_passed > 1
        ? days_passed + " " + daysTxt[1]
        : "");

    //return the result
    return result.trim();
  };

  useEffect(() => {
    populateData();
  }, []);

  function formatDate(date, defaultDate) {
    if (date) {
      return new Date(date).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
    } else {
      // If 'date' is null or undefined, return today's date
      const today = new Date(defaultDate);
      return today.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
    }
  }

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly23">
          <span className="me-2">Code</span>
          {
            <FilterComponent
              type="search"
              array={packages}
              setArray={setFilteredData}
              value="Code"
              searchValue={tableSearch[fieldPathname]?.Code}
            />
          }
        </div>
      ),
      selector: (row) => row.code,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "code"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly24">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={packages}
              setArray={setFilteredData}
              value="Name"
              searchValue={tableSearch[fieldPathname]?.Name}
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Effective Date",
      selector: (row) => formatDate(row?.effectiveDate, new Date()),
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "effectiveDate", true),
    },
    {
      name: "Expiry Date",
      selector: (row) => formatDate(row?.expiredDate, "2099-01-01"),
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "expiredDate", true),
    },
    {
      name: "Qty",
      selector: (row) => row.packageQty?.toLocaleString(),
      wrap: true,
      width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "packageQty"),
    },
    // {
    //   name: "Total Item Price",
    //   selector: (row) => row.totalCost?.toLocaleString(),
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customNumberSort(a, b, "totalCost"),
    // },
    {
      name: "Total Selling Price",
      selector: (row) => row.totalPrice?.toLocaleString(),
      wrap: true,
      width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "totalPrice"),
    },
    {
      name: "Status",
      selector: (row) => row.isActive,
      wrap: true,
      width: "170px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "140px",
    },
  ].reverse();

  const data = getDefaultFilteredData(packages)?.map(
    (e, index) => {
      return {
        index: index,
        id: e._id,
        code: e.code,
        name: e.name,
        treatments: e?.treatments,

        Description: e?.description,
        effectiveDate: e.effectiveDate,
        expiredDate: e.expiredDate,
        packageQty: e.packageQty,
        totalPrice: e.totalPrice,
        totalCost: e.totalCost,
        isDiscountable: e.isDiscountable,
        isPromoable: e.isPromoable,
        isDeleted: e.isDeleted,
        products: e.products,
        history: e.history,
        status: e.isActive ? "Active" : "In Active",
        isActive:
          e.isActive === true ? (
            <div className="active-status">Active</div>
          ) : (
            <div className="inactive-status">In Active</div>
          ),
        action: (
          <div className="d-flex">
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                editPackage(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
    table: {
      style: {
        paddingRight: 10,
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 0,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  const ExpandedComponent = ({ data }) => {
    return (
      <div className="my-2">
        {data.history.map((e, index) => (
          <div
            className={`cw-div ${
              index % 2 === 0 ? "light-gray-background" : ""
            }`}
            key={index}
          >
            <span className="cw-160">{data?.code}</span>
            <span className="cw-200">{e?.name}</span>
            <span className="cw-160">
              {new Date(e?.effectiveDate).toLocaleDateString()}
            </span>
            <span className="cw-160">
              {getMonthDif(
                new Date(e?.effectiveDate),
                new Date(e?.expiredDate)
              )}
            </span>
            <span className="cw-160">
              {new Date(e?.expiredDate).toLocaleDateString()}
            </span>
            <span className="cw-170">{data?.totalCost}</span>
            <span className="cw-170">{data?.totalPrice}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="container p-0 rev-table">
        <div className="mainHeading px-4">
          <h1>Packages</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  console.log(e);
                  return {
                    Code: e.code,
                    Name: e.name,
                    Description: e?.Description,
                    "Effective Date": new Date(
                      e.effectiveDate
                    ).toLocaleDateString(),
                    "Expired Date": new Date(
                      e.expiredDate
                    ).toLocaleDateString(),

                    "Code Drug/Consumable": e.products?.code,
                    "Drug Limit": e.packageQty,
                    "Drug Discount %": e.isDiscountable,
                    "Code Treatment/Service": e?.treatments?.code,
                  };
                });
                exportData(tempDataArr, "Packages", "Packages");
              }}
            >
              Export
            </Button>
            {/* <MuiButton
              className="addItem me-3"
              component="label"
              role={undefined}
              variant="contained"
              sx={{ backgroundColor: "#295381" }}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Import
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </MuiButton> */}

            <Button className="addItem" onClick={addProduct}>
              + Add New
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Product Deletion?"
          onYes={removePackage}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
          // expandableRows
          // expandableRowsComponent={ExpandedComponent}
          // expandableIcon={{
          // 	expanded: (
          // 		<img src={ExpandableIcon} className="expand-icon" />
          // 	),
          // 	collapsed: (
          // 		<img src={ExpandableIcon} className="expand-icon" />
          // 	),
          // }}
        />
      </div>
    </>
  );
}
