import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { getAdjustmentHistory } from "../../../../apis/products";
import { updateReceivingGoods } from "../../../../apis/receivingGoods";
import { toastify } from "../../../../helperFunctions/toastify";
import DeleteModal from "../../../SharedComponents/DeleteModal";

export default function AdjustmentHistory() {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [goods, setGoods] = useState([]);
    const [show, setShow] = useState(false);
    const [deleting, setDeleting] = useState("");

    const handleClose = () => {
        setShow(false);
        setDeleting("");
    };

    const populateData = async () => {
        console.log({ id: state?.product._id });
        const response = await getAdjustmentHistory(state?.product?._id);
        if (response?.status !== "Fail") {
            setGoods(response.data);
            console.log(response.data);
        } else {
            toastify(response?.message, "error");
        }
    };

    const removeReceivingGood = async () => {
        setShow(false);
        let response = await updateReceivingGoods(deleting, {
            isDeleted: true,
        });
        if (response?.status !== "Fail") {
            populateData();
            toastify("Receiving Goods deleted successfully!", "success");
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        populateData();
    }, []);

    const columns = [
        {
            name: "Date",
            selector: (row) => new Date(row.created_at).toLocaleDateString(),
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Previous Qty",
            selector: (row) => row.currentQty,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Physical Qty",
            selector: (row) => row.physicalQty,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Difference",
            selector: (row) => row.difference,
            wrap: true,
            minWidth: "160px",
        },
    ].reverse();

    const data = goods?.map((e, index) => {
        const selectedItem = e?.items?.find(x => x.product?._id === state?.product?._id);
        console.log("DEBUG", e.items, state)
        return {
            index: index,
            id: e._id,
            created_at: e.created_at,
            currentQty: selectedItem ? selectedItem.currentQty : 0,
            physicalQty: selectedItem ? selectedItem.physicalQty : 0,
            difference: selectedItem ? (selectedItem.physicalQty - selectedItem?.currentQty) : 0,
            remarks: e.remarks,
        }
    });

    console.log(data)

    const customStyles = {
        headCells: {
            style: {
                justifyContent: "center",
            },
        },
        cells: {
            style: {
                justifyContent: "center",
                textAlign: "center",
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: (row) => row,
            style: {
                borderWidth: "0px",
            },
        },
        {
            when: (row) => row.index % 2 === 1,
            style: {
                borderWidth: "0px",
                backgroundColor: "#f8f8f8",
            },
        },
    ];

    return (
        <>
            <div className="container p-0 rev-table">
                <div className="mainHeading px-4">
                    <h1>
                        <i
                            className="fa fa-arrow-left cursor-pointer"
                            onClick={() => navigate(-1)}
                        ></i>
                        Product Details / Adjustment History
                    </h1>
                </div>

                <DeleteModal
                    content="Confirm Purchase List Item Deletion?"
                    onYes={removeReceivingGood}
                    onNo={handleClose}
                    show={show}
                />

                <DataTable
                    columns={columns}
                    customStyles={customStyles}
                    data={data || []}
                    pagination
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
        </>
    );
}
