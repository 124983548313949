import api from "../axios/axios-config";

export const getpaitentSOAP = async () => {
  try {
    let response = await api.get("paitentSOAP/listOfPaitentsoap");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createpaitentSOAP = async (data) => {
  try {
    let response = await api.post("paitentSOAP/createpaitentSOAP", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateDotPhrase = async (dataId, data) => {
  try {
    let response = await api.post("paitentSOAP/updatepaitentSOAP", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deletepaitentSOAP = async (data) => {
  try {
    let response = await api.post("paitentSOAP/deletepaitentSOAP", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
