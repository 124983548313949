import { authorizedRequests } from "../axios/axios-config";

export const getPatientKolsList = async () => {
	const response = await authorizedRequests({
		url: "patientKol/listOfPatientKols",
		method: "get",
	});
	return response;
};

export const updatePatientKol = async (body) => {
	const response = await authorizedRequests({
		url: "patientKol/updatePatientKol",
		method: "post",
		data: body,
	});
	return response;
};

export const getKolById = async (id) => {
	const response = await authorizedRequests({
		url: "patientKol/getKolById",
		method: "post",
		data: {
			kolId: id,
		},
	});
	return response;
};
