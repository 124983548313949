import api from "../axios/axios-config";

export const getPatientNotes = async (dataId, typeNote) => {
  try {
    let response = await api.post(
      "notes/listOfNotesByPatientId",
      {
        dataId,
        typeNote,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getNurseNotes = async (nurseId) => {
  try {
    let response = await api.post(
      "notes/listOfNotesByNurseId",
      {
        nurseId,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const addNote = async (description, patientId, type) => {
  try {
    let response = await api.post(
      "notes/createNotes",
      {
        description,
        patientId,
        type: type ? type : "",
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateNote = async (dataId, data) => {
  try {
    let response = await api.post(
      "notes/updateNotes",
      {
        dataId,
        ...data,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
