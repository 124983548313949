import { useNavigate } from "react-router-dom";
// import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import { getKolReports, getPatientGeneralReports } from "../../../apis/reports";
import BarGraph from "./BarGraph";
import DataTable from "react-data-table-component";
import PieChart from "./PieChart";
import {
	exportData,
	getAgeFromDob,
	getInvoiceId,
} from "../../../helperFunctions/utils";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { Button, Form, Table } from "react-bootstrap";

export default function KolReport() {
	const navigate = useNavigate();

	const [kolDataByName, setKolDataByName] = useState([]);
	const [activeIndex, setActiveIndex] = useState([]);

	const [range, setRange] = useState({
		start: new Date(new Date().getFullYear(), 0, 1),
		end: new Date(),
	});
	const [startDate, setStartDate] = useState(
		new Date(new Date().getFullYear(), 0, 1),
	);
	const [endDate, setEndDate] = useState(new Date());
	function getMonthsBetweenDates(startDate, endDate) {
		const months = [];
		let currentDate = new Date(startDate);

		while (currentDate <= endDate) {
			months.push(currentDate.getMonth() + 1);
			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		return months;
	}

	useEffect(() => {
		if (!startDate || !endDate) {
			return;
		}
		(async () => {
			const { result, error } = await getKolReports({
				startDate,
				endDate,
			});
			if (error) {
				toastify(error.message, "error");
				return;
			}
			let tempkolByName = result?.data?.map((o) => {
				let billingDetails = [];
				o?.billing_details?.forEach((o) => {
					const index = billingDetails.findIndex(
						(x) =>
							x.date ===
							new Date(
								o.billing_id?.created_at,
							).toLocaleDateString(),
					);
					if (index == -1) {
						billingDetails.push({
							date: new Date(
								o.billing_id?.created_at,
							).toLocaleDateString(),
							"Spent Amount": o.billing_id?.totalAmount,
							"Revenue Amount": o?.isFirst
								? o.billing_id?.totalAmount
								: 0,
							billingId: [
								getInvoiceId(o?.billing_id?.invoiceNo) ?? "-",
							],
						});
					} else {
						let tempBillingObj = { ...billingDetails[index] };
						tempBillingObj = {
							...tempBillingObj,
							"Spent Amount":
								tempBillingObj["Spent Amount"] +
								o.billing_id?.totalAmount,
							billingId: tempBillingObj.billingId.concat(
								getInvoiceId(o?.billing_id?.invoiceNo) ?? "-",
							),
							"Revenue Amount": o?.isFirst
								? tempBillingObj["Revenue Amount"] +
								o.billing_id?.totalAmount
								: tempBillingObj["Revenue Amount"],
						};
						billingDetails[index] = tempBillingObj;
					}
				});

				let spendingAmount = 0;
				let revenueAmount = 0;
				o.billing_details?.map((billing) => {
					revenueAmount += billing.billing_id?.totalAmount ?? 0;
					return false;
				});
				o.kol_billings?.map((billing) => {
					spendingAmount += billing?.totalAmount ?? 0;
					return false;
				})

				return {
					label: o.kol_id?.patientProfile?.fullName,
					"Spent Amount": spendingAmount,
					"Revenue Amount": revenueAmount,
					"Increase Amount": (spendingAmount - revenueAmount) >= 0 ? (spendingAmount - revenueAmount) : 0,
					billingDetails: o.billing_details,
					allBillings: o?.kol_billings,
				};
			});
			setKolDataByName([...tempkolByName]);
		})();
	}, [startDate, endDate]);

	const spending_columns = [
		{
			name: "Date",
			selector: (row) => row.date,
			wrap: true,
		},
		{
			name: "Invoice No",
			selector: (row) => row.invoiceNo,
			wrap: true,
		},
		{
			name: "Amount",
			selector: (row) => row.amount?.toLocaleString(),
			wrap: true,
			sortable: true,
		},
		{
			name: "Amount GST",
			selector: (row) => row.gst?.toLocaleString(),
			wrap: true,
			sortable: true,
		},
	];

	const revenue_columns = [
		{
			name: "Date",
			selector: (row) => row.date,
			wrap: true,
		},
		{
			name: "Patient",
			selector: (row) => row.patient,
			wrap: true,
		},
		{
			name: "Invoice No.",
			selector: (row) => row.invoiceNo,
			wrap: true,
		},
		{
			name: "Amount",
			selector: (row) => row.amount?.toLocaleString(),
			wrap: true,
		},
		{
			name: "Amount GST",
			selector: (row) => row.gst?.toLocaleString(),
			wrap: true,
		},
	];

	const spendingData = (array) => {
		let tempArr = [...array];
		tempArr = tempArr.map((o, index) => {
			return {
				index: index,
				date: new Date(o.date).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
				amount: o?.totalAmount,
				gst: o?.gst,
				invoiceNo: getInvoiceId(o?.invoiceNo),
			};
		});
		return tempArr;
	};

	const revenueData = (array) => {
		let tempArr = [...array];
		tempArr = tempArr
			.filter((o) => o?.isFirst)
			?.map((o, index) => {
				return {
					index: index,
					date: new Date(
						o?.billing_id?.created_at,
					).toLocaleDateString("en-GB", {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					}),
					patient: o?.billing_id?.patient?.patientProfile?.fullName,
					invoiceNo: getInvoiceId(o?.billing_id?.invoiceNo) ?? "-",
					amount: o?.billing_id?.totalAmount,
					gst: o?.billing_id?.gst,
				};
			});
		return tempArr;
	};
	const customStyles = {
		headCells: {
			style: {
				// justifyContent: "center",
			},
		},
		cells: {
			style: {
				// justifyContent: "center",
				// textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];
	return (
		<>
			<div>
				<div
					className="d-flex flex-wrap"
					style={{ width: "calc(100% - 130px)" }}
				>
						<div className="col-3 pe-2">
							<div className="font-400-20 mb-1">Start Date:</div>
							<DateInput
								value={range.start}
								placeholder="Start Date"
								onChange={(e) => {
									if (e > range.end) {
										toastify(
											"Start date can't be greater than end date",
											"error",
										);
										return;
									}
									setRange({
										...range,
										start: e,
									});
									// setStartDate(e);
								}}
							/>
						</div>
						<div className="col-3 pe-2">
							<div className="font-400-20 mb-1">End Date:</div>
							<DateInput
								placeholder="End Date"
								value={range.end}
								onChange={(e) => {
									if (e < range.start) {
										toastify(
											"End date can't be less than start date",
											"error",
										);
										return;
									}
									setRange({
										...range,
										end: e,
									});
									// setEndDate(e);
								}}
							/>
						</div>
						<div className="col-6 pe-2" style={{display: "flex"}}>
							<Button
								className="addItem mb-3 m-2 align-self-end"
								style={{ height: "47px" }}
								onClick={() => {
									setStartDate(range.start);
									setEndDate(range.end);
								}}
							>
								Apply
							</Button>
							<Button
								className="addItem mb-3 align-self-end"
								style={{ height: "47px" }}
								onClick={() => {
									let tempDataArr = kolDataByName?.map((e) => {
										return {
											Label: e.label,
											"Spend Amount": e["Spent Amount"],
											"Revenue Amount": e["Revenue Amount"],
											"Different":
												e["Increase Amount"],
											[`Date : ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`]:
												"",
										};
									});
									exportData(tempDataArr, [], "KOL");
								}}
							>
								Export
							</Button>
					</div>
				</div>

				<div className="mt-4 p-3" style={{ background: "white" }}>
					<div className="font-400-20">
						<b>KOL / Channel All</b>
					</div>
					<BarGraph
						data={kolDataByName}
						groupMode={"grouped"}
						xLegend={"Name"}
						yLegend={"Amount"}
						enableGridY={true}
						enableGridX={false}
						label={false}
						// layout="horizontal"
						keys={[
							"Spent Amount",
							"Revenue Amount",
						]}
						indexBy="label"
					/>
				</div>

				<div className="wrapper-table-report">
					<Table striped>
						<thead
							style={{
								background: "#34556F",
								color: "white",
							}}
						>
							<tr
								style={{
									height: "45px",
								}}
								className="align-items-center
								"
							>
								<th className="wd-100">KOL/Channel</th>
								<th className="wd-100">Spent Amount</th>
								<th className="wd-100">Revenue Amount</th>
								<th className="wd-100">Different</th>
								<th className="wd-100">Action</th>
							</tr>
						</thead>
						<tbody>
							{kolDataByName?.length > 0 &&
								kolDataByName?.map((e, index) => (
									<>
										<tr>
											<td className="wd-150 ps-3">
												{e.label}
											</td>
											<td className="wd-150 ps-3">
												{e[
													"Spent Amount"
												]?.toLocaleString()}
											</td>
											<td className="wd-150">
												{e[
													"Revenue Amount"
												]?.toLocaleString()}
											</td>
											<td className="wd-150">
												{e[
													"Increase Amount"
												]?.toLocaleString()}
											</td>
											<td className="wd-100 ">
												<Button
													className="addItem"
													style={{
														maxHeight: "35px",
														width: "130px",
														fontWeight: 400,
														fontSize: "14px",
													}}
													onClick={() => {
														if (activeIndex == index) {
															setActiveIndex();
															// setKolDataByDate({});
															return;
														}
														// setKolDataByDate(
														// e.billingDetails,
														// );
														setActiveIndex(index);
													}}
												>
													View Detail
												</Button>
											</td>
										</tr>
										{activeIndex === index && (
											<tr>
												<td colSpan="12">
													<div
														className="mt-4 p-3"
														style={{
															background: "white",
														}}
													>
														<div className="d-flex justify-content-between font-400-20">
															<b>
																KOL / Channel{" "}
																{e.label}
															</b>
															<Button
																className="addItem align-self-end"
																style={{
																	height: "47px",
																}}
																onClick={() => {
																	const array =
																		spendingData(
																			e.billingDetails,
																		);
																	let tempDataArr =
																		array?.map(
																			(
																				m,
																				i,
																			) => {
																				return {
																					Date: m.date,
																					"Invoice No.":
																						m.billingText,
																					Amount: m.amount,
																					[`Date : ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`]:
																						i ==
																							0
																							? `KOL /Channel : ${e.label}`
																							: "",
																				};
																			},
																		);
																	exportData(
																		tempDataArr,
																		`${e.label} kol`,
																	);
																}}
															>
																Export
															</Button>
														</div>

														<div
															className="p-3"
															style={{
																background: "white",
															}}
														>
															<div className="font-400-20 mb-3">
																<b>Spending Amounts</b>
															</div>
															<DataTable
																pagination
																columns={
																	spending_columns
																}
																data={spendingData(
																	e.allBillings,
																)}
																conditionalRowStyles={
																	conditionalRowStyles
																}
																customStyles={
																	customStyles
																}
															/>
														</div>
														<div
															className="mt-4 p-3"
															style={{
																background: "white",
															}}
														>
															<div className="font-400-20 mb-3">
																<b>Revenue Amounts</b>
															</div>
															<DataTable
																pagination
																columns={
																	revenue_columns
																}
																data={revenueData(
																	e.billingDetails,
																)}
																conditionalRowStyles={
																	conditionalRowStyles
																}
																customStyles={
																	customStyles
																}
															/>
														</div>
														{/* <BarGraph
															data={e.billingDetails}
															groupMode={"grouped"}
															xLegend={"Date"}
															yLegend={"Amount"}
															enableGridY={true}
															enableGridX={false}
															label={false}
															keys={[
																"Spent Amount",
																"Revenue Amount",
															]}
															indexBy="date"
														/> */}
													</div>
												</td>
											</tr>
										)}
									</>
								))}
						</tbody>
					</Table>
				</div>
			</div>
		</>
	);
}
