import api from "../axios/axios-config";

export const getCampaigns = async (data) => {
  try {
    let response = await api.get("compaign/listOfCompaign");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createCampaign = async (data) => {
  try {
    let response = await api.post("compaign/createCompaign", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateCampaign = async (dataId, data) => {
  try {
    let response = await api.post("compaign/updateCompaign", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
