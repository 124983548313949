import api from "../axios/axios-config";

export const getTags = async () => {
  try {
    let response = await api.get("tags/listOfTags");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createTags = async (data) => {
  try {
    let response = await api.post("tags/createTag", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateTags = async (tagId, data) => {
  try {
    let response = await api.post("tags/updateTag", {
      tagId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
