import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getDepartments } from "../../../apis/departments";
import {
  deleteStaff,
  getStaff,
  getStaffListByFilter,
} from "../../../apis/staff";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
// import { departmentDetails, getDepartments } from "../../../apis/departments";
import { setTableSearch } from "../../../redux/slices/commonSlice";

import {
  customDateSort,
  customStringSort,
  downloadCsv,
  formatDate
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function User({
  hideTitle
}) {
  let navigate = useNavigate();
  const userData = useSelector((state) => state.userData.value);
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [staff, setStaff] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [roles, setRoles] = useState(["admin", "staff"]);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.user?.startDate ? tableSearch[fieldPathname]?.user?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.user?.endDate ? tableSearch[fieldPathname]?.user?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(tableSearch[fieldPathname]?.user?.selectedDepartment ? tableSearch[fieldPathname]?.user?.selectedDepartment : "");

  const onReduxFilter = (valueStartDate, valueEndDate, valueDepartment) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
        user: {
          startDate: valueStartDate,
          endDate: valueEndDate,
          selectedDepartment: valueDepartment,
        }
			}
		}))
  }

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.user?.startDate ? tableSearch[fieldPathname]?.user?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.user?.endDate ? tableSearch[fieldPathname]?.user?.endDate : new Date());
    setSelectedDepartment(tableSearch[fieldPathname]?.user?.selectedDepartment ? tableSearch[fieldPathname]?.user?.selectedDepartment : "");

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.user?.startDate ||
      tableSearch[fieldPathname]?.user?.endDate ||
      tableSearch[fieldPathname]?.user?.selectedDepartment
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  const getStaffList = async () => {
    const response = await getStaff();
    if (response?.status !== "Fail") {
      // let data = response.filter((e) => e?._id !== userData?._id);
      let data = response.filter((e) => !e?.role?.includes("superAdmin"));
      setStaff(data);
    } else {
      if (response?.message === "No data Found") {
        setStaff([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };
  const getFilteredStaffList = async () => {
    const response = await getStaffListByFilter({
      startDate,
      endDate,
      selectedDepartment,
    });

    if (response?.status !== "Fail") {
      let data = response.filter((e) => !e?.role?.includes("superAdmin"));
      // console.log("response of get Staff List By Filter", data);
      setStaff(data);
    } else {
      if (response?.message === "No data Found") {
        setStaff([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  useEffect(() => {
    getDepartmentList();
    getStaffList();
  }, []);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly17">
          <span className="me-2">Username</span>
          {
            <FilterComponent
              type="search"
              array={staff}
              setArray={setFilteredData}
              value="Username"
            />
          }
        </div>
      ),
      wrap: true,
      selector: (row) => row.username,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "username"),
    },
    {
      name: "Joining Date",
      selector: (row) =>
        new Date(row.createdAt).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),

      width: "200px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "createdAt", true),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly18">
          <span className="me-2">Contact No</span>
          {
            <FilterComponent
              type="search"
              array={staff}
              setArray={setFilteredData}
              value="StaffContact"
            />
          }
        </div>
      ),
      selector: (row) => row.contactNumber,
      width: "180px",
      sortable: true,
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly19">
          <span className="me-2">Email</span>
          {
            <FilterComponent
              type="search"
              array={staff}
              setArray={setFilteredData}
              value="Email"
            />
          }
        </div>
      ),
      selector: (row) => row.email,
      width: "250px",
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "emailText"),
    },
    // {
    //   name: (
    //     <div className="d-flex align-items-center" id="ovly20">
    //       <span className="me-2">Role</span>
    //       {
    //         <FilterComponent
    //           type="radio"
    //           array={staff}
    //           setArray={setFilteredData}
    //           options={roles}
    //           value="StaffRole"
    //         />
    //       }
    //     </div>
    //   ),
    //   selector: (row) =>
    //     row.role.map((e, index) => {
    //       if (index === row.role.length - 1) {
    //         return e;
    //       }
    //       return e + `, `;
    //     }),

    //   width: "180px",
    // },

    {
      name: "Department",
      selector: (row) => row.dept,

      width: "140px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "dept"),
    },
    {
      name: "Designation",

      selector: (row) => row.designation,
      width: "180px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "designation"),
    },
    // {
    //   name: "Action",
    //   selector: (row) => row.action,
    // },
  ];

  const data = (filteredData.length === 0 ? staff : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        emailText: e.email,
        email: <span className="color-blue">{e.email}</span>,
        index: index,
        dept: departments[departments.findIndex((d) => d._id === e.department)]
          ?.name,
        username: e.username,
        createdAt: e.created_at,
        department: e.department,
        jobTitle: e.jobTitle,
        designation: e.designation,
        contactNumber: e.contactNumber,
        nationalID: e.nationalID,
        dob: e.dob,
        address: e.address,
        postalCode: e.postalCode,
        gender: e.gender,
        defaultAppt: e.defaultAppt,
        role: e.role,
        isActive: e.isActive,
        // action: (
        //   <div className="d-flex">
        //     <div
        //       className="mx-3 cursor-pointer icon-width"
        //       onClick={() => {
        //         editStaff(e);
        //       }}
        //     >
        //       <i className="fa fa-pen"></i>
        //     </div>
        //     <div
        //       className="mx-3 cursor-pointer icon-width"
        //       onClick={() => {
        //         setDeleting(e._id);
        //         setShow(true);
        //       }}
        //     >
        //       <i className="fa fa-trash"></i>
        //     </div>
        //   </div>
        // ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const getDepartmentList = async () => {
    const response = await getDepartments();
    // console.log("department list...", response);
    if (response.status !== "Fail") {
      setDepartments(response);
    } else {
      toastify(response?.message, "error");
    }
  };

  // useEffect(() => {
  //   if (selectedDepartment) {
  //     const filteredData = staff?.filter(
  //       (item) => item.department === selectedDepartment?._id
  //     );
  //     // console.log("filteredData...", filteredData);
  //     // console.log("selectedDepartment...", selectedDepartment);
  //     setStaff(filteredData);
  //   } else {
  //     setStaff(staff);
  //   }
  // }, [selectedDepartment]);

  const exportCsv = () => {
    const csvData = data?.map((row) => {
      return {
        Username: row.username,
        "Joining Date": formatDate(row.createdAt),
        "Contact No": row.contactNumber,
        "Email": row.emailText,
        "Department": row.dept,
        "Designation": row.designation
      };
    });

    downloadCsv(csvData ? csvData : [], "Staff Details");
  }

  return (
    <div className="container p-0">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {
          !hideTitle && (
            <h1>Users List</h1>
          )
        }
        <div
          style={{
            display: "flex",
            // flexDirection: "column",
            // alignItems: "center",
            // justifyContent: "end",
            marginTop: "18px",
          }}
        >
          <Form.Group className="col-3 me-3">
            <Form.Label className="headings2" style={{ color: "#000" }}>
              Filter by Department
            </Form.Label>
            <SelectComponent
              // isDisabled={userId !== undefined}
              singleSelect
              searchEnabled
              selectedValue={
                selectedDepartment ? JSON.stringify(selectedDepartment) : null
              }
              options={
                departments?.length > 0
                  ? [
                      ...departments.map((item) => {
                        return {
                          label: item.name,
                          value: JSON.stringify(item),
                        };
                      }),
                    ]
                  : []
              }
              onChange={(e) => {
                let val = e.value;
                if (val === "") {
                  setSelectedDepartment();
                } else {
                  setSelectedDepartment(JSON.parse(e.value));
                }
              }}
            />
          </Form.Group>
          <FilterWrapper className="d-flex">
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(startDate, endDate, selectedDepartment);
                  getFilteredStaffList();
                  // setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button
                className="addItem"
                onClick={() => exportCsv()}
              >
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default User;
