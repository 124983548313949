export const permissionTypes = Object.freeze({
  CREATE: "create",
  VIEW: "view",
  DELETE: "delete",
  UPDATE: "update",
  ADMIN: "admin",
  REPORT: "report",
});

export const routes = [
  {
    module: "Reception",
    permission: [permissionTypes.VIEW],
    path: "rostering-view",
  },
  {
    module: "Reception",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "rostering",
  },
  {
    module: "Appointment",
    permission: [permissionTypes.VIEW],
    path: "appointments",
  },
  {
    module: "Appointment",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-appointment",
  },
  {
    module: "Appointment",
    permission: [permissionTypes.VIEW],
    path: "waiting-lists",
  },
  {
    module: "Appointment",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-waitings",
  },
  {
    module: "Session",
    permission: [permissionTypes.VIEW],
    path: "sessions",
  },
  {
    module: "Session",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-sessions",
  },
  {
    module: "Patient-Database",
    permission: [permissionTypes.VIEW],
    path: "patient",
  },
  {
    module: "Patient-Database",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-patient",
  },
  {
    module: "KOL/Channel",
    permission: [permissionTypes.VIEW],
    path: "kol",
  },
  {
    module: "Campaign",
    permission: [permissionTypes.ADMIN],
    path: "campaign",
  },
  {
    module: "Campaign",
    permission: [permissionTypes.ADMIN],
    path: "add-campaign",
  },
  {
    module: "Note-Taking",
    permission: [permissionTypes.VIEW],
    path: "doctor-notes",
  },
  {
    module: "Note-Taking",
    permission: [permissionTypes.VIEW],
    path: "therapist-notes",
  },
  {
    module: "Note-Taking",
    permission: [permissionTypes.VIEW],
    path: "nutritionist-notes",
  },
  {
    module: "Note-Taking",
    permission: [permissionTypes.VIEW],
    path: "patient-notes",
  },
  {
    module: "Promo",
    permission: [permissionTypes.VIEW],
    path: "promo",
  },
  {
    module: "Promo",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-promo",
  },
  {
    module: "Finance",
    permission: [permissionTypes.VIEW],
    path: "billing",
  },
  {
    module: "Finance",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-billing",
  },
  {
    module: "Finance",
    permission: [permissionTypes.VIEW],
    path: "refund",
  },
  {
    module: "Finance",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "add-refund",
  },
  {
    module: "Reports",
    permission: [permissionTypes.VIEW],
    path: "data-table",
  },
  // {
  // 	module: "Data Table Generation",
  // 	permission: [permissionTypes.VIEW],
  // 	path: "preview-template",
  // },
  {
    module: "Mail Chimp",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "create-templates",
  },
  {
    module: "Mail Chimp",
    permission: [permissionTypes.CREATE, permissionTypes.UPDATE],
    path: "send-mail",
  },
];
