import { useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { ExpandableIcon } from "../../../assets/images/index";

export default function PackagesTable({
  sessionInvoices,
  setSessionInvoices,
  setPromo,
  setSubTotal,
  setTotal,
  setTax,
  setUsePoint,
  onRowEdit,
}) {
  const [activeIndex, setActiveIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [itemToDel, setItemToDel] = useState();

  if (sessionInvoices?.filter((o) => o.type == "PACKAGE")?.length == 0)
    return (
      <div className="w-100 text-center" style={{ padding: "24px" }}>
        There are no records to display
      </div>
    );
  return (
    <>
      <Table>
        <thead
          style={{
            background: "#34556F",
            color: "white",
          }}
        >
          <tr
            style={{
              height: "45px",
            }}
            className={`align-items-center`}
          >
            <th style={{ textAlign: "center", width: "200px" }}>
              <span className="me-2 ps-2">Item Description</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2 ps-2">Quantity</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2">Promo</span>
            </th>
            <th style={{ textAlign: "center" }}>
              <span className="me-2">Discount</span>
            </th>
            {/* <th>Limit</th> */}

            <th style={{ textAlign: "center" }}>Amount</th>
            <th style={{ width: "200px", textAlign: "center" }}>Action</th>
          </tr>
        </thead>
        <tbody>
          {sessionInvoices.length > 0 &&
            sessionInvoices?.map((e, index) => {
              if (e.type !== "PACKAGE") {
                return <></>;
              }
              return (
                <>
                  <tr
                    style={{
                      backgroundColor: e.isAlreadyPaid ? "#fff9e5" : "inherit",
                    }}
                    // style={
                    // 	e.isAlreadyPaid
                    // 		? { backgroundColor: "#B6ee7a" }
                    // 		: {}
                    // }
                  >
                    <td className="ps-3" style={{ textAlign: "center" }}>
                      {e.isAlreadyPaid ? "Current Package - " : ""}
                      {e.name}
                    </td>
                    <td style={{ textAlign: "center" }}>{e?.qty}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.promoDiscountType === "Percentage"
                        ? `${e.promoDiscountValue}%`
                        : `$${e.promoDiscountValue ?? 0}`}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {e.discountType === "Percentage"
                        ? `${e.discount}%`
                        : `$${e.discount}`}
                    </td>
                    {/* <td className="wd-100">{e.qty}</td> */}
                    <td style={{ textAlign: "center" }}>${e.total_price}</td>
                    <td style={{ width: "200px" }}>
                      <div className="d-flex ">
                        {!e.isAlreadyPaid ? (
                          <>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                onRowEdit(
                                  index,
                                  e.qty,
                                  e.discount,
                                  e.price,
                                  e.remark
                                );
                                // setItemToEdit(
                                // 	i,
                                // );
                                // setNewQty(
                                // 	o.qty,
                                // );
                                // setNewDisc(
                                // 	o.discount,
                                // );
                                // setItemToEditPrice(
                                // 	o.price,
                                // );
                                // setModal(
                                // 	"edit",
                                // );
                              }}
                            >
                              <i className="fa fa-pen"></i>
                            </div>
                          </>
                        ) : (
                          <div className="me-3"> </div>
                        )}
                        <div
                          className="ms-3  cursor-pointer icon-width"
                          onClick={() => {
                            setItemToDel(index);
                            setOpenModal(true);
                          }}
                        >
                          <i className="fa fa-trash"></i>
                        </div>
                        <div className="ms-2 cursor-pointer icon-width">
                          <img
                            src={ExpandableIcon}
                            className="expand-icon"
                            onClick={(e) => {
                              if (activeIndex == index) {
                                setActiveIndex();
                                return;
                              }
                              setActiveIndex(index);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    {/* <td className="wd-100 ">
											<Button
												className="addItem"
												style={{
													maxHeight: "35px",
													width: "130px",
													fontWeight: 400,
													fontSize: "14px",
												}}
												onClick={(e) => {
													if (activeIndex == index) {
														setActiveIndex();
														return;
													}
													setActiveIndex(index);
												}}
											>
												View Detail
											</Button>
										</td> */}
                  </tr>
                  {activeIndex === index && (
                    <>
                      <tr
                      // style={
                      // 	e.isAlreadyPaid
                      // 		? {
                      // 				backgroundColor:
                      // 					"#B6ee7a",
                      // 		  }
                      // 		: {}
                      // }
                      >
                        <td
                          colSpan="12"
                          style={{
                            backgroundColor: "#f2f2f2",
                            padding: "20px",
                          }}
                        >
                          <Table>
                            <thead
                              style={{
                                background: "#34556F",
                                color: "white",
                                width: "100%",
                              }}
                            >
                              <tr
                                style={{
                                  height: "45px",
                                }}
                                className="align-items-center
							"
                              >
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  <span className="me-2 ps-2">Type</span>
                                </th>
                                {/* <th className="wd-150" style={{textAlign: "center"}}>
																		Code
																</th> */}
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Name
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Quantity
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Used
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Balance
                                </th>
                                <th
                                  className="wd-150"
                                  style={{ textAlign: "center" }}
                                >
                                  Amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {e.products?.length > 0 &&
                                e?.products?.map((product, productIndex) => {
                                  const finalQty =
                                    product.qty * (e.qty ? e.qty : 1);

                                  return (
                                    <>
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          Product
                                        </td>
                                        {/* <td>
																						{
																							product.name
																						}
																					</td> */}
                                        <td style={{ textAlign: "center" }}>
                                          {product.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {finalQty}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            className="d-flex"
                                            style={{ justifyContent: "center" }}
                                          >
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]?.products;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  0
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used -
                                                  1;
                                                tempSessions[index].products = [
                                                  ...tempArr,
                                                ];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <span className="mx-2">
                                              {product.used}
                                            </span>
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]?.products;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  finalQty
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used +
                                                  1;
                                                tempSessions[index].products = [
                                                  ...tempArr,
                                                ];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {finalQty - product.used}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {(e.qty ? e.qty : 1) *
                                            product.total_price}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                              {e.treatments?.length > 0 &&
                                e?.treatments?.map((product, productIndex) => {
                                  const finalQty =
                                    product.qty * (e.qty ? e.qty : 1);

                                  return (
                                    <>
                                      <tr>
                                        <td style={{ textAlign: "center" }}>
                                          Treatment
                                        </td>
                                        {/* <td>
																						{
																							product.name
																						}
																					</td> */}
                                        <td style={{ textAlign: "center" }}>
                                          {product.name}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {finalQty}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          <div
                                            className="d-flex"
                                            style={{ justifyContent: "center" }}
                                          >
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]
                                                    ?.treatments;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  0
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used -
                                                  1;
                                                tempSessions[index].treatments =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-minus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            <span className="mx-2">
                                              {product.used}
                                            </span>
                                            <div
                                              className="pointer"
                                              onClick={() => {
                                                let tempSessions = [
                                                  ...sessionInvoices,
                                                ];
                                                let tempArr =
                                                  tempSessions[index]
                                                    ?.treatments;
                                                if (
                                                  tempArr[productIndex].used ==
                                                  finalQty
                                                )
                                                  return;
                                                tempArr[productIndex].used =
                                                  tempArr[productIndex].used +
                                                  1;
                                                tempSessions[index].treatments =
                                                  [...tempArr];
                                                setSessionInvoices([
                                                  ...tempSessions,
                                                ]);
                                              }}
                                            >
                                              <i
                                                class="fa fa-plus-square"
                                                style={{
                                                  color: "#34556F",
                                                }}
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                          </div>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {finalQty - product.used}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {(e.qty ? e.qty : 1) *
                                            product.total_price}
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
        </tbody>
      </Table>
      <Modal
        centered
        show={openModal}
        onHide={() => {
          setItemToDel(null);
          setOpenModal(!openModal);
        }}
      >
        <Modal.Body className="font-18">
          Confirm Item Deletion
          <div className="align-self-end m-3 d-flex">
            <Button
              className="me-3 delete-no ms-auto"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              className="delete-yes"
              onClick={() => {
                if (sessionInvoices?.length == 1) {
                  setSubTotal(0);
                  setPromo(0);
                  setTotal(0);
                  setTax(null);
                  setUsePoint(0);
                }
                let tempArr = [...sessionInvoices];
                tempArr = tempArr.filter((k, idx) => idx !== itemToDel);
                setSessionInvoices([...tempArr]);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
