import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { HiTrash } from "react-icons/hi";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { modifyPatientMI } from "../../../apis/patients";
import {
  addMedicalInfo,
  deleteMedicalInfo,
  getMedicalInfo,
  updateMedicalInfo,
} from "../../../apis/medicalInfo";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { toastify } from "../../../helperFunctions/toastify";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import DeleteModal from "../../SharedComponents/DeleteModal";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function TabMedicalInfo({}) {
  const dispatch = useDispatch();
  const patientData = useSelector((state) => state.patientData.value);

  const [type, setType] = useState();
  const [date, setDate] = useState();
  const [description, setDescription] = useState();
  const [open, setOpen] = useState(false);
  const [ptData, setPtData] = useState([]);
  const [isUpdating, setIsUpdating] = useState();
  const [medicalTypes, setMedicalTypes] = useState(["Allergy", "Medical"]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [show2, setShow2] = useState(false);

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const handleClickAway = () => {
    setShow2(false);
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
    },
    {
      name: "Type",
      selector: (row) => row.type,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = ptData?.map((e, index) => {
    return {
      id: e._id,
      index: index,
      date: e.date ? new Date(e.date).toLocaleDateString() : "",
      type: e.type,
      description: e.description,
      action: (
        <div className="d-flex">
          <MdOutlineModeEditOutline
            color="#E7C13D"
            className="mx-3 cursor-pointer"
            size={"20px"}
            onClick={() => editInfo(e)}
          />

          <HiTrash
            color="red"
            cursor={"pointer"}
            size={"20px"}
            onClick={() => {
              setDeleting(e._id);
              setShow(true);
            }}
          />
        </div>
      ),
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const savePatient = async () => {
    const response = await modifyPatientMI(
      patientData._id,
      patientData?.medicalInfo
    );
    if (response?.status !== "Fail") {
      dispatch(setPatientData(response));
      // setPage("membershipInfo");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editInfo = async (data) => {
    setIsUpdating(data._id);
    setOpen(true);
    setDate(new Date(data.date));
    setDescription(data.description);

    let _types = [...medicalTypes];
    _types.forEach(function (item, i) {
      if (item === data.type) {
        _types.splice(i, 1);
        _types.unshift(item);
      }
    });
    setMedicalTypes(_types);
    setType(_types[0]);
  };

  const addInfo = async () => {
    if (isUpdating) {
      if (!type || type === "") {
        missingPopup("Type");
      } else if (!description) {
        missingPopup("Description");
      } else {
        const response = await updateMedicalInfo(
          type,
          (date ? date.toLocaleDateString() : ""),
          description,
          isUpdating
        );
        if (response?.status !== "Fail") {
          populateMedicalInfo();
          setDate(new Date());
          setDescription();
          setOpen(false);
          setIsUpdating();
          toastify("updated!", "success");
        } else {
          toastify(response?.message, "error");
        }
      }
    } else {
      if (!type || type === "") {
        missingPopup("Type");
      } else if (!description) {
        missingPopup("Description");
      } else {
        const response = await addMedicalInfo(
          type,
          (date ? date.toLocaleDateString() : ""),
          description,
          patientData._id
        );
        if (response?.status !== "Fail") {
          populateMedicalInfo();
          setDate(new Date());
          setDescription();
          setOpen(false);
          toastify("added Medical Info!", "success");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const removeMedicalInfo = async () => {
    setShow(false);
    const response = await deleteMedicalInfo(deleting);
    if (response.status !== "Fail") {
      populateMedicalInfo();
      toastify("Medical Info deleted!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const populateMedicalInfo = async () => {
    const response = await getMedicalInfo(patientData?._id);
    if (response?.status !== "Fail") {
      setPtData(response);
      let _patient = { ...patientData };
      _patient.medicalInfo = response;
      dispatch(setPatientData(_patient));
    }
  };

  useEffect(() => {
    if (patientData?.medicalInfo) {
      populateMedicalInfo();
    }
  }, []);

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <div>
      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />

      <DeleteModal
        content="Confirm Medical Info Deletion?"
        onYes={removeMedicalInfo}
        onNo={handleClose}
        show={show}
      />

      <Button
        className="my-add-button mb-3"
        onClick={() => {
          setOpen(!open);
          setDate();
          setType("");
          setDescription();
        }}
      >
        + Add
      </Button>

      {open ? (
        <div>
          <div className="d-flex">
            <Form.Group className="col-5 mt-3">
              <Form.Label className="headings2">Type *</Form.Label>
              <select
                className="form-select mb-3"
                aria-label="Default select example"
                onChange={(e) => {
                  let val = e.target.value;
                  if (val === "") {
                    setType();
                  } else {
                    setType(e.target.value);
                  }
                }}
              >
                {!type && <option value={""}>Select</option>}
                {medicalTypes.map((e) => (
                  <option value={e}>{e}</option>
                ))}
              </select>
            </Form.Group>

            <span className="col-1" />

            <ClickAwayListener onClickAway={handleClickAway}>
              <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => {
                      setDate(newValue?.$d);
                      setShow2(false);
                    }}
                    open={show2}
                    maxDate={new Date()}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Form.Group className="mt-3">
                        <Form.Label className="headings2">Date</Form.Label>
                        <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                          <Form.Control
                            ref={inputRef}
                            // {...inputProps}
                            value={
                              date
                                ? new Date(date).toLocaleDateString("en-GB", {
                                    day: "numeric",
                                    month: "numeric",
                                    year: "numeric",
                                  })
                                : null
                            }
                            onClick={() => setShow2(!show2)}
                            readOnly
                            placeholder="Select"
                          />
                          <div onClick={() => setShow2(!show2)}>
                            {InputProps?.endAdornment}
                          </div>
                        </InputGroup>
                      </Form.Group>
                    )}
                  />
                </LocalizationProvider>
              </div>
            </ClickAwayListener>
          </div>

          <Form.Group className="col-5 mt-3">
            <Form.Label className="headings2">Description *</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                as="textarea"
                placeholder="Write..."
                className="large-text-area"
                style={{ resize: "none" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </InputGroup>
          </Form.Group>

          <Button className="my-save-button mb-5" onClick={addInfo}>
            Save
          </Button>
        </div>
      ) : (
        <Button className="addItem" onClick={savePatient}>
          Save
        </Button>
      )}
    </div>
  );
}
