import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import { SketchPicker } from "react-color";
import { getSetting, updateSetting } from "../../../apis/generalSetting";

const listStatus = [
	"Waiting List",
	"Appointment Planned",
	"Appointment Confirmed",
	"Appointment Cancelled",
	"On Session",
	"Billing Paid",
	"Billing Unpaid",
];

export default function GeneralSetting() {
    const navigate = useNavigate();

    const [color, setColor] = useState("#0092fe");
	const [show, setShow] = useState(null);
	const [stateSetting, setStateSetting] = useState({});
	const [statusRostering, setStatusRostering] = useState({});

    const saveSetting = async () => {
		const response = await updateSetting({
			...stateSetting,
			statusRostering: statusRostering
		});
		if (response?.status !== "Fail") {
			toastify("Setting updated successfully!", "success");
			navigate("");
		} else {
			toastify(response?.message, "error");
		}
    };

    useEffect(() => {
		getSetting()
		.then((res) => {
			if (res.status === "Fail") {
				const defaultValue = {};
				listStatus?.map((status) => {
					const labelObj = status.replaceAll(" ", "");
					defaultValue[labelObj] = "#0092fe";
		
					return false;
				});
		
				setStatusRostering(defaultValue);
			} else {
				setStatusRostering(res?.statusRostering);
				setStateSetting(res);
			}
		})
	}, []);
	
	const getColorStatus = (status) => {
		const labelObj = status.replaceAll(" ", "");
		if (statusRostering[labelObj]) {
			return statusRostering[labelObj];
		}

		return "#0092fe";
	}

    return (
        <React.Fragment>
            <div className="mainHeading px-4">
                <h1>
                    <i
                        className="fa fa-arrow-left cursor-pointer"
                        onClick={() => navigate(-1)}
                    ></i>
                    Color Status Appt
                </h1>
            </div>

            <Modal
                className="mt-5"
                centered
                show={show !== null}
                size="sm"
                onHide={() => setShow(null)}
            >
                <div className="p-3 d-flex align-items-center justify-content-center flex-column">
                    <h5>Pick a Color</h5>
                    <div>
                        <SketchPicker
                            className="mt-2 mb-4"
                            color={color}
                            onChange={(e) => {
                                setColor(e.hex);
                            }}
                        />
                    </div>

                    <Button
                        className="select-button"
                        style={{ background: "#225e7a" }}
                        onClick={() => {
							const labelObj = show.replaceAll(" ", "");
							setShow(null);
                            setStatusRostering({
								...statusRostering,
								[labelObj]: color
							});
                        }}
                    >
                        Select
                    </Button>
                </div>
            </Modal>

            <div className="pb-4 px-4">
                <div className="content">
                    <div className="addEdit pb-4">
                        <div className="col-5">
							{
								listStatus?.map((status, index) => (
									<Form.Group key={index}>
										<Form.Label className="headings2">
											{status}
										</Form.Label>
										<InputGroup className="mb-3 p-0">
											<div
												style={{
													background: getColorStatus(status),
												}}
												className="color-div"
												onClick={() => setShow(status)}
											></div>
											<Form.Control
												onClick={() => setShow(status)}
												placeholder="Pick Color..."
												value={getColorStatus(status)}
											/>
										</InputGroup>
									</Form.Group>
								))
							}
                        </div>
                    </div>

                    <div className="addEdit">
                        <Button className="addItem" onClick={saveSetting}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
