import React, { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { addNote, updateNote } from "../../../apis/notes";
import { getPatientDetailsById } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import NoteRenderer from "../NoteTaking/NoteRenderer";
import { Mentions } from "antd";
import DeleteModal from "../../SharedComponents/DeleteModal";
import { getDotPhrases } from "../../../apis/dotPhrases";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";

export default function TabNurseNotes({ patientId }) {
  const patientData = useSelector((state) => state.patientData.value);
  const userData = useSelector((state) => state.userData.value);
  const [notes, setNotes] = useState([]);
  const [text, setText] = useState("");
  const [data, setData] = useState({ ".": [] });
  const [dotPhrases, setDotPhrases] = useState([]);
  const [val, setVal] = useState("");
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [updating, setUpdating] = useState("");

  const { Option } = Mentions;

  const savePatient = async () => {
    if (
      userData?.department?.permissions?.filter(
        (p) => p.moduleName === "Note-Taking"
      ).length > 0
    ) {
      if (text) {
        if (updating) {
          let isSameDay = false;

          let ind = notes.find((e) => {
            if (updating === e._id) {
              if (checkDate(e.created_at)) isSameDay = true;
              return e;
            }
          });

          if (ind?.staff?._id === userData._id) {
            if (isSameDay) {
              let response = await updateNote(updating, {
                description: text,
                type: "Therapist",
              });
              if (response?.status !== "Fail") {
                setText("");
                setUpdating("");
                toastify("Note updated successfully!", "success");
                populateData();
              } else {
                toastify(response?.message);
                setUpdating("");
                setText("");
              }
            } else {
              toastify("Note can be deleted on the same day only.", "error");
              setUpdating("");
              setText("");
            }
          } else {
            toastify("Only note maker can update/delete note.", "error");
            setUpdating("");
            setText("");
          }
        } else {
          let response = await addNote(
            text,
            patientId ?? patientData?._id,
            "Therapist"
          );
          if (response?.status !== "Fail") {
            setText("");
            toastify("Note added successfully!", "success");
            populateData();
            setUpdating("");
            setDeleting("");
          } else {
            toastify(response?.message);
          }
        }
      } else {
        missingPopup("Text in Note");
      }
    } else {
      toastify("Only nurse can add Nurse Notes!", "error");
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const populateData = async () => {
    let phrases = await getDotPhrases();
    setDotPhrases(phrases);
    let response = await getPatientDetailsById(patientId ?? patientData?._id);

    if (response?.status !== "Fail") {
      setNotes(
        response?.nurseNotesList?.reverse()?.filter((e) => !e.isDeleted)
      );
    } else {
      toastify(response?.message, "error");
    }
  };

  const checkDate = (noteDate) => {
    let day1 = new Date().getDate();
    let day2 = new Date(noteDate).getDate();

    let month1 = new Date().getMonth();
    let month2 = new Date(noteDate).getMonth();

    let year1 = new Date().getFullYear();
    let year2 = new Date(noteDate).getFullYear();

    if (day1 === day2 && month1 === month2 && year1 === year2) return true;
    else return false;
  };

  const removeNote = async () => {
    let isSameDay = false;

    let ind = notes.find((e) => {
      if (deleting === e._id) {
        if (checkDate(e.created_at)) isSameDay = true;
        return e;
      }
    });

    setShow(false);

    if (ind?.staff?._id === userData._id) {
      if (isSameDay) {
        const response = await updateNote(deleting, {
          isDeleted: true,
        });
        if (response?.status !== "Fail") {
          toastify("Note deleted successfully!", "success");
          populateData();
        } else {
          toastify(response?.message, "error");
        }
      } else {
        toastify("Note can be deleted on the same day only.", "error");
      }
    } else {
      toastify("Only note maker can update/delete note.", "error");
    }
  };

  const editNote = (data) => {
    setText(data.description);
    setUpdating(data._id);
  };

  useEffect(() => {
    setData({ ".": dotPhrases });
  }, [dotPhrases]);

  useEffect(() => {
    if (val !== "") {
      setText(text.replaceAll(`.${val}`, val));
    }
  }, [val]);

  useEffect(() => {
    populateData();
  }, [patientId]);

  return (
    <div>
      <div className="nurse-notes-container">
        {notes?.map((note) => (
          <NoteRenderer
            role="nurse"
            isNurse={true}
            note={note}
            handleClose={handleClose}
            show={show}
            setShow={setShow}
            setDeleting={setDeleting}
            editNote={editNote}
          />
        ))}
      </div>

      <div className="m-3 p-3">
        <Mentions
          placeholder="input . to mention people"
          prefix={["."]}
          rows="5"
          value={text}
          onChange={(e) => {
            setText(e);
            setVal("");
          }}
          onSelect={(e) => {
            setVal(e.value);
          }}
          onSearch={(e) => {
            setVal("");
          }}
        >
          {data["."]?.map((e) => (
            <Option key={JSON.stringify(e)} value={e?.description}>
              {e?.title}
            </Option>
          ))}
        </Mentions>
        <DeleteModal
          content="Confirm Nurse Note Deletion?"
          onYes={removeNote}
          onNo={handleClose}
          show={show}
        />
        <Button className="addItem mt-3" onClick={savePatient}>
          Save
        </Button>
      </div>
    </div>
  );
}
