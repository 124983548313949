import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { PDFViewer } from "@react-pdf/renderer";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getBranches, updateBranch } from "../../../apis/branch";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import {
  resetValue,
  setData,
  setForm,
} from "../../../redux/slices/StaffFormsSlices";
import { setPatientData } from "../../../redux/slices/patientSlice";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";
import hamburger from "../../../assets/images/hamburger.png";
import { SESSION_CURRENT_STATE } from "./PatientDetails";
import { useMemo } from "react";
import { Modal, Overlay } from "react-bootstrap";
import billingIcon from "../../../assets/images/billing.png";
import {
  customDateSort,
  customStringSort,
  customTimeSort,
  exportData,
  getFormatedTime,
  adjustDateFormat,
} from "../../../helperFunctions/utils";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import DateInput from "../../SharedComponents/DateInput";
import { getPaymentMethodList } from "../../../apis/paymentMethod";

import { permissionTypes } from "../../../helperFunctions/routes";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import { PDFDownloadLink } from "@react-pdf/renderer";
import MyDoc from "../sessionPDF";
import SortComponent, {
  sortTypes,
} from "../../SharedComponents/SortComponents";
import { getAppointment } from "../../../apis/appointment";
import { getBillingByPatient } from "../../../apis/billing";

export default function SessionView() {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { tableSearch } = useSelector((state) => state.commonData);
  const fieldPathname = pathname.replace("/", "");

  const [filteredData, setFilteredData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [openContainer, setOpenContainer] = useState(true);
  const [itemToDel, setItemToDel] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [closeSessionModal, setCloseSessionModal] = useState(false);
  const [sortedArray, setSortedArray] = useState([]);
  const [selectedSessionData, setSelectedSessionData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [stateDate, setStateDate] = useState(tableSearch[fieldPathname]?.currentDate ? tableSearch[fieldPathname].currentDate : new Date());
  const [reloadSession, setReloadSession] = useState(true);
  const [amountToPayData, setAmountToPayData] = useState(0);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData.value);
  const isDoctor = userData?.department?.name
    ?.toLowerCase()
    ?.includes("doctor");

  const { appointmentDataForSession, sessionData, sessionForm } = useSelector(
    (state) => state.staffForm
  );

  const getPaymentMethods = async () => {
    const { result, error } = await getPaymentMethodList();
    if (error) {
      return;
    }
    setPaymentMethods(result.data);
  };

  const getList = async () => {
    const { result, error } = await authorizedRequests({
      url: "appointment/listOfAppointments",
      method: "get",
      params: {
        date: stateDate,
      },
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    var tempArr = result.data
      ?.filter((app) => app.status !== "Not Show")
      .map((app) => {
        return {
          id: app._id,
          time: (app.startTime ?? "") + "-" + (app.endTime ?? ""),
          patientName: app?.patient?.patientProfile?.fullName,
          remarks: app?.remarks,
          staff: app?.staff?.map((o) => {
            return o.username;
          }),
          branch: app?.branch?._id,
          staffAllData: app?.staff,
          tag: app.tag.name,
          isOpen: false,
          // service: app.package.name,
          startTime: app.startTime,
          endTime: app.endTime,
          status: app.status ? "Confirmed" : "Planned",
          date: new Date(app.date).toDateString(),
        };
      })
      .filter((o) => {
        return (
          o.date == new Date(stateDate)?.toDateString() &&
          !sessionData?.find((k) => k.appointmentId == o.id)
        );
      });

    dispatch(
      setData({
        parent: "appointmentDataForSession",
        value: tempArr,
      })
    );
    return;
  };

  const onRowEdit = async (id, index) => {
    var tempArr = formData[index];
    const valueObj = {
      appointment: tempArr?.appointment?._id,
      branch: tempArr?.branch?._id,
      date: tempArr?.date,
      location: tempArr.location,
      room: tempArr.room,
      consents: tempArr.consents?.map((o) => {
        return o._id;
      }),
      staff: tempArr.staff?.map((o) => {
        return o._id;
      }),
      status: tempArr.status,
      startTime: tempArr.startTime,
      endTime: tempArr.endTime,
      formStatus: "Edit",
      dataId: tempArr._id,
      patientID: tempArr?.appointment?.patient?._id,
      patientMedicalHistory: {
        ...tempArr.patientMedicalHistory,
      },
      patientSOAP: {
        ...tempArr.patientSOAP,
      },
      patientConsents: [...tempArr.patientConsents],
      patientPrescriptionSheet: [...tempArr.patientPrescriptionSheet],
    };
    dispatch(
      setData({
        parent: "sessionForm",
        value: valueObj,
      })
    );
    navigate("/add-session", {
      state: { valueObj, session: tempArr, stateDate: valueObj?.date },
    });
  };
  const onRowDelete = async (id) => {
    // tempArr =
    const { result, error } = await authorizedRequests({
      url: "session/updateSession",
      method: "post",
      data: {
        dataId: id,
        isDeleted: true,
      },
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    setFilteredData((prev) => prev.filter((e) => e.id !== id));
    getSessionList();
  };

  const viewPatient = async (id) => {
    const result = await getAppointment(id);
    if (result) {
      dispatch(setPatientData(result.patient));
      navigate("/add-patient");
    }
  };

  const getSessionList = async () => {
    const { result, error } = await authorizedRequests({
      url: "session/listOfSession",
      method: "get",
      params: {
        date: stateDate,
      },
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }
    var tempArr = result.data
      ?.filter((a) => a?.isDisplay !== false)
      .map((app) => {
        return {
          id: app._id,
          appointmentId: app.appointment?._id,
          location: app?.branch?.location?.find((o) => o._id === app.location)
            ?.name,
          room: app?.room,
          staff: app?.staff?.map((o) => {
            return o.username;
          }),
          startTime: app.startTime,
          date: new Date(app.date).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }),
          endTime: app.endTime,
          patientName: app?.appointment?.patient?.patientProfile?.fullName,
          patientId: app?.appointment?.patient?._id,
          isNewPatient: app?.isNewPatient,
          needQuestionnaire: app?.appointment?.patient?.needQuestionnaire,
          remarks: app?.envoiceId?.remarkDiscount,
          haveInvoice: app?.envoiceId?._id ? true : false,
          status: app.status,
        };
      });

    setFormData(result.data);

    dispatch(
      setData({
        parent: "sessionData",
        value: tempArr,
      })
    );
    return;
  };

  const getDefaultFilteredData = (values) => {
    let list = [...values];

    if (tableSearch[fieldPathname]) {
      if (tableSearch[fieldPathname].patientName) {
        list = values.filter(v => v.patientName?.toLowerCase()?.includes(tableSearch[fieldPathname].patientName.toLowerCase()));
      }
      if (tableSearch[fieldPathname].location) {
        list = values.filter(v => v.location?.toLowerCase()?.includes(tableSearch[fieldPathname].location?.toLowerCase()));
      }
      if (tableSearch[fieldPathname].staff) {
        list = values.filter(v => v.staff.find(s => s?.toLowerCase().includes(tableSearch[fieldPathname].staff?.toLowerCase())));
      }
      if (tableSearch[fieldPathname].status) {
        const searchStatus = tableSearch[fieldPathname].status.find(s => s.isChecked);
        list = values.filter(v => v.status === searchStatus.name);
      }
    }

    return list;
  }

  const openWaiting = (index) => {
    var tempArr = [...appointmentDataForSession];
    tempArr = tempArr.map((o, i) => {
      if (index === i) {
        return { ...o, isOpen: !o.isOpen };
      } else {
        return { ...o, isOpen: false };
      }
    });
    dispatch(
      setData({
        parent: "appointmentDataForSession",
        value: tempArr,
      })
    );
  };

  const addAppointment = async (appointment) => {
    dispatch(
      setData({
        parent: "sessionForm",
        value: {
          appointment: appointment.id,
          startTime: appointment.startTime,
          branch: appointment.branch,
          endTime: appointment.endTime,
          date: adjustDateFormat(new Date(appointment.date)),
          staff: appointment?.staffAllData?.map((o) => o._id),
          formStatus: "New",
        },
      })
    );
    navigate("/add-session", {
      state: {
        stateDate: stateDate,
        valueObj: {
          appointment: appointment.id,
          startTime: appointment.startTime,
          branch: appointment.branch,
          endTime: appointment.endTime,
          date: adjustDateFormat(new Date(appointment.date)),
          staff: appointment?.staffAllData?.map((o) => o._id),
          formStatus: "New",
        },
      },
    });
  };

  const handleTodaySession = async () => {
    const { result, error } = await authorizedRequests({
      url: "session/closeTodaySessions",
      method: "get",
    });
    setCloseSessionModal(false);
    if (!result?.data) {
      toastify(result?.message, "error");
      return;
    } else {
      toastify(result?.message, "success");
      setPaymentData(result.data);
      setShowPDFModal(true);
      return;
    }
  };

  // get patient billing status payment
  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const getAmountToPay = async (patientId) => {
    const { result, error } = await getBillingByPatient(patientId);

    if (error) {
      if (error?.message === "No data Found") {
        return;
        // setAmountToPay(0);  // Reset amount to 0 if no data is found
        // setPaymentComplete(false);  // No data, payment not complete
      } else {
        toastify(error?.message, "error");
      }
      return;
    } else {
      let totalAmountToPay = 0;
    
      result?.forEach((e) => {
        const statusPayment = getStatusPayment(e);
  
        if (statusPayment === "Paid") {
          return; // Skip if paid, no need to adjust amountToPay
        }
  
        const payed = e?.paymentRecord.reduce(
          (acc, current) => Number(current.currentPaidAmount) + acc,
          0
        );
        const outstandingAmount = (Number(e.gst) - payed) || 0;
  
        // if (outstandingAmount > 0) {
         
        // }
  
        totalAmountToPay += outstandingAmount;
      });

      return parseFloat(totalAmountToPay.toFixed(2));
    }
  };

  // Fetch `amountToPay` for each patient asynchronously
useEffect(() => {
  const fetchAmountToPay = async () => {
    const updatedAmountToPayData = {};

    await Promise.all(
      getDefaultFilteredData(sessionData)?.map(async (e) => {
        const amountToPay = await getAmountToPay(e.patientId).catch(err => {
          return 0;  // Return 0 if there's an error
        });
        
        // Store the amount to pay for each patient
        updatedAmountToPayData[e.patientId] = amountToPay;
      })
    );

    setAmountToPayData(updatedAmountToPayData);  // Update the state
  };

  fetchAmountToPay();
}, [sessionData]);

  useEffect(() => {
    getSessionList();
    getPaymentMethods();
    dispatch(resetValue("billingForm"));
    dispatch(resetValue("selectedSession"));
    dispatch(resetValue("sessionForm"));
  }, [stateDate, reloadSession]);

  useMemo(() => {
    getList();
  }, [sessionData, stateDate, reloadSession]);

  const handlePDFClick = async (session) => {
    setSelectedSessionData(session);
    const payload = {
      sessionId: session.id,
    };
    const result = await authorizedRequests({
      url: "payment/getPaymentbySessionId",
      method: "post",
      data: payload,
    });
    let data = result.result.data;
    data = { ...data, session: session };
    setPaymentData([data]);
    setShowPDFModal(true);
  };

  const PatientTooltipCell = ({ row, patientName, amountToPay }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
  
    return (
      <div className="d-flex align-items-center">
        {
          row.isNewPatient ? (
            <div
              style={{
                backgroundColor: "#67dd67",
                padding: "5px",
                width: "100%",
              }}
              onClick={() => viewPatient(formData[row.index]?.appointment?._id)}
            >
              {row.patientName}
            </div>
          ) : (
            <div
              onClick={() => viewPatient(formData[row.index]?.appointment?._id)}
            >
              {row.patientName}
            </div>
          )
        }
  
        {/* Warning Icon */}
        {amountToPay > 0 && (
          <div
            ref={target}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
            style={{ cursor: 'pointer', marginLeft: "5px" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              shapeRendering="geometricPrecision"
              textRendering="geometricPrecision"
              imageRendering="optimizeQuality"
              fillRule="evenodd"
              clipRule="evenodd"
              viewBox="0 0 512 512"
              width="16px" // You can adjust the size
              height="16px" // You can adjust the size
            >
              <path
                fill="#A82C1F"
                fillRule="nonzero"
                d="M256 0c70.686 0 134.69 28.658 181.016 74.984C483.342 121.31 512 185.314 512 256c0 70.686-28.658 134.69-74.984 181.016C390.69 483.342 326.686 512 256 512c-70.686 0-134.69-28.658-181.016-74.984C28.658 390.69 0 326.686 0 256c0-70.686 28.658-134.69 74.984-181.016C121.31 28.658 185.314 0 256 0z"
              />
              <circle fill="#D03827" cx="256" cy="256" r="226.536" />
              <path
                fill="#fff"
                fillRule="nonzero"
                d="M275.546 302.281c-.88 22.063-38.246 22.092-39.099-.007-3.779-37.804-13.444-127.553-13.136-163.074.312-10.946 9.383-17.426 20.99-19.898 3.578-.765 7.512-1.136 11.476-1.132 3.987.007 7.932.4 11.514 1.165 11.989 2.554 21.402 9.301 21.398 20.444l-.044 1.117-13.099 161.385zm-19.55 39.211c14.453 0 26.168 11.717 26.168 26.171 0 14.453-11.715 26.167-26.168 26.167s-26.171-11.714-26.171-26.167c0-14.454 11.718-26.171 26.171-26.171z"
              />
            </svg>
          </div>
        )}
  
        {/* Tooltip Overlay */}
        {amountToPay > 0 && (
          <Overlay target={target.current} show={show} placement="right">
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                {...props}
                style={{
                  position: 'absolute',
                  backgroundColor: 'black',
                  padding: '2px 10px', // Adjust padding to reduce size
                  fontSize: '12px', // Decrease font size
                  color: 'white',
                  borderRadius: 3,
                  maxWidth: '200px', // Optionally set a max-width
                  ...props.style,
                }}
              >
                ${amountToPay} Payment Billing Left
              </div>
            )}
          </Overlay>
        )}
      </div>
    );
  };
  
  
  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Date</span>
          {/* {
            <FilterComponent
              type="date"
              array={sessionData}
              setArray={setFilteredData}
              value="date"
            />
          } */}
        </div>
      ),
      selector: (row) => row.date,
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "date"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Location</span>
          {
            <FilterComponent
              type="search"
              array={filteredData.length > 0 ? filteredData : sessionData}
              setArray={setFilteredData}
              searchValue={tableSearch[fieldPathname]?.location}
              value="location"
            />
          }
        </div>
      ),
      selector: (row) => row.location,
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "location"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Patient</span>
          {
            <FilterComponent
              type="search"
              array={sessionData}
              setArray={setFilteredData}
              searchValue={tableSearch[fieldPathname]?.patientName}
              value="patientName"
            />
          }
        </div>
      ),
      selector: (row) => row.patientName,
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "patientName"),
      // cell: (row) => {
      //   if (row.isNewPatient === true) {
      //     return (
      //       <div
      //         style={{
      //           backgroundColor: "#67dd67",
      //           padding: "5px",
      //           width: "100%",
      //         }}
      //         onClick={() => viewPatient(formData[row.index]?.appointment?._id)}
      //       >
      //         {row.patientName}
      //       </div>
      //     );
      //   }

      //   return (
      //     <div
      //       onClick={() => viewPatient(formData[row.index]?.appointment?._id)}
      //     >
      //       {row.patientName}
      //     </div>
      //   );
      // },
      cell: (row) => (
        <PatientTooltipCell
          row={row}
          patientName={row.patientName}
          amountToPay={row.amountToPay}
        />
      ),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Staff</span>
          {
            <FilterComponent
              type="search"
              array={sessionData}
              setArray={setFilteredData}
              subArray
              value="staff"
              searchValue={tableSearch[fieldPathname]?.staff}
            />
          }
        </div>
      ),
      selector: (row) => row.staff,
      wrap: true,
      width: "160px",
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Remark</span>
        </div>
      ),
      selector: (row) => row.remarks,
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "remarks"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Time In</span>
        </div>
      ),
      selector: (row) => row.startTime,
      wrap: true,
      width: "140px",
      sortable: true,
      sortFunction: (a, b) => customTimeSort(a, b, "startTime"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Status</span>
          {
            <FilterComponent
              type="radio-search"
              array={sessionData}
              setArray={setFilteredData}
              subArray
              options={[
                {
                  id: "Cancelled",
                  name: "Cancelled",
                },
                {
                  id: "Pending",
                  name: "Pending",
                },
                {
                  id: "Completed",
                  name: "Completed",
                },
              ]}
              value="status"
              searchOption={tableSearch[fieldPathname]?.status}
            />
          }
        </div>
      ),
      selector: (row) => row.status,
      wrap: true,
      width: "160px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: isDoctor ? "80px" : "200px",
    },
  ];

  const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
		  [fieldPathname]: {
        ...tableSearch[fieldPathname],
			  currentDate: value
		  }
		}))
  }

  const dataSource = sessionData;

  // const filteredSource = isDoctor
  //   ? dataSource.filter((e) => e.staff.includes(userData.username))
  //   : dataSource;

  const data = getDefaultFilteredData(dataSource)
    ?.map((e, index) => {
      const formDataIndex = formData.findIndex(f => f._id === e.id);

      const amountToPay = amountToPayData[e.patientId] || 0;

      return {
        id: e.id,
        index: formDataIndex < 0 ? index : formDataIndex,
        date: e.date,
        startTime: getFormatedTime(e.startTime),
        location: e.location,
        patientName: e.patientName,
        isNewPatient: e.isNewPatient,
        needQuestionnaire: e.needQuestionnaire,
        remarks: e.remarks,
        staffText: e.staff?.join(","),
        statusText: e.status,
        amountToPay: amountToPay,
        status: (
          <>
            <div
              style={{
                minWidth: "85px",
                paddingLeft: "0",
                paddingRight: "0",
              }}
              className={`${
                e.status == "Completed"
                  ? "green-rounded-button"
                  : e.status == "Pending"
                  ? "brown-rounded-button"
                  : "red-rounded-button"
              }`}
            >
              {`${
                e.status == "Completed"
                  ? "Completed"
                  : e.status == "Pending"
                  ? "Pending"
                  : e.status === "Closed"
                  ? "Closed"
                  : "Cancelled"
              }`}
            </div>
          </>
        ),
        staff: (
          <>
            {e.staff.map((o) => {
              return <div className="">{o}</div>;
            })}
          </>
        ),

        action: (
          <div className="d-flex" style={{ alignItems: "center" }}>
            <React.Fragment>
              {!isDoctor && (
                <React.Fragment>
                  <ComponentWrapepr
                    permissionType={permissionTypes.UPDATE}
                    moduleName={"Session"}
                  >
                    <div
                      className="  cursor-pointer icon-width "
                      onClick={() => {
                        onRowEdit(e.id, formDataIndex);
                      }}
                    >
                      <i className="fa fa-pen"></i>
                    </div>
                  </ComponentWrapepr>
                  <ComponentWrapepr
                    permissionType={permissionTypes.UPDATE}
                    moduleName={"Session"}
                  >
                    <div
                      className=" cursor-pointer icon-width "
                      onClick={() => {
                        window.open(`/questions/${e.patientId}`);
                      }}
                    >
                      <i
                        class="fa fa-clipboard-question"
                        style={{ fontSize: "18px" }}
                      ></i>
                    </div>
                  </ComponentWrapepr>
                </React.Fragment>
              )}
              <ComponentWrapepr
                permissionType={permissionTypes.UPDATE}
                moduleName={"Session"}
              >
                <div
                  className="  cursor-pointer icon-width"
                  onClick={() => {
                    const tempArr = formData[formDataIndex];
                    const valueObj = {
                      dataId: tempArr?._id,
                      appointment: tempArr?.appointment?._id,
                      branch: tempArr?.branch?._id,
                      date: tempArr?.date,
                      location: tempArr?.location,
                      room: tempArr?.room,
                      consents: tempArr?.consents?.map((o) => {
                        return o._id;
                      }),
                      staff: tempArr?.staff?.map((o) => {
                        return o._id;
                      }),
                      status: tempArr?.status,
                      startTime: tempArr?.startTime,
                      endTime: tempArr?.endTime,
                      formStatus: "Edit",
                      patientID: tempArr?.appointment?.patient?._id,
                      patientMedicalHistory: {
                        ...tempArr?.patientMedicalHistory,
                      },
                      patientSOAP: {
                        ...tempArr?.patientSOAP,
                      },
                      patientConsents: [...tempArr?.patientConsents],
                      patientChart: [...tempArr?.patientChart],
                      patientPrescriptionSheet: [
                        ...tempArr?.patientPrescriptionSheet,
                      ],
                      photoSession: tempArr?.photoSession,
                    };
                    dispatch(
                      setData({
                        parent: "sessionForm",
                        value: valueObj,
                      })
                    );
                    dispatch(setPatientData(tempArr?.appointment?.patient));
                    if (!isDoctor) {
                      localStorage.setItem(
                        "session_current_state",
                        SESSION_CURRENT_STATE.patientConsents
                      );
                    } else {
                      localStorage.setItem(
                        "session_current_state",
                        SESSION_CURRENT_STATE.patientMedicalHistory
                      );
                    }
                    navigate("/patient-details", {
                      state: { valueObj },
                    });
                    // onRowEdit(e.id, index);
                  }}
                >
                  <i className="fa fa-server" style={{ color: "#34556F" }}></i>
                </div>
              </ComponentWrapepr>
              {!isDoctor && (
                <React.Fragment>
                  <ComponentWrapepr
                    permissionType={permissionTypes.CREATE}
                    moduleName={"Finance"}
                  >
                    <div
                      className="cursor-pointer icon-width"
                      onClick={() => {
                        const sessionClicked = formData.find(
                          (o) => o?._id == e?.id
                        );

                        if (sessionClicked?.envoiceId?._id) {
                          authorizedRequests({
                            url: "payment/listOfPayments",
                            method: "get",
                            params: {
                              _id: sessionClicked?.envoiceId?._id,
                            },
                          }).then((res) => {
                            if (res?.result?.data?.length > 0) {
                              const sessionBilling = res?.result?.data[0];
                              navigate("/add-billing", {
                                state: {
                                  session: sessionBilling.session,
                                  branch: sessionBilling?.branch,
                                  location: sessionBilling?.location,
                                  paymentMethod: sessionBilling?.paymentMethod,
                                  patient: sessionBilling.patient,
                                  formStatus: "Edit",
                                  dataId: sessionBilling._id,
                                  totalAmount: sessionBilling.totalAmount,
                                  categoryCosts: sessionBilling.categoryCosts,
                                  tax: sessionBilling.tax,
                                  promo: sessionBilling.promo,
                                  promoType: sessionBilling.promoType,
                                  usePoints: sessionBilling.usePoints,
                                  recommend: sessionBilling.recommend,
                                  remarks: sessionBilling.remarks,
                                  campaign: sessionBilling.campaign,
                                  package: [...sessionBilling?.package],
                                  others: [...sessionBilling?.others],
                                  paymentAdjustments: [...sessionBilling?.paymentAdjustments],
                                  product: [...sessionBilling?.product],
                                  treatment: [...sessionBilling?.treatment],
                                  rewardPoints: sessionBilling?.rewardPoints,
                                  status: sessionBilling?.isPaymentCompleted,
                                  remarkDiscount: sessionBilling?.remarkDiscount,
                                  recommend: sessionBilling.recommend
                                },
                              });
                            } else {
                              navigate("/add-billing", {
                                state: { session: sessionClicked },
                              });
                            }
                          })
                        } else {
                          navigate("/add-billing", {
                            state: { session: sessionClicked },
                          });
                        }
                        // onRowDelete(e.id, index);
                      }}
                    >
                      <img alt="" src={billingIcon} className=" " />
                    </div>
                  </ComponentWrapepr>

                  {!e.haveInvoice ? (
                    <ComponentWrapepr
                      permissionType={permissionTypes.DELETE}
                      moduleName={"Session"}
                    >
                      <div
                        className="  cursor-pointer icon-width"
                        onClick={() => {
                          setItemToDel(e.id);
                          setOpenModal(true);
                          // onRowDelete(e.id, index);
                        }}
                      >
                        <i className="fa fa-trash"></i>
                      </div>
                    </ComponentWrapepr>
                  ) : (
                    <div className="icon-width"></div>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
        ),
      };
    })
    .reverse();
  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            ></i>
            Sessions
          </h1>
          <div className="d-flex">
            <div className="me-3" style={{ width: "200px" }}>
              <DateInput
                value={stateDate}
                placeholder="Date"
                shouldDisableDates={true}
                onChange={(e) => {
                  setStateDate(e);
                  onSubmitRedux(e);
                }}
              />
            </div>
            <Button
              className="addItem me-3"
              onClick={() => {
                setCloseSessionModal(!closeSessionModal);
              }}
            >
              End Session
            </Button>
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((o) => {
                  return {
                    Location: o.location,
                    Room: o.room,
                    "Patient Name": o.patientName,
                    Staff: o?.staffText,
                    Remarks: o.remarks,
                    "Time In": o.startTime,
                    "Time Out": o.endTime,
                    status: o?.statusText,
                  };
                });
                exportData(tempDataArr, "Session");
              }}
            >
              Export
            </Button>
            <ComponentWrapepr
              permissionType={permissionTypes.CREATE}
              moduleName={"Session"}
            >
              <Button
                className="addItem px-5"
                onClick={() => {
                  dispatch(resetValue("sessionForm"));
                  navigate("/add-session", {
                    state: { stateDate: stateDate },
                  });
                }}
              >
                + Add New
              </Button>
            </ComponentWrapepr>
          </div>
        </div>
        {/* 
				<DeleteModal
					content="Confirm Branch Deletion?"
					onYes={removeBranch}
					onNo={handleClose}
					show={show}
				/> */}
        <div className="d-flex pb-3" style={{ overflowX: "auto" }}>
          <div
            style={{
              width: !isDoctor
                ? `${
                    openContainer ? "calc(100% - 280px)" : "calc(100% - 200px)"
                  }`
                : "100%",
            }}
          >
            <DataTable
              columns={columns}
              data={data || []}
              pagination
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
              // responsive="true"
            />
          </div>
          {openModal && (
            <>
              <Modal
                centered
                show={openModal}
                onHide={() => {
                  setItemToDel(null);

                  setOpenModal(!openModal);
                }}
              >
                <Modal.Body className="font-18">
                  Confirm Session Deletion
                  <div className="align-self-end m-3 d-flex">
                    <Button
                      className="me-3 delete-no ms-auto"
                      onClick={() => {
                        setOpenModal(false);
                      }}
                    >
                      No
                    </Button>
                    <Button
                      className="delete-yes"
                      onClick={() => {
                        onRowDelete(itemToDel);
                        setOpenModal(false);
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
          <Modal
            centered
            show={closeSessionModal}
            onHide={() => {
              setItemToDel(null);

              setOpenModal(!closeSessionModal);
            }}
          >
            <Modal.Body className="font-18">
              Are you sure want to end all the session for today ?
              <div className="align-self-end m-3 d-flex">
                <Button
                  className="me-3 delete-no ms-auto"
                  onClick={() => {
                    setCloseSessionModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="delete-yes"
                  onClick={() => {
                    handleTodaySession();
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          {!isDoctor && (
            <div
              className={`${openContainer ? "open-animate" : "close-animate"}`}
            >
              <div className="header d-flex align-items-center">
                <img
                  className="img img-fluid hamburgerIcon pointer"
                  onClick={() => {
                    setOpenContainer(!openContainer);
                  }}
                  src={hamburger}
                  alt=""
                />
                <div
                  className="h5"
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    marginBottom: "0px",
                  }}
                >
                  Appointments
                </div>
              </div>
              {/* {moment().isSame(moment(stateDate), "day") && ( */}
              <React.Fragment>
                {appointmentDataForSession.length > 0 &&
                  appointmentDataForSession.map((o, i) => {
                    return (
                      <div className="waiting-card">
                        <div
                          className={`d-flex pointer ${
                            o.isOpen ? "top-bar" : "p-1"
                          }`}
                          onClick={() => {
                            // if (openContainer) {
                            openWaiting(i);
                            // }
                          }}
                        >
                          <div className="d-flex">
                            <span
                              className="top-text"
                              style={{ fontSize: "13px", minWidth: "70px" }}
                            >
                              {getFormatedTime(o.startTime)}
                            </span>
                            <div
                              className="top-text ms-3"
                              style={{
                                wordBreak: "break-all",
                                fontSize: "13px",
                              }}
                            >
                              {o.patientName}
                            </div>
                          </div>
                        </div>

                        {o.isOpen && (
                          <div className="card-body" style={{fontSize: "13px"}}>
                            <div className="my-1" style={{display: "grid", gridTemplateColumns: "40% 60%", gap: "5px"}}>
                              <span style={{display: "block", width: "40%"}}>Appointment Time</span>
                              <span style={{ textAlign: "right" }}>
                                {getFormatedTime(o.startTime)} -{" "}
                                {getFormatedTime(o.endTime)}{" "}
                              </span>
                            </div>
                            <>
                              <div className="my-1" style={{display: "grid", gridTemplateColumns: "40% 60%", gap: "5px"}}>
                                <span style={{display: "block", width: "40%"}}>Staff</span>
                                <span style={{ textAlign: "right" }}>
                                {o.staff.map((staf, indexstaff) => {
                                    return (
                                      `${staf} ${indexstaff !== o.staff.length - 1 ? ", " : ""}`
                                    );
                                  })}
                                </span>
                              </div>
                            </>
                            {/* <div className="d-flex justify-content-between my-1">
                              <span>Service</span>
                              <span>{o.service}</span>
                            </div> */}
                            <div className="w-100 df-center my-2">
                              <Button
                                className="white-rounded-button"
                                onClick={() => {
                                  // console.log(o);
                                  addAppointment(o);
                                }}
                                style={{fontSize: "13px"}}
                              >
                                Register Session
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </React.Fragment>
              {/* )} */}
            </div>
          )}
        </div>
      </div>
      <Modal
        centered
        show={showPDFModal}
        onHide={() => {
          setShowPDFModal(false);
          setReloadSession(!reloadSession);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Session Report
          </Modal.Title>
        </Modal.Header>

        <div className="pdf-btn">
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <MyDoc payment={paymentData} paymentMethods={paymentMethods} />
          </PDFViewer>
        </div>
      </Modal>
    </>
  );
}
