import api from "../axios/axios-config";

export const getMedicalInfo = async (patientId) => {
  try {
    let response = await api.post(
      "patientMedicalInfo/listOfPatientMedicalInfo",
      {
        patientId,
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateMedicalInfo = async (type, date, description, infoId) => {
  try {
    let response = await api.post(
      "patientMedicalInfo/updatePatientMedicalInfo",
      {
        type,
        date: date ? date : null,
        description,
        infoId,
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addMedicalInfo = async (type, date, description, patient) => {
  try {
    let response = await api.post("patientMedicalInfo/addPatientMedicalInfo", {
      type,
      date: date ? date : null,
      description,
      patient,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteMedicalInfo = async (infoId) => {
  try {
    let response = await api.post(
      "patientMedicalInfo/updatePatientMedicalInfo",
      {
        infoId,
        isDeleted: true,
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
