import React, { useState, useEffect } from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getProducts, updateProducts } from "../../../../apis/products";
import {
  getAdjustmentStock,
  updateAdjustmentStock,
} from "../../../../apis/adjustmentStock";
import { toastify } from "../../../../helperFunctions/toastify";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import { SearchIcon } from "../../../../assets/images/index";
import FilterComponent from "../../../SharedComponents/FilterComponent";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";

export default function AdjustmentStock() {
  const navigate = useNavigate();

  const [stock, setStock] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [itemShow, setItemShow] = useState(false);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [show2, setShow2] = useState(false);
  const [updating, setUpdating] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();

  const [typeShow, setTypeShow] = useState(false);
  useEffect(() => {
    if (selectedProduct) {
      setSearchItem(selectedProduct?.name);
    }
  }, [selectedProduct]);
  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setUpdating("");
  };

  const populateData = async () => {
    const response = await getAdjustmentStock();
    if (response?.status !== "Fail") {
      setStock(response.filter((e) => !e.isDeleted).reverse());
      console.log({ response });
    } else {
      if (response?.message === "No data Found") {
        setStock([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removeStock = async () => {
    setShow(false);
    let response = await updateAdjustmentStock(deleting, {
      isDeleted: true,
    });
    if (response?.status !== "Fail") {
      populateData();
      toastify("Adjustment Stock deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editProduct = (e) => {
    navigate("/bulk-adjustment-stock", {
      state: {
        stock: e,
      },
    });
  };

  const addProduct = () => {
    navigate("/add-adjustment-stock");
  };

  const getDifference = (currentQty, physicalQ) => {
    let diff = physicalQ - currentQty;
    return diff;
  };

  const switchStatus = async () => {
    setShow2(false);
    let response = await updateAdjustmentStock(updating, {
      isFinalized: true,
    });
    if (response?.status !== "Fail") {
      populateData();
      toastify("Switched Status successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const columns = [
    {
      name: "Date",
      selector: (row) =>
        new Date(row.created_at).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
      wrap: true,
      width: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "created_at", true),
    },
    {
      name: "Created By",
      selector: (row) => row.username,
    },
    {
      name: "Items",
      selector: (row) => row.items,
      cell: (row) => {
        return (
          <div>
            {
              row.items?.map((item, index) => (
                <div key={index}>{item?.product?.name ?? ""}</div>
              ))
            }
          </div>
        )
      }
    },
    // {
    //   name: "Batch No",
    //   selector: (row) => row.product.batchNumber || "N/A",
    //   wrap: true,
    //   sortable: true,
    //   sortFunction: (a, b) =>
    //     customStringSort(a, b, "product.batchNumber", true),
    // },
    // {
    //   name: "Item Name",
    //   selector: (row) => row.product.name,
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customStringSort(a, b, "product.name", true),
    // },
    // {
    //   name: "Type Name",
    //   selector: (row) => row.product.type.name || "N/A",
    //   wrap: true,
    //   width: "170px",
    // },
    // {
    //   name: "Location",
    //   selector: (row) => row?.location || "N/A",
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customStringSort(a, b, "location"),
    // },
    // {
    //   name: "Current Quantity",
    //   selector: (row) => row.currentQty?.toLocaleString(),
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customNumberSort(a, b, "currentQty"),
    // },
    // {
    //   name: "Physical Quantity",
    //   selector: (row) => row.qty?.toLocaleString(),
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customNumberSort(a, b, "qty"),
    // },
    // {
    //   name: "Difference",
    //   selector: (row) => row.difference?.toLocaleString(),
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customNumberSort(a, b, "difference"),
    // },
    // {
    //   name: "Remarks",
    //   selector: (row) => row.remarks,
    //   wrap: true,
    //   width: "170px",
    //   sortable: true,
    //   sortFunction: (a, b) => customStringSort(a, b, "remarks"),
    // },
    {
      name: "Remark",
      selector: (row) => row.remarks,
      wrap: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "160px",
    },
  ];

  const data = (filteredData.length === 0 ? stock : filteredData)?.map(
    (e, index) => {
      const addEditAction = (
        <div className="d-flex">
          <div
            className="mx-3 cursor-pointer icon-width"
            onClick={() => {
              editProduct(e);
            }}
          >
            <i className="fa fa-pen"></i>
          </div>
          <div
            className="mx-3 cursor-pointer icon-width"
            onClick={() => {
              setDeleting(e._id);
              setShow(true);
            }}
          >
            <i className="fa fa-trash"></i>
          </div>
        </div>
      );
      const viewAction = (
        <div className="d-flex">
          <div
            className="mx-3 cursor-pointer icon-width"
            onClick={() => {
              editProduct(e);
            }}
          >
            <i className="fa fa-eye"></i>
          </div>
        </div>
      );
      const returnAction = !e.isFinalized ? addEditAction : viewAction;
      return {
        index: index,
        id: e._id,
        username: e.createdBy?.username,
        created_at: e.created_at,
        items: e.items,
        // qty: e.physicalQty,
        // difference: getDifference(e.currentQty, e.physicalQty),
        remarks: e.remarks,
        // product: e.product,
        // batchNumber: e.batchNumber,
        // currentQty: e.currentQty,
        // location: e.product.branch.location.find(
        //   (f) => f._id === e.product.location
        // )?.name,
        // status: e.isFinalized,
        // isFinalized:
        //   e.isFinalized === true ? (
        //     <div className="finalized-status">Finalized</div>
        //   ) : (
        //     <div className="draft-status">Draft</div>
        //   ),
        // isFinalized:
        //   e.isFinalized === true ? (
        //     <div className="active-finalized">Finalized</div>
        //   ) : (
        //     <div
        //       className="inactive-finalized"
        //       onClick={() => {
        //         setUpdating(e._id);
        //         setShow2(true);
        //       }}
        //     >
        //       Draft
        //     </div>
        //   ),
        action: returnAction,
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Adjustment Stock</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  return {
                    Date: new Date(e.created_at).toLocaleDateString(),
                    "Batch Number": e?.product?.batchNumber || "N/A",
                    "Item Name": e?.product?.name,
                    "Type Name": e?.product?.type.name,
                    Location: e.location,
                    "Current Quantity": e.currentQty,
                    Quantity: e.qty,
                    Difference: e.difference,
                    Remarks: e.remarks,
                    status: e.status ? "Finalized" : "Draft",
                  };
                });
                exportData(tempDataArr, "Adjustment Stocks");
              }}
            >
              Export
            </Button>
            {/* <Button className="addItem me-3" onClick={addProduct}>
              + Add New
            </Button> */}
            <Button
              className="addItem"
              onClick={() => navigate("/bulk-adjustment-stock")}
            >
              Add Bulk Stock
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Switch Status to Finalized?"
          onYes={switchStatus}
          onNo={handleClose2}
          show={show2}
        />

        <DeleteModal
          content="Confirm Adjustment Stock Deletion?"
          onYes={removeStock}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
