import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

// import ModalPdfViewer from "../../SharedComponents/ModalPdfViewer";
import { convertFileToBase64 } from "../../../apis/document";
import { updateSession, uploadSessionFiles } from "../../../apis/session";
import { useSelector } from "react-redux";
import { toastify } from "../../../helperFunctions/toastify";
import {
	createCaseFolder,
	getCaseFoldersByPatient,
	updateCaseFolder,
	updateFolderDocuments,
} from "../../../apis/caseFolder";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";

function CaseFolder() {
	const patientData = useSelector((state) => state.patientData.value);

	const [folders, setFolders] = useState([]);
	const [selectedFolder, setSelectedFolder] = useState(null);
	const [pdfBase64, setPdfBase64] = useState(null);
	const [reload, setReload] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [file, setFile] = useState();
	const [newFolder, setNewFolder] = useState();
	const [folderToEdit, setFolderToEdit] = useState();

	const [folderToDel, setFolderToDel] = useState();
	const [documentToDel, setDocumentToDel] = useState();
	const [openDelModal, setOpenDelModal] = useState();

	const uploadInSession = async (formData) => {
		const { result, error } = await uploadSessionFiles(formData);
		if (error) {
			toastify(error.message ?? "Failed to upload image", "error");
			return;
		}
		toastify("Image Uploaded succesfully", "success");
		setReload(!reload);
		return;
	};

	const updateFolderDoc = async (formData) => {
		const { result, error } = await updateFolderDocuments(formData);
		if (error) {
			toastify(error.message ?? "Failed to upload image", "error");
			return;
		}
		toastify("Image Uploaded succesfully", "success");
		setReload(!reload);
		return;
	};

	const onUpload = async (e) => {
		const file = e.target.files;
		const type = file[0].type;
		if (!type.includes("image") && !type.includes("pdf")) {
			toastify("Only images and pdf are allowed", "error");
			return;
		}
		var base64;
		const reader = new FileReader();
		reader.readAsDataURL(e.target.files[0]);
		reader.onload = async (event) => {
			base64 = event.target.result;
			const base64ToFile = (base64, filename) => {
				const arr = base64.split(",");
				const mime = arr[0].match(/:(.*?);/)[1];
				const bstr = atob(arr[1]);
				let n = bstr.length;
				const u8arr = new Uint8Array(n);

				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}

				return new File([u8arr], filename, { type: mime });
			};
			if (folders.find((o) => o.id == selectedFolder).isSession) {
				const Editedfile = base64ToFile(base64, file[0]?.name);
				const formData = new FormData();
				formData.append("dataId", selectedFolder);
				formData.append("file", Editedfile);
				formData.append("name", file[0]?.name);
				formData.append("type", type.includes("image") ? "img" : "pdf");
				formData.append("isDefault", false);
				await uploadInSession(formData);
				return;
			} else {
				const Editedfile = base64ToFile(base64, file[0]?.name);
				const formData = new FormData();
				formData.append("dataId", selectedFolder);
				formData.append("file", Editedfile);
				formData.append("name", file[0]?.name);
				formData.append("type", type.includes("image") ? "img" : "pdf");

				await updateFolderDoc(formData);
				return;
			}
		};
	};

	const onFolderEdit = async (data, isEditing) => {
		const { result, error } = await updateCaseFolder({
			...data,
		});
		if (error) {
			toastify(error?.message, "error");
			return;
		}
		toastify(
			`${isEditing ? "Updated" : "Deleted"} successfully`,
			"success",
		);
		if (isEditing) {
			setNewFolder();
			setFolderToEdit();
		}
		setReload(!reload);
		return "";
	};

	const addNewFolder = async () => {
		if (!newFolder) {
			toastify("Folder name is missing", "error");
			return;
		}
		if (folderToEdit) {
			onFolderEdit(
				{
					name: newFolder,
					dataId: folderToEdit,
				},
				true,
			);
			return;
		}
		const { result, error } = await createCaseFolder({
			name: newFolder,
			patientId: patientData?._id,
		});
		if (error) {
			toastify(error.message, "error");
			return;
		}
		toastify("Folder created successfully", "success");
		setNewFolder();
		setFolderToEdit();
		setReload(!reload);
	};

	const onDocumentDelete = async (id) => {
		const folder = folders.find((o) => o.id == selectedFolder);
		if (!folder.isSession) {
			onFolderEdit({
				dataId: folder.folderId,
				documents: folder?.allDocuments?.filter((o, i) => {
					return o._id !== id;
				}),
			});
		} else {
			const updatedPatientCharts = folder?.patientChart?.filter(
				(o) => o._id !== id,
			);
			const updatedPatientConsents = folder?.patientConsents?.filter(
				(o) => o._id !== id,
			);
			const { result, error } = await updateSession({
				dataId: folder.id,
				patientChart: updatedPatientCharts,
				patientConsents: updatedPatientConsents,
			});
			if (error) {
				toastify("Failed to delete document", "error");
				return;
			}
			console.log(reload);
			setReload(!reload);
			return;
		}
	};

	const onViewDocument = (document) => {
		// if (document.type !== "consent") {
		setOpenModal(true);
		setFile(document);

		if (document.type === "pdf") {
			convertFileToBase64({ url: document.link }).then((res) => {
				setPdfBase64(
					`data:application/${document.type};base64, ${res.data}`,
				);
				// setOpen(true);
			});
		}
		// } else {
		// 	console.log("claled", document);
		// }
	};

	useEffect(() => {
		if (patientData?._id) {
			(async () => {
				const { result, error } = await getCaseFoldersByPatient(
					patientData?._id,
				);
				if (error) {
					setFolders([]);
					toastify(
						error.message ?? "Failed to get patient folders",
						"error",
					);
					return;
				}
				const tempArr = result?.data?.map((ses) => {
					const charts =
						ses?.session?.patientChart?.map((doc) => {
							return {
								name: doc.name,
								id: doc?._id,
								type: doc?.file_type ?? "img",
								link: doc.link,
								createdAt: doc.date
									? new Date(doc.date).toLocaleDateString()
									: new Date(
											ses?.session.date,
									  ).toLocaleDateString(),
								isDefault: doc.isDefault,
							};
						}) ?? [];
					const otherFolders =
						ses?.documents?.map((doc) => {
							return {
								name: doc.name,
								id: doc?._id,
								type: doc?.file_type ?? "img",
								link: doc.link,
								createdAt: new Date(
									ses.created_at,
								).toLocaleDateString(),
							};
						}) ?? [];
					const consents =
						ses?.session?.patientConsents?.map((con) => {
							const consent_detail = ses?.session?.consents?.find(
								(o) => o._id == con.consent,
							);
							return {
								name: consent_detail?.title,
								url: consent_detail?.url,
								type: "consent",
								id: con?.consent,
								patientSign: con?.pateintSign,
								doctorSign: con?.doctorSign,
							};
						}) ?? [];
					// const doc_consents =
					// 	ses?.session?.patientConsents?.map((con) => {
					// 		return {
					// 			name: "Doctor Sign",
					// 			type: "img",
					// 			id: con?._id,
					// 			link: con?.doctorSign,
					// 		};
					// 	}) ?? [];
					const photoSessions = ses?.session?.photoSession ? [
						{
							name: `Photo`,
							id: ses?.session.photoSession,
							type: "img",
							link: ses?.session.photoSession,
							createdAt: new Date(
								ses?.session.date,
							).toLocaleDateString(),
						}
					] : [];
					const sessionObj = {
						name:
							ses.name ??
							new Date(ses?.session?.date).toLocaleDateString(),
						createdAt: new Date(
							ses?.created_at,
						).toLocaleDateString(),
						isSession: ses?.session?._id ? true : false,
						id: ses?.session?._id ? ses.session?._id : ses?._id,
						charts,
						folderId: ses?._id,
						allDocuments: [...ses?.documents],
						patientConsents: ses?.session?.patientConsents,
						patientChart: ses?.session?.patientChart,
						documents: [
							...charts,
							...otherFolders,
							...consents,
							...photoSessions
							// ...pat_consents,
							// ...doc_consents,
						],
					};
					return sessionObj;
				});
				setFolders([...tempArr]);
			})();
		}
	}, [patientData?._id, reload]);

	return (
		<React.Fragment>
			<Tab.Container id="left-tabs-example" defaultActiveKey="first">
				<Row>
					{(newFolder == "" || newFolder) && (
						<>
							<Col md={7} className="d-flex">
								<Form.Group className="w-100">
									<Form.Label className="required">
										Name
									</Form.Label>
									<div className="d-flex">
										<InputGroup>
											<Form.Control
												value={newFolder}
												onChange={(e) =>
													setNewFolder(e.target.value)
												}
											></Form.Control>
										</InputGroup>
										<Button
											onClick={addNewFolder}
											className="addItem ms-2"
										>
											{folderToEdit ? "Edit" : "Save"}
										</Button>
									</div>
								</Form.Group>
							</Col>
						</>
					)}
					<Col sm={6}>
						<Nav variant="pills" className="flex-column mainTab">
							<div className="header">
								<h4>Folders</h4>
								<button
									className="addBtn"
									onClick={() => {
										setNewFolder("");
										setFolderToEdit();
									}}
								>
									+ New Folder
								</button>
							</div>
							<div className="scrollBar">
								{folders.map((folder, index) => (
									<Nav.Item
										className={selectedFolder === folder.id ? "active-folder" : ""}
										key={index}
										onClick={() =>
											setSelectedFolder(folder.id)
										}
									>
										<Nav.Link eventKey="first">
											<div className="innerLink">
												<img
													src="/imgs/folder.svg"
													alt="folder"
												/>
												<div>
													<p>Session - {folder.name}</p>
												</div>
											</div>
											{!folder.isSession && (
												<>
													<div className="icons">
														<i
															className="fa fa-pen"
															onClick={() => {
																setFolderToEdit(
																	folder.folderId,
																);
																setNewFolder(
																	folder.name,
																);
															}}
														></i>
														{/* <span className="line"></span> */}
														<i
															className="fa fa-trash ms-3"
															onClick={() => {
																setFolderToDel(
																	folder.folderId,
																);
																setOpenDelModal(
																	true,
																);
																setDocumentToDel();
															}}
														></i>
													</div>
												</>
											)}
										</Nav.Link>
									</Nav.Item>
								))}
							</div>
						</Nav>
					</Col>
					<Col sm={6}>
						{
							selectedFolder && (
								<div class="tabContent">
									<div className="header">
										<h4>Documents</h4>
										<div class="wrapper">
											<button className="addBtn">
												<img
													src="/imgs/upload.svg"
													alt="upload"
												/>{" "}
												Upload
											</button>
											<input
												type="file"
												onChange={(e) => {
													if (!selectedFolder) {
														toastify(
															"Please select a folder",
															"error",
														);
														return;
													}
													onUpload(e);
												}}
											/>
										</div>
									</div>
									<div className="scrollBar2">
										<Tab.Content>
											<Tab.Pane eventKey="first">
												{folders
													.find(
														(o, index) =>
															o.id == selectedFolder,
													)
													?.documents?.map(
														(document, index) => (
															<div
																className="document"
																key={index}
															>
																<div
																	className="innerLink pointer"
																	onClick={() =>
																		onViewDocument(
																			document,
																		)
																	}
																>
																	<img
																		src="/imgs/doc.svg"
																		alt="doc"
																	/>
																	<div>
																		<h4 style={{textAlign: "left"}}>
																			{
																				document.name
																			}
																		</h4>
																		<p>
																			{
																				document.createdAt
																			}
																		</p>
																	</div>
																</div>
																{!document.isDefault && (
																	<>
																		<div
																			className="icons"
																			onClick={() => {
																				setDocumentToDel(
																					document.id,
																				);
																				setFolderToDel();
																				setOpenDelModal(
																					true,
																				);
																			}}
																		>
																			<i className="fa fa-trash"></i>
																		</div>
																	</>
																)}
															</div>
														),
													)}
											</Tab.Pane>
										</Tab.Content>
									</div>
								</div>
							)
						}
					</Col>
				</Row>
			</Tab.Container>
			{openModal && (
				<>
					<Modal
						centered
						size={"lg"}
						show={openModal}
						onHide={() => {
							// setItemToDel(null);

							setOpenModal(!openModal);
						}}
					>
						<Modal.Header closeButton></Modal.Header>
						<Modal.Body>
							<>
								{file.type == "img" && (
									<>
										<img
											src={file.link}
											style={{ width: "100%" }}
										/>
									</>
								)}
								{file.type == "pdf" && (
									<>
										<div style={{ height: "80vh" }}>
											<object
												data={pdfBase64}
												type="application/pdf"
												width="100%"
												height="100%"
											>
												<p>
													It appears your Web browser
													is not configured to display
													PDF files. No worries, just{" "}
													<a href={file.type}>
														click here to download
														the PDF file.
													</a>
												</p>
											</object>
										</div>
									</>
								)}
								{file.type == "consent" && (
									<>
										<div>
											<Editor
												editorState={EditorState.createWithContent(
													convertFromRaw(
														JSON.parse(file.url),
													),
												)}
												wrapperClassName="p-0"
												editorClassName=" hidePointer"
												disabled
												toolbarHidden={true}
												wrapperStyle={{
													overflow: "hidden",
													// borderTop:
													// 	"2px solid #e9e9e9",
													padding: "30px 0px",
													marginBottom: "30px",
												}}
											/>
											<div className="d-flex justify-content-between">
												<img
													className=""
													src={file.doctorSign}
													style={{
														background: "#f2f2f2",
														width: "48%",
													}}
												/>
												<img
													className="me-2"
													src={file.patientSign}
													style={{
														background: "#f2f2f2",
														width: "48%",
													}}
												/>
											</div>
										</div>
									</>
								)}
							</>
						</Modal.Body>
					</Modal>
				</>
			)}

			<Modal
				centered
				show={openDelModal}
				onHide={() => {
					setDocumentToDel();
					setFolderToDel();
					setOpenDelModal(!openDelModal);
				}}
			>
				<Modal.Body className="font-18">
					Confirm {folderToDel ? "Folder" : "Document"} Deletion
					<div className="align-self-end m-3 d-flex">
						<Button
							className="me-3 delete-no ms-auto"
							onClick={() => {
								setOpenDelModal(false);
								setFolderToDel();
								setDocumentToDel();
							}}
						>
							No
						</Button>
						<Button
							className="delete-yes"
							onClick={() => {
								if (documentToDel) {
									onDocumentDelete(documentToDel);
								} else if (folderToDel) {
									onFolderEdit({
										dataId: folderToDel,
										isDeleted: true,
									});
								}
								setOpenDelModal(!openDelModal);
							}}
						>
							Yes
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
}

export default CaseFolder;
