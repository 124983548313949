import styled from "styled-components";

export const SignWrapper = styled.div`
  width: 350px;
  background: #F8F8F8;
  border-radius: 12px;

  p {
    margin-bottom: 0px;
  }

  .header-sign {
    border-bottom: 2px solid #E9E9E9;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const OlWrapper = styled.ol`
  font-size: 16px;
  color: rgba(0,0,0,.85);

  .MuiTypography-root {
    color: rgba(0,0,0,.85);
  }

  .child-item {
    display: inline-block;
  }

  li > div {
    display: flex;
    align-items: center;
    height: 55px;

    .child-item {
      width: 382px;
    }
  }

  li > ol li {
    .child-item {
      width: 350px;
    }
  }

  .radio-box {
    display: inline-block;
    label {
      margin-bottom: 0px !important;
    }
  }

  .desc-box {
    p {
      margin-top: 10px;
    }
  }

  .remark-input {
    margin-left: 25px;
  }
`;