import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "../src/assets/css/style.css";
import "antd/dist/antd.min.css";
// import "react-date-picker/dist/DatePicker.css";
// import "react-calendar/dist/Calendar.css";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import "tui-image-editor/dist/tui-image-editor.css";

import MainLayout from "./components/Layouts/MainLayout/MainLayout";
import { useEffect } from "react";

function App() {
	return <MainLayout />;
}

export default App;
