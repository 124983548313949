import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getPatients } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import { checkPermission } from "../../../helperFunctions/checkPermission";
import { useSelector } from "react-redux";
import FilterComponent from "../../SharedComponents/FilterComponent";

function PatientNotes() {
	const [patients, setPatients] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const navigate = useNavigate();
	const userData = useSelector((state) => state.userData.value);

	const getPatientsList = async () => {
		const response = await getPatients();
		if (response.status !== "Fail") {
			setPatients(response?.list);
		} else {
			toastify(response?.message, "error");
		}
	};

	const getNotes = (id, name) => {
		console.log("🚀 ~ file: PatientNotes.js:26 ~ getNotes ~ name", name);
		navigate("/patient-notes-selected", {
			state: {
				id,
				name,
			},
		});
	};

	useEffect(() => {
		getPatientsList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly30">
					<span className="me-2">Patient Name</span>
					{
						<FilterComponent
							type="search"
							array={patients}
							setArray={setFilteredData}
							value="PatientName"
						/>
					}
				</div>
			),
			selector: (row) => (
				<div onClick={() => getNotes(row.id, row.name)}>{row.name}</div>
			),
		},
		// {
		//   name: (
		//     <div className="d-flex align-items-center">
		//       <span className="me-2">National ID</span>
		//       {
		//         <FilterComponent
		//           type="search"
		//           array={patients}
		//           setArray={setFilteredData}
		//           value="NationalId"
		//         />
		//       }
		//     </div>
		//   ),
		//   selector: (row) => row.nationalID,
		// },
		{
			name: "Gender",
			selector: (row) => row.gender,
		},
		// {
		//   name: "Date of Birth",
		//   selector: (row) => row.dob,
		// },
		{
			name: "Email",
			selector: (row) => row.email,
		},
		{
			name: "Contact",
			selector: (row) => row.contactNo,
		},
		{
			name: "Nurse Notes",
			selector: (row) => row.nurseNotes || 0,
		},
		{
			name: "Doctor Notes",
			selector: (row) => row.doctorNotes || 0,
		},
		// {
		//   name: "Action",
		//   selector: (row) => row.action,
		//
		// },
	];

	const data = (filteredData.length === 0 ? patients : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				index: index,
				name: e.patientProfile?.fullName,
				nationalID: e.patientProfile?.nationID,
				gender: e.patientProfile?.gender,
				dob:
					e.patientProfile?.dob && e.patientProfile?.dob !== ""
						? new Date(e.patientProfile?.dob).toLocaleDateString()
						: "NaN",
				email: e?.contactInfo?.email,
				contactNo: e?.contactInfo?.contactNo,
				nurseNotes: e?.nurseNotesList?.length,
				doctorNotes: e?.doctorNotesList?.length,

				// action: (
				//   <div className="d-flex">
				//     <div  className="mx-3 cursor-pointer icon-width">
				//       <i className="fa fa-ellipsis-vertical"></i>
				//     </div>
				//     <div
				//
				//       className="mx-3 cursor-pointer icon-width"
				//       onClick={() => {
				//         //   editDepartment(e._id);
				//       }}
				//     >
				//       <i className="fa fa-pen"></i>
				//     </div>
				//     <div
				//
				//       className="mx-3 cursor-pointer icon-width"
				//       onClick={() => {
				//         //   removeDepartment(e._id);
				//       }}
				//     >
				//       <i className="fa fa-trash"></i>
				//     </div>
				//   </div>
				// ),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
				width: "180px",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				width: "180px",
				cursor: "pointer",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>
						<span className="unbold">Note-Taking / </span>Patient
					</h1>
					{/* <div className="d-flex">
            <Button
              onClick={() => navigate("/patient-notes-selected")}
              className="addItem mx-3"
            >
              View Doctor Notes
            </Button>
            <Button
              onClick={() => navigate("/patient-notes-selected")}
              className="addItem"
            >
              View Nurse Notes
            </Button>
          </div> */}
				</div>

				{checkPermission(userData, "Note-Taking", "view") && (
					<DataTable
						columns={columns}
						customStyles={customStyles}
						data={data || []}
						pagination
						conditionalRowStyles={conditionalRowStyles}
						const
						onRowClicked={(row) => {
							getNotes(row.id, row.name);
						}}
					/>
				)}
			</div>
		</>
	);
}

export default PatientNotes;
