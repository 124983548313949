import { Pie } from "@nivo/pie";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const PieChart = ({ data, itemWidth }) => (
	<>
		<Pie
			data={data}
			width={600}
			// enableArcLabels={false}
			colors={{ scheme: "category10" }}
			height={400}
			margin={{ top: 20, right: 10, bottom: 50, left: 10 }}
			activeOuterRadiusOffset={6}
			borderWidth={1}
			borderColor={{
				from: "color",
				modifiers: [["darker", 0.2]],
			}}
			enableArcLinkLabels={false}
			arcLinkLabelsSkipAngle={4}
			arcLinkLabelsTextColor="#333333"
			arcLinkLabelsThickness={2}
			arcLinkLabelsColor={{ from: "color" }}
			arcLabelsRadiusOffset={0.8}
			arcLabelsSkipAngle={13}
			arcLabelsTextColor={{
				from: "color",
				modifiers: [["brighter", 2]],
			}}
			animate={false}
			legends={[
				{
					anchor: "bottom",
					direction: "row",
					justify: false,
					translateX: 0,
					translateY: 40,
					itemsSpacing: 2,
					itemWidth: itemWidth ?? 70,
					itemHeight: 18,
					itemTextColor: "#999",
					itemDirection: "left-to-right",
					itemOpacity: 1,
					symbolSize: 18,
					symbolShape: "circle",
					effects: [
						{
							on: "hover",
							style: {
								itemTextColor: "#000",
							},
						},
					],
				},
			]}
			// activeOuterRadiusOffset={8}
			// borderWidth={1}
			// borderColor={{
			// 	from: "color",
			// 	modifiers: [["darker", 0.2]],
			// }}
		/>
	</>
);

export default PieChart;
