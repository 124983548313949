import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function PieChart({ series, options }) {
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={380}
      />
    </div>
  );
}

export default PieChart;
