import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
// import ApexCharts from 'apexcharts'

const LineChart = ({ chartData }) => {
  console.log(chartData);
  if (!chartData) {
    return <div>Loading chart data...</div>;
  }
  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default LineChart;
