import { useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { getSalesReports } from "../../../apis/reports";
import { getPaymentMethodList } from "../../../apis/paymentMethod";
import { getStaff } from "../../../apis/staff";
import { yearsOptions } from "../../../constants";
import { toastify } from "../../../helperFunctions/toastify";
import { exportData, removeDuplicatesByPatientId } from "../../../helperFunctions/utils";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import BarGraph from "./BarGraph";
import { filterTypes } from "./PatientGeneralReport";
import PieChart from "./PieChart";
import moment from "moment";

export default function SalesReport() {
	// const [selectedMonth, setSelectedMonth] = useState(null);
	// const [selectedQuater, setSelectedQuater] = useState(null);

	const [allData, setAllData] = useState([]);
	const [salesGraphData, setSalesGraphData] = useState([]);
	const [tableGraphData, setTableGraphData] = useState([]);
	const [activeSalesData, setActiveSalesData] = useState({});

	const [filterType, setFilterType] = useState("date");
	const [selectedQuaterStart, setSelectedQuaterStart] = useState(null);
	const [quarterStartYear, setQuarterStartYear] = useState();
	const [quarterEndYear, setQuarterEndYear] = useState();
	const [selectedQuarterEnd, setSelectedQuaterEnd] = useState();

	const [uniquePatients, setUniquePatients] = useState([]);
	const [totals, setTotals] = useState([]);
	const [activeIndex, setActiveIndex] = useState();
	const [allTreatments, setAllTreatments] = useState([]);
	const [allProducts, setAllproducts] = useState([]);
	const [allPackages, setAllPackages] = useState([]);

	const [allStaff, setAllStaff] = useState([]);
	const [selectedStaff, setSelectedStaff] = useState([]);
	const [staff, setStaff] = useState([]); //staff to be send in query

	const [listPaymentMethod, setListPaymentMethod] = useState([]);
	const [paymentMethodsData, setPaymentMethodsData] = useState([]);

	const [patientsCount, setPatientCount] = useState({
		recurringPatients: 0,
		withKol: 0,
		withReferral: 0,

		newPatients: 0,
	});

	const quaterOptions = [
		{
			label: "Jan - Mar",
			value: "January-March",
		},
		{
			label: "Apr - June",
			value: "April-June",
		},
		{
			label: "Jul - Sept",
			value: "July-September",
		},
		{
			label: "Oct - Dec",
			value: "October-December",
		},
	];

	const monthNames = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const [range, setRange] = useState({
		start: new Date(new Date().getFullYear(), 0, 1),
		end: new Date(),
	});

	const [months, setMonths] = useState({
		start: new Date(new Date().getFullYear(), 0, 1),
		end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
	});
	const [startDate, setStartDate] = useState(
		new Date(new Date().getFullYear(), 0, 1),
	);
	const [endDate, setEndDate] = useState(new Date());

	const onApply = (start, end) => {
		setStartDate(start);
		setEndDate(end);
		setStaff(selectedStaff);
	};

	const onQuarterApply = () => {
		let sYear = quarterStartYear;
		let sQuarter = selectedQuaterStart;
		let eQuarter = selectedQuarterEnd;
		let eYear = quarterEndYear;

		if (sQuarter && eQuarter && sYear && eYear) {
			const startMonth = new Date(
				sYear,
				monthNames.indexOf(sQuarter.split("-")[0]),
			);
			const endMonth = new Date(
				eYear,
				monthNames.indexOf(eQuarter.split("-")[1]) + 1,
				0,
			);

			setStartDate(startMonth);
			setEndDate(endMonth);
		}
		setStaff(selectedStaff);
	};
	function getQuarterForDate(date) {
		const month = new Date(date).getMonth();
		if (month >= 0 && month < 3) {
			return `Jan-Mar ${new Date(date).getFullYear()}`;
		}
		if (month >= 3 && month < 6) {
			return `Apr-Jun ${new Date(date).getFullYear()}`;
		}
		if (month >= 6 && month < 9) {
			return `Jul-Sep ${new Date(date).getFullYear()}`;
		}
		if (month >= 9) {
			return `Sep-Dec ${new Date(date).getFullYear()}`;
		}
	}

	const getPaymentMethods = async () => {
		const { result, error } = await getPaymentMethodList();
		if (error) {
				return;
		}
		setListPaymentMethod(result.data);
	};

	useEffect(() => {
		getPaymentMethods();
	}, []);

	useEffect(() => {
		if (!startDate || !endDate || !allStaff?.length) {
			return;
		}
		(async () => {
			let staffArr = [];
			// if (!staff.length) {
			// 	staffArr = allStaff.map((o) => o.value);
			// } else {
			staffArr = [...staff];
			// }
			const { result, error } = await getSalesReports({
				startDate,
				endDate,
				staff: staffArr,
			});
			if (error) {
				toastify(error?.message, "error");
				return;
			}
			setAllData([...result.data]);

			let newPatients = 0;
			let referralPatients = 0;
			let withMedia = 0;

			const uniquePatients = removeDuplicatesByPatientId(result.data);
			setUniquePatients(uniquePatients);

			uniquePatients?.map((o) => {
				let date;
				if (filterType == "month") {
					date =
						monthNames[new Date(o.created_at).getMonth()] +
						" " +
						new Date(o.created_at).getFullYear();
				} else if (filterType == "quarter") {
					date = getQuarterForDate(o.created_at);
				} else {
					date = new Date(o.created_at).toLocaleDateString();
				}
				if (
					new Date(o?.patient?.created_at).getTime >=
						startDate.getTime() &&
					new Date(o?.patient?.created_at).getTime <= endDate.getTime
				) {
					newPatients++;
				}
				if (o?.patient?.patientProfile?.kol?._id) {
					withMedia++;
				}
				if (o?.patient?.patientProfile?.recommendFrom?._id) {
					referralPatients++;
				}
			});

			const dataGraph = converToGraphData(result.data, "month");
			const dataTable = converToGraphData(result.data, "date", true);

			setPaymentMethodsData([...dataTable._paymentMethodsData]);
			setPatientCount({
				newPatients: newPatients,
				withKol: withMedia,
				withReferral: referralPatients,
				recurringPatients: uniquePatients.length - newPatients,
			});
			setSalesGraphData([...dataGraph.tempSalesArr]);
			setTableGraphData(dataTable.tempSalesArr);
		})();
	}, [startDate, endDate, staff, allStaff]);


	const converToGraphData = (resValues, type, needPaymentData) => {
		let tempSalesArr = [];
		let _paymentMethodsData = [];

		resValues?.forEach((o, idx) => {
			if (needPaymentData) {
				if (o?.paymentMethod?._id) {
					let tempArr = [..._paymentMethodsData];
					let indexOfPaymentMethod = tempArr.findIndex(
						(pm) => pm.id == o?.paymentMethod?._id,
					);
					if (indexOfPaymentMethod == -1) {
						tempArr.push({
							id: o?.paymentMethod?._id,
							name: o?.paymentMethod?.name,
							count: 1,
						});
					} else {
						tempArr[indexOfPaymentMethod] = {
							...tempArr[indexOfPaymentMethod],
							count: tempArr[indexOfPaymentMethod]?.count + 1,
						};
					}
					_paymentMethodsData = [...tempArr];
				}
			}

			let date;
			if (type == "month") {
				date =
					monthNames[new Date(o.created_at).getMonth()] +
					" " +
					new Date(o.created_at).getFullYear();
			} else if (type == "quarter") {
				date = getQuarterForDate(o.created_at);
			} else {
				date = new Date(o.created_at).toLocaleDateString();
			}
			const index = tempSalesArr.findIndex((x) => x.date === date);

			if (index == -1) {
				let patients = [o?.patient];
				tempSalesArr.push({
					date,
					"Total Amount": o?.totalAmount,
					patients,
					paymentRecord: o?.paymentRecord?.length > 0 ? [o?.paymentRecord[0]] : [],
					treatments: [o?.treatment],
					products: [o?.product],
					packages: [o?.package],
				});
			} else {
				let _salesObj = tempSalesArr[index];
				const totalAmount =
					_salesObj["Total Amount"] + o.totalAmount;

				let treatments = _salesObj.treatments;
				treatments.push(o.treatment);

				let packages = _salesObj.packages;
				packages.push(o?.package);

				let products = _salesObj.products;
				products.push(o?.product);

				let paymentRecords = _salesObj.paymentRecord;
				if (o?.paymentRecord?.length > 0) {
					paymentRecords.push(o?.paymentRecord[0]);
				}

				const tempPatientsArr = _salesObj.patients;

				if (
					_salesObj.patients.findIndex(
						(p) => p._id == o.patient?._id,
					) == -1
				) {
					tempPatientsArr.push({ ...o.patient });
				}

				_salesObj = {
					..._salesObj,
					"Total Amount": totalAmount,
					patients: tempPatientsArr,
					paymentRecord: paymentRecords,
					treatments,
					packages,
					products,
				};
				tempSalesArr[index] = _salesObj;
			}
		});

		return {
			tempSalesArr,
			_paymentMethodsData
		}
	}

	useMemo(() => {
		if (activeSalesData.date) {
			let packagesTotal = 0;
			let productsTotal = 0;
			let treatmentsTotal = 0;

			let allPackages = [];
			let allProducts = [];
			let allTreatments = [];
			activeSalesData.packages?.forEach((p) => {
				if (p.length > 0) {
					p.forEach((singlePack) => {
						if (!singlePack.isAlreadyPaid) {
							packagesTotal =
								packagesTotal +
								(singlePack?.package_price ?? 0);

							const existPackage = allPackages.find(x => x.name === singlePack?.package_id?.name);
							if (existPackage) {
								existPackage.price += singlePack?.package_price;
							} else {
								allPackages.push({
									name: singlePack?.package_id?.name,
									price: singlePack?.package_price,
								});
							}
						}
					});
				}
			});

			activeSalesData.products?.forEach((p) => {
				if (p.length > 0) {
					p.forEach((singlePack) => {
						productsTotal =
							productsTotal + (singlePack?.product_price ?? 0);

						const existProduct = allProducts?.find(x => x.name === singlePack?.product_id?.name);
						if (existProduct) {
							existProduct.price += singlePack?.product_price;
						} else {
							allProducts.push({
								name: singlePack?.product_id?.name,
								price: singlePack?.product_price,
							});
						}
					});
				}
			});

			activeSalesData.treatments?.forEach((p) => {
				if (p.length > 0) {
					p.forEach((singlePack) => {
						treatmentsTotal =
							treatmentsTotal + (singlePack?.service_price ?? 0);

						const existTreatment = allTreatments?.find(x => x.name === singlePack?.service_id?.name);
						if (existTreatment) {
							existTreatment.price += singlePack?.service_price;
						} else {
							allTreatments.push({
								name: singlePack?.service_id?.name,
								price: singlePack?.service_price,
							});
						}
					});
				}
			});

			setTotals([
				{
					id: "Products",
					label: "Products",
					value: parseFloat(
						(productsTotal / activeSalesData["Total Amount"]) * 100,
					).toFixed(2),
				},
				{
					id: "Treatments",
					label: "Treatments",
					value: parseFloat(
						(treatmentsTotal / activeSalesData["Total Amount"]) *
							100,
					).toFixed(2),
				},
				{
					id: "Packages",
					label: "Packages",
					value: parseFloat(
						(packagesTotal / activeSalesData["Total Amount"]) * 100,
					).toFixed(2),
				},
			]);
			setAllTreatments(allTreatments);
			setAllproducts(allProducts);
			setAllPackages(allPackages);
		}
	}, [activeSalesData]);

	useEffect(() => {
		(async () => {
			const res = await getStaff();
			if (res.status !== "Fail") {
				const tempArr = res.map((o) => ({
					label: o?.username,
					value: o?._id,
				}));
				setAllStaff(tempArr);
			}
		})();
	}, []);
	useMemo(() => {
		// if (
		// 	selectedQuaterStart &&
		// 	selectedQuarterEnd &&
		// 	quarterStartYear &&
		// 	quarterEndYear
		// ) {
		// 	const startMonth = new Date(
		// 		quarterStartYear,
		// 		monthNames.indexOf(selectedQuaterStart.split("-")[0]),
		// 	);
		// 	const endMonth = new Date(
		// 		quarterEndYear,
		// 		monthNames.indexOf(selectedQuarterEnd.split("-")[1]) + 1,
		// 		0,
		// 	);
		// 	setStartDate(startMonth);
		// 	setEndDate(endMonth);
		// }
	}, [
		selectedQuaterStart,
		selectedQuarterEnd,
		quarterStartYear,
		quarterEndYear,
	]);

	const getNewPatient = (values) => {
		let newPatients = 0;
		values?.map((o) => {
			let date;
			if (filterType == "month") {
				date =
					monthNames[new Date(o.created_at).getMonth()] +
					" " +
					new Date(o.created_at).getFullYear();
			} else if (filterType == "quarter") {
				date = getQuarterForDate(o.created_at);
			} else {
				date = new Date(o.created_at).toLocaleDateString();
			}
			if (
				new Date(o?.patient?.created_at).getTime >=
					startDate.getTime() &&
				new Date(o?.patient?.created_at).getTime <= endDate.getTime
			) {
				newPatients++;
			}
		})

		return newPatients;
	}

	const getMediaPatient = (values) => {
		let withMedia = 0;
		values?.map((o) => {
			let date;
			if (filterType == "month") {
				date =
					monthNames[new Date(o.created_at).getMonth()] +
					" " +
					new Date(o.created_at).getFullYear();
			} else if (filterType == "quarter") {
				date = getQuarterForDate(o.created_at);
			} else {
				date = new Date(o.created_at).toLocaleDateString();
			}
			if (o?.patient?.patientProfile?.kol?._id) {
				withMedia++;
			}
		})

		return withMedia;
	}

	const getReferralPatient = (values) => {
		let referralPatients = 0;
		values?.map((o) => {
			let date;
			if (filterType == "month") {
				date =
					monthNames[new Date(o.created_at).getMonth()] +
					" " +
					new Date(o.created_at).getFullYear();
			} else if (filterType == "quarter") {
				date = getQuarterForDate(o.created_at);
			} else {
				date = new Date(o.created_at).toLocaleDateString();
			}
			if (o?.patient?.patientProfile?.recommendFrom?._id) {
				referralPatients++;
			}
		})

		return referralPatients;
	}

	const getPaymentRecordCount = (paymentRecords, paymentMethod) => {
		return paymentRecords?.filter(x => x.paymentMethod === paymentMethod)?.length;
	}

	const getDataForExport = (array) => {
		let treatments = 0;
		let packages = 0;
		let products = 0;
		let resultarray= [];
		array.forEach((o) => {
			const date = o.date;
			o?.packages[0]?.forEach((pack) => {
				if (pack?.package_price) packages += pack?.package_price;
			});
			o?.products[0]?.forEach((pack) => {
				if (pack?.product_price) products += pack?.product_price;
			});
			o?.treatments[0]?.forEach((pack) => {
				if (pack?.service_price) treatments += pack?.service_price;
			});
			resultarray = [...resultarray,{
				date,
				treatments,
				products,
				packages,
			}]
		});
		return resultarray
	};
	const getColumns = (array, name, value) => {
		let total = 0;

		array.forEach((o) => {
			if (o.price) {
				total += o.price;
			}
		});

		return [
			{
				name: name,
				selector: (row) => row.name,
			},
			{
				name: total?.toLocaleString(),
				selector: (row) => row.price?.toLocaleString(),
			},
		];
	};
	const customStyles = {
		headCells: {
			style: {
				// justifyContent: "center",
			},
		},
		cells: {
			style: {
				// justifyContent: "center",
				// textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	const getTotalByMethod = (method, paymentDay) => {
		let total = 0;
		let listFilter = [];
		if (filterType === "date") {
			listFilter = allData.filter(x => x.paymentMethod?.name === method && moment(x.created_at).isSame(paymentDay, "day"));
		} else if (filterType === "month") {
			listFilter = allData.filter(x => x.paymentMethod?.name === method && moment(x.created_at).isSame(paymentDay, "month"));
		}
		listFilter.map((item) => {
			total += item.totalAmount ? item.totalAmount : 0;
		});

		return total;
	}

	return (
		<>
			<div
				className="d-flex flex-wrap"
				style={{ width: "calc(100% - 130px)" }}
			>
				{/* <div className="col-3 me-2">
					<Form.Group>
						<div className="font-400-20 mb-1">Filter Selection</div>
						<SelectComponent
							options={filterTypes}
							selectedValue={filterType}
							singleSelect
							searchEnabled
							nullDisabled
							onChange={(e) => {
								setFilterType(e.value);
							}}
						/>
					</Form.Group>
				</div> */}
				{filterType == "date" && (
					<>
						<div className="col-3 pe-2">
							<div className="font-400-20 mb-1">Start Date:</div>
							<DateInput
								value={range.start}
								placeholder="Start Date"
								onChange={(e) => {
									if (e > range.end) {
										toastify(
											"Start date can't be greater than end date",
											"error",
										);
										return;
									}
									setRange({ ...range, start: e });

									// setStartDate(e);
								}}
							/>
						</div>
						<div className="col-3 pe-2">
							<div className="font-400-20 mb-1">End Date:</div>
							<DateInput
								placeholder="End Date"
								value={range.end}
								onChange={(e) => {
									if (e < range.start) {
										toastify(
											"End date can't be less than start date",
											"error",
										);
										return;
									}
									setRange({ ...range, end: e });

									// setEndDate(e);
								}}
							/>
						</div>
						<div className="col-3">
							<div className="font-400-20 mb-1">Staff</div>
							<SelectComponent
								options={allStaff}
								isNullEnabled
								searchEnabled
								array={selectedStaff}
								onChange={(e) => {
									setSelectedStaff(e);
								}}
							/>
						</div>

						<Button
							className="addItem mb-3 mx-2 align-self-end"
							style={{ height: "47px" }}
							onClick={() => {
								onApply(range.start, range.end);
							}}
						>
							Apply
						</Button>
					</>
				)}
				{filterType == "month" && (
					<>
						<div className="col-3 pe-2">
							<div className="font-400-20 mb-1">Start Month:</div>
							<DateInput
								placeholder="Start Month"
								views={["month", "year"]}
								value={months.start}
								onChange={(e) => {
									setMonths({
										...months,
										start: new Date(
											e.getFullYear(),
											e.getMonth(),
										),
									});
									// setStartDate(
									// 	new Date(e.getFullYear(), e.getMonth()),
									// );
								}}
							/>
						</div>
						<div className="col-3 pe-2">
							<div className="font-400-20 mb-1">End Month:</div>
							<DateInput
								placeholder="Start Month"
								views={["month", "year"]}
								value={months.end}
								onChange={(e) => {
									if (months.start > e) {
										toastify(
											"End date cann't be less than start date",
											"error",
										);
										return;
									}

									const date = new Date(
										e.getFullYear(),
										e.getMonth() + 1,
										0,
									);
									setMonths({
										...months,
										end: date,
									});
									// setEndDate(date);
								}}
							/>
						</div>{" "}
						<div className="col-3">
							<SelectComponent
								options={allStaff}
								isNullEnabled
								searchEnabled
								array={selectedStaff}
								onChange={(e) => {
									setSelectedStaff(e);
								}}
							/>
						</div>
						<Button
							className="addItem mb-3 mx-2 align-self-end"
							style={{ height: "47px" }}
							onClick={() => {
								onApply(months.start, months.end);
							}}
						>
							Apply
						</Button>
					</>
				)}
				{filterType == "quarter" && (
					<>
						<div className="col-3">
							<div className="font-400-20 mb-1">Start Year:</div>
							<SelectComponent
								options={yearsOptions}
								selectedValue={quarterStartYear}
								singleSelect
								searchEnabled
								onChange={(e) => {
									if (
										parseInt(e.value) >
										parseInt(quarterEndYear)
									) {
										toastify(
											"Start Year can't be greater than end year",
											"error",
										);
										return;
									}
									setQuarterStartYear(e.value);
								}}
							/>
						</div>
						<Form.Group className="col-3 mx-2">
							<div className="font-400-20 mb-1">
								Start Quarter
							</div>
							<SelectComponent
								options={quaterOptions}
								selectedValue={selectedQuaterStart}
								singleSelect
								searchEnabled
								onChange={(e) => {
									setSelectedQuaterStart(e.value);
								}}
							/>
						</Form.Group>
						<div className="col-3">
							<div className="font-400-20 mb-1">End Year:</div>
							<SelectComponent
								options={yearsOptions}
								selectedValue={quarterEndYear}
								singleSelect
								searchEnabled
								onChange={(e) => {
									if (
										parseInt(e.value) <
										parseInt(quarterStartYear)
									) {
										toastify(
											"End Year can't be less than start year",
											"error",
										);
										return;
									}
									setQuarterEndYear(e.value);
								}}
							/>
						</div>
						<Form.Group className="col-3 ms-2">
							<div className="font-400-20 mb-1">End Quarter</div>
							<SelectComponent
								options={quaterOptions}
								selectedValue={selectedQuarterEnd}
								singleSelect
								searchEnabled
								onChange={(e) => {
									setSelectedQuaterEnd(e.value);
								}}
							/>
						</Form.Group>
						<div className="col-3 align-self-end ms-2">
							<SelectComponent
								options={allStaff}
								isNullEnabled
								searchEnabled
								array={selectedStaff}
								onChange={(e) => {
									setSelectedStaff(e);
								}}
							/>
						</div>
						<Button
							className="addItem mb-3 mx-2 align-self-end"
							style={{ height: "47px" }}
							onClick={() => {
								onQuarterApply();
							}}
						>
							Apply
						</Button>
					</>
				)}
				{
					filterType !== "quarter" && (
						<Button
							className="addItem mb-3 align-self-end"
							style={{ height: "47px" }}
							onClick={() => {
								// let getData = getDataForExport(salesGraphData);
								let getData = [];
								const keysDate = [];

								salesGraphData.map((item) => {
									keysDate.push(item.date);

									let newPatients = 0;
									let withKol = 0;
									let withReferral = 0;
									let referralPatients = 0;
									let totalPackage = 0;
									let totalTreatment = 0;
									let totalProduct = 0;
									
									totalPackage = item.packages?.reduce(
										(acc, obj) => acc + (obj?.[0]?.package_price ?? 0), 0,
									);
									totalTreatment = item.treatments?.reduce(
										(acc, obj) => acc + (obj?.[0]?.service_price ?? 0), 0,
									);
									totalProduct = item.products?.reduce(
										(acc, obj) => acc + (obj?.[0]?.product_price ?? 0), 0,
									);

									item.patients?.map(o => {
										if (filterType === "date") {
											if (
												new Date(o?.created_at).getTime >=
													startDate.getTime() &&
												new Date(o?.created_at).getTime <= endDate.getTime
											) {
												newPatients++;
											}
										} else if (filterType === "month") {
											if (
												new Date(o?.created_at).getMonth >=
													startDate.getMonth() &&
												new Date(o?.created_at).getMonth <= endDate.getMonth
											) {
												newPatients++;
											}
										}

										if (o?.patientProfile?.kol?._id) {
											withKol++;
										}
										if (o?.patientProfile?.recommendFrom?._id) {
											referralPatients++;
										}
									})

									const recurringPatients = item.patients?.length - newPatients;

									const methodsArr = listPaymentMethod?.map(
										(o, i) => {
											return {
												[`Date ${item.date}`]: `${
													i == 0 ? "Payment Methods" : ""
												}`,
												"": o?.name,
												" ": getTotalByMethod(o?.name, moment(item.date)),
											};
										},
									);
									getData.push([
										...methodsArr,
										{
											[`Date ${item.date}`]:
												"",
											"": "",
											" ": "",
										},
										{
											[`Date ${item.date}`]:
												"Items",
											"": "Treatments",
											" ": totalTreatment ? totalTreatment : 0,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "Packages",
											" ": totalPackage ? totalPackage : 0,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "Products",
											" ": totalProduct ? totalProduct : 0,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "",
											" ": "",
										},
										{
											[`Date ${item.date}`]:
												"Patients",
											"": "New Patient",
											" ": newPatients,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "Existing Patients",
											" ": recurringPatients,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "No. KOL/Channel",
											" ": withKol,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "No. Referral",
											" ": withReferral,
										},
										{
											[`Date ${item.date}`]:
												"",
											"": "Total Patients",
											" ": recurringPatients + newPatients,
										},
									]);
								})
								
								exportData(getData, keysDate, "sales_report",filterType);
							}}
						>
							Export
						</Button>
					)
				}
			</div>
			<div className="mt-4 p-3" style={{ background: "white" }}>
				<div className="font-400-20">
					<b>Sales Report</b>
				</div>
				<BarGraph
					data={salesGraphData}
				    // isDate={filterType == "date"}
					xLegend={"Date"}
					yLegend={"Amount"}
					enableGridY={true}
					enableGridX={false}
					keys={["Total Amount"]}
					indexBy="date"
					axisBottom={{
						tickLabelRotation: 45,
					  }}
					
				/>
			</div>
			<div className="wrapper-table-report">
				<Table striped>
					<thead
						style={{
							background: "#34556F",
							color: "white",
						}}
					>
						<tr
							style={{
								height: "45px",
							}}
							className="align-items-center
								"
						>
							<th className="wd-100">Date</th>
							<th className="wd-100">Total Patients</th>
							<th className="wd-100">Total Amount</th>
							<th className="wd-100">Action</th>
						</tr>
					</thead>
					<tbody>
						{tableGraphData?.length > 0 &&
							tableGraphData?.map((e, index) => (
								<>
									<tr>
										<td className="wd-150 ps-3">
											{new Date(e.date).toLocaleDateString(
												"en-GB",
												{
													day: "numeric",
													month: "numeric",
													year: "numeric",
												},
											)}
										</td>
										<td className="wd-150 ps-3">
											{e.patients.length}
										</td>
										<td className="wd-150">
											{e["Total Amount"]?.toLocaleString()}
										</td>

										<td className="wd-100 ">
											<Button
												className="addItem"
												style={{
													maxHeight: "35px",
													width: "130px",
													fontWeight: 400,
													fontSize: "14px",
												}}
												onClick={() => {
													if (activeIndex == index) {
														setActiveIndex();
														setActiveSalesData({});
														return;
													}
													setActiveSalesData(e);
													setActiveIndex(index);
												}}
											>
												View Detail
											</Button>
										</td>
									</tr>
									{activeIndex === index && (
										<tr>
											<td colSpan="12">
												<div
													className="mt-4 p-3"
													style={{ background: "white" }}
												>
													<div className="font-400-20">
														<b>
															Treatments, Products,
															Packages
														</b>
													</div>
													<div className="w-100 d-flex justify-content-center">
														<PieChart
															itemWidth={90}
															data={totals}
														/>
													</div>
												</div>
												<div className="white-bg p-2 px-3">
													<span className="font-400-16">
														<b>Items : </b>
													</span>{" "}
												</div>
												{allTreatments?.length > 0 && (
													<>
														<div
															className="mt-0 px-3"
															style={{
																background: "white",
															}}
														>
															<DataTable
																columns={getColumns(
																	allTreatments,
																	"Treatment",
																)}
																data={allTreatments}
																conditionalRowStyles={
																	conditionalRowStyles
																}
																customStyles={
																	customStyles
																}
															/>
														</div>
													</>
												)}
												{allProducts.length > 0 && (
													<>
														<div
															className="mt-0 px-3"
															style={{
																background: "white",
															}}
														>
															<DataTable
																columns={getColumns(
																	allProducts,
																	"Product",
																)}
																data={allProducts}
																conditionalRowStyles={
																	conditionalRowStyles
																}
																customStyles={
																	customStyles
																}
															/>
														</div>
													</>
												)}

												{allPackages.length > 0 && (
													<>
														<div
															className="mt-0 px-3"
															style={{
																background: "white",
															}}
														>
															<DataTable
																columns={getColumns(
																	allPackages,
																	"Package",
																)}
																data={allPackages}
																conditionalRowStyles={
																	conditionalRowStyles
																}
																customStyles={
																	customStyles
																}
															/>
														</div>
													</>
												)}
												<div className="white-bg" style={{paddingLeft: "50px", paddingRight: "1rem"}}>
													<span className="font-400-16">
														<b>Patients : </b>
													</span>{" "}
													<div
														className="w-100 px-3"
														style={{ fontSize: "16px" }}
													>
														<div className="d-flex py-2">
															<span className="w-50">
																No of New Patients
															</span>
															<span>
																{
																	getNewPatient(e.patients)
																}
															</span>
														</div>
														<div className="d-flex py-2">
															<span className="w-50">
																No of Media
															</span>
															<span>
																{
																	getMediaPatient(e.patients)
																}
															</span>
														</div>
														<div className="d-flex py-2">
															<span className="w-50">
																No of Referral
																Patients
															</span>
															<span>
																{
																	getReferralPatient(e.patients)
																}
															</span>
														</div>
														<div className="d-flex py-2">
															<span className="w-50">
																No of Recurring
																Patients
															</span>
															<span>
																{
																	(e.patients?.length ?? 0) - getNewPatient(e.patients)
																}
															</span>
														</div>
													</div>
												</div>
												<div className="white-bg" style={{paddingLeft: "50px", paddingRight: "1rem", paddingTop: "15px"}}>
													<span className="font-400-16">
														<b>Payment Methods : </b>
													</span>{" "}
													{paymentMethodsData?.length >
														0 &&
														paymentMethodsData?.map(
															(meth) => {
																return (
																	<>
																		<div className="d-flex py-2" style={{paddingLeft: "15px;"}}>
																			<span className="w-50">
																				{
																					meth.name
																				}
																			</span>
																			<span>
																				{
																					getPaymentRecordCount(e?.paymentRecord, meth.name)
																				}
																			</span>
																		</div>
																	</>
																);
															},
														)}
												</div>
											</td>
										</tr>
									)}
								</>
							))}
					</tbody>
				</Table>
			</div>
		</>
	);
}
