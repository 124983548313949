import { authorizedRequests } from "../axios/axios-config";

export const getoccupationList = async () => {
  const response = await authorizedRequests({
    url: "occupation/listOccupation",
    method: "get",
  });
  return response;
};

export const updateoccupation = async (data) => {
  const response = await authorizedRequests({
    url: "occupation/updateOccupation",
    method: "post",
    data: {
      ...data,
    },
  });
  return response;
};

export const createoccupation = async (data) => {
  const response = await authorizedRequests({
    url: "occupation/createOccupation",
    method: "post",
    data: {
      ...data,
    },
  });
  return response;
};
