import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { getMailchimpTemplates, sendMail } from "../../../apis/mailchimp";
import { toastify } from "../../../helperFunctions/toastify";
import { requiredFieldValidation } from "../../../helperFunctions/validation";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function SendMail() {
	const [values, setValues] = useState({
		subject: "",
		from: "",
		title: "",
		tempalteId: null,
	});

	const [allTemplates, setAllTemplates] = useState([]);
	const onFormChange = (name, value) => {
		setValues({
			...values,
			[name]: value,
		});
	};

	const submitForm = async (e) => {
		const validation = requiredFieldValidation(
			{
				name: "Subject",
				value: values.subject,
			},
			{
				name: "From",
				value: values.from,
			},
			{
				name: "Title",
				value: values.title,
			},
			{
				name: "Template",
				value: values.tempalteId,
			},
		);
		if (!validation.status) {
			toastify(validation.message, "error");
			return;
		}
		const { result, error } = await sendMail({
			...values,
		});
		if (error) {
			toastify(error.message ?? "Failed to send mail", "error");
			return;
		}
		toastify("Mail sent successfuly", "success");
		setValues({
			from: "",
			subject: "",
			title: "",
			tempalteId: null,
		});
		return;
	};
	useEffect(() => {
		(async () => {
			const { result, error } = await getMailchimpTemplates();
			if (error) {
				toastify(error.message ?? "Failed to get templates", "error");
				return;
			}
			const tempArr = result.data?.map((o) => ({
				label: o.name,
				value: o.id,
			}));
			setAllTemplates([...tempArr]);
		})();
	}, []);
	return (
		<>
			<div className="mainHeading px-4">
				<h1>Send Email</h1>
			</div>
			<div className="px-4 pb-4">
				<Form.Group className="col-md-6 ">
					<Form.Label className="required black-label-text">
						From
					</Form.Label>
					<InputGroup className="mb-3 w-75">
						<Form.Control
							name={"from"}
							placeholder="Write..."
							onChange={(e) => {
								onFormChange("from", e.target.value);
							}}
							value={values.from}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group className="col-md-6">
					<Form.Label className="required black-label-text">
						Title
					</Form.Label>
					<InputGroup className="mb-3 w-75">
						<Form.Control
							name={"title"}
							placeholder="Write..."
							onChange={(e) => {
								onFormChange("title", e.target.value);
							}}
							value={values.title}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group className="col-md-6">
					<Form.Label className="required black-label-text">
						Subject
					</Form.Label>
					<InputGroup className="mb-3 w-75">
						<Form.Control
							name={"subject"}
							placeholder="Write..."
							onChange={(e) => {
								onFormChange("subject", e.target.value);
							}}
							value={values.subject}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group className="col-md-6">
					<Form.Label className="required black-label-text">
						Template
					</Form.Label>
					<SelectComponent
						className="w-75"
						searchEnabled
						singleSelect
						options={[...allTemplates]}
						selectedValue={values.tempalteId}
						onChange={(e) => {
							onFormChange("tempalteId", e.value);
						}}
					/>
				</Form.Group>
				<Button
					className="addItem"
					onClick={submitForm}
					style={{ width: "150px" }}
				>
					Send
				</Button>
			</div>
		</>
	);
}
