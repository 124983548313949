import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
	getMembershipCategories,
	getMembershipSettings,
	updateMembershipSettings,
} from "../../../apis/membership";
import { toastify } from "../../../helperFunctions/toastify";

function ExpiredSettings() {
	const [settingsId, setSettingsId] = useState("");
	const [expiredPatient, setExpiredPatient] = useState(0);

	const numberRegex = new RegExp(`^[0-9]*$`);

	const getMembershipDetails = async () => {
		const response = await Promise.all([
			getMembershipCategories(),
			getMembershipSettings(),
		]);
		if (response[1]) {
			setExpiredPatient(response[1][0]?.expiredPatient);
			setSettingsId(response[1][0]?._id);
		}
	};

	const saveSettings = async () => {
		let data = {
			expiredPatient: parseInt(expiredPatient),
		};
		const response = await updateMembershipSettings(settingsId, data);
		if (response?.status !== "Fail") {
			toastify(
				"Membership Settings updated successfully!",
				"success",
			);
			getMembershipDetails();
		} else {
			toastify(response?.message, "error");
		}
	};

	useEffect(() => {
		getMembershipDetails();
	}, []);

	return (
		<div className="container px-5" style={{ height: "100vh" }}>
			<div className="mainHeading">
				<h1>Expired Setting</h1>
			</div>

			<div className="white-background p-3">
				<div className="d-flex mb-4 align-items-end">
					<Form.Group className="col-md-5">
						<Form.Label className="membership-headings font-black ">
							Expired Patient (Months)
						</Form.Label>
						<InputGroup className="mb-3">
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									if (
										numberRegex.test(e.target.value) ||
										e.target.value === ""
									) {
										setExpiredPatient(e.target.value);
									}
								}}
								value={expiredPatient}
							/>
						</InputGroup>
					</Form.Group>
					<span className="col-md-1" />
				</div>

				<div className="col-md-12">
					<Button
						className="addItem my-3 ms-auto me-5"
						onClick={saveSettings}
					>
						Save
					</Button>
				</div>
			</div>
		</div>
	);
}

export default ExpiredSettings;
