import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getListIpAccess, updateIpAccess } from "../../../apis/ipAccess";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function IpAccess() {
	const navigate = useNavigate();
	const [ipAccesss, setIpAccesss] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getList = async () => {
		const response = await getListIpAccess();
		if (response?.status !== "Fail") {
			setIpAccesss(response.reverse());
		} else {
			if (response?.message === "No data Found") {
				setIpAccesss([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const removeIpAccess = async () => {
		setShow(false);
		let response = await updateIpAccess(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			getList();
			toastify("IpAccess deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	const editIpAccess = (e) => {
		navigate("/add-ipAccess", {
			state: {
				ipAccess: e,
			},
		});
	};

	const addIpAccess = () => {
		navigate("/add-ipAccess");
	};

	useEffect(() => {
		getList();
	}, []);

	const columns = [
		{
			name: "Public Ip",
			selector: (row) => row.publicIp,
			wrap: true,
			sortable: true,
		},
		{
			name: "Description",
			selector: (row) => row.description,
			wrap: true,
			sortable: true,
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? ipAccesss : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				publicIp: e.publicIp,
				description: e.description,
				index: index,
				action: (
					<div className="d-flex">
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								editIpAccess(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Ip Access Control</h1>
					<Button className="addItem" onClick={addIpAccess}>
						+ New Ip Access
					</Button>
				</div>

				<DeleteModal
					content="Confirm IpAccess Deletion?"
					onYes={removeIpAccess}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}
