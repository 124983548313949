import React from "react";
import { useState } from "react";
import OptionsToggle from "./OptionsToggle";
import { HiTrash } from "react-icons/hi";
import { useEffect } from "react";

export default function IndicatorsComponent(props) {
  const [heading, setHeading] = useState([]);
  const [headerValue, setHeaderValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.indicators.length > 0) {
      let _data = [];
      props.indicators.map((e) => {
        _data.push({ indicator: e.indicator, options: e.options });
      });
      setHeading(_data);
    }
  }, [props.indicators]);

  // useEffect(() => {
  //   setHeading([]);
  //   setHeaderValue("");
  // }, [props.type]);

  const postData = (operation, value, parent) => {
    if (operation === "add") {
      let _arr = heading.findIndex((e) => e.indicator === parent);
      let _heading = [...heading];
      _heading[_arr].options.push(value);
      setHeading(_heading);
      let _data = _heading.map((e) => {
        return { indicator: e.indicator, options: e.options };
      });
      props.setIndicators(_data);
    } else if (operation === "delete") {
      let _arr = heading.findIndex((e) => e.indicator === parent);

      let _heading = [...heading];
      let _newData = _heading[_arr].options.filter((e) => e !== value);

      _heading[_arr].options = _newData;
      setHeading(_heading);
      props.setIndicators(_heading);
    }
  };

  const addHeader = () => {
    if (headerValue !== "") {
      let val = { indicator: headerValue, options: [] };
      setHeading((oldArray) => [...oldArray, val]);
      let _heading = [...heading].push(val);
      props.setIndicators(_heading);
      setHeaderValue("");
      setOpen(true);
    }
  };

  const deleteHeader = (opt) => {
    let arr = [...heading];
    let _arr = arr.filter((e) => e !== opt);
    setHeading(_arr);
    props.setIndicators(_arr);
  };

  return (
    <div>
      {heading?.map((e) => {
        return (
          <div>
            <div className="checkBoxChild">
              <div className="form-check p-0">
                <div className="minus-icon">-</div>
                <span>{e.indicator}</span>
                {/* <MdOutlineModeEditOutline
                  className="mx-2"
                  cursor={"pointer"}
                  // onClick={() => deleteHeader(e)}
                /> */}
                <HiTrash
                  color="red"
                  cursor={"pointer"}
                  onClick={() => {
                    deleteHeader(e);
                  }}
                  className="ms-3"
                />
              </div>
            </div>
            {heading.length > 0 && (
              <OptionsToggle
                open={setOpen}
                header={e}
                heading={heading}
                postData={postData}
                indicators={props.indicators}
              />
            )}
          </div>
        );
      })}
      <div className="checkBoxChild">
        <div className="form-check p-0">
          <div className="plus-icon" onClick={addHeader}>
            +
          </div>
          <input
            className="custom-input"
            value={headerValue}
            onChange={(e) => {
              setHeaderValue(e.target.value);
            }}
            type={"text"}
          />
        </div>
      </div>
    </div>
  );
}
