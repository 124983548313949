import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";
import { adminLogin } from "../../../apis/login";
import { login } from "../../../redux/slices/userSlice";
import { useDispatch } from "react-redux";
import { toastify } from "../../../helperFunctions/toastify";
import { setTableSearch, setFormData } from "../../../redux/slices/commonSlice";
// import AWS from "aws-sdk";

function SignIn() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [inputType, setInputType] = useState("password");
  // const [file,setFile] = useState()
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const loginAttempt = async () => {
    if (email && password) {
      const response = await adminLogin(email, password, "admin");
      if (response?.status === "Success") {
        dispatch(setTableSearch({}));
        dispatch(setFormData({}));
        localStorage.setItem("orgid", response?.data?.selectedOrg);
        localStorage.setItem("token", response?.data?.token);
        dispatch(login(response?.data?.user));
        toastify("Logged in successfully!", "success");
        if (response?.data?.user?.role.includes("admin")) {
          navigate("/staff");
        } else if (response?.data?.user?.role.includes("staff")) {
          if (
            response?.data?.departmentData?.permissions?.filter(
              (x) => x?.moduleName === "Reception"
            )?.length === 0
          ) {
            const firstPermission =
              response?.data?.departmentData?.permissions?.[0];
            if (firstPermission?.moduleName === "Appointment" || firstPermission?.moduleName === "Reception") {
              navigate("/appointments");
            } else if (firstPermission?.moduleName === "Patient-Database" || firstPermission?.moduleName === "Patient") {
              navigate("/patient");
            } else if (firstPermission?.moduleName === "Session") {
              navigate("/session");
            } else if (firstPermission?.moduleName === "Note-Taking") {
              navigate("/doctor-notes");
            } else {
              navigate("");
            }
          } else {
            navigate("/rostering");
          }
        }
      } else {
        if (response?.error?.msg) {
          toastify(response?.error?.msg, "error");
        } else {
          toastify(response?.message, "error");
        }
      }
    } else {
      toastify("Please enter credentials", "error");
    }
  };

  // const uploadFileS3 = (file) => {
  //   const S3_BUCKET = "nft-ipfs";
  //   const REGION = "us-east-1";
  //   const ACCESS_KEY = "137243FC40A4AEC238BE";
  //   const SECRET_ACCESS_KEY = "Boua8LMtt7A9ZoVCMBGrHtqCcqacOpbApKrkmRy0";

  //   const myBucket = new AWS.S3({
  //     accessKeyId: ACCESS_KEY,
  //     secretAccessKey: SECRET_ACCESS_KEY,
  //     endpoint: "https://s3.filebase.com",
  //     region: REGION,
  //     signatureVersion: "v4",
  //     params: { Bucket: S3_BUCKET },
  //     region: REGION,
  //   });

  //   const params = {
  //     ACL: "public-read",
  //     Body: file,
  //     Bucket: S3_BUCKET,
  //     Key: file.name,
  //     Metadata: { import: "car" },
  //   };

  //   myBucket
  //     .putObject(params)
  //     .on("success", () =>
  //       toastify("Picture Uploaded successfully!", "success")
  //     )
  //     .on("httpHeaders", (statusCode, headers) => {})
  //     .send((err) => {
  //       if (err) {
  //         toastify("Error Uploading Image!", "error");
  //       }
  //     });
  // };

  return (
    <div className="loginMain">
      <div className="row mx-0 loginMain">
        <div className="col-12 my-auto">
          <div className="text-center py-5">
            <img src="/imgs/logo.svg" alt="" />
          </div>
          <div className="row mx-0">
            <div className="col-md-6 mx-auto">
              <div className="loginBox">
                {/* <input
                  type="file"
                  required
                  accept={".png"}
                  onChange={(e) => {
                    uploadFileS3(e.target.files[0]);
                  }}
                /> */}
                <h3>Sign In</h3>
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <Form className="mt-5">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Username</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Enter your username..."
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <InputGroup.Text id="basic-addon1">
                            <img src="/imgs/profile.svg" alt="" />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <Form.Group
                        className="mb-0"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <InputGroup className="mb-2">
                          <Form.Control
                            type={inputType}
                            placeholder="Enter your password..."
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <InputGroup.Text
                            id="basic-addon1"
                            onClick={() =>
                              inputType === "password"
                                ? setInputType("text")
                                : setInputType("password")
                            }
                          >
                            <img
                              src="/imgs/u_eye.svg"
                              className="cursor-pointer"
                              alt=""
                            />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      {/* <p className="forgotpassword" onClick={()=>{
                        navigate('/forget-password')
                      }}>Forgotten password ?</p> */}
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label mb-0"
                          // for="flexCheckDefault"
                        >
                          <p className="forgotpassword remember">Remember me</p>
                        </label>
                      </div>
                      <div className="mb-3">
                        <Button className="loginBtn" onClick={loginAttempt}>
                          Sign In
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
