import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getProducts } from "../../../../apis/products";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../../helperFunctions/toastify";
import { SearchIcon } from "../../../../assets/images/index";
import { Switch } from "antd";
import { addPackage, updatePackage } from "../../../../apis/packages";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import {
  addAdjustmentStock,
  updateAdjustmentStock,
} from "../../../../apis/adjustmentStock";
import { toast } from "react-toastify";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditAdjustmentStock() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);

  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const [allProducts, setAllProducts] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [filteredType, setFilteredType] = useState([]);
  const [itemShow, setItemShow] = useState(false);
  const [typeShow, setTypeShow] = useState(false);
  const [searchType, setSearchType] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [physicalQty, setPhysicalQty] = useState("");
  const [difference, setDifference] = useState("");
  const [remarks, setRemarks] = useState("");

  const numberRegex = new RegExp(`^[0-9]*$`);

  const populateData = async () => {
    setPhysicalQty(state?.stock?.physicalQty);
    // setDifference(state?.stock?.difference);
    setRemarks(state?.stock?.remarks);
  };

  const savePackage = async (isFinalized) => {
    if (!selectedProduct) missingPopup("Item");
    else if (!physicalQty) missingPopup("Physical Quantity");
    else {
      let data = {
        product: selectedProduct._id,
        physicalQty,
        remarks,
        isFinalized,
        currentQty: selectedProduct.qty,
      };
      if (!state?.stock) {
        const response = await addAdjustmentStock(data);
        if (response?.status !== "Fail") {
          toastify("Adjustment Stock added successfully!", "success");
          navigate("/adjustment-stock");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateAdjustmentStock(state?.stock?._id, data);
        if (response?.status !== "Fail") {
          toastify("Adjustment Stock updated successfully!", "success");
          navigate("/adjustment-stock");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const populateOptions = async () => {
    const response = await getProducts();
    if (response?.status !== "Fail") {
      setAllProducts(response);

      setSelectedProduct(
        response.find((e) => e._id === state?.stock?.product?._id)
      );
    } else {
      toastify(response?.message, "error");
    }
  };

  const showDraft = () => {
    if (state?.stock) {
      if (state?.stock?.isFinalized === true) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const removeStock = () => {
    let _products = [...products].filter((e) => e._id !== deleting);
    setProducts(_products);
    setShow(false);
  };

  useEffect(() => {
    if (selectedProduct) {
      setSearchItem(selectedProduct?.name);
      setSearchType(selectedProduct?.type.name);
      setDifference(physicalQty - selectedProduct.qty);
    }
  }, [selectedProduct]);

  useEffect(() => {
    setFilteredType(
      allProducts.filter((e) => e.type.name.includes(searchType))
    );
  }, [searchType]);

  useEffect(() => {
    setFilteredItem(allProducts.filter((e) => e.name.includes(searchItem)));
  }, [searchItem]);

  useEffect(() => {
    if (selectedProduct) {
      setDifference(physicalQty - selectedProduct.qty);
    } else {
      setDifference("N/A");
    }
  }, [physicalQty]);

  useEffect(() => {
    populateOptions();
    if (state?.stock) populateData();
  }, []);

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.stock?._id,
      type: "Stock",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.stock ? "Edit " : "New "}`}
            Adjustment Stock
          </h1>
          {state?.stock && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Item Name *</Form.Label>
                  <InputGroup
                    className="mb-3 p-0 search-group"
                    onClick={() => {
                      setItemShow(!itemShow);
                      if (!itemShow) {
                        setTypeShow(false);
                      }
                    }}
                  >
                    <Form.Control
                      placeholder="Select"
                      value={searchItem}
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <Button>
                      <img src={SearchIcon} />
                    </Button>
                  </InputGroup>
                  {itemShow && (
                    <div className="searchbox-stock col-3">
                      {(filteredItem.length > 0
                        ? filteredItem
                        : allProducts
                      ).map((e) => (
                        <div
                          onClick={() => {
                            setSearchItem(e.name);
                            setItemShow(false);
                            setSelectedProduct(e);
                          }}
                        >
                          {e.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Type *</Form.Label>
                  <InputGroup
                    className="mb-3 p-0 search-group disabled-input"
                    onClick={() => {
                      setTypeShow(!typeShow);
                      if (!typeShow) {
                        setItemShow(false);
                      }
                    }}
                  >
                    <Form.Control
                      placeholder="Select"
                      disabled={true}
                      value={searchType}
                      onChange={(e) => setSearchType(e.target.value)}
                    />
                    {/* <Button>
                                            <img src={SearchIcon} />
                                        </Button> */}
                  </InputGroup>
                  {typeShow && (
                    <div className="searchbox-stock col-3">
                      {(filteredType.length > 0
                        ? filteredType
                        : allProducts
                      ).map((e) => (
                        <div
                          onClick={() => {
                            setSearchType(e.type.name);
                            setTypeShow(false);
                            setSelectedProduct(e);
                          }}
                        >
                          {e.type.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Group>
              </div>

              <div className="shadow-text-box col-8 mb-4 box-margin">
                <span>Current Qty</span>
                <div>{selectedProduct?.qty || "N/A"}</div>
              </div>

              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Physical Qty *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => {
                        if (
                          numberRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setPhysicalQty(e.target.value);
                        }
                      }}
                      value={physicalQty}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Difference</Form.Label>
                  <InputGroup className="mb-3 gray-background">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setDifference(e.target.value)}
                      value={difference}
                      disabled
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Remarks</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              <DeleteModal
                content="Confirm Adjustment Stock Deletion?"
                onYes={removeStock}
                onNo={handleClose}
                show={show}
              />
            </div>
          </div>

          <div className="addEdit d-flex">
            {showDraft() && (
              <Button className="addItem" onClick={() => savePackage(false)}>
                Save as Draft
              </Button>
            )}
            <Button className="addItem ms-3" onClick={() => savePackage(true)}>
              Finalized
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
