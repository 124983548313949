import { Bar } from "@nivo/bar";
import { useEffect, useRef, useState } from "react";
import { formatNumber } from "../../../helperFunctions/utils";

const BarGraph = ({
	data,
	keys,
	indexBy,
	groupMode,
	height,
	xLegend,
	yLegend,
	layout,
	enableGridX,
	enableGridY,
	label,
	isDate,
}) => {
	const barGraphRef = useRef();
	const [width, setWidth] = useState(1000);
	useEffect(() => {
		if (barGraphRef?.current?.offsetWidth) {
			setWidth(barGraphRef?.current?.offsetWidth);
		}
	}, [barGraphRef?.current?.offsetWidth]);
	return (
		<>
			<div ref={barGraphRef} style={{ overflow: "auto" }}>
				<Bar
					data={data}
					keys={keys}
					layout={layout}
					colors={{ scheme: "category10" }}
					indexBy={indexBy}
					width={width ?? 1000}
					height={500}
					margin={{ top: 50, right: 0, bottom: 80, left: 120 }}
					padding={0.3}
					enableGridX={enableGridX}
					enableGridY={enableGridY}
					groupMode={groupMode}
					// enableLabel={true}
					label={(d) => {
						if (typeof d.value == "number") {
							return label ?? formatNumber(d.value);
						}
						return label ?? d.value;
					}}
					valueScale={{ type: "linear" }}
					indexScale={{ type: "band", round: true }}
					borderColor={{
						from: "color",
						modifiers: [["darker", 1.6]],
					}}
					axisTop={null}
					axisRight={null}
					axisBottom={{
						format: (e) => {
							if (isDate) {
								return new Date(isDate).toLocaleDateString(
									"en-GB",
									{
										day: "numeric",
										month: "numeric",
										year: "numeric",
									},
								);
							}
							if (typeof e == "number") {
								return formatNumber(e);
							}
							const res =
								e?.length > 12 ? e.slice(0, 10) + "..." : e;
							return res;
						},
						tickSize: 5,
						tickPadding: 5,
						tickRotation: isDate ? 30 : 0,
						legend: "",
						// legend: xLegend,
						legendPosition: "middle",
						legendOffset: 40,
					}}
					axisLeft={{
						tickSize: 5,
						format: (e) => {
							if (typeof e == "number") {
								return formatNumber(e);
							}
							// const res =
							// e?.length > 10 ? e.slice(0, 8) + "..." : e;
							// return res;
							return e;
						},
						tickPadding: 5,
						tickRotation: 0,
						legend: yLegend,
						legendPosition: "middle",
						legendOffset: -100,
					}}
					labelSkipWidth={12}
					labelSkipHeight={12}
					labelTextColor={{
						from: "color",
						modifiers: [["brighter", 3]],
					}}
					legends={[
						{
							dataFrom: "keys",
							anchor: "bottom",
							direction: "row",
							justify: false,
							translateX: 0,
							translateY: 60,
							itemsSpacing: 2,
							itemWidth: 130,
							itemHeight: 20,
							itemDirection: "left-to-right",
							itemOpacity: 0.85,
							symbolSize: 20,
							effects: [
								{
									on: "hover",
									style: {
										itemOpacity: 1,
									},
								},
							],
						},
					]}
					role="application"
				/>
			</div>
		</>
	);
};
export default BarGraph;
