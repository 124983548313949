import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getPromo, updatePromo } from "../../../apis/promos";
import { permissionTypes } from "../../../helperFunctions/routes";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
} from "../../../helperFunctions/utils";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import DeleteModal from "../../SharedComponents/DeleteModal";

function Promo() {
  const [promos, setPromos] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();

  const getPromoCodeList = async () => {
    const response = await getPromo();
    if (response?.status !== "Fail") {
      console.log({ response });
      setPromos(response.reverse());
    } else {
      if (response?.message === "No data Found") {
        setPromos([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const editPromo = (e) => {
    navigate("/add-promo", {
      state: {
        promo: e,
      },
    });
  };

  useEffect(() => {
    getPromoCodeList();
  }, []);

  const addPromo = () => {
    navigate("/add-promo");
  };

  const removePromo = async () => {
    const response = await updatePromo(deleting, { isDeleted: true });
    if (response?.status !== "Fail") {
      toastify("Promo deleted successfully!", "success");
      getPromoCodeList();
    } else {
      toastify(response?.message, "error");
    }
    setShow(false);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      wrap: true,
      width: "180px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Treatment",
      selector: (row) => (
        <div className="d-flex flex-column">
          {row?.treatments?.map((e) => (
            <div className="my-1">{e.name}</div>
          ))}
        </div>
      ),
      wrap: true,
      width: "180px",
    },
    {
      name: "Package",
      selector: (row) => (
        <div className="d-flex flex-column">
          {row.packages.map((e) => (
            <div className="my-1">{e.name}</div>
          ))}
        </div>
      ),
      wrap: true,
      width: "180px",
    },
    {
      name: "Product",
      selector: (row) => (
        <div className="d-flex flex-column">
          {row.products.map((e) => (
            <div className="my-1">{e.name}</div>
          ))}
        </div>
      ),
      wrap: true,
      width: "180px",
    },
    {
      name: "Valid Date",
      selector: (row) =>
        `${new Date(row.validityStart).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })} to ${new Date(row.validityEnd).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        })}`,
      wrap: true,
      width: "240px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "validityStart", true),
    },
    // {
    //   name: "Usage Limit",
    //   selector: (row) => row.usageLimit?.toLocaleString(),
    //   wrap: true,
    //   width: "180px",
    //   sortable: true,
    //   sortFunction: (a, b) => customNumberSort(a, b, "usageLimit"),
    // },
    // {
    //   name: "Remarks",
    //   selector: (row) => row.remarks,
    //   wrap: true,
    //   sortable: true,
    //   width: "180px",
    // },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? promos : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        name: e.name,
        index: index,
        treatments: e.treatments,
        packages: e.packages,
        products: e.products,
        validityStart: e.vaildStartDate,
        validityEnd: e.vaildEndDate,
        usageLimit: e.usageLimit,
        remarks: e.remarks,

        action: (
          <div className="d-flex">
            <ComponentWrapepr
              moduleName="Promo"
              permissionType={permissionTypes.UPDATE}
            >
              <div
                className="mx-3  cursor-pointer icon-width"
                onClick={() => {
                  editPromo(e);
                }}
              >
                <i className="fa fa-pen"></i>
              </div>
            </ComponentWrapepr>
            <ComponentWrapepr
              moduleName="Promo"
              permissionType={permissionTypes.DELETE}
            >
              <div
                className="mx-3  cursor-pointer icon-width"
                onClick={() => {
                  setDeleting(e._id);
                  setShow(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </div>
            </ComponentWrapepr>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Packages/Promo</h1>
          <ComponentWrapepr
            moduleName="Promo"
            permissionType={permissionTypes.DELETE}
          >
            <Button className="addItem" onClick={addPromo}>
              + Add New
            </Button>
          </ComponentWrapepr>
        </div>

        <DeleteModal
          content="Confirm Promo Deletion?"
          onYes={removePromo}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          highlightOnHover
          pointerOnHover
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}

export default Promo;
