import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { addBranch, updateBranch } from "../../../apis/branch";
import { authorizedRequests } from "../../../axios/axios-config";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import CustomTable from "./CustomTable";
import DeleteModal from "../../SharedComponents/DeleteModal";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function AddEditBranch() {
	const { state } = useLocation();
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [editIndex, setEditIndex] = useState();
	const [rooms, setRooms] = useState([]);
	const [editableRoom, setEditableRoom] = useState("");
	const [show, setShow] = useState(false);
	const [showAdd, setShowAdd] = useState(false);
	const [showDel, setShowDel] = useState(false);
	const [locName, setLocName] = useState("");
	const [locDescription, setLocDescription] = useState("");
	const [loc_id, setLoc_id] = useState(null);
	const [locations, setLocations] = useState([]);
	const [deleting, setDeleting] = useState("");
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);


	const addLocation = () => {
		if (!locName) missingPopup("Location Name");
		// else if (rooms.length === 0) missingPopup("Room");
  
    let _locations = [...locations];
    _locations.push({
      rooms,
      name: locName,
      description: locDescription,
      _id: loc_id,
      staff: selectedOptions,
    });
    setLocations(_locations);
    setShowAdd(false);
    setLocName("");
    setLocDescription("");
    setLoc_id(null);
    setRooms([]);
    setSelectedOptions([]);
    // setStaffOptions([]);
		
	};

	const editLocation = (name) => {
		let _l = [...locations].find((e) => e.name === name);
		let _locations = [...locations].filter((e) => e.name !== name);
		setLocations(_locations);
		setShowAdd(true);
		setLocDescription(_l.description);
    setLoc_id(_l._id);
		setLocName(_l.name);
		setRooms(_l.rooms);
    setSelectedOptions(_l.staff)
	};

	const deleteLocation = (name) => {
		// let _locations = [...locations].filter((e) => e.name !== name);
		// setLocations(_locations);
		setDeleting(name);
		setShowDel(true);
	};

	const deleteLocationData = () => {
		let _locations = [...locations].filter((e) => e.name !== deleting);
		setLocations(_locations);
		setShowDel(false);
		setDeleting("");
	};

	const handleClose = () => {
		setShowDel(false);
		setDeleting("");
	};

  // const getStaffList = async () => {
  //   const { result, error } = await authorizedRequests({
  //     url: "users/staffList",
  //     method: "get",
  //   });
  //   if (error) {
  //     toastify(error.message ?? "", "error");
  //     return;
  //   }

  //   const defaultStaff = result?.data
  //     ?.filter((o) => o.defaultAppt === true)
  //     ?.map((o) => o._id);

  //   const tempArr = result?.data
  //     ?.filter((o) => o.role?.length !== 1 || o.role.indexOf("admin") == -1)
  //     ?.map((o) => {
  //       return {
  //         value: o?._id,
  //         label: `${o.username} (${o.role})`,
  //       };
  //     });
    
  //   console.log('Staff Options:', tempArr); 
  //   setStaffOptions(tempArr)
  // };

  const getStaffList = async () => {
    const { result, error } = await authorizedRequests({
      url: "users/staffList",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }
  
    const defaultStaff = result?.data
      ?.filter((o) => o.defaultAppt === true)
      ?.map((o) => o._id);
  
    const tempArr = result?.data
      ?.filter((o) => o.role?.length !== 1 || o.role.indexOf("admin") == -1)
      ?.map((o) => {
        return {
          value: o?._id, // Changed from 'value' to 'id'
          label: `${o.username} (${o.role})`, // Changed from 'label' to 'name'
        };
      });
  
    setStaffOptions(tempArr);
  }

	const populateData = async () => {
		setName(state?.branch?.name);
		setDescription(state?.branch?.address);
		// setRooms(state?.branch?.rooms);
		setLocations(state?.branch?.location);
	};

	const saveBranch = async () => {
		if (!name) {
			missingPopup("Name");
		}
		if (locations.length < 1) {
			missingPopup("Location");
		} else {
			let data = {
				name,
				address: description,
				location: locations,
				isActive: true,
			};
      
			if (!state?.branch) {
				const response = await addBranch(data);
				if (response?.status !== "Fail") {
					toastify("Branch added successfully!", "success");
					navigate("/branch");
				} else {
					toastify(response?.message, "error");
				}
			} else {
				const response = await updateBranch(state?.branch?._id, data);
				if (response?.status !== "Fail") {
					toastify("Branch updated successfully!", "success");
					navigate("/branch");
				} else {
					toastify(response?.message, "error");
				}
			}
		}
	};

  

	useEffect(() => {
		if (state?.branch) populateData();
    getStaffList()
	}, []);


  const handleChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  console.log("DEBUG", selectedOptions)

	return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.branch ? "Edit " : "New "}`}
          Branch
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <Form.Group className="col-md-6">
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label className="headings2">Address *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </InputGroup>
                </Form.Group>

                <div className="d-flex justify-content-between w-100">
                  <Form.Label className="headings2 gray-font mt-4">
                    Location *
                  </Form.Label>
                  <Button
                    className="addItem"
                    onClick={() => setShowAdd(!showAdd)}
                  >
                    +Add New
                  </Button>
                </div>

                {showAdd && (
                  <div className="d-flex my-3 px-3 py-3 prod-box">
                    <div className="col-5">
                      <Form.Group>
                        <Form.Label className="headings2">Name *</Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Write..."
                            onChange={(e) => setLocName(e.target.value)}
                            value={locName}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="headings2">
                          Description *
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Write..."
                            onChange={(e) => setLocDescription(e.target.value)}
                            value={locDescription}
                          />
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="col-md-12">
                        {/* <Form.Label className="headings2">Staff *</Form.Label>
                        <MultiSelectWithCheckboxes
    options={staffOptions}
    selected={selectedOptions} // Use selectedOptions from the parent
    onChange={setSelectedOptions} // Pass the state setter
  /> */}
                       <Form.Label className="headings2">Staff</Form.Label>
                       <SelectComponent
                        options={staffOptions}
                        array={selectedOptions}
                        onChange={handleChange}
                        searchEnabled
                        isNullEnabled
                         
                      />
                      </Form.Group>

                      <Form.Group>
                        <Form.Label className="headings2 d-flex">
                          Rooms
                          <div
                            className="ms-4 plus-sign"
                            onClick={() => setShow(true)}
                          >
                            +
                          </div>
                        </Form.Label>
                        <div className="ps-4 pt-2">
                          {show && (
                            <div className="d-flex">
                              <input
                                value={editableRoom}
                                className="w-180 h-50 mb-2"
                                onChange={(e) =>
                                  setEditableRoom(e.target.value)
                                }
                              />
                              <div className="d-flex ms-5">
                                <div
                                  className="mx-3 cursor-pointer"
                                  onClick={() => {
                                    if (!editableRoom)
                                      missingPopup("Room Name");
                                    else {
                                      setShow(false);
                                      setEditableRoom("");
                                      let _rooms = [...rooms];
                                      _rooms.push(editableRoom);
                                      setRooms(_rooms);
                                    }
                                  }}
                                >
                                  <i class="fa-solid fa-circle-check"></i>
                                </div>
                                <div
                                  className="mx-3 cursor-pointer"
                                  onClick={() => {
                                    setShow(false);
                                    setEditableRoom("");
                                  }}
                                >
                                  <i class="fa-solid fa-circle-xmark"></i>
                                </div>
                              </div>
                            </div>
                          )}
                          {rooms.map((e, index) => (
                            <div className="d-flex mb-2">
                              {editIndex === index ? (
                                <input
                                  value={editableRoom || e}
                                  className="w-180 h-50"
                                  onChange={(e) =>
                                    setEditableRoom(e.target.value)
                                  }
                                />
                              ) : (
                                <div className="w-180 h-50">{e}</div>
                              )}
                              {editIndex === index ? (
                                <div className="d-flex ms-5">
                                  <div
                                    className="mx-3 cursor-pointer"
                                    onClick={() => {
                                      if (!editableRoom)
                                        missingPopup("Room Name");
                                      else {
                                        let _rooms = [...rooms].filter(
                                          (f) => f !== e
                                        );

                                        _rooms.push(editableRoom);

                                        setRooms(_rooms);

                                        setShow(false);
                                        setEditIndex();
                                        setEditableRoom("");
                                      }
                                    }}
                                  >
                                    <i class="fa-solid fa-circle-check"></i>
                                  </div>
                                  <div
                                    className="mx-3 cursor-pointer"
                                    onClick={() => {
                                      setShow(false);
                                      setEditableRoom("");
                                      setEditIndex();
                                    }}
                                  >
                                    <i class="fa-solid fa-circle-xmark"></i>
                                  </div>
                                </div>
                              ) : (
                                <div className="d-flex ms-5">
                                  <div
                                    className="mx-3 cursor-pointer"
                                    onClick={() => {
                                      if (editIndex !== index)
                                        setEditIndex(index);
                                      else setEditIndex();
                                      setEditableRoom(e);
                                    }}
                                  >
                                    <i className="fa fa-pen"></i>
                                  </div>
                                  <div
                                    className="mx-3 cursor-pointer"
                                    onClick={() => {
                                      let _rooms = [...rooms].filter(
                                        (f) => f !== e
                                      );

                                      setRooms(_rooms);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      </Form.Group>

                      <Button
                        className="my-save-button mt-3"
                        onClick={addLocation}
                      >
                        Save
                      </Button>
                    </div>
                  </div>
                )}

                {locations?.length > 0 && (
                  <CustomTable
                    data={locations}
                    editLocation={editLocation}
                    deleteLocation={deleteLocation}
                    staffOptions={staffOptions}
                  />
                )}
              </div>
            </div>
          </div>

          <DeleteModal
            content="Are you sure want to delete this location?"
            onYes={() => deleteLocationData()}
            onNo={handleClose}
            show={showDel}
          />

          <div className="addEdit">
            <Button className="addItem" onClick={saveBranch}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
