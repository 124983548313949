import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { create, getDataById, update } from "../../../apis/paymentMethod";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddEditPaymentMethod() {
	const params = useParams();
	const navigate = useNavigate();

	const [values, setValues] = useState({
		name: "",
	});

	const onChange = (name, value) => {
		setValues((val) => {
			return { ...val, [name]: value };
		});
	};

	const submitForm = async () => {
		if (!values?.name) {
			missingPopup("Name");
			return;
		}

		const data = {
			name: values.name,
		};

		if (!params.id) {
			const { result, error } = await create(data);
			if (error) {
				toastify(error.message, "error");
				return;
			}
			toastify("Payment Method created successfully", "success");
			navigate(-1);
		} else {
			const { result, error } = await update({
				...data,
				dataId: params.id,
			});
			if (error) {
				toastify(error.message, "error");
				return;
			}
			toastify("Payment Method updated successfully", "success");
			navigate(-1);
		}
	};
	const populateDate = async (id) => {
		const { error, result } = await getDataById(id);
		if (error) {
			toastify(error.message, "error");
			return;
		}
		setValues({
			...values,
			name: result.data?.name,
			description: result.data?.description,
			startDate: new Date(result.data?.startDate),
			expiryDate: new Date(result.data?.expiryDate),
		});
	};

	useEffect(() => {
		if (params?.id) {
			populateDate(params?.id);
		}
	}, [params?.id]);

	return (
		<>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => {
							navigate(-1);
						}}
					></i>
					{`${!params.id ? "Add " : "Edit "}`}
					Payment Method
				</h1>
			</div>
			<div className="col-md-6 px-4 pb-3">
				<Form.Group className="">
					<Form.Label className="black-label-text required">
						Name
					</Form.Label>
					<InputGroup className="mb-3 ">
						<Form.Control
							placeholder="Write..."
							onChange={(e) => {
								onChange("name", e.target.value);
							}}
							value={values.name}
						/>
					</InputGroup>
				</Form.Group>

				<Button
					className="mt-3 addItem"
					onClick={() => {
						submitForm();
					}}
				>
					Submit
				</Button>
			</div>
		</>
	);
}
