import React from "react";
import moment from "moment";
import styled from "styled-components";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { authorizedRequests } from "../../axios/axios-config";
import { toastify } from "../../helperFunctions/toastify";

const StyledDate = styled.div`
  .themeInput .input-group {
    background-color: ${(props) =>
      props.background ? props.background : "white"};
    border: ${(props) =>
      props.background ? "inherit" : "1px solid #939393 !important"};
  }
`;

export default function DateInput({
  value,
  onChange,
  label,
  className,
  placeholder,
  background,
  inputClassName,
  calenderClass,
  style,
  disabled,
  format,
  views,
  shouldDisableDates,
}) {
  const [showED, setShowED] = useState(false);
  const [showMonthView, setShowMonthView] = useState(false);
  const [holidayDates, setHolidayDates] = useState([]);
  const handleClickAway1 = () => {
    setShowED(false);
    setShowMonthView(false);
  };

  const shouldDisabledDate = (date) => {
    if (!date?.$d) {
      return true;
    }
    if (!(date?.$d instanceof Date && !isNaN(date?.$d))) {
      return true;
    }

    const dates = holidayDates?.some(
      (disabledDate) =>
        disabledDate.getDate() === date?.$d?.getDate() &&
        disabledDate.getMonth() === date?.$d?.getMonth() &&
        disabledDate.getFullYear() === date?.$d?.getFullYear()
    );
    return dates;
  };

  const getListOfHolidays = async () => {
    const { result, error } = await authorizedRequests({
      url: "holidays/listOfHolidays",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }

    const disabledDates = [];
    result.data?.map((d) => {
      const holidayStartDate = moment(d.eventStartDate);
      const holidayEndDate = moment(d.eventEndDate);
      // Loop through the dates
      while (holidayStartDate.isSameOrBefore(holidayEndDate)) {
        // Increment the current date by one day
        disabledDates.push({
          date: moment(holidayStartDate),
          name: d.description,
        });
        holidayStartDate.add(1, "days");
      }
    });
    // const dates = result.data?.map((d) => { return { date: moment(d.eventDate), name: d.description } })
    setHolidayDates(disabledDates);
  };

  const isSpecialDay = (day) => {
    const dates = holidayDates?.some(
      (disabledDate) =>
        disabledDate.date._d.getDate() === day?.$d?.getDate() &&
        disabledDate.date._d.getMonth() === day?.$d?.getMonth() &&
        disabledDate.date._d.getFullYear() === day?.$d?.getFullYear()
    );
    return dates;
  };
  const renderDay = (day, _selectedDate, _dayInCurrentMonth) => {
    if (isSpecialDay(day)) {
      // holidayDates?.map((dates) => {
      // 	console.log(dates.date._d.getDate())
      // })
      return (
        <PickersDay className="specialDay" {..._dayInCurrentMonth}>
          {day.$D}
        </PickersDay>
      );
    }

    return <PickersDay {..._dayInCurrentMonth}>{day.$D}</PickersDay>;
  };

  useEffect(() => {
    if (shouldDisableDates) {
      getListOfHolidays();
    }
  }, [shouldDisableDates]);

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway1}>
        <StyledDate
          background={background}
          className={`w-100 ${className ?? ""}`}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              key={value}
              value={value}
              views={views ? views : ["year", "month", "day"]}
              disabled={disabled}
              onChange={(newValue) => {
                newValue?.$d.setHours(0);
                newValue?.$d.setMinutes(0);
                newValue?.$d.setSeconds(0);
                newValue?.$d.setMilliseconds(0);

                const isHoliday = holidayDates.filter((x) =>
                  moment(newValue?.$d).isSame(x.date, "day")
                );
                if (isHoliday.length > 0) {
                  toastify(
                    `${isHoliday[0].date.format("DD/MMM/YYYY")} is a holiday (${
                      isHoliday[0].name
                    })`,
                    "warning"
                  );
                }

                onChange(newValue?.$d);
                setShowED(false);
                setShowMonthView(false);
              }}
              onYearChange={() => {
                // This will be triggered when the year view is clicked
                setShowMonthView(true);
              }}
              onMonthChange={() => {
                // This will be triggered when the month view is clicked
                setShowED(true);
              }}
              componentsProps={{
                actionBar: { actions: ["today"] },
              }}
              style={{ style }}
              // shouldDisableDate={(date) => shouldDisabledDate(date)}
              open={showMonthView || showED}
              openTo={showMonthView ? "month" : showED ? "day" : "year"}
              renderDay={renderDay}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Form.Group className={`${inputClassName}`}>
                  {label && (
                    <Form.Label className="headings2">{label}</Form.Label>
                  )}

                  <InputGroup
                    className={`mb-3 d-flex pe-3 align-items-center `}
                  >
                    <Form.Control
                      ref={inputRef}
                      style={
                        background
                          ? {
                              background,
                              color: "white",
                            }
                          : {}
                      }
                      value={
                        value
                          ? new Date(value).toLocaleDateString("en-GB", {
                              day: "numeric",
                              month: "numeric",
                              year: "numeric",
                            })
                          : null
                      }
                      readOnly
                      onClick={() => setShowED(!showED)}
                      placeholder={placeholder}
                    />
                    <div
                      onClick={() => setShowED(!showED)}
                      className={calenderClass}
                    >
                      {!calenderClass && <>{InputProps?.endAdornment}</>}
                    </div>
                  </InputGroup>
                </Form.Group>
              )}
            />
          </LocalizationProvider>
        </StyledDate>
      </ClickAwayListener>
    </>
  );
}
