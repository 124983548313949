import zIndex from "@mui/material/styles/zIndex";
import { useMemo, useState } from "react";
import Select from "react-select";
// import makeAnimated from "react-select/animated";

/**
 * @options
 * @search @enabled
 * {value:"example",label:"example label"}
 * @Search @disabled
 * {id:"example",name:"example"}
 */

export default function SelectComponent({
  options,
  isNullEnabled,
  nullDisabled,
  onChange,
  name,
  className,
  style,
  array,
  searchEnabled,
  selectedValue,
  isDisabled,
  nullNotHidden,
  singleSelect,
  adjustValues,
  selectCondition,
}) {
  const selectStyles = {
    input: (styles) => ({
      ...styles,
      margin: "3px",
      height: 31,
      zIndex: "1000",
    }),
    control: (styles) => ({
      ...styles,
      border: "1px solid #939393",
      borderRadius: "7px",
      outline: "none",
      height: "fit-content",
      minHeight: "47px",
      zIndex: "1",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1001,
    }),
  };
  const singleSelectStyles = {
    input: (styles) => ({
      ...styles,
      margin: "3px",
      height: 31,
      zIndex: "1",
    }),
    control: (styles) => ({
      ...styles,
      border: "1px solid #939393",
      borderRadius: "7px",
      outline: "none",
      height: "fit-content",
      minHeight: "47px",
      zIndex: "1",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1001,
    }),
  };
  const [selectOptions, setSelectOption] = useState([]);
  useMemo(() => {
    if (searchEnabled && singleSelect && options?.length > 0) {
      const tempArray = adjustValues
        ? options.map((o) => {
            return {
              value: o._id,
              label: o.name,
            };
          })
        : options;
      if (nullDisabled) {
        setSelectOption([...tempArray]);
      } else {
        setSelectOption([{ label: "Select", value: null }, ...tempArray]);
      }
    }
  }, [options, searchEnabled, singleSelect]);
  
  if (searchEnabled && !singleSelect) {
    console.log('array received', array);
    return (
      // <>
      //   <Select
      //     closeMenuOnSelect={false}
      //     // components={animatedComponents}
      //     className={`basic-single mb-3 ${className}`}
      //     classNamePrefix="select"
      //     value={
      //       selectCondition ??
      //       array.map((o) => {
      //         return options.find((item) => item.value == o);
      //       })
      //     }
      //     styles={selectStyles}
      //     isMulti
      //     isLoading={options.length === 0}
      //     isClearable={true}
      //     // isRtl={isRtl}
      //     isSearchable={true}
      //     name={name}
      //     options={options}
      //     onChange={(e) => {
      //       onChange(e.map((o) => o.value));
      //     }}
      //     placeholder="Select"
      //   />
      // </>
      <>
      <Select
        closeMenuOnSelect={false}
        className={`basic-single mb-3 ${className}`}
        classNamePrefix="select"
        value={
          selectCondition ??
          (array?.length > 0
            ? array.map((o) => options.find((item) => item.value === o))
            : options.find((item) => item.value === selectedValue) // Handle the case when `array` is not provided or empty
          )
        }
        styles={selectStyles}
        isMulti
        isLoading={options.length === 0}
        isClearable={true}
        isSearchable={true}
        name={name}
        options={options}
        onChange={(e) => {
          onChange(e.map((o) => o.value));
        }}
        placeholder="Select"
      />
    </>

    );
  } else if (searchEnabled && singleSelect) {
    const IndicatorSeparator = ({ innerProps }) => {
      return <></>;
    };
    const DropdownIndicator = (props) => {
      return <div {...props} className="down-arrow me-3"></div>;
    };
    return (
      <>
        <Select
          closeMenuOnSelect
          components={{ IndicatorSeparator, DropdownIndicator }}
          className={`basic-single mb-3 ${className}`}
          classNamePrefix="select"
          value={
            selectCondition ??
            selectOptions?.find((o) => o.value == selectedValue)
          }
          styles={singleSelectStyles}
          isLoading={false}
          isDisabled={isDisabled}
          // isClearable={true}
          // isRtl={isRtl}
          isSearchable={true}
          name={name}
          options={selectOptions}
          // backspaceRemovesValue
          onChange={(e) => {
            // console.log(e);
            onChange(e);
          }}
          placeholder="Select"
        />
      </>
    );
  }
  return (
    <>
      <select
        name={name}
        className={`form-select mb-3 ${className}`}
        onChange={onChange}
        disabled={isDisabled}
        style={style}
        placeholder="Select"
      >
        {isNullEnabled && !nullNotHidden && selectedValue === "" && (
          <option
            value={null}
            disabled
            selected={selectedValue ? false : true}
            hidden
            // disabled={true}
          >
            Select
          </option>
        )}
        {isNullEnabled && nullNotHidden && (
          <option
            value={null}
            // disabled
            selected={!selectedValue}
            // hidden
            // disabled={true}
          >
            Select
          </option>
        )}
        {options?.length > 0 &&
          options.map((e) => (
            <>
              <option
                key={e.id}
                value={e.id}
                selected={e.id === selectedValue}
                // disabled={array?.find((o) => o === e.id)}
              >
                {e.name}
              </option>
            </>
          ))}
        {options?.length == 0 && (
          <option value={null} disabled={true}>
            No options to display
          </option>
        )}
      </select>
    </>
  );
}
