import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";
import { getCampaigns, updateCampaign } from "../../../apis/campaign";
import { permissionTypes } from "../../../helperFunctions/routes";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import {
	customDateSort,
	customStringSort,
} from "../../../helperFunctions/utils";

function Campaign() {
	const navigate = useNavigate();
	const [campaigns, setCampaigns] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getCampaignsList = async () => {
		const response = await getCampaigns();
		if (response.status !== "Fail") {
			setCampaigns(response.reverse());
			console.log({ response });
		} else {
			if (response?.message === "No data Found") {
				setCampaigns([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const removeCampaign = async () => {
		setShow(false);
		let response = await updateCampaign(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			getCampaignsList();
			toastify("Campaign deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	const editCampaign = (e) => {
		navigate("/add-campaign", {
			state: {
				campaign: e,
			},
		});
	};

	const addCampaign = () => {
		navigate("/add-campaign");
	};

	useEffect(() => {
		getCampaignsList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly7">
					<span className="me-2">Name</span>
					{
						<FilterComponent
							type="search"
							array={campaigns}
							setArray={setFilteredData}
							value="Name"
						/>
					}
				</div>
			),
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Remarks",
			selector: (row) => row.remarks,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "remarks"),
		},
		{
			name: "Created At",
			selector: (row) =>
				new Date(row.createdAt).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "createdAt", true),
		},
		{
			name: "Status",
			selector: (row) =>
				row.isActive === true ? (
					<div className="active-status">Active</div>
				) : (
					<div className="inactive-status">In Active</div>
				),
			wrap: true,
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? campaigns : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				name: e.name,
				index: index,
				remarks: e.remarks,
				createdAt: e.created_at,
				isActive: e.isActive,
				action: (
					<div className="d-flex">
						<ComponentWrapepr
							moduleName="Campaign"
							permissionType={permissionTypes.UPDATE}
						>
							<div
								className="mx-3  cursor-pointer icon-width"
								onClick={() => {
									editCampaign(e);
								}}
							>
								<i className="fa fa-pen"></i>
							</div>
						</ComponentWrapepr>
						<ComponentWrapepr
							moduleName="Campaign"
							permissionType={permissionTypes.DELETE}
						>
							<div
								className="mx-3  cursor-pointer icon-width"
								onClick={() => {
									setDeleting(e._id);
									setShow(true);
								}}
							>
								<i className="fa fa-trash"></i>
							</div>
						</ComponentWrapepr>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Campaign</h1>
					<ComponentWrapepr
						moduleName="Campaign"
						permissionType={permissionTypes.CREATE}
					>
						<Button className="addItem" onClick={addCampaign}>
							+ Add New
						</Button>
					</ComponentWrapepr>
				</div>

				<DeleteModal
					content="Confirm Campaign Deletion?"
					onYes={removeCampaign}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}

export default Campaign;
