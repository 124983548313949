export const thousandAndDecimalFormat = (data, allowDecimal, decimalScale) => {
  if (!data) 
    return 0;
  
  let decimalPoint = allowDecimal ? (decimalScale ? decimalScale : 0) : 0;
  return isNaN(data)
    ? Number.parseFloat(0)
        .toFixed(decimalPoint)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : Number.parseFloat(data)
        .toFixed(decimalPoint)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
