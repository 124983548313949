import api from "../axios/axios-config";

export const getConsents = async () => {
  try {
    let response = await api.get("consents/listOfConsents");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createConsents = async (data) => {
  try {
    let response = await api.post("consents/createConsents", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateConsents = async (dataId, data) => {
  try {
    let response = await api.post("consents/updateConsents", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
