import React from "react";
import {
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import _ from "lodash";
import { getInvoiceId } from "../../helperFunctions/utils";

import LogoPNG from "../../assets/images/logo.png";

const styles = StyleSheet.create({
  page: {
    width: 170,
    height: 145,
    paddingTop: 10,
    paddingBottom: 5,
    paddingLeft: 25,
    paddingRight: 25,
  },
  title: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11px",
    fontWeight: "bold",
  },
  address: {
    fontSize: "10px",
  },
  taxInvoice: {
    fontSize: "14px",
  },
  logo: {
    width: 160,
    height: 110,
  },
  subtitle: {
    color: "#34556f",
  },
  section: {
    flexGrow: 0,
    flexDirection: "row",
  },
  table: {
    width: "100%",
    borderBottom: "1px solid #34556f",
  },
  text: {
    fontSize: "11px",
    marginBottom: "5px",
    fontFamily: "Helvetica",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: "10px",
  },
  header: {
    fontSize: "10px",
    backgroundColor: "#989898",
    fontFamily: "Helvetica-Bold",
  },
  whiteColor: {
    color: "#ffffff",
  },
  flex: {
    flexGrow: 0,
    flexDirection: "row",
    height: "auto",
  },
  block: {
    display: "block",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  session1: {
    width: "60%",
  },
  session2: {
    width: "40%",
  },
  row1: {
    width: "30%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  row2: {
    width: "15%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  row3: {
    width: "5%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  row4: {
    width: "15%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  rowBig: {
    width: "70%",
  },
  rowSmall: {
    width: "40%",
  },
  rowHalf1: {
    width: "60%",
    marginLeft: "10px",
  },
  rowHalf2: {
    width: "40%",
    marginLeft: "10px",
  },
  noPdBot: {
    paddingBottom: "0px",
  },
  noBorder: {
    border: "0px",
  },
  textRight: {
    textAlign: "right",
  },
  labelText: {
    width: "60px",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ details, setting, usePointVal }) {
  return (
    <Document>
      <Page break={true} style={styles.page} size={[420, 140]}>
        <View style={{ ...styles.section, marginBottom: "30px" }}>
          <View style={{ textAlign: "left", width: "100%" }}>
            <Text style={{ ...styles.text, ...styles.title }}>
              EPION Pte Ltd
            </Text>
          </View>
        </View>
        <View
          style={{
            textAlign: "left",
            width: "100%",
            marginTop: "-30px",
            borderBottom: "2px",
          }}
        >
          <Text
            style={{ ...styles.text, ...styles.address, marginBottom: "6px" }}
          >
            {setting?.companyAddress}
          </Text>
        </View>
        <View
          style={{
            ...styles.section,
          }}
        >
          <View
            style={{
              ...styles.section,
              justifyContent: "space-between",
              marginTop: "5px",
              width: "100%",
            }}
          >
            <Text style={{ ...styles.text, ...styles.title }}>
              {details?.drugName}
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.title,
              }}
            >
              {details?.date}
            </Text>
          </View>
        </View>
        <View>
          <Text
            style={{
              ...styles.text,
            }}
          >
            {details?.quantity}
          </Text>
          <Text
            style={{
              ...styles.text,
            }}
          >
            {details?.description}
          </Text>
        </View>
        {/* <View
          style={{
            ...styles.section,
          }}
        >
          <View
            style={{
              ...styles.section,
              justifyContent: "space-between",
              marginTop: "5px",
              width: "100%",
            }}
          >
            <Text style={{ ...styles.text }}>
              {details?.batch && "Batch No:"} {details?.batch}
            </Text>
            <Text
              style={{
                ...styles.text,
              }}
            >
              {details?.expDate && "Expiry Date:"} {details?.expDate}
            </Text>
          </View>
        </View> */}
        <View
          style={{
            ...styles.section,
            width: "100%",
            borderTop: "2px",
          }}
        >
          <View
            style={{
              ...styles.section,
              marginTop: "5px",
              width: "100%",
            }}
          >
            <Text
              style={{ ...styles.text, ...styles.title, marginRight: "10px" }}
            >
              {details?.paitentId}
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.title,
              }}
            >
              {details?.paitentName}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
