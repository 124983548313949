import React, { useState, useEffect } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  ListGroup,
  Accordion,
} from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";
import { BlobProvider, PDFViewer } from "@react-pdf/renderer";
import DataTable from "react-data-table-component";
import _ from "lodash";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { setForm } from "../../../redux/slices/StaffFormsSlices";
import { authorizedRequests } from "../../../axios/axios-config";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { toastify } from "../../../helperFunctions/toastify";
import { generateRandomStr } from "../../../helperFunctions/utils";
import { getSessionById, getSessionByPatientId } from "../../../apis/session";
import {
  get,
  craete,
  deleteData,
  update,
} from "../../../apis/prescriptionTemplates";
import AddBoxIcon from "@mui/icons-material/AddBox";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { get as getConstants } from "../../../apis/prescriptionConstant";
import ExternalPDF from "../../SharedComponents/ExternalPDF";
import { getSetting, getExternalTemplate } from "../../../apis/generalSetting";
const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
      textAlign: "center",
    },
  },
};

const SwitchWrapper = styled.div`
  label {
    color: black;
    font-weight: bold;
    font-size: 19px;
    line-height: 21px;
  }
`;

const getDefaultValue = () => {
  return {
    title: "",
    drugroup: [
      {
        drugId: "",
        totalDosage: "",
        drugName: "",
        code: "",
        deliveryMode: "",
        dosage: "",
        period: "",
        periodDescription: "",
        takePeriod: "",
        takeType: "",
        quantity: 1,
        discount: 0,
        unitPrice: 0,
        unitCost: 0,
        instruction: "",
        type: "internal",
        isAdd: true,
        isPrn: false,
        tempId: generateRandomStr(10),
        precatutions: [{ id: Date.now().toString(32), desc: "" }],
      },
    ],
  };
};
const getDefaultValueSingle = () => {
  return {
    drugId: "",
    totalDosage: "",
    drugName: "",
    code: "",
    deliveryMode: "",
    dosage: "",
    period: "",
    periodDescription: "",
    takePeriod: "",
    takeType: "",
    quantity: 1,
    discount: 0,
    unitPrice: 0,
    unitCost: 0,
    instruction: "",
    type: "internal",
    isAdd: true,
    isPrn: false,
    tempId: generateRandomStr(10),
    precatutions: [{ id: Date.now().toString(32), desc: "" }],
  };
};

const PatientPrescriptionSheet = ({
  sessionId,
  paitentId,
  saveData,
  setSaveData,
  setSuccessSaveData,
  getEditData,
  patientData,
}) => {
  const userData = useSelector((state) => state.userData.value);
  console.log(userData);
  const dispatch = useDispatch();
  const [companySetting, setCompanySetting] = useState(null);
  const [modalLabelPDF, setmodalLabelPDF] = useState();
  useEffect(() => {
    getSetting().then((res) => {
      if (res.status !== "Fail") {
        setCompanySetting(res);
      }
    });
  }, []);
  const { patientPrescriptionSheet, dataId } = useSelector(
    (state) => state.staffForm.sessionForm
  );

  const [sessionData, setSessionData] = useState();
  const [templateData, settemplateData] = useState();
  const [sheetConstants, setsheetConstants] = useState();
  const [copysessionData, setcopySessionData] = useState();
  const [externalDetailsPDF, setExternalDetailsPDF] = useState();

  const populateTemplate = async () => {
    const response = await get();
    if (response?.status !== "Fail") {
      settemplateData(response);
    } else {
      if (response?.message === "No data Found") {
        settemplateData([]);
      } else {
        toastify(response?.message, "error");
      }
    }

    const constatntResponse = await getConstants();
    if (constatntResponse?.status !== "Fail") {
      setsheetConstants(constatntResponse);
    } else {
      if (constatntResponse?.message === "No data Found") {
        setsheetConstants([]);
      } else {
        toastify(constatntResponse?.message, "error");
      }
    }
  };
  useEffect(() => {
    populateTemplate();
  }, []);

  useEffect(() => {
    getSessionByPatientId(paitentId).then((res) => {
      const sessionDataWithDate = res.result?.data
        ?.filter((e) => e?._id !== sessionId)
        ?.flatMap((session) => {
          return session.patientPrescriptionSheet.map((prescription) => {
            return {
              ...prescription,
              date: session.date,
              sessionId: session._id,
            };
          });
        });

      setExternalDetailsPDF(res.result?.data?.find((e) => e?._id == sessionId));

      setcopySessionData(sessionDataWithDate);

      const groupedData = sessionDataWithDate.reduce((acc, item) => {
        const formattedDate = moment(item.date).format("DD-MM-YYYY");

        if (!acc[item.sessionId]) {
          acc[item.sessionId] = {
            date: formattedDate,
            types: new Set(),
            drugs: [],
            totalQuantity: 0,
            totalCost: 0,
            sessionId: item.sessionId,
          };
        }

        acc[item.sessionId].types.add(item.type);
        acc[item.sessionId].drugs.push(item.drugName);
        acc[item.sessionId].totalQuantity += item.quantity;
        acc[item.sessionId].totalCost +=
          item.unitPrice * item.quantity - item.discount;

        return acc;
      }, {});

      const result = Object.values(groupedData).map((group) => ({
        date: group.date,
        type: Array.from(group.types).join(", "),
        drugName: group.drugs.join(", "),
        quantity: group.totalQuantity,
        totalCost: group.totalCost,
        sessionId: group.sessionId,
      }));

      result.reverse();

      setSessionData(result);
    });
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [saveType, setSaveType] = useState();
  const [showModalTemplate, setShowModalTemplate] = useState(false);
  const [products, setProducts] = useState([]);
  const [precatutions, setPrecatutions] = useState([
    { id: Date.now().toString(32), desc: "" },
  ]);

  const [sheetDrugs, setSheetDrugs] = useState(
    patientPrescriptionSheet
      ? JSON.parse(JSON.stringify(patientPrescriptionSheet))
      : []
  );

  const [tempDrug, setTempDrug] = useState(getDefaultValue());
  const [gstTax, setGstTax] = useState(null);

  useEffect(() => {
    setSheetDrugs(
      patientPrescriptionSheet
        ? JSON.parse(JSON.stringify(patientPrescriptionSheet))
        : []
    );
  }, [patientPrescriptionSheet]);

  useEffect(() => {
    authorizedRequests({
      url: "product/listOfProducts",
      method: "get",
    }).then((res) => {
      setProducts(res?.result?.data ?? []);
    });

    authorizedRequests({
      url: "taxSetting/listOfTaxSetting",
      method: "get",
    }).then((res) => {
      const resultGstTax = res?.result?.data?.find(
        (x) => x.name?.toLowerCase() === "gst"
      );
      setGstTax(resultGstTax ? resultGstTax : null);
    });
  }, []);

  // const handleChange = (field, value, id) => {
  //   const updateddrugGroup = tempDrug?.drugroup?.map((item) => {
  //     if (item.tempId === id) {
  //       return {
  //         ...item,
  //         [field]: value,
  //       };
  //     }
  //     return item;
  //   });

  //   console.log(updateddrugGroup);
  //   // setTempDrug({
  //   //   ...tempDrug,
  //   //   [field]: value,
  //   // });
  // };

  const handleChange = (field, value, index) => {
    const updatedDrugroup = tempDrug.drugroup.map((drug, i) =>
      drug?.tempId === index
        ? {
            ...drug,
            [field]: value,
            ...(field === "period" && { periodDescription: value }),
          }
        : drug
    );

    setTempDrug((prevState) => ({
      ...prevState,
      drugroup: updatedDrugroup,
    }));
  };

  const handleChangeDrugId = (
    valueDrugId,
    valueDrugName,
    valuePrice,
    valueCost,
    code,
    id
  ) => {
    const updatedDrugroup = tempDrug.drugroup.map((drug, i) =>
      drug?.tempId === id
        ? {
            ...drug,
            drugId: valueDrugId,
            drugName: valueDrugName,
            unitPrice: valuePrice,
            unitCost: valueCost,
            code: code,
          }
        : drug
    );

    setTempDrug((prevState) => ({
      ...prevState,
      drugroup: updatedDrugroup,
    }));
  };

  const handleChangeSingle = (field, value) => {
    setTempDrug({
      ...tempDrug,
      [field]: value,
    });
  };

  const handleChangeSingleDrugId = (
    valueDrugId,
    valueDrugName,
    valuePrice,
    valueCost,
    code
  ) => {
    setTempDrug({
      ...tempDrug,
      drugId: valueDrugId,
      drugName: valueDrugName,
      unitPrice: valuePrice,
      unitCost: valueCost,
      code: code,
    });
  };

  const getSubtotal = (drugId, quantity) => {
    const selectedDrug = products?.find((x) => x._id === drugId);
    if (!selectedDrug) {
      return 0;
    }
    return selectedDrug.unitSellingPrice * quantity;
  };

  const getTotal = (drugId, quantity, discount) => {
    const subtotal = getSubtotal(drugId, quantity);
    return subtotal - discount;
  };

  const handleSubmit = async () => {
    let totalGST = 0;
    const dataDrugs = sheetDrugs.map((x) => {
      if (x.type === "external") {
        delete x.drugId;
      }

      if (x.type === "internal") {
        totalGST += x.quantity * x.unitPrice - x.discount;
      }

      return x;
    });

    if (gstTax !== null && totalGST !== 0) {
      if (gstTax.typeValue === "Percentage") {
        totalGST += (totalGST * gstTax.value) / 100;
      } else {
        totalGST += gstTax.value;
      }
    }

    const { result, error } = await authorizedRequests({
      url: "session/updateSession",
      method: "post",
      data: {
        dataId: dataId,
        patientPrescriptionSheet: dataDrugs,
        prescriptionGSTAmount: totalGST,
      },
    });
    if (error) {
      toastify(error?.message, "error");
      setSuccessSaveData(false);
      return;
    } else {
      dispatch(
        setForm({
          parent: "sessionForm",
          child: "patientPrescriptionSheet",
          value: dataDrugs,
        })
      );
      // toastify("Prescription Sheet saved succesfully", "success");
      getEditData();
      setSuccessSaveData(true);
      return;
    }
  };

  const renderInstruction = (service) => {
    const result = `${service?.instruction || ""} ${
      service?.isPrn ? "(When necessary)" : ""
    } ${service?.totalDosage || ""} ${service?.dosage || ""} ${
      service?.periodDescription || ""
    } ${
      service?.takePeriod && service?.takeType
        ? `For ${service?.takePeriod} ${service?.takeType}`
        : ""
    }`.trim("");

    return result;
  };

  const columns = [
    {
      name: "Drug",
      selector: (row) => (row.drugId?.name ? row.durgId?.name : row.drugName),
      wrap: true,
      width: "200px",
    },
    {
      name: "Instruction",
      selector: (row) => row.instruction,
      wrap: true,
      width: "230px",
      cell: (row) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{renderInstruction(row)}</div>
      ),
    },
    {
      name: "Precautions",
      selector: (row) => row.precatutions,
      wrap: true,
      width: "250px",
      cell: (row) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {row.precatutions.map((precaution, index) => (
            <span key={precaution.id}>
              {precaution.desc}
              {index < row.precatutions.length - 1 && ", "}
            </span>
          ))}
        </div>
      ),
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      wrap: true,
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="d-flex ">
            <div
              className="cursor-pointer"
              onClick={() => {
                setTempDrug(row);
                setPrecatutions(row?.precatutions);
                setSaveType("d");
                setShowModalTemplate(false);
                setShowModal(true);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>

            <div
              className="ms-3  cursor-pointer icon-width"
              onClick={() =>
                setSheetDrugs(
                  sheetDrugs.filter((x) => x.drugName !== row.drugName)
                )
              }
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        );
      },
      wrap: true,
    },
  ];

  const precolumns = [
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      wrap: true,
    },
    {
      name: "Drug",
      selector: (row) => row.drugName,
      wrap: true,
    },
    {
      name: "Quantity",
      selector: (row) => row.quantity,
      wrap: true,
    },
    // {
    //   name: "Total",
    //   selector: (row) => row.totalCost,
    //   wrap: true,
    // },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="d-flex ">
            <div
              className="cursor-pointer"
              onClick={() => {
                const updatedSheetDrugs = copysessionData
                  ?.filter((a) => a.sessionId == row.sessionId)
                  .map((drug) => ({
                    ...drug,
                    isAdd: false,
                  }));

                setSheetDrugs((prevSheetDrugs) => [
                  // ...prevSheetDrugs,
                  ...updatedSheetDrugs,
                ]);
              }}
            >
              <i class="fa fa-clipboard"></i>
            </div>
          </div>
        );
      },
      wrap: true,
    },
  ];

  const handleTemplateSave = async (_data) => {
    let response = null;

    if (_data?.isEdit == true) {
      response = await update(_data?._id, _data);
      if (response?.status !== "Fail") {
        populateTemplate();
        setShowModalTemplate(true);
        setShowModal(false);
        setTempDrug(getDefaultValue());
        toastify("Template Updated successfully!", "success");
      } else {
        toastify(response?.message, "error");
      }
    } else {
      response = await craete(_data);
      if (response?.status !== "Fail") {
        populateTemplate();
        setShowModalTemplate(true);
        setShowModal(false);
        setTempDrug(getDefaultValue());
        toastify("Template added successfully!", "success");
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const handleDelte = async (id) => {
    const response = await deleteData({ id: id });
    if (response?.status !== "Fail") {
      populateTemplate();
      toastify("Template deletd successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const handleAddDrug = () => {
    const newDrug = {
      drugId: "",
      totalDosage: "",
      drugName: "",
      code: "",
      deliveryMode: "",
      dosage: "",
      period: "",
      periodDescription: "",
      takePeriod: "",
      takeType: "",
      quantity: 1,
      discount: 0,
      unitPrice: 0,
      unitCost: 0,
      instruction: "",
      type: "internal",
      isAdd: true,
      isPrn: false,
      tempId: generateRandomStr(10),
      precatutions: [{ id: Date.now().toString(32), desc: "" }],
    };

    setTempDrug((prevState) => ({
      ...prevState,
      drugroup: [...prevState.drugroup, newDrug],
    }));
  };

  const validateDrugroup = (drugroup) => {
    for (let i = 0; i < drugroup.length; i++) {
      const drug = drugroup[i];
      for (const key in drug) {
        // Skip validation for 'drugId' and 'code'
        if (key === "drugId" || key === "code") {
          continue;
        }
        // if (drug[key] === "" && key !== "precatutions") {
        //   return `Error: The field "${key}" in drug ${i + 1} cannot be empty.`;
        // }
        // if (key === "precatutions") {
        //   for (let j = 0; j < drug[key].length; j++) {
        //     const precaution = drug[key][j];
        //     for (const pKey in precaution) {
        //       if (precaution[pKey] === "") {
        //         return `Error: The field "${pKey}" in precaution ${
        //           j + 1
        //         } of drug ${i + 1} cannot be empty.`;
        //       }
        //     }
        //   }
        // }
      }
    }
    return null;
  };

  useEffect(() => {
    if (saveData > 0) {
      handleSubmit();
    }
  }, [saveData]);

  return (
    <div className="consent-container pr-4 pb-4">
      <div className="addEdit">
        <div className="mb-4 d-flex align-self-center col-12">
          <div className="d-flex align-self-center col-12">
            <Button
              style={{ width: "150px", marginRight: "10px" }}
              onClick={() => setSaveData()}
              className="addItem ms-auto"
            >
              Save
            </Button>
            <Button
              style={{ width: "150px" }}
              onClick={() => setmodalLabelPDF(true)}
              className="addItem"
            >
              Print
            </Button>
          </div>
        </div>

        <div className="pb-4">
          <Button
            className="form-button"
            style={{ width: "200px" }}
            onClick={() => {
              setSaveType("d");
              setTempDrug(getDefaultValueSingle());
              setPrecatutions([{ id: Date.now().toString(32), desc: "" }]);
              setShowModal(true);
            }}
          >
            +Add Drug
          </Button>
          <Button
            className="form-button"
            style={{ width: "200px", marginLeft: "20px" }}
            onClick={() => setShowModalTemplate(true)}
          >
            Templates
          </Button>
        </div>
        <div className="highlighted-container p-4 mt-2 mb-4">
          <p className="font-600-24 grey mt-3">Prescription Sheet</p>
          <div>
            <p className="font-600-24 grey mt-3" style={{ fontSize: "18px" }}>
              Internal
            </p>
            <DataTable
              columns={columns}
              data={sheetDrugs
                .filter((x) => x.type === "internal")
                .map((x, index) => ({
                  ...x,
                  index: index,
                  key: index,
                }))}
              customStyles={customStyles}
            />
          </div>
          <div>
            <p className="font-600-24 grey mt-3" style={{ fontSize: "18px" }}>
              External
            </p>
            <DataTable
              columns={columns}
              data={sheetDrugs
                .filter((x) => x.type === "external")
                .map((x, index) => ({
                  ...x,
                  index: index,
                  key: index,
                }))}
              customStyles={customStyles}
            />
          </div>
        </div>

        <div className="highlighted-container p-4 mt-4 mb-4">
          <p className="font-600-24 grey mt-3">History Prescription Sheet</p>
          <div>
            <DataTable
              columns={precolumns}
              data={sessionData}
              customStyles={customStyles}
            />
          </div>
        </div>
      </div>

      <Modal
        centered
        size={"lg"}
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setTempDrug(getDefaultValue());
        }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <div>
              <span className="grey font-600-24 ">Drug</span>
            </div>
            <i
              className="fa fa-xmark align-self-center pointer"
              onClick={() => {
                setShowModal(false);
                setTempDrug(getDefaultValue());
              }}
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex w-100">
            <div className="col-12" style={{ paddingRight: "10px" }}>
              {saveType === "t" ? (
                <>
                  <Form.Group>
                    <Form.Label className="black-label-text">
                      Group Name
                    </Form.Label>
                    <InputGroup className="mb-3 ">
                      <Form.Control
                        type="text"
                        className="hideArrows"
                        name={"uom"}
                        placeholder="Write..."
                        onChange={(e) => {
                          setTempDrug((prevState) => ({
                            ...prevState,
                            title: e?.target?.value,
                          }));
                        }}
                        value={tempDrug.title}
                      />
                    </InputGroup>
                  </Form.Group>
                  <Form.Label className="black-label-text">
                    Drugs{" "}
                    <AddBoxIcon
                      sx={{ mt: -0.2, marginLeft: 1, cursor: "pointer" }}
                      onClick={handleAddDrug}
                    />
                  </Form.Label>
                  {tempDrug?.drugroup?.map((values, index) => (
                    <>
                      <Accordion
                        key={index}
                        defaultActiveKey={"0"}
                        style={{ marginBottom: "10px" }}
                      >
                        <Accordion.Item eventKey="0" style={{}}>
                          <Accordion.Header>
                            <div className="bold font-14">Drug {index + 1}</div>
                            {tempDrug?.drugroup?.length > 1 && (
                              <DeleteForeverIcon
                                sx={{
                                  cursor: "pointer",
                                  marginLeft: "86%",
                                  marginTop: -0.4,
                                }}
                                onClick={() => {
                                  const updatedDrugroup =
                                    tempDrug.drugroup.filter(
                                      (drug) => drug?.tempId !== values?.tempId
                                    );

                                  setTempDrug((prevState) => ({
                                    ...prevState,
                                    drugroup: updatedDrugroup,
                                  }));
                                }}
                              />
                            )}
                          </Accordion.Header>
                          <Accordion.Body>
                            <Form.Group>
                              <SwitchWrapper>
                                <Form.Check
                                  type="switch"
                                  id="custom-switch"
                                  label="Is External"
                                  checked={
                                    values.type === "internal" ? false : true
                                  }
                                  onChange={(e) => {
                                    handleChange(
                                      "type",
                                      e.target.checked === true
                                        ? "external"
                                        : "internal",
                                      values?.tempId
                                    );
                                  }}
                                />
                              </SwitchWrapper>
                            </Form.Group>
                            <Form.Group>
                              <SwitchWrapper>
                                <Form.Check
                                  type="checkbox"
                                  id="custom-prn"
                                  label="PRN"
                                  checked={values.isPrn}
                                  onChange={(e) => {
                                    handleChange(
                                      "isPrn",
                                      e.target.checked,
                                      values?.tempId
                                    );
                                  }}
                                />
                              </SwitchWrapper>
                            </Form.Group>
                            <Container>
                              {values.type === "internal" ? (
                                <Row>
                                  <Col>
                                    {" "}
                                    <Form.Group>
                                      <Form.Label className="black-label-text">
                                        Drug
                                      </Form.Label>
                                      <SelectComponent
                                        searchEnabled
                                        singleSelect
                                        selectedValue={values.drugId}
                                        options={products?.map((product) => ({
                                          label: product.name,
                                          value: product._id,
                                        }))}
                                        placeholder="Write..."
                                        onChange={(e) => {
                                          const selectedDrug = products.find(
                                            (x) => x._id === e.value
                                          );
                                          handleChangeDrugId(
                                            e.value,
                                            selectedDrug?.name,
                                            selectedDrug?.unitSellingPrice,
                                            selectedDrug?.averageUnitCost,
                                            selectedDrug?.uom?.name
                                              ? selectedDrug?.uom?.name
                                              : "",
                                            values?.tempId
                                          );
                                        }}
                                        name={"drugId"}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <Form.Group>
                                      <Form.Label className="black-label-text">
                                        Stock UOM
                                      </Form.Label>
                                      <InputGroup className="mb-3 disabled-input">
                                        <Form.Control
                                          type="text"
                                          className="hideArrows"
                                          name={"uom"}
                                          placeholder="Write..."
                                          disabled
                                          value={values.code}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <Form.Group>
                                      <Form.Label className="black-label-text">
                                        Quantity
                                      </Form.Label>
                                      <InputGroup className="mb-3">
                                        <Form.Control
                                          type="number"
                                          className="hideArrows"
                                          name={"quantity"}
                                          placeholder="Write..."
                                          value={values.quantity}
                                          onChange={(e) => {
                                            handleChange(
                                              "quantity",
                                              e.target.value,
                                              values?.tempId
                                            );
                                          }}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              ) : (
                                <Row>
                                  <Col>
                                    {" "}
                                    <Form.Group>
                                      <Form.Label className="black-label-text">
                                        Drug
                                      </Form.Label>
                                      <InputGroup className="mb-3 ">
                                        <Form.Control
                                          type="text"
                                          className="hideArrows"
                                          name={"drugName"}
                                          placeholder="Write..."
                                          onChange={(e) =>
                                            handleChange(
                                              "drugName",
                                              e.target.value,
                                              values?.tempId
                                            )
                                          }
                                          value={values.drugName}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <Form.Group>
                                      <Form.Label className="black-label-text">
                                        Stock UOM
                                      </Form.Label>
                                      <InputGroup className="mb-3 ">
                                        <Form.Control
                                          type="text"
                                          className="hideArrows"
                                          name={"uom"}
                                          placeholder="Write..."
                                          onChange={(e) =>
                                            handleChange(
                                              "code",
                                              e.target.value,
                                              values?.tempId
                                            )
                                          }
                                          value={values.code}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    {" "}
                                    <Form.Group>
                                      <Form.Label className="black-label-text">
                                        Quantity
                                      </Form.Label>
                                      <InputGroup className="mb-3 ">
                                        <Form.Control
                                          type="number"
                                          className="hideArrows"
                                          name={"quantity"}
                                          placeholder="Write..."
                                          onChange={(e) =>
                                            handleChange(
                                              "quantity",
                                              e.target.value,
                                              values?.tempId
                                            )
                                          }
                                          value={values.quantity}
                                        />
                                      </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              )}
                              <Row>
                                <Col>
                                  <Form.Group>
                                    <Form.Label className="black-label-text">
                                      Instruction
                                    </Form.Label>
                                    <InputGroup className="mb-3 ">
                                      <Form.Control
                                        type="text"
                                        className="hideArrows"
                                        name={"uom"}
                                        placeholder="Write..."
                                        onChange={(e) =>
                                          handleChange(
                                            "instruction",
                                            e.target.value,
                                            values?.tempId
                                          )
                                        }
                                        value={values?.instruction}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group>
                                    <Form.Label className="black-label-text">
                                      Mode of Delivery
                                    </Form.Label>
                                    <SelectComponent
                                      searchEnabled
                                      singleSelect
                                      selectedValue={values.deliveryMode}
                                      options={sheetConstants
                                        ?.filter(
                                          (a) =>
                                            a?.select_type ===
                                            "Mode of delivery"
                                        )
                                        ?.map((product) => ({
                                          label: product.title,
                                          value: product.title,
                                        }))}
                                      placeholder="Write..."
                                      onChange={(e) => {
                                        handleChange(
                                          "deliveryMode",
                                          e.value,
                                          values?.tempId
                                        );
                                      }}
                                      name={"modeofdelivery"}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                {" "}
                                <Form.Label className="black-label-text">
                                  Dosage
                                </Form.Label>
                                <Col>
                                  <Form.Group>
                                    <InputGroup className="mb-3 ">
                                      <Form.Control
                                        type="text"
                                        className="hideArrows"
                                        name={"uom"}
                                        placeholder="Write..."
                                        onChange={(e) =>
                                          handleChange(
                                            "totalDosage",
                                            e.target.value,
                                            values?.tempId
                                          )
                                        }
                                        value={values?.totalDosage}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group>
                                    <SelectComponent
                                      searchEnabled
                                      singleSelect
                                      selectedValue={values.dosage}
                                      options={sheetConstants
                                        ?.filter(
                                          (a) =>
                                            a?.select_type ===
                                            "Dosage strength (units)"
                                        )
                                        ?.map((product) => ({
                                          label: product.title,
                                          value: product.title,
                                        }))}
                                      placeholder="Write..."
                                      onChange={(e) => {
                                        handleChange(
                                          "dosage",
                                          e.value,
                                          values?.tempId
                                        );
                                      }}
                                      name={"dosage"}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Group>
                                    <SelectComponent
                                      searchEnabled
                                      singleSelect
                                      selectedValue={values.period}
                                      options={sheetConstants
                                        ?.filter(
                                          (a) => a?.select_type === "Frequency"
                                        )
                                        ?.map((product) => ({
                                          label: product.title,
                                          value: product.title,
                                        }))}
                                      placeholder="Write..."
                                      onChange={(e) => {
                                        handleChange(
                                          "period",
                                          e.value,
                                          values?.tempId
                                        );
                                      }}
                                      name={"period"}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group>
                                    <InputGroup className="mb-3 ">
                                      <Form.Control
                                        type="text"
                                        className="hideArrows"
                                        name={"uom"}
                                        placeholder="Write..."
                                        onChange={(e) =>
                                          handleChange(
                                            "periodDescription",
                                            e.target.value,
                                            values?.tempId
                                          )
                                        }
                                        value={values?.periodDescription}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Form.Label className="black-label-text">
                                  For
                                </Form.Label>
                                <Col>
                                  <Form.Group>
                                    <InputGroup className="mb-3 ">
                                      <Form.Control
                                        type="text"
                                        className="hideArrows"
                                        name={"uom"}
                                        placeholder="Write..."
                                        onChange={(e) =>
                                          handleChange(
                                            "takePeriod",
                                            e.target.value,
                                            values?.tempId
                                          )
                                        }
                                        value={values?.takePeriod}
                                      />
                                    </InputGroup>
                                  </Form.Group>
                                </Col>
                                <Col>
                                  <Form.Group>
                                    <SelectComponent
                                      searchEnabled
                                      singleSelect
                                      selectedValue={values.takeType}
                                      options={sheetConstants
                                        ?.filter(
                                          (a) =>
                                            a?.select_type === "Duration units"
                                        )
                                        ?.map((product) => ({
                                          label: product.title,
                                          value: product.title,
                                        }))}
                                      placeholder="Write..."
                                      onChange={(e) => {
                                        handleChange(
                                          "takeType",
                                          e.value,
                                          values?.tempId
                                        );
                                      }}
                                      name={"takeType"}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Group>
                                    <Form.Label className="black-label-text">
                                      Precatutions{" "}
                                      <AddBoxIcon
                                        sx={{
                                          mt: -0.1,
                                          marginLeft: 2,
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          const newPrecaution = {
                                            id: Date.now().toString(32),
                                            desc: "",
                                          };

                                          const updatedDrugroup =
                                            tempDrug.drugroup.map((drug) =>
                                              drug.tempId === values?.tempId
                                                ? {
                                                    ...drug,
                                                    precatutions: [
                                                      ...drug.precatutions,
                                                      newPrecaution,
                                                    ],
                                                  }
                                                : drug
                                            );

                                          setTempDrug((prevState) => ({
                                            ...prevState,
                                            drugroup: updatedDrugroup,
                                          }));
                                        }}
                                      />
                                    </Form.Label>
                                    {values?.precatutions?.map((e) => (
                                      <InputGroup className="mb-3 ">
                                        <Form.Control
                                          type="text"
                                          className="hideArrows"
                                          name={"prescription"}
                                          placeholder="Write..."
                                          onChange={(event) => {
                                            // Update the specific precaution's description
                                            const updatedPrecautions =
                                              values.precatutions.map(
                                                (item) => {
                                                  if (item.id === e.id) {
                                                    return {
                                                      ...item,
                                                      desc: event.target.value,
                                                    };
                                                  }
                                                  return item;
                                                }
                                              );

                                            // Update the specific drug group with the new precautions array
                                            const updatedDrugroup =
                                              tempDrug.drugroup.map((drug) =>
                                                drug.tempId === values.tempId
                                                  ? {
                                                      ...drug,
                                                      precatutions:
                                                        updatedPrecautions,
                                                    }
                                                  : drug
                                              );

                                            // Set the updated state
                                            setTempDrug((prevState) => ({
                                              ...prevState,
                                              drugroup: updatedDrugroup,
                                            }));
                                          }}
                                          value={e?.desc}
                                        />
                                        {values?.precatutions?.length > 1 && (
                                          <CloseIcon
                                            sx={{
                                              mt: 0.9,
                                              mr: 1,
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              // Remove the specific precaution
                                              const updatedPrecautions =
                                                values.precatutions.filter(
                                                  (item) => item.id !== e.id
                                                );

                                              // Update the specific drug group with the new precautions array
                                              const updatedDrugroup =
                                                tempDrug.drugroup.map((drug) =>
                                                  drug.tempId === values.tempId
                                                    ? {
                                                        ...drug,
                                                        precatutions:
                                                          updatedPrecautions,
                                                      }
                                                    : drug
                                                );

                                              // Set the updated state
                                              setTempDrug((prevState) => ({
                                                ...prevState,
                                                drugroup: updatedDrugroup,
                                              }));
                                            }}
                                          />
                                        )}
                                      </InputGroup>
                                    ))}
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Container>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  ))}
                </>
              ) : (
                <div className="d-flex w-100">
                  <div className="col-12" style={{ paddingRight: "10px" }}>
                    <Container>
                      <Form.Group>
                        <SwitchWrapper>
                          <Form.Check
                            type="switch"
                            id="custom-switch"
                            label="Is External"
                            checked={
                              tempDrug.type === "internal" ? false : true
                            }
                            onChange={(e) => {
                              handleChangeSingle(
                                "type",
                                e.target.checked === true
                                  ? "external"
                                  : "internal"
                              );
                            }}
                          />
                        </SwitchWrapper>
                      </Form.Group>
                      <Form.Group>
                        <SwitchWrapper>
                          <Form.Check
                            type="checkbox"
                            id="custom-prn"
                            label="PRN"
                            checked={tempDrug.isPrn}
                            onChange={(e) => {
                              handleChangeSingle("isPrn", e.target.checked);
                            }}
                          />
                        </SwitchWrapper>
                      </Form.Group>
                      {tempDrug.type === "internal" ? (
                        <Row>
                          <Col>
                            {" "}
                            <Form.Group>
                              <Form.Label className="black-label-text">
                                Drug
                              </Form.Label>
                              <SelectComponent
                                searchEnabled
                                singleSelect
                                selectedValue={tempDrug.drugId}
                                options={products?.map((product) => ({
                                  label: product.name,
                                  value: product._id,
                                }))}
                                placeholder="Write..."
                                onChange={(e) => {
                                  const selectedDrug = products.find(
                                    (x) => x._id === e.value
                                  );
                                  handleChangeSingleDrugId(
                                    e.value,
                                    selectedDrug?.name,
                                    selectedDrug?.unitSellingPrice,
                                    selectedDrug?.averageUnitCost,
                                    selectedDrug?.uom?.name
                                      ? selectedDrug?.uom?.name
                                      : ""
                                  );
                                }}
                                name={"drugId"}
                              />
                            </Form.Group>
                          </Col>
                          <Col>
                            {" "}
                            <Form.Group>
                              <Form.Label className="black-label-text">
                                Stock UOM
                              </Form.Label>
                              <InputGroup className="mb-3 disabled-input">
                                <Form.Control
                                  type="text"
                                  className="hideArrows"
                                  name={"uom"}
                                  placeholder="Write..."
                                  disabled
                                  value={tempDrug.code}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col>
                            {" "}
                            <Form.Group>
                              <Form.Label className="black-label-text">
                                Quantity
                              </Form.Label>
                              <InputGroup className="mb-3">
                                <Form.Control
                                  type="text"
                                  className="hideArrows"
                                  name={"quantity"}
                                  placeholder="Write..."
                                  value={tempDrug.quantity}
                                  onChange={(e) =>
                                    handleChangeSingle(
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : (
                        <Row>
                          <Col>
                            {" "}
                            <Form.Group>
                              <Form.Label className="black-label-text">
                                Drug
                              </Form.Label>
                              <InputGroup className="mb-3 ">
                                <Form.Control
                                  type="text"
                                  className="hideArrows"
                                  name={"drugName"}
                                  placeholder="Write..."
                                  onChange={(e) =>
                                    handleChangeSingle(
                                      "drugName",
                                      e.target.value
                                    )
                                  }
                                  value={tempDrug.drugName}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col>
                            {" "}
                            <Form.Group>
                              <Form.Label className="black-label-text">
                                Stock UOM
                              </Form.Label>
                              <InputGroup className="mb-3 ">
                                <Form.Control
                                  type="text"
                                  className="hideArrows"
                                  name={"uom"}
                                  placeholder="Write..."
                                  onChange={(e) =>
                                    handleChangeSingle("code", e.target.value)
                                  }
                                  value={tempDrug.code}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col>
                            {" "}
                            <Form.Group>
                              <Form.Label className="black-label-text">
                                Quantity
                              </Form.Label>
                              <InputGroup className="mb-3 ">
                                <Form.Control
                                  type="number"
                                  className="hideArrows"
                                  name={"quantity"}
                                  placeholder="Write..."
                                  onChange={(e) =>
                                    handleChangeSingle(
                                      "quantity",
                                      e.target.value
                                    )
                                  }
                                  value={tempDrug.quantity}
                                />
                              </InputGroup>
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label className="black-label-text">
                              Instruction
                            </Form.Label>
                            <InputGroup className="mb-3 ">
                              <Form.Control
                                type="text"
                                className="hideArrows"
                                name={"uom"}
                                placeholder="Write..."
                                onChange={(e) =>
                                  handleChangeSingle(
                                    "instruction",
                                    e.target.value
                                  )
                                }
                                value={tempDrug?.instruction}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label className="black-label-text">
                              Mode of Delivery
                            </Form.Label>
                            <SelectComponent
                              searchEnabled
                              singleSelect
                              selectedValue={tempDrug.deliveryMode}
                              options={sheetConstants
                                ?.filter(
                                  (a) => a?.select_type === "Mode of delivery"
                                )
                                ?.map((product) => ({
                                  label: product.title,
                                  value: product.title,
                                }))}
                              placeholder="Write..."
                              onChange={(e) => {
                                handleChangeSingle("deliveryMode", e.value);
                              }}
                              name={"modeofdelivery"}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        {" "}
                        <Form.Label className="black-label-text">
                          Dosage
                        </Form.Label>
                        <Col>
                          <Form.Group>
                            <InputGroup className="mb-3 ">
                              <Form.Control
                                type="text"
                                className="hideArrows"
                                name={"uom"}
                                placeholder="Write..."
                                onChange={(e) =>
                                  handleChangeSingle(
                                    "totalDosage",
                                    e.target.value
                                  )
                                }
                                value={tempDrug?.totalDosage}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <SelectComponent
                              searchEnabled
                              singleSelect
                              selectedValue={tempDrug.dosage}
                              options={sheetConstants
                                ?.filter(
                                  (a) =>
                                    a?.select_type === "Dosage strength (units)"
                                )
                                ?.map((product) => ({
                                  label: product.title,
                                  value: product.title,
                                }))}
                              placeholder="Write..."
                              onChange={(e) => {
                                handleChangeSingle("dosage", e.value);
                              }}
                              name={"dosage"}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <SelectComponent
                              searchEnabled
                              singleSelect
                              selectedValue={tempDrug.period}
                              options={sheetConstants
                                ?.filter((a) => a?.select_type === "Frequency")
                                ?.map((product) => ({
                                  label: product.title,
                                  value: product.title,
                                }))}
                              placeholder="Write..."
                              onChange={(e) => {
                                handleChangeSingle("period", e.value);
                                handleChangeSingle(
                                  "periodDescription",
                                  e.value
                                );
                              }}
                              name={"period"}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <InputGroup className="mb-3 ">
                              <Form.Control
                                type="text"
                                className="hideArrows"
                                name={"uom"}
                                placeholder="Write..."
                                onChange={(e) =>
                                  handleChangeSingle(
                                    "periodDescription",
                                    e.target.value
                                  )
                                }
                                value={tempDrug?.periodDescription}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Form.Label className="black-label-text">
                          For
                        </Form.Label>
                        <Col>
                          <Form.Group>
                            <InputGroup className="mb-3 ">
                              <Form.Control
                                type="text"
                                className="hideArrows"
                                name={"uom"}
                                placeholder="Write..."
                                onChange={(e) =>
                                  handleChangeSingle(
                                    "takePeriod",
                                    e.target.value
                                  )
                                }
                                value={tempDrug?.takePeriod}
                              />
                            </InputGroup>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <SelectComponent
                              searchEnabled
                              singleSelect
                              selectedValue={tempDrug.takeType}
                              options={sheetConstants
                                ?.filter(
                                  (a) => a?.select_type === "Duration units"
                                )
                                ?.map((product) => ({
                                  label: product.title,
                                  value: product.title,
                                }))}
                              placeholder="Write..."
                              onChange={(e) => {
                                handleChangeSingle("takeType", e.value);
                              }}
                              name={"takeType"}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label className="black-label-text">
                              Precatutions{" "}
                              <AddBoxIcon
                                sx={{
                                  mt: -0.1,
                                  marginLeft: 2,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const newPrecaution = {
                                    id: Date.now().toString(32),
                                    desc: "",
                                  };
                                  setPrecatutions([
                                    ...precatutions,
                                    newPrecaution,
                                  ]);
                                }}
                              />
                            </Form.Label>
                            {precatutions?.map((e) => (
                              <InputGroup className="mb-3 ">
                                <Form.Control
                                  type="text"
                                  className="hideArrows"
                                  name={"prescription"}
                                  placeholder="Write..."
                                  onChange={(event) => {
                                    const updatedPrecautions =
                                      precatutions?.map((item) => {
                                        if (item.id === e.id) {
                                          return {
                                            ...item,
                                            desc: event.target.value,
                                          };
                                        }
                                        return item;
                                      });
                                    setPrecatutions(updatedPrecautions);
                                  }}
                                  value={e?.desc}
                                />
                                {precatutions?.length > 1 && (
                                  <CloseIcon
                                    sx={{ mt: 0.9, mr: 1, cursor: "pointer" }}
                                    onClick={() =>
                                      setPrecatutions(
                                        precatutions?.filter(
                                          (a) => a?.id !== e?.id
                                        )
                                      )
                                    }
                                  />
                                )}
                              </InputGroup>
                            ))}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Button
            className="addItem ms-auto mt-3"
            style={{ borderRadius: "25px", width: "150px" }}
            onClick={() => {
              if (saveType === "t") {
                console.log(tempDrug);
                const error = validateDrugroup(tempDrug.drugroup);
                if (error) {
                  toastify(
                    "Please Fill All the Fields of added Drug!",
                    "error"
                  ); // You can replace this with any error handling method you prefer
                  return;
                }

                handleTemplateSave(tempDrug);
              } else {
                if (tempDrug.isAdd !== true) {
                  setSheetDrugs((prevState) => {
                    const updatedDrugs = [...prevState];
                    let selectedDrug = null;
                    if (tempDrug._id) {
                      selectedDrug = updatedDrugs.find(
                        (x) => x._id === tempDrug._id
                      );
                    } else {
                      selectedDrug = updatedDrugs.find(
                        (x) => x.tempId === tempDrug.tempId
                      );
                    }

                    if (selectedDrug) {
                      if (tempDrug.drugId) {
                        selectedDrug.drugId = tempDrug.drugId;
                      }
                      if (tempDrug.type === "internal") {
                        const selectedDrug = products.find(
                          (x) => x._id === tempDrug.drugId
                        );
                        selectedDrug.drugName = selectedDrug?.name;
                      } else {
                        selectedDrug.drugName = tempDrug.drugName;
                      }
                      selectedDrug.quantity = tempDrug.quantity;
                      selectedDrug.discount = tempDrug.discount;
                      selectedDrug.instruction = tempDrug.instruction;
                      selectedDrug.type = tempDrug.type;
                      selectedDrug.unitPrice = tempDrug.unitPrice;
                      selectedDrug.unitCost = tempDrug.unitCost;
                      selectedDrug.code = tempDrug.code;
                      selectedDrug.deliveryMode = tempDrug.deliveryMode;
                      selectedDrug.dosage = tempDrug.dosage;
                      selectedDrug.period = tempDrug.period;
                      selectedDrug.periodDescription =
                        tempDrug.periodDescription;
                      selectedDrug.takePeriod = tempDrug.takePeriod;
                      selectedDrug.takeType = tempDrug.takeType;
                      selectedDrug.totalDosage = tempDrug.totalDosage;
                      selectedDrug.precatutions = precatutions;
                      selectedDrug.isPrn = tempDrug?.isPrn;
                    }

                    return updatedDrugs;
                  });
                } else {
                  setSheetDrugs([
                    ...sheetDrugs,
                    { ...tempDrug, precatutions: precatutions, isAdd: false },
                  ]);
                }
                setTempDrug(getDefaultValue());
                setPrecatutions([{ id: Date.now().toString(32), desc: "" }]);
                setShowModal(false);
              }
            }}
          >
            Save
          </Button>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size={"lg"}
        show={showModalTemplate}
        onHide={() => {
          setShowModalTemplate(false);
          setTempDrug(getDefaultValue());
        }}
      >
        <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <div>
              <span className="grey font-600-24 ">Template</span>
              <AddBoxIcon
                sx={{ mt: -0.1, marginLeft: 2, cursor: "pointer" }}
                onClick={() => {
                  setSaveType("t");
                  setShowModal(true);
                  setTempDrug(getDefaultValue());
                }}
              />
            </div>
            <i
              className="fa fa-xmark align-self-center pointer"
              onClick={() => {
                setShowModalTemplate(false);
                setTempDrug(getDefaultValue());
              }}
              style={{ fontSize: "30px" }}
            ></i>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex w-100">
            <div className="col-12" style={{ paddingRight: "10px" }}>
              {templateData?.map((a) => (
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center mb-2 border rounded"
                >
                  <div
                    className="ms-2 me-auto cursor-pointer p-2 w-full"
                    onClick={() => {
                      console.log(a);
                      setSheetDrugs([
                        ...sheetDrugs,
                        ...a?.drugroup.map((drug) => ({
                          ...drug,
                          isAdd: false,
                        })),
                      ]);
                      setShowModalTemplate(false);
                    }}
                  >
                    <div className="fw-bold">{a?.title}</div>
                  </div>
                  <EditIcon
                    sx={{ cursor: "pointer", marginRight: "10px" }}
                    onClick={() => {
                      setSaveType("t");
                      setShowModal(true);
                      setTempDrug({ ...a, isEdit: true });
                      setPrecatutions(a?.precatutions);
                    }}
                  />
                  <CloseIcon
                    sx={{ cursor: "pointer", marginRight: "10px" }}
                    onClick={() => handleDelte(a?._id)}
                  />
                </ListGroup.Item>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {modalLabelPDF && (
        <Modal
          centered
          show={modalLabelPDF}
          onHide={() => {
            setmodalLabelPDF(false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              External Drug
            </Modal.Title>
          </Modal.Header>

          <div className="pdf-btn">
            <PDFViewer style={{ width: "100%", height: "700px" }}>
              <ExternalPDF
                setting={companySetting}
                billing={patientData}
                userData={userData}
                drugData={sheetDrugs
                  .filter((x) => x.type === "external")
                  .map((x, index) => ({
                    ...x,
                    index: index,
                    key: index,
                  }))}
              />
              {/* <ExternalPDF
                  products={products_data}
                  templates={externalTemplates}
                /> */}
            </PDFViewer>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default PatientPrescriptionSheet;
