import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import { resetValue, setData } from "../../../redux/slices/adminSlices";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function ReminderTemplateView() {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [formData, setFormData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const dispatch = useDispatch();

  const { reminderTemplateForm, allReminderTemplates } = useSelector(
    (state) => state.staffForm
  );

  const getList = async () => {
    const { result, error } = await authorizedRequests({
      url: "reminder/listOfReminders",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    var tempArr = result?.data?.map((app) => {
      return {
        id: app._id,
        name: app.name,
        message: app.message,
        via: app.via,
        viaDetail: app.viaDetail,
        isActive: app.isActive,
        phoneNumberList: app.phoneNumberList,
        emailNumberList: app.emailNumberList,
      };
    });
    dispatch(
      setData({
        parent: "allReminderTemplates",
        value: tempArr,
      })
    );
    return;
  };

  const onRowEdit = async (e) => {
    const valueObj = {
      name: e.name,
      message: e.message,
      via: e.via,
      viaDetail: e.viaDetail,
      contactNumbers:
        e.via === "whatsapp" ? e.phoneNumberList : e.emailNumberList,
      formStatus: "Edit",
      dataId: e.id,
    };
    console.log(
      "🚀 ~ file: ReminerTemplateView.jsx:65 ~ onRowEdit ~ valueObj",
      e
    );
    dispatch(
      setData({
        parent: "reminderTemplateForm",
        value: valueObj,
      })
    );
    // dispatch(
    // 	setData({
    // 		parent: "appointmentForm",
    // 		value: {
    // 			patient: tempArr.patient._id,
    // 			branch: tempArr.branch._id,
    // 			location: tempArr.location,
    // 			startTime: tempArr.startTime,
    // 			endTime: tempArr.endTime,
    // 			package: tempArr.package._id,
    // 			staff: [
    // 				...tempArr.staff.map((o) => {
    // 					return o._id;
    // 				}),
    // 			],
    // 			tag: tempArr.tag._id,
    // 			status: tempArr.status,
    // 			remarks: tempArr.remarks,
    // 			formStatus: "Edit",
    // 			editId: id,
    // 		},
    // 	}),
    // );
    navigate("/add-reminder-template", { state: { valueObj } });
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const onRowDelete = async (id) => {
    // tempArr =
    setShow(false);
    const { result, error } = await authorizedRequests({
      url: "reminder/updateReminder",
      method: "post",
      data: {
        dataId: deleting,
        isDeleted: true,
      },
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }
    toastify("Reminder deleted successfully.", "success");
    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={allReminderTemplates || []}
              setArray={setFilteredData}
              value="Name"
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Via</span>
          {
            <FilterComponent
              type="search"
              array={allReminderTemplates}
              setArray={setFilteredData}
              value="via"
            />
          }
        </div>
      ),
      selector: (row) => row.via,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "via"),
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const data = (filteredData.length === 0 ? allReminderTemplates : filteredData)
    ?.map((e, index) => {
      return {
        index: index,
        name: e.name,
        via: e.via,
        status: (
          <>
            <div
              className={`${
                e.isActive ? "green-rounded-button" : "red-rounded-button"
              }`}
              style={{ minWidth: "120px" }}
            >
              {e.isActive ? "Active" : "Inactive"}
            </div>
          </>
        ),
        action: (
          <div className="d-flex">
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                onRowEdit(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              // onClick={() => {
              // 	onRowDelete(e.id);
              // }}
              onClick={() => {
                setDeleting(e.id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    })
    .reverse();

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <>
      <div className="container">
        <div className="mainHeading px-4">
          <h1>
            {/* <i
							className="fa fa-arrow-left cursor-pointer"
							onClick={() => {
								navigate(-1);
							}}
						></i> */}
            Reminder Template
          </h1>
          <Button
            className="addItem"
            onClick={() => {
              dispatch(resetValue("reminderTemplateForm"));
              navigate("/add-reminder-template");
            }}
          >
            + Add New
          </Button>
        </div>

        <DeleteModal
          content="Confirm Reminder Template Deletion?"
          onYes={onRowDelete}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          data={data || []}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          responsive="true"
        />
      </div>
    </>
  );
}
