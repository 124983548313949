import React from "react";
import { useEffect, useRef } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getKolOptions } from "../../../apis/optionKol";
import DateInput from "../../SharedComponents/DateInput";

import {
  getPatients,
  addPatients,
  hearAboutUs,
  modifyPatientGI,
  getPatientDetailsById,
} from "../../../apis/patients";
import { LANGUAGES, MARITAL_STATUS, RACES } from "../../../constants";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Popover } from "antd";
import { PREFERRED_CONTACTS } from "../../../constants";
import { Country } from "country-state-city";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { setForm } from "../../../redux/slices/StaffFormsSlices";
import { authorizedRequests } from "../../../axios/axios-config";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { getQuestionnaireByPatientId } from "../../../apis/questionnaire";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import {
  loadOptionPatients,
  loadSelectedPatient,
} from "../../../apis/patients";
import { getoccupationList } from "../../../apis/occupation";
import Nationalities from "../../../utils/nationalities";
import { getPostalArea } from "../../../helperFunctions/utils";
import { setFormData } from "../../../redux/slices/commonSlice";

export default function TabGeneralInfo({ setPage, getEditData }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const formDataChangedRef = useRef();
  const { state } = location;

  const patientData = useSelector((state) => state.patientData.value);

  const [kolOptions, setKolOptions] = useState([]);
  const [gender, setGender] = useState();
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");
  // const [nationalID, setNationalID] = useState();
  const [occupation, setOccupation] = useState();
  const [otherOccupation, setotherOccupation] = useState("");
  const [hearAboutUsRemark, setHearAboutUsRemark] = useState("");
  const [nationalities, setnationalities] = useState();
  const [selectedMaritalStatus, setSelectedMaritalStatus] = useState();
  const [selectedRace, setSelectedRace] = useState();

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedKol, setSelectedKol] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const [dob, setDob] = useState();
  const [source, setSource] = useState();
  const [isMember, setIsMember] = useState(false);
  const [receiveMarkInfo, setReceiveMarkInfo] = useState(false);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [description, setDescription] = useState("");
  const [tagText, setTagText] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [hearingOptions, setHearingOptions] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState(MARITAL_STATUS);
  const [races, setRaces] = useState(RACES);
  const [languages, setLanguages] = useState(LANGUAGES);

  const [country, setCountry] = useState();
  const [countries, setCountries] = useState();
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [preferredContact, setPreferredContact] = useState();
  const [contactOptions, setContactOptions] = useState(PREFERRED_CONTACTS);
  const [address, setAddress] = useState("");
  const [tempPostalCode, setTempPostalCode] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  const [occupationlist, setOccupationList] = useState([]);

  const pathname = location.pathname;
  const { formData } = useSelector((state) => state.commonData);

  const getOccupationLists = async () => {
    const { result, error } = await getoccupationList();
    if (error) {
      toastify(error.message, "error");
      return;
    } else {
      setOccupationList(result.data);
    }
  };

  const contactRegex = new RegExp("^[0-9]+[0-9]*$");


  const [status, setStatus] = useState([
    { name: "Active", value: true },
    { name: "InActive", value: false },
  ]);

  useEffect(() => {
    if (!patientData) {
      formDataChangedRef.current = {
        fullName: name,
        maritalStatus: selectedMaritalStatus,
        race: selectedRace,
        email,
        contactNo,
        address,
        preferredContact,
        country,
        postalCode,
        dob,
        occupation,
        gender,
        language: selectedLanguage,
        kol: selectedKol,
        channel: selectedChannel,
        hearAboutUs: source,
        recommendFrom: selectedPatient,
        description,
        hashtag: hashtags,
        nationality: nationalities,
        otherOccupation: otherOccupation,
        hearAboutUsRemark: hearAboutUsRemark,
        isActive,
        isMember,
        receiveMarketingInfo: receiveMarkInfo
      }
    }
  }, [name, selectedMaritalStatus, selectedRace, email, contactNo, address, preferredContact, 
    country, postalCode, dob, occupation, gender, selectedLanguage, selectedKol, selectedChannel,
    source, selectedPatient, description, hashtags, nationalities, otherOccupation, hearAboutUsRemark,
    isActive, isMember, receiveMarkInfo
  ]);

  useEffect(() => {
    return () => {
      if (!patientData && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!patientData) {
      populateData();
    }
  }, []);

  const populatePatients = async () => {
    const response = await getPatients();
    setPatients(response);
  };

  const populateHearAboutUs = async () => {
    const response = await hearAboutUs();
    setHearingOptions(response);
  };

  const populateKolOptions = async () => {
    const response = await getKolOptions();
    if (response?.status !== "Fail") {
      setKolOptions(response);
    }
  };

  const editPatient = async () => {
    if (!name) {
      missingPopup("Name");
    }
    // else if (!nationalID) {
    //   missingPopup("National ID");
    // }
    // else if (!dob) {
    //   missingPopup("Date of Birth");
    // }
    // else if (!gender) {
    // 	missingPopup("Gender");
    // }
    else if (isActive === undefined) {
      missingPopup("Is Active key");
    } else {
      let apiData = {
        fullName: name,
        maritalStatus: selectedMaritalStatus,
        // nationID: nationalID,
        race: selectedRace,
        email,
        contactNo,
        address,
        preferredContact,
        country,
        postalCode,
        dob,
        occupation,
        gender,
        language: selectedLanguage,
        kol: selectedKol,
        channel: selectedChannel,
        hearAboutUs: source,
        recommendFrom: selectedPatient,
        description,
        hashtag: hashtags,
        nationality: nationalities,
        otherOccupation: otherOccupation,
        hearAboutUsRemark: hearAboutUsRemark,
      };
      const response = await modifyPatientGI(
        patientData?._id,
        apiData,
        isActive,
        isMember,
        receiveMarkInfo
      );
      if (response.status !== "Fail") {
        toastify("Patient added successfully!", "success");
        getEditData();
        dispatch(setPatientData(response));
        // setPage("emergencyContact");
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const save = async () => {
    // console.log(location?.state.isFromAppointment);
    if (!name) {
      missingPopup("Name");
    }
    // else if (!nationalID) {
    //   missingPopup("National ID");
    // }
    // else if (!dob) {
    //   missingPopup("Date of Birth");
    // }
    // else if (!gender) {
    // 	missingPopup("Gender");
    // }
    else if (isActive === undefined) {
      missingPopup("Status");
    } else {
      let apiData = {
        fullName: name,
        maritalStatus: selectedMaritalStatus,
        race: selectedRace,
        email,
        contactNo,
        address,
        preferredContact,
        country,
        postalCode,
        dob,
        occupation,
        gender,
        language: selectedLanguage,
        kol: selectedKol,
        channel: selectedChannel,
        hearAboutUs: source,
        recommendFrom: selectedPatient,
        description,
        hashtag: hashtags,
        nationality: nationalities,
        otherOccupation: otherOccupation,
        hearAboutUsRemark: hearAboutUsRemark,
      };
      const response = await addPatients(
        apiData,
        isActive,
        isMember,
        receiveMarkInfo
      );
      if (response?.status !== "Fail") {
        formDataChangedRef.current = null;
        dispatch(setFormData({
          ...formData,
          [pathname]: null
        }));
        toastify("Patient added successfully!", "success");
        if (location?.state?.isFromAppointment) {
          dispatch(
            setForm({
              parent: "appointmentForm",
              child: "patient",
              value: response._id,
            })
          );
          navigate(-1);
          return;
        } else {
          dispatch(setPatientData(response));
          getEditData();
          // setPage("emergencyContact");
        }
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const setOtherInfo = (otherData) => {
    setPostalCode(otherData?.postalCode);
    setAddress(otherData?.address);
    setContactNo(otherData?.contactNo);
    setEmail(otherData?.email);
    if (
      // otherData?.city &&
      otherData?.country
    ) {
      // setCityCountry();
      const myCountry = Country.getCountryByCode(
        otherData?.country?.isoCode
      );
      // let _cities = City.getCitiesOfCountry(
      //   otherData?.country?.isoCode
      // );
      const allCountries = Country.getAllCountries();

      allCountries.forEach(function (item, i) {
        if (item.name === myCountry?.name) {
          allCountries.splice(i, 1);
          allCountries.unshift(item);
        }
      });
    }

    let _prefContact = [...contactOptions];
    _prefContact.forEach(function (item, i) {
      if (item === otherData?.preferredContact) {
        _prefContact.splice(i, 1);
        _prefContact.unshift(item);
      }
    });
    setPreferredContact(_prefContact[0]);
    setContactOptions(_prefContact);
  };

  const setPatientInfo = async(otherData) => {
    const response = await Promise.all([
      getPatients(),
      hearAboutUs(),
      getKolOptions(),
    ]);
    const resPatient = response[0];
    const resAboutUs = response[1];
    const resKolOptions = response[2];

    if (otherData?.maritalStatus) {
      let _maritalStatus = [...maritalStatus];
      _maritalStatus.forEach(function (item, i) {
        if (item === otherData?.maritalStatus) {
          _maritalStatus.splice(i, 1);
          _maritalStatus.unshift(item);
        }
      });
      setMaritalStatus(_maritalStatus);
      setSelectedMaritalStatus(_maritalStatus[0]);
    }

    let _races = [...races];
    _races.forEach(function (item, i) {
      if (item === otherData?.race) {
        _races.splice(i, 1);
        _races.unshift(item);
      }
    });
    setRaces(_races);
    setSelectedRace(_races[0]);

    if (otherData?.language) {
      setSelectedLanguage(otherData?.language);
    }

    let _status = [...status];
    _status.forEach(function (item, i) {
      if (item?.value === otherData?.isActive) {
        _status.splice(i, 1);
        _status.unshift(item);
      }
    });
    setStatus(_status);
    setIsActive(_status[0].value);

    let _kolOptions = [...resKolOptions];
  
    setKolOptions(_kolOptions);
    setSelectedKol(otherData?.kol);
    setSelectedChannel(otherData?.channel);
    if (otherData?.hearAboutUs) {
      let _hearingOptions = [...resAboutUs];
      _hearingOptions.forEach(function (item, i) {
        if (item?._id === otherData?.hearAboutUs) {
          _hearingOptions.splice(i, 1);
          _hearingOptions.unshift(item);
        }
      });
      setHearingOptions(_hearingOptions);
      setSource(_hearingOptions[0]._id);
    } else {
      setHearingOptions(resAboutUs);
    }

    let _patients = [...resPatient?.list];
    setPatients(_patients);
    setSelectedPatient(otherData?.recommendFrom);
  }

  const populateData = async () => {
    const reduxData = formData?.[pathname];
    if (reduxData && !patientData) {
      setName(reduxData?.fullName);
      setOccupation(reduxData?.occupation);
      setnationalities(reduxData?.nationality);
      setotherOccupation(reduxData?.otherOccupation);
      setHearAboutUsRemark(reduxData?.hearAboutUsRemark);
      setDescription(reduxData?.description);
      setGender(reduxData?.gender);
      setIsMember(reduxData?.isMember);
      setReceiveMarkInfo(reduxData?.receiveMarketingInfo);
      setHashtags(reduxData?.hashtag);
      setCountry(reduxData?.country);
      setOtherInfo(reduxData);
      setDob(reduxData.dob);

      setPatientInfo(reduxData);
    } else if (patientData) {
      setName(patientData?.patientProfile?.fullName);
      // setNationalID(patientData?.patientProfile?.nationID);
      setOccupation(patientData?.patientProfile?.occupation);
      setnationalities(patientData?.patientProfile?.nationality);
      setotherOccupation(patientData?.patientProfile?.otherOccupation);
      setHearAboutUsRemark(patientData?.patientProfile?.hearAboutUsRemark);
      setDescription(patientData?.patientProfile?.description);
      setGender(patientData?.patientProfile?.gender);
      setIsMember(patientData?.isMember);
      setReceiveMarkInfo(patientData?.receiveMarketingInfo);
      setHashtags(patientData?.patientProfile?.hashtag);
      setCountry(patientData?.patientProfile?.country);
      setOtherInfo(patientData?.patientProfile);
      setDob(
        patientData?.patientProfile?.dob &&
          patientData?.patientProfile?.dob !== ""
          ? new Date(patientData?.patientProfile?.dob)
          : null
      );

      setPatientInfo({
        ...patientData.patientProfile,
        isActive: patientData.isActive
      });
    }
  };

  const openQuestionnaire = () => {
    window.open(`/questions/${patientData._id}`, "_blank").focus();
  };

  const getQuestionnaireData = async () => {
    const { result, error } = await getQuestionnaireByPatientId(
      patientData._id
    );

    if (result) {
      result?.data?.questionData?.forEach((o) => {
        if (o?.question?.linkedTo == "fullName" && !name) setName(o.textAnswer);
        else if (o?.question?.linkedTo == "occupation" && !occupation) {
          setOccupation(o.textAnswer);
        } else if (o?.question?.linkedTo == "email" && !email)
          setEmail(o.textAnswer);
        else if (o?.question?.linkedTo == "contactNo" && !contactNo)
          setContactNo(o.textAnswer);
        else if (o?.question?.linkedTo == "postalCode" && !postalCode)
          setPostalCode(o.textAnswer);
        else if (o?.question?.linkedTo == "address" && !address)
          setAddress(o.textAnswer);
        else if (o?.question?.linkedTo == "description" && !description)
          setDescription(o.textAnswer);
      });
    }
  };
  const copy = () => {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(
        `${process.env.REACT_APP_OWN_URI}questions/${patientData._id}`
      );
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = `${process.env.REACT_APP_OWN_URI}questions/${patientData._id}`;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand("copy");
      } catch (err) {
        console.error("Unable to copy to clipboard", err);
      }
      document.body.removeChild(textArea);
    }
  };

  // const setCityCountry = async () => {
  //   const myCountry = Country.getCountryByCode(
  //     patientData?.patientProfile?.country?.isoCode
  //   );
  //   // let _cities = City.getCitiesOfCountry(
  //   //   patientData?.patientProfile?.country?.isoCode
  //   // );
  //   const allCountries = Country.getAllCountries()

  //   allCountries.forEach(function (item, i) {
  //     if (item.name === myCountry?.name) {
  //       allCountries.splice(i, 1);
  //       allCountries.unshift(item);
  //     }
  //   });

  //   // _cities.forEach(function (item, i) {
  //   //   if (
  //   //     item.name === patientData?.patientProfile?.city ||
  //   //     item.name.toLowerCase === patientData?.patientProfile?.city
  //   //   ) {
  //   //     _cities.splice(i, 1);
  //   //     _cities.unshift(item);
  //   //   }
  //   // });

  //   setCountries(allCountries);
  //   setCountry(allCountries[0]);
  //   // setCities(_cities);
  //   // setCity(_cities[0]);
  // };

  useEffect(() => {
    if (patientData) {
      populateData();
    } else {
      populatePatients();
      populateHearAboutUs();
      populateKolOptions();
    }
    setCountries(Country.getAllCountries());
    getOccupationLists();
  }, [patientData]);

  useEffect(() => {
    if (patientData?._id) {
      getPatientDetailsById(patientData?._id).then((res) => {
        if (res._id) {
          dispatch(setPatientData(res));
        }
      });
    }
  }, [patientData?._id]);

  useEffect(() => {
    if (!patientData?._id) {
      return;
    }
    // ()();
  }, [patientData?._id]);


  // useEffect(() => {
  //   if (typeof postalCode === 'string' && postalCode.length >= 2) {
  //     const matchedLocation = getPostalArea(postalCode);
  //     setAddress(matchedLocation ? matchedLocation : "");
  //   } else {
  //     setAddress(""); // Clear location if input doesn't meet criteria
  //   }
  // }, [postalCode]);

  useEffect(() => {
    // Check if postalCode is a string and has exactly 6 characters
    if (typeof postalCode === 'string' && postalCode.length === 6 && fetchTrigger === true) {
      const fetchAddress = async () => {
        const matchedLocation = await getPostalArea(postalCode, setLoading);
        setAddress(matchedLocation || ""); // Set address if found, otherwise empty
        setFetchTrigger(false);
      };

      fetchAddress();
    }
  }, [postalCode, fetchTrigger]);

  const getHearAboutUsDetail = () => {
    const hearAboutUsDetail = hearingOptions?.find((x) => x._id === source);
    return hearAboutUsDetail?.name;
  };

  return (
    <div>
      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Full Name *</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Write..."
            />
          </InputGroup>
        </Form.Group>

        <span className="col-1" />

        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Marital Status</Form.Label>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={selectedMaritalStatus}
            onChange={(e) => {
              setSelectedMaritalStatus(e.value);
            }}
            options={
              maritalStatus?.length > 0
                ? [
                    ...maritalStatus?.map((e) => {
                      return {
                        value: e,
                        label: e,
                      };
                    }),
                  ]
                : []
            }
          />
          {/* <select
						className="form-select mb-3"
						onChange={(e) => {
							setSelectedMaritalStatus(e.target.value);
						}}
					>
						{!patientData?.patientProfile?.maritalStatus && (
							<option value={""}>Select</option>
						)}
						{maritalStatus?.map((e) => (
							<option value={e}>{e}</option>
						))}
					</select> */}
        </Form.Group>
      </div>

      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Occupation</Form.Label>
          {/* <InputGroup className="mb-3">
            <Form.Control
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
              placeholder="Write..."
            />
          </InputGroup> */}
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={occupation}
            onChange={(e) => {
              setOccupation(e.value);
              e.value === "Other" && setotherOccupation("");
            }}
            options={
              occupationlist?.length > 0
                ? [
                    ...occupationlist
                      ?.map((e) => {
                        return {
                          value: e?.name,
                          label: e?.name,
                        };
                      })
                      .concat({
                        value: "Other",
                        label: "Other",
                      }),
                  ]
                : []
            }
          />
        </Form.Group>

        <span className="col-1" />

        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Race</Form.Label>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={selectedRace}
            onChange={(e) => {
              setSelectedRace(e.value);
            }}
            options={
              races?.length > 0
                ? [
                    ...races.map((e) => {
                      return {
                        value: e,
                        label: e,
                      };
                    }),
                  ]
                : []
            }
          />
          {/* <select
						className="form-select mb-3"
						onChange={(e) => {
							setSelectedRace(e.target.value);
						}}
					>
						{!patientData?.patientProfile?.race && (
							<option value={""}>Select</option>
						)}
						{races?.map((e) => (
							<option value={e}>{e}</option>
						))}
					</select> */}
        </Form.Group>
      </div>

      <div className="d-flex">
        {occupation === "Other" && (
          <>
            <Form.Group className="col-5 mt-3">
              <Form.Label className="headings2">Occupation (Other)</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  value={otherOccupation}
                  onChange={(e) => setotherOccupation(e.target.value)}
                  placeholder="Write..."
                />
              </InputGroup>
            </Form.Group>

            <span className="col-1" />
          </>
        )}

        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Nationality</Form.Label>

          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={nationalities}
            onChange={(e) => {
              setnationalities(e.value);
            }}
            options={
              Nationalities?.length > 0
                ? [
                    ...Nationalities?.map((e) => {
                      return {
                        value: e,
                        label: e,
                      };
                    }),
                  ]
                : []
            }
          />
        </Form.Group>
      </div>

      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Email</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Write..."
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              // maxLength={30}
            />
          </InputGroup>
        </Form.Group>

        <span className="col-1" />

        <Form.Group className="col-5 mt-3">
      <Form.Label className="headings2">Postal Code</Form.Label>
      <InputGroup className="mb-3">
        <Form.Control
          placeholder="Write..."
          value={postalCode}
          maxLength={6}
          onChange={(e) => setPostalCode(e.target.value)}
        />
        <Button
          className="add-button"
          size="sm"
          onClick={() => {
            // setPostalCode(tempPostalCode); 
            setFetchTrigger(true); // Toggle fetchTrigger to trigger the effect// 
          }}
          style={{ fontSize: '0.9rem' }} // Slightly smaller font size for button text
        >
          <p className="update-address mb-0">Update Address</p>
        </Button>
      </InputGroup>
      
      <div className="mt-3">
        {loading && (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        )}
      </div>
    
    </Form.Group>

        {/* <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">City</Form.Label>
          <select
            className="form-select mb-3"
            onChange={(e) => {
              let val = e.target.value;
              if (val === "") {
                setCity();
              } else {
                setCity(JSON.parse(e.target.value));
              }
            }}
          >
            {!patientData?.patientProfile?.city && (
              <option value={""}>Select</option>
            )}
            {cities?.map((e, id) => (
              <option value={JSON.stringify(e)}>{e.name}</option>
            ))}
          </select>
        </Form.Group> */}
      </div>

      <div className="d-flex flex-row">
        <div className="col-5">
          <Form.Group className=" mt-3">
            <Form.Label className="headings2">Contact No *</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Write..."
                value={contactNo}
                onChange={(e) => {
                  if (
                    contactRegex.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setContactNo(e.target.value);
                  }
                }}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className=" mt-3">
            <Form.Label className="headings2">Date of Birth</Form.Label>
            <DateInput
              value={dob ? new Date(dob).toLocaleDateString() : null}
              placeholder="Select Dob"
              onChange={(e) => {
                const parsedDate = new Date(e);
                const displayFormat = `${parsedDate.getFullYear()}-${(
                  parsedDate.getMonth() + 1
                )
                  .toString()
                  .padStart(2, "0")}-${parsedDate
                  .getDate()
                  .toString()
                  .padStart(2, "0")}`;
                setDob(displayFormat);
              }}
            />
          </Form.Group>

          <Form.Group className=" mt-3">
            <Form.Label className="headings2">Preferred Contact</Form.Label>
            <SelectComponent
              singleSelect
              searchEnabled
              selectedValue={preferredContact ?? null}
              onChange={(e) => {
                let val = e.value;
                if (val === "") {
                  setPreferredContact();
                } else {
                  setPreferredContact(e.value);
                }
              }}
              options={
                contactOptions?.length > 0
                  ? [
                      ...contactOptions?.map((e) => {
                        return {
                          value: e,
                          label: e,
                        };
                      }),
                    ]
                  : []
              }
            />
            {/* <select
							className="form-select mb-3"
							aria-label="Default select example"
							onChange={(e) => {
								let val = e.target.value;
								if (val === "") {
									setPreferredContact();
								} else {
									setPreferredContact(e.target.value);
								}
							}}
						>
							{!patientData?.patientProfile?.preferredContact && (
								<option value={""}>Select</option>
							)}
							{contactOptions.map((e) => (
								<option value={e}>{e}</option>
							))}
						</select> */}
          </Form.Group>
        </div>

        <span className="col-1" />

        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Address</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Write..."
              style={{ resize: "none" }}
              className="larger-text-area"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>

      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2 d-flex">Country</Form.Label>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={country ? JSON.stringify(country) : null}
            onChange={(e) => {
              let val = e.value;
              if (val === "") {
                setCountry();
              } else {
                setCountry(JSON.parse(val));
              }
            }}
            options={
              countries?.length > 0
                ? [
                    ...countries?.map((e) => {
                      return {
                        value: JSON.stringify({
                          name: e.name,
                          isoCode: e.isoCode,
                        }),
                        label: e.name,
                      };
                    }),
                  ]
                : []
            }
          />
          {/* <select
						className="form-select mb-3"
						onChange={(e) => {
							// if (country) {
							//   let newCities = City.getCitiesOfCountry(country.isoCode);
							//   setCities(newCities);
							// }
							let val = e.target.value;
							if (val === "") {
								setCountry();
								// setCity();
								// setCities([]);
							} else {
								setCountry(JSON.parse(val));
								// let newCities = City.getCitiesOfCountry(
								//   JSON.parse(val).isoCode
								// );
								// setCities(newCities);
								// setCity(newCities[0]);
							}
						}}
					>
						{!patientData?.patientProfile?.country && (
							<option value={""}>Select</option>
						)}
						{countries?.map((e) => (
							<option value={JSON.stringify(e)}>{e.name}</option>
						))}
					</select> */}
        </Form.Group>

        <span className="col-1" />
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Recommend From</Form.Label>
          <AsyncSelect
            value={selectedPatient}
            setValue={(value) => {
              let val = value._id;
              if (val === "") {
                setSelectedPatient();
              } else {
                setSelectedPatient(value._id);
              }
            }}
            loadOptions={(search, loadedOptions, { page }) =>
              loadOptionPatients(search, loadedOptions, { page })
            }
            loadSelectedOption={loadSelectedPatient}
          />
        </Form.Group>
      </div>

      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2 d-flex">Gender</Form.Label>
          <Form.Check
            inline
            label="Male"
            type={"radio"}
            onClick={() => setGender("Male")}
            checked={gender === "Male"}
          />
          <Form.Check
            inline
            label="Female"
            type={"radio"}
            onClick={() => setGender("Female")}
            checked={gender === "Female"}
          />
        </Form.Group>

        <span className="col-1" />
        {/* KOL/Channel */}
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">KOL</Form.Label>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={selectedKol ?? null}
            onChange={(e) => {
              let val = e.value;
              if (val === "") {
                setSelectedKol();
              } else {
                setSelectedKol(e.value);
              }
            }}
            options={
              kolOptions?.length > 0
                ? [
                    ...kolOptions?.map((e) => {
                      return {
                        value: e._id,
                        label: e.name,
                      };
                    }),
                  ]
                : []
            }
          />
        </Form.Group>
      </div>
      <div className="d-flex">
        {/* <span className="col-1" /> */}

        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Channel</Form.Label>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={selectedChannel ?? null}
            onChange={(e) => {
              let val = e.value;
              if (val === "") {
                setSelectedChannel();
              } else {
                setSelectedChannel(e.value);
              }
            }}
            options={
              kolOptions?.length > 0
                ? [
                    ...kolOptions?.map((e) => {
                      return {
                        value: e._id,
                        label: e.name,
                      };
                    }),
                  ]
                : []
            }
          />
        </Form.Group>

        <span className="col-1" />

        <Form>
          <div className="mt-4">
            <Form.Check
              type={"checkbox"}
              label="Member"
              onClick={() => setIsMember(!isMember)}
              checked={isMember === true}
            />
            <Form.Check
              type={"checkbox"}
              label="I agree to receive marketing information"
              onClick={() => setReceiveMarkInfo(!receiveMarkInfo)}
              checked={receiveMarkInfo === true}
            />
          </div>
        </Form>
      </div>

      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Language</Form.Label>

          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={selectedLanguage ?? null}
            onChange={(e) => {
              let val = e.value;
              if (val === "") {
                setSelectedLanguage();
              } else {
                setSelectedLanguage(e.value);
              }
            }}
            options={
              languages?.length > 0
                ? [
                    ...languages?.map((e) => {
                      return {
                        value: e,
                        label: e,
                      };
                    }),
                  ]
                : []
            }
          />
        </Form.Group>

        <span className="col-1" />

        {patientData && (
          <React.Fragment>
            <Popover
              className="popper"
              content={<div>Copied to Clipboard!</div>}
              trigger="click"
            >
              <div
                onClick={copy}
                className="cursor-pointer copy-icon mt-4 me-3"
              >
                <i class="fa-regular fa-copy"></i>
              </div>
            </Popover>

            <button
              className="questionnaire-button mt-4"
              onClick={openQuestionnaire}
            >
              Questionnaire
            </button>
          </React.Fragment>
        )}
      </div>
      <div className="d-flex z-5">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">
            {" "}
            How Did You Hear About Us?
          </Form.Label>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={source ?? null}
            onChange={(e) => {
              let val = e.value;
              if (e.value === "Other" || e.value === "OTHER") {
                setHearAboutUsRemark("");
              }
              if (val === "") {
                setSource();
              } else {
                setSource(e.value);
              }
            }}
            options={
              hearingOptions?.length > 0
                ? [
                    ...hearingOptions?.map((e) => {
                      return {
                        value: e._id,
                        label: e.name,
                      };
                    }),
                  ]
                : []
            }
          />
        </Form.Group>
        <span className="col-1" />

        <>
          {(getHearAboutUsDetail() === "Other" ||
            getHearAboutUsDetail() === "OTHER") && (
            <Form.Group className="col-5 mt-3">
              <Form.Label className="headings2">
                How Did You Hear About Us? (Other)
              </Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  value={hearAboutUsRemark}
                  onChange={(e) => setHearAboutUsRemark(e.target.value)}
                  placeholder="Write..."
                />
              </InputGroup>
            </Form.Group>
          )}

          <span className="col-1" />
        </>
      </div>

      <div className="d-flex z-5">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Description</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              as="textarea"
              placeholder="Write..."
              style={{ resize: "none" }}
              className="large-text-area"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </InputGroup>
        </Form.Group>
      </div>

      <Form.Group className="col-5 mt-3">
        <Form.Label className="headings2">Input Hashtag</Form.Label>
        <InputGroup className="mb-3 p-0">
          <Form.Control
            placeholder="Write..."
            value={tagText}
            onChange={(e) => setTagText(e.target.value)}
          />
          <Button
            size="sm"
            className="add-button"
            onClick={() => {
              if (tagText && tagText !== "") {
                setHashtags((old) => [...old, tagText]);
                setTagText("");
              }
            }}
          >
            Add
          </Button>
        </InputGroup>
        <div className="d-flex mb-3 flex-wrap">
          {hashtags?.map((hash) => (
            <span
              className="multi-select"
              onClick={() => {
                let arr = [...hashtags].filter((e) => !e.includes(hash));
                setHashtags(arr);
              }}
            >
              {hash}
              <i class="fa-solid fa-xmark ms-3" style={{ color: "red" }}></i>
            </span>
          ))}
        </div>
      </Form.Group>

      <Button
        className="addItem"
        onClick={() => {
          if (patientData) {
            editPatient();
          } else {
            save();
          }
        }}
      >
        Save
      </Button>
    </div>
  );
}
