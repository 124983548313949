import api from "../axios/axios-config";

export const getClientGrowth = async (_data) => {
  try {
    let response = await api.post("reports/clientgrowth", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsAge = async (params) => {
  try {
    let response = await api.get("dashboardReports/comparisonOfPatientsAge", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: params,
    });

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsCountry = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsCountry",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsNationality = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsNationality",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsMaterialStatus = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsMaterialStatus",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsOccupation = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsOccupation",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsAddress = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsAddress",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsGender = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsGender",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsEthnicity = async () => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsEthnicity",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getComparisonOfPatientsHearAboutUs = async (params) => {
  try {
    let response = await api.get(
      "dashboardReports/comparisonOfPatientsHearAboutUs",
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: params,
      }
    );

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getSaleReport1 = async ({ data }) => {
  try {
    let response = await api.get("reports/salesReport", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      body: data,
    });

    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getSaleReport = async (_data) => {
  try {
    let response = await api.post("reports/revenuefornewbusiness", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getYearOverYear = async (_data) => {
  try {
    let response = await api.post("reports/yearoveryear", _data);
    // return response;
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAverageClv = async (_data) => {
  try {
    let response = await api.post("reports/averageclv", _data);
    // return response;
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getDailyConsolidateRevenue = async (_data) => {
  try {
    let response = await api.post("reports/dailyconsolidate", _data);
    // return response;
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getRevenueReport = async (_data) => {
  try {
    let response = await api.post("reports/dailyconsolidate", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getSalePaymentMethodReport = async (_data) => {
  try {
    let response = await api.post("reports/salesPaymentMethod", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getMediaResponseReport = async (_data) => {
  try {
    let response = await api.post("reports/salesMediaResponse", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getCostOfSellingReport = async (_data) => {
  try {
    let response = await api.post("reports/marketingRevenue", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getCustomerAcquisitionCost = async (_data) => {
  try {
    let response = await api.post(
      "reports/marketing/customer_acquisition_cost",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

// Client Profile Detail Table Report
export const getClientGrowthDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/client_profile/client_growth",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getDemographicDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/client_profile/demographic",
      _data
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getClientHearAboutUsDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/client_profile/hearaboutus",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

// Daily Consolidated Sales Detail Table Report
export const getRevenueDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/daily_consolidate/revenue",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getPaymentMethodDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/daily_consolidate/payment_method",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getMediaResponseDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/daily_consolidate/media_response",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

// Sales Report Detail Table Report
export const getSaleRevenueDetail = async (_data) => {
  try {
    let response = await api.post("reports/details/sales/revenue", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getSaleYoyRevenueDetail = async (_data) => {
  try {
    let response = await api.post("reports/details/sales/yoy_revenue", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getSaleAverageClvDetail = async (_data) => {
  try {
    let response = await api.post("reports/details/sales/average_clv", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

// Marketing Report Detail Table Report
export const getMarketingSellingDetail = async (_data) => {
  try {
    let response = await api.post("reports/details/marketing/selling", _data);
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getMarketingAcquisitionCostDetail = async (_data) => {
  try {
    let response = await api.post(
      "reports/details/marketing/acquisition_cost",
      _data
    );
    return response;
    // return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
