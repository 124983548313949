import React, { useState } from "react";
import { Table } from "react-bootstrap";
import Checkbox from "@mui/material/Checkbox";
import styled from "styled-components";
import { ExpandableIcon } from "../../../assets/images/index";

const RefundWrapper = styled.div`
  .MuiCheckbox-root {
    padding: 0px;
  }
`;

export default function ItemTable({
  checkboxValues,
  packages,
  products,
  treatments,
  setCheckboxValues,
}) {
  const [activeIndex, setActiveIndex] = useState();

  const setParentValues = (re) => {
    let copyValues = [...checkboxValues];

    [...re.products, ...re.treatments].map((item) => {
      if (copyValues?.includes(item._id)) {
        copyValues = copyValues?.filter((x) => x !== item._id);
      } else {
        copyValues.push(item._id);
      }
    });

    setCheckboxValues(copyValues);
  };

  const setChildValues = (_id) => {
    if (checkboxValues?.includes(_id)) {
      setCheckboxValues(checkboxValues?.filter((x) => x !== _id));
    } else {
      setCheckboxValues([...checkboxValues, _id]);
    }
  };

  const checkChildrenValues = (re) => {
    let exist = false;
    [...re.treatments, ...re.products].map((item) => {
      if (checkboxValues?.includes(item._id)) {
        exist = true;
      }
    });

    return exist;
  };

  return (
    <RefundWrapper>
      <Table>
        <thead
          style={{
            background: "#34556F",
            color: "white",
          }}
        >
          <tr style={{ height: "45px" }} className={`align-items-center`}>
            <th className=""></th>
            <th className="wd-150">Type</th>
            <th className="wd-150 ">
              <span className="me-2 ps-2">Name</span>
            </th>
            <th className="wd-150">Qty</th>
            <th className="wd-150">
              <span className="me-2">Unit Price</span>
            </th>
            <th className="wd-150">Discount</th>
            <th className="wd-100">Amount</th>
            <th className="wd-100"></th>
          </tr>
        </thead>
        <tbody>
          {packages.map((e, index) => (
            <React.Fragment>
              <tr key={index}>
                <td className="ps-3">
                  <Checkbox
                    checked={checkChildrenValues(e)}
                    onChange={() => setParentValues(e)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </td>
                <td className="wd-150">{e.type}</td>
                <td className="wd-150">{e.name}</td>
                <td className="wd-150">{e.qty}</td>
                <td className="wd-150">
                  {e.unitPrice
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td className="wd-100">{e.discount}</td>
                <td className="wd-100">
                  {e.totalPrice
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </td>
                <td>
                  <div className="d-flex ">
                    <div className="ms-2 cursor-pointer icon-width">
                      <img
                        src={ExpandableIcon}
                        className="expand-icon"
                        onClick={(e) => {
                          if (activeIndex == index) {
                            setActiveIndex();
                            return;
                          }
                          setActiveIndex(index);
                        }}
                      />
                    </div>
                  </div>
                </td>
              </tr>

              {activeIndex === index && (
                <>
                  <tr>
                    <td
                      colSpan="12"
                      style={{ backgroundColor: "#f2f2f2", padding: "20px" }}
                    >
                      <Table>
                        <thead
                          style={{
                            background: "#34556F",
                            color: "white",
                            width: "100%",
                          }}
                        >
                          <tr
                            style={{ height: "45px" }}
                            className="align-items-center"
                          >
                            <th></th>
                            <th className="wd-150">
                              <span className="me-2 ps-2">Type</span>
                            </th>
                            <th className="wd-150">Name</th>
                            <th className="wd-150">Limit</th>
                            <th className="wd-150">Used</th>
                            <th className="wd-150">Unit Price</th>
                            <th className="wd-150">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {e.products?.length > 0 &&
                            e?.products?.map((product, productIndex) => {
                              return (
                                <tr key={productIndex}>
                                  <td>
                                    <Checkbox
                                      checked={
                                        checkboxValues?.filter(
                                          (x) => x === product._id
                                        ).length > 0
                                      }
                                      onChange={() =>
                                        setChildValues(product._id)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </td>
                                  <td>{product.type}</td>
                                  <td>{product.name}</td>
                                  <td>{product.limit}</td>
                                  <td>{product.used}</td>
                                  <td>{product.unitPrice}</td>
                                  <td>{product.totalPrice}</td>
                                </tr>
                              );
                            })}

                          {e.treatments?.length > 0 &&
                            e?.treatments?.map((treatment, treatmentIndex) => {
                              return (
                                <tr key={treatmentIndex}>
                                  <td>
                                    <Checkbox
                                      checked={
                                        checkboxValues?.filter(
                                          (x) => x === treatment._id
                                        ).length > 0
                                      }
                                      onChange={() =>
                                        setChildValues(treatment._id)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                  </td>
                                  <td>{treatment.type}</td>
                                  <td>{treatment.name}</td>
                                  <td>{treatment.limit}</td>
                                  <td>{treatment.used}</td>
                                  <td>{treatment.unitPrice}</td>
                                  <td>{treatment.totalPrice}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                </>
              )}
            </React.Fragment>
          ))}

          {[...products, ...treatments].map((e, index) => (
            <tr key={index}>
              <td className="ps-3">
                <Checkbox
                  checked={
                    checkboxValues?.filter((x) => x === e._id).length > 0
                  }
                  onChange={() => setChildValues(e._id)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </td>
              <td className="wd-150">{e.type}</td>
              <td className="wd-150">{e.name}</td>
              <td className="wd-150">{e.qty}</td>
              <td className="wd-150">{e.unitPrice}</td>
              <td className="wd-100">{e.discount}</td>
              <td className="wd-100">{e.totalPrice}</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </RefundWrapper>
  );
}
