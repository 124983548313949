import api from "../axios/axios-config";

export const getBranches = async () => {
  try {
    let response = await api.get("branch/listOfBranch");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addBranch = async (data) => {
  try {
    let response = await api.post("branch/createBranch", {
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateBranch = async (dataId, data) => {
  try {
    let response = await api.post("branch/updateBranch", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteBranch = async (dataId, data) => {
  try {
    let response = await api.post("branch/deleteBranch", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
