import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

export default function TextQuestion({ question, grpQuestion, onChange }) {
	return (
		<Col className="mb-3" md={question.size ? question.size : 12} key={question.question}>
			<div>
				<div className="question-header">
					{question?.question}
				</div>
				<div className="pt-2">
					<Form.Control
						as="textarea"
						rows={1}
						value={question.patient_answer}
						onChange={(e) => {
							onChange(
								question._id,
								e?.target?.value,
								question?.questionType,
								grpQuestion,
							);
						}}
						className="question-input"
						style={{ resize: "none" }}
					/>
				</div>
			</div>
		</Col>
	);
}
