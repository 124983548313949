import { authorizedRequests } from "../axios/axios-config";

const getListOfHolidays = async () => {
	const response = await authorizedRequests({
		url: "holidays/listOfHolidays",
		method: "get",
	});
	return response;
};

const createHoliday = async (data) => {
	const response = await authorizedRequests({
		url: "holidays/createHoliday",
		method: "post",
		data,
	});
	return response;
};

const updateHoliday = async (data) => {
	const response = await authorizedRequests({
		url: "holidays/updateHoliday",
		method: "post",
		data,
	});
	return response;
};

const getHolidayById = async (id) => {
	const response = await authorizedRequests({
		url: "holidays/getHolidayById",
		method: "post",
		data: {
			id: id,
		},
	});
	return response;
};

export { getListOfHolidays, createHoliday, updateHoliday, getHolidayById };
