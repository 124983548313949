import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

export default function Products() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [product, setProduct] = useState();

  useEffect(() => {
    if (state?.product) {
      setProduct(state?.product);
    }
  }, []);

  return (
    <>
      <div className="container p-0 h-100">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            Product Details
          </h1>
        </div>
        <div className="d-flex p-3">
          <Button
            className="addItem"
            onClick={() =>
              navigate("/receiving-history", {
                state: {
                  product,
                },
              })
            }
          >
            Receiving History
          </Button>
          <Button
            className="addItem mx-2"
            onClick={() =>
              navigate("/adjustment-history", {
                state: {
                  product,
                },
              })
            }
          >
            Adjustment History
          </Button>
          <Button
            className="addItem"
            onClick={() =>
              navigate("/add-inventory-products", {
                state: {
                  product,
                },
              })
            }
          >
            Edit
          </Button>
        </div>
        <div className="ps-3 pb-5">
          <div className="detail-box">
            <div>Code</div>
            <div>{product?.code}</div>
          </div>
          <div className="detail-box">
            <div>Name</div>
            <div>{product?.name}</div>
          </div>
          <div className="detail-box">
            <div>Type</div>
            <div>{product?.type.name}</div>
          </div>
          <div className="detail-box">
            <div>Branch</div>
            <div>{product?.branch?.name || "N/A"}</div>
          </div>
          <div className="detail-box">
            <div>Location</div>
            <div>
              {product?.branch?.location?.find(
                (b) => b._id === product?.location
              )?.name || "N/A"}
            </div>
          </div>
          <div className="detail-box">
            <div>UOM</div>
            <div>{product?.uom?.name}</div>
          </div>
          <div className="detail-box">
            <div>Qty</div>
            <div>{product?.qty?.toLocaleString()}</div>
          </div>
          <div className="detail-box">
            <div>Average Unit Price</div>
            <div>{product?.averageUnitCost?.toLocaleString()}</div>
          </div>
          <div className="detail-box">
            <div>Unit Selling Price</div>
            <div>{product?.unitSellingPrice?.toLocaleString()}</div>
          </div>
          <div className="detail-box">
            <div>Total Cost</div>
            <div>
              {(product?.qty * product?.averageUnitCost)?.toLocaleString()}
            </div>
          </div>
          <div className="detail-box">
            <div>Total Selling Price</div>
            <div>{product?.totalSellingPrice?.toLocaleString()}</div>
          </div>
        </div>
      </div>
    </>
  );
}
