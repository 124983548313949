import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getStaffByDepartment } from "../../../apis/staff";
import { DOCTOR_DEPARTMENT_ID } from "../../../constants";
import { checkPermission } from "../../../helperFunctions/checkPermission";
import { toastify } from "../../../helperFunctions/toastify";
import FilterComponent from "../../SharedComponents/FilterComponent";

function DoctorNotes() {
	const navigate = useNavigate();
	const userData = useSelector((state) => state.userData.value);
	const [staff, setStaff] = useState([]);
	const [filteredData, setFilteredData] = useState([]);

	const showPatients = (data) => {
		navigate("/patient-list", {
			state: {
				doctorId: data,
			},
		});
	};

	const getStaffList = async () => {
		const response = await getStaffByDepartment(DOCTOR_DEPARTMENT_ID);
		if (response.status !== "Fail") {
			console.log(
				"🚀 ~ file: DoctorNotes.js:28 ~ getStaffList ~ response",
				response,
			);
			setStaff(response);
		} else {
			toastify(response.message, "error");
		}
	};

	useEffect(() => {
		getStaffList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly29">
					<span className="me-2">Doctor Name</span>
					{
						<FilterComponent
							type="search"
							array={staff}
							setArray={setFilteredData}
							value="Username"
						/>
					}
				</div>
			),
			selector: (row) => row.username,
		},
		{
			name: "Type/Specialties",
			selector: (row) => row.designation,
		},
		{
			name: "Patient Count",
			selector: (row) => row.patientCount,
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? staff : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				index: index,
				username: (
					<div
						className="cursor-pointer"
						onClick={() => showPatients(e._id)}
					>
						{e.username}
					</div>
				),
				designation: (
					<div
						className="cursor-pointer"
						onClick={() => showPatients(e._id)}
					>
						{e.designation}
					</div>
				),
				patientCount: (
					<div
						className="cursor-pointer"
						onClick={() => showPatients(e._id)}
					>
						{e?.patientList?.length || 0}
					</div>
				),
				action: (
					<div
						className="cursor-pointer"
						onClick={() => showPatients(e._id)}
					>
						<i class="fa-regular fa-eye"></i>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>
						<span className="unbold">Note-Taking / </span>Doctor
					</h1>
				</div>
				{checkPermission(userData, "Note-Taking", "view") && (
					<DataTable
						columns={columns}
						customStyles={customStyles}
						data={data || []}
						pagination
						conditionalRowStyles={conditionalRowStyles}
					/>
				)}
			</div>
		</>
	);
}

export default DoctorNotes;
