export const MARITAL_STATUS = ["Single", "Married", "Others"];

export const PREFERRED_CONTACTS = ["Email", "SMS", "Whatsapp"];

export const medicalType = ["Allergy", "Medical"];

export const DOCTOR_DEPARTMENT_ID = "63369369ec82159eef468f7c";

export const NURSE_DEPARTMENT_ID = "633693e9ec82159eef468f93";

export const SALES_DEPARTMENT_ID = "633693f4ec82159eef468f9e";

export const ROLES = ["admin", "staff"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const RACES = [
  "Asian",
  "Black or African American",
  "White",
  "American Indian or Alaska Native",
  "Native Hawaiian or Pacific Islander",
];

export const LANGUAGES = [
  "Afar",
  "Abkhazian",
  "Afrikaans",
  "Akan",
  "Amharic",
  "Aragonese",
  "Arabic",
  "Assamese",
  "Avar",
  "Aymara",
  "Azerbaijani",
  "Bashkir",
  "Belarusian",
  "Bulgarian",
  "Bihari",
  "Bislama",
  "Bambara",
  "Bengali",
  "Tibetan",
  "Breton",
  "Bosnian",
  "Catalan",
  "Chechen",
  "Chamorro",
  "Corsican",
  "Cree",
  "Czech",
  "Old Church Slavonic / Old Bulgarian",
  "Chuvash",
  "Welsh",
  "Danish",
  "German",
  "Divehi",
  "Dzongkha",
  "Ewe",
  "Greek",
  "English",
  "Esperanto",
  "Spanish",
  "Estonian",
  "Basque",
  "Persian",
  "Peul",
  "Finnish",
  "Fijian",
  "Faroese",
  "French",
  "West Frisian",
  "Irish",
  "Scottish Gaelic",
  "Galician",
  "Guarani",
  "Gujarati",
  "Manx",
  "Hausa",
  "Hebrew",
  "Hindi",
  "Hiri Motu",
  "Croatian",
  "Haitian",
  "Hungarian",
  "Armenian",
  "Herero",
  "Interlingua",
  "Indonesian",
  "Interlingue",
  "Igbo",
  "Sichuan Yi",
  "Inupiak",
  "Ido",
  "Icelandic",
  "Italian",
  "Inuktitut",
  "Japanese",
  "Javanese",
  "Kongo",
  "Kikuyu",
  "Kuanyama",
  "Kazakh",
  "Greenlandic",
  "Cambodian",
  "Kannada",
  "Korean",
  "Kanuri",
  "Kashmiri",
  "Kurdish",
  "Komi",
  "Cornish",
  "Kirghiz",
  "Latin",
  "Luxembourgish",
  "Ganda",
  "Limburgian",
  "Lingala",
  "Laotian",
  "Lithuanian",
  "Latvian",
  "Malagasy",
  "Marshallese",
  "Maori",
  "Macedonian",
  "Malayalam",
  "Mongolian",
  "Moldovan",
  "Marathi",
  "Malay",
  "Maltese",
  "Burmese",
  "Nauruan",
  "North Ndebele",
  "Nepali",
  "Ndonga",
  "Dutch",
  "Norwegian Nynorsk",
  "Norwegian",
  "South Ndebele",
  "Navajo",
  "Chichewa",
  "Occitan",
  "Ojibwa",
  "Oromo",
  "Oriya",
  "Ossetian",
  "Punjabi",
  "Pali",
  "Polish",
  "Pashto",
  "Portuguese",
  "Quechua",
  "Raeto Romance",
  "Kirundi",
  "Romanian",
  "Russian",
  "Rwandi",
  "Sanskrit",
  "Sardinian",
  "Sindhi",
  "Sango",
  "Serbo-Croatian",
  "Sinhalese",
  "Slovak",
  "Slovenian",
  "Samoan",
  "Shona",
  "Somalia",
  "Albanian",
  "Serbian",
  "Swati",
  "Southern Sotho",
  "Sundanese",
  "Swedish",
  "Swahili",
  "Tamil",
  "Telugu",
  "Tajik",
  "Thai",
  "Tigrinya",
  "Turkmen",
  "Tagalog",
  "Tswana",
  "Tonga",
  "Turkish",
  "Tsonga",
  "Tatar",
  "Twi",
  "Tahitian",
  "Uyghur",
  "Urdu",
  "Venda",
  "Vietnamese",
  "Volapük",
  "Walloon",
  "Wolof",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zhuang",
  "Chinese",
  "Zulu",
];

export const QuetionnaireLinkedTo = [
  {
    label: "Full Name",
    value: "patientProfile.fullName",
  },
  {
    label: "NRIC / FIN / PASSPORT",
    value: "patientProfile.nationID",
  },
  {
    label: "Date of Birth",
    value: "patientProfile.dob",
  },
  {
    label: "Marital Status",
    value: "patientProfile.maritalStatus",
  },
  {
    label: "Occupation",
    value: "patientProfile.occupation",
  },
  {
    label: "Nationality",
    value: "patientProfile.nationality",
  },
  {
    label: "Email",
    value: "patientProfile.email",
  },
  {
    label: "Contact No",
    value: "patientProfile.contactNo",
  },
  {
    label: "Language",
    value: "patientProfile.language",
  },
  {
    label: "Postal Code",
    value: "patientProfile.postalCode",
  },
  {
    label: "Address",
    value: "patientProfile.address",
  },
  {
    label: "Description",
    value: "patientProfile.description",
  },
  {
    label: "Hear About Us",
    value: "patientProfile.hearAboutUs",
  },
  {
    label: "Emergency Contact (Name)",
    value: "emergencyContact.name",
  },
  {
    label: "Emergency Contact (Relation)",
    value: "emergencyContact.relation",
  },
  {
    label: "Emergency Contact (Phone)",
    value: "emergencyContact.contactNo",
  },
];

export const yearsOptions = [
  {
    label: "2010",
    value: "2010",
  },
  {
    label: "2011",
    value: "2011",
  },
  {
    label: "2012",
    value: "2012",
  },
  {
    label: "2013",
    value: "2013",
  },
  {
    label: "2014",
    value: "2014",
  },
  {
    label: "2015",
    value: "2015",
  },
  {
    label: "2016",
    value: "2016",
  },
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },

  {
    label: "2024",
    value: "2024",
  },
  {
    label: "2025",
    value: "2025",
  },
  {
    label: "2026",
    value: "2026",
  },
  {
    label: "2027",
    value: "2027",
  },
  {
    label: "2028",
    value: "2028",
  },
  {
    label: "2029",
    value: "2029",
  },
  {
    label: "2030",
    value: "2030",
  },
];
// export const UNAVAILABLE_COUNTRIES = [
//   "AX",
//   "AS",
//   "AI",
//   "AQ",
//   "AW",
//   "BM",
//   "BV",
//   "IO",
//   "KY",
//   "CX",
//   "CC",
//   "CK",
//   "FK",
//   "FO",
//   "GF",
//   "PF",
//   "TF",
//   "GI",
//   "GL",
//   "GP",
//   "GU",
//   "GG",
//   "HM",
//   "HK",
//   "JE",
//   "MO",
//   "IM",
//   "MH",
//   "MQ",
//   "YT",
//   "MC",
//   "MS",
//   "BQ",
//   "NC",
//   "NU",
//   "NF",
//   "MP",
//   "PS",
//   "PN",
//   "PR",
//   "RE",
//   "SH",
//   "PM",
//   "BL",
//   "MF",
//   "GS",
//   "SJ",
//   "TK",
//   "TC",
//   "UM",
//   "VA",
//   "VG",
//   "VI",
//   "WF",
//   "EH",
//   "XK",
//   "CW",
//   "SX",
// ];
