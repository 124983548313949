import React from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Country } from "country-state-city";
import { useEffect } from "react";
import { PREFERRED_CONTACTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { modifyPatientCI } from "../../../apis/patients";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { toastify } from "../../../helperFunctions/toastify";

export default function TabAddressContact({}) {
	const patientData = useSelector((state) => state.patientData.value);
	const dispatch = useDispatch();

	// const [cities, setCities] = useState();
	// const [city, setCity] = useState();
	const [country, setCountry] = useState();
	const [countries, setCountries] = useState();
	const [email, setEmail] = useState();
	const [contact, setContact] = useState("");
	const [prefContact, setPrefContact] = useState();
	const [contactOptions, setContactOptions] = useState(PREFERRED_CONTACTS);
	const [address, setAddress] = useState();
	const [postalCode, setPostalCode] = useState();
	const contactRegex = new RegExp("^[0-9]+[0-9]*$");

	const populateData = () => {
		setPostalCode(patientData?.contactInfo?.postalCode);
		setAddress(patientData?.contactInfo?.address);
		setContact(patientData?.contactInfo?.contactNo || "");
		setEmail(patientData?.contactInfo?.email);
		if (
			patientData?.contactInfo?.city &&
			patientData?.contactInfo?.country
		) {
			setCityCountry();
		}

		let _prefContact = [...contactOptions];
		_prefContact.forEach(function (item, i) {
			if (item === patientData?.contactInfo?.preferredContact) {
				_prefContact.splice(i, 1);
				_prefContact.unshift(item);
			}
		});
		setPrefContact(_prefContact[0]);
		setContactOptions(_prefContact);
	};

	const setCityCountry = async () => {
		const myCountry = Country.getCountryByCode(
			patientData?.contactInfo?.country?.isoCode,
		);
		// let _cities = City.getCitiesOfCountry(
		//   patientData?.contactInfo?.country?.isoCode
		// );
		const allCountries = Country.getAllCountries();

		allCountries.forEach(function (item, i) {
			if (item.name === myCountry?.name) {
				allCountries.splice(i, 1);
				allCountries.unshift(item);
			}
		});

		// _cities.forEach(function (item, i) {
		//   if (
		//     item.name === patientData?.contactInfo?.city ||
		//     item.name.toLowerCase === patientData?.contactInfo?.city
		//   ) {
		//     _cities.splice(i, 1);
		//     _cities.unshift(item);
		//   }
		// });

		setCountries(allCountries);
		setCountry(allCountries[0]);
		// setCities(_cities);
		// setCity(_cities[0]);
	};

	useEffect(() => {
		setCountries(Country.getAllCountries());
	}, []);

	// useEffect(() => {
	//   if (country) {
	//     let newCities = City.getCitiesOfCountry(country.isoCode);
	//     setCities(newCities);
	//   }
	// }, [country]);

	useEffect(() => {
		if (patientData?.contactInfo) {
			populateData();
		}
	}, []);

	const savePatient = async () => {
		if (email && !email.includes("@")) {
			toastify("Invalid Email!", "error");
			return;
		} else {
			const response = await modifyPatientCI(
				patientData._id,
				email,
				contact,
				prefContact,
				country,
				// city?.name,
				address,
				postalCode,
			);

			if (response?.status !== "Fail") {
				dispatch(setPatientData(response));
				// setPage("emergencyContact");
			} else {
				toastify(response.message, "error");
			}
		}
	};

	return (
		<div>
			<div className="d-flex">
				<Form.Group className="col-5 mt-3">
					<Form.Label className="headings2">Email</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							placeholder="Write..."
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>

				<span className="col-1" />

				{/* <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">City</Form.Label>
          <select
            className="form-select mb-3"
            onChange={(e) => {
              let val = e.target.value;
              if (val === "") {
                setCity();
              } else {
                setCity(JSON.parse(e.target.value));
              }
            }}
          >
            {!patientData?.contactInfo?.city && (
              <option value={""}>Select</option>
            )}
            {cities?.map((e, id) => (
              <option value={JSON.stringify(e)}>{e.name}</option>
            ))}
          </select>
        </Form.Group> */}
			</div>

			<div className="d-flex flex-row">
				<div className="col-5">
					<Form.Group className=" mt-3">
						<Form.Label className="headings2">
							Contact No
						</Form.Label>
						<InputGroup className="mb-3">
							<Form.Control
								placeholder="Write..."
								value={contact}
								onChange={(e) => {
									if (
										contactRegex.test(e.target.value) ||
										e.target.value === ""
									) {
										setContact(e.target.value);
									}
								}}
							/>
						</InputGroup>
					</Form.Group>

					<Form.Group className=" mt-3">
						<Form.Label className="headings2">
							Preferred Contact
						</Form.Label>
						<select
							className="form-select mb-3"
							aria-label="Default select example"
							onChange={(e) => {
								let val = e.target.value;
								if (val === "") {
									setPrefContact();
								} else {
									setPrefContact(e.target.value);
								}
							}}
						>
							{!patientData?.contactInfo?.preferredContact && (
								<option value={""}>Select</option>
							)}
							{contactOptions.map((e) => (
								<option value={e}>{e}</option>
							))}
						</select>
					</Form.Group>
				</div>

				<span className="col-1" />

				<Form.Group className="col-5 mt-3">
					<Form.Label className="headings2">Address</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							as="textarea"
							placeholder="Write..."
							style={{ resize: "none" }}
							className="larger-text-area"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>
			</div>

			<div className="d-flex">
				<Form.Group className="col-5 mt-3">
					<Form.Label className="headings2 d-flex">
						Country
					</Form.Label>
					<select
						className="form-select mb-3"
						onChange={(e) => {
							// if (country) {
							//   let newCities = City.getCitiesOfCountry(country.isoCode);
							//   setCities(newCities);
							// }
							let val = e.target.value;
							if (val === "") {
								setCountry();
								// setCity();
								// setCities([]);
							} else {
								setCountry(JSON.parse(val));
								// let newCities = City.getCitiesOfCountry(
								//   JSON.parse(val).isoCode
								// );
								// setCities(newCities);
								// setCity(newCities[0]);
							}
						}}
					>
						{!patientData?.contactInfo?.country && (
							<option value={""}>Select</option>
						)}
						{countries?.map((e) => (
							<option value={JSON.stringify(e)}>{e.name}</option>
						))}
					</select>
				</Form.Group>

				<span className="col-1" />

				<Form.Group className="col-5 mt-3">
					<Form.Label className="headings2">Postal Code</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							placeholder="Write..."
							value={postalCode}
							onChange={(e) => setPostalCode(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>
			</div>

			<Button className="addItem" onClick={savePatient}>
				Save
			</Button>
		</div>
	);
}
