import React from "react";
import {
  Page,
  Image,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import _ from "lodash";
import { getInvoiceId, convertDateTime } from "../../helperFunctions/utils";

import LogoPNG from "../../assets/images/logo.png";

const styles = StyleSheet.create({
  page: {
    width: 870,
    height: 1245,
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 35,
    paddingRight: 35,
  },
  title: {
    fontFamily: "Helvetica-Bold",
    fontSize: "11px",
    fontWeight: "bold",
  },
  address: {
    fontSize: "10px",
  },
  taxInvoice: {
    fontSize: "14px",
  },
  logo: {
    width: 160,
    height: 110,
  },
  subtitle: {
    color: "#34556f",
  },
  section: {
    flexGrow: 0,
    flexDirection: "row",
  },
  table: {
    width: "100%",
    borderBottom: "1px solid #34556f",
  },
  text: {
    fontSize: "11px",
    marginBottom: "5px",
    fontFamily: "Helvetica",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingTop: 8,
    paddingBottom: 8,
    fontSize: "10px",
  },
  header: {
    fontSize: "10px",
    backgroundColor: "#989898",
    fontFamily: "Helvetica-Bold",
  },
  whiteColor: {
    color: "#ffffff",
  },
  flex: {
    flexGrow: 0,
    flexDirection: "row",
    height: "auto",
  },
  block: {
    display: "block",
  },
  bold: {
    fontFamily: "Helvetica-Bold",
    fontWeight: "bold",
  },
  session1: {
    width: "60%",
  },
  session2: {
    width: "40%",
  },
  row1: {
    width: "20%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  row2: {
    width: "15%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  row3: {
    width: "15%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  row4: {
    width: "15%",
    marginLeft: "10px",
    marginRight: "10px",
  },
  rowBig: {
    width: "70%",
  },
  rowSmall: {
    width: "40%",
  },
  rowHalf1: {
    width: "60%",
    marginLeft: "10px",
  },
  rowHalf2: {
    width: "40%",
    marginLeft: "10px",
  },
  noPdBot: {
    paddingBottom: "0px",
  },
  noBorder: {
    border: "0px",
  },
  textRight: {
    textAlign: "right",
  },
  labelText: {
    width: "60px",
  },
});

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ billing, setting, drugData, userData }) {
  return (
    <Document>
      <Page break={true} style={styles.page} size={"A4"}>
        <View style={{ ...styles.section, marginBottom: "30px" }}>
          <View>
            <Image src={LogoPNG} style={styles.logo} />
          </View>
          <View style={{ textAlign: "right", width: "100%" }}>
            <Text
              style={{ ...styles.text, ...styles.title, marginBottom: "6px" }}
            >
              EPION CLINIC MEDICAL AESTHETICS
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.bold,
                fontSize: "11px",
                marginBottom: "6px",
              }}
            >
              Company UEN: {setting?.companyUEN}
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.bold,
                fontSize: "11px",
                marginBottom: "6px",
              }}
            >
              GST No: {setting?.companyGST}
            </Text>
            <Text
              style={{ ...styles.text, ...styles.address, marginBottom: "6px" }}
            >
              {setting?.companyAddress}
            </Text>
            <Text style={{ ...styles.text, ...styles.address }}>
              {setting?.companyContactNo} | {setting?.companyEmail}
            </Text>
            <Text style={{ ...styles.text, ...styles.address }}>
              {setting?.companySite}
            </Text>
          </View>
        </View>
        <View
          style={{
            ...styles.section,
            borderBottom: "2px",
          }}
        >
          <View
            style={{
              ...styles.section,
              justifyContent: "space-between",
              marginTop: "5px",
              width: "100%",
            }}
          >
            <Text style={{ ...styles.text, ...styles.title }}>
              Prescription
            </Text>
            <Text
              style={{
                ...styles.text,
                ...styles.title,
              }}
            >
              Visit Date: {moment(new Date()).format("DD MMM YYYY") ?? ""}
            </Text>
          </View>
        </View>

        <View style={{ ...styles.section, marginBottom: "10px" }}>
          <View style={{ ...styles.session1, marginTop: "10px" }}>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>
                Patient Name
              </Text>
              <Text style={styles.text}>
                : {billing?.patientProfile?.fullName ?? ""}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>Patient ID </Text>
              <Text style={styles.text}>: {billing?.accountNumber ?? ""}</Text>
            </View>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>
                Date of Birth{" "}
              </Text>
              <Text style={styles.text}>
                : {convertDateTime(billing?.patientProfile?.dob) ?? "-"}
              </Text>
            </View>
            <View style={styles.flex}>
              <Text style={{ ...styles.text, width: "70px" }}>
                Contact No.{" "}
              </Text>
              <Text style={styles.text}>
                : {billing?.patientProfile?.contactNo ?? ""}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View
            style={{
              ...styles.row,
              ...styles.bold,
              ...styles.header,
            }}
          >
            <Text style={{ ...styles.row1 }}>Drug</Text>
            <Text style={{ ...styles.row2, width: "20%" }}>Instructions</Text>
            <Text style={{ ...styles.row3, ...styles.textRight }}>
              Frequency
            </Text>
            <Text style={{ ...styles.row2, ...styles.textRight }}>
              Duration
            </Text>
            <Text style={{ ...styles.row4, ...styles.textRight }}>QTY</Text>
            <Text style={{ ...styles.row4, ...styles.textRight }}>UOM</Text>
          </View>
          {drugData?.map((item, index) => (
            <View style={styles.row} key={index}>
              <Text style={styles.row1}>{item?.drugName ?? "-"}</Text>
              <Text style={{ ...styles.row2, width: "20%" }}>
                {`${item?.instruction || ""} ${
                  item?.isPrn ? "(When necessary)" : ""
                } ${item?.totalDosage || ""} ${item?.dosage || ""} ${
                  item?.periodDescription || ""
                } ${
                  item?.takePeriod && item?.takeType
                    ? `For ${item?.takePeriod} ${item?.takeType}`
                    : ""
                }`.trim("") ?? ""}
              </Text>
              <Text style={{ ...styles.row3, ...styles.textRight }}>
                {` ${item?.totalDosage || ""} ${item?.dosage || ""} ${
                  item?.periodDescription || ""
                }` ?? "-"}
              </Text>
              <Text style={{ ...styles.row2, ...styles.textRight }}>
                {`${item?.takePeriod ?? "-"} ${item?.takeType ?? ""}`}
              </Text>
              <Text style={{ ...styles.row4, ...styles.textRight }}>
                {item?.quantity ?? "-"}
              </Text>
              <Text style={{ ...styles.row4, ...styles.textRight }}>
                {item?.code ?? "-"}
              </Text>
            </View>
          ))}
        </View>

        <View style={{ ...styles.flex, marginTop: "60%" }}>
          <Text style={{ ...styles.text, ...styles.title }}>
            {userData?.username ?? ""}
          </Text>
        </View>
        <View style={{ ...styles.flex, marginTop: "2px" }}>
          <Text style={{ ...styles.text, ...styles.title }}>
            {moment(new Date()).format("DD MMM YYYY") ?? ""}
          </Text>
        </View>
      </Page>
    </Document>
  );
}
