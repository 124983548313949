import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  getAdditionalCost,
  updateAdditionalCost,
} from "../../../apis/additionalCost";
import { deleteKolOption, getKolOptions } from "../../../apis/optionKol";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customNumberSort,
  customStringSort,
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function AdditionalCost() {
  const navigate = useNavigate();
  const [additionalCost, setAdditionalCost] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getAdditionalCostList = async () => {
    const response = await getAdditionalCost();
    if (response?.status !== "Fail") {
      setAdditionalCost(response?.reverse());
    } else {
      if (response?.message === "No data Found") {
        setAdditionalCost([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removeAdditionalCost = async () => {
    setShow(false);
    let response = await updateAdditionalCost(deleting, {
      isDeleted: true,
    });
    if (response?.status !== "Fail") {
      getAdditionalCostList();
      toastify("Additional Cost deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editAdditionalCost = (e) => {
    navigate("/add-additional-marketing-cost", {
      state: {
        cost: e,
      },
    });
  };

  const addAdditionalCost = () => {
    navigate("/add-additional-marketing-cost");
  };

  useEffect(() => {
    getAdditionalCostList();
  }, []);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly1">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={additionalCost}
              setArray={setFilteredData}
              value="Name"
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Month",
      selector: (row) => row.month,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "month"),
    },
    {
      name: "Amount",
      selector: (row) => row.amount?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amount"),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "description"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? additionalCost : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        index: index,
        name: e.name,
        month: e.month,
        amount: e.amount,
        description: e.description,
        action: (
          <div className="d-flex">
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                editAdditionalCost(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Additional / Marketing Cost</h1>
          <Button className="addItem" onClick={addAdditionalCost}>
            + Add New
          </Button>
        </div>

        <DeleteModal
          content="Confirm Additional Cost Deletion?"
          onYes={removeAdditionalCost}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
