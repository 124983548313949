import api from "../axios/axios-config";

export const getTaxSettings = async () => {
  try {
    let response = await api.get("taxSetting/listOfTaxSetting");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addTaxSettings = async (data, patientId) => {
  try {
    let response = await api.post("taxSetting/createTaxSetting", {
      patientId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateTaxSettings = async (dataId, data) => {
  try {
    let response = await api.post("taxSetting/updateTaxSetting", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
