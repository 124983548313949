import api from "../axios/axios-config";

export const getDrawtool = async () => {
  try {
    let response = await api.get("drawTool/listOfDrawTools", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createDrawtool = async (data) => {
  try {
    let response = await api.post("drawTool/createDrawTool", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateDrawtool = async (data) => {
  try {
    let response = await api.post("drawTool/updateDrawTool", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
