import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getFilterAppointments } from "../../../apis/appointment";
import { toastify } from "../../../helperFunctions/toastify";
import { getFormatedTime } from "../../../helperFunctions/utils";

export default function TabAppointmentHistory() {
	const patientData = useSelector((state) => state.patientData.value);
	const [appointments, setAppointments] = useState([]);
	const [show, setShow] = useState(false);

	const navigate = useNavigate();

	const getSessionsList = async () => {
		const response = await getFilterAppointments(patientData?._id);
		if (response?.status !== "Fail") {
			setAppointments(response.reverse());
		} else {
			if (response?.message === "No data Found") {
				setAppointments([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	useEffect(() => {
		getSessionsList();
	}, []);

	const columns = [
		{
			name: "Visit Date",
			selector: (row) => new Date(row.visitDate).toLocaleDateString(),
			wrap: true,
			width: "180px",
		},
		{
			name: "Time",
			selector: (row) => row.time,
			wrap: true,
			width: "180px",
		},
		// {
		//     name: "Packages",
		//     selector: (row) => row.packages.name,
		//     wrap: true,
		//     width: "180px",
		// },
		// {
		// 	name: "Products",
		// 	selector: (row) => row.products.map((e) => <div>{e?.name}</div>),
		// 	wrap: true,
		// 	width: "180px",
		// },
		// {
		//     name: "Treatments",
		//     selector: (row) => row.treatments.map((e) => <div>{e?.name}</div>),
		//     wrap: true,
		//     width: "180px",
		// },
		{
			name: "Staff",
			selector: (row) => row.staff.map((e) => <div>{e?.username}</div>),
			width: "180px",
		},
		// {
		// 	name: "Total Charges",
		// 	selector: (row) => row.total,
		// 	width: "160px",
		// },
		{
			name: "Tag",
			selector: (row) => row.tagButtons,
			wrap: true,
			width: "160px",
		},
		{
			name: "Status",
			selector: (row) => row.status,
			width: "160px",
		},
		{
			name: "Remarks",
			selector: (row) => row.remarks,
			width: "160px",
		},
	];

	const data = appointments?.map((e, index) => {
		const colors = e?.tag?.map(x => x.color);
		return {
			index: index,
			id: e._id,
			visitDate: e?.date,
			time:
					(getFormatedTime(e?.startTime) ?? "") +
					"-" +
					(getFormatedTime(e?.endTime) ?? ""),
			// packages: e.package,
			products: e.products,
			// treatments: e.treatments,
			staff: e.staff,
			total: "N/A",
			remarks: e.remarks,
			tagButtons: (
				<div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
					{
						e?.tag.map((tag, index) => (
							<div
								key={index}
								className="rounded-button"
								style={{ width: 110, background: colors[index], marginBottom: "5px", color: "white" }}
							>
								{tag.name?.length > 9
									? tag.name.substring(0, 6) + "..."
									: tag.name}
							</div>
						))
					}
				</div>
			),
			status: (
				<div
					className={`${e.status == "Confirmed"
						? "green-rounded-button"
						: e.status == "Planned"
							? "brown-rounded-button"
							: "red-rounded-button"
						}`}
					style={{ width: 110 }}
				>
					{e.status}
				</div>
			)
		};
	});

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<div>
			{/* <div className="d-flex mb-3">
				<Form.Check
					label={
						<div className="unbold">Show Rescheduled by Clinic</div>
					}
					type={"checkbox"}
					onClick={(e) => setShow(e.target.checked)}
					checked={show}
				/>
			</div> */}
			<DataTable
				columns={columns}
				customStyles={customStyles}
				data={data || []}
				pagination
				highlightOnHover
				pointerOnHover
				conditionalRowStyles={conditionalRowStyles}
			/>
		</div>
	);
}
