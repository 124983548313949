import api from "../axios/axios-config";

export const getAdditionalCost = async () => {
  try {
    let response = await api.get("marketingCost/listOfMarketingCost");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addAdditionalCost = async (data) => {
  try {
    let response = await api.post("marketingCost/createMarketingCost", {
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateAdditionalCost = async (dataId, data) => {
  try {
    let response = await api.post("marketingCost/updateMarketingCost", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
