import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SelectComponent from "../../SharedComponents/SelectComponent";
import { getReferralReport } from "../../../apis/customTemplate";
import { getPatients } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import {
  getInvoiceId,
  formatDate,
  downloadCsv,
} from "../../../helperFunctions/utils";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import {
  loadOptionPatients,
  loadSelectedPatient2,
} from "../../../apis/patients";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import AsyncMultiSelect from "../../SharedComponents/AsyncMultiSelect";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function ReferralReport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

  const { tableSearch } = useSelector((state) => state.commonData);

  const [users, setUsers] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.referral?.startDate ? tableSearch[fieldPathname]?.referral?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.referral?.endDate ? tableSearch[fieldPathname]?.referral?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);
  const [selectedOptPatient, setSelectedOptPatient] = useState(tableSearch[fieldPathname]?.referral?.selectedOptPatient ? tableSearch[fieldPathname]?.referral?.selectedOptPatient : []);

  const onReduxFilter = (valueStartDate, valueEndDate, valuePatient) => {
    dispatch(
      setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          ...tableSearch[fieldPathname],
          referral: {
            startDate: valueStartDate,
            endDate: valueEndDate,
            selectedOptPatient: valuePatient,
          },
        },
      })
    );
  };

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.referral?.startDate ? tableSearch[fieldPathname]?.referral?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.referral?.endDate ? tableSearch[fieldPathname]?.referral?.endDate : new Date());
    setSelectedOptPatient(tableSearch[fieldPathname]?.referral?.selectedOptPatient ? tableSearch[fieldPathname]?.referral?.selectedOptPatient : []);

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.referral?.startDate || 
      tableSearch[fieldPathname]?.referral?.endDate ||
      tableSearch[fieldPathname]?.referral?.selectedOptPatient
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    getPatients().then((res) => {
      setUsers(res?.list);
    });
  }, []);

  useEffect(() => {
    getReferralReport({
      startDate: startDate,
      endDate: endDate,
      referral: selectedOptPatient.map((item) => item.value),
    }).then((res) => {
      console.log(res);
      if (res?.result?.data) {
        setFilteredData(res.result.data);
      }
    });
  }, [refetch]);

  const columns = [
    {
      name: "Recommend By",
      selector: (row) =>
        row.recommend?.patientProfile?.fullName ||
        row.patient?.patientProfile?.fullName,
    },
    {
      name: "Patient Name",
      selector: (row) => row.patient?.patientProfile?.fullName,
    },
    {
      name: "Invoice No",
      selector: (row) => getInvoiceId(row.invoiceNo),
    },
    {
      name: "Date",
      selector: (row) => formatDate(row.date),
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
    },
  ];

  const data = filteredData?.map((e, index) => {
    return {
      ...e,
      index,
      key: index,
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data?.map((row) => {
      return {
        "Recommend By":
          row.recommend?.patientProfile?.fullName ||
          row.patient?.patientProfile?.fullName,
        "Patient Name": row.patient?.patientProfile?.fullName,
        "Invoice No": getInvoiceId(row.invoiceNo),
        Date: formatDate(row.date),
        "Total Amount": row.totalAmount,
      };
    });

    downloadCsv(csvData, "Referral Report");
  };

  return (
    <div className="p-0">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "18px",
          }}
        >
          <FilterWrapper className="d-flex">
            <Form.Group className="me-3" style={{ width: "260px" }}>
              <Form.Label className="headings2" style={{ color: "#000" }}>
                Filter by Referral
              </Form.Label>
              <AsyncMultiSelect
                placeholder="Select"
                value={selectedOptPatient}
                setValue={(value) => {
                  setSelectedOptPatient(value);
                }}
                loadOptions={(search, loadedOptions, { page }) =>
                  loadOptionPatients(search, loadedOptions, { page })
                }
                loadSelectedOption={loadSelectedPatient2}
              />
              {/* <AsyncMultiSelect
                placeholder="Select"
                value={selectedOptPatient}
                setValue={(value) => {
                  setSelectedOptPatient(value);
                }}
                loadOptions={(search, loadedOptions, { page }) =>
                  loadOptionPatients(search, loadedOptions, { page })
                }
                loadSelectedOption={loadSelectedPatient2}
              /> */}
            </Form.Group>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(startDate, endDate, selectedOptPatient);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button className="addItem" onClick={() => exportCsv()}>
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default ReferralReport;
