import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { FormControlLabel, Checkbox } from "@mui/material";
import {
  addQuestionMultiple,
  getAllQuestions,
  updateQuestionMultiple,
  addQuestion,
  updateQuestion,
} from "../../../apis/questionnaire";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import SelectComponent from "../../SharedComponents/SelectComponent";
import IndicatorsComponent from "./IndicatorsComponent";
import OtherIndicators from "./OtherIndicators";
import { QuetionnaireLinkedTo } from "../../../constants";

import { CheckBoxWrapper, AutocompleteWrapper } from "./styled";

export default function AddEditQuestions() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(true);
  const [indicators, setIndicators] = useState([]);
  const [checkboxOptions, setCheckboxOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [question, setQuestion] = useState();
  const [size, setSize] = useState(12);
  const [orderIndex, setOrderIndex] = useState(1);
  const [group, setGroup] = useState("");
  const [groups, setGroups] = useState([]);
  const [type, setType] = useState([
    { name: "Yes / No", value: "Yes/No" },
    { name: "Text", value: "Text" },
    { name: "Checkbox", value: "Checkbox" },
    { name: "Multiple Indicators", value: "Multiple Indicators" },
  ]);
  const [linkedTo, setLinkedTo] = useState("");
  const [linkedToOptions, setLinkedToOptions] = useState(
    QuetionnaireLinkedTo ?? []
  );
  console.log("linkedTo...", QuetionnaireLinkedTo);

  const editQuestion = async () => {
    if (active === undefined) {
      missingPopup("Active key");
    } else if (!selectedType) {
      missingPopup("Type");
    } else if (!question) {
      missingPopup("Question");
    } else if (selectedType === "Checkbox" && checkboxOptions.length < 1) {
      toastify("Missing Checkbox Options!", "error");
    } else if (
      selectedType === "Multiple Indicators" &&
      indicators.length < 1
    ) {
      toastify("Missing Indicators!", "error");
    } else {
      if (selectedType !== "Text") {
        setLinkedTo("");
      }
      if (selectedType === "Multiple Indicators") {
        const res = await updateQuestionMultiple(
          state.id,
          question,
          selectedType,
          indicators,
          active,
          group,
          size,
          orderIndex,
          checkboxOptions
        );
        if (res.status !== "Fail") {
          toastify("Question updated successfully!", "success");
          navigate("/questionnaire-settings");
        } else {
          toastify(res.message, "error");
        }
      } else {
        const res = await updateQuestion(
          state.id,
          question,
          selectedType,
          active,
          linkedTo,
          group,
          size,
          orderIndex,
          checkboxOptions
        );
        if (res.status !== "Fail") {
          toastify("Question updated successfully!", "success");
          navigate("/questionnaire-settings");
        } else {
          toastify(res.message, "error");
        }
      }
    }
  };

  const saveQuestion = async () => {
    if (active === undefined) {
      missingPopup("Active key");
    } else if (!selectedType) {
      missingPopup("Type");
    } else if (!question) {
      missingPopup("Question");
    } else if (
      selectedType === "Multiple Indicators" &&
      indicators.length < 1
    ) {
      missingPopup("Indicators");
    } else {
      if (selectedType === "Multiple Indicators") {
        const res = await addQuestionMultiple(
          question,
          selectedType,
          indicators,
          active,
          group,
          size,
          orderIndex,
          checkboxOptions
        );
        if (res.status !== "Fail") {
          toastify("Question added successfully!", "success");
          navigate("/questionnaire-settings");
        } else {
          toastify(res.message, "error");
        }
      } else {
        if (selectedType !== "Text") {
          setLinkedTo("");
        }
        const res = await addQuestion(
          question,
          selectedType,
          active,
          linkedTo,
          group,
          size,
          orderIndex,
          checkboxOptions
        );
        if (res.status !== "Fail") {
          toastify("Question added successfully!", "success");
          navigate("/questionnaire-settings");
        } else {
          toastify(res.message, "error");
        }
      }
    }
  };

  const getQuestionDetails = async () => {
    const res = await getAllQuestions();
    let arrRes = [];
    const groups = res.data?.map((x) => ({
      label: x._id,
      value: x._id,
    }));
    res.data?.map((resQuestion) => {
      arrRes = arrRes.concat(resQuestion.data);
    });

    const response = arrRes.find((e) => e._id === state.id);
    setGroups(groups);
    setActive(response.isActive);
    setQuestion(response.question);
    setSize(response.size);
    setOrderIndex(response.orderIndex);
    setGroup(response.group);
    setLinkedTo(response?.linkedTo ?? "");
    setCheckboxOptions(response?.checkboxOptions);
    if (response.questionType === "Multiple Indicators") {
      setIndicators(response.indicators);
    }

    let _type = [...type];
    _type.forEach(function (item, i) {
      if (item.value === response.questionType) {
        _type.splice(i, 1);
        _type.unshift(item);
      }
    });
    setType(_type);

    if (_type[0].value === "Multiple Indicators") {
      setSelectedType("Multiple Indicators");
    } else if (_type[0].value === "Text") {
      setSelectedType("Text");
    } else if (_type[0].value === "Checkbox") {
      setSelectedType("Checkbox");
    } else {
      setSelectedType("Yes/No");
    }
  };

  const getQuestions = async () => {
    const response = await getAllQuestions();
    if (response?.status !== "Fail") {
      setGroups(
        response.data?.map((x) => ({
          label: x._id,
          value: x._id,
        }))
      );
    }
  };

  useEffect(() => {
    if (state?.id) {
      getQuestionDetails();
    } else {
      getQuestions();
    }
  }, []);

  useEffect(() => {
    if (!state?.id) {
      setIndicators([]);
    }
  }, [selectedType]);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.id ? "Edit " : "New "}`}
          Questionnaire Setting
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <Form.Group className="col-5 mt-3">
                  <Form.Label className="headings2 mt-3 d-flex">
                    Is Active
                  </Form.Label>
                  <div>
                    <Form.Check
                      inline
                      label="Yes"
                      type={"radio"}
                      onClick={() => setActive(true)}
                      checked={active === true}
                      bsPrefix={"custom-radio"}
                    />
                    <Form.Check
                      bsPrefix={"custom-radio"}
                      inline
                      label="No"
                      type={"radio"}
                      onClick={() => setActive(false)}
                      checked={active === false}
                    />
                  </div>
                </Form.Group>

                <div className="d-flex">
                  <Form.Group className="col-10 mb-3">
                    <Form.Label className="headings2 mt-3">Question *</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setQuestion(e.target.value)}
                        value={question}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 pe-2">
                    <Form.Label className="headings2">Question Type</Form.Label>
                    <SelectComponent
                      singleSelect
                      searchEnabled
                      selectedValue={selectedType}
                      options={[
                        ...type?.map((o) => {
                          return {
                            value: o.value,
                            label: o.name,
                          };
                        }),
                      ]}
                      onChange={(e) => {
                        setSelectedType(e.value);
                      }}
                    />
                  </Form.Group>

                  <Form.Group className="col-5 ps-2">
                    <Form.Label className="grayLabel">Linked to</Form.Label>
                    <SelectComponent
                      singleSelect
                      searchEnabled
                      selectedValue={linkedTo}
                      options={linkedToOptions}
                      onChange={(e) => {
                        setLinkedTo(e.value);
                      }}
                    />
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 pe-2">
                    <Form.Label className="headings2 mt-3">Group</Form.Label>
                    <AutocompleteWrapper>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        options={groups
                          .map((x) => x.label)
                          .filter((x) => x !== "")}
                        onChange={(e, newValue) => setGroup(newValue)}
                        value={group}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Write..."
                            InputProps={{
                              ...params.InputProps,
                              type: "search",
                            }}
                          />
                        )}
                      />
                    </AutocompleteWrapper>
                  </Form.Group>

                  <Form.Group className="col-5 ps-2">
                    <Form.Label className="headings2 mt-3">Size</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setSize(e.target.value)}
                        value={size}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 pe-2">
                    <Form.Label className="headings2 mt-3">
                      Order Index
                    </Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setOrderIndex(e.target.value)}
                        value={orderIndex}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
            </div>
          </div>
          {selectedType === "Multiple Indicators" && (
            <div className="grayBack">
              <Form.Group>
                <Form.Label className="grayLabel">Indicators</Form.Label>

                <IndicatorsComponent
                  indicators={indicators}
                  setIndicators={setIndicators}
                  type={selectedType}
                />
              </Form.Group>
            </div>
          )}

          {selectedType === "Checkbox" && (
            <div className="grayBack">
              <Form.Group>
                <Form.Label className="grayLabel">
                  Checkbox Options
                  <AddCircleIcon
                    style={{ marginLeft: "15px", cursor: "pointer" }}
                    onClick={() =>
                      setCheckboxOptions([
                        ...checkboxOptions,
                        { name: "", needRemark: false, isRequired: false },
                      ])
                    }
                  />
                </Form.Label>

                <CheckBoxWrapper>
                  {checkboxOptions?.map((option, index) => (
                    <li key={index} style={{ marginBottom: "10px" }}>
                      <input
                        style={{ width: "500px", height: "40px" }}
                        value={option.name}
                        onChange={(e) =>
                          setCheckboxOptions(() => {
                            const prevState = [...checkboxOptions];
                            prevState[index].name = e.target.value;

                            return prevState;
                          })
                        }
                      />

                      <FormControlLabel
                        onChange={() =>
                          setCheckboxOptions(() => {
                            const prevState = [...checkboxOptions];
                            prevState[index].needRemark =
                              !prevState[index].needRemark;

                            return prevState;
                          })
                        }
                        control={<Checkbox checked={option.needRemark} />}
                        label="Enable Remark"
                      />

                      <FormControlLabel
                        onChange={() =>
                          setCheckboxOptions(() => {
                            const prevState = [...checkboxOptions];
                            prevState[index].isRequired =
                              !prevState[index].isRequired;

                            return prevState;
                          })
                        }
                        control={<Checkbox checked={option.isRequired} />}
                        label="Is Required"
                      />

                      <DeleteForeverIcon
                        style={{ marginLeft: "25px", cursor: "pointer" }}
                        onClick={() => {
                          setCheckboxOptions(() => {
                            const prevState = [...checkboxOptions];
                            prevState.splice(index, 1);

                            return prevState;
                          });
                        }}
                      />
                    </li>
                  ))}
                </CheckBoxWrapper>
              </Form.Group>
            </div>
          )}

          {/* {(selectedType === "Yes/No" || selectedType === "Text") && (
            <div className="grayBack">
              <Form.Group>
                <Form.Label className="grayLabel">Indicators</Form.Label>

                <OtherIndicators
                  indicators={indicators}
                  setIndicators={setIndicators}
                  type={selectedType}
                />
              </Form.Group>
            </div>
          )} */}
          <div className="addEdit">
            <Button
              className="addItem"
              onClick={() => {
                if (state?.id) editQuestion();
                else saveQuestion();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
