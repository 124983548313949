import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  getReceivingGoods,
  updateReceivingGoods,
} from "../../../../apis/receivingGoods";
import { toastify } from "../../../../helperFunctions/toastify";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";
import DeleteModal from "../../../SharedComponents/DeleteModal";

import { migratePurchaseList } from "../../../../apis/purchaseList";
import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function ReceivingGoods() {
  const navigate = useNavigate();
  const [goods, setGoods] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [updating, setUpdating] = useState("");
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const handleClose2 = () => {
    setShow2(false);
    setUpdating("");
  };

  const populateData = async () => {
    const response = await getReceivingGoods();
    if (response?.status !== "Fail") {
      setGoods(response.filter((e) => !e.isDeleted).reverse());
      console.log({ response });
    } else {
      if (response?.message === "No data Found") {
        setGoods([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removeReceivingGood = async () => {
    setShow(false);
    let response = await updateReceivingGoods(deleting, {
      isDeleted: true,
    });
    if (response?.status !== "Fail") {
      populateData();
      toastify("Receiving Goods deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editPurchases = (e) => {
    navigate("/add-receiving-goods", {
      state: {
        goods: e,
      },
    });
  };

  const addReceivingGoods = () => {
    navigate("/add-receiving-goods");
  };

  useEffect(() => {
    populateData();
  }, []);

  const getTotalQty = (prod) => {
    let total = 0;
    prod.forEach((element) => {
      total += element.recivedQty;
    });
    return total;
  };

  const switchStatus = async () => {
    setShow2(false);
    let response = await updateReceivingGoods(updating, {
      isFinalized: true,
    });
    if (response?.status !== "Fail") {
      populateData();
      toastify("Switched Status successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const columns = [
    {
      name: "Received Date",
      selector: (row) =>
        new Date(row.receivedDate).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "receivedDate", true),
    },
    // {
    //   name: "Supplier Name",
    //   selector: (row) => row.name,
    //   wrap: true,
    //   minWidth: "160px",
    // },
    {
      name: "Doc No",
      selector: (row) => row.receivedDocNo,
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "receivedDocNo"),
    },
    {
      name: "Purchase Doc No",
      selector: (row) => row.purchaseDocNo,
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "purchaseDocNo"),
    },
    {
      name: "Received Qty",
      selector: (row) => row.receiveQty?.toLocaleString(),
      wrap: true,
      minWidth: "160px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "receiveQty"),
    },
    {
      name: "Status",
      selector: (row) => row.isFinalized,
      wrap: true,
      minWidth: "170px",
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "160px",
    },
  ].reverse();

  const data = (filteredData.length === 0 ? goods : filteredData)?.map(
    (e, index) => {
      return {
        index: index,
        id: e._id,
        receivedDate: e.receivedDate,
        receivedDocNo: e.receivedDocNo,
        purchaseDocNo: e.purchaseDocNo,
        receiveQty: getTotalQty(e.products),
        bonusQty: e.bonusQty || 0,
        invoiceGST: e.invoiceGST || 0,
        products: e.products,
        status: e.isFinalized,
        isFinalized:
          e.isFinalized === true ? (
            <div className="active-finalized">Finalized</div>
          ) : (
            <div
              className="inactive-finalized"
              onClick={() => {
                setUpdating(e._id);
                setShow2(true);
              }}
            >
              Draft
            </div>
          ),
        action: (
          <div className="d-flex">
            {
              e.isFinalized ? (
                <div
                  className="mx-3  cursor-pointer icon-width"
                  onClick={() => {
                    editPurchases(e);
                  }}
                >
                  <i className="fa fa-eye"></i>
                </div>
              ) : (
                <React.Fragment>
                  <div
                    className="mx-3  cursor-pointer icon-width"
                    onClick={() => {
                      editPurchases(e);
                    }}
                  >
                    <i className="fa fa-pen"></i>
                  </div>
                  <div
                    className="mx-3  cursor-pointer icon-width"
                    onClick={() => {
                      setDeleting(e._id);
                      setShow(true);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                </React.Fragment>
              )
            }
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    let formData = new FormData();
    formData.append("csvFile", selectedFile);
    migratePurchaseList(formData).then((res) => {
      populateData();
      toastify(res, "success");
    });
  };
  return (
    <>
      <div className="container p-0 rev-table">
        <div className="mainHeading px-4">
          <h1>Receiving Goods</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  return {
                    "Received Date": new Date(
                      e.receivedDate
                    ).toLocaleDateString(),
                    "Doc No.": e.receivedDocNo,
                    "Purchase Doc No.": e.purchaseDocNo,
                    "Received Quantity": e.receiveQty,
                    // "Bonus Quantity": e.bonusQty,
                    status: e.status ? "Finalized" : "Draft",
                  };
                });
                exportData(tempDataArr, "Receiving Goods", "Receiving_Goods");
              }}
            >
              Export
            </Button>
            <MuiButton
              className="addItem me-3"
              component="label"
              role={undefined}
              variant="contained"
              sx={{ backgroundColor: "#295381" }}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Import
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </MuiButton>
            <Button className="addItem" onClick={addReceivingGoods}>
              + Add New
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Receiving Goods Deletion?"
          onYes={removeReceivingGood}
          onNo={handleClose}
          show={show}
        />

        <DeleteModal
          content="Switch Status to Finalized?"
          onYes={switchStatus}
          onNo={handleClose2}
          show={show2}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
