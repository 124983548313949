import { combineReducers } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { HiTrash } from "react-icons/hi";
import { MdOutlineModeEditOutline } from "react-icons/md";

export default function OptionsToggle(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");

  function toggle() {
    setIsOpened((isOpened) => !isOpened);
  }

  // useEffect(() => {
  //   // if (props.indicators.length > 0) setOptions(props.indicators);
  // }, [props.indicators]);

  const addOption = () => {
    if (value && value !== "") {
      let arr = [...options];
      arr.push(value);
      setOptions(arr);
      setValue("");
      props.postData("add", value, props.header.indicator);
    }
  };

  const deleteOption = (opt) => {
    let arr = [...options];
    let _arr = arr.filter((e) => e !== opt);
    setOptions(_arr);
    props.postData("delete", opt, props.header.indicator);
  };

  const editOption = (opt) => {
    let arr = [...options];
    let _arr = arr.filter((e) => e !== opt);
    setOptions(_arr);
    setValue(opt);
    props.postData("delete", opt, props.header.indicator);
  };

  useEffect(() => {
    setOptions(props.header.options);
  }, [props.indicators]);

  return (
    <div className="box2 mx-5 my-3">
      <div className="d-flex justify-content-center flex-column">
        {/* <div className="d-flex">
          <div
            className={isOpened ? "boxTitleColor" : "boxTitle"}
            onClick={toggle}
          >
            <span></span>
          </div>
          <div className="checkBox">
            <div className="form-check">
              <label className="form-check-label m-0" for="flexCheckDefault">
                ABC
              </label>
            </div>
          </div>
        </div> */}
        {props.open && (
          <div>
            {options?.map((e) => (
              <div className="checkBoxChild">
                <div className="form-check p-0">
                  <div className="minus-icon">-</div>
                  <span>{e}</span>
                  <MdOutlineModeEditOutline
                    className="mx-2"
                    cursor={"pointer"}
                    onClick={() => editOption(e)}
                  />
                  <HiTrash
                    color="red"
                    cursor={"pointer"}
                    onClick={() => {
                      deleteOption(e);
                    }}
                  />
                </div>
              </div>
            ))}
            <div className="checkBoxChild">
              <div className="form-check p-0">
                <div className="plus-icon" onClick={addOption}>
                  +
                </div>
                <input
                  className="custom-input"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  type={"text"}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
