import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
	getCategoryCost,
	updateCategoryCost,
} from "../../../apis/categoryCost";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function CategoryCost() {
	const navigate = useNavigate();
	const [categoryCost, setCategoryCost] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getCategoryCostList = async () => {
		const response = await getCategoryCost();
		if (response.status !== "Fail") {
			setCategoryCost(response.reverse());
		} else {
			if (response?.message === "No data Found") {
				setCategoryCost([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const removeCategoryCost = async () => {
		setShow(false);

		let response = await updateCategoryCost(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			getCategoryCostList();
			toastify("Category Cost deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	const editCategoryCost = (e) => {
		navigate("/add-category-cost", {
			state: {
				categoryCost: e,
			},
		});
	};

	const addDepartment = () => {
		navigate("/add-category-cost");
	};

	useEffect(() => {
		getCategoryCostList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly3">
					<span className="me-2">Name</span>
					{
						<FilterComponent
							type="search"
							array={categoryCost}
							setArray={setFilteredData}
							value="Name"
						/>
					}
				</div>
			),
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Description",
			selector: (row) => row.description,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "description"),
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? categoryCost : filteredData)?.map(
		(e, index) => {
			return {
				index: index,
				id: e._id,
				name: e.name,
				description: e.description,
				action: (
					<div className="d-flex">
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								editCategoryCost(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Category Cost</h1>
					<Button className="addItem" onClick={addDepartment}>
						+ Add New
					</Button>
				</div>

				<DeleteModal
					content="Confirm Category Cost Deletion?"
					onYes={removeCategoryCost}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}
