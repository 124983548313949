import { authorizedRequests } from "../axios/axios-config";

const getPaymentMethodList = async () => {
	const response = await authorizedRequests({
		url: "paymentMethod/listPaymentMethod",
		method: "get",
	});
	return response;
};

const create = async (data) => {
	const response = await authorizedRequests({
		url: "paymentMethod/createPaymentMethod",
		method: "post",
		data,
	});
	return response;
};

const update = async (data) => {
	const response = await authorizedRequests({
		url: "paymentMethod/updatePaymentMethod",
		method: "post",
		data,
	});
	return response;
};

const getDataById = async (id) => {
	const response = await authorizedRequests({
		url: "paymentMethod/getPaymentMethodById",
		method: "post",
		data: {
			id: id,
		},
	});
	return response;
};

export { getPaymentMethodList, create, update, getDataById };
