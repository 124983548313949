import styled from "styled-components";

export const SignWrapper = styled.div`
  width: 300px;
  background: #F8F8F8;
  border-radius: 12px;

  p {
    margin-bottom: 0px;
  }

  .header-sign {
    border-bottom: 2px solid #E9E9E9;
    font-size: 16px;
    font-weight: 600;
  }
`;