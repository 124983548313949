import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { adjustDateFormat } from "../../helperFunctions/utils";

const initialState = {
	reminderTemplateForm: {
		name: "",
		message: "",
		via: "whatsapp",
		viaDetail: "phone_number",
		formStatus: "New",
		contactNumbers: [],
		dataId: null,
	},
	externalDrugForm: {
		template: "",
		width: 400,
		height: 100
	},
	allReminderTemplates: [],
};

export const adminSlice = createSlice({
	name: "templateSlice",
	initialState,
	reducers: {
		setForm: (state, { payload }) => {
			state[payload.parent] = {
				...state[payload.parent],
				[payload.child]: payload.value,
			};
		},
		setData: (state, { payload }) => {
			state[payload.parent] = payload.value;
		},
		resetValue: (state, { payload }) => {
			state[payload] = initialState[payload];
		},
	},
});

export const { setForm, setData, resetValue } = adminSlice.actions;

export default adminSlice.reducer;
