import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toastify } from "../../../helperFunctions/toastify";
import { authorizedRequests } from "../../../axios/axios-config";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import activeReportIcon from "../../../assets/images/reportIcons/activeReportIcon.png";
import inactiveReportIcon from "../../../assets/images/reportIcons/inactiveReportIcon.png";

import CustomizedPreviewTemplate from "./CustomizedPreviewTemplate";
import HumanResource from "../HumanResource";
import User from "./User";
import PaymentReport from "./PaymentReport";
import DrugMovementReport from "./DrugMovementReport";
import TopSpenderReport from "./TopSpenderReport";
import SalesReportByStaff from "./SalesReportByStaff";
import ReferralReport from "./ReferralReport";
import SalesReport from "./SalesReport";
import PackageExpiryReport from "./PackageExpiryReport";

export default function CategoryReportsView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [reportsStates, setReportsStates] = useState([]);
  const [currentState, setCurrentState] = useState();
  const [selectedReport, setSelectedReport] = useState();

  const onReduxSelectReport = (value, valueName) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
				currentState: valueName,
        selectedReport: value
			}
		}))
  }

  const getAllTemplates = async () => {
    try {
      const { result, error } = await authorizedRequests({
        url: "collections/templates",
        method: "get",
      });
      if (error) {
        toastify(error.message ?? "Unknown error occured", "error");
        return;
      }
      setReportsStates(result.data);
      // setCurrentState(result.data[0]?.name);
      // console.log("report templates list", result.data);
    } catch (error) {
      toastify(error.message ?? "Unknown error occured", "error");
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  useEffect(() => {
    setCurrentState(tableSearch[fieldPathname]?.currentState ? tableSearch[fieldPathname]?.currentState : null);
    setSelectedReport(tableSearch[fieldPathname]?.selectedReport ? tableSearch[fieldPathname]?.selectedReport : null);
  }, [id]);

  const [categoriesList, setCategoriesList] = useState([]);
  // console.log("Category Report List....", categoriesList);

  const getCategoriesList = async () => {
    const { result, error } = await authorizedRequests({
      url: "category/listOfCategory",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    if (result) {
      setCategoriesList(result?.data);
    } else {
      if (!result?.status === "Success") {
        setCategoriesList([]);
      } else {
        toastify(result?.message, "error");
      }
    }

    return;
  };

  // Filter the second array based on matching name and category
  // const filteredData = reportsStates?.filter((secondItem) => {
  //   return categoriesList?.some(
  //     (firstItem) => firstItem?.name === secondItem?.category
  //   );
  // });

  const hrMenuList = [
    {
      name: "Time In & Out",
      columns: [],
      category: "HR",
    },
    {
      name: "Staff Details",
      columns: [],
      category: "HR",
    },
  ];
  const financeMenuList = [
    {
      name: "Sales Report",
      columns: [],
      category: "Finance",
    },
    {
      name: "Payment Method Report",
      columns: [],
      category: "Finance",
    },
    {
      name: "Referral Report",
      columns: [],
      category: "Finance",
    },
    {
      name: "Sales Report by Staff",
      columns: [],
      category: "Finance",
    },
    {
      name: "Top Spender Report",
      columns: [],
      category: "Finance",
    },
    {
      name: "Package Expiry Report",
      columns: [],
      category: "Finance",
    },
    // {
    //   name: "Revenue Report",
    //   columns: [],
    //   category: "Finance",
    // },
  ];
  const inventoryList = [
    {
      name: "Drug Movement Report",
      columns: [],
      category: "Inventory",
    },
  ];

  // Filter the objects
  const filteredArray = reportsStates?.filter((item) => item?.category === id);

  // useEffect(() => {
  //   if (filteredArray.length && currentState === "") {
  //     setCurrentState(filteredArray[0].name);
  //     setSelectedReport(filteredArray[0]);
  //   }
  // }, [filteredArray]);

  useEffect(() => {
    getCategoriesList();
  }, []);

  return (
    <>
      <div className="px-4 py-2">
        <div className="mainHeading">
          <h1>{id}</h1>
        </div>
      </div>

      <div
        className="search-container d-flex px-4"
        style={{ minHeight: "100vh" }}
      >
        {id === "HR" || id === "Human Resource" ? (
          <div className="col-9">
            {selectedReport?.name === "Staff Details" ? (
              <User hideTitle={true} />
            ) : (
              <HumanResource hideTitle={true} />
            )}
          </div>
        ) : selectedReport?.name === "Drug Movement Report" ? (
          <div className="col-9">
            <DrugMovementReport />
          </div>
        ) : selectedReport?.name === "Top Spender Report" ? (
          <div className="col-9">
            <TopSpenderReport />
          </div>
        ) : selectedReport?.name === "Sales Report by Staff" ? (
          <div className="col-9">
            <SalesReportByStaff />
          </div>
        ) : selectedReport?.name === "Referral Report" ? (
          <div className="col-9">
            <ReferralReport />
          </div>
        ) : selectedReport?.name === "Sales Report" ? (
          <div className="col-9">
            <SalesReport />
          </div>
        ) : selectedReport?.name === "Package Expiry Report" ? (
          <div className="col-9">
            <PackageExpiryReport />
          </div>
        ) : selectedReport?.name === "Payment Method Report" ? (
          <div className="col-9">
            <PaymentReport />
          </div>
        ) : (
          <div className="col-9">
            {filteredArray?.length ? (
              <CustomizedPreviewTemplate id={selectedReport?._id} />
            ) : (
              <div
                style={{
                  height: "100vh",
                  width: "100%",
                  display: "flex",
                  alignItems: "start",
                  justifyContent: "center",
                }}
              >
                <h6
                  style={{
                    padding: "20px",
                    background: "#fff",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  There are no record to be display
                </h6>
              </div>
            )}
          </div>
        )}
        <div
          className="ps-3 col-3 flex-stretch"
          style={{ height: "max-content" }}
        >
          <div className="white-bg h-100">
            <div
              className="font-400-20 py-3 px-2 "
              style={{ borderBottom: "1px solid #E9E9E9" }}
            >
              <b>Report List</b>
            </div>
            {id === "HR" || id === "Human Resource" ? (
              <>
                {hrMenuList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      currentState === item.name
                        ? "activeReport"
                        : "InactiveReport"
                    } font-400-16 px-2 py-3 pointer d-flex`}
                    onClick={() => {
                      setCurrentState(item.name);
                      onReduxSelectReport(item, item.name);
                      setSelectedReport(item);
                    }}
                  >
                    <img
                      alt="icon-report"
                      src={
                        currentState === item.name
                          ? activeReportIcon
                          : inactiveReportIcon
                      }
                    />
                    <div className="ms-2 align-self-center">{item.name}</div>
                  </div>
                ))}
              </>
            ) : id === "Finance" ? (
              <>
                {financeMenuList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      currentState === item.name
                        ? "activeReport"
                        : "InactiveReport"
                    } font-400-16 px-2 py-3 pointer d-flex`}
                    onClick={() => {
                      setCurrentState(item.name);
                      onReduxSelectReport(item, item.name);
                      setSelectedReport(item);
                    }}
                  >
                    <img
                      alt="icon-report"
                      src={
                        currentState === item.name
                          ? activeReportIcon
                          : inactiveReportIcon
                      }
                    />
                    <div className="ms-2 align-self-center">{item.name}</div>
                  </div>
                ))}
              </>
            ) : id === "Inventory" ? (
              <>
                {inventoryList?.map((item, index) => (
                  <div
                    key={index}
                    className={`${
                      currentState === item.name
                        ? "activeReport"
                        : "InactiveReport"
                    } font-400-16 px-2 py-3 pointer d-flex`}
                    onClick={() => {
                      setCurrentState(item.name);
                      onReduxSelectReport(item, item.name);
                      setSelectedReport(item);
                    }}
                  >
                    <img
                      alt="icon-report"
                      src={
                        currentState === item.name
                          ? activeReportIcon
                          : inactiveReportIcon
                      }
                    />
                    <div className="ms-2 align-self-center">{item.name}</div>
                  </div>
                ))}
              </>
            ) : null}
            {filteredArray?.map((item, index) => (
              <div
                key={index}
                className={`${
                  currentState === item.name ? "activeReport" : "InactiveReport"
                } font-400-16 px-2 py-3 pointer d-flex`}
                onClick={() => {
                  setCurrentState(item.name);
                  onReduxSelectReport(item, item.name);
                  setSelectedReport(item);
                }}
              >
                <img
                  alt="icon-report"
                  src={
                    currentState === item.name
                      ? activeReportIcon
                      : inactiveReportIcon
                  }
                />
                <div className="ms-2 align-self-center">{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
