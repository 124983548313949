import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SearchIcon } from "../../../../assets/images/index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTable from "./CustomTable";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { getPurchaseList } from "../../../../apis/purchaseList";
import { toastify } from "../../../../helperFunctions/toastify";
import {
  addReceivingGoods,
  updateReceivingGoods,
  getReceivingGoods,
} from "../../../../apis/receivingGoods";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import SelectComponent from "../../../SharedComponents/SelectComponent";
import { setFormData } from "../../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditReceivingGoods() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;
  const navigate = useNavigate();

  const [totalCost, setTotalCost] = useState("");
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [purchaseList, setPurchaseList] = useState([]);
  const [rDocNo, setRDocNo] = useState("");
  const [receiveDate, setReceiveDate] = useState("");
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [itemNameShow, setItemNameShow] = useState(false);
  const [filteredItemName, setFilteredItemName] = useState([]);
  const [searchPurchase, setSearchPurchase] = useState("");
  const [purchaseShow, setPurchaseShow] = useState(false);
  const [filteredPurchase, setFilteredPurchase] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [typeShow, setTypeShow] = useState(false);
  const [filteredType, setFilteredType] = useState([]);
  const [location, setLocation] = useState("");
  const [rQty, setRQty] = useState();
  const [batchNo, setBatchNo] = useState("");
  const [expiry, setExpiry] = useState();
  const [invoiceGst, setInvoiceGst] = useState(0);
  const [gstCost, setGstCost] = useState(0);
  const [gstCostInput, setGstCostInput] = useState(gstCost.toString());
  const [invoiceTotal, setInvoiceTotal] = useState("");
  const [selectedPL, setSelectedPL] = useState();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [bonus, setBonus] = useState("");
  const [editingProduct, setEditingProduct] = useState("");
  const [applyTaxDetail, setApplyTaxDetail] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const isDisabled = state?.goods?.isFinalized;

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  const numberRegex = new RegExp(`^[0-9]*$`);
  const percentRegex = new RegExp(
    `(0*100{1,1}\\.?((?<=\\.)0*)??$)|(^0*\\d{0,2}\\.?((?<=\\.)\\d*)??)$`
  );

  useEffect(() => {
    if (!state?.goods?._id) {
      formDataChangedRef.current = {
        receivedDocNo: rDocNo,
        purchaseDocNo: selectedPL?.docNumber,
        status: status,
        receivedDate: receiveDate,
        totalCost,
        invoiceGST: invoiceGst,
        invoiceTotal: invoiceTotal,
        isFinalized: false,
        products: products,
        remarks,
      }
    }
  }, [rDocNo, selectedPL, status, receiveDate, totalCost, invoiceGst, invoiceTotal,
    products, remarks
  ]);

  useEffect(() => {
    return () => {
      if (!state?.goods?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.goods?._id) {
      populateData();
    }
  }, []);

  const populateData = async () => {
    const reduxData = formData?.[pathname];
    if (reduxData && !state?.goods?._id) {
      const response = await getPurchaseList({isRecived: reduxData?.isFinalized ? true : false});
  
      let _purchaseList = response.find(
        (e) => e.docNumber === reduxData?.purchaseDocNo
      );
  
      setSelectedPL(_purchaseList);
      setAllProducts(_purchaseList?.products);
      setSearchPurchase(_purchaseList?.name);
      setReceiveDate(reduxData?.receivedDate);
      setRDocNo(reduxData?.receivedDocNo);
      setStatus(reduxData?.status);
      setRemarks(reduxData?.remarks);
      setInvoiceGst(reduxData?.invoiceGST || 0);
      setApplyTaxDetail(_purchaseList?.applyTax);
      setBatchNo(reduxData?.batchNo);
      setProducts(reduxData?.products);
    } else if (state.goods?._id) {
      const response = await getPurchaseList({isRecived: state?.goods?.isFinalized ? true : false});
  
      let _purchaseList = response.find(
        (e) => e.docNumber === state?.goods?.purchaseDocNo
      );
  
      setSelectedPL(_purchaseList);
      setAllProducts(_purchaseList?.products);
      setSearchPurchase(_purchaseList?.name);
      setReceiveDate(state?.goods?.receivedDate);
      setRDocNo(state?.goods?.receivedDocNo);
      setStatus(state?.goods?.status ? "Active" : "Not Active");
      setRemarks(state?.goods?.remarks);
      setInvoiceGst(state?.goods?.invoiceGST || 0);
      // setInvoiceGst(_purchaseList?.applyTax?.value || 0);
      setApplyTaxDetail(_purchaseList?.applyTax);
      setBatchNo(state?.goods?.batchNo);
  
      let _all = [...state?.goods?.products].map((e) => {
        const selectedProduct = _purchaseList?.products?.find(
          (x) => x.product._id === e.product
        );
  
        return {
          recivedQty: e.recivedQty,
          batchNo: e.batchNo,
          expiryDate: e.expiryDate,
          unitCost: _purchaseList?.products?.find(
            (f) => f.product._id === e.product
          ).unitCost,
          product: _purchaseList?.products?.find((f) => f.product._id === e.product)
            .product,
          productName: selectedProduct?.product?.name,
          location: e.location,
          qty: e.recivedQty,
          bonusQty: e.bonusQty,
        };
      });
      setProducts(_all);
    }
  };


  const populateOptions = async () => {
    const response = await getPurchaseList({isRecived: false});
    setPurchaseList(response);
  };

  const saveGoods = async (isFinalized) => {
    if (!selectedPL) {
      missingPopup("Purchase Doc No");
    } else if (!receiveDate) {
      missingPopup("Receive Date");
    } else if (!rDocNo) {
      missingPopup("Received Doc Number");
    } else if (!status) {
      missingPopup("Status");
    } else if (products.length === 0) {
      missingPopup("Items");
    } else if (
      invoiceGst === "" ||
      invoiceGst === undefined ||
      invoiceGst === null
    ) {
      missingPopup("Invoice GST");
    } else {
      let _products = [...products].map((e) => {
        return {
          ...e,
          product: e.product._id,
          // bonusQty: parseInt(e.bonusQty),
        };
      });
      let data = {
        receivedDocNo: rDocNo,
        purchaseDocNo: selectedPL?.docNumber,
        status: status === "Active" ? true : false,
        receivedDate: receiveDate,
        totalCost,
        invoiceGST: invoiceGst,
        invoiceTotal: invoiceTotal,
        isFinalized,
        products: _products,
        remarks,
      };
      if (!state?.goods) {
        const response = await addReceivingGoods(data);
        if (response?.status !== "Fail") {
          formDataChangedRef.current = null;
          dispatch(setFormData({
            ...formData,
            [pathname]: null
          }));
          
          toastify("Receiving Goods added successfully!", "success");
          navigate("/receiving-goods");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateReceivingGoods(state?.goods?._id, data);
        if (response?.status !== "Fail") {
          toastify("Receiving Goods updated successfully!", "success");
          navigate("/receiving-goods");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const editProduct = (id, index) => {
    // let _products = [...products].filter((e) => e.product._id !== id);
    // setProducts(_products);
    let _p = [...products].find((e, i) => e.product._id === id);
    setSelectedProduct(_p);
    setShowAdd(true);
  };

  const addProduct = () => {
    if (!selectedProduct) missingPopup("Item");
    else if (!rQty) missingPopup("Received Quantity");
    else if (!expiry) missingPopup("Expiry Date");
    else {
      let f = products.findIndex(
        (e) => e.product._id === selectedProduct.product._id
      );

      if (f === -1 || editingProduct !== "") {
        let data = [...products];
        if (editingProduct !== "") {
          data = [...data].filter((_e, i) => i !== editingProduct);
        }
        data.push({
          recivedQty: rQty,
          batchNo,
          expiryDate: expiry,
          unitCost: selectedProduct.unitCost,
          product: selectedProduct.product,
          productName: selectedProduct.product?.name,
          location,
          qty: selectedProduct.qty,
          bonusQty: bonus,
        });
        setProducts(data);
        setSelectedProduct();
        setSearchItem("");
        setSearchType("");
        setShowAdd(false);
        setLocation("");
        setRQty("");
        setBonus("");
        setBatchNo("");
        setExpiry("");
      } else {
        toastify("This product is already in the list.", "error");
      }
    }
  };

  const deleteProduct = (id) => {
    let _products = [...products].filter((e) => e.product._id !== id);
    setProducts(_products);
  };

  useEffect(() => {
    if (state?.goods) populateData();
    populateOptions();
  }, []);

  useEffect(() => {
    if (allProducts) {
      setFilteredItemName(
        allProducts.filter((e) =>
          e.product.name.toLowerCase().includes(searchItem.toLowerCase())
        )
      );
    }
  }, [searchItem]);

  useEffect(() => {
    if (allProducts) {
      setFilteredType(
        allProducts.filter((e) =>
          e.product.type.name.toLowerCase().includes(searchType.toLowerCase())
        )
      );
    }
  }, [searchType]);

  useEffect(() => {
    if (purchaseList) {
      setFilteredPurchase(
        purchaseList.filter((e) =>
          e.name.toLowerCase().includes(searchPurchase.toLowerCase())
        )
      );
    }
  }, [searchPurchase]);

  useEffect(() => {
    if (selectedProduct) {
      setSearchItem(selectedProduct?.product?.name);
      setSearchType(selectedProduct?.product?.type?.name);
      if (selectedProduct.recivedQty) setRQty(selectedProduct.recivedQty);
      if (selectedProduct.batchNo) setBatchNo(selectedProduct.batchNo);
      if (selectedProduct.expiryDate) setExpiry(selectedProduct.expiryDate);
      if (selectedProduct.location) setLocation(selectedProduct.location);
      if (selectedProduct.bonusQty) setBonus(selectedProduct.bonusQty);
    }
  }, [selectedProduct]);

  const getPurchases = async () => {
    const response = await getReceivingGoods();
    return response;
  };

  // useEffect(() => {
  //   if (selectedPL) {
  //     const response = getPurchases();
  //     console.log({ 1: response, 2: selectedPL });
  //     setAllProducts(selectedPL?.products);
  //     setSelectedProduct();
  //     setSearchItem("");
  //     setSearchType("");
  //     setRQty("");
  //     setBatchNo("");
  //     setExpiry("");
  //     // setLocation("");
  //     // setProducts([]);
  //     let a = selectedPL?.products?.map((e) => {
  //       return {
  //         recivedQty: e.qty,
  //         qty: e.qty,
  //         unitCost: e.unitCost,
  //         location: e.product.location,
  //         product: e.product,
  //         batchNo: e.product.batchNumber,
  //         expiryDate: new Date(),
  //         bonusQty: e.bonusQty,
  //       };
  //     });
  //     console.log({ products: a });
  //     setProducts(a);
  //     setShowAdd(false);
  //     //  {
  //     //     recivedQty: rQty,
  //     //     expiryDate: expiry,
  //     //     batchNo,
  //     //     product: selectedProduct.product,
  //     //     location,
  //     //     unitCost: selectedProduct.unitCost,
  //     //     qty: selectedProduct.qty,
  //     //   }
  //   }
  // }, [selectedPL]);

  const updatePLdata = async (PL) => {
    // const response = await getPurchases();
    // console.log({ 1: response, 2: selectedPL });
    setAllProducts(PL?.products);
    setSelectedProduct();
    setSearchItem("");
    setSearchType("");
    setRQty("");
    setBatchNo("");
    setExpiry("");
    // setLocation("");
    // setProducts([]);
    let a = PL?.products?.map((e) => {
      return {
        recivedQty: e.qty,
        qty: e.qty,
        unitCost: e.unitCost,
        location: e.product.location,
        product: e.product,
        productName: e.product.name,
        batchNo: e.product.batchNumber,
        expiryDate: new Date(),
        bonusQty: e.bonusQty,
      };
    });
    setProducts(a);
    setShowAdd(false);
  };

  // useEffect(() => {
  //   if (products.length > 0) {
  //     let total = 0;
  //     products.forEach((e) => (total += Number(e.recivedQty) * e.unitCost));
  //     setTotalCost(total);
  //     setInvoiceTotal(total + (total * Number(invoiceGst)) / 100);
  //   } else {
  //     setTotalCost(0);
  //     setInvoiceTotal(0);
  //   }
  // }, [products]);

  // useEffect(() => {
  //   let total = 0;
  //   products.forEach((e) => (total += Number(e.recivedQty) * e.unitCost));
  //   setInvoiceTotal(total + (total * Number(invoiceGst)) / 100);
  // }, [invoiceGst]);

  useEffect(() => {
    if (searchPurchase && isSearch) {
      const purchase = purchaseList.find(
        (pl) => pl.name.toLowerCase() === searchPurchase.toLowerCase()
      );
      if (purchase) {
        setInvoiceGst(purchase.applyTax?.value || 0);
        setApplyTaxDetail(purchase?.applyTax);
      } else {
        setInvoiceGst(0); // Reset to 0 if no purchase is found
      }
    }
  }, [searchPurchase, purchaseList, isSearch]);

  useEffect(() => {
    if (products.length > 0) {
      let total = 0;
      products.forEach((e) => (total += Number(e.recivedQty) * e.unitCost));
      setTotalCost(total);
      const gstCost = (total * Number(invoiceGst)) / 100;
      setGstCost(gstCost);
      setGstCostInput(gstCost.toFixed(3));
      setInvoiceTotal(total + gstCost);
    } else {
      setTotalCost(0);
      setGstCost(0);
      setInvoiceTotal(0);
    }
  }, [products]);
  
  useEffect(() => {
    let total = 0;
    products.forEach((e) => (total += Number(e.recivedQty) * e.unitCost));
    let gstCost = 0;
    if (applyTaxDetail) {
      if (applyTaxDetail.typeValue === "Percentage") {
        gstCost = (total * Number(invoiceGst)) / 100;
      } else {
        gstCost = Number(invoiceGst);
      }
    }

    setGstCost(gstCost);
    setGstCostInput(gstCost.toFixed(3));
    setInvoiceTotal(total + gstCost);
  }, [invoiceGst, applyTaxDetail]);

  const handleClickAway1 = () => {
    setShow1(false);
  };

  const handleClickAway2 = () => {
    setShow2(false);
  };

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.goods?._id,
      type: "Goods",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.goods ? "Edit " : "New "}`}
            Receiving Goods
          </h1>

          {state?.goods && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex">
                <Form.Group className="position-relative col-5">
                  <Form.Label className="headings2">
                    Purchase Doc No *
                  </Form.Label>
                  <InputGroup
                    className={`mb-3 p-0 search-group ${isDisabled && "disabled-input"}`}
                    onClick={() => {
                      setIsSearch(true);
                      setPurchaseShow(!purchaseShow);
                      if (!purchaseShow) {
                        setTypeShow(false);
                        setItemNameShow(false);
                      }
                    }}
                  >
                    <Form.Control
                      placeholder="Select"
                      value={searchPurchase}
                      disabled={isDisabled}
                      onChange={(e) => setSearchPurchase(e.target.value)}
                    />
                    <Button>
                      <img src={SearchIcon} />
                    </Button>
                  </InputGroup>

                  {purchaseShow && (
                    <div className="searchbox-packages">
                      {(filteredPurchase.length > 0
                        ? filteredPurchase
                        : purchaseList
                      ).map((e) => (
                        <div
                          onClick={() => {
                            setSearchPurchase(e.name);
                            setPurchaseShow(false);
                            setSelectedPL(e);
                            updatePLdata(e);
                          }}
                        >
                          {e.name}
                        </div>
                      ))}
                    </div>
                  )}
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-6">
                  <ClickAwayListener onClickAway={handleClickAway1}>
                    <div className="col-12">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={receiveDate}
                          onChange={(newValue) => {
                            setReceiveDate(newValue?.$d);
                            setShow1(false);
                          }}
                          componentsProps={{
                            actionBar: { actions: ["today"] },
                          }}
                          disabled={isDisabled}
                          open={show1}
                          // maxDate={new Date()}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Form.Group className={`col-10`}>
                              <Form.Label className="headings2">
                                Received Date *
                              </Form.Label>
                              <InputGroup className={`mb-3 d-flex pe-3 align-items-center ${isDisabled && "disabled-input"}`}>
                                <Form.Control
                                  style={{backgroundColor: isDisabled ? "#f2f2f2" : "inherit"}}
                                  ref={inputRef}
                                  // {...inputProps}
                                  value={
                                    receiveDate
                                      ? new Date(
                                          receiveDate
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "numeric",
                                          year: "numeric",
                                        })
                                      : null
                                  }
                                  onClick={() => setShow1(!show1)}
                                  readOnly
                                  placeholder="Select Date"
                                />
                                <div onClick={() => setShow1(!show1)}>
                                  {InputProps?.endAdornment}
                                </div>
                              </InputGroup>
                            </Form.Group>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">
                    Received Doc No *
                  </Form.Label>
                  <InputGroup className={`mb-3 ${isDisabled && "disabled-input"}`}>
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setRDocNo(e.target.value)}
                      value={rDocNo}
                      disabled={isDisabled}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Status *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={status}
                    options={[
                      {
                        label: "Active",
                        value: "Active",
                      },
                      {
                        label: "Not Active",
                        value: "Not Active",
                      },
                    ]}
                    onChange={(e) => {
                      setStatus(e.value);
                    }}
                    isDisabled={isDisabled}
                  />
                  {/* <select
                    className="form-select mb-3"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value="Active" selected={status === "Active"}>
                      Active
                    </option>
                    <option
                      value="Not Active"
                      selected={status === "Not Active"}
                    >
                      Not Active
                    </option>
                  </select> */}
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Remarks</Form.Label>
                  <InputGroup className={`${isDisabled && "disabled-input"}`}>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                      className="large-text-area"
                      disabled={isDisabled}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />
              </div>

              {/* <div className="ms-1 mt-3">
                <button
                  className="round-button"
                  onClick={() => {
                    if (selectedPL) {
                      setShowAdd(!showAdd);
                      setItemNameShow(false);
                      setTypeShow(false);
                    } else {
                      toastify("Please Select Purchase Doc No");
                    }
                  }}
                >
                  +Add Item
                </button>
              </div> */}

              {showAdd && (
                <div className="d-flex my-3 px-3 py-3 prod-box">
                  <div className="col-5">
                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Item Name *</Form.Label>
                      <InputGroup
                        className="mb-3 p-0 search-group"
                        onClick={() => {
                          setItemNameShow(!itemNameShow);
                          if (!itemNameShow) {
                            setTypeShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchItem}
                          onChange={(e) => setSearchItem(e.target.value)}
                        />
                        <Button>
                          <img src={SearchIcon} />
                        </Button>
                      </InputGroup>

                      {itemNameShow && (
                        <div className="searchbox-packages">
                          {(filteredItemName.length > 0
                            ? filteredItemName
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchItem(e.product.name);
                                setItemNameShow(false);
                                // setSelectedProduct(e);
                                setSelectedProduct({
                                  recivedQty: rQty,
                                  batchNo,
                                  qty: e.qty,
                                  expiryDate: expiry,
                                  unitCost: e.unitCost,
                                  product: e.product,
                                  productName: e.product.name,
                                  location,
                                  bonusQty: e.bonusQty,
                                });
                              }}
                            >
                              {e.product.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Item Type *</Form.Label>
                      <InputGroup
                        className="mb-3 p-0 search-group"
                        onClick={() => {
                          setTypeShow(!typeShow);
                          if (!typeShow) {
                            setItemNameShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchType}
                          onChange={(e) => setSearchType(e.target.value)}
                        />
                        <Button>
                          <img src={SearchIcon} />
                        </Button>
                      </InputGroup>
                      {typeShow && (
                        <div className="searchbox-packages">
                          {(filteredType.length > 0
                            ? filteredType
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchType(e.product.type.name);
                                setTypeShow(false);
                                // setSelectedProduct(e);
                                setSelectedProduct({
                                  recivedQty: rQty,
                                  batchNo,
                                  qty: e.qty,
                                  expiryDate: expiry,
                                  unitCost: e.unitCost,
                                  product: e.product,
                                  productName: e.product.name,
                                  location,
                                  bonusQty: e.bonusQty,
                                });
                              }}
                            >
                              {e.product.type.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Received Bonus
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          onChange={(e) => {
                            if (
                              numberRegex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setBonus(e.target.value);
                            }
                          }}
                          value={bonus}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Received Qty *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          value={rQty}
                          onChange={(e) => {
                            if (
                              numberRegex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setRQty(e.target.value);
                            }
                          }}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Batch No
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          onChange={(e) => setBatchNo(e.target.value)}
                          value={batchNo}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group className="">
                      <ClickAwayListener onClickAway={handleClickAway2}>
                        <div className="col-12">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              value={expiry}
                              onChange={(newValue) => {
                                setExpiry(newValue?.$d);
                                setShow2(false);
                              }}
                              open={show2}
                              // minDate={new Date()}
                              renderInput={({
                                inputRef,
                                inputProps,
                                InputProps,
                              }) => (
                                <Form.Group className=" mt-3">
                                  <Form.Label className="headings2">
                                    Expiry Date *
                                  </Form.Label>
                                  <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                    <Form.Control
                                      ref={inputRef}
                                      // {...inputProps}
                                      value={
                                        expiry
                                          ? new Date(expiry).toLocaleDateString(
                                              "en-GB",
                                              {
                                                day: "numeric",
                                                month: "numeric",
                                                year: "numeric",
                                              }
                                            )
                                          : null
                                      }
                                      onClick={() => setShow2(!show2)}
                                      readOnly
                                      placeholder="Select Date"
                                    />
                                    <div onClick={() => setShow2(!show2)}>
                                      {InputProps?.endAdornment}
                                    </div>
                                  </InputGroup>
                                </Form.Group>
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                      </ClickAwayListener>
                    </Form.Group>

                    <Button
                      className="my-save-button mt-3"
                      onClick={addProduct}
                    >
                      Save
                    </Button>
                  </div>
                  {selectedProduct && (
                    <div className="ms-5 ps-5">
                      <div className="shadow-box-2">
                        <div className="a">UOM</div>
                        <div className="b">
                          {selectedProduct?.product?.uom?.name}
                        </div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Order Qty</div>
                        <div className="b">{selectedProduct?.qty}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Unit Cost</div>
                        <div className="b">${selectedProduct?.unitCost}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Total Cost</div>
                        <div className="b">
                          ${selectedProduct?.qty * selectedProduct?.unitCost}
                        </div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Unit Price</div>
                        <div className="b">
                          ${selectedProduct?.product?.unitSellingPrice}
                        </div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Total Price</div>
                        <div className="b">
                          $
                          {selectedProduct?.product?.unitSellingPrice *
                            selectedProduct?.qty}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {products.length !== 0 && (
                <CustomTable
                  data={products}
                  disabled={isDisabled}
                  editProduct={editProduct}
                  deleteProduct={deleteProduct}
                  setEditingProduct={setEditingProduct}
                />
              )}

              <div className="col-8 mt-3">
                <Form.Label className="headings2 gray-font">
                  Totalize
                </Form.Label>
                <div className="shadow-text-box my-2">
                  <span>Total Cost</span>
                  <div>${totalCost || 0}</div>
                </div>

                <Form.Group>
                  <Form.Label className="headings2 mt-2">
                    Invoice GST ({applyTaxDetail?.typeValue === "Percentage" ? "%" : "Amount"})
                  </Form.Label>
                  <InputGroup className={`${isDisabled && "disabled-input"}`}>
                    <Form.Control
                      type="text"
                      name="gstCostInput"
                      placeholder="Write..."
                      onChange={(e) => {
                        setInvoiceGst(e.target.value);
                      }}
                      value={invoiceGst}
                      disabled={isDisabled}
                    />
                  </InputGroup>
                </Form.Group>
                <div className="shadow-text-box">
                  <span>Invoice Total</span>
                  <div>${invoiceTotal || 0}</div>
                </div>
              </div>
            </div>
          </div>

          {
            !isDisabled && (
              <div className="addEdit d-flex">
                <Button className="addItem" onClick={() => saveGoods(false)}>
                  Save as Draft
                </Button>
                <Button className="addItem mx-3" onClick={() => saveGoods(true)}>
                  Finalize
                </Button>
              </div>
            )
          }
        </div>
      </div>
    </React.Fragment>
  );
}
