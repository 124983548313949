import { useNavigate } from "react-router-dom";
// import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import { getPatientGeneralReports } from "../../../apis/reports";
import BarGraph from "./BarGraph";
import { Bar } from "@nivo/bar";
import DataTable from "react-data-table-component";
import PieChart from "./PieChart";
import { exportData, getAgeFromDob } from "../../../helperFunctions/utils";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { Button, Form } from "react-bootstrap";

export default function InactivePatientsReports() {
	const navigate = useNavigate();

	const [inactivePatients, setInactivePatients] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [refetch, setRefetch] = useState(false);

	const [selectedOption, setSelectedOption] = useState("3 Months");
	const [filterRange, setFilterRange] = useState({
		start: new Date(new Date().setMonth(new Date().getMonth() - 1)),
		end: new Date(),
	});

	const [options, setOptions] = useState([
		{
			label: "3 Months",
			value: "3 Months",
			startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
			endDate: new Date(),
		},
		{
			label: "6 Months",
			value: "6 Months",
			startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
			endDate: new Date(),
		},
		{
			label: "9 Months",
			value: "9 Months",
			startDate: new Date(new Date().setMonth(new Date().getMonth() - 9)),
			endDate: new Date(),
		},
		{
			label: "1 year",
			value: "1 year",
			startDate: new Date(
				new Date().setMonth(new Date().getMonth() - 12),
			),
			endDate: new Date(),
		},
		{
			label: "2 year",
			value: "2 year",
			startDate: new Date(
				new Date().setMonth(new Date().getMonth() - 24),
			),
			endDate: new Date(),
		},
	]);

	const [startDate, setStartDate] = useState(
		new Date(new Date().getFullYear(), 0, 1),
	);
	const [endDate, setEndDate] = useState(new Date());

	function getMonthsBetweenDates(startDate, endDate) {
		const months = [];
		let currentDate = new Date(startDate);

		while (currentDate <= endDate) {
			months.push(currentDate.getMonth() + 1);
			currentDate.setMonth(currentDate.getMonth() + 1);
		}

		return months;
	}

	useEffect(() => {
		if (!startDate || !endDate) {
			return;
		}
		(async () => {
			const { result, error } = await getPatientGeneralReports({
				startDate,
				endDate,
			});
			if (error) {
				toastify(error.message, "error");
				return;
			}

			//////////////Pie charts\\\\\\\\\\\\\\\
			let maleCount = 0;
			let femaleCount = 0;

			const { patientsWithoutBilling } = result?.data;

			const tempTableData = [];
			patientsWithoutBilling?.forEach((o) => {
				if (o?.patientProfile?.gender == "Male") maleCount++;
				if (o?.patientProfile?.gender == "Female") femaleCount++;

				const obj = {
					patientName: o?.patientProfile?.fullName,
					email: o?.patientProfile?.email,
					contact: o?.patientProfile?.contactNo,
					gender: o?.patientProfile?.gender,
					location: o?.branch?.length > 0 ? o.branch[0].name : "-",
					lastVisit: o?.paymentData?._id
						? new Date(
								o?.paymentData?.created_at,
						  ).toLocaleDateString("en-GB", {
								day: "numeric",
								month: "numeric",
								year: "numeric",
						  })
						: "-",
					package: (
						<>
							{o?.paymentData?.packages?.length > 0 &&
								o?.paymentData?.packages?.map((pack) => {
									return (
										<>
											<div>{pack?.name}</div>
										</>
									);
								})}
						</>
					),
					packageText: o?.paymentData?.packages
						?.map((pack) => pack?.name)
						?.join(""),
				};
				tempTableData.push({ ...obj });
			});

			setInactivePatients([
				{
					id: "Men",
					label: "Men",
					value: maleCount,
				},
				{
					id: "Women",
					label: "Women",
					value: femaleCount,
				},
			]);
			setTableData([...tempTableData]);
		})();
	}, [refetch]);

	const columns = [
		{
			name: "Patient Name",
			selector: (row) => row.patientName,
			wrap: true,
		},
		{
			name: "Email",
			selector: (row) => row.email,
			wrap: true,
		},
		{
			name: "Contact No.",
			selector: (row) => row.contact,
			wrap: true,
		},
		{
			name: "Gender",
			selector: (row) => row.gender,
			wrap: true,
		},
		{
			name: "Location",
			selector: (row) => row.location,
			wrap: true,
		},
		{
			name: "Last Visit",
			selector: (row) => row.lastVisit,
			wrap: true,
		},
		// {
		// 	name: "Package",
		// 	selector: (row) => row.package,
		// 	wrap: true,
		// },
	];

	const data = tableData?.map((o, i) => {
		return { ...o, index: i };
	});

	const customStyles = {
		headCells: {
			style: {
				// justifyContent: "center",
			},
		},
		cells: {
			style: {
				// justifyContent: "center",
				// textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];
	return (
		<>
			<div>
				<div className="d-flex" style={{ width: "calc(100% - 130px)" }}>
					<div className="col-3 pe-2">
						<div className="font-400-20 mb-1">Start Date:</div>
						<DateInput
							value={startDate}
							placeholder="Start Date"
							onChange={(e) => {
								if (e > endDate) {
									toastify(
										"Start date can't be greater than start date",
										"error",
									);
									return;
								}
								setStartDate(e);
							}}
						/>
					</div>
					<div className="col-3 pe-2">
						<div className="font-400-20 mb-1">End Date:</div>
						<DateInput
							placeholder="End Date"
							value={endDate}
							onChange={(e) => {
								if (e < startDate) {
									toastify(
										"End date can't be less than start date",
										"error",
									);
									return;
								}
								setEndDate(e);
							}}
						/>
					</div>

					{/* <div className="col-3 pe-2">
						<Form.Group>
							<div className="font-400-20 mb-1">By Month</div>
							<SelectComponent
								options={options}
								className="w-100"
								selectedValue={selectedOption}
								singleSelect
								searchEnabled
								onChange={(e) => {
									setFilterRange({
										start: e.startDate,
										end: e.endDate,
									});
									// setStartDate(e.startDate);
									// setEndDate(e.endDate);
									setSelectedOption(e.value);
								}}
							/>
						</Form.Group>
					</div> */}
					<Button
						className="addItem mb-3 me-2 align-self-end"
						style={{ height: "47px" }}
						onClick={() => {
							setRefetch(!refetch);
							// setStartDate(filterRange.start);
							// setEndDate(filterRange.end);
						}}
					>
						Apply
					</Button>
					<Button
						className="addItem mb-3 align-self-end"
						style={{ height: "47px" }}
						onClick={() => {
							let tempDataArr = data?.map((o) => {
								return {
									"Patient Name": o?.patientName,
									Email: o?.email,
									"Contact No.": o?.contact,
									Gender: o?.gender,
									Location: o?.location,
									"Last Visit": o?.lastVisit,
									"Package Text": o?.packageText,
									[`Date : ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`]:
										"",
								};
							});
							exportData(
								tempDataArr,
								[],
								"Inactive_patients_reports",
							);
						}}
					>
						Export
					</Button>
					{/* <div className="col-3">
						<Form.Group>
							<div className="font-400-20 mb-1">By Quarter</div>
							<SelectComponent
								options={quaterOptions}
								selectedValue={selectedQuater}
								singleSelect
								searchEnabled
								onChange={(e) => {
									setSelectedQuater(e.value);
									if (e.value) {
										setSelectedMonth(null);

										const months = e.value.split("-");
										const firstDate = new Date(
											new Date().getFullYear(),
											monthNames.indexOf(months[0]),
											1,
										);
										const lastDate = new Date(
											new Date().getFullYear(),
											monthNames.indexOf(months[1]) + 1,
											0,
										);
										setStartDate(firstDate);
										setEndDate(lastDate);
									}
								}}
							/>
						</Form.Group>
					</div> */}
				</div>

				<div className="mt-4 p-3" style={{ background: "white" }}>
					<div className="font-400-20">
						<b>Inactive Patients</b>
					</div>
					<PieChart data={inactivePatients} />
				</div>

				<div className="mt-4" style={{ background: "white" }}>
					<div className="font-400-20 p-3">
						{/* <b>New Patients</b> */}
					</div>
					<DataTable
						pagination
						columns={columns}
						data={data}
						conditionalRowStyles={conditionalRowStyles}
						customStyles={customStyles}
					/>
				</div>
			</div>
		</>
	);
}
