import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { updateCampaign, createCampaign } from "../../../apis/campaign";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import { Switch } from "antd";
import { createRefund, updateRefund } from "../../../apis/refund";
import { loadOptionBillings, loadSelectedBilling } from "../../../apis/billing";
import { SearchIcon } from "../../../assets/images/index";
import { getInvoiceId } from "../../../helperFunctions/utils";
import AsyncSelect from "../../SharedComponents/AsyncSelect";

import ItemTable from "./ItemTable";

export default function AddEditRefund() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [remarks, setRemarks] = useState("");
  const [billings, setBillings] = useState([]);
  const [showBilling, setShowBilling] = useState(false);
  const [filteredCode, setFilteredCode] = useState([]);
  const [searchBill, setSearchBill] = useState("");
  const [idBill, setIdBill] = useState("");
  const [selectedBill, setSelectedBill] = useState();
  const [listItem, setListItem] = useState({});
  const [checkboxValues, setCheckboxValues] = useState([]);

  const populateData = async () => {
    setAmount(state?.refund?.amountRefund);
    setSelectedBill(state?.refund?.envoiceId);
    setIdBill(state?.refund?.envoiceId?._id);
    setSearchBill(state?.refund?.envoiceId?.invoiceNo);
    setRemarks(state?.refund?.remarks);
    setCheckboxValues(state?.refund?.items);
  };

  const getBillingList = async () => {
    // const response = await getBillings();
    // if (response?.status !== "Fail") {
    //   setBillings(response);
    // } else {
    //   if (response?.message === "No data Found") {
    //     setBillings([]);
    //   } else {
    //     toastify(response?.message, "error");
    //   }
    // }
  };

  const pupulateCOnverssion = async () => {
    const getInvoice = await loadSelectedBilling(state?.preInvoice);
    setAmount(getInvoice?.totalAmount - state?.totalAmount);
    setSelectedBill(getInvoice);
    setIdBill(getInvoice?._id);
    setSearchBill(getInvoice?.label);
  };

  useEffect(() => {
    getBillingList();
    if (state?.isConversion) {
      pupulateCOnverssion();
    }
  }, []);

  useEffect(() => {
    setFilteredCode(billings.filter((e) => e._id.includes(searchBill)));
    if (searchBill === "") {
      setFilteredCode(billings);
    }
  }, [searchBill]);

  useEffect(() => {
    const packages = [];
    const products = [];
    const treatments = [];

    if (selectedBill) {
      // packages
      const itemPackProducts = [];
      const itemPackTreatments = [];

      selectedBill.package?.map((billPackage) => {
        billPackage.package_id?.products?.map((billProduct) => {
          const productUsed = billPackage.products_used?.find(
            (x) => x.id === billProduct.product?._id
          );

          itemPackProducts.push({
            type: "Product",
            name: billProduct.product?.name,
            unitPrice: billProduct.product?.unitSellingPrice,
            totalPrice: billProduct.product?.totalSellingPrice,
            limit: billProduct.qty,
            used: productUsed?.used ?? 0,
            _id: billProduct._id,
          });
        });

        billPackage.package_id?.treatments?.map((billProduct) => {
          const treatmentUsed = billPackage.treatments_used?.find(
            (x) => x.id === billProduct.treatment?._id
          );

          itemPackTreatments.push({
            type: "Treatment",
            name: billProduct.treatment?.name,
            unitPrice: billProduct.treatment?.price,
            totalPrice: billProduct.treatment?.price,
            limit: billProduct.limit,
            used: treatmentUsed?.used ?? 0,
            _id: billProduct._id,
          });
        });

        packages.push({
          type: "Package",
          name: billPackage.package_id?.description,
          qty: billPackage.package_qty,
          unitPrice: billPackage.package_unit_price,
          totalPrice: billPackage.package_price,
          discount: billPackage.package_discount,
          products: itemPackProducts,
          treatments: itemPackTreatments,
          _id: billPackage._id,
        });
      });

      // products
      selectedBill.product?.map((billProduct) => {
        products.push({
          type: "Product",
          name: billProduct.product_id?.name,
          unitPrice: billProduct.product_unit_price,
          totalPrice: billProduct.product_price,
          discount: billProduct.product_discount,
          qty: billProduct.product_qty,
          _id: billProduct._id,
        });
      });

      // treatments
      selectedBill.treatment?.map((billTreatment) => {
        treatments.push({
          type: "Treatment",
          name: billTreatment.service_id?.name,
          unitPrice: billTreatment.service_unit_price,
          totalPrice: billTreatment.service_price,
          discount: billTreatment.service_discount,
          qty: billTreatment.service_qty,
          _id: billTreatment._id,
        });
      });
    }

    setListItem({
      packages: packages,
      products: products,
      treatments: treatments,
    });
  }, [selectedBill]);

  const saveRefund = async () => {
    if (!amount) {
      missingPopup("Amount");
    } else if (!selectedBill) {
      missingPopup("Invoice");
    } else {
      if (!state?.refund) {
        const response = await createRefund({
          amountRefund: amount,
          remarks,
          envoiceId: selectedBill._id,
          items: checkboxValues,
          conversionId: state?.converssionId,
        });
        if (response?.status !== "Fail") {
          toastify("Refund added successfully!", "success");
          navigate("/refund");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateRefund(state?.refund?._id, {
          amountRefund: amount,
          remarks,
          envoiceId: selectedBill._id,
          dataId: state?.refund?._id,
          items: checkboxValues,
        });
        if (response?.status !== "Fail") {
          toastify("Refund updated successfully!", "success");
          navigate("/refund");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  useEffect(() => {
    if (state?.refund) populateData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.refund ? "Edit " : "New "}`}
          Refund
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-8">
                <Form.Group>
                  <Form.Label className="headings2">Invoice *</Form.Label>
                  <AsyncSelect
                    value={idBill}
                    setValue={(value) => {
                      setSearchBill(value.invoiceNo);
                      setIdBill(value._id);
                      setSelectedBill(value);
                    }}
                    loadOptions={(search, loadedOptions, { page }) =>
                      loadOptionBillings(search, loadedOptions, { page })
                    }
                    loadSelectedOption={loadSelectedBilling}
                  />
                </Form.Group>

                <div style={{ marginTop: "20px" }}>
                  <div
                    className="refund-info mb-2 p-2"
                    style={{ fontSize: "18px" }}
                  >
                    <div className="bold">Patient Name</div>
                    <div>{selectedBill?.patient?.patientProfile?.fullName}</div>
                  </div>
                  {selectedBill?.treatments && (
                    <div
                      className="refund-info mb-2 p-2"
                      style={{ fontSize: "18px" }}
                    >
                      <div className="bold">Treatment</div>
                      {selectedBill?.treatments?.map((e) => (
                        <div>{e.name}</div>
                      ))}
                    </div>
                  )}

                  {selectedBill?.packages && (
                    <div
                      className="refund-info mb-2 p-2"
                      style={{ fontSize: "18px" }}
                    >
                      <div className="bold">Packages</div>
                      {selectedBill?.packages?.map((e) => (
                        <div>{e.name}</div>
                      ))}
                    </div>
                  )}

                  {selectedBill?.products && (
                    <div
                      className="refund-info mb-2 p-2"
                      style={{ fontSize: "18px" }}
                    >
                      <div className="bold">Products</div>
                      {selectedBill?.products?.map((e) => (
                        <div>{e.name}</div>
                      ))}
                    </div>
                  )}

                  <div
                    className="refund-info mb-2 p-2"
                    style={{ fontSize: "18px" }}
                  >
                    <div className="bold">Total Amount</div>
                    <div>
                      {selectedBill?.totalAmount
                        ?.toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "N/A"}
                    </div>
                  </div>
                </div>

                <div style={{ marginTop: 25, marginBottom: 25 }}>
                  <ItemTable
                    packages={listItem.packages ? listItem.packages : []}
                    products={listItem.products ? listItem.products : []}
                    treatments={listItem.treatments ? listItem.treatments : []}
                    checkboxValues={checkboxValues}
                    setCheckboxValues={setCheckboxValues}
                  />
                </div>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Amount Refund *</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setAmount(e.target.value)}
                      value={amount}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Remarks</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      rows={4}
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveRefund}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
