import { useEffect, useMemo, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { getSalesReports, getProgramExpirationReports } from "../../../apis/reports";
import { getStaff } from "../../../apis/staff";
import { getPrograms } from "../../../apis/programExpiration";
import { yearsOptions } from "../../../constants";
import { toastify } from "../../../helperFunctions/toastify";
import { exportData, getInvoiceId } from "../../../helperFunctions/utils";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import BarGraph from "./BarGraph";
// import { filterTypes } from "./PatientGeneralReport";
import PieChart from "./PieChart";

const filterTypes = [
    {
        label: "Last 3 Months",
        value: "threeMonths",
    },
    {
        label: "Last 6 Months",
        value: "sixMonths",
    },
    {
        label: "Last 1 Year",
        value: "year",
    },
    {
        label: "Last 2 Years",
        value: "twoYears",
    },
    {
        label: "Date Range",
        value: "range",
    },
];

export default function SalesReport() {
    // const [selectedMonth, setSelectedMonth] = useState(null);
    // const [selectedQuater, setSelectedQuater] = useState(null);

    const [allData, setAllData] = useState([]);
    const [salesGraphData, setSalesGraphData] = useState([]);
    const [activeSalesData, setActiveSalesData] = useState({});

    const [filterType, setFilterType] = useState("range");
    const [refetch, setRefetch] = useState(true);
    const [selectedQuaterStart, setSelectedQuaterStart] = useState(null);
    const [quarterStartYear, setQuarterStartYear] = useState();
    const [quarterEndYear, setQuarterEndYear] = useState();
    const [selectedQuarterEnd, setSelectedQuaterEnd] = useState();

    const [totals, setTotals] = useState([]);
    const [activeIndex, setActiveIndex] = useState();
    const [allTreatments, setAllTreatments] = useState([]);
    const [allProducts, setAllproducts] = useState([]);
    const [allPackages, setAllPackages] = useState([]);

    const [allStaff, setAllStaff] = useState([]);
    const [selectedStaff, setSelectedStaff] = useState();
    const [staff, setStaff] = useState([]); //staff to be send in query
    const [paymentMethodsData, setPaymentMethodsData] = useState([]);

    const [patientsCount, setPatientCount] = useState({
        recurringPatients: 0,
        withKol: 0,
        withReferral: 0,

        newPatients: 0,
    });

    const quaterOptions = [
        {
            label: "Jan - Mar",
            value: "January-March",
        },
        {
            label: "Apr - June",
            value: "April-June",
        },
        {
            label: "Jul - Sept",
            value: "July-September",
        },
        {
            label: "Oct - Dec",
            value: "October-December",
        },
    ];

    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];
    const shortMonthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    const [range, setRange] = useState({
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(),
    });

    const [months, setMonths] = useState({
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
    });
    const [startDate, setStartDate] = useState(
		new Date(new Date().getFullYear(), 0, 1),
	);
	const [endDate, setEndDate] = useState(new Date());

    // const onApply = (start, end) => {
    //     if (!selectedStaff) {
    //         toastify("Please select all the fields", "error")
    //     }
    //     setStartDate(start);
    //     setEndDate(end);
    //     setStaff(selectedStaff);
    // };

    const onApply = () => {
        if (!selectedStaff) {
            toastify("Please select all the fields", "error")
        } else {
            setRefetch(!refetch);
        }
    };

    const onQuarterApply = () => {
        let sYear = quarterStartYear;
        let sQuarter = selectedQuaterStart;
        let eQuarter = selectedQuarterEnd;
        let eYear = quarterEndYear;

        if (sQuarter && eQuarter && sYear && eYear) {
            const startMonth = new Date(
                sYear,
                monthNames.indexOf(sQuarter.split("-")[0]),
            );
            const endMonth = new Date(
                eYear,
                monthNames.indexOf(eQuarter.split("-")[1]) + 1,
                0,
            );

            setStartDate(startMonth);
            setEndDate(endMonth);
        }
        setStaff(selectedStaff);
    };
    function getQuarterForDate(date) {
        const month = new Date(date).getMonth();
        if (month >= 0 && month < 3) {
            return `Jan-Mar ${new Date(date).getFullYear()}`;
        }
        if (month >= 3 && month < 6) {
            return `Apr-Jun ${new Date(date).getFullYear()}`;
        }
        if (month >= 6 && month < 9) {
            return `Jul-Sep ${new Date(date).getFullYear()}`;
        }
        if (month >= 9) {
            return `Sep-Dec ${new Date(date).getFullYear()}`;
        }
    }

    function getMonthsBetweenDates(startDate, endDate) {
        if (filterType !== "quarter") {
            const months = [];
            let currentDate = new Date(startDate);

            while (currentDate <= endDate) {
                months.push(
                    `${currentDate.getMonth() + 1
                    } ${currentDate.getFullYear()}`,
                );
                currentDate.setMonth(currentDate.getMonth() + 1);
            }
            return months;
        }
    }

    useEffect(() => {
        if (!startDate || !endDate || !selectedStaff) {
            return;
        }
        (async () => {
            let staffArr = [];
            // if (!staff.length) {
            // 	staffArr = allStaff.map((o) => o.value);
            // } else {
            staffArr = [...staff];
            // }
            const { result, error } = await getProgramExpirationReports({
                startDate,
                endDate,
                program: selectedStaff
            });
            console.log("====kkkkk", result)
            if (error) {
                toastify(error?.message, "error");
                return;
            }

            setAllData([...result.data]);
            const months = getMonthsBetweenDates(startDate, endDate);
            const commonCondition = (curDate, year, startMonth, endMonth) => {
                if (
                    filterType !== "quarter" &&
                    new Date(curDate).getMonth() + 1 == startMonth &&
                    new Date(curDate).getFullYear() == year
                ) {
                    return true;
                } else if (
                    filterType == "quarter" &&
                    new Date(curDate).getMonth() >= startMonth &&
                    new Date(curDate).getMonth() <= endMonth &&
                    new Date(curDate).getFullYear() == year
                ) {
                    return true;
                } else {
                    return false;
                }
            };

            let tempGeneralDataArr = [];
            months.forEach((mon) => {
                let startMonth;
                let endMonth;
                let year;
                if (filterType !== "quarter") {
                    startMonth = mon.split(" ")[0];
                    year = mon.split(" ")[1];
                } else {
                    startMonth = mon.split("-")[0];
                    year = mon.split(" ")[1];
                    endMonth = mon.split(" ")[0].split("-")[1];
                }

                const allPatients = result?.data.filter(
                    (ap) =>
                        commonCondition(
                            ap.created_at,
                            year,
                            startMonth,
                            endMonth,
                        ) && new Date(ap.created_at).getFullYear() == year,

                ).length;


                const label =
                    filterType !== "quarter"
                        ? `${monthNames[startMonth - 1]} ${year}`
                        : `${shortMonthNames[startMonth]}-${shortMonthNames[endMonth]} ${year}`;

                const formatedDate = new Date(`${label}`).getMonth()
                const monthlyData = result?.data.filter(md => new Date(md.created_at).getMonth() === formatedDate)
                let sum = 0;
                let gst = 0;
                monthlyData?.forEach((md) => { return sum += md.totalAmount })
                monthlyData?.forEach((md) => { return gst += md.gst })
                tempGeneralDataArr.push({
                    label: label,
                    month: mon,
                    "Programs": allPatients,
                    data: monthlyData,
                    totalAmount: sum,
                    gst: gst
                    // Referrals: allReferrals,
                    // "Medial / KOL": allKols,
                    // "Recurring Patients": recurringPatients,
                    // "Existing Patients": existingPatients,
                });
            });


            // setPaymentMethodsData([..._paymentMethodsData]);
            // setPatientCount({
            //     newPatients: newPatients,
            //     withKol: withMedia,
            //     withReferral: referralPatients,
            //     recurringPatients: result.data.length - newPatients,
            // });
            setSalesGraphData([...tempGeneralDataArr]);
        })();
    }, [refetch]);

    useMemo(() => {
        if (activeSalesData.date) {
            let packagesTotal = 0;
            let productsTotal = 0;
            let treatmentsTotal = 0;

            let allPackages = [];
            let allProducts = [];
            let allTreatments = [];
            activeSalesData.packages?.forEach((p) => {
                if (p.length > 0) {
                    p.forEach((singlePack) => {
                        if (!singlePack.isAlreadyPaid) {
                            packagesTotal =
                                packagesTotal +
                                (singlePack?.package_price ?? 0);
                            allPackages.push({
                                name: singlePack?.package_id?.name,
                                price: singlePack?.package_price,
                            });
                        }
                    });
                }
            });

            activeSalesData.products?.forEach((p) => {
                if (p.length > 0) {
                    p.forEach((singlePack) => {
                        productsTotal =
                            productsTotal + (singlePack?.product_price ?? 0);
                        allProducts.push({
                            name: singlePack?.product_id?.name,
                            price: singlePack?.product_price,
                        });
                    });
                }
            });

            activeSalesData.treatments?.forEach((p) => {
                if (p.length > 0) {
                    p.forEach((singlePack) => {
                        treatmentsTotal =
                            treatmentsTotal + (singlePack?.service_price ?? 0);
                        allTreatments.push({
                            name: singlePack?.service_id?.name,
                            price: singlePack?.service_price,
                        });
                    });
                }
            });

            setTotals([
                {
                    id: "Products",
                    label: "Products",
                    value: parseFloat(
                        (productsTotal / activeSalesData["Total Amount"]) * 100,
                    ).toFixed(2),
                },
                {
                    id: "Treatments",
                    label: "Treatments",
                    value: parseFloat(
                        (treatmentsTotal / activeSalesData["Total Amount"]) *
                        100,
                    ).toFixed(2),
                },
                {
                    id: "Packages",
                    label: "Packages",
                    value: parseFloat(
                        (packagesTotal / activeSalesData["Total Amount"]) * 100,
                    ).toFixed(2),
                },
            ]);
            setAllTreatments(allTreatments);
            setAllproducts(allProducts);
            setAllPackages(allPackages);
        }
    }, [activeSalesData]);

    useEffect(() => {
        (async () => {
            const res = await getPrograms();
            if (res.status !== "Fail") {
                const tempArr = res.result?.data?.map((o) => ({
                    label: o?.name,
                    value: o?._id,
                }));
                setAllStaff(tempArr);
            }
        })();
    }, []);
    useMemo(() => {
        // if (
        // 	selectedQuaterStart &&
        // 	selectedQuarterEnd &&
        // 	quarterStartYear &&
        // 	quarterEndYear
        // ) {
        // 	const startMonth = new Date(
        // 		quarterStartYear,
        // 		monthNames.indexOf(selectedQuaterStart.split("-")[0]),
        // 	);
        // 	const endMonth = new Date(
        // 		quarterEndYear,
        // 		monthNames.indexOf(selectedQuarterEnd.split("-")[1]) + 1,
        // 		0,
        // 	);
        // 	setStartDate(startMonth);
        // 	setEndDate(endMonth);
        // }
    }, [
        selectedQuaterStart,
        selectedQuarterEnd,
        quarterStartYear,
        quarterEndYear,
    ]);
    console.log("salesgggg", salesGraphData)
    const getDataForExport = (array) => {
        const programs = array.filter(d => d.Programs > 0)
        return programs.map((p) => { return { Month: p.label, Programs: p.Programs } })
        // let treatments = 0;
        // let packages = 0;
        // let products = 0;
        // array.forEach((o) => {
        //     o?.packages[0]?.forEach((pack) => {
        //         if (pack?.package_price) packages += pack?.package_price;
        //     });
        // o?.products[0]?.forEach((pack) => {
        //     if (pack?.product_price) products += pack?.product_price;
        // });
        // o?.treatments[0]?.forEach((pack) => {
        //     if (pack?.service_price) treatments += pack?.service_price;
        // });
        // });
        // return {
        // treatments,
        // products,
        // packages,
        // };
    };
    const getColumns = (array, name, value) => {
        let total = 0;

        array.forEach((o) => {
            if (o.price) {
                total += o.price;
            }
        });

        return [
            {
                name: name,
                selector: (row) => row.name,
            },
            {
                name: total?.toLocaleString(),
                selector: (row) => row.price?.toLocaleString(),
            },
        ];
    };
    const customStyles = {
        headCells: {
            style: {
                // justifyContent: "center",
            },
        },
        cells: {
            style: {
                // justifyContent: "center",
                // textAlign: "center",
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: (row) => row,
            style: {
                borderWidth: "0px",
            },
        },
        {
            when: (row) => row.index % 2 === 1,
            style: {
                backgroundColor: "#f8f8f8",
                borderWidth: "0px",
            },
        },
    ];
    return (
        <>
            <div
                className="d-flex flex-wrap filter-wrapper"
                style={{ width: "calc(100% - 130px)" }}
            >
                {/* <div className="col-3 pe-2">
                    <Form.Group>
                        <div className="font-400-20 mb-1">Filter Selection</div>
                        <SelectComponent
                            options={filterTypes}
                            selectedValue={filterType}
                            singleSelect
                            searchEnabled
                            nullDisabled
                            onChange={(e) => {
                                setFilterType(e.value);
                            }}
                        />

                    </Form.Group>
                </div> */}

                {filterType == "range" &&
                    (
                        <>
                            <div className="col-3 pe-2">
                                <div className="font-400-20 mb-1">Start Date:</div>
                                <DateInput
                                    value={range.start}
                                    placeholder="Start Date"
                                    onChange={(e) => {
                                        if (e > range.end) {
                                            toastify(
                                                "Start date can't be greater than end date",
                                                "error",
                                            );
                                            return;
                                        }
                                        setRange({ ...range, start: e });

                                        // setStartDate(e);
                                    }}
                                />
                            </div>
                            <div className="col-3 pe-2">
                                <div className="font-400-20 mb-1">End Date:</div>
                                <DateInput
                                    placeholder="End Date"
                                    value={range.end}
                                    onChange={(e) => {
                                        if (e < range.start) {
                                            toastify(
                                                "End date can't be less than start date",
                                                "error",
                                            );
                                            return;
                                        }
                                        setRange({ ...range, end: e });

                                        // setEndDate(e);
                                    }}
                                />
                            </div>
                        </>
                    )

                }
                <div className="col-3 pe-2">
                    <Form.Group>
                        <div className="font-400-20 mb-1">Select Program</div>
                        <SelectComponent
                            singleSelect
                            searchEnabled
                            selectedValue={selectedStaff}
                            options={allStaff}
                            onChange={(e) => {
                                setSelectedStaff(e.value);
                            }}
                        />
                    </Form.Group>
                </div>

                {filterType == "range" &&
                    (
                        <>
                            <div className="col-3 pe-2 aplybtn" style={{display: "flex"}}>
                                <Button
                                    className="addItem mb-3 mx-2 align-self-end"
                                    style={{ height: "47px" }}
                                    onClick={() => {
                                        var d = new Date();
                                        onApply(range.start, range.end);
                                    }}
                                >
                                    Apply
                                </Button>
                                <Button
                                    className="addItem mb-3 align-self-end"
                                    style={{ height: "47px" }}
                                    onClick={() => {
                                        const prg = getDataForExport(salesGraphData);
                                        exportData(prg, "program_expiration_report");
                                    }}
                                >
                                    Export
                                </Button>
                            </div>
                        </>
                    )
                }

                {filterType == "threeMonths" &&
                    (
                        <>
                            <div className="col-3 pe-2 aplybtn">
                                <Button
                                    className="addItem mb-3 mx-2 align-self-end"
                                    style={{ height: "47px" }}
                                    onClick={() => {
                                        var d = new Date();
                                        d.setMonth(d.getMonth() - 3);
                                        setRange({ ...range, start: d });
                                        setRange({ ...range, end: new Date() });
                                        onApply(d, new Date());

                                    }}
                                >
                                    Apply
                                </Button>
                            </div>
                        </>
                    )
                }
                {filterType === "sixMonths" &&
                    (
                        <>
                            <div className="col-3 pe-2 aplybtn">
                                <Button
                                    className="addItem mb-3 mx-2 align-self-end"
                                    style={{ height: "47px" }}
                                    onClick={() => {
                                        var d = new Date();
                                        d.setMonth(d.getMonth() - 6);
                                        setRange({ ...range, start: d });
                                        setRange({ ...range, end: new Date() });
                                        onApply(d, new Date());

                                    }}
                                >
                                    Apply
                                </Button>
                            </div>
                        </>
                    )
                }
                {filterType === "year" &&
                    (
                        <>
                            <div className="col-3 pe-2 aplybtn">
                                <Button
                                    className="addItem mb-3 mx-2 align-self-end"
                                    style={{ height: "47px" }}
                                    onClick={() => {
                                        var startDate = new Date();
                                        startDate.setFullYear(startDate.getFullYear() - 1);
                                        setRange({ ...range, start: startDate });
                                        setRange({ ...range, end: new Date() });
                                        onApply(startDate, new Date());

                                    }}
                                >
                                    Apply
                                </Button>
                            </div>
                        </>
                    )
                }
                {filterType === "twoYears" &&
                    (
                        <>
                            <div className="col-3 pe-2 aplybtn">
                                <Button
                                    className="addItem mb-3 mx-2 align-self-end"
                                    style={{ height: "47px" }}
                                    onClick={() => {
                                        var startDate = new Date();
                                        startDate.setFullYear(startDate.getFullYear() - 2);
                                        setRange({ ...range, start: startDate });
                                        setRange({ ...range, end: new Date() });
                                        onApply(startDate, new Date());

                                    }}
                                >
                                    Apply
                                </Button>
                            </div>
                        </>
                    )
                }

                {/* {filterType == "month" && (
                    <>
                        <div className="col-3 pe-2">
                            <div className="font-400-20 mb-1">Start Month:</div>
                            <DateInput
                                placeholder="Start Month"
                                views={["month", "year"]}
                                value={months.start}
                                onChange={(e) => {
                                    setMonths({
                                        ...months,
                                        start: new Date(
                                            e.getFullYear(),
                                            e.getMonth(),
                                        ),
                                    });
                                    // setStartDate(
                                    // 	new Date(e.getFullYear(), e.getMonth()),
                                    // );
                                }}
                            />
                        </div>
                        <div className="col-3 pe-2">
                            <div className="font-400-20 mb-1">End Month:</div>
                            <DateInput
                                placeholder="Start Month"
                                views={["month", "year"]}
                                value={months.end}
                                onChange={(e) => {
                                    if (months.start > e) {
                                        toastify(
                                            "End date cann't be less than start date",
                                            "error",
                                        );
                                        return;
                                    }

                                    const date = new Date(
                                        e.getFullYear(),
                                        e.getMonth() + 1,
                                        0,
                                    );
                                    setMonths({
                                        ...months,
                                        end: date,
                                    });
                                    // setEndDate(date);
                                }}
                            />
                        </div>{" "}
                        <div className="col-3">
                            <SelectComponent
                                options={allStaff}
                                isNullEnabled
                                searchEnabled
                                array={selectedStaff}
                                onChange={(e) => {
                                    setSelectedStaff(e);
                                }}
                            />
                        </div>
                        <Button
                            className="addItem mb-3 mx-2 align-self-end"
                            style={{ height: "47px" }}
                            onClick={() => {
                                onApply(months.start, months.end);
                            }}
                        >
                            Apply
                        </Button>
                    </>
                )} */}
                {/* {filterType == "quarter" && (
                    <>
                        <div className="col-3">
                            <div className="font-400-20 mb-1">Start Year:</div>
                            <SelectComponent
                                options={yearsOptions}
                                selectedValue={quarterStartYear}
                                singleSelect
                                searchEnabled
                                onChange={(e) => {
                                    if (
                                        parseInt(e.value) >
                                        parseInt(quarterEndYear)
                                    ) {
                                        toastify(
                                            "Start Year can't be greater than end year",
                                            "error",
                                        );
                                        return;
                                    }
                                    setQuarterStartYear(e.value);
                                }}
                            />
                        </div>
                        <Form.Group className="col-3 mx-2">
                            <div className="font-400-20 mb-1">
                                Start Quarter
                            </div>
                            <SelectComponent
                                options={quaterOptions}
                                selectedValue={selectedQuaterStart}
                                singleSelect
                                searchEnabled
                                onChange={(e) => {
                                    setSelectedQuaterStart(e.value);
                                }}
                            />
                        </Form.Group>
                        <div className="col-3">
                            <div className="font-400-20 mb-1">End Year:</div>
                            <SelectComponent
                                options={yearsOptions}
                                selectedValue={quarterEndYear}
                                singleSelect
                                searchEnabled
                                onChange={(e) => {
                                    if (
                                        parseInt(e.value) <
                                        parseInt(quarterStartYear)
                                    ) {
                                        toastify(
                                            "End Year can't be less than start year",
                                            "error",
                                        );
                                        return;
                                    }
                                    setQuarterEndYear(e.value);
                                }}
                            />
                        </div>
                        <Form.Group className="col-3 ms-2">
                            <div className="font-400-20 mb-1">End Quarter</div>
                            <SelectComponent
                                options={quaterOptions}
                                selectedValue={selectedQuarterEnd}
                                singleSelect
                                searchEnabled
                                onChange={(e) => {
                                    setSelectedQuaterEnd(e.value);
                                }}
                            />
                        </Form.Group>
                        <div className="col-3 align-self-end ms-2">
                            <SelectComponent
                                options={allStaff}
                                isNullEnabled
                                searchEnabled
                                array={selectedStaff}
                                onChange={(e) => {
                                    setSelectedStaff(e);
                                }}
                            />
                        </div>
                        <Button
                            className="addItem mb-3 mx-2 align-self-end"
                            style={{ height: "47px" }}
                            onClick={() => {
                                onQuarterApply();
                            }}
                        >
                            Apply
                        </Button>
                    </>
                )} */}
            </div>
            <div className="mt-4 p-3" style={{ background: "white" }}>
                <div className="font-400-20">
                    <b>Program Expiration Report</b>
                </div>
                {/* <BarGraph
                    data={salesGraphData}
                    isDate={filterType == "date"}
                    xLegend={"Date"}
                    yLegend={"Amount"}
                    enableGridY={true}
                    enableGridX={false}
                    keys={["Total Amount"]}
                    indexBy="date"
                /> */}
                <BarGraph
                    data={salesGraphData}
                    groupMode={"grouped"}
                    yLegend={"Count"}
                    xLegend={"Months"}
                    enableGridY={true}
                    // isDate
                    enableGridX={true}
                    layout="vertical"
                    keys={[
                        "Programs",
                    ]}
                    indexBy="label"
                />
            </div>

            <div className="wrapper-table-report">
                <Table striped>
                    <thead
                        style={{
                            background: "#34556F",
                            color: "white",
                        }}
                    >
                        <tr
                            style={{
                                height: "45px",
                            }}
                            className="align-items-center
                                "
                        >
                            <th className="wd-100">Month</th>
                            <th className="wd-100">Count</th>
                            <th className="wd-100">Total Amount</th>
                            <th className="wd-100">Total Amount GST</th>
                            <th className="wd-100">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salesGraphData?.length > 0 &&
                            salesGraphData?.filter(s => s.Programs > 0).map((e, index) => (
                                <>
                                    <tr>
                                        <td className="wd-150 ps-3">
                                            {new Date(e.label).toLocaleString('default', { month: 'short' })}
                                        </td>
                                        <td className="wd-150 ps-3">
                                            {e.Programs}
                                        </td>
                                        <td className="wd-150">
                                            {e.totalAmount.toLocaleString()}
                                        </td>
                                        <td className="wd-150">
                                            {e.gst.toLocaleString()}
                                        </td>

                                        <td className="wd-100 ">
                                            <Button
                                                className="addItem"
                                                style={{
                                                    maxHeight: "35px",
                                                    width: "130px",
                                                    fontWeight: 400,
                                                    fontSize: "14px",
                                                }}
                                                onClick={() => {
                                                    if (activeIndex == index) {
                                                        setActiveIndex();
                                                        setActiveSalesData({});
                                                        return;
                                                    }
                                                    setActiveSalesData(e);
                                                    setActiveIndex(index);
                                                }}
                                            >
                                                View Detail
                                            </Button>
                                        </td>
                                    </tr>
                                    {activeIndex === index && (
                                        <tr>
                                            <td colSpan="12">
                                                <Table>
                                                    <thead
                                                        style={{
                                                            background: "#34556F",
                                                            color: "white",
                                                        }}
                                                    >
                                                        <tr
                                                            style={{
                                                                height: "45px",
                                                            }}
                                                            className="align-items-center"
                                                        >
                                                            <th className="wd-100">Date</th>
                                                            <th className="wd-100">Patient Name</th>
                                                            <th className="wd-100">Is KOL/Channel</th>
                                                            <th className="wd-100">Invoice No</th>
                                                            <th className="wd-100">Total Amount</th>
                                                            <th className="wd-100">Total Amount GST</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {e.data?.map((d) => (
                                                            <tr>
                                                                <td>
                                                                    {`${new Date(d.created_at).getMonth()}-${new Date(d.created_at).getDate()}-${new Date(d.created_at).getFullYear()}`}
                                                                </td>
                                                                <td>{d.patient.patientProfile.fullName}</td>
                                                                <td>{d.patient.patientProfile.kol?._id ? `true` : `false`}</td>
                                                                <td>{getInvoiceId(d.invoiceNo)}</td>
                                                                <td>{d.totalAmount.toLocaleString()}</td>
                                                                <td>{d.gst.toLocaleString()}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </td>
                                        </tr>
                                        // <tr>
                                        //     <td colSpan="12">
                                        //         <div
                                        //             className="mt-4 p-3"
                                        //             style={{ background: "white" }}
                                        //         >
                                        //             <div className="font-400-20">
                                        //                 <b>
                                        //                     Treatments, Products,
                                        //                     Packages
                                        //                 </b>
                                        //             </div>
                                        //             <div className="w-100 d-flex justify-content-center">
                                        //                 <PieChart
                                        //                     itemWidth={90}
                                        //                     data={totals}
                                        //                 />
                                        //             </div>
                                        //         </div>
                                        //         <div className="white-bg p-2 px-3">
                                        //             <span className="font-400-16">
                                        //                 <b>Items : </b>
                                        //             </span>{" "}
                                        //         </div>
                                        //         {allTreatments?.length > 0 && (
                                        //             <>
                                        //                 <div
                                        //                     className="mt-0 px-3"
                                        //                     style={{
                                        //                         background: "white",
                                        //                     }}
                                        //                 >
                                        //                     <DataTable
                                        //                         columns={getColumns(
                                        //                             allTreatments,
                                        //                             "Treatment",
                                        //                         )}
                                        //                         data={allTreatments}
                                        //                         conditionalRowStyles={
                                        //                             conditionalRowStyles
                                        //                         }
                                        //                         customStyles={
                                        //                             customStyles
                                        //                         }
                                        //                     />
                                        //                 </div>
                                        //             </>
                                        //         )}
                                        //         {/* {allProducts.length > 0 && (
                                        //             <>
                                        //                 <div
                                        //                     className="mt-0 px-3"
                                        //                     style={{
                                        //                         background: "white",
                                        //                     }}
                                        //                 >
                                        //                     <DataTable
                                        //                         columns={getColumns(
                                        //                             allProducts,
                                        //                             "Product",
                                        //                         )}
                                        //                         data={allProducts}
                                        //                         conditionalRowStyles={
                                        //                             conditionalRowStyles
                                        //                         }
                                        //                         customStyles={
                                        //                             customStyles
                                        //                         }
                                        //                     />
                                        //                 </div>
                                        //             </>
                                        //         )}

                                        //         {allPackages.length > 0 && (
                                        //             <>
                                        //                 <div
                                        //                     className="mt-0 px-3"
                                        //                     style={{
                                        //                         background: "white",
                                        //                     }}
                                        //                 >
                                        //                     <DataTable
                                        //                         columns={getColumns(
                                        //                             allPackages,
                                        //                             "Package",
                                        //                         )}
                                        //                         data={allPackages}
                                        //                         conditionalRowStyles={
                                        //                             conditionalRowStyles
                                        //                         }
                                        //                         customStyles={
                                        //                             customStyles
                                        //                         }
                                        //                     />
                                        //                 </div>
                                        //             </>
                                        //         )}
                                        //         <div className="white-bg p-2 px-3">
                                        //             <span className="font-400-16">
                                        //                 <b>Patients : </b>
                                        //             </span>{" "}
                                        //             <div
                                        //                 className="w-100 px-3"
                                        //                 style={{ fontSize: "16px" }}
                                        //             >
                                        //                 <div className="d-flex py-2">
                                        //                     <span className="w-50">
                                        //                         No of New Patients
                                        //                     </span>
                                        //                     <span>
                                        //                         {
                                        //                             patientsCount.newPatients
                                        //                         }
                                        //                     </span>
                                        //                 </div>
                                        //                 <div className="d-flex py-2">
                                        //                     <span className="w-50">
                                        //                         No of Media
                                        //                     </span>
                                        //                     <span>
                                        //                         {
                                        //                             patientsCount.withKol
                                        //                         }
                                        //                     </span>
                                        //                 </div>
                                        //                 <div className="d-flex py-2">
                                        //                     <span className="w-50">
                                        //                         No of Referral
                                        //                         Patients
                                        //                     </span>
                                        //                     <span>
                                        //                         {
                                        //                             patientsCount.withReferral
                                        //                         }
                                        //                     </span>
                                        //                 </div>
                                        //                 <div className="d-flex py-2">
                                        //                     <span className="w-50">
                                        //                         No of Recurring
                                        //                         Patients
                                        //                     </span>
                                        //                     <span>
                                        //                         {
                                        //                             patientsCount.recurringPatients
                                        //                         }
                                        //                     </span>
                                        //                 </div>
                                        //             </div>
                                        //         </div>
                                        //         <div className="white-bg p-2 px-3">
                                        //             <span className="font-400-16">
                                        //                 <b>Patients : </b>
                                        //             </span>{" "}
                                        //             {paymentMethodsData?.length >
                                        //                 0 &&
                                        //                 paymentMethodsData?.map(
                                        //                     (meth) => {
                                        //                         return (
                                        //                             <>
                                        //                                 <div className="d-flex py-2">
                                        //                                     <span className="w-50">
                                        //                                         {
                                        //                                             meth.name
                                        //                                         }
                                        //                                     </span>
                                        //                                     <span>
                                        //                                         {
                                        //                                             meth.count
                                        //                                         }
                                        //                                     </span>
                                        //                                 </div>
                                        //                             </>
                                        //                         );
                                        //                     },
                                        //                 )}
                                        //         </div> */}
                                        //     </td>
                                        // </tr>
                                    )}
                                </>
                            ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
}
