import api from "../axios/axios-config";

export const getSuppliers = async () => {
  try {
    let response = await api.get("supplier/listOfSupplier");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createSupplier = async (data) => {
  try {
    let response = await api.post("supplier/createSupplier", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateSupplier = async (supplierId, data) => {
  try {
    let response = await api.post("supplier/updateSupplier", {
      supplierId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
