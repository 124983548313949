import { authorizedRequests } from "../axios/axios-config";

const getPrograms = async () => {
	const response = await authorizedRequests({
		url: "program/listPrograms",
		method: "get",
	});
	return response;
};

const createProgram = async (data) => {
	const response = await authorizedRequests({
		url: "program/createProgram",
		method: "post",
		data,
	});
	return response;
};

const updateProgram = async (data) => {
	const response = await authorizedRequests({
		url: "program/updateProgram",
		method: "post",
		data,
	});
	return response;
};

const getProgramById = async (id) => {
	const response = await authorizedRequests({
		url: "program/getProgramById",
		method: "post",
		data: {
			id: id,
		},
	});
	return response;
};

export { getPrograms, createProgram, updateProgram, getProgramById };
