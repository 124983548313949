import React from "react";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";
import SelectComponent from "../../SharedComponents/SelectComponent";

let options = [
  {
    label: "Mode of delivery",
    value: "Mode of delivery",
  },
  {
    label: "Dosage strength (units)",
    value: "Dosage strength (units)",
  },
  {
    label: "Frequency",
    value: "Frequency",
  },
  {
    label: "Duration units",
    value: "Duration units",
  },
];

export default function AddEditModel({
  show,
  data,
  onChange,
  setIsOpen,
  onAction,
  isUpdate,
}) {
  return (
    <Modal size="md" centered show={show}>
      <div className="flex p-2 ">
        <div className="font-18 text-bold">Prescription Sheet Contstants</div>
        <Form.Group>
          <div className="h5 mt-3">Title *</div>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Write..."
              value={data?.title}
              onChange={(e) =>
                onChange((prev) => ({
                  ...prev,
                  title: e.target.value,
                }))
              }
            />
          </InputGroup>
        </Form.Group>
        <Form.Group>
          <div className="h5">Select Type *</div>
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={data?.select_type}
            onChange={(e) =>
              onChange((prev) => ({
                ...prev,
                select_type: e?.value,
              }))
            }
            options={options}
            name={"location"}
          />
        </Form.Group>
        <div className="d-grid gap-2">
          <Button className="" onClick={() => onAction()}>
            {isUpdate ? "Update" : "Save"}
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              setIsOpen(false);
              onChange({
                title: "",
                select_type: "",
              });
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
}
