import api from "../axios/axios-config";

export const getListIpAccess = async () => {
  try {
    let response = await api.get("ipAccess/listOfIpAccess");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createIpAccess = async (data) => {
  try {
    let response = await api.post("ipAccess/createIpAccess", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateIpAccess = async (ipAccessId, data) => {
  try {
    let response = await api.post("ipAccess/updateIpAccess", {
      ipAccessId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
