import axios from "axios";

let baseURL = process.env.REACT_APP_BASE_URI;
if (baseURL[baseURL?.length - 1] !== "/") {
  baseURL = baseURL + "/";
}

const customAxios = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

customAxios.interceptors.request.use(
  (request) => {
    request.headers.Authorization = localStorage.getItem("token");
    request.headers.orgid = localStorage.getItem("orgid");
    return request;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export const unAuthorizesRequests = async (config) => {
  const { url, method, data } = config;
  if (method.toLowerCase() == "get" && data) {
    return { error: "can't use data argument for get request" };
  }
  try {
    const res = await axios({
      method,
      url: `${baseURL}${url}`,
      data,
    });
    return { result: res.data };
  } catch (error) {
    return { error: error?.response?.data ?? error };
  }
};

export const authorizedRequests = async (config) => {
  const { url, method, data, params, token } = config;
  const user_token = localStorage.getItem("token");
  const orgid = localStorage.getItem("orgid");
  if (!user_token && !token)
    return { error: { message: "Failed to send authorized request" } };
  if (method.toLowerCase() == "get" && data) {
    return {
      error: { message: "can't use data argument for get request" },
    };
  }

  const bearer_token = token ?? user_token;
  try {
    const res = await axios({
      method,
      url: `${baseURL}${url}`,
      data,
      headers: {
        Authorization: bearer_token,
        orgid: orgid,
      },
      params: params,
    });
    return { result: res.data };
  } catch (error) {
    return { error: error?.response?.data ?? error };
  }
};

export default customAxios;
