import React, { useState, useEffect, useMemo } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";

import { getPatients } from "../../../apis/patients";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { permissionTypes } from "../../../helperFunctions/routes";
import {
	customDateSort,
	customTimeSort,
  customStringSort,
  getFormatedTime
} from "../../../helperFunctions/utils";
import {
	setData,
} from "../../../redux/slices/StaffFormsSlices";

import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";

const ModalWrapper = styled.div`
  .title {
    font-weight: bold;
  }

  .filter-wrapper {
    > div {
      display: inline-block;
      width: 200px;
    }

    .select-wrapper {
      width: 250px;
    }

    > div:last-child {
      width: auto;
    }
  }
`;

const optionStatus = [
  {
    value: "",
    label: "All",
  },
  {
    value: "Planned",
    label: "Planned",
  },
  {
    value: "Confirmed",
    label: "Confirmed",
  },
  {
    value: "Cancelled",
    label: "Cancelled",
  },
  {
    value: "Not Show",
    label: "Not Show",
  },
]

export default function ModalSearchAppointment({ 
  open,
  setOpen,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [patient, setPatient] = useState("");
  const [status, setStatus] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [listPatient, setListPatient] = useState([]);
  const [originalAppointments, setOriginalAppointments] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [itemToDel, setItemToDel] = useState();
	const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (open === true) {
      setAppointments([]);
      setOriginalAppointments([]);
      setPatient("");
      setStatus(null);
      setStartDate(null);
      setEndDate(null);
    }

    // getPatients()
    // .then((res) => {
    //   if (Array.isArray(res?.list)) {
		// 		setListPatient(res?.list?.map((x) => (
    //       {
    //         value: x._id,
    //         label: x.patientProfile?.fullName ?? ""
    //       }
    //     )));
		// 	}
    // })
  }, [open]);

  const filterAppointments = () => {
    if (patient === "") {
      toastify("Please select patient first", "error");
      return;
    }

    if (startDate !== null && endDate !== null) {
      if (endDate < startDate) {
        toastify("End Date cannot be less than Start Date", "error");
        return;
      }
    }

    authorizedRequests({
			url: "appointment/filterAppointmentRosterings",
			method: "get",
			params: {
        startDate: startDate,
        endDate: endDate,
        patient: patient,
        status: status?.value ?? ""
			}
    })
    .then((res) => {
      toastify("Success Fetch Appointments", "success");
      if (res.result?.data) {
        let tempArr = res.result?.data?.map((app) => {
          return {
            id: app?._id,
            time:
              (getFormatedTime(app?.startTime) ?? "") +
              "-" +
              (getFormatedTime(app?.endTime) ?? ""),
            patientName: app?.patientName ? app?.patientName : app?.patient?.patientProfile?.fullName,
            remarks: app?.remarks,
            products: app?.products,
            // treatments: app?.treatments,
            // staff: app?.staff?.map((o) => {
            //   return o?.username;
            // }),
            tag: app?.tag?.map(x => x.name),
            otherTags: app?.otherTags?.map(x => x.name),
            color: app?.tag?.map(x => x.color),
            date: new Date(app?.date).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }),
            status: app?.status,
            isWaitingList: app?.patientName ? true : false
          };
        });
      console.log("DEBUG", tempArr)
        tempArr = tempArr?.reverse();
        setAppointments(tempArr);
        setOriginalAppointments(res.result?.data.reverse());
      }
    })
    .catch((err) => {
      toastify("There are no records", "error");
    })
  }

  const onRowEdit = async (id, index) => {
    var tempArr = originalAppointments[index];
		const valueObj = {
			patient: tempArr.patient._id,
			branch: tempArr.branch._id,
			date: tempArr.date,
			location: tempArr.location,
			startTime: tempArr.startTime,
			endTime: tempArr.endTime,
			products: tempArr.products.map((o) => {
				return o._id;
			}),
			staff: [
				...tempArr.staff.map((o) => {
					return o._id;
				}),
			],
			tag: tempArr.tag,
			otherTags: tempArr.otherTags,
			status: tempArr.status,
			remarks: tempArr.remarks,
			formStatus: "Edit",
			editId: id,
		};
    console.log("DEBUG", valueObj)
		dispatch(
			setData({
				parent: "appointmentForm",
				value: valueObj,
			}),
		);
		navigate("/add-appointment", { state: { valueObj } });
	};

  const onRowEditWaitingList = (index) => {
    var tempArr = originalAppointments[index];

    navigate("/add-waiting", {
			state: {
				data: {
					patientName: tempArr.patientName,
					gender: tempArr.gender,
					date: tempArr.date,
					remarks: tempArr.remarks,
					contactNumber: tempArr.contactNumber,
					dataId: tempArr.id,
					startTime: tempArr.startTime,
					endTime: tempArr.endTime,
				},
			},
		});
  }
	
	const onRowDelete = async (id) => {
		const { result, error } = await authorizedRequests({
			url: "appointment/updateAppointment",
			method: "post",
			data: {
				dataId: id,
				isDeleted: true,
			},
		});
		if (error) {
			toastify(error.message ?? "unknown error occured", "error");
			return;
		}

    toastify("Appointment deleted succesfully", "success");
    filterAppointments();
	};

  const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Date</span>
				</div>
			),
			selector: (row) => row.date,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "date"),
		},
		{
			name: "Time",
			selector: (row) => row.time,
			wrap: true,
			width: "200px",
			sortable: true,
			sortFunction: (a, b) => customTimeSort(a, b, "time"),
		},
    {
			name: "Patient",
			selector: (row) => row.patientName,
			wrap: true,
			width: "200px",
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "patientName"),
		},
		{
			name: (
				<>
					<div className="d-flex align-items-center" id="ovly2">
						<span className="me-2">Remarks</span>
					</div>
				</>
			),
			selector: (row) => row.remarks,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "remarks"),
		},
		// {
		// 	name: (
		// 		<div className="d-flex align-items-center" id="ovly2">
		// 			<span className="me-2">Staff</span>
		// 		</div>
		// 	),
		// 	selector: (row) => row.staffMembers,
		// 	wrap: true,
		// 	width: "160px",
		// },
		{
			name: "Tag",
			selector: (row) => row.tagButtons,
			wrap: true,
			width: "160px",
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Status</span>
				</div>
			),
			selector: (row) => row.statusButtons,
			wrap: true,
			width: "160px",
		},
		{
			name: "Action",
			selector: (row) => row.action,
			width: "180px",
		},
  ];
  
  const sourceData = useMemo(() => {
    return appointments.map((e, index) => {
      return {
        index: index,
        date: e?.date,
        time: e?.time,
        patient: e?.patientName,
        patientName: e?.patientName,
        remarks: e?.remarks,
        staff: e?.staff?.join(","),
        tag: e?.tag,
        status: e.status,
        statusButtons: (
          <>
            {
              !e.isWaitingList ? (
                <div
                  className={`${e.status == "Confirmed"
                    ? "green-rounded-button"
                    : e.status == "Planned"
                      ? "brown-rounded-button"
                      : "red-rounded-button"
                    }`}
                  style={{ width: 110 }}
                >
                  {e.status}
                </div>
              ) : (
                ""
              )
            }
          </>
        ),
        tagButtons: (
          <div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
            {
              e?.tag?.map((tagName, index) => (
                <div
                  key={index}
                  className="rounded-button"
                  style={{ width: 110, background: e?.color[index], marginBottom: "5px", color: "white" }}
                >
                  {tagName?.length > 9
                    ? tagName.substring(0, 6) + "..."
                    : tagName}
                </div>
              ))
            }
          </div>
        ),
        staffMembers: (
          <>
            {e?.staff?.map((o) => {
              return <div className="">{o}</div>;
            })}
          </>
        ),
        action: (
          <div className="d-flex ">
            <ComponentWrapepr
              moduleName="Appointment"
              permissionType={permissionTypes.UPDATE}
            >
              <div
                className="cursor-pointer"
                onClick={() => {
                  if (e.isWaitingList) {
                    onRowEditWaitingList(index);
                  } else {
                    onRowEdit(e.id, index);
                  }
                  // editBranch(e);
                }}
              >
                <i className="fa fa-pen"></i>
              </div>
            </ComponentWrapepr>
            <ComponentWrapepr
              moduleName="Appointment"
              permissionType={permissionTypes.DELETE}
            >
              <div
                className="mx-3  cursor-pointer icon-width"
                onClick={() => {
                  setItemToDel(e.id);
                  setOpenModal(true);
                  // onRowDelete(e.id);
                  // setDeleting(e._id);
                  // setShow(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </div>
            </ComponentWrapepr>
          </div>
        ),
      };
    })
  }, [appointments]);

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

  return (
    <React.Fragment>

      <Modal
        centered
        show={open}
        size="xl"
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <span style={{fontWeight: "bold", fontSize: 18}}>
            Appointments
          </span>
        </Modal.Header>
        <Modal.Body className="font-18">
          <ModalWrapper>
            <div className="filter-wrapper">
              <div className="me-3 select-wrapper">
                <Form.Control
                  style={{height: "50px"}}
                  placeholder="Patient Name"
                  onChange={(e) => setPatient(e.target.value)}
                  value={patient}
                />
                {/* <SelectComponent
                  singleSelect
                  searchEnabled
                  selectedValue={patient}
                  options={listPatient}
                  onChange={(value) => {
                    setPatient(value)
                  }}
                  name={"patient"}
                /> */}
              </div>
              <div className="me-3">
                <SelectComponent
                  singleSelect
                  searchEnabled
                  selectedValue={status}
                  options={optionStatus}
                  onChange={(value) => {
                    setStatus(value)
                  }}
                  name={"Status"}
                />
              </div>
              <div className="me-3">
                <DateInput
                  value={startDate}
                  placeholder="Start Date"
                  shouldDisableDates={true}
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                />
              </div>
              <div className="me-3">
                <DateInput
                  value={endDate}
                  placeholder="End Date"
                  shouldDisableDates={true}
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                />
              </div>
              <div className="me-3">
                <Button
                  className="addItem"
                  onClick={() => filterAppointments()}
                >
                  Search
                </Button>
              </div>
            </div>

            <div>
              <DataTable
                columns={columns}
                data={sourceData || []}
                pagination
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </ModalWrapper>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        show={openModal}
        onHide={() => {
          setItemToDel(null);
          setOpenModal(!openModal);
        }}
      >
        <Modal.Body className="font-18">
          Confirm Appointment Deletion
          <div className="align-self-end m-3 d-flex">
            <Button
              className="me-3 delete-no ms-auto"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              No
            </Button>
            <Button
              className="delete-yes"
              onClick={() => {
                onRowDelete(itemToDel);
                setOpenModal(false);
              }}
            >
              Yes
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
