import api from "../axios/axios-config";

export const getRefund = async () => {
  try {
    let response = await api.get("refund/listOfRefunds", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createRefund = async (data) => {
  try {
    let response = await api.post("refund/createRefund", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateRefund = async (dataId, data) => {
  try {
    let response = await api.post(
      "refund/updateRefunds",
      {
        dataId,
        ...data,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
