import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProducts, updateProductQuantity } from "../../../../apis/products";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../../helperFunctions/toastify";
import { SearchIcon } from "../../../../assets/images/index";
import { Switch } from "antd";
import { addPackage, updatePackage } from "../../../../apis/packages";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import {
  addAdjustmentStock,
  updateAdjustmentStock,
} from "../../../../apis/adjustmentStock";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import SelectComponent from "../../../SharedComponents/SelectComponent";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";
import { setFormData } from "../../../../redux/slices/commonSlice";

export default function AddEditAdjustmentStock() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;
  const navigate = useNavigate();

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  const [products, setProducts] = useState([]);

  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const [allProducts, setAllProducts] = useState([]);
  const [filteredItem, setFilteredItem] = useState([]);
  const [filteredType, setFilteredType] = useState([]);
  const [itemShow, setItemShow] = useState(false);
  const [typeShow, setTypeShow] = useState(false);
  const [searchType, setSearchType] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [selectedProduct, setSelectedProduct] = useState();
  const [physicalQty, setPhysicalQty] = useState("");
  const [lessQty, setLessQty] = useState("");
  const [moreQty, setMoreQty] = useState("");
  const [remarks, setRemarks] = useState("");
  const [bulkProduct, setBulkProduct] = useState([]);
  const [isFinalized, setIsFinalized] = useState(false);

  const numberRegex = new RegExp(`^[0-9]*$`);

  useEffect(() => {
    if (!state?.stock?._id) {
      formDataChangedRef.current = {
        remarks: remarks,
        isFinalized: false,
        items: bulkProduct
      }
    }
  }, [bulkProduct, remarks]);

  useEffect(() => {
    return () => {
      if (!state?.stock?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.stock?._id) {
      populateOptions({});
      populateData();
    }
  }, []);

  const populateData = async () => {
    const reduxData = formData?.[pathname];
    if (reduxData && !state?.stock?._id) {
      setBulkProduct(reduxData?.items ? reduxData?.items : []);
      setRemarks(reduxData?.remarks ?? "");
      setIsFinalized(reduxData?.isFinalized ?? false);
    } else if (state?.stock?._id) {
      setBulkProduct(state?.stock?.items?.map((item) => (
        {
          qty: item.physicalQty,
          lessQty: item.lessQty,
          moreQty: item.moreQty,
          id: item.product?._id,
          name: item.product?.name
        }
      )));
      setRemarks(state?.stock?.remarks);
      setIsFinalized(state?.stock?.isFinalized);
    }
    // setPhysicalQty(state?.stock?.physicalQty);
    // setRemarks(state?.stock?.remarks);
  };

  const populateOptions = async (dataProduct) => {
    const response = await getProducts();
    if (response?.status !== "Fail") {
      setAllProducts(response);

      setSelectedProduct(
        response.find((e) => e._id === dataProduct?._id)
      );
    } else {
      toastify(response?.message, "error");
    }
  };

  const showDraft = () => {
    if (state?.stock) {
      if (state?.stock?.isFinalized === true) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const removeStock = () => {
    let _products = [...products].filter((e) => e._id !== deleting);
    setProducts(_products);
    setShow(false);
  };
  
  useEffect(() => {
    if (selectedProduct) {
      setSearchItem(selectedProduct?.name);
      setSearchType(selectedProduct?.type.name);
    }
  }, [selectedProduct]);

  useEffect(() => {
    setFilteredType(
      allProducts.filter((e) => e.type.name.includes(searchType))
    );
  }, [searchType]);

  useEffect(() => {
    setFilteredItem(allProducts.filter((e) => e.name.includes(searchItem)));
  }, [searchItem]);

  // useEffect(() => {
  //   if (selectedProduct) {
  //     setDifference(physicalQty - selectedProduct.qty);
  //   } else {
  //     setDifference("N/A");
  //   }
  // }, [physicalQty]);

  useEffect(() => {
    if (state?.stock) {
      populateOptions(state?.stock?.product);
      populateData();
    }
  }, []);

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.stock?._id,
      type: "Stock",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);


  const columns = [
    {
      name: "Item Name",
      selector: (row) => row.name,
    },
    {
      name: "Physical Quantity",
      selector: (row) => row.qty?.toLocaleString(),
      wrap: true,
    },
    {
      name: "Less Quantity",
      selector: (row) => row.lessQty?.toLocaleString(),
      wrap: true,
    },
    {
      name: "More Quantity",
      selector: (row) => row.moreQty?.toLocaleString(),
      wrap: true,
    },
    {
      ...(!isFinalized && {
        name: "Action",
        cell: (row) => {
          return (
            <>
              <div className="d-flex">
                <div
                  className="mx-3 cursor-pointer icon-width"
                  onClick={() => {
                    setSelectedProduct(
                      (filteredItem.length > 0 ? filteredItem : allProducts).find(
                        (e) => e?._id == row?.id
                      )
                    );
                    setPhysicalQty(row?.qty);
                    setLessQty(row?.lessQty);
                    setMoreQty(row?.moreQty);
                    setSearchItem(row?.name);
                  }}
                >
                  <i className="fa fa-pen"></i>
                </div>
                <div
                  className="mx-3 cursor-pointer icon-width"
                  onClick={() =>
                    setBulkProduct(bulkProduct?.filter((a) => a?.id != row?.id))
                  }
                >
                  <i className="fa fa-trash"></i>
                </div>
              </div>
            </>
          );
        },
      }),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            Bulk Adjustment Stock
          </h1>
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex">
                <Form.Group className="col-3">
                  <Form.Label className="headings2">Item Name *</Form.Label>
                  {/* <InputGroup
                    className="mb-3 p-0 search-group"
                    onClick={() => {
                      setItemShow(!itemShow);
                      if (!itemShow) {
                        setTypeShow(false);
                      }
                    }}
                  >
                    <Form.Control
                      placeholder="Select"
                      value={searchItem}
                      onChange={(e) => setSearchItem(e.target.value)}
                    />
                    <Button
                      onClick={() => {
                        const foundProduct = filteredItem.find((e) => e.name === searchItem);
                        if (foundProduct) {
                          setSelectedProduct(foundProduct);
                          setPhysicalQty(foundProduct.qty); 
                        } else {
                          setSelectedProduct(null);
                          setPhysicalQty('');
                        }
                      }}
                    >
                      <img src={SearchIcon} alt="Search" />
                    </Button>
                  </InputGroup> */}
                  {/* {itemShow && (
                    <div className="searchbox-stock col-3">
                      {(filteredItem.length > 0
                        ? filteredItem
                        : allProducts
                      ).map((e) => (
                        <div
                          onClick={() => {
                            setSearchItem(e.name);
                            setItemShow(false);
                            setSelectedProduct(e);
                            setPhysicalQty(e.qty);
                          }}
                        >
                          {e.name}
                        </div>
                      ))}
                    </div>
                  )} */}
                     <SelectComponent
                        key={searchItem}
                        searchEnabled
                        singleSelect
                        selectedValue={searchItem} // Use the selected value
                        onChange={(e) => {
                          const foundProduct = allProducts.find((product) => product.name === e.value);
                          console.log('product found', foundProduct);
                          setSearchItem(e.value); // Set the selected code
                          setSelectedProduct(foundProduct);
                          setPhysicalQty(foundProduct.qty); 
                        }}

                        options={
                          allProducts?.length > 0
                            ? [
                                ...allProducts.map((o) => {
                                  return {
                                    value: o.name,
                                    label: o.name,
                                  };
                                }),
                              ]
                            : []
                        }
                      />
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-2">
                  <Form.Label className="headings2">Physical Qty</Form.Label>
                  <InputGroup className="mb-3 disabled-input">
                    <Form.Control
                      placeholder="Write..."
                      value={physicalQty}
                      disabled={true}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-2">
                  <Form.Label className="headings2">Less Qty</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => {
                        if (
                          numberRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setLessQty(e.target.value);
                        }
                      }}
                      value={lessQty}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-2">
                  <Form.Label className="headings2">More Qty</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => {
                        if (
                          numberRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setMoreQty(e.target.value);
                        }
                      }}
                      value={moreQty}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            {!isFinalized && (
              <Button
                className="addItem"
                onClick={() => {
                  if (!selectedProduct?.name) {
                    toastify("Product is required", "error");
                    return;
                  }
                  let exist = bulkProduct?.find(
                    (a) => a?.id == selectedProduct?._id
                  );
                  if (exist) {
                    setBulkProduct((prv) =>
                      prv.map((item) =>
                        item.id === selectedProduct?._id
                          ? { ...item, qty: physicalQty, lessQty: lessQty, moreQty: moreQty }
                          : item
                      )
                    );
                  } else {
                    setBulkProduct((prv) => [
                      ...prv,
                      {
                        id: selectedProduct?._id,
                        name: selectedProduct?.name,
                        qty: physicalQty || 0,
                        lessQty: lessQty || 0,
                        moreQty: moreQty || 0,
                      },
                    ]);
                  }

                  setSearchItem("");
                  setPhysicalQty("");
                  setLessQty("");
                  setMoreQty("");
                  setSelectedProduct(null);
                }}
              >
                {
                  bulkProduct?.find(
                    (a) => a?.id == selectedProduct?._id
                  ) ? "Save" : "Add"
                }
              </Button>
            )}
          </div>
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={bulkProduct || []}
            conditionalRowStyles={conditionalRowStyles}
          />
          <div className="addEdit">
            <div>
              <Form.Group className="col-5">
                <Form.Label className="headings2">Remarks</Form.Label>
                <InputGroup>
                  <Form.Control
                    as="textarea"
                    placeholder="Write..."
                    style={{ resize: "none" }}
                    onChange={(e) => setRemarks(e.target.value)}
                    value={remarks}
                    className="large-text-area"
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div className="d-flex mt-4">
              {!isFinalized && (
                <>
                  <Button
                    className="addItem"
                    onClick={() => {
                      if (bulkProduct.length === 0) {
                        toastify("Please add at least one item", "error");
                        return;
                      }
                      const submittedData = {
                        remarks: remarks,
                        isFinalized: false,
                        items: bulkProduct.map((item) => (
                          {
                            ...item,
                            physicalQty: item.qty,
                            product: item.id
                          }
                        ))
                      }
                      if (state?.stock?._id) {
                        submittedData.dataId = state?.stock?._id;
                        updateAdjustmentStock(submittedData.dataId, submittedData).then((res) => {
                          toastify("Stock Updated Succesfully", "success");
                          navigate(-1);
                        })
                      } else {
                        addAdjustmentStock(submittedData).then((res) => {
                          formDataChangedRef.current = null;
                          dispatch(setFormData({
                            ...formData,
                            [pathname]: null
                          }));

                          toastify("Stock Created Succesfully", "success");
                          navigate(-1);
                        })
                      }
                    }}
                  >
                    Save As Draft
                  </Button>
                  <Button
                    className="addItem"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      if (bulkProduct.length === 0) {
                        toastify("Please add at least one item", "error");
                        return;
                      }
                      const submittedData = {
                        remarks: remarks,
                        items: bulkProduct.map((item) => (
                          {
                            ...item,
                            physicalQty: item.qty,
                            product: item.id
                          }
                        ))
                      }
                      if (state?.stock?._id) {
                        submittedData._id = state?.stock?._id;
                      }
                      updateProductQuantity(submittedData).then((res) => {
                        formDataChangedRef.current = null;
                        dispatch(setFormData({
                          ...formData,
                          [pathname]: null
                        }));
                        
                        toastify("Stock Finalize Succesfully", "success");
                        navigate(-1);
                      })
                    }}
                  >
                    Finalize
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
