import React from "react";

export default function CustomTable({
  data,
  disabled,
  editProduct,
  deleteProduct,
  setEditingProduct,
}) {
  console.log("Table Data", { data });
  return (
    <div style={{ overflowX: "scroll" }} className="my-4">
      <div className="custom-table-head">
        <div>
          <p className="cw-180">Item Name</p>
        </div>
        <div>
          <p className="cw-180">Item Type</p>
        </div>
        <div>
          <p className="cw-180">UOM</p>
        </div>
        <div>
          <p className="cw-160">Qty</p>
        </div>
        <div>
          <p className="cw-160">Bonus Qty</p>
        </div>
        <div>
          <p className="cw-160">Unit Cost</p>
        </div>
        <div>
          <p className="cw-160">Total Cost</p>
        </div>
        {/* <div>
          <p className="cw-160">Action</p>
        </div> */}
      </div>
      {data?.map(({ product, recivedQty, unitCost, bonusQty }, index) => (
        <div className="custom-table-cells">
          <div>
            <p className="cw-180">{product.name}</p>
          </div>
          <div>
            <p className="cw-180">{product.type?.name}</p>
          </div>
          <div>
            <p className="cw-180">{product.uom?.name}</p>
          </div>
          <div>
            <p className="cw-160">{recivedQty}</p>
          </div>
          <div>
            <p className="cw-160">{bonusQty || 0}</p>
          </div>
          <div>
            <p className="cw-160">${unitCost}</p>
          </div>
          <div>
            <p className="cw-160">${unitCost * Number(recivedQty)}</p>
          </div>
          {/* <div className="d-flex cw-160">
            {
              !disabled && (
                <React.Fragment>
                  <div
                    className="mx-3  cursor-pointer icon-width"
                    onClick={() => {
                      editProduct(product._id, index);
                      setEditingProduct(index);
                    }}
                  >
                    <i className="fa fa-pen"></i>
                  </div>
                  <div
                    className="mx-3  cursor-pointer icon-width"
                    onClick={() => {
                      deleteProduct(product._id);
                    }}
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                </React.Fragment>
              )
            }
          </div> */}
        </div>
      ))}
    </div>
  );
}
