import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

export default function ManualQuestion({ 
	label,
	value,
	onChange
}) {
	return (
		<Col className="mb-3" md={3} key={label}>
			<div>
				<div className="question-header">
					{label}
				</div>
				<div className="pt-2">
					<Form.Control
						as="textarea"
						rows={1}
						value={value}
						onChange={(e) => {
							onChange(e.target.value)
						}}
						className="question-input"
						style={{ resize: "none" }}
					/>
				</div>
			</div>
		</Col>
	);
}
