import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { setData } from "../../../redux/slices/StaffFormsSlices";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import FilterComponent from "../../SharedComponents/FilterComponent";
import { SearchIcon } from "../../../assets/images/index";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { updateAppointments } from "../../../apis/appointment";
import {
	customDateSort,
	customStringSort,
	customTimeSort,
	exportData,
	getFormatedTime,
} from "../../../helperFunctions/utils";
import DateInput from "../../SharedComponents/DateInput";

export default function AppointmentReminder() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const pathname = location.pathname;
	const { tableSearch } = useSelector((state) => state.commonData);
	const fieldPathname = pathname.replace("/", "");

	const [formData, setFormData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [selectedAppointments, setSelectedAppointments] = useState([]);
	const [template, setTemplate] = useState();
	const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
	const [filteredItemName, setFilteredItemName] = useState({});
	const [selectedVia, setSelectedVia] = useState();

	const [previewtemplate, setPreviewTemplate] = useState(false);
	const [searchItem, setSearchItem] = useState("");
	const [itemNameShow, setItemNameShow] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [appointmentToEdit, setAppointmenttoEdit] = useState();
	const [newStatus, setNewStatus] = useState();

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalDoc, setTotalDoc] = useState(0);
	const [stateDate, setStateDate] = useState(tableSearch[fieldPathname]?.currentDate ? tableSearch[fieldPathname]?.currentDate : new Date());

	const { appointmentReminderData, reminderTemplates } = useSelector(
		(state) => state.staffForm,
	);

	const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
				currentDate: value
			}
		}))
  	}

	const getDefaultFilteredData = (values) => {
		let list = [...values];
		const fieldPathname = pathname.replace("/", "");
	
		if (tableSearch[fieldPathname]) {
		  if (tableSearch[fieldPathname].patientName) {
			list = values.filter(v => v.patientName?.toLowerCase()?.includes(tableSearch[fieldPathname].patientName.toLowerCase()));
		  }
		  if (tableSearch[fieldPathname].contact) {
			list = values.filter(v => v.contact?.toLowerCase()?.includes(tableSearch[fieldPathname].contact?.toLowerCase()));
		  }
		  if (tableSearch[fieldPathname].email) {
			list = values.filter(v => v.email?.toLowerCase()?.includes(tableSearch[fieldPathname].email?.toLowerCase()));
		  }
		}
	
		return list;
	  }

	const getList = async () => {
		// const params = {
		// 	skip: page === 0 ? page : (page - 1) * rowsPerPage,
      	// 	limit: page === 0 ? rowsPerPage : rowsPerPage,
		// }
		const params = {
			date: stateDate
		}
		const { result, error } = await authorizedRequests({
			url: "appointment/listOfReminders",
			method: "get",
			params: params
		});
		if (error) {
			toastify(error.message ?? "unknown error occured", "error");
			return;
		}

		setTotalDoc(result.data?.totalDoc);

		var tempArr = result.data?.list?.map((app, i) => {
			return {
				id: app._id,
				patientName: app?.patient?.patientProfile?.fullName,
				contact: app?.patient?.patientProfile?.contactNo,
				email: app?.patient?.patientProfile?.email,
				date: new Date(app.date).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
				time:
					(getFormatedTime(app?.startTime) ?? "") +
					"-" +
					(getFormatedTime(app?.endTime) ?? ""),
				remarks: app?.remarks,
				tag: app.tag?.map(x => x.name),
				color: app.tag?.map(x => x.color),
				status: app.isReminder ? "Confirmed" : "Planned",
				reminderCount: app?.reminderCount || 0,
				reminder:
					app.reminder == "email"
						? "Email"
						: app.reminder == "phone_number"
						? "Whatsapp"
						: "",
			};
		});

		setFormData(result.data?.list);
		dispatch(
			setData({
				parent: "appointmentReminderData",
				value: tempArr,
			}),
		);
		return;
	};
	const getReminderTemplates = async () => {
		const { result, error } = await authorizedRequests({
			url: "reminder/listOfReminders",
			method: "get",
		});
		if (error) {
			toastify(error.message ?? "unknown error occured", "error");
			return;
		}
		const tempArr = result?.data?.map((o) => {
			return {
				id: o._id,
				name: o.name,
				message: o.message,
				via: o.via,
				viaDetail: o.viaDetail,
				phoneNumberList:
					o.via === "whatsapp"
						? o?.phoneNumberList
						: o?.emailNumberList,
				isEmail: !(o.via === "whatsapp"),
			};
		});
		dispatch(
			setData({
				parent: "reminderTemplates",
				value: tempArr,
			}),
		);
	};
	const onSave = async () => {
		if (!template) {
			toastify("Please select template", "error");
			return;
		}
		if (selectedAppointments.length < 1) {
			toastify("Please select appointments", "error");
			return;
		}
		const templateDetails = reminderTemplates.find((o) => o.id == template);
		// if (!selectedPhoneNumber) {
		// 	toastify("Please select whatsapp or Email", "error");
		// 	return;
		// }

		const { result, error } = await authorizedRequests({
			url: "appointment/setAppointmentReminder",
			method: "post",
			data: {
				reminderId: template,
				appointmentList: selectedAppointments,
				phoneNumber:
					templateDetails?.via == "whatsapp"
						? selectedPhoneNumber
						: undefined,
				isEmail: !(templateDetails?.via == "whatsapp"),
				email:
					templateDetails?.via == "whatsapp"
						? undefined
						: selectedPhoneNumber,
			},
		});
		if (error) {
			toastify(error.message, "error");
			return;
		} else {
			if (result.data?.results?.length > 0) {
				toastify(
					`Contact number is missing for ${result?.data?.results?.join(
						",",
					)}`,
					"success",
				);
				return;
			}
			toastify("reminder sent successfully", "success");
		}
		// var tempArr = appointmentReminderData;
		getList();
		// tempArr = tempArr.map((o) => {
		// 	if (selectedAppointments.indexOf(o.id) !== -1) {
		// 		return { ...o, status: true };
		// 	}
		// 	return o;
		// });
		// dispatch(
		// 	setData({
		// 		parent: "appointmentReminderData",
		// 		value: tempArr,
		// 	}),
		// );
		// setSelectedAppointments([]);
	};
	const updateStatus = async () => {
		const response = updateAppointments({
			dataId: appointmentToEdit,
			isReminder: newStatus,
		});
		if (response.status !== "Fail") {
			toastify("Status updated successfully", "success");
			await getList();
			setAppointmenttoEdit();
			setNewStatus();
			setOpenModal(false);
			return;
		}
		toastify("Failed to update reminder status", "error");

		setAppointmenttoEdit();
		setNewStatus();
		setOpenModal(false);
		return;
	};
	useEffect(() => {
		getList();
	}, [stateDate]);

	useEffect(() => {
		getReminderTemplates();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<input
						type={"checkbox"}
						checked={
							selectedAppointments.length > 0 &&
							selectedAppointments.length ==
								appointmentReminderData.length
						}
						onChange={() => {
							if (
								appointmentReminderData.length !==
								selectedAppointments.length
							) {
								const tempArr = appointmentReminderData?.map(
									(o) => {
										return o.id;
									},
								);

								setSelectedAppointments(tempArr);
							} else {
								setSelectedAppointments([]);
							}
						}}
					/>
				</div>
			),
			selector: (row) => row.isChecked,
			wrap: true,
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Patient Name</span>
					{
						<FilterComponent
							type="search"
							array={appointmentReminderData}
							setArray={setFilteredData}
							value="patientName"
							searchValue={tableSearch[fieldPathname]?.patientName}
						/>
					}
				</div>
			),
			selector: (row) => row.patientName,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "patientName"),
			width: "160px",
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Contact No.</span>
					{
						<FilterComponent
							type="search"
							array={appointmentReminderData}
							setArray={setFilteredData}
							value="contact"
							searchValue={tableSearch[fieldPathname]?.contact}
						/>
					}
				</div>
			),
			selector: (row) => row.contact,
			wrap: true,
			width: "160px",
			sortable: true,
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Email</span>
					{
						<FilterComponent
							type="search"
							array={appointmentReminderData}
							setArray={setFilteredData}
							value="email"
							searchValue={tableSearch[fieldPathname]?.email}
						/>
					}
				</div>
			),
			selector: (row) => row.email,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "email"),
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Date</span>
				</div>
			),
			selector: (row) => row.date,
			wrap: true,
			width: "180px",
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "date"),
		},
		{
			name: "Time",
			selector: (row) => row.time,
			wrap: true,
			width: "200px",
			sortable: true,
			sortFunction: (a, b) => customTimeSort(a, b, "time"),
		},
		{
			name: "Remarks",
			selector: (row) => row.remarks,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "remarks"),
		},
		{
			name: "Tag",
			selector: (row) => row.tagButtons,
			wrap: true,
			width: "160px",
		},
		{
			name: "Reminder Count",
			selector: (row) => row.reminderCount,
			wrap: true,
			width: "160px",
		},
		{
			name: "Reminder",
			selector: (row) => row.reminder,
			wrap: true,
			width: "160px",
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Status</span>
					{
						<FilterComponent
							type="radio-search"
							array={appointmentReminderData}
							setArray={setFilteredData}
							message="0 results found for applied filter"
							subArray
							options={[
								{
									id: "Confirmed",
									name: "Confirmed",
								},
								{
									id: "Planned",
									name: "Planned",
								},
							]}
							value="status"
						/>
					}
				</div>
			),
			selector: (row) => row.status,
			wrap: true,
			width: "160px",
		},
		{
			name: "Action",
			selector: (row) => row.action,
			wrap: true,
			// width: "160px",
		},
	];
	const data = getDefaultFilteredData(appointmentReminderData)?.map((e, index) => {
		return {
			index: index,
			reminderCount: e.reminderCount,
			time: e.time,
			isChecked: (
				<>
					<input
						type={"checkbox"}
						checked={selectedAppointments.find((o) => o == e.id)}
						onChange={() => {
							if (selectedAppointments.find((o) => o == e.id)) {
								const tempArr = selectedAppointments.filter(
									(app) => {
										return app !== e.id;
									},
								);
								setSelectedAppointments(tempArr);
							} else {
								const tempArr = selectedAppointments;
								tempArr.push(e.id);

								setSelectedAppointments(tempArr);
							}
						}}
					/>
				</>
			),
			contact: e.contact,
			email: e.email,
			patientName: e.patientName,
			remarks: e.remarks,
			date: e.date,
			reminder: e?.reminder,
			statusText: e?.status,
			status: (
				<>
					<div
						className={`${
							e.status == "Confirmed"
								? "green-rounded-button"
								: "brown-rounded-button"
						}`}
					>
						{e.status}
					</div>
				</>
			),
			tag: e?.tag?.name,
			tagButtons: (
				<div style={{paddingTop: "8px", paddingBottom: "8px"}}>
					{
						e?.tag.map((tagName, index) => (
							<div
								key={index}
								className="rounded-button"
								style={{ width: 110, background: e?.color[index], marginBottom: "5px", color: "white" }}
							>
								{tagName?.length > 9
									? tagName.substring(0, 6) + "..."
									: tagName}
							</div>
						))
					}
				</div>
			),
			action: (
				<div className="d-flex ">
					<div
						className="cursor-pointer"
						onClick={() => {
							setAppointmenttoEdit(e.id);
							setNewStatus(
								e.status == "Confirmed" ? true : false,
							);
							setOpenModal(true);
							// editBranch(e);
						}}
					>
						<i className="fa fa-pen"></i>
					</div>
				</div>
			),
		};
	});
	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];
	return (
		<>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => {
							navigate(-1);
						}}
					></i>
					Appointment Reminder
				</h1>
				<div style={{display: "flex"}}>
					<div className="me-3" style={{width: "200px", display: "inline-block"}}>
						<DateInput
							value={stateDate}
							placeholder="Date"
							shouldDisableDates={true}
							onChange={(e) => {
								setStateDate(e);
								onSubmitRedux(e);
							}}
						/>
					</div>
					<Button
						className="addItem me-3"
						onClick={() => {
							const tempDataArr = data?.map((o) => {
								return {
									"Patient Name": o.patientName,
									"Contact No.": o?.contact,
									Email: o.email,
									Date: o.date,

									Remarks: o.remarks,
									Tags: o.tag,
									"Reminder Count": o?.reminderCount,

									Reminder: o?.reminder,
									Status: o?.statusText,
								};
							});
							exportData(tempDataArr, "Reminders");
						}}
					>
						Export
					</Button>
				</div>
			</div>
			<div className="appointment-reminder">
				<div className="search-container pt-3 px-5 mb-3 d-flex">
					<Form.Group
						className="position-relative"
						style={{ width: "350px" }}
					>
						<InputGroup
							className="mb-3 p-0 search-group"
							onClick={() => {
								setItemNameShow(!itemNameShow);
							}}
						>
							<Form.Control
								placeholder="Select"
								value={searchItem}
								onChange={(e) => {
									setSearchItem(e.target.value);
									const tempArr = reminderTemplates.filter(
										(o) => o.name.includes(e.target.value),
									);
									setFilteredItemName(tempArr);
								}}
							/>
							<Button>
								<img src={SearchIcon} />
							</Button>
						</InputGroup>

						{itemNameShow && (
							<div className="searchbox-packages">
								{(filteredItemName.length > 0
									? filteredItemName
									: reminderTemplates
								).map((e) => (
									<div
										onClick={() => {
											setSearchItem(e.name);
											setItemNameShow(false);
											setSelectedPhoneNumber(null);
											setTemplate(e.id);
										}}
									>
										{e.name}
									</div>
								))}
							</div>
						)}
					</Form.Group>
					<Button
						className="addItem ms-3"
						style={{ height: "45px", width: 100 }}
						onClick={() => {
							if (template) setPreviewTemplate(!previewtemplate);
							else toastify("Please select template", "error");
						}}
					>
						Preview
					</Button>
					<Button
						className="addItem ms-3"
						style={{ height: "45px", width: 100 }}
						onClick={onSave}
					>
						Send
					</Button>
				</div>
				<div className="d-flex mb-3 px-5">
					{template &&
						reminderTemplates?.find((o) => o.id == template)
							?.phoneNumberList?.length > 0 &&
						reminderTemplates
							?.find((o) => o.id == template)
							?.phoneNumberList?.map((num) => {
								return (
									<>
										<Form.Check
											inline
											label={num}
											type={"radio"}
											onClick={() => {
												setSelectedPhoneNumber(num);
											}}
											checked={selectedPhoneNumber == num}
										/>
									</>
								);
							})}
				</div>
				<Modal
					centered
					show={previewtemplate}
					onHide={() => {
						setPreviewTemplate(false);
					}}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							<div className="w-100 text-center">
								Reminder Template Preview
							</div>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{reminderTemplates
							.filter((o) => o.id == template)
							.map((o) => {
								return (
									<>
										<div className="font-400-20">
											{o.name}
										</div>
										<div className="">
											Message: {o.message}
										</div>
										<div className="">Via: {o.via}</div>
										<div className="">
											Via Detail: {o.viaDetail}
										</div>
									</>
								);
							})}
					</Modal.Body>
				</Modal>
				<div className="table-container px-5 ">
					<DataTable
						columns={columns}
						data={data || []}
						pagination
						paginationServer={true} 
						paginationTotalRows={totalDoc}
						customStyles={customStyles}
						conditionalRowStyles={conditionalRowStyles}
						onChangeRowsPerPage={(value) => {
							setRowsPerPage(value);
							// onSubmitRedux(rowsPerPage, value);
						}}
						onChangePage={(value) => {
							setPage(value);
						// onSubmitRedux(page, value);
						}}
						// responsive="true"
					/>
				</div>
				{openModal && (
					<>
						<Modal
							centered
							show={openModal}
							onHide={() => {
								// setItemToDel(null);

								setOpenModal(!openModal);
							}}
						>
							<Modal.Header closeButton>
								<Modal.Title>
									<div className="w-100 text-center">
										Edit Appointment Reminder Status
									</div>
								</Modal.Title>
							</Modal.Header>
							<Modal.Body className="font-18">
								<div className="">
									<Form.Group>
										<Form.Label className="black-label-text required">
											Reminder Status
										</Form.Label>
										<SelectComponent
											singleSelect
											searchEnabled
											nullDisabled
											options={[
												{
													value: false,
													label: "Planned",
												},
												{
													value: true,
													label: "Confirmed",
												},
											]}
											onChange={(e) => {
												setNewStatus(e.value);
											}}
											selectedValue={newStatus}
										/>
									</Form.Group>
									<Button
										className="addItem ms-auto"
										onClick={updateStatus}
									>
										Save
									</Button>
								</div>
							</Modal.Body>
						</Modal>
					</>
				)}
			</div>
		</>
	);
}
