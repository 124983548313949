import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteQuestion, getAllQuestions } from "../../../apis/questionnaire";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

function QuestionnaireSettings() {
	const [questions, setQuestions] = useState([]);
	const [groups, setGroups] = useState([]);
	const navigate = useNavigate();
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const removeQuestion = async () => {
		setShow(false);
		await deleteQuestion(deleting);
		getQuestions();
		toastify("Question deleted successfully!", "success");
	};

	const editQuestion = (e) => {
		navigate("/add-question", {
			state: {
				id: e,
			},
		});
	};

	const getQuestions = async () => {
		const response = await getAllQuestions();
		if (response?.status !== "Fail") {
			let arrRes = [];
			setGroups(response.data?.map(x => (
				{
					label: x._id,
					value: x._id
				}
			)));
			response.data?.map((resQuestion) => {
				arrRes = arrRes.concat(resQuestion.data);
			})
			setQuestions(arrRes);
		} else {
			if (response?.message === "No data Found") {
				setQuestions([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	useEffect(() => {
		getQuestions();
	}, []);

	const addQuestion = () => {
		navigate("/add-question");
	};

	const columns = [
		{
			name: (
				<div
					className="d-flex align-items-center justify-content-center"
					id="ovly9"
				>
					<span className="me-2">Name</span>
					{
						<FilterComponent
							type="search"
							array={questions}
							setArray={setFilteredData}
							value="QName"
						/>
					}
				</div>
			),
			selector: (row) => row.question,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "question"),
		},
		{
			name: (
				<div
					className="d-flex align-items-center justify-content-center"
				>
					<span className="me-2">Group</span>
					{
						<FilterComponent
							type="search"
							array={questions}
							setArray={setFilteredData}
							value="QName"
						/>
					}
				</div>
			),
			selector: (row) => row.group,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "group"),
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly10">
					<span className="me-2">Description</span>
					{
						<FilterComponent
							type="radio"
							array={questions}
							setArray={setFilteredData}
							options={["Yes/No", "Multiple Indicators", "Text"]}
							value="QDescription"
						/>
					}
				</div>
			),
			selector: (row) => {
				return row.questionType;
			},
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "questionType"),
		},
		{
			name: (
				<div
					className="d-flex align-items-center justify-content-center"
				>
					<span className="me-2">Size</span>
				</div>
			),
			selector: (row) => row.size,
			wrap: true,
		},
		{
			id: "orderIndex",
			name: (
				<div
					className="d-flex align-items-center justify-content-center"
				>
					<span className="me-2">Order</span>
				</div>
			),
			selector: (row) => row.orderIndex,
			sortable: true,
			wrap: true,
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly11">
					<span className="me-2">isActive</span>
					{
						<FilterComponent
							type="radio"
							array={questions}
							setArray={setFilteredData}
							options={["True", "False"]}
							value="QActive"
						/>
					}
				</div>
			),
			selector: (row) => row.isActiveStatus,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "isActiveStatus"),
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? questions : filteredData)?.map(
		(e, index) => {
			return {
				index: index,
				id: e._id,
				question: e.question,
				questionType: e.questionType,
				group: e.group,
				groups: groups,
				size: e.size,
				orderIndex: e.orderIndex,
				isActive: e.isActive,
				isActiveStatus: e.isActive ? "True" : "False",
				action: (
					<div className="d-flex">
						<div
							className="mx-3 cursor-pointer icon-width"
							onClick={() => {
								editQuestion(e._id);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3 cursor-pointer icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Questionnaire Setting</h1>
					<div className="d-flex">
						<Button className="addItem" onClick={addQuestion}>
							+ Add New
						</Button>
					</div>
				</div>

				<DeleteModal
					content="Confirm Question Deletion?"
					onYes={removeQuestion}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
					defaultSortFieldId="orderIndex"
					defaultSortAsc={true}
				/>
			</div>
		</>
	);
}

export default QuestionnaireSettings;
