import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import { authorizedRequests } from '../../../axios/axios-config';
import { toastify } from '../../../helperFunctions/toastify';

function ResetPassword() {
	// const [confirmPassword, setConfirmPassword] = useState();
	const passwordRef = useRef();
	const confirmPasswordRef = useRef();

	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const password = passwordRef.current.value;
		const confirmPassword = confirmPasswordRef.current.value;

		if (password !== confirmPassword) {
			toastify("Passowrd don't match", 'error');
			return;
		} else if (!password) {
			toastify('Please Enter a valid password', 'error');
			return;
		}
		try {
			const { result, error } = await authorizedRequests({
				url: 'auth/resetpassword',
				method: 'Post',
				data: {
					password: password,
				},
				token: JSON.parse(localStorage.getItem('forgot_user')).token,
			});
			if (error) {
				toastify(error?.message ?? 'Unknown Error Occured', 'error');

				return;
			}
			toastify(error?.message ?? 'Password Reset Successfuly', 'success');
			localStorage.removeItem('forgot_user');
			navigate('/');
		} catch (error) {
			toastify(error?.message ?? 'Unknown Error Occured', 'error');
		}
	};

	return (
		<div className='forgetPassword'>
			<div className='row mx-0 forgetPassword'>
				<div className='col-12 my-auto'>
					<div className='text-center py-5'>
						<img src='/imgs/logo.svg' alt='' />
					</div>
					<div className='row mx-0'>
						<div className='col-md-6 mx-auto'>
							<div className='loginBox'>
								<h3>Forgotten Password</h3>
								<div className='row'>
									<div className='col-md-12 mx-auto'>
										<Form
											className='mt-5'
											onSubmit={handleSubmit}
										>
											<Form.Group
												className='mb-0'
												controlId='formBasicPassword'
											>
												<Form.Label>
													Password
												</Form.Label>
												<InputGroup className='mb-4'>
													<Form.Control
														type='password'
														ref={passwordRef}
														placeholder='Enter your password...'
													/>
													<InputGroup.Text id='basic-addon1'>
														<img
															src='/imgs/u_eye.svg'
															className='cursor-pointer'
															alt=''
														/>
													</InputGroup.Text>
												</InputGroup>
											</Form.Group>
											<Form.Group
												className='mb-0'
												controlId='formBasicPassword'
											>
												<Form.Label>
													Confirm Password
												</Form.Label>
												<InputGroup className='mb-2'>
													<Form.Control
														type='password'
														ref={confirmPasswordRef}
														placeholder='Enter your password...'
													/>
													<InputGroup.Text id='basic-addon1'>
														<img
															src='/imgs/u_eye.svg'
															className='cursor-pointer'
															alt=''
														/>
													</InputGroup.Text>
												</InputGroup>
											</Form.Group>

											<div className='mt-5 mb-3'>
												<Button
													className='loginBtn'
													type='submit'
												>
													Reset Password
												</Button>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ResetPassword;
