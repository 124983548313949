import api from "../axios/axios-config";

export const getProducts = async () => {
  try {
    let response = await api.get("product/listOfProducts");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createProducts = async (data) => {
  console.log({ data });
  try {
    let response = await api.post("product/createProduct", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateProducts = async (dataId, data) => {
  try {
    let response = await api.post("product/updateProduct", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateProductQuantity = async (bulkProduct) => {
  try {
    let response = await api.post("product/updateProductQuantity", {
      bulkProduct,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getReceivingHistory = async (dataId) => {
  try {
    let response = await api.post("recevingGoods/listOfRecevingHistory", {
      dataId,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAdjustmentHistory = async (dataId) => {
  try {
    let response = await api.post(
      "stockAdjustment/listOfStockAdjustmentHistory",
      {
        dataId,
      }
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const migrateProducts = async (data) => {
  try {
    let response = await api.post("migrate/product", data);
    return response?.data?.message;
  } catch (error) {
    return error?.response?.data;
  }
};
