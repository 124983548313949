import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
	getHearAboutUsList,
	updateHearAboutUs,
} from "../../../apis/hearAboutUs";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customDateSort,
	customStringSort,
} from "../../../helperFunctions/utils";

export default function HearAboutUsView() {
	const navigate = useNavigate();
	const [list, setList] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [itemToDel, setItemToDel] = useState();

	const getLists = async () => {
		const { result, error } = await getHearAboutUsList();
		if (error) {
			toastify(error.message, "error");
			return;
		} else {
			setList(result.data);
		}
	};

	const onRowEdit = (e) => {
		navigate("/add-hear-about-us", {
			state: {
				name: e.name,
				id: e._id,
			},
		});
	};

	const onRowDelete = async (id) => {
		const { result, error } = await updateHearAboutUs({
			dataId: id,
			isDeleted: true,
		});
		if (error) {
			toastify("Failed to delete", "error");
			return;
		}
		getLists();
	};
	useEffect(() => {
		getLists();
	}, []);

	const columns = [
		{
			name: "Name",
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Date",
			selector: (row) => row.date,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "date"),
		},
		{
			name: "Action",
			selector: (row) => row.action,
			wrap: true,
		},
	];
	const data = list.map((item) => {
		return {
			id: item?._id,
			name: item.name,
			date: new Date(item?.created_at).toLocaleDateString("en-GB", {
				day: "numeric",
				month: "numeric",
				year: "numeric",
			}),
			action: (
				<>
					<div className="d-flex">
						<div
							className="  cursor-pointer icon-width "
							onClick={() => {
								onRowEdit(item);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="  cursor-pointer icon-width"
							onClick={() => {
								setItemToDel(item._id);
								setOpenModal(true);
								// onRowDelete(e.id, index);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				</>
			),
		};
	});
	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];
	return (
		<div className="container p-0">
			<div className="mainHeading px-4">
				<h1>Hear About Us</h1>
				<Button
					className="addItem"
					onClick={() => {
						navigate("/add-hear-about-us");
					}}
				>
					Add Hear About Us
				</Button>
			</div>
			<DataTable
				columns={columns}
				data={data || []}
				pagination
				customStyles={customStyles}
				conditionalRowStyles={conditionalRowStyles}
			/>
			<Modal
				centered
				show={openModal}
				onHide={() => {
					setItemToDel(null);
					setOpenModal(!openModal);
				}}
			>
				<Modal.Body className="font-18">
					Confirm Hear About Us Deletion
					<div className="align-self-end m-3 d-flex">
						<Button
							className="me-3 delete-no ms-auto"
							onClick={() => {
								setOpenModal(false);
							}}
						>
							No
						</Button>
						<Button
							className="delete-yes"
							onClick={() => {
								onRowDelete(itemToDel);
								setOpenModal(false);
							}}
						>
							Yes
						</Button>
					</div>
				</Modal.Body>
			</Modal>
		</div>
	);
}
