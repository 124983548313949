import { authorizedRequests } from "../axios/axios-config";

export const addMailchimpTemplate = async (body) => {
	const response = await authorizedRequests({
		url: "mailchimp/createTemplate",
		method: "post",
		data: body,
	});
	return response;
};

export const getMailchimpTemplates = async () => {
	const response = await authorizedRequests({
		url: "mailchimp/listTemplates",
		method: "get",
	});
	return response;
};

export const sendMail = async (body) => {
	const response = await authorizedRequests({
		url: "mailchimp/createCampaign",
		method: "post",
		data: body,
	});
	return response;
};
