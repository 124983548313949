import { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddCategoryReport() {
  const navigate = useNavigate();
  const location = useLocation();

  const [reminderTemplateForm, setReminderTemplateForm] = useState({
    name: null,
    orderId: null,
    formStatus: "New",
  });

  const handleInputs = (e) => {
    setReminderTemplateForm({
      ...reminderTemplateForm,
      [e.target.name]: e.target.value,
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (!reminderTemplateForm.name) {
      toastify("Name is missing", "error");
      return;
    }
    if (!reminderTemplateForm.orderId) {
      toastify("Message is missing", "error");
      return;
    }

    if (reminderTemplateForm.formStatus === "New") {
      const { result, error } = await authorizedRequests({
        url: "category/createCategory",
        method: "post",
        data: reminderTemplateForm,
      });

      console.log("result...", result);
      if (result?.status === "Success") {
        toastify("Added succesfully", "success");
        navigate("/category-report");
      } else {
        if (error) {
          toastify(error.message ?? "Unknown error occured", "error");
          return;
        }
      }
    } else {
      const { result, error } = await authorizedRequests({
        url: "category/updateCategory",
        method: "post",
        data: reminderTemplateForm,
      });

      if (error) {
        toastify(error.message ?? "Unknown error occured", "error");
        return;
      }

      toastify("Added succesfully", "success");

      navigate("/category-report");
    }
  };

  useEffect(() => {
    if (location?.state?.valueObj) {
      setReminderTemplateForm(location?.state?.valueObj);
    }
  }, [location?.state]);

  console.log("reminderTemplateForm...", reminderTemplateForm);

  return (
    <>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => {
              navigate("/category-report");
            }}
          ></i>
          {`${reminderTemplateForm == "New" ? "Add " : "Edit "}`}
          Category Report
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text required">
                    Name
                  </Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name="name"
                      placeholder="Write..."
                      value={reminderTemplateForm?.name}
                      onChange={handleInputs}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text required">
                    Order
                  </Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name="orderId"
                      type="number"
                      placeholder="Write..."
                      onChange={handleInputs}
                      value={reminderTemplateForm?.orderId}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <Button
              className="addItem mt-2"
              style={{ width: "250px" }}
              onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
