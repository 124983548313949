export const checkPermission = (userData, parent, child) => {
  let perms = userData?.department?.permissions?.find(
    (e) => e.moduleName === parent
  );

  if (perms?.permissionsList?.includes(child)) {
    return true;
  } else {
    return false;
  }
};
