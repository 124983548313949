import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { addKolOption, updateKolOption } from "../../../apis/optionKol";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddEditOptionKol() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const populateData = async () => {
    setName(state?.kol?.name);
    setDescription(state?.kol?.description);
  };

  const saveKol = async () => {
    if (name) {
      if (!state?.kol) {
        const response = await addKolOption(name, description);
        if (response?.status !== "Fail") {
          toastify("KOL Option added successfully!", "success");
          navigate("/option-kol");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateKolOption(
          state?.kol?._id,
          name,
          description
        );
        if (response?.status !== "Fail") {
          toastify("KOL Option updated successfully!", "success");
          navigate("/option-kol");
        } else {
          toastify(response?.message, "error");
        }
      }
    } else {
      missingPopup("Name");
    }
  };

  useEffect(() => {
    if (state?.kol) populateData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.kol ? "Edit " : "New "}`}
          KOL/Channel
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      rows={4}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveKol}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
