import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { modifyPatientEC } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import { setPatientData } from "../../../redux/slices/patientSlice";

export default function TabEmergencyContact({ }) {
	const dispatch = useDispatch();
	const patientData = useSelector((state) => state.patientData.value);

	const [name, setName] = useState("");
	const [relation, setRelation] = useState("");
	const [contact, setContact] = useState("");
	const contactRegex = new RegExp("^[0-9]+[0-9]*$");

	const savePatient = async () => {
		const response = await modifyPatientEC(
			patientData._id,
			name,
			relation,
			contact,
		);
		if (response?.status !== "Fail") {
			dispatch(setPatientData(response));
			toastify("Emergency contact successfully updated!", "success");
			// setPage("medicalInfo");
		} else {
			toastify(response?.message, "error");
		}
	};

	const populateData = () => {
		setContact(patientData?.emergencyContact?.contactNo);
		setRelation(patientData?.emergencyContact?.relation);
		setName(patientData?.emergencyContact?.name);
	};

	useEffect(() => {
		if (patientData?.emergencyContact) {
			populateData();
		}
	}, []);

	return (
		<div>
			<div className="d-flex">
				<Form.Group className="col-5 mt-3">
					<Form.Label className="headings2">Name</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							placeholder="Write..."
							value={name}
							onChange={(e) => setName(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>

				<span className="col-1" />

				<Form.Group className="col-5 mt-3">
					<Form.Label className="headings2">Relation</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							placeholder="Write..."
							value={relation}
							onChange={(e) => setRelation(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>
			</div>

			<Form.Group className="col-5 mt-3">
				<Form.Label className="headings2">Contact No</Form.Label>
				<InputGroup className="mb-3">
					<Form.Control
						placeholder="Write..."
						value={contact}
						// type={"number"}
						onChange={(e) => {
							if (
								contactRegex.test(e.target.value) ||
								e.target.value === ""
							) {
								setContact(e.target.value);
							}
						}}
					/>
				</InputGroup>
			</Form.Group>

			<Button className="addItem" onClick={savePatient}>
				Save
			</Button>
		</div>
	);
}
