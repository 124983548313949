import api from "../axios/axios-config";

export const getStaff = async () => {
  try {
    let response = await api.get("users/staffList");
    return response?.data?.data.reverse();
  } catch (error) {
    return error?.response?.data;
  }
};

export const checkIsDeletedUser = async (email, username) => {
  try {
    let response = await api.get(
      `users/checkIsDeletedUser/${email}/${username}`
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addUser = async (apiData) => {
  try {
    let response = await api.post("users/createStaff", apiData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateUser = async (apiData) => {
  try {
    let response = await api.post("users/updateStaff", apiData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteStaff = async (userId, isDeleted) => {
  try {
    let response = await api.post("users/updateStaff", {
      _id: userId,
      isDeleted,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getStaffByDepartment = async (departmentId) => {
  try {
    let response = await api.post("users/staffListByDepartment", {
      departmentId,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getStaffListByFilter = async (filterData) => {
  const { startDate, endDate, selectedDepartment } = filterData;
  try {
    let response = await api.post("users/staffListByFilter", {
      startDate,
      endDate,
      selectedDepartment,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
