import api from "../axios/axios-config";

export const getDotPhrases = async () => {
  try {
    let response = await api.get("dotPhrases/listOfDotPhrases");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createDotPhrase = async (data) => {
  try {
    let response = await api.post("dotPhrases/createDotPhrases", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateDotPhrase = async (dataId, data) => {
  try {
    let response = await api.post("dotPhrases/updateDotPhrases", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteDotPhrases = async (data) => {
  try {
    let response = await api.post("dotPhrases/deleteDotPhrases", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
