import React, { useState, useEffect, useMemo } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import styled from "styled-components";
import MultipleIndicator from "./MultipleIndicator";
import CheckboxQuestion from "./CheckboxQuestion";
import YesNo from "./YesNo";
import TextQuestion from "./TextQuestion";
import ManualQuestion from "./ManualQuestion";
import SelectQuestion from "./SelectQuestion";
import { getAllQuestions } from "../../../apis/questionnaire";
import { hearAboutUs } from "../../../apis/patients";
import { getoccupationList } from "../../../apis/occupation";
import { toastify } from "../../../helperFunctions/toastify";
import { authorizedRequests } from "../../../axios/axios-config";
import Nationalities from "../../../utils/nationalities";
import { LANGUAGES } from "../../../constants";

import { SignWrapper } from "./styled";

const StyledTitle = styled.p`
	text-align: center;
	color: #b3914c;
	font-size: 20px;
	font-weight: 600;
	margin-top: 20px;
`

function Questionnaire() {
	let navigate = useNavigate();
	let params = useParams();
	const { id } = params;
	// const [id, setId] = useState();
	const today = new Date().toLocaleDateString("en-GB", {
		day: "numeric",
		month: "numeric",
		year: "numeric",
	});

	const [questions, setQuestions] = useState([]);
	const [allQuestions, setAllQuestions] = useState([]);
	const [edit, setEdit] = useState(null);
	const [patientRef, setPatientRef] = useState({});
	const [haveSigned, setHaveSigned] = useState(false);
	const [staffRef, setStaffRef] = useState({});
	const [resUser, setResUser] = useState(null);
	const [isSubmit, setIsSubmit] = useState(false);
	const [otherOccupation, setotherOccupation] = useState("");

	// options
  const [occupationlist, setOccupationList] = useState([]);
  const [hearingOptions, setHearingOptions] = useState([]);
	const languages = LANGUAGES;

	useEffect(() => {
		getOccupationLists();
		populateHearAboutUs();
	}, []);

	const getOccupationLists = async () => {
    const { result, error } = await getoccupationList();
    if (error) {
      toastify(error.message, "error");
      return;
    } else {
      setOccupationList(result.data);
    }
  };

	const populateHearAboutUs = async () => {
    const response = await hearAboutUs();
    setHearingOptions(response);
  };

	const getSignDate = (type) => {
		if (type === "Patient") {
			return today;
		} else {
			return today;
		}
	}

	const renderQuestions = (question, grpQuestion) => {
		if (question.linkedTo === "patientProfile.occupation") {
			return (
				<React.Fragment>
					<SelectQuestion 
						question={{
							...question,
							size: question.patient_answer !== "Other" ? 6 : 3
						}} 
						grpQuestion={grpQuestion}
						options={[
							...occupationlist.map((e) => (
								{
									label: e.name,
									value: e.name
								}
							)),
							{
								label: "Other",
								value: "Other"
							},
						]}
						onChange={onAnsChange} 
					/>
					{
						question.patient_answer === "Other" && (
							<ManualQuestion 
								label="Other Occupation"
								value={otherOccupation}
								onChange={value => setotherOccupation(value)} 
							/>
						)
					}
				</React.Fragment>
			);
		} else if (question.linkedTo === "patientProfile.language") {
				return (
					<SelectQuestion 
						question={{
							...question,
						}} 
						grpQuestion={grpQuestion}
						options={[
							...languages.map((e) => (
								{
									label: e,
									value: e
								}
							)),
						]}
						onChange={onAnsChange} 
					/>
				)
		} else if (question.linkedTo === "patientProfile.nationality") {
			return (
				<SelectQuestion 
					question={question} 
					grpQuestion={grpQuestion}
					options={Nationalities.map((e) => (
						{
							label: e,
							value: e
						}
					))}
					onChange={onAnsChange} 
				/>
			);
		} else if (question.linkedTo === "patientProfile.maritalStatus") {
			return (
				<SelectQuestion 
					question={question} 
					grpQuestion={grpQuestion}
					options={["Single", "Married", "Others"].map((e) => (
						{
							label: e,
							value: e
						}
					))}
					onChange={onAnsChange} 
				/>
			);
		}

		if (question?.questionType === "Multiple Indicators")
			return (
				<MultipleIndicator question={question} grpQuestion={grpQuestion} onChange={onAnsChange} />
			);
		else if (question?.questionType === "Yes/No")
			return <YesNo question={question} grpQuestion={grpQuestion} onChange={onAnsChange} />;
		else if (question?.questionType === "Text")
			return <TextQuestion question={question} grpQuestion={grpQuestion} onChange={onAnsChange} />;
		else if (question?.questionType === "Checkbox")
			return <CheckboxQuestion question={question} grpQuestion={grpQuestion} onChange={onAnsChangeCheckbox} isSubmit={isSubmit} />
	};

	const getQuestions = async (answers) => {
		const response = await getAllQuestions();
		let allChildQuestions = [];
		if (response?.status !== "Fail") {
			const mapQuestions = response.data?.map((x => {
				const items = x.data
				.filter((e) => e.isActive === true)
				.map((o) => {
					if (answers?.length == 0) {
						var pat_ans =
							(o?.questionType == "Multiple Indicators" || o?.questionType == "Checkbox")
								? []
								: "";
						return {
							...o,
							patient_answer: pat_ans,
						};
					}

					const ans = answers?.find((k) => {
						return k?.question?._id === o._id;
					});

					pat_ans =
						o?.questionType == "Yes/No"
							? ans?.booleanAnswer
							: o?.questionType == "Text"
							? ans?.textAnswer
							: o?.questionType == "Checkbox"
							? ans?.checkboxAnswers
							: ans?.multiAnswers;
					return {
						...o,
						patient_answer: pat_ans,
					};
				});

				allChildQuestions = allChildQuestions.concat(items);

				return {
					group: x._id,
					questions: items
				}
			}));

			mapQuestions.sort((a,b) => a.questions[0].orderIndex - b.questions[0].orderIndex);
			const order = ["REGISTRATION FORM", "CONTACT INFORMATION", "EMERGENCY CONTACT DETAILS", ""];
			mapQuestions.sort((a,b) => order.indexOf(a.group) - order.indexOf(b.group));

			setQuestions(mapQuestions);
			setAllQuestions(allChildQuestions);
		} else {
			if (response?.message === "No data Found") {
				setQuestions([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const getUntickCheckboxs = () => {
		const arr = [];
		allQuestions.filter(o => o.questionType === "Checkbox").map((question) => {
			const requiredValues = question.checkboxOptions.filter(x => x.isRequired === true);
			requiredValues.map((checkBox) => {
				if (question.patient_answer.filter(p => p.value === checkBox.name).length === 0) {
					arr.push(checkBox.name)
				}
			})
		});

		return arr;
	}

	const submitForm = async () => {
		const unTickCheckboxs = getUntickCheckboxs();

		if (unTickCheckboxs.length > 0) {
			toastify(
				"Please fill up required information",
				"error",
			);

			return;
		}

		if (haveSigned === false) {
			toastify(
				"Patient need to sign",
				"error",
			);

			return;
		}

		const tempArr = allQuestions?.map((o) => {
			if (o.questionType == "Text") {
				return {
					question: o?._id,
					questionType: o?.questionType,
					linkedTo: o?.linkedTo,
					textAnswer: o?.patient_answer,
				};
			} else if (o.questionType == "Yes/No") {
				return {
					question: o?._id,
					questionType: o?.questionType,
					linkedTo: o?.linkedTo,
					booleanAnswer: o?.patient_answer,
				};
			} else if (o.questionType == "Checkbox") {
				return {
					question: o?._id,
					questionType: o?.questionType,
					linkedTo: o?.linkedTo,
					checkboxAnswers: o?.patient_answer,
				};
			} else {
				return {
					question: o?._id,
					linkedTo: o?.linkedTo,
					multiAnswers: o?.patient_answer?.filter(
						(k) => k?.answers.length > 0,
					),
				};
			}
		});

		let newPatient = null;

		if (id === "new") {
			const patientProfile = {};
			const emergencyContact = {
				name: "",
				relation: "",
				contactNo: "",
			};
			tempArr.map((item) => {
				if (item.linkedTo === "patientProfile.fullName") {
					patientProfile.fullName = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.dob") {
					patientProfile.dob = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.contactNo") {
					patientProfile.contactNo = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.language") {
					patientProfile.language = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.nationalId") {
					patientProfile.nationID = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.occupation") {
					patientProfile.occupation = item.textAnswer;
					patientProfile.otherOccupation = item.textAnswer === "Other" ? otherOccupation : "";
				}
				if (item.linkedTo === "patientProfile.nationality") {
					patientProfile.nationality = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.maritalStatus") {
					patientProfile.maritalStatus = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.hearAboutUs") {
					const firstCheckbox = item.checkboxAnswers?.length > 0 ? item.checkboxAnswers[0] : null;
					if (firstCheckbox) {
						const hearingOpt = hearingOptions.find(h => h.name === firstCheckbox.value);
						if (hearingOpt) {
							patientProfile.hearAboutUs = hearingOpt._id;
							patientProfile.hearAboutUsRemark = firstCheckbox.remark;
						}
					}
				}
				if (item.linkedTo === "patientProfile.address") {
					patientProfile.address = item.textAnswer;
				}
				if (item.linkedTo === "patientProfile.postalCode") {
					patientProfile.postalCode = item.textAnswer;
				}
				if (item.linkedTo === "emergencyContact.name") {
					emergencyContact.name = item.textAnswer;
				}
				if (item.linkedTo === "emergencyContact.relation") {
					emergencyContact.relation = item.textAnswer;
				}
				if (item.linkedTo === "emergencyContact.contactNo") {
					emergencyContact.contactNo = item.textAnswer;
				}
			})
			const { result, error } = await authorizedRequests({
				url: "patient/addPatient",
				method: "post",
				data: {
					patientProfile: patientProfile,
					emergencyContact: emergencyContact
				},
			});

			if (error) {
				toastify(error.message ?? "unknown error occured", "error");
				return;
			}

			newPatient = {...result.data};
		}

		if (edit) {
			const editedData = [...tempArr];
			const editedOoccupation = editedData.find(s => s.linkedTo === "patientProfile.occupation");
			if (editedOoccupation.textAnswer === "Other") {
				editedOoccupation.otherOccupation = otherOccupation;
			}

			const { result, error } = await authorizedRequests({
				url: "usersQuestionnaire/updateUserQuestionnaireById",
				method: "post",
				data: {
					id: edit,
					questionData: [...editedData],
					patientSign: patientRef.toDataURL("image/png"),
					staffSign: staffRef.toDataURL("image/png"),
					otherOccupation: otherOccupation
				},
			});
			if (error) {
				toastify(
					error.message ?? "Failed to post questionnaire form",
					"error",
				);
				return;
			}
			toastify("Thank You!", "success");
			navigate(`/medicalform/${id}`);
			return;
		}
		const { result, error } = await authorizedRequests({
			url: "usersQuestionnaire/createUsersQuestionnaire",
			method: "post",
			data: {
				questionData: [...tempArr],
				patient: newPatient ? newPatient._id : id,
				patientSign: patientRef.toDataURL("image/png"),
				staffSign: staffRef.toDataURL("image/png"),
				otherOccupation: otherOccupation
			},
		});
		if (error) {
			toastify(
				error.message ?? "Failed to post questionnaire form",
				"error",
			);
			return;
		}
		toastify("Thank You!", "success");
		navigate(`/medicalform/${newPatient ? newPatient._id : id}`);
	};

	function onAnsChangeCheckbox(id, ans, type, typeGroup, checkboxName, field) {
		const tempArr = [...questions];
		const groupIdx = tempArr.findIndex((o) => o.group == typeGroup);
		if (groupIdx >= 0) {
			const index = tempArr[groupIdx].questions.findIndex((o) => o._id == id);
			if (type === "Checkbox") {
				const selectedPatientAnswer = tempArr[groupIdx].questions[index].patient_answer.find(x => x.value === checkboxName);
				if (selectedPatientAnswer) {
					if (field === "remark") {
						selectedPatientAnswer.remark = ans;
					} else {
						tempArr[groupIdx].questions[index].patient_answer = tempArr[groupIdx].questions[index].patient_answer.filter(x => x.value !== ans);
					}
				} else {
					tempArr[groupIdx].questions[index].patient_answer.push({
						value: ans,
						remark: ""
					});
				}
				setQuestions(tempArr);
			}
		}
	}

	function onAnsChange(id, ans, type, typeGroup) {
		const tempArr = [...questions];
		const groupIdx = tempArr.findIndex((o) => o.group == typeGroup);
		if (groupIdx >= 0) {
			const index = tempArr[groupIdx].questions.findIndex((o) => o._id == id);
			if (type !== "Multiple Indicators") {
				if (type === "Checkbox") {
					if (tempArr[groupIdx].questions[index].patient_answer?.filter(x => x.value === ans).length > 0) {
						tempArr[groupIdx].questions[index].patient_answer = tempArr[groupIdx].questions[index].patient_answer.filter(x => x.value !== ans);
					} else {
						tempArr[groupIdx].questions[index].patient_answer.push({
							value: ans,
							remark: ""
						});
					}
					setQuestions(tempArr);
				} else {
					tempArr[groupIdx].questions[index].patient_answer = ans;
					setQuestions(tempArr);
				}
			} else {
				const indicatorIndex = tempArr[groupIdx].questions[index]?.patient_answer?.findIndex(
					(o) => o.indicator == ans.indicator,
				);
				if (indicatorIndex == -1) {
					tempArr[groupIdx].questions[index].patient_answer.push({
						indicator: ans.indicator,
						answers: [ans.label],
					});
					setQuestions(tempArr);
				} else {
					let ansArr =
						tempArr[groupIdx].questions[index].patient_answer[indicatorIndex].answers;
					const ansIndex = ansArr.indexOf(ans.label);
					if (ansIndex === -1) {
						ansArr.push(ans.label);
						tempArr[groupIdx].questions[index].patient_answer[indicatorIndex].answers =
							ansArr;
						setQuestions(tempArr);
					} else {
						ansArr = ansArr.filter((o) => o != ans.label);
						tempArr[groupIdx].questions[index].patient_answer[indicatorIndex].answers =
							ansArr;
						setQuestions(tempArr);
					}
				}
			}
		}
	}
	// useEffect(() => {
	//   if (params?.id) {
	//     setId(params.id);
	//   }
	// }, [params.id]);

	useEffect(() => {
		(async () => {
			const { result, error } = await authorizedRequests({
				url: "usersQuestionnaire/getUserQuestionnaireByPatientId",
				method: "post",
				data: {
					patient: id,
				},
			});
			if (error) {
				setEdit();
				getQuestions([]);
				return;
			}
			setEdit(result.data?._id);
			getQuestions(result.data?.questionData);
			setResUser(result.data);
			setotherOccupation(result.data?.otherOccupation);

			if (result.data) {
				setHaveSigned(true);
			}
		})();
	}, []);

	return (
		<div className=" px-5 mb-5 pb-5 white-background">
			<div className="d-flex w-100 justify-content-center p-0 questionnaire-heading my-5 flex-column align-items-center">
				<div className="logo-toggle my-3">
					<img src="/imgs/logo.svg" className="dpImg" alt="" />
				</div>
			</div>
			<Container fluid>
				<Row>
					{
						questions?.map((q, index) => (
							<React.Fragment>
								<StyledTitle>{q.group}</StyledTitle>
								{
									q.questions?.map((question, childIdx) => (
										renderQuestions(question, q.group)
									))
								}
							</React.Fragment>
						))
					}
					<Col md={6}>
						<SignWrapper className="patient-signature">
							<div className="text-center p-3 grey header-sign">
								<p>Patient Signature</p>
								<p>{getSignDate("Patient")}</p>
							</div>
							<SignatureCanvas
								penColor="green"
								ref={(ref) => {
									ref?.fromDataURL(
										resUser?.patientSign
									);

									setPatientRef(ref);
								}}
								onBegin={() => setHaveSigned(true)}
								canvasProps={{
									width: 300,
									height: 150,
									className: "sigCanvas",
								}}
							/>

						</SignWrapper>
						<div style={{width: 300}}>
							<Button
								className="grey-rounded-button ms-auto mt-3"
								onClick={() => { patientRef.clear() }}
							>
								Clear Box
							</Button>
						</div>
					</Col>
					<Col md={6}>
						<SignWrapper className="patient-signature" style={{marginLeft: "auto"}}>
							<div className="text-center p-3 grey header-sign">
								<p>Staff Signature</p>
								<p>{getSignDate("Staff")}</p>
							</div>
							<SignatureCanvas
								penColor="green"
								ref={(ref) => {
									ref?.fromDataURL(
										resUser?.staffSign
									);

									setStaffRef(ref);
								}}
								canvasProps={{
									width: 300,
									height: 150,
									className: "sigCanvas",
								}}
							/>

						</SignWrapper>
						<div style={{width: 300, marginLeft: "auto"}}>
							<Button
								className="grey-rounded-button ms-auto mt-3"
								onClick={() => { staffRef.clear() }}
							>
								Clear Box
							</Button>
						</div>
					</Col>

					<div className="d-flex justify-content-center signature-btn">
						{allQuestions?.length > 0 && (
							<Button
								className="addItem"
								onClick={() => {
									setIsSubmit(true);
									submitForm();
									// navigate("/patient");
								}}
							>
								Continue
							</Button>
						)}
					</div>
				</Row>
			</Container>
		</div>
	);
}

export default Questionnaire;
