import { useNavigate } from "react-router-dom";
// import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useState } from "react";
import { toastify } from "../../../helperFunctions/toastify";
import PatientGeneralReport from "./PatientGeneralReport";
import activeReportIcon from "../../../assets/images/reportIcons/activeReportIcon.png";
import inactiveReportIcon from "../../../assets/images/reportIcons/inactiveReportIcon.png";
import SalesReport from "./SalesReport";
import SessionReport from "./SessionReport";
import KOLReports from "./KOLReports";
import InactivePatientsReports from "./InactivePatientsReports";
import ProgramEXpirationReport from "./ProgramExpirationReport";

export const reportsStates = Object.freeze({
  CLIENT_PROFILE: "client-profile",
  INACTIVE_PATIENTS: "inactive-patients",
  SALES_REPORTS: "sales-reports",
  KOL_REPORTS: "kol-reports",
  PROGRAM_EXPIRATION: "program_expiration",
  SESSION_REPORTS: "session-reports",
});
export default function CustomizedReportsView() {
  const navigate = useNavigate();

  const [currentState, setCurrentState] = useState(
    reportsStates.CLIENT_PROFILE
  );

  // const [startDate, setStartDate] = useState(
  // 	new Date(new Date().getFullYear(), 0, 1),
  // );
  // const [endDate, setEndDate] = useState(new Date());
  // const [patients, setPatients] = useState([]);

  // useEffect(() => {
  // 	if (!startDate || !endDate) {
  // 		return;
  // 	}
  // 	(async () => {
  // 		const { result, error } = await getPatientGeneralReports({
  // 			startDate,
  // 			endDate,
  // 		});
  // 		if (error) {
  // 			toastify(error.message, "error");
  // 			return;
  // 		}
  // 	})();
  // }, [startDate, endDate]);
  return (
    <>
      <div className="px-4 py-2">
        <div className="mainHeading">
          <h1>Dashboard</h1>
        </div>
      </div>

      <div className="search-container d-flex px-4">
        <div className="col-9">
          {currentState == reportsStates.CLIENT_PROFILE && (
            <PatientGeneralReport />
          )}
          {currentState == reportsStates.INACTIVE_PATIENTS && (
            <InactivePatientsReports />
          )}
          {currentState == reportsStates.SALES_REPORTS && <SalesReport />}
          {currentState == reportsStates.KOL_REPORTS && <KOLReports />}
          {currentState == reportsStates.PROGRAM_EXPIRATION && (
            <ProgramEXpirationReport />
          )}
          {currentState == reportsStates.SESSION_REPORTS && <SessionReport />}
        </div>
        <div className="ps-3 col-3 flex-strech">
          <div className="white-bg h-100">
            <div
              className="font-400-20 py-3 px-2 "
              style={{ borderBottom: "1px solid #E9E9E9" }}
            >
              <b>Reports</b>
            </div>
            <div
              className={`${
                currentState == reportsStates.CLIENT_PROFILE
                  ? "activeReport"
                  : "InactiveReport"
              } font-400-16 px-2 py-3 pointer d-flex`}
              onClick={() => {
                setCurrentState(reportsStates.CLIENT_PROFILE);
              }}
            >
              <img
                src={
                  currentState == reportsStates.CLIENT_PROFILE
                    ? activeReportIcon
                    : inactiveReportIcon
                }
              />
              <div className="ms-2 align-self-center">Client Growth</div>
            </div>
            <div
              className={`${
                currentState == reportsStates.INACTIVE_PATIENTS
                  ? "activeReport"
                  : "InactiveReport"
              } font-400-16 px-2 py-3 pointer d-flex`}
              onClick={() => {
                setCurrentState(reportsStates.INACTIVE_PATIENTS);
              }}
            >
              <img
                src={
                  currentState == reportsStates.INACTIVE_PATIENTS
                    ? activeReportIcon
                    : inactiveReportIcon
                }
              />
              <div className="ms-2 align-self-center">Inactive Patients</div>
            </div>
            <div
              className={`${
                currentState == reportsStates.SALES_REPORTS
                  ? "activeReport"
                  : "InactiveReport"
              } font-400-16 px-2 py-3 pointer d-flex`}
              onClick={() => {
                setCurrentState(reportsStates.SALES_REPORTS);
              }}
            >
              <img
                src={
                  currentState == reportsStates.SALES_REPORTS
                    ? activeReportIcon
                    : inactiveReportIcon
                }
              />
              <div className="ms-2 align-self-center">Sales Reports</div>
            </div>
            <div
              className={`${
                currentState == reportsStates.KOL_REPORTS
                  ? "activeReport"
                  : "InactiveReport"
              } font-400-16 px-2 py-3 pointer d-flex`}
              onClick={() => {
                setCurrentState(reportsStates.KOL_REPORTS);
              }}
            >
              <img
                src={
                  currentState == reportsStates.KOL_REPORTS
                    ? activeReportIcon
                    : inactiveReportIcon
                }
              />
              <div className="ms-2 align-self-center">KOL Reports</div>
            </div>
            <div
              className={`${
                currentState == reportsStates.PROGRAM_EXPIRATION
                  ? "activeReport"
                  : "InactiveReport"
              } font-400-16 px-2 py-3 pointer d-flex`}
              onClick={() => {
                setCurrentState(reportsStates.PROGRAM_EXPIRATION);
              }}
            >
              <img
                src={
                  currentState == reportsStates.PROGRAM_EXPIRATION
                    ? activeReportIcon
                    : inactiveReportIcon
                }
              />
              <div className="ms-2 align-self-center">Program Expiration</div>
            </div>
            {/* <div
							className={`${currentState == reportsStates.SESSION_REPORTS
								? "activeReport"
								: "InactiveReport"
								} font-400-16 px-2 py-3 pointer d-flex`}
							onClick={() => {
								setCurrentState(reportsStates.SESSION_REPORTS);
							}}
						>
							<img
								src={
									currentState == reportsStates.SESSION_REPORTS
										? activeReportIcon 	
										: inactiveReportIcon
								}
							/>
							<div className="ms-2 align-self-center">
								End-Sessions Reports
							</div>
						</div> */}
          </div>
        </div>
      </div>
    </>
  );
}
