import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getDepartments } from "../../../apis/departments";
import { deleteStaff, getStaff } from "../../../apis/staff";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customDateSort,
  customStringSort,
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

function User() {
  let navigate = useNavigate();
  const userData = useSelector((state) => state.userData.value);

  const [staff, setStaff] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [roles, setRoles] = useState(["admin", "staff"]);

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getStaffList = async () => {
    const response = await getStaff();
    if (response?.status !== "Fail") {
      // let data = response.filter((e) => e?._id !== userData?._id);
      let data = response.filter((e) => !e?.role?.includes("superAdmin"));
      setStaff(data);
    } else {
      if (response?.message === "No data Found") {
        setStaff([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const addUser = () => {
    navigate("/add-user");
  };

  const editStaff = (e) => {
    navigate("/add-user", {
      state: {
        user: e,
      },
    });
  };

  const removeUser = async () => {
    setShow(false);
    const res = await deleteStaff(deleting, true);
    if (res) {
      getStaffList();
      toastify("User deleted successfully!", "success");
    }
  };

  const getDepartmentList = async () => {
    const response = await getDepartments();
    if (response?.status !== "Fail") {
      setDepartments(response);
    } else {
      toastify(response?.message, "error");
    }
  };

  useEffect(() => {
    getDepartmentList();
    getStaffList();
  }, []);

  const getOptions = (usersArray) => {
    let _opt = [];
    let arr = [...usersArray];
    arr.forEach((e) => {
      e.role.forEach((f) => {
        if (!_opt.includes(f)) _opt.push(f);
      });
    });
    // return _opt;
    setRoles(_opt);
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly17">
          <span className="me-2">Username</span>
          {
            <FilterComponent
              type="search"
              array={staff}
              setArray={setFilteredData}
              value="Username"
            />
          }
        </div>
      ),
      wrap: true,
      selector: (row) => row.username,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "username"),
    },
    {
      name: "Joining Date",
      selector: (row) =>
        new Date(row.createdAt).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),

      width: "200px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "createdAt", true),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly18">
          <span className="me-2">Contact No</span>
          {
            <FilterComponent
              type="search"
              array={staff}
              setArray={setFilteredData}
              value="StaffContact"
            />
          }
        </div>
      ),
      selector: (row) => row.contactNumber,
      width: "180px",
      sortable: true,
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly19">
          <span className="me-2">Email</span>
          {
            <FilterComponent
              type="search"
              array={staff}
              setArray={setFilteredData}
              value="Email"
            />
          }
        </div>
      ),
      selector: (row) => row.email,
      width: "250px",
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "emailText"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly20">
          <span className="me-2">Role</span>
          {
            <FilterComponent
              type="radio"
              array={staff}
              setArray={setFilteredData}
              options={roles}
              value="StaffRole"
            />
          }
        </div>
      ),
      selector: (row) =>
        row.role.map((e, index) => {
          if (index === row.role.length - 1) {
            return e;
          }
          return e + `, `;
        }),

      width: "180px",
    },
    // {
    //   name: "Department",
    //   selector: (row) => row.department,
    //
    //   width: "250px",
    // },
    {
      name: "Department",
      selector: (row) => row.dept,

      width: "140px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "dept"),
    },
    {
      name: "Designation",

      selector: (row) => row.designation,
      width: "180px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "designation"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? staff : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        emailText: e.email,
        email: <span className="color-blue">{e.email}</span>,
        index: index,
        dept: departments[departments.findIndex((d) => d._id === e.department)]
          ?.name,
        username: e.username,
        createdAt: e.created_at,
        department: e.department,
        jobTitle: e.jobTitle,
        designation: e.designation,
        contactNumber: e.contactNumber,
        nationalID: e.nationalID,
        dob: e.dob,
        address: e.address,
        postalCode: e.postalCode,
        gender: e.gender,
        defaultAppt: e.defaultAppt,
        role: e.role,
        isActive: e.isActive,
        action: (
          <div className="d-flex">
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                editStaff(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <div className="container p-0">
      <div className="mainHeading px-4">
        <h1>User</h1>
        <Button className="addItem" onClick={addUser}>
          + New User
        </Button>
      </div>

      <DeleteModal
        content="Confirm User Deletion?"
        onYes={removeUser}
        onNo={handleClose}
        show={show}
      />

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default User;
