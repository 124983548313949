import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getTaxSettings, updateTaxSettings } from "../../../apis/tax";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customNumberSort,
	customStringSort,
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function TaxSettings() {
	const [tax, setTax] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");
	const navigate = useNavigate();

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getTaxList = async () => {
		const response = await getTaxSettings();
		if (response?.status !== "Fail") {
			setTax(response?.reverse());
		} else {
			if (response?.message === "No data Found") {
				setTax([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const removeTax = async () => {
		setShow(false);
		let response = await updateTaxSettings(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			getTaxList();
			toastify("Tax deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	const editTax = (e) => {
		navigate("/add-tax-setting", {
			state: {
				tax: e,
			},
		});
	};

	const addDepartment = () => {
		navigate("/add-tax-setting");
	};

	useEffect(() => {
		getTaxList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly15">
					<span className="me-2">Name</span>
					{
						<FilterComponent
							type="search"
							array={tax}
							setArray={setFilteredData}
							value="Name"
						/>
					}
				</div>
			),
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Type Value",
			selector: (row) => row.typeValue,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "typeValue"),
		},
		{
			name: "Value",
			selector: (row) => row.value?.toLocaleString(),
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customNumberSort(a, b, "value"),
		},
		{
			name: "Type",
			selector: (row) => row.taxType,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "taxType"),
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? tax : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				index: index,
				name: e.name,
				typeValue: e.typeValue,
				value: e.value,
				taxType: e.taxType,
				action: (
					<div className="d-flex">
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								editTax(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3  cursor-pointer icon-width"
							// onClick={() => {
							//   removeTax(e._id);
							// }}
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Tax Setting</h1>
					<Button className="addItem" onClick={addDepartment}>
						+ Add New
					</Button>
				</div>

				<DeleteModal
					content="Confirm Tax Deletion?"
					onYes={removeTax}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}
