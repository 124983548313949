import { useState, useEffect } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { getPaymentMethodList } from "../../../apis/paymentMethod";
import { exportData, getInvoiceId } from "../../../helperFunctions/utils";
import DateInput from "../../SharedComponents/DateInput";
import MyDoc from "../sessionPDF";

export default function Billing() {
  const navigate = useNavigate();

  const [billingData, setBillingData] = useState([]);
  const [stateDate, setStateDate] = useState(new Date());
  const [showPDFModal, setShowPDFModal] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentData, setPaymentData] = useState([]);

  const getPaymentMethods = async () => {
    const { result, error } = await getPaymentMethodList();
    if (error) {
      return;
    }
    setPaymentMethods(result.data);
  };

  const getBillingsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "payment/listOfPayments",
      method: "get",
      params: {
        date: stateDate,
      },
    });
    if (error) {
      toastify(error?.message ?? "Failed to get billing details", "error");
      return;
    }

    const tempArr = result.data
      ?.filter((x) => x.session?.status === "Closed")
      ?.map((o) => {
        return {
          ...o,
          tableData: {
            date: new Date(o.created_at).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            }),
            patientId: o.patient?._id,
            id: o._id,
            gst: o?.gst,
            paidAmount:
              o?.paymentRecord && o?.paymentRecord?.length > 0
                ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
                : 0,
            invoice: o.invoiceNo ?? "-",
            name: o?.patient?.patientProfile?.fullName || "No Name",
            totalAmount: o.totalAmount,
          },
        };
      });

    setBillingData([...tempArr]);
  };

  useEffect(() => {
    getPaymentMethods();
    getBillingsList();
  }, [stateDate]);

  const handleTodaySession = async () => {
    const { result } = await authorizedRequests({
      url: "session/closeSessionsReport",
      method: "get",
      params: {
        date: stateDate,
      },
    });

    if (result?.status !== "Success") {
      toastify("Error when export the end session report", "error");
      return;
    } else {
      setPaymentData(result.data || []);
      setShowPDFModal(true);
      return;
    }
  };

  const column = [
    {
      name: "Invoice No",
      selector: (row) => {
        return getInvoiceId(row.invoice);
      },
      wrap: true,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      wrap: true,
    },
    {
      name: "Amount GST",
      selector: (row) => row.gst?.toLocaleString(),
      wrap: true,
    },
    {
      name: "Payer Name",
      selector: (row) => row.name,
      wrap: true,
    },
    {
      name: "Staff",
      selector: (row) => row.staff,
      wrap: true,
    },
    {
      name: "Payment",
      selector: (row) => row.paidAmount?.toLocaleString(),
      wrap: true,
    },
  ];

  const data = billingData
    ?.map((o, i) => {
      return {
        index: i,
        invoice: o?.tableData?.invoice ?? "-",
        id: o?.tableData?.id,
        name: o?.tableData?.name,
        amount: o?.tableData?.amount,
        gst: o?.tableData?.gst ?? "-",
        totalAmount: o?.tableData?.totalAmount ?? 0,
        paidAmount: o?.tableData?.paidAmount ?? 0,
        staff:
          o?.session?.staff?.map((staff) => `${staff?.username}`).join(", ") ??
          "-",
      };
    })
    .reverse();

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            ></i>

            <span className="400-28-32">End Session Report</span>
          </h1>
          <span className="d-flex">
            <div className="me-3" style={{ width: "200px" }}>
              <DateInput
                value={stateDate}
                placeholder="Date"
                shouldDisableDates={true}
                onChange={(e) => {
                  setStateDate(e);
                }}
              />
            </div>
            <Button
              className="addItem me-3"
              onClick={() => handleTodaySession()}
            >
              Export
            </Button>
          </span>
        </div>
        <DataTable
          columns={column}
          data={data || []}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>

      <Modal
        centered
        show={showPDFModal}
        onHide={() => {
          setShowPDFModal(false);
        }}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Session Report
          </Modal.Title>
        </Modal.Header>

        <div className="pdf-btn">
          <PDFViewer style={{ width: "100%", height: "800px" }}>
            <MyDoc
              payment={paymentData}
              paymentMethods={paymentMethods}
              date={stateDate}
            />
          </PDFViewer>
        </div>
      </Modal>
    </>
  );
}
