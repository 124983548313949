import api from "../axios/axios-config";

export const getTreatments = async () => {
  try {
    let response = await api.get("treatment/listOfTreatment");
    console.log("Treatment list", response);
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createTreatments = async (data) => {
  console.log({ data });
  try {
    let response = await api.post("treatment/createTreatment", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const migrateTreatments = async (data) => {
  try {
    console.log('treatment file send frontend:', data);
    let response = await api.post("migrate/treatment", data);
    return response?.data?.message;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateTreatments = async (dataId, data) => {
  try {
    let response = await api.post("treatment/updateTreatment", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
