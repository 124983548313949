import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getPackageExpiryReport } from "../../../apis/customTemplate";
import DateInput from "../../SharedComponents/DateInput";
import { setTableSearch } from "../../../redux/slices/commonSlice";

import { downloadCsv, formatDate } from "../../../helperFunctions/utils";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function PackageExpiryReport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [filteredData, setFilteredData] = useState([]);
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.packageExpiry?.endDate ? tableSearch[fieldPathname]?.packageExpiry?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (valueEndDate) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
        packageExpiry: {
          endDate: valueEndDate,
        }
			}
		}))
  }

  useEffect(() => {
    setEndDate(tableSearch[fieldPathname]?.packageExpiry?.endDate ? tableSearch[fieldPathname]?.packageExpiry?.endDate : new Date());
    if (buttonRef.current && (
      tableSearch[fieldPathname]?.packageExpiry?.endDate
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);


  useEffect(() => {
    getPackageExpiryReport({
      endDate: endDate,
    }).then((res) => {
      if (res?.result?.data) {
        setFilteredData(res.result.data);
      }
    });
  }, [refetch]);


  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.patient?.patientProfile?.fullName,
    },
    {
      name: "Package Name",
      selector: (row) => row.name ? `${row.name} ${row.promoName ? `(${row.promoName})` : ""}` : "",
    },
    {
      name: "Purchase Date",
      selector: (row) => formatDate(row.date),
    },
    {
      name: "Expiry Date",
      selector: (row) => row.package_expiry?.length > 0 ? formatDate(row.package_expiry[0]) : "",
    },
    {
      name: "Package Price",
      selector: (row) => row.package_unit_price,
    },
    {
      name: "Promo",
      selector: (row) => row.promoDiscountType === "Percentage" ? 
        `${row.promoDiscountValue}%` : 
        `$${row.promoDiscountValue ? row.promoDiscountValue : 0}`,
    },
    {
      name: "Total",
      selector: (row) => row.package_price,
    },
  ];

  const data = filteredData?.map(
    (e, index) => {
      return {
        ...e,
        index,
        key: index,
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data.map((row) => {
      return {
        "Patient Name": row.patient?.patientProfile?.fullName,
        "Package Name": row.name ? `${row.name} ${row.promoName ? `(${row.promoName})` : ""}` : "",
        "Purchase Date": formatDate(row.date),
        "Expiry Date": row.package_expiry?.length > 0 ? formatDate(row.package_expiry[0]) : "",
        "Package Price": row.package_price,
        "Promo": row.promoDiscountType === "Percentage" ? 
          `${row.promoDiscountValue}%` : 
          `$${row.promoDiscountValue ? row.promoDiscountValue : 0}`,
        "Total": row.package_price,
      }
    });

    downloadCsv(csvData, "Package Expiry Report");
  }


  return (
    <div className="p-0">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "18px",
          }}
        >
          <FilterWrapper className="d-flex">
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  onReduxFilter(endDate);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button
                className="addItem"
                onClick={() => exportCsv()}
              >
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default PackageExpiryReport;
