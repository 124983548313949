import { useEffect } from "react";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { editWaiting, getWaiting } from "../../../apis/waiting";
import { permissionTypes } from "../../../helperFunctions/routes";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customDateSort,
	customStringSort,
	customTimeSort,
	getFormatedTime,
} from "../../../helperFunctions/utils";
import { resetValue } from "../../../redux/slices/StaffFormsSlices";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import FilterComponent from "../../SharedComponents/FilterComponent";
import DateInput from "../../SharedComponents/DateInput";

export default function WaitingListView() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

	const [waitingList, setWaitingList] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [itemToDel, setItemToDel] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [stateDate, setStateDate] = useState(tableSearch[fieldPathname]?.currentDate ? tableSearch[fieldPathname]?.currentDate : new Date());

	const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
				currentDate: value
			}
		}))
  	}

	const getDefaultFilteredData = (values) => {
		let list = [...values];
		const fieldPathname = pathname.replace("/", "");
	
		if (tableSearch[fieldPathname]) {
			if (tableSearch[fieldPathname].patientName) {
				list = values.filter(v => v.patientName?.toLowerCase()?.includes(tableSearch[fieldPathname].patientName.toLowerCase()));
			}
		}
	
		return list;
	}

	const getList = async () => {
		const response = await getWaiting({
			params: {
				date: stateDate
			}
		});
		if (response.status !== "Fail") {
			console.log(response);

			const tempArr = response.map((o) => {
				return {
					id: o._id,
					patientName: o?.patientName,
					gender: o?.gender,
					contactNumber: o?.contactNumber,
					date: new Date(o?.date),
					startTime: o.startTime,
					endTime: o.endTime,
					time: `${getFormatedTime(o.startTime)} - ${getFormatedTime(
						o?.endTime,
					)}`,
					remarks: o?.remarks,
				};
			});
			setWaitingList([...tempArr]);
		} else {
			toastify(
				response?.message ?? "Failed to fetch waiting list",
				"error",
			);
			return;
		}
	};
	const onRowDelete = async () => {
		const response = await editWaiting({
			dataId: itemToDel,
			isDeleted: true,
		});
		if (response.status !== "Fail") {
			toastify("Deleted successfully", "success");
			setFilteredData((prev) => prev.filter((e) => e.id !== itemToDel));
			getList();
			return;
		} else {
			toastify(
				response?.message ?? "Failed to fetch waiting list",
				"error",
			);
			return;
		}
	};
	const onRowEdit = (itemToEdit) => {
		navigate("/add-waiting", {
			state: {
				data: {
					patientName: itemToEdit.patientName,
					gender: itemToEdit.gender,
					date: itemToEdit.date,
					remarks: itemToEdit.remarks,
					contactNumber: itemToEdit.contactNumber,
					dataId: itemToEdit.id,
					startTime: itemToEdit.startTime,
					endTime: itemToEdit.endTime,
				},
			},
		});
	};
	useEffect(() => {
		getList();
	}, [stateDate]);
	const columns = [
		{
			name: "Date",
			selector: (row) =>
				row.date.toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "date", true),
			// width: "160px",
		},
		{
			name: "Time",
			selector: (row) => row.time,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customTimeSort(a, b, "time"),
			// width: "160px",
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Patient</span>
					{
						<FilterComponent
							type="search"
							array={waitingList}
							setArray={setFilteredData}
							value="patientName"
							searchValue={tableSearch[fieldPathname]?.patientName}
						/>
					}
				</div>
			),
			selector: (row) => row.patientName,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "patientName"),
			// width: "160px",
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Contact</span>
				</div>
			),
			selector: (row) => row.contactNumber,
			wrap: true,
			sortable: true,
			// sortFunction: (a, b) => customStringSort(a, b, "contactNumber"),

			// width: "160px",
		},
		{
			name: "Remarks",
			selector: (row) => row.remarks,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "remarks"),

			// width: "160px",
		},

		{
			name: "Action",
			selector: (row) => row.action,
			// width: "180px",
		},
	];

	const data = getDefaultFilteredData(waitingList)
		?.map((e, index) => {
			return {
				index: index,
				id: e.id,
				date: e?.date,
				time: e?.time,
				patientName: e?.patientName,
				remarks: e?.remarks,
				contactNumber: e?.contactNumber,
				action: (
					<div className="d-flex ">
						<ComponentWrapepr
							moduleName="Appointment"
							permissionType={permissionTypes.UPDATE}
						>
							<div
								className="cursor-pointer"
								onClick={() => {
									onRowEdit(e);
								}}
							>
								<i className="fa fa-pen"></i>
							</div>
						</ComponentWrapepr>
						<ComponentWrapepr
							moduleName="Appointment"
							permissionType={permissionTypes.DELETE}
						>
							<div
								className="mx-3  cursor-pointer icon-width"
								onClick={() => {
									setItemToDel(e.id);
									setOpenModal(true);
								}}
							>
								<i className="fa fa-trash"></i>
							</div>
						</ComponentWrapepr>
					</div>
				),
			};
		})
		.reverse();
	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];
	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>
						{/* <i
							className="fa fa-arrow-left cursor-pointer"
							onClick={() => {
								navigate(-1);
							}}
						></i> */}
						Waiting List
					</h1>
					<div>
						<div className="me-3" style={{width: "200px", display: "inline-block"}}>
							<DateInput
								value={stateDate}
								placeholder="Date"
								shouldDisableDates={true}
								onChange={(e) => {
									setStateDate(e);
									onSubmitRedux(e);
								}}
							/>
						</div>
						<ComponentWrapepr
							moduleName="Appointment"
							permissionType={permissionTypes.CREATE}
						>
							<Button
								className="addItem"
								style={{display: "inline-block"}}
								onClick={() => {
									dispatch(resetValue("waitingListForm"));
									navigate("/add-waiting");
								}}
							>
								+Add Waiting
							</Button>
						</ComponentWrapepr>
					</div>
				</div>
				<DataTable
					columns={columns}
					data={data || []}
					pagination
					customStyles={customStyles}
					conditionalRowStyles={conditionalRowStyles}
					responsive="true"
				/>
			</div>
			{openModal && (
				<>
					<Modal
						centered
						show={openModal}
						onHide={() => {
							setItemToDel(null);
							setOpenModal(!openModal);
						}}
					>
						<Modal.Body className="font-18">
							Confirm Waiting Deletion
							<div className="align-self-end m-3 d-flex">
								<Button
									className="me-3 delete-no ms-auto"
									onClick={() => {
										setOpenModal(false);
									}}
								>
									No
								</Button>
								<Button
									className="delete-yes"
									onClick={() => {
										onRowDelete(itemToDel);
										setOpenModal(false);
									}}
								>
									Yes
								</Button>
							</div>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
}
