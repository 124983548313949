import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { permissionTypes, routes } from "../../../helperFunctions/routes";

export default function RoutesWrapper({ children }) {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [isPermitted, setIsPermitted] = useState(false);
  const userData = useSelector((state) => state.userData.value);

  useEffect(() => {
    console.log("~~", location.pathname.split("/")[1]);
    const index = routes.findIndex(
      (o) => o.path === location.pathname.split("/")[1]
    );
    console.log("what is index", index);

    if (index !== -1) {
      console.log("routes...", routes);
      console.log("permissionTypes...", permissionTypes);

      if (routes[index].permission[0] === permissionTypes.ADMIN) {
        if (userData.isAdmin) {
          setLoading(false);
          setIsPermitted(true);
        } else {
          setLoading(false);
          setIsPermitted(false);
        }
        return;
      }
      const permissionForRoute = userData?.department?.permissions?.find(
        (e) => e.moduleName === routes[index].module
      );

      if (!permissionForRoute) {
        setLoading(false);
        setIsPermitted(false);
      } else {
        const shouldPermit = permissionForRoute.permissionsList.find((o) => {
          if (routes[index].permission.indexOf(o) !== -1) return true;
        });
        if (shouldPermit) {
          setLoading(false);
          setIsPermitted(true);
        } else {
          setLoading(false);
          setIsPermitted(false);
        }
      }
    } else {
      setLoading(false);
      setIsPermitted(true);
    }
  }, [location.pathname, userData?.department?.permissions]);

  if (loading) {
    return (
      <div
        style={{
          height: "calc(100vh - 5.3rem)",
        }}
        className="df-center"
      >
        <div className="spinner spinner-border"></div>
      </div>
    );
  } else if (!isPermitted) {
    return (
      <>
        <div
          style={{
            height: "calc(100vh - 6rem)",
          }}
          className="df-center"
        >
          <h1>You don't have permissions to access this route.</h1>
        </div>
      </>
    );
  }
  return <>{children}</>;
}
