import api from "../axios/axios-config";

export const getAdjustmentStock = async () => {
  try {
    let response = await api.get("stockAdjustment/listOfStockAdjustment");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addAdjustmentStock = async (data) => {
  try {
    let response = await api.post("stockAdjustment/createStockAdjustment", {
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateAdjustmentStock = async (dataId, data) => {
  try {
    let response = await api.post("stockAdjustment/updateStockAdjustment", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
