import { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import greyTemplate from "../../../assets/images/greyTemplate.png";
import { Button, Form } from "react-bootstrap";
import DateInput from "../../SharedComponents/DateInput";

export default function DataTableView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  console.log("allData...", allData);
  const [selectedTemplate, setSelectedTemplate] = useState();

  const [date, setDate] = useState({
    start: null,
    end: null,
  });

  // useMemo(() => {
  // 	if (date.start && date.end) {
  // 		const tempArr = allData?.filter((o) => {
  // 			return o.date >= date.start && o.date <= date.end;
  // 		});

  // 		setFilteredData([...tempArr]);
  // 	}
  // }, [date.start, date.end]);

  useEffect(() => {
    (async () => {
      const { result, error } = await authorizedRequests({
        url: "collections/templates",
        method: "get",
      });
      if (error) {
        toastify(error?.message ?? "Failed to get templates list", "error");
        return;
      }
      const tempArr = result?.data?.map((o) => {
        return {
          id: o._id,
          name: o.name,
          date: new Date(o.updatedAt),
        };
      });
      setAllData([...tempArr]);
    })();
  }, []);

  return (
    <>
      <div className="container px-4">
        <div className="mainHeading ">
          <h1>Data Table Generation</h1>
        </div>
        <div className="d-flex justify-content-between">
          <div className="date-container d-flex" style={{ maxWidth: "500px" }}>
            <DateInput
              value={date.start}
              placeholder="Start Date"
              onChange={(e) => {
                setDate((val) => {
                  return { ...val, start: e };
                });
              }}
              componentsProps={{
                actionBar: { actions: ["today"] },
              }}
            />
            <DateInput
              placeholder="End Date"
              className="ms-2"
              value={date.end}
              onChange={(e) => {
                if (!date?.start) {
                  toastify("Please select start date", "error");
                  return;
                }
                if (e < date?.start) {
                  toastify("End date can't be less than start date", "error");
                  return;
                }
                setDate((val) => {
                  return { ...val, end: e };
                });
              }}
              componentsProps={{
                actionBar: { actions: ["today"] },
              }}
            />
          </div>
          <Button
            className="addItem px-5"
            style={{ height: "45px" }}
            onClick={() => {
              if (selectedTemplate) {
                navigate(`/preview-template/${selectedTemplate}`, {
                  state: {
                    startDate: date?.start,
                    endDate: date?.end,
                  },
                });
              }
            }}
          >
            Generate
          </Button>
        </div>
        <div
          className="template-container d-flex flex-wrap"
          style={{ marginLeft: "-5pt" }}
        >
          {(filteredData?.length > 0 ? filteredData : allData)?.map((o, i) => {
            return (
              <div
                key={i}
                className="m-2 pointer"
                style={{ background: "white" }}
                onClick={() => setSelectedTemplate(o.id)}
              >
                <div className="p-2">
                  <img src={greyTemplate} alt="" style={{ width: "270px" }} />
                  <div className="font-400-20 w-100 text-center mt-2">
                    <b>{o.name}</b>
                  </div>
                </div>
                <div
                  className="p-2 text-center"
                  style={{
                    borderTop: "2px solid #E9E9E9",
                  }}
                >
                  <Form.Check
                    inline
                    type={"radio"}
                    checked={selectedTemplate == o.id}
                    onClick={() => setSelectedTemplate(o.id)}
                    bsPrefix={"custom-radio"}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
