import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getProducts,
  updateProducts,
  migrateProducts,
} from "../../../../apis/products";
import { getBranches } from "../../../../apis/branch";
import { toastify } from "../../../../helperFunctions/toastify";
import {
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import FilterComponent from "../../../SharedComponents/FilterComponent";

import { Button as MuiButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function Products() {
  const navigate = useNavigate();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [products, setProducts] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const getDefaultFilteredData = (values) => {
    let list = [...values];
    const fieldPathname = pathname.replace("/", "");

    if (tableSearch[fieldPathname]) {
      if (tableSearch[fieldPathname].Code) {
        list = values.filter(v => v.code?.toLowerCase()?.includes(tableSearch[fieldPathname].Code.toLowerCase()));
      }
      if (tableSearch[fieldPathname].Name) {
        list = values.filter(v => v.name?.toLowerCase()?.includes(tableSearch[fieldPathname].Name.toLowerCase()));
      }
      if (tableSearch[fieldPathname].ProductType) {
        list = values.filter(v => v.productType?.toLowerCase()?.includes(tableSearch[fieldPathname].ProductType.toLowerCase()));
      }
      if (tableSearch[fieldPathname].Branch) {
        list = list.filter(v => 
          v.branchName?.toLowerCase()?.includes(tableSearch[fieldPathname].Branch.toLowerCase())
        );
      }
      if (tableSearch[fieldPathname].Location) {
        list = list.filter(v => 
          v.locationName?.toLowerCase()?.includes(tableSearch[fieldPathname].Location.toLowerCase())
        );
      }
    }

    return list;
  } 

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getBranchesMap = async () => {
    const response = await getBranches();
    if (response?.status !== "Fail") {
      return response.reduce((map, branch) => {
        map[branch._id] = {
          name: branch.name,
          locations: branch.location, // assuming location is an array or string
        };
        return map;
      }, {});
    } else {
      toastify(response?.message, "error");
      return {};
    }
  };

  const populateData = async () => {
  
    const branchMap = await getBranchesMap(); // Fetch and map branches
    const response = await getProducts();
  
    if (response?.status !== "Fail") {
      const productsWithNames = response.reverse().map(product => {
        const branch = branchMap[product.branch._id]; 
        return {
          ...product,
          productType: product.type?.name,
          branchName: branch?.name || "Unknown Branch",
          locationName: branch?.locations?.find(loc => loc._id === product.location)?.name || "Unknown Location",
        };
      });
  
      setProducts(productsWithNames);
    } else {
      if (response?.message === "No data Found") {
        setProducts([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  
  };


  // const populateData = async () => {
  //   const response = await getProducts();
  //   if (response?.status !== "Fail") {
  //     setProducts(response.reverse().map((r) => {
  //       return {
  //         ...r,
  //         productType: r.type?.name,
  //       }
  //     }));
  //   } else {
  //     if (response?.message === "No data Found") {
  //       setProducts([]);
  //     } else {
  //       toastify(response?.message, "error");
  //     }
  //   }
  // };

  const removeProduct = async () => {
    setShow(false);
    let response = await updateProducts(deleting, { isDeleted: true });
    if (response?.status !== "Fail") {
      populateData();
      setFilteredData((prev) => prev.filter((e) => e._id !== deleting));
      toastify("Product deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editProduct = (e) => {
    navigate("/add-inventory-products", {
      state: {
        product: e,
      },
    });
  };

  const addProduct = () => {
    navigate("/add-inventory-products");
  };

  useEffect(() => {
    populateData();
  }, []);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly25">
          <span className="me-2">Code</span>
          {
            <FilterComponent
              type="search"
              array={products}
              setArray={setFilteredData}
              value="Code"
              searchValue={tableSearch[fieldPathname]?.Code}
            />
          }
        </div>
      ),
      selector: (row) => row.code,
      wrap: true,
      sortable: true,
      width: "160px",
      sortFunction: (a, b) => customStringSort(a, b, "code"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly26">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={products}
              setArray={setFilteredData}
              value="Name"
              searchValue={tableSearch[fieldPathname]?.Name}
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      width: "200px",
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "UOM",
      selector: (row) => row.uom?.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "uom.name", true),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly27">
          <span className="me-2">Type</span>
          {
            <FilterComponent
              type="radio"
              array={products}
              setArray={setFilteredData}
              options={[...new Set(products.map((e) => e.type.name))]}
              value="ProductType"
              searchRadio={tableSearch[fieldPathname]?.ProductType}
            />
          }
        </div>
      ),
      selector: (row) => row.type?.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "type.name", true),
    },
    {
      name: "Qty",
      selector: (row) => row.quantity?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "quantity"),
    },
    {
      name: "Average Unit Cost",
      selector: (row) => row.averageUnitCost?.toLocaleString(),
      wrap: true,
      width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "averageUnitCost"),
    },
    {
      name: "Unit Selling Price",
      selector: (row) => row.unitSellingPrice?.toLocaleString(),
      wrap: true,
      width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "unitSellingPrice"),
    },
    {
      name: "Total Selling Price",
      selector: (row) => row.totalSellingPrice?.toLocaleString(),
      wrap: true,
      width: "170px",
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "totalSellingPrice"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      width: "180px",
    },
  ];
  
  const data = getDefaultFilteredData(products)?.map(
    (e, index) => {
      return {
        index: index,
        id: e._id,
        code: e.code,
        name: e.name,
        description: e?.description,
        quantity: e.qty,
        type: e.type,
        averageUnitCost: e.averageUnitCost,
        unitSellingPrice: e.unitSellingPrice,
        totalSellingPrice: e.totalSellingPrice,
        branch: e.branchName,
        location: e.locationName,
        action: (
          <div className="d-flex">
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                editProduct(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() =>
                navigate("/product-details", {
                  state: {
                    product: e,
                  },
                })
              }
            >
              <i class="fa-regular fa-eye"></i>
            </div>
          </div>
        ),
        totalCost: e?.totalCost,
        qty: e?.qty,
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    let formData = new FormData();
    formData.append("csvFile", selectedFile);
    migrateProducts(formData).then((res) => {
      populateData();
      toastify(res, "success");
    });
  };
  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Inventory / Products</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  return {
                    Code: e.code,
                    Name: e.name,
                    Description: e?.description,
                    Type: e?.type?.name,
                    "Quantity ": e?.qty,
                    "Unit Selling Price": e.unitSellingPrice,
                    "Unit Cost": e?.totalCost,
                    Branch: e.branch,       
                    Location: e.location,  
                  };
                });
                exportData(tempDataArr, "Products", "Products");
              }}
            >
              Export
            </Button>
            <MuiButton
              className="addItem me-3"
              component="label"
              role={undefined}
              variant="contained"
              sx={{ backgroundColor: "#295381" }}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Import
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </MuiButton>
            <Button className="addItem" onClick={addProduct}>
              + New Product
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Product Deletion?"
          onYes={removeProduct}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}

