import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Form, InputGroup, Accordion } from "react-bootstrap";

import { convertDateTime, convertToDefaultDate } from "../../../helperFunctions/utils";

const HalfWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 25px;

  label {
    color: inherit;
  }
`;

export default function ModalDetail({ 
  open,
  setOpen,
  detail,
}) {
  const renderPunchDetail = (label, dateTime, imgSrc) => {
    return (
      <div>
        <Form.Group>
          <Form.Label className="headings2">
            {label}
          </Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              value={dateTime}
              disabled={true}
            />
          </InputGroup>
        </Form.Group>
        {
          imgSrc ? (
            <img src={imgSrc} alt="punch-in" />
          ) : (
            ""
          )
        }
      </div>
    )
  }

  return (
    <React.Fragment>
      <Modal
        centered
        show={open}
        size="xl"
        onHide={() => {
          setOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <span style={{fontWeight: "bold", fontSize: 18}}>
            Attendance Detail
          </span>
        </Modal.Header>
        <Modal.Body className="font-18">
          <HalfWrapper>
            <div>
              <Form.Group>
                <Form.Label className="headings2">
                  Username
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    value={detail?.user?.userName}
                    disabled={true}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            <div>
              <Form.Group>
                <Form.Label className="headings2">
                  Date
                </Form.Label>
                <InputGroup className="mb-3">
                  <Form.Control
                    value={convertToDefaultDate(detail?.punchInDateTime)}
                    disabled={true}
                  />
                </InputGroup>
              </Form.Group>
            </div>
            {renderPunchDetail("Punch In", convertDateTime(detail?.punchInDateTime), detail?.punchInImage)}
            {renderPunchDetail("Punch Out", convertDateTime(detail?.punchOutDateTime), detail?.punchOutImage)}
          </HalfWrapper>

          <div className="mt-4">
            <Accordion>
              {
                detail?.breakTime?.map((breakItem, index) => (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>Break Time {index + 1}</Accordion.Header>
                    <Accordion.Body>
                      <HalfWrapper>
                        {renderPunchDetail("Break Start Time", convertDateTime(breakItem?.startTime), breakItem?.startImage)}
                        {renderPunchDetail("Punch End Time", convertDateTime(breakItem?.endTime), breakItem?.endImage)}
                      </HalfWrapper>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              }
            </Accordion>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}
