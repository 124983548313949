import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { createConsents, updateConsents } from "../../../apis/consents";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddEditConsent() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [title, setTitle] = useState("");

  const save = async () => {
    if (!title) {
      missingPopup("Title");
    } else {
      let data = {
        title,
        url: JSON.stringify(convertToRaw(editorState.getCurrentContent())),
      };

      if (state?.consent) {
        const response = await updateConsents(state?.consent?._id, data);
        if (response?.status !== "Fail") {
          toastify("Consent updated successfully!", "success");
          navigate("/consent-settings");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await createConsents(data);
        if (response?.status !== "Fail") {
          toastify("Consent added successfully!", "success");
          navigate("/consent-settings");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const updateTextDescription = (data) => {
    setEditorState(data);
  };

  const populateConsent = (data) => {
    setEditorState(
      EditorState.createWithContent(convertFromRaw(JSON.parse(data.url)))
    );
    setTitle(data.title);
  };

  useEffect(() => {
    if (state?.consent) {
      populateConsent(state.consent);
    }
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.consent ? "Edit " : "New "}`}
          Consents
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit">
            <Form.Group className="">
              <Form.Label className="headings2">Title *</Form.Label>
              <InputGroup className="mb-4">
                <Form.Control
                  placeholder="Write..."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </InputGroup>
            </Form.Group>

            <Editor
              editorState={editorState}
              onEditorStateChange={updateTextDescription}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "textAlign",
                  // "colorPicker",
                  // "embedded",
                  "link",
                  "emoji",
                  "image",
                  "remove",
                  "history",
                ],
              }}
              editorStyle={{
                minHeight: 200,
                background: "#f9f9fb",
              }}
              wrapperStyle={{
                overflow: "hidden",
                borderTop: "2px solid #e9e9e9",
                padding: "30px 0px",
                overflowX: "scroll",
                marginBottom: "30px",
              }}
            />
            <Button className="addItem" onClick={save}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
