import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getConsents, updateConsents } from "../../../apis/consents";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customDateSort,
	customStringSort,
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";

function ConsentSettings() {
	const navigate = useNavigate();
	const [consents, setConsents] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getConsentsList = async () => {
		const response = await getConsents();
		if (response?.status !== "Fail") {
			setConsents(response?.reverse());
		} else {
			if (response?.message === "No data Found") {
				setConsents([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	useEffect(() => {
		getConsentsList();
	}, []);

	const addConsent = () => {
		navigate("/add-consent");
	};

	const editConsent = (consent) => {
		navigate("/add-consent", {
			state: {
				consent,
			},
		});
	};

	const removeConsent = async (id) => {
		setShow(false);

		const response = await updateConsents(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			toastify("Consent deleted successfully!", "success");
			getConsentsList();
		} else {
			toastify(response?.message, "error");
		}
	};

	const columns = [
		{
			name: "Name",
			selector: (row) => <div className="text-center">{row.title}</div>,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "title"),
		},
		{
			name: "Created At",
			selector: (row) => {
				return new Date(row?.createdAt)?.toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				});
			},
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "createdAt", true),
		},

		{
			name: "Created By",
			selector: (row) => {
				return row?.createdBy;
			},
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "createdBy"),
		},

		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = consents?.map((e, index) => {
		return {
			index: index,
			id: e._id,
			title: e.title,
			createdAt: e.created_at,
			createdBy: e.createdBy.username,
			action: (
				<div className="d-flex">
					<div
						className="mx-3 cursor-pointer icon-width"
						onClick={() => {
							editConsent(e);
						}}
					>
						<i className="fa fa-pen"></i>
					</div>
					<div
						className="mx-3 cursor-pointer icon-width"
						onClick={() => {
							setDeleting(e._id);
							setShow(true);
						}}
					>
						<i className="fa fa-trash"></i>
					</div>
				</div>
			),
		};
	});

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Consents</h1>
					<Button className="addItem" onClick={addConsent}>
						+ Add New
					</Button>
				</div>

				<DeleteModal
					content="Confirm Consent Deletion?"
					onYes={removeConsent}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}

export default ConsentSettings;
