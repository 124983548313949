import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBranches } from "../../../../apis/branch";
import { getConsents } from "../../../../apis/consents";
import {
  createVendorContact,
  updateVendorContact,
} from "../../../../apis/vendorContact";
import { getProducts } from "../../../../apis/products";
import { authorizedRequests } from "../../../../axios/axios-config";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../../helperFunctions/toastify";
import SelectComponent from "../../../SharedComponents/SelectComponent";
import { ROLES } from "../../../../constants";
import { capCaseString } from "../../../../helperFunctions/utils";
import { setFormData } from "../../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditVendorContact() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  const navigate = useNavigate();

  const [consents, setConsents] = useState([]);

  const [allBranches, setAllBranches] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [branch, setBranch] = useState();
  const [tagOptions, setTagOptions] = useState([]);

  const [productsList, setProductList] = useState();
  // const [selectedProducts, setSelectedProducts] = useState([]);
  const [stateData, setStateData] = useState({
    compName: "",
    compTypesOfProducts: [],
    compSales: "",
    compNumber: "",
    compDescription: "",
  });

  useEffect(() => {
    if (!state?.treatment?._id) {
      formDataChangedRef.current = {
        ...stateData
      }
    }
  }, [stateData]);

  useEffect(() => {
    return () => {
      if (!state?.treatment?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.treatment?._id) {
      const reduxData = formData?.[pathname];
      if (reduxData) {
        setStateData({
          compName: reduxData?.compName,
          compTypesOfProducts: reduxData?.compTypesOfProducts ? reduxData?.compTypesOfProducts : [],
          compSales: reduxData?.compSales,
          compNumber: reduxData?.compNumber,
          compDescription: reduxData?.compDescription,
        });
      }
    }
  }, []);

  // const populateData = async (dataTreatment) => {
  //   const filteredData = productsList?.filter((item) =>
  //     (dataTreatment?.compTypesOfProducts ?? [])?.includes(item._id)
  //   );

  //   if (filteredData) {
  //     setSelectedProducts(filteredData?.map((item) => item.name));
  //   }
  // };
  // useEffect(() => {}, [state?.treatment?.compTypesOfProducts]);

  const handleInputs = (e) => {
    setStateData({ ...stateData, [e.target.name]: e.target.value });
  };

  const saveVendorContact = async () => {
    if (!stateData.compName) {
      missingPopup("compName");
    } else if (!stateData.compTypesOfProducts?.length) {
      missingPopup("compTypesOfProducts");
    } else if (!stateData.compSales) {
      missingPopup("compSales");
    } else if (!stateData.compNumber) {
      missingPopup("compNumber");
    } else {
      if (!state?.treatment) {
        const response = await createVendorContact(stateData);
        if (response?.status !== "Fail") {
          formDataChangedRef.current = null;
          dispatch(setFormData({
            ...formData,
            [pathname]: null
          }));

          toastify("Vendor Contact added successfully!", "success");
          navigate("/vendor-contact");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateVendorContact(
          state?.treatment?._id,
          stateData
        );
        if (response?.status !== "Fail") {
          toastify("Vendor Contact updated successfully!", "success");
          navigate("/vendor-contact");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const populateConsents = async () => {
    const response = await getConsents();
    if (response?.status !== "Fail") {
      setConsents(response);
    } else {
      toastify(response?.message, "error");
    }

    const response2 = await getBranches();
    if (response2?.status !== "Fail") {
      setAllBranches(response2);
    } else {
      toastify(response2?.message, "error");
    }
  };

  const getProductsList = async () => {
    const response = await getProducts();
    if (response?.status !== "Fail") {
      setProductList(response.reverse());
    } else {
      if (response?.message === "No data Found") {
        setProductList([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  useEffect(() => {
    getProductsList();
  }, []);

  useEffect(() => {
    populateConsents();
    // if (state?.treatment) populateData(state?.treatment);
  }, []);

  useEffect(() => {
    if (branch) {
      let _locations = allBranches?.find((e) => e._id === branch);
      setAllLocations(_locations?.location);
    }
  }, [branch]);

  useEffect(() => {
    (async () => {
      const { result, error } = await authorizedRequests({
        url: "tags/listOfTags",
        method: "get",
      });
      if (error) {
        toastify(error.message ?? "", "error");
        return;
      }

      const tempArr = result?.data?.map((o) => {
        return {
          value: o?._id,
          label: o.name,
        };
      });

      setTagOptions([...tempArr]);
    })();
  }, []);

  useEffect(() => {
    if (state?.treatment) {
      setStateData(state?.treatment);
      // setSelectedProducts(state?.treatment?.compTypesOfProducts ?? []);
    }
  }, [state]);

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.treatment?._id,
      type: "Vendor",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.treatment ? "Edit " : "New "}`}
            Vendor Contact
          </h1>

          {state?.treatment && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      name="compName"
                      value={stateData.compName}
                      onChange={handleInputs}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className=" mt-3 w-full">
                  <Form.Label className="headings2">
                    Type of Product *
                  </Form.Label>
                  <select
                    className="form-select mb-3"
                    aria-label="Default select example"
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val !== "" && !stateData?.compTypesOfProducts?.includes(val)) {
                        setStateData({ ...stateData, compTypesOfProducts: [...stateData.compTypesOfProducts, val] });
                      }
                    }}
                  >
                    <option value={""}>Select</option>
                    {productsList?.map((e) => (
                      <option value={e?.name}>{e?.name}</option>
                    ))}
                  </select>
                  <div className="d-flex flex-wrap">
                    {stateData?.compTypesOfProducts?.map((e) => (
                      <div className="multi-roles-parent">
                        {e}
                        <div className="multi-roles-child">
                          <i
                            className="fa-solid fa-xmark"
                            onClick={() => {
                              let _data = [
                                ...stateData.compTypesOfProducts,
                              ].filter((f) => f !== e);
                              console.log("DEBUG", _data)
                              setStateData({ ...stateData, compTypesOfProducts: _data });
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2 mt-3">
                    Sales Representative *
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      name="compSales"
                      value={stateData.compSales}
                      onChange={handleInputs}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2 mt-3">
                    Contact Number *
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      name="compNumber"
                      value={stateData.compNumber}
                      onChange={handleInputs}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2">Note</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      name="compDescription"
                      value={stateData.compDescription}
                      onChange={handleInputs}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveVendorContact}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
