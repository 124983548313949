const { authorizedRequests } = require("../axios/axios-config");

const createActiveStaff = async (data) => {
	const response = await authorizedRequests({
		url: "activeStaff/createActiveStaff",
		method: "post",
		data: data,
	});
	return response;
};

const getActiveStaffByDate = async (data) => {
	const response = await authorizedRequests({
		url: "activeStaff/getActiveStaffByDate",
		method: "post",
		data: data,
	});
	return response;
};

export { createActiveStaff, getActiveStaffByDate };
