import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import {
	authorizedRequests,
	unAuthorizesRequests,
} from '../../../axios/axios-config';
import { toastify } from '../../../helperFunctions/toastify';
import ResetPassword from './ResetPassword';

const resetPasswordStates = Object.freeze({
	VERIFY_OTP: 'verify-otp',
	RESET_PASSWORD: 'reset-password',
});
function VerifyOTP() {
	const [otp, setOTP] = useState();
	const [resendButton, setResendButton] = useState(false);
	const [currentState, setCurrentState] = useState(
		resetPasswordStates.VERIFY_OTP,
	);

	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { result, error } = await authorizedRequests({
				url: 'auth/verifyforgotpin',
				method: 'Post',
				data: {
					pinCode: otp,
				},
				token: JSON.parse(localStorage.getItem('forgot_user')).token,
			});
			if (error) {
				toastify(error?.message ?? 'Unknown Error Occured', 'error');
				return;
			}
			setCurrentState(resetPasswordStates.RESET_PASSWORD);
			// localStorage.removeItem('forgot_user');
			// navigate('/reset-password');
		} catch (error) {
			// console.log(JSON.parse(localStorage.getItem('forgot_user')).token);
			toastify(error?.message ?? 'Unknown Error Occured', 'error');
		}
	};

	const resendOTP = async () => {
		setResendButton(false);
		try {
			const { result, error } = await unAuthorizesRequests({
				url: 'auth/forgotemail',
				method: 'Post',
				data: {
					email: JSON.parse(localStorage.getItem('forgot_user'))
						.email,
				},
			});
			if (error) {
				toastify(error.message, 'error');
				return;
			}
			localStorage.setItem(
				'forgot_user',
				JSON.stringify({
					token: result.data.token,
					email: JSON.parse(localStorage.getItem('forgot_user'))
						.email,
				}),
			);
			navigate('/verify-otp');
		} catch (error) {
			toastify(error.message, 'error');
		}
	};
	useEffect(() => {
		const forgot_user_token = JSON.parse(
			localStorage.getItem('forgot_user'),
		);
		if (!forgot_user_token.token || !forgot_user_token.token) {
			navigate('/forget-password');
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setResendButton(true);
		}, 3000);
	}, [resendButton]);

	if (currentState === resetPasswordStates.VERIFY_OTP) {
		return (
			<div className='forgetPassword'>
				<div className='row mx-0 forgetPassword'>
					<div className='col-12 my-auto'>
						<div className='text-center py-5'>
							<img src='/imgs/logo.svg' alt='' />
						</div>
						<div className='row mx-0'>
							<div className='col-md-6 mx-auto'>
								<div className='loginBox'>
									<h3>Verify OTP</h3>
									<div className='row'>
										<div className='col-md-12 mx-auto'>
											<Form
												className='mt-5'
												onSubmit={handleSubmit}
											>
												<Form.Group
													className='mb-0'
													controlId='formBasicPassword'
												>
													<Form.Label>OTP</Form.Label>
													<InputGroup className='mb-4'>
														<Form.Control
															type='number'
															value={otp}
															onChange={(e) => {
																if (
																	e.target
																		.value <=
																	999999
																) {
																	setOTP(
																		e.target
																			.value,
																	);
																}
															}}
															placeholder='Enter your 6 digit OTP'
														/>
													</InputGroup>
												</Form.Group>
												<p
													className='forgotpassword'
													onClick={() => {
														if (resendButton) {
															resendOTP();
														}
													}}
												>
													Resend OTP
												</p>
												<div className='mt-5 mb-3'>
													<Button
														className='loginBtn'
														type='submit'
													>
														Submit
													</Button>
												</div>
											</Form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else if (currentState === resetPasswordStates.RESET_PASSWORD) {
		return (
			<>
				<ResetPassword />
			</>
		);
	} else {
		return <>asd</>;
	}
}

export default VerifyOTP;
