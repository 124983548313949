import React from "react";

// export default function CustomTable({ data, editLocation, deleteLocation }) {
//   return (
//     <div style={{ overflowX: "scroll" }} className="my-4">
//       <div className="custom-table-head-2">
//         <div>
//           <p className="cw-180">Name</p>
//         </div>
//         <div>
//           <p className="cw-180">Description</p>
//         </div>
//         <div>
//           <p className="cw-180">Rooms</p>
//         </div>
//         <div>
//           <p className="cw-160">Action</p>
//         </div>
//       </div>
//       {data?.map(({ name, description, rooms, _id }) => (
//         <div className="custom-table-cells-2">
//           <div>
//             <p className="cw-180">{name}</p>
//           </div>
//           <div>
//             <p className="cw-180">{description}</p>
//           </div>
//           <div>
//             {rooms.map((f) => (
//               <div className="cw-180">{f}</div>
//             ))}
//           </div>

//           <div className="d-flex cw-160">
//             <div
//               className="mx-3  cursor-pointer icon-width"
//               onClick={() => {
//                 editLocation(name);
//               }}
//             >
//               <i className="fa fa-pen"></i>
//             </div>
//             <div
//               className="mx-3  cursor-pointer icon-width"
//               onClick={() => {
//                 deleteLocation(name);
//               }}
//             >
//               <i className="fa fa-trash"></i>
//             </div>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// }

export default function CustomTable({ data, editLocation, deleteLocation, staffOptions }) {
  return (
    <div style={{ overflowX: "scroll" }} className="my-4">
      <div className="custom-table-head-2" style={{width: "fit-content"}}>
        <div>
          <p className="cw-180">Name</p>
        </div>
        <div>
          <p className="cw-180">Description</p>
        </div>
        <div>
          <p className="cw-180">Rooms</p>
        </div>
        <div>
          <p className="cw-180">Staff</p> {/* New column header for Staff */}
        </div>
        <div>
          <p className="cw-160">Action</p>
        </div>
      </div>
      {data?.map(({ name, description, rooms, staff, _id }) => (
        <div className="custom-table-cells-2" style={{width: "fit-content"}} key={_id}>
          <div>
            <p className="cw-180">{name}</p>
          </div>
          <div>
            <p className="cw-180">{description}</p>
          </div>
          {/* <div>
            {rooms.map((room, index) => (
              <div className="cw-180" key={index}>{room}</div>
            ))}
          </div> */}
          <div>
            {rooms.length > 0
              ? rooms.map((room, index) => (
                  <div className="cw-180" key={index}>
                    {room}
                  </div>
                ))
              : (
                  <div className="cw-180"></div>
                )}
          </div>
          {/* <div>
            {staff.map((staffId) => {
              const staffMember = staffOptions.find(option => option.value === staffId);
              return (
                <p key={staffId} className="cw-180">{staffMember ? staffMember.label : staffId}</p>
              );
            })}
          </div> */}
          <div>
            {staff.length > 0
              ? staff.map((staffId) => {
                  const staffMember = staffOptions.find(option => option.value === staffId);
                  return (
                    <p key={staffId} className="cw-180">
                      {staffMember ? staffMember.label : staffId}
                    </p>
                  );
                })
              : (
                  <div className="cw-180"></div>
                )}
          </div>
          <div className="d-flex cw-160">
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                editLocation(name);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                deleteLocation(name);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
