import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { addMailchimpTemplate } from "../../../apis/mailchimp";
import { toastify } from "../../../helperFunctions/toastify";
import { requiredFieldValidation } from "../../../helperFunctions/validation";

export default function CreateTemplates() {
	const [values, setValues] = useState({
		name: "",
		heading: "",
		content: "",
	});

	const onFormChange = (name, value) => {
		setValues({
			...values,
			[name]: value,
		});
	};

	const submitForm = async (e) => {
		const validation = requiredFieldValidation(
			{
				name: "Name",
				value: values.name,
			},
			{
				name: "Heading",
				value: values.heading,
			},
			{
				name: "Content",
				value: values.content,
			},
		);
		if (!validation.status) {
			toastify(validation.message, "error");
			return;
		}
		const { result, error } = await addMailchimpTemplate({
			templateName: values.name,
			templateContent: `<h1>${values.heading}</h1><p>${values.content}</p>`,
		});
		if (error) {
			toastify(error.message ?? "Failed to add template", "error");
			return;
		}
		toastify("Template added successfully", "success");
		setValues({
			name: "",
			heading: "",
			content: "",
		});
		return;
	};
	return (
		<>
			<div className="mainHeading px-4">
				<h1>Create Template</h1>
			</div>
			<div className="px-4 pb-4">
				<Form.Group className="col-md-6">
					<Form.Label className="required black-label-text">
						Template Name
					</Form.Label>
					<InputGroup className="mb-3 w-75">
						<Form.Control
							name={"name"}
							placeholder="Write..."
							onChange={(e) => {
								onFormChange("name", e.target.value);
							}}
							value={values.name}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group className="col-md-6">
					<Form.Label className="required black-label-text">
						Heading
					</Form.Label>
					<InputGroup className="mb-3 w-75">
						<Form.Control
							name={"heading"}
							placeholder="Write..."
							onChange={(e) => {
								onFormChange("heading", e.target.value);
							}}
							value={values.heading}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group className="col-md-6">
					<Form.Label className="required black-label-text">
						Content
					</Form.Label>
					<InputGroup className="mb-3 w-75">
						<Form.Control
							name={"content"}
							as="textarea"
							style={{ resize: "none" }}
							placeholder="Write..."
							onChange={(e) => {
								onFormChange("content", e.target.value);
							}}
							value={values.content}
						/>
					</InputGroup>
				</Form.Group>
				<Button
					className="addItem"
					onClick={submitForm}
					style={{ width: "150px" }}
				>
					Save
				</Button>
			</div>
		</>
	);
}
