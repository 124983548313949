import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { requiredFieldValidation } from "../../../helperFunctions/validation";
import {
  resetValue,
  setData,
  setForm,
  setWaitingListFormData,
} from "../../../redux/slices/StaffFormsSlices";
import TimePicker from "react-bootstrap-time-picker";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useEffect, useMemo, useState, useRef } from "react";
import { editWaiting } from "../../../apis/waiting";
import DateInput from "../../SharedComponents/DateInput";
import { setFormData } from "../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../apis/appointment";

export default function AddEditWaitingList(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const formDataChangedRef = useRef();
  const { state } = location;

  const navigate = useNavigate();

  const { waitingListForm } = useSelector((state) => state.staffForm);
  const [showED, setShowED] = useState(false);
  const [holidayDates, setHolidayDates] = useState([]);
  const [waitingListFormValues, setWaitingListFormValues] = useState({
    ...waitingListForm,
  });

  const pathname = location.pathname;
  const { formData } = useSelector((state) => state.commonData);

  useEffect(() => {
    if (!state?.data?.dataId && !props.isModalWaitingOpen) {
      formDataChangedRef.current = {
        ...waitingListFormValues
      }
    }
  }, [waitingListFormValues]);

  useEffect(() => {
    return () => {
      if (!state?.data?.dataId && !props.isModalWaitingOpen && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.data?.dataId && !props.isModalWaitingOpen) {
      const reduxData = formData?.[pathname];
      if (reduxData) {
        dispatch(
          setData({
            parent: "waitingListForm",
            value: {
              patientName: reduxData?.patientName,
              startTime: reduxData?.startTime,
              endTime: reduxData?.endTime,
              contactNumber: reduxData?.contactNumber,
              remarks: reduxData?.remarks,
              date: reduxData?.date,
              gender: reduxData?.gender,
              dataId: reduxData?.dataId,
              formStatus: "Add"
            },
          })
        );
      }
    }
  }, []);

  const onFormChange = (e) => {
    const { value, name } = e.target;
    // waitingListForm = { ...waitingListForm, [name]: value }
    setWaitingListFormValues((prev) => ({ ...prev, [name]: value }));
    // dispatch(setForm({ parent: "waitingListForm", child: name, value }));
  };
  const handleClickAway1 = () => {
    setShowED(false);
  };

  const getListOfHolidays = async () => {
    const { result, error } = await authorizedRequests({
      url: "holidays/listOfHolidays",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "", "error");
      return;
    }
    const dates = result.data?.map((d) => {
      return new Date(d.eventDate);
    });
    setHolidayDates(dates);
  };

  const shouldDisabledDate = (date) => {
    if (!date?.$d) {
      return true;
    }
    if (!(date?.$d instanceof Date && !isNaN(date?.$d))) {
      return true;
    }
    const dates = holidayDates?.some(
      (disabledDate) =>
        disabledDate.getDate() === date?.$d?.getDate() &&
        disabledDate.getMonth() === date?.$d?.getMonth() &&
        disabledDate.getFullYear() === date?.$d?.getFullYear()
    );
    return dates;
  };

  const formatTime = (timeStr) => {
    let timeArray = timeStr.split(":");
    let hours = timeArray[0];
    let minutes = timeArray[1];

    if (hours.length === 1) {
      hours = "0" + hours;
    }

    return hours + ":" + minutes;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    dispatch(setWaitingListFormData(waitingListFormValues));
    const formObj = {
      ...waitingListFormValues,
      startTime: formatTime(waitingListFormValues.startTime),
      endTime: formatTime(waitingListFormValues.endTime),
    };

    delete formObj.formStatus;
    if (formObj.startTime == formObj.endTime) {
      toastify("Time in can't be equal to Time out", "error");
      return;
    }
    const validation = requiredFieldValidation(
      {
        name: "Patient",
        value: formObj.patientName,
      },
      {
        name: "Contact",
        value: formObj.contactNumber,
      },
      {
        name: "End Time",
        value: formObj.endTime,
      },
      {
        name: "Start Time",
        value: formObj.startTime,
      },
      {
        name: "Date",
        value: formObj.date,
      }

      // formObj.endTime,
      // formObj.startTime,
      // formObj.date,
    );
    if (!validation.status) {
      toastify(validation.message, "error");
      return;
    }
    if (formObj?.dataId) {
      const response = await editWaiting({
        ...formObj,
      });
      if (response.status !== "Fail") {
        toastify("Updated successfully", "success");

        if (props.setRefreshWaiting) {
          props.setRefreshWaiting((val) => !val);
          props.setIsModalWaitingOpen(false);
          return;
        }

        if (!waitingListFormValues.isRostering) {
          navigate("/waiting-list");
        } else {
          navigate(-1);
        }
        return;
      } else {
        toastify(response?.message ?? "Failed to update data", "error");
        return;
      }
    }
    const { result, error } = await authorizedRequests({
      url: "waitingList/createWaitingList",
      method: "post",
      data: formObj,
    });
    if (error) {
      toastify(error.message ?? "Unknown error occured", "error");
      return;
    }

    formDataChangedRef.current = null;
    dispatch(setFormData({
      ...formData,
      [pathname]: null
    }));
    dispatch(resetValue("waitingListForm"));
    toastify("Added succesfully", "success");

    if (props.setRefreshWaiting) {
      props.setRefreshWaiting((val) => !val);
      props.setIsModalWaitingOpen(false);
      return;
    }

    navigate(-1);
  };
  useMemo(() => {
    if (location?.state?.data) {
      dispatch(
        setData({
          parent: "waitingListForm",
          value: {
            patientName: location?.state?.data?.patientName,
            startTime: location?.state?.data?.startTime,
            endTime: location?.state?.data?.endTime,
            contactNumber: location?.state?.data?.contactNumber,
            remarks: location?.state?.data?.remarks,
            date: location?.state?.data?.date,
            gender: location?.state?.data?.gender,
            dataId: location?.state?.data?.dataId,
            formStatus: "Edit",
          },
        })
      );
    }
  }, [location?.state?.data]);

  useEffect(() => {
    setWaitingListFormValues({ ...waitingListForm });
  }, [waitingListForm]);

  useEffect(() => {
    getListOfHolidays();
  }, []);

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: location?.state?.data?.dataId,
      type: "Waiting",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            ></i>
            {`${waitingListFormValues.formStatus + " "}`}
            Waiting List
          </h1>
          {waitingListFormValues.formStatus == "Edit" && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text required">
                    Patient Name
                  </Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name={"patientName"}
                      placeholder="Write..."
                      onChange={(e) => {
                        onFormChange(e);
                      }}
                      value={waitingListFormValues.patientName}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text required">
                    Contact
                  </Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name={"contactNumber"}
                      // type="text"
                      placeholder="Write..."
                      value={waitingListFormValues.contactNumber}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          onFormChange(e);
                        }
                      }}
                    />
                  </InputGroup>
                </Form.Group>
                <label>Gender</label>
                <Form.Group className="ms-2">
                  <Form.Check
                    inline
                    name="gender"
                    type={"radio"}
                    onClick={(e) => {
                      onFormChange({
                        target: {
                          name: "gender",
                          value: "Male",
                        },
                      });
                    }}
                    checked={waitingListFormValues.gender === "Male"}
                  />
                  <span className="font-400-20" style={{ fontSize: "16px" }}>
                    <b>Male</b>
                  </span>
                </Form.Group>
                <Form.Group className="ms-2 mb-3">
                  <Form.Check
                    inline
                    name="gender"
                    type={"radio"}
                    onClick={(e) => {
                      onFormChange({
                        target: {
                          name: "gender",
                          value: "Female",
                        },
                      });
                    }}
                    checked={waitingListFormValues.gender === "Female"}
                  />
                  <span className="font-400-20" style={{ fontSize: "16px" }}>
                    <b>Female</b>
                  </span>
                </Form.Group>

                <Form.Group className=" col-md-6">
                  <div className="w-75">
                    <label>Date *</label>
                    <div className="date align-self-center white cursor-pointer mb-3">
                      <div>
                        <DateInput
                          // background={"#34556f"}
                          // calenderClass={"whiteCalender"}
                          inputClassName="themeInput"
                          value={waitingListFormValues.date}
                          onChange={(newValue) => {
                            onFormChange({
                              target: {
                                name: "date",
                                value: newValue,
                              },
                            });
                          }}
                          shouldDisableDates={true}
                        />
                      </div>
                    </div>
                  </div>
                </Form.Group>
                {/* <Form.Group className="col-md-6">
									<Form.Label className="black-label-text required">
										Date
									</Form.Label>
									<Form.Control
										type="date"
										name="date"
										// selectedValue={appointmentForm.date}
										className="modalTextField mb-3"
										style={{
											paddingRight: "6px",
											width: "75%",
										}}
										value={waitingListFormValues.date}
										onChange={(e) => {
											onFormChange(e);
										}}
									/>
								</Form.Group> */}
                <Form.Group className=" col-md-6">
                  <Form.Label className="black-label-text required">
                    Time Start
                  </Form.Label>
                  <div className="d-flex mb-3 w-75">
                    <TimePicker
                      start="09:00"
                      end="21:00"
                      step={30}
                      selectedValue={waitingListFormValues.startTime}
                      // disabled={appointmentForm.startTime}
                      className="modalTextField"
                      style={{
                        paddingRight: "6px",
                        height: "47px",
                        border: "1.4px solid #939393",
                      }}
                      value={waitingListFormValues.startTime}
                      onChange={(e) => {
                        let h = Math.floor(e / 3600);
                        h = h < 10 ? "0" + h : h;

                        let m = Math.floor((e % 3600) / 60);
                        m = m < 10 ? "0" + m : m;

                        onFormChange({
                          target: {
                            name: "startTime",
                            value: `${h}:${m}`,
                          },
                        });

                        // Adding 1 hour and 30 minutes to the input time
                        let totalSeconds = e + 1 * 3600 + 30 * 60;

                        let h_end = Math.floor(totalSeconds / 3600);
                        h_end = h_end < 10 ? "0" + h_end : h_end;

                        let m_end = Math.floor((totalSeconds % 3600) / 60);
                        m_end = m_end < 10 ? "0" + m_end : m_end;

                        // Update end time
                        if (h_end >= 21 && m_end >= 0) {
                          onFormChange("endTime", `21:00`);
                          onFormChange({
                            target: {
                              name: "endTime",
                              value: `21:00`,
                            },
                          });
                        } else {
                          onFormChange({
                            target: {
                              name: "endTime",
                              value: `${h_end}:${m_end}`,
                            },
                          });
                        }
                      }}
                    />

                    <TimePicker
                      start="09:00"
                      end="21:00"
                      step={30}
                      selectedValue={waitingListFormValues.endTime}
                      // disabled={appointmentForm.startTime}
                      className="modalTextField ms-2"
                      style={{
                        paddingRight: "6px",
                        height: "47px",
                        border: "1.4px solid #939393",
                      }}
                      value={waitingListFormValues.endTime}
                      onChange={(e) => {
                        let h = Math.floor(e / 3600);
                        h = h < 10 ? "0" + h : h;
                        let m = Math.floor((e % 3600) / 60);
                        m = m < 10 ? "0" + m : m;
                        onFormChange({
                          target: {
                            name: "endTime",
                            value: `${h}:${m}`,
                          },
                        });
                      }}
                    />
                    {/* <Form.Control
											type="time"
											className="modalTextField"
											name="startTime"
											style={{ paddingRight: "6px" }}
											onChange={(e) => {
												onFormChange(e);
											}}
										/>
										<Form.Control
											type="time"
											name="endTime"
											className="modalTextField ms-2"
											style={{ paddingRight: "6px" }}
											onChange={(e) => {
												onFormChange(e);
											}}
										/> */}
                  </div>
                </Form.Group>

                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text">Remarks</Form.Label>
                  <InputGroup className="mb-3 w-75">
                    <Form.Control
                      name={"remarks"}
                      as="textarea"
                      style={{ resize: "none" }}
                      placeholder="Write..."
                      onChange={(e) => {
                        onFormChange(e);
                      }}
                      value={waitingListFormValues.remarks}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <Button
              className="addItem mt-2"
              style={{ width: "150px" }}
              onClick={submitForm}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
