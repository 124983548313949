import { authorizedRequests } from "../axios/axios-config";
import api from "../axios/axios-config";

export const getAppointments = async () => {
  try {
    let response = await api.get(
      "appointment/listOfAppointments",

      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getEditBy = async (data) => {
  try {
    let response = await authorizedRequests({
      url: "appointment/geteditby",
      method: "post",
      data: data,
    });

    return response?.result?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAppointment = async (id) => {
  try {
    let response = await api.get(`appointment/getAppointment/${id}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getFilterAppointments = async (patientId) => {
  try {
    let response = await api.get(
      "appointment/filterAppointments",

      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
        params: {
          patient: patientId,
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateAppointments = async (data) => {
  try {
    let response = await authorizedRequests({
      url: "appointment/updateAppointment",
      method: "post",
      data: data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
