import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";

import { getDepartments, deleteDepartment } from "../../../apis/departments";
import { getStaff } from "../../../apis/staff";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

function Departments() {
  const navigate = useNavigate();
  const [departments, setDepartments] = useState([]);
  const [staff, setStaff] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getDepartmentList = async () => {
    const response = await getDepartments();
    if (response.status !== "Fail") {
      setDepartments(response);
    } else {
      if (response?.message === "No data Found") {
        setDepartments([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removeDepartment = async () => {
    setShow(false);
    const response = await deleteDepartment(deleting);
    if (response?.status !== "Fail") {
      getDepartmentList();
      toastify("Department deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editDepartment = (e) => {
    navigate("/add-department", {
      state: {
        id: e,
      },
    });
  };

  const populateStaff = async () => {
    const response = await getStaff();
    if (response?.status !== "Fail") {
      setStaff(response);
    } else {
      toastify(response?.message, "error");
    }
  };

  useEffect(() => {
    populateStaff();
    getDepartmentList();
  }, []);

  const addDepartment = () => {
    navigate("/add-department");
  };

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly4">
          <span className="me-2">Designation</span>
          {
            <FilterComponent
              type="search"
              array={departments}
              setArray={setFilteredData}
              value="Name"
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Name",
      selector: (row) => row.manegerText,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "manegerText"),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "description"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? departments : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        index: index,
        name: e.name,
        description: e.description,
        isDelete: e.isDelete,
        manegerText:
          staff[staff.findIndex((m) => m._id === e.manager)]?.username,
        isActive: e.isActive,
        manager: e.manager,
        action: (
          <div className="d-flex">
            <div
              className="mx-3 cursor-pointer icon-width"
              onClick={() => {
                editDepartment(e._id);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div className="mx-3 cursor-pointer icon-width">
              {e?.isDeleteAble === true && (
                <i
                  onClick={() => {
                    setDeleting(e._id);
                    setShow(true);
                  }}
                  className="fa fa-trash"
                ></i>
              )}
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Department</h1>
          <Button className="addItem" onClick={addDepartment}>
            + Department
          </Button>
        </div>

        <DeleteModal
          content="Confirm Department Deletion?"
          onYes={removeDepartment}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}

export default Departments;
