import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getDrawtool, updateDrawtool } from "../../../apis/drawtool";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customDateSort,
	customStringSort,
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";

export default function DrawtoolTemplate() {
	let navigate = useNavigate();
	const [templates, setTemplates] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");
	const [filteredData, setFilteredData] = useState([]);
	const [preview, setPreview] = useState();

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getDrawtoolTemplates = async () => {
		const response = await getDrawtool();
		if (response?.status !== "Fail") {
			setTemplates(response.reverse());
			console.log({ response });
		} else {
			if (response?.message === "No data Found") {
				setTemplates([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const addDrawtool = () => {
		navigate("/add-draw-tool");
	};

	const editTemplate = (e) => {
		navigate("/add-draw-tool", {
			state: {
				drawtool: e,
			},
		});
	};

	const removeTemplate = async (dataId) => {
		setShow(false);
		const response = await updateDrawtool({ isDeleted: true, dataId: deleting });
		if (response) {
			getDrawtoolTemplates();
			toastify("Drawtool deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	useEffect(() => {
		getDrawtoolTemplates();
	}, []);

	const columns = [
		{
			name: "Name",
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Description",
			selector: (row) => row.description,
			width: "300px",
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "description"),
		},
		{
			name: "Created At",
			selector: (row) => (
				<div className="d-flex align-items-center">
					<div> {new Date(row.createdAt).toLocaleTimeString()}</div>
					<div className="bold mx-1 font-18">|</div>
					<div>
						{" "}
						{new Date(row.createdAt).toLocaleDateString("en-GB", {
							day: "numeric",
							month: "numeric",
							year: "numeric",
						})}
					</div>
				</div>
			),
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "createdAt", true),
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? templates : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				index: index,
				name: e.title,
				description: e.description,
				createdAt: e.created_at,
				imageUrl: e.imageUrl,
				action: (
					<div className="d-flex align-items-center">
						<div
							className="icon-width"
							onClick={() => {
								editTemplate(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
						<div>
							<Button
								className="preview-btn ms-2"
								onClick={() => setPreview(e.imageUrl)}
							>
								Preview
							</Button>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<div className="container p-0">
			<div className="mainHeading px-4">
				<h1>Drawtool Template</h1>
				<Button className="addItem" onClick={addDrawtool}>
					+ Add New
				</Button>
			</div>

			<DeleteModal
				content="Confirm Drawtool Deletion?"
				onYes={removeTemplate}
				onNo={handleClose}
				show={show}
			/>

			<Modal centered show={preview} onHide={() => setPreview()}>
				<Modal.Body className="font-18 d-flex justify-content-center align-items-center">
					<img
						src={preview}
						key={Math.random() * 10}
						style={{ width: 200 }}
					/>
				</Modal.Body>
			</Modal>

			<DataTable
				columns={columns}
				data={data || []}
				pagination
				customStyles={customStyles}
				conditionalRowStyles={conditionalRowStyles}
			/>
		</div>
	);
}
