import api from "../axios/axios-config";

export const getUOM = async () => {
  try {
    let response = await api.get("uom/listOfUOM");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createUOM = async (data) => {
  try {
    let response = await api.post("uom/createUOM", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateUOM = async (dataId, data) => {
  try {
    let response = await api.post("uom/updateUOM", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
