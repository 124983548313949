import { authorizedRequests } from "../axios/axios-config";

export const getHearAboutUsList = async () => {
	const response = await authorizedRequests({
		url: "hearAboutUs/listHearAboutUs",
		method: "get",
	});
	return response;
};

export const updateHearAboutUs = async (data) => {
	const response = await authorizedRequests({
		url: "hearAboutUs/updateHearAboutUs",
		method: "post",
		data: {
			...data,
		},
	});
	return response;
};

export const createHearAboutUs = async (data) => {
	const response = await authorizedRequests({
		url: "hearAboutUs/createHearAboutUs",
		method: "post",
		data: {
			...data,
		},
	});
	return response;
};
