import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";

import SelectComponent from "../../SharedComponents/SelectComponent";

const StyledSelect = styled.div`
  .select__control {
    border-radius: 0px;
    height: 46px;
    border: 1px solid gray !important;
  }
`;

export default function SelectQuestion({ question, grpQuestion, options, onChange }) {
	return (
		<Col className="mb-3" md={question.size ? question.size : 12} key={question.question}>
			<div>
				<div className="question-header">
					{question?.question}
				</div>
				<StyledSelect className="pt-2">
          <SelectComponent
            singleSelect
            searchEnabled
            selectedValue={question.patient_answer}
            onChange={(e) => {
							onChange(
								question._id,
								e?.value,
								question?.questionType,
								grpQuestion,
							);
						}}
            options={options}
          />
				</StyledSelect>
			</div>
		</Col>
	);
}
