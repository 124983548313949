import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { createIpAccess, updateIpAccess } from "../../../apis/ipAccess";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddEditIpAccess() {
	const { state } = useLocation();
	const navigate = useNavigate();

	const [publicIp, setPublicIp] = useState("");
	const [description, setDescription] = useState("");

	const populateData = async () => {
		setPublicIp(state?.ipAccess?.publicIp);
		setDescription(state?.ipAccess?.description);
	};

	const saveIpAccess = async () => {
		if (!publicIp) {
			missingPopup("PublicIp");
		} else {
			let data = { publicIp, description };
			if (!state?.ipAccess) {
				const response = await createIpAccess(data);
				if (response?.status !== "Fail") {
					toastify("IpAccess added successfully!", "success");
					navigate("/ipAccess");
				} else {
					toastify(response?.message, "error");
				}
			} else {
				const response = await updateIpAccess(
					state?.ipAccess?._id,
					data,
				);
				if (response?.status !== "Fail") {
					toastify("IpAccess updated successfully!", "success");
					navigate("/ipAccess");
				} else {
					toastify(response?.message, "error");
				}
			}
		}
	};

	useEffect(() => {
		if (state?.ipAccess) populateData();
	}, []);

	return (
		<React.Fragment>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => navigate(-1)}
					></i>
					{`${state?.ipAccess ? "Edit " : "New "}`}
					IpAccess
				</h1>
			</div>
			<div className="pb-4 px-4">
				<div className="content">
					<div className="addEdit pb-4">
						<div className="col-5">
							<Form.Group>
								<Form.Label className="headings2">
									Public Ip *
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Write..."
										onChange={(e) => {
											setPublicIp(e.target.value);
										}}
										value={publicIp}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group>
								<Form.Label className="headings2">
									Description
								</Form.Label>
								<InputGroup>
									<Form.Control
										placeholder="Write..."
										onChange={(e) =>
											setDescription(e.target.value)
										}
										value={description}
									/>
								</InputGroup>
							</Form.Group>
						</div>
					</div>

					<div className="addEdit">
						<Button className="addItem" onClick={saveIpAccess}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
