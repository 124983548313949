import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getPatientsByDoctorId } from "../../../apis/patients";
import { checkPermission } from "../../../helperFunctions/checkPermission";
import { toastify } from "../../../helperFunctions/toastify";

function PatientList() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [patients, setPatients] = useState([]);
  const userData = useSelector((state) => state.userData.value);

  const showPatients = (data) => {
    console.log({ data });
  };

  const getPatientsList = async () => {
    const response = await getPatientsByDoctorId(state?.doctorId);
    if (response.status !== "Fail") {
      setPatients(response);
    } else {
      toastify(response.message, "error");
    }
  };

  useEffect(() => {
    getPatientsList();
  }, []);

  const columns = [
    {
      name: "Patient Name",
      selector: (row) => row.name,
    },
    {
      name: "National ID",
      selector: (row) => row.nationalID,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Date of Birth",
      selector: (row) => row.dob,
    },
    {
      name: "Note-Taking Count",
      selector: (row) => row.notesCount || 0,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = patients?.map((e, index) => {
    return {
      id: e._id,
      index: index,
      name: (
        <div className="cursor-pointer" onClick={() => showPatients(e)}>
          {e.patientProfile.fullName}
        </div>
      ),
      nationalID: (
        <div className="cursor-pointer" onClick={() => showPatients(e)}>
          {e.patientProfile.nationID}
        </div>
      ),
      gender: (
        <div className="cursor-pointer" onClick={() => showPatients(e)}>
          {e.patientProfile.gender}
        </div>
      ),
      dob: (
        <div className="cursor-pointer" onClick={() => showPatients(e)}>
          {e?.patientProfile?.dob && e?.patientProfile?.dob !== ""
            ? new Date(e?.patientProfile?.dob).toLocaleDateString()
            : "NaN"}
        </div>
      ),
      action: (
        <div className="cursor-pointer" onClick={() => showPatients(e)}>
          <i class="fa-regular fa-eye"></i>
        </div>
      ),
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            Patient List
          </h1>
        </div>

        {checkPermission(userData, "Note-Taking", "view") && (
          <DataTable
            columns={columns}
            customStyles={customStyles}
            data={data || []}
            pagination
            highlightOnHover
            conditionalRowStyles={conditionalRowStyles}
          />
        )}
      </div>
    </>
  );
}

export default PatientList;
