import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import {
  getVendorContact,
  updateVendorContact,
  migrateVendorContact,
  deleteVendorContact,
} from "../../../../apis/vendorContact";
import { toastify } from "../../../../helperFunctions/toastify";
import {
  customNumberSort,
  customStringSort,
  exportData,
} from "../../../../helperFunctions/utils";
import { Button as MuiButton } from "@mui/material";
import DeleteModal from "../../../SharedComponents/DeleteModal";
import FilterComponent from "../../../SharedComponents/FilterComponent";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function VendorContact() {
  const navigate = useNavigate();
  const [vendors, setVendors] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getVendorsList = async () => {
    const response = await getVendorContact();
    // console.log("vendors list resp", response);
    if (response) {
      setVendors(response);
    } else {
      if (response?.message === "No data Found") {
        setVendors([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const removeTreatment = async () => {
    setShow(false);
    let response = await deleteVendorContact(deleting);

    if (response?.status !== "Fail") {
      getVendorsList();
      toastify("Vender deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editUom = (e) => {
    navigate("/add-vendor-contact", {
      state: {
        treatment: e,
      },
    });
  };

  const addUom = () => {
    navigate("/add-vendor-contact");
  };

  useEffect(() => {
    getVendorsList();
  }, []);

  console.log("vendors", vendors);
  const columns = [
    {
      name: "Name of Company",
      selector: (row) => row.Name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "code"),
    },
    // {
    //   name: (
    //     <div className="d-flex align-items-center" id="ovly28">
    //       <span className="me-2">Sales Representative</span>
    //       {
    //         <FilterComponent
    //           type="search"
    //           array={vendors}
    //           setArray={setFilteredData}
    //           value="Name"
    //         />
    //       }
    //     </div>
    //   ),
    //   selector: (row) => row.Name,
    //   wrap: true,
    //   sortable: true,
    //   sortFunction: (a, b) => customStringSort(a, b, "name"),
    // },
    {
      name: "Contact Number",
      selector: (row) => row.compNumber,
    },
    {
      name: "Sales Representative",
      selector: (row) => row.compSales?.toLocaleString(),
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "price"),
    },
    {
      name: "Products",
      wrap: true,
      cell: (row) => (
        <div className="d-flex flex-column">
          {row.compTypesOfProducts.map((index) => (
            <p className="prd-item mb-1">{index}</p>
          ))}
        </div>
      ),
      // selector: (row) => row.compTypesOfProducts,
    },
    {
      name: "Description",
      wrap: true,
      selector: (row) => row.compDescription?.toLocaleString(),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData?.length === 0 ? vendors : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        Name: e.compName,
        compSales: e.compSales,
        compNumber: e.compNumber,
        compDescription: e.compDescription,
        index: index,
        compTypesOfProducts: e?.compTypesOfProducts,
        tagButtons: (
          <div
            className="rounded-button"
            style={{ width: 110, background: e?.tag?.color }}
          >
            {e?.tag?.Name?.length > 9
              ? e?.tag?.Name?.substring(0, 6) + "..."
              : e?.tag?.Name}
          </div>
        ),
        action: (
          <div className="d-flex">
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                editUom(e);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="mx-3  cursor-pointer icon-width"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    let formData = new FormData();
    formData.append("csvFile", selectedFile);
    migrateVendorContact(formData).then((res) => {
      getVendorsList();
      toastify(res, "success");
    });
  };

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Vendor Contact</h1>
          <div className="d-flex">
            <Button
              className="addItem me-3"
              onClick={() => {
                const tempDataArr = data?.map((e) => {
                  return {
                    Name: e.Name,
                    "Contact No": e.compNumber,
                    "Sales Representative": e.compSales,
                    Products: e?.compTypesOfProducts,
                    Description: e?.compDescription,
                  };
                });
                exportData(tempDataArr, "Vendor Contact", "vendor_contact");
              }}
            >
              Export
            </Button>
            <MuiButton
              className="addItem me-3"
              component="label"
              role={undefined}
              variant="contained"
              sx={{ backgroundColor: "#295381" }}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Import
              <VisuallyHiddenInput type="file" onChange={handleFileChange} />
            </MuiButton>
            <Button className="addItem" onClick={addUom}>
              + Add New
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Vendor Contact Deletion?"
          onYes={removeTreatment}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
