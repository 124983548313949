import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProducts } from "../../../../apis/products";
import { getTaxSettings } from "../../../../apis/tax";
import { SearchIcon } from "../../../../assets/images/index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTable from "./CustomTable";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import {
  addPurchaseList,
  getPurchaseList,
  updatePurchaseList,
} from "../../../../apis/purchaseList";
import { toastify } from "../../../../helperFunctions/toastify";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import SelectComponent from "../../../SharedComponents/SelectComponent";
import { setFormData } from "../../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditPurchaseList() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;
  const navigate = useNavigate();
  const numberRegex = new RegExp(`^[0-9]*$`);

  const [totalCost, setTotalCost] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [docNo, setDocNo] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [orderDate, setOrderDate] = useState("");
  const [status, setStatus] = useState("");
  const [applyTax, setApplyTax] = useState("");
  const [tax, setTax] = useState([]);
  const [products, setProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [itemNameShow, setItemNameShow] = useState(false);
  const [filteredItemName, setFilteredItemName] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [typeShow, setTypeShow] = useState(false);
  const [filteredType, setFilteredType] = useState([]);
  const [searchUom, setSearchUom] = useState("");
  const [uomShow, setUomShow] = useState(false);
  const [filteredUom, setFilteredUom] = useState([]);
  const [qty, setQty] = useState("");
  const [show, setShow] = useState(false);
  const [editingProduct, setEditingProduct] = useState("");

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  useEffect(() => {
    if (!state?.purchase?._id) {
      formDataChangedRef.current = {
        docNumber: docNo,
        orderDate,
        name,
        description,
        applyTax,
        totalCost,
        products: products,
        isActive: status,
      }
    }
  }, [docNo, orderDate, name, description, applyTax, totalCost, products, status]);

  useEffect(() => {
    return () => {
      if (!state?.purchase?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.purchase?._id) {
      populateData();
    }
  }, []);

  const populateData = async () => {
    const reduxData = formData?.[pathname];
    if (reduxData && !state?.purchase?._id) {
      setApplyTax(reduxData?.applyTax);
      setDocNo(reduxData?.docNumber);
      setName(reduxData?.name);
      setDescription(reduxData?.description);
      setOrderDate(reduxData?.orderDate);
      setStatus(reduxData?.isActive);
      setTotalCost(reduxData?.totalCost);
      setProducts(reduxData?.products ? reduxData?.products : []);
    } else if (state?.purchase?._id) {
      setApplyTax(state?.purchase?.applyTax?._id);
      setDocNo(state?.purchase?.docNumber);
      setName(state?.purchase?.name);
      setDescription(state?.purchase?.description);
      setOrderDate(state?.purchase?.orderDate);
      setStatus(state?.purchase?.isActive ? "Active" : "Not Active");
      setTotalCost(state?.purchase?.totalCost);
      setProducts(state?.purchase?.products);
    }
  };

  const populateOptions = async () => {
    const response = await Promise.all([getTaxSettings(), getProducts()]);
    setTax(response[0] || []);
    setAllProducts(response[1] || []);
  };

  const savePurchaseList = async () => {
    if (!name) {
      missingPopup("Name");
    } else if (!docNo) {
      missingPopup("Doc Number");
    } else if (!orderDate) {
      missingPopup("Order Date");
    } 
    // else if (!applyTax) {
    //   missingPopup("Apply Tax");
    // }
    // else if (!status) {
    //   missingPopup("Status");
    // }
    else if (products.length === 0) {
      missingPopup("Item");
    } else {
      let _products = [...products].map((e) => {
        return {
          product: e.product?._id,
          qty: e.qty,
          unitCost: e.unitCost,
        };
      });
      let data = {
        docNumber: docNo,
        orderDate,
        name,
        description,
        applyTax,
        totalCost,
        products: _products,
        isActive: status === "Active" ? true : false,
      };
      if (!state?.purchase) {
        const response = await addPurchaseList(data);
        if (response?.status !== "Fail") {
          formDataChangedRef.current = null;
          dispatch(setFormData({
            ...formData,
            [pathname]: null
          }));

          toastify("Purchase List added successfully!", "success");
          navigate("/purchase-list");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updatePurchaseList(state?.purchase?._id, data);
        if (response?.status !== "Fail") {
          toastify("Purchase List updated successfully!", "success");
          navigate("/purchase-list");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  useEffect(() => {
    populateOptions();
    if (state?.purchase) {
      populateData();
    }
  }, []);

  useEffect(() => {
    setFilteredItemName(
      allProducts.filter((e) =>
        e.name.toLowerCase().includes(searchItem.toLowerCase())
      )
    );
  }, [searchItem]);

  useEffect(() => {
    setFilteredType(
      allProducts.filter((e) =>
        e.type.name.toLowerCase().includes(searchType.toLowerCase())
      )
    );
  }, [searchType]);

  useEffect(() => {
    setFilteredUom(
      allProducts.filter((e) =>
        e.uom?.name?.toLowerCase()?.includes(searchUom?.toLowerCase())
      )
    );
  }, [searchUom]);

  useEffect(() => {
    let total = 0;
    products.forEach((e) => (total += e.qty * e.product.averageUnitCost));
    setTotalCost(total);
  }, [products]);

  useEffect(() => {
    if (selectedProduct) {
      setSearchItem(selectedProduct?.name);
      setSearchType(selectedProduct?.type?.name);
      setSearchUom(selectedProduct?.uom?.name);
    }
  }, [selectedProduct]);

  const addProduct = () => {
    if (!qty) {
      missingPopup("Quantity");
    } else if (!selectedProduct) {
      missingPopup("Item");
    } else {
      let data = [...products];
      if (editingProduct !== "") {
        data = [...data].filter((_e, i) => i !== editingProduct);
      }

      let found = data.find((e) => e.product._id === selectedProduct._id);

      if (found) {
        let _data = data.filter((e) => e.product._id !== selectedProduct._id);
        _data.push({
          qty: (parseInt(qty) + parseInt(found.qty)).toString(),
          unitCost: selectedProduct.averageUnitCost,
          product: selectedProduct,
        });
        setProducts(_data);
      } else {
        data.push({
          qty,
          unitCost: selectedProduct.averageUnitCost,
          product: selectedProduct,
        });
        setProducts(data);
      }

      // data.push({
      //     qty,
      //     unitCost: selectedProduct.averageUnitCost,
      //     product: selectedProduct,
      // });
      // setProducts(data);
      setSelectedProduct();
      setSearchItem("");
      setSearchType("");
      setSearchUom("");
      setQty("");
      setEditingProduct("");
      setShowAdd(false);
    }
  };

  const editProduct = (id) => {
    let _p = [...products].find((e) => e.product._id === id);
    // let _products = [...products].filter((e) => e.product._id !== id);
    // setProducts(_products);
    setShowAdd(true);
    setQty(_p.qty);
    setSelectedProduct(_p.product);
  };

  const deleteProduct = (id) => {
    let _products = [...products].filter((e) => e.product._id !== id);
    setProducts(_products);
  };

  const handleClickAway = () => {
    setShow(false);
  };

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.purchase?._id,
      type: "Purchase",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.purchase ? "Edit " : "New "}`}
            Purchase List
          </h1>
          {state?.purchase && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Doc No *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setDocNo(e.target.value)}
                      value={docNo}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-6">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="col-12">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={orderDate}
                          onChange={(newValue) => {
                            setOrderDate(newValue?.$d);
                            setShow(false);
                          }}
                          componentsProps={{
                            actionBar: { actions: ["today"] },
                          }}
                          open={show}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Form.Group className="col-10">
                              <Form.Label className="headings2">
                                Order Date *
                              </Form.Label>
                              <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                <Form.Control
                                  ref={inputRef}
                                  // {...inputProps}
                                  value={
                                    orderDate
                                      ? new Date(orderDate).toLocaleDateString(
                                          "en-GB",
                                          {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                          }
                                        )
                                      : null
                                  }
                                  onClick={() => setShow(!show)}
                                  readOnly
                                  placeholder="Select Date"
                                />
                                <div onClick={() => setShow(!show)}>
                                  {InputProps?.endAdornment}
                                </div>
                              </InputGroup>
                            </Form.Group>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>
                </Form.Group>
              </div>

              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Apply Tax</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    adjustValues
                    selectedValue={applyTax}
                    options={
                      tax?.length > 0
                        ? tax?.filter((x) => x.taxType.includes("Purchase"))
                        : []
                    }
                    onChange={(e) => {
                      setApplyTax(e.value);
                    }}
                  />
                  {/* <select
										className="form-select mb-3"
										onChange={(e) => {
											setApplyTax(e.target.value);
										}}
									>
										<option value="" disabled selected>
											Select
										</option>
										{tax?.map((e) => (
											<option
												value={e._id}
												selected={applyTax === e._id}
											>
												{e.name}
											</option>
										))}
									</select> */}
                </Form.Group>
                {/* <Form.Group className="col-5">
                  <Form.Label className="headings2">Status *</Form.Label>
                  <select
                    className="form-select mb-3"
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                  >
                    <option value="" disabled selected>
                      Select
                    </option>
                    <option value="Active" selected={status === "Active"}>
                      Active
                    </option>
                    <option
                      value="Not Active"
                      selected={status === "Not Active"}
                    >
                      Not Active
                    </option>
                  </select>
                </Form.Group> */}
              </div>

              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />
              </div>

              <div className="ms-1 mt-3">
                <button
                  className="round-button"
                  onClick={() => {
                    setShowAdd(!showAdd);
                    setItemNameShow(false);
                    setTypeShow(false);
                    setUomShow(false);
                    setEditingProduct("");
                  }}
                >
                  +Add Item
                </button>
              </div>

              {showAdd && (
                <div className="d-flex my-3 px-3 py-3 prod-box">
                  <div className="col-5">
                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Item Name *</Form.Label>
                      <InputGroup
                        className="mb-3 p-0 search-group"
                        onClick={() => {
                          setItemNameShow(!itemNameShow);
                          if (!itemNameShow) {
                            setUomShow(false);
                            setTypeShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchItem}
                          onChange={(e) => setSearchItem(e.target.value)}
                        />
                        <Button>
                          <img src={SearchIcon} />
                        </Button>
                      </InputGroup>

                      {itemNameShow && (
                        <div className="searchbox-packages">
                          {(filteredItemName.length > 0
                            ? filteredItemName
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchItem(e.name);
                                setItemNameShow(false);
                                setSelectedProduct(e);
                              }}
                            >
                              {e.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">Item Type *</Form.Label>
                      <InputGroup
                        className="mb-3 p-0 search-group disabled-input"
                        onClick={() => {
                          setTypeShow(!typeShow);
                          if (!typeShow) {
                            setItemNameShow(false);
                            setUomShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchType}
                          disabled={true}
                          onChange={(e) => setSearchType(e.target.value)}
                        />
                        {/* <Button>
                          <img src={SearchIcon} />
                        </Button> */}
                      </InputGroup>
                      {typeShow && (
                        <div className="searchbox-packages">
                          {(filteredType.length > 0
                            ? filteredType
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchType(e.type.name);
                                setTypeShow(false);
                                setSelectedProduct(e);
                              }}
                            >
                              {e.type.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group className="position-relative">
                      <Form.Label className="headings2">UOM *</Form.Label>
                      <InputGroup
                        className="mb-3 p-0 search-group disabled-input"
                        onClick={() => {
                          setUomShow(!uomShow);
                          if (!uomShow) {
                            setItemNameShow(false);
                            setTypeShow(false);
                          }
                        }}
                      >
                        <Form.Control
                          placeholder="Select"
                          value={searchUom}
                          disabled={true}
                          onChange={(e) => setSearchUom(e.target.value)}
                        />
                        {/* <Button>
                          <img src={SearchIcon} />
                        </Button> */}
                      </InputGroup>
                      {uomShow && (
                        <div className="searchbox-packages">
                          {(filteredUom.length > 0
                            ? filteredUom
                            : allProducts
                          ).map((e) => (
                            <div
                              onClick={() => {
                                setSearchUom(e.uom?.name);
                                setUomShow(false);
                                setSelectedProduct(e);
                              }}
                            >
                              {e.uom?.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Quantity *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          // onChange={(e) => {
                          //   setQty(e.target.value);
                          // }}
                          onChange={(e) => {
                            if (
                              numberRegex.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              setQty(e.target.value);
                            }
                          }}
                          value={qty}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Unit Cost *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          disabled
                          value={selectedProduct?.averageUnitCost}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className="headings2 mt-2">
                        Total Cost *
                      </Form.Label>
                      <InputGroup>
                        <Form.Control
                          placeholder="Write..."
                          disabled
                          value={selectedProduct?.averageUnitCost * qty}
                        />
                      </InputGroup>
                    </Form.Group>

                    <Button
                      className="my-save-button mt-3"
                      onClick={addProduct}
                    >
                      Save
                    </Button>
                  </div>
                  {/* {selectedProduct && (
                    <div className="ms-5 ps-5">
                      <div className="shadow-box-2">
                        <div className="a">UOM</div>
                        <div className="b">{selectedProduct?.uom?.name}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Available Stock</div>
                        <div className="b">{selectedProduct?.qty}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Unit Cost</div>
                        <div className="b">
                          ${selectedProduct?.averageUnitCost}
                        </div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Total Cost</div>
                        <div className="b">${selectedProduct?.totalCost}</div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Unit Price</div>
                        <div className="b">
                          ${selectedProduct?.unitSellingPrice}
                        </div>
                      </div>
                      <div className="shadow-box-2">
                        <div className="a">Total Price</div>
                        <div className="b">
                          ${selectedProduct?.totalSellingPrice}
                        </div>
                      </div>
                    </div>
                  )} */}
                </div>
              )}

              {products.length > 0 && (
                <CustomTable
                  show={showAdd}
                  data={products}
                  editProduct={editProduct}
                  deleteProduct={deleteProduct}
                  setEditingProduct={setEditingProduct}
                />
              )}

              <div className="col-8 mt-3">
                <Form.Label className="headings2 gray-font">
                  Totalize
                </Form.Label>
                <div className="shadow-text-box">
                  <span>Total Cost</span>
                  <div>${totalCost || 0}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="addEdit d-flex">
            <Button className="addItem" onClick={savePurchaseList}>
              Save
            </Button>
            {/* <Button className="addItem mx-3">Print</Button> */}
            {/* <Button className="addItem" onClick={savePurchaseList}>
              Receiving
            </Button> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
