import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { createSupplier, updateSupplier } from "../../../apis/supplier";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddEditSupplier() {
	const { state } = useLocation();
	const navigate = useNavigate();
	const titleRegex = new RegExp("^[a-zA-Z0-9]+$");
	const numberRegex = new RegExp(`^[0-9]*$`);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [contact, setContact] = useState("");
	const [address, setAddress] = useState("");

	const populateData = async () => {
		setName(state?.supplier?.name);
		setEmail(state?.supplier?.email);
		setContact(state?.supplier?.contactNumber);
		setAddress(state?.supplier?.address);
	};

	const validateEmail = (email) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			);
	};

	const saveSupplier = async () => {
		if (!name) {
			missingPopup("Name");
		} else if (email !== "" && !validateEmail(email)) {
			toastify("Invalid Email", "error");
		} else {
			let data = { name, email, contactNumber: contact, address };
			if (!state?.supplier) {
				const response = await createSupplier(data);
				if (response?.status !== "Fail") {
					toastify("Supplier added successfully!", "success");
					navigate("/supplier");
				} else {
					toastify(response?.message, "error");
				}
			} else {
				const response = await updateSupplier(
					state?.supplier?._id,
					data,
				);
				if (response?.status !== "Fail") {
					toastify("Supplier updated successfully!", "success");
					navigate("/supplier");
				} else {
					toastify(response?.message, "error");
				}
			}
		}
	};

	useEffect(() => {
		if (state?.supplier) populateData();
	}, []);

	return (
		<React.Fragment>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => navigate(-1)}
					></i>
					{`${state?.supplier ? "Edit " : "New "}`}
					Supplier
				</h1>
			</div>
			<div className="pb-4 px-4">
				<div className="content">
					<div className="addEdit pb-4">
						<div className="col-5">
							<Form.Group>
								<Form.Label className="headings2">
									Name *
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Write..."
										onChange={(e) => {
											if (
												titleRegex.test(
													e.target.value,
												) ||
												e.target.value === ""
											) {
												// setName(e.target.value);
											}
											setName(e.target.value);
										}}
										value={name}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group>
								<Form.Label className="headings2">
									Contact No
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Write..."
										onChange={(e) => {
											if (
												numberRegex.test(
													e.target.value,
												) ||
												e.target.value === ""
											) {
												setContact(e.target.value);
											}
										}}
										value={contact}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group>
								<Form.Label className="headings2">
									Email
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Write..."
										onChange={(e) =>
											setEmail(e.target.value)
										}
										value={email}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group>
								<Form.Label className="headings2">
									Address
								</Form.Label>
								<InputGroup>
									<Form.Control
										placeholder="Write..."
										onChange={(e) =>
											setAddress(e.target.value)
										}
										value={address}
									/>
								</InputGroup>
							</Form.Group>
						</div>
					</div>

					<div className="addEdit">
						<Button className="addItem" onClick={saveSupplier}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
