import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";

import { getPaymentReport } from "../../../apis/customTemplate";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import { setTableSearch } from "../../../redux/slices/commonSlice";

import {
  customDateSort,
  downloadCsv,
  formatDate,
} from "../../../helperFunctions/utils";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function PaymentReportView() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [filteredData, setFilteredData] = useState([]);
  const [paymentM, setPaymentM] = useState([]);
  const [startDate, setStartDate] = useState(
    tableSearch[fieldPathname]?.paymentMethod?.startDate ? tableSearch[fieldPathname]?.paymentMethod?.startDate : `${new Date().getFullYear()}-01-01`
  );
  const [endDate, setEndDate] = useState(
    tableSearch[fieldPathname]?.paymentMethod?.endDate ? tableSearch[fieldPathname]?.paymentMethod?.endDate : `${new Date().getFullYear()}-12-29`
  );
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (valueStartDate, valueEndDate) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
        paymentMethod: {
          startDate: valueStartDate,
          endDate: valueEndDate,
        }
			}
		}))
  }

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.paymentMethod?.startDate ? tableSearch[fieldPathname]?.paymentMethod?.startDate : `${new Date().getFullYear()}-01-01`);
    setEndDate(tableSearch[fieldPathname]?.paymentMethod?.endDate ? tableSearch[fieldPathname]?.paymentMethod?.endDate : `${new Date().getFullYear()}-12-29`);

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.paymentMethod?.startDate ||
      tableSearch[fieldPathname]?.paymentMethod?.endDate
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    getPaymentReport({
      start_date: startDate,
      end_date: endDate,
    }).then((res) => {
      if (res?.result?.data) {
        setPaymentM(res.result.data.type);
        setFilteredData(res.result.data.result);
      }
    });
  }, [refetch]);
  console.log(filteredData);
  const paymentMColumns = paymentM?.map((e) => ({
    name: e?.name,
    selector: (row) => {
      const amount = row.paymentRecord.reduce((acc, record) => {
        if (record.paymentMethod === e?.name) {
          return acc + record.currentPaidAmount;
        }
        return acc;
      }, 0);
      return amount.toString(); // Convert to string as the return type expects a string or number
    },
  }));
  // eslint-disable-next-line no-sparse-arrays
  const columns = [
    {
      name: "Month",
      // eslint-disable-next-line no-undef
      selector: (row) => row.created_at,
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "created_at"),
      cell: (row) => {
        return moment(row.created_at).format("MMM YY");
      }
    },
    {
      name: "Date",
      selector: (row) => row.created_at,
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "created_at"),
      cell: (row) => {
        return formatDate(row.created_at)
      }
    },
    ...paymentMColumns,
    ,
    {
      name: "Total Amount",
      selector: (row) => {
        const amount = row.paymentRecord.reduce((acc, record) => {
          return acc + record.currentPaidAmount;
        }, 0);
        return amount.toString(); // Convert to string as the return type expects a string or number
      },
    },
  ];

  const data = filteredData?.map((e, index) => {
    return {
      ...e,
      index,
      key: index,
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data.map((row) => {
      const totalAmount = row.paymentRecord.reduce((acc, record) => {
        return acc + record.currentPaidAmount;
      }, 0);

      const monthValues = {};
      paymentM?.map((e) => {
        const amount = row.paymentRecord.reduce((acc, record) => {
          if (record.paymentMethod === e?.name) {
            return acc + record.currentPaidAmount;
          }
          return acc;
        }, 0);

        monthValues[e?.name] = amount;
        return false;
      })

      return {
        Month: moment(row.created_at).format("MMM YY"),
        Date: formatDate(row.created_at),
        ...monthValues,
        "Total Amount": totalAmount,
      };
    });

    downloadCsv(csvData, "Payment Method Report");
  };

  return (
    <div className="p-0">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "18px",
          }}
        >
          <FilterWrapper className="d-flex">
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(startDate, endDate);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button className="addItem" onClick={() => exportCsv()}>
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default PaymentReportView;
