import React from "react";
import { Button, Modal } from "react-bootstrap";

export default function DeleteModal({ show, onNo, onYes, content }) {
  return (
    <Modal size="sm" centered show={show} onHide={onNo}>
      <Modal.Body className="font-18">{content}</Modal.Body>
      <div className="align-self-end m-3 d-flex">
        <Button className="me-3 delete-no" onClick={onNo}>
          No
        </Button>
        <Button className="delete-yes" onClick={onYes}>
          Yes
        </Button>
      </div>
    </Modal>
  );
}
