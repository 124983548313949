const { authorizedRequests } = require("../axios/axios-config");

const getPatientGeneralReports = async (data) => {
	const response = await authorizedRequests({
		url: "reports/listPatientGeneralReportData",
		method: "post",
		data,
	});
	return response;
};

const getSalesReports = async (data) => {
	const response = await authorizedRequests({
		url: "reports/salesReport",
		method: "post",
		data,
	});
	return response;
};

const getEndSessionReports = async (data) => {
	const response = await authorizedRequests({
		url: "reports/getEndSessionsReport",
		method: "post",
		data,
	});
	return response;
};

const getKolReports = async (data) => {
	const response = await authorizedRequests({
		url: "reports/getKolReport",
		method: "post",
		data,
	});
	return response;
};

const getProgramExpirationReports = async (data) => {
	const response = await authorizedRequests({
		url: "reports/getAccountsReport",
		method: "post",
		data,
	});
	return response;
};

export { 
	getPatientGeneralReports, 
	getSalesReports, 
	getKolReports, 
	getProgramExpirationReports,
	getEndSessionReports 
};
