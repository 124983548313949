import api from "../axios/axios-config";

export const getDepartments = async () => {
  try {
    let response = await api.get("department/listOfDepartment");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addDepartment = async (data, permissions) => {
  try {
    let response = await api.post("department/createDepartment", {
      name: data?.name,
      description: data?.description,
      manager: data?.manager,
      permissions: permissions.map((e) => {
        return {
          moduleName: e.moduleName,
          permissionsList: e.permissionsList,
        };
      }),
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const departmentDetails = async (departmentId) => {
  try {
    let response = await api.post("department/detailOfDepartment", {
      departmentId,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateDepartment = async (apiData, permissions, id) => {
  try {
    let response = await api.post("department/updateDepartment", {
      departmentId: id,
      name: apiData?.name,
      manager: apiData?.manager,
      description: apiData?.description,
      permissions,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteDepartment = async (id) => {
  try {
    let response = await api.post("department/updateDepartment", {
      departmentId: id,
      isDeleted: true,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
