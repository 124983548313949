import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBranches, updateBranch } from "../../../apis/branch";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import {
	resetValue,
	setData,
	setForm,
} from "../../../redux/slices/StaffFormsSlices";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";
import hamburger from "../../../assets/images/hamburger.png";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import { useLayoutEffect } from "react";
import {
	customDateSort,
	customTimeSort,
	customStringSort,
	exportData,
	getFormatedTime,
	adjustDateFormat
} from "../../../helperFunctions/utils";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import DateInput from "../../SharedComponents/DateInput";
import { permissionTypes } from "../../../helperFunctions/routes";
// import SortComponent, {
// 	sortTypes,
// } from "../../SharedComponents/SortComponents";

export default function AppointmentsView() {
	const navigate = useNavigate();

	const [filteredData, setFilteredData] = useState([]);
	const [formData, setFormData] = useState([]);
	const [openContainer, setOpenContainer] = useState(false);
	const [itemToDel, setItemToDel] = useState();
	const [openModal, setOpenModal] = useState(false);
	const [stateDate, setStateDate] = useState(new Date());

	const dispatch = useDispatch();

	const { appointmentData, waitingData } = useSelector(
		(state) => state.staffForm,
	);

	const getList = async () => {
		const { result, error } = await authorizedRequests({
			url: "appointment/listOfAppointments",
			method: "get",
			params: {
				date: stateDate
			}
		});
		if (error) {
			toastify(error.message ?? "unknown error occured", "error");
			return;
		}
		var tempArr = result?.data?.map((app) => {
			return {
				id: app?._id,
				time:
					(getFormatedTime(app?.startTime) ?? "") +
					"-" +
					(getFormatedTime(app?.endTime) ?? ""),
				patientName: app?.patient?.patientProfile?.fullName,
				remarks: app?.remarks,
				products: app?.products,
				// treatments: app?.treatments,
				staff: app?.staff?.map((o) => {
					return o?.username;
				}),
				tag: app?.tag?.map(x => x.name),
				otherTags: app?.otherTags?.map(x => x.name),
				color: app?.tag?.map(x => x.color),
				date: new Date(app?.date).toLocaleDateString("en-GB", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
				}),
				status: app?.status,
			};
		});
		tempArr = tempArr?.reverse();

		setFormData(result.data.reverse());
		dispatch(
			setData({
				parent: "appointmentData",
				value: tempArr,
			}),
		);
		return;
	};

	const onRowEdit = async (id, index) => {
		var tempArr = formData[index];
		const valueObj = {
			patient: tempArr.patient._id,
			branch: tempArr.branch._id,
			date: tempArr.date,
			location: tempArr.location,
			startTime: tempArr.startTime,
			endTime: tempArr.endTime,
			// package: tempArr.package._id,
			products: tempArr.products.map((o) => {
				return o._id;
			}),
			// treatments: tempArr.treatments.map((o) => {
			// return o._id;
			// }),
			staff: [
				...tempArr.staff.map((o) => {
					return o._id;
				}),
			],
			// tag: tempArr.tag.map((t) => {
			// 	return t._id
			// }),
			// otherTags: tempArr.otherTags.map((t) => {
			// 	return t._id
			// }),
			tag: tempArr.tag,
			otherTags: tempArr.otherTags,
			status: tempArr.status,
			remarks: tempArr.remarks,
			formStatus: "Edit",
			editId: id,
		};
		dispatch(
			setData({
				parent: "appointmentForm",
				value: valueObj,
			}),
		);
		navigate("/add-appointment", { state: { valueObj } });
	};

	const onRowDelete = async (id) => {
		// tempArr =
		const { result, error } = await authorizedRequests({
			url: "appointment/updateAppointment",
			method: "post",
			data: {
				dataId: id,
				isDeleted: true,
			},
		});
		if (error) {
			toastify(error.message ?? "unknown error occured", "error");
			return;
		}

		let tempArr = appointmentData?.filter((o) => o.id !== id);
		dispatch(
			setData({
				parent: "appointmentData",
				value: tempArr,
			}),
		);
		toastify("Appointment deleted succesfully", "success");
		getList();
	};

	const getWaitingList = async () => {
		const { result, error } = await authorizedRequests({
			url: "waitingList/listOfWaitings",
			method: "get",
		});
		if (error) {
			toastify(error.message ?? "unknown error occured", "error");
			return;
		}
		var tempArr = result.data
			.map((app) => {
				return {
					id: app._id,
					patientName: app.patientName,
					contactNumber: app.contactNumber,
					startTime: app.startTime,
					endTime: app.endTime,
					remarks: app.remarks,
					isOpen: false,
					gender: app.gender,
					date: new Date(app.date).toDateString(),
				};
			})
			.filter((o) => o.date == new Date().toDateString());
		dispatch(
			setData({
				parent: "waitingData",
				value: tempArr,
			}),
		);
		return;
	};
	const openWaiting = (index) => {
		var tempArr = [...waitingData];
		tempArr = tempArr.map((o, i) => {
			if (index === i) {
				return { ...o, isOpen: !o.isOpen };
			} else {
				return { ...o, isOpen: false };
			}
		});
		dispatch(
			setData({
				parent: "waitingData",
				value: tempArr,
			}),
		);
	};

	const addAppointment = async (patient) => {
		console.log(patient.date)
		dispatch(
			setData({
				parent: "appointmentForm",
				value: {
					patient: -1,
					branch: "",
					location: "",
					startTime: patient.startTime,
					endTime: patient.endTime,
					// package: "",
					date: adjustDateFormat(new Date(patient.date)),
					staff: [],
					tag: "",
					status: "",
					remarks: patient.remarks,
					formStatus: "Edit",
					editId: null,
					products: [],
					// treatments: [],
				},
			}),
		);
		navigate("/add-appointment", {
			state: {
				isFromWaiting: true,
				patient: patient?.patientName,
				contactNo: patient?.contactNo,
				gender: patient?.gender,
				waitingId: patient?.id,
			},
		});
	};

	useEffect(() => {
		getList();
		getWaitingList();
	}, [stateDate]);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Date</span>
					{
						<FilterComponent
							type="date"
							array={appointmentData}
							setArray={setFilteredData}
							value="date"
						/>
					}
				</div>
			),
			selector: (row) => row.date,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customDateSort(a, b, "date"),
		},
		{
			name: "Time",
			selector: (row) => row.time,
			wrap: true,
			width: "200px",
			sortable: true,
			sortFunction: (a, b) => customTimeSort(a, b, "time"),
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Patient</span>
					{
						<FilterComponent
							type="search"
							array={appointmentData}
							setArray={setFilteredData}
							value="patientName"
						/>
					}
				</div>
			),
			selector: (row) => row.patientName,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "patientName"),
		},
		{
			name: (
				<>
					<div className="d-flex align-items-center" id="ovly2">
						<span className="me-2">Remarks</span>
					</div>
				</>
			),
			selector: (row) => row.remarks,
			wrap: true,
			width: "160px",
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "remarks"),
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Staff</span>
					{
						<FilterComponent
							type="search"
							array={appointmentData}
							setArray={setFilteredData}
							subArray
							value="staff"
						/>
					}
				</div>
			),
			selector: (row) => row.staffMembers,
			wrap: true,
			width: "160px",
		},
		{
			name: "Tag",
			selector: (row) => row.tagButtons,
			wrap: true,
			width: "160px",
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Status</span>
					{
						<FilterComponent
							type="radio-search"
							array={appointmentData}
							setArray={setFilteredData}
							subArray
							message="Results not found for applied filters"
							options={[
								{
									id: "Planned",
									name: "Planned",
								},
								{
									id: "Confirmed",
									name: "Confirmed",
								},
								{
									id: "Cancelled",
									name: "Cancelled",
								},
								{
									id: "Not Show",
									name: "Not Show",
								},
							]}
							value="status"
						/>
					}
				</div>
			),
			selector: (row) => row.statusButtons,
			wrap: true,
			width: "160px",
		},
		{
			name: "Action",
			selector: (row) => row.action,
			width: "180px",
		},
	];

	const data = (
		filteredData.length === 0 ? appointmentData : filteredData
	)?.map((e, index) => {
		return {
			index: index,
			date: e?.date,
			time: e?.time,
			patient: e?.patientName,
			patientName: e?.patientName,
			remarks: e?.remarks,
			staff: e?.staff?.join(","),
			tag: e?.tag,
			status: e.status,
			statusButtons: (
				<>
					<div
						className={`${e.status == "Confirmed"
							? "green-rounded-button"
							: e.status == "Planned"
								? "brown-rounded-button"
								: "red-rounded-button"
							}`}
						style={{ width: 110 }}
					>
						{e.status}
					</div>
				</>
			),
			tagButtons: (
				<div style={{ paddingTop: "8px", paddingBottom: "8px" }}>
					{
						e?.tag.map((tagName, index) => (
							<div
								key={index}
								className="rounded-button"
								style={{ width: 110, background: e?.color[index], marginBottom: "5px", color: "white" }}
							>
								{tagName?.length > 9
									? tagName.substring(0, 6) + "..."
									: tagName}
							</div>
						))
					}
				</div>
			),
			staffMembers: (
				<>
					{e?.staff?.map((o) => {
						return <div className="">{o}</div>;
					})}
				</>
			),

			action: (
				<div className="d-flex ">
					<ComponentWrapepr
						moduleName="Appointment"
						permissionType={permissionTypes.UPDATE}
					>
						<div
							className="cursor-pointer"
							onClick={() => {
								onRowEdit(e.id, index);
								// editBranch(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
					</ComponentWrapepr>
					<ComponentWrapepr
						moduleName="Appointment"
						permissionType={permissionTypes.DELETE}
					>
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								setItemToDel(e.id);
								setOpenModal(true);
								// onRowDelete(e.id);
								// setDeleting(e._id);
								// setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</ComponentWrapepr>
				</div>
			),
		};
	});
	const customStyles = {
		headCells: {
			style: {
				// justifyContent: "center",
			},
		},
		cells: {
			style: {
				// justifyContent: "center",
				// textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>
						<i
							className="fa fa-arrow-left cursor-pointer"
							onClick={() => {
								navigate(-1);
							}}
						></i>
						Appointments
					</h1>
					<div className="d-flex">
						<div className="me-3" style={{ width: "200px" }}>
							<DateInput
								value={stateDate}
								placeholder="Date"
								shouldDisableDates={true}
								onChange={(e) => {
									setStateDate(e);
								}}
							/>
						</div>
						<Button
							className="addItem me-3"
							onClick={() => {
								const tempDataArr = data?.map((o) => {
									const tempObj = { ...o };
									delete tempObj.index;
									delete tempObj.action;
									delete tempObj.statusButtons;
									delete tempObj.tagButtons;
									delete tempObj.staffMembers;
									delete tempObj.patientName;
									return { ...tempObj };
								});
								exportData(tempDataArr, "Appointments");
							}}
						>
							Export
						</Button>
						<ComponentWrapepr
							moduleName="Appointment"
							permissionType={permissionTypes.CREATE}
						>
							<Button
								className="addItem"
								onClick={() => {
									dispatch(resetValue("appointmentForm"));
									navigate("/add-appointment");
								}}
							>
								+ Add New
							</Button>
						</ComponentWrapepr>
					</div>
				</div>
				{/* 
				<DeleteModal
					content="Confirm Branch Deletion?"
					onYes={removeBranch}
					onNo={handleClose}
					show={show}
				/> */}
				<div
					className="d-flex pb-3"
				// style={{ overflow: "auto" }}
				>
					<div
						style={{
							width: `${openContainer
								? "calc(100% - 440px)"
								: "calc(100% - 240px)"
								}`,
						}}
					>
						<DataTable
							columns={columns}
							data={data || []}
							pagination
							customStyles={customStyles}
							conditionalRowStyles={conditionalRowStyles}
						// responsive="true"
						/>
					</div>

					<div
						className={`${openContainer
							? "open-animate col-5"
							: "close-animate col-3"
							}`}
					>
						<div className="header d-flex align-items-center">
							<img
								className="img img-fluid hamburgerIcon pointer"
								onClick={() => {
									setOpenContainer(!openContainer);
								}}
								src={hamburger}
								alt=""
							/>
							<div className="heading">
								Today's {openContainer ? "Waiting List" : ""}
							</div>
						</div>
						{waitingData.length > 0 &&
							waitingData?.map((o, i) => {
								return (
									<div className="waiting-card">
										<div
											className={`d-flex pointer ${o.isOpen ? "top-bar" : "p-1"
												}`}
											onClick={() => {
												// if (openContainer)
												openWaiting(i);
											}}
										>
											<div className="d-flex">
												<span className="top-text">
													{getFormatedTime(
														o.startTime,
													)}
												</span>
												<div
													className="top-text ms-3"
													style={{
														wordBreak: "break-all",
													}}
												>
													{o.patientName}
												</div>
											</div>
										</div>

										{o.isOpen && (
											<div className="card-body">
												<div className="d-flex justify-content-between my-1">
													<span>Contact</span>
													<span>
														{o.contactNumber}
													</span>
												</div>
												<div className="d-flex justify-content-between my-1">
													<span>Preferred Time</span>
													<span>
														{getFormatedTime(
															o.startTime,
														)}{" "}
														-{" "}
														{getFormatedTime(
															o.endTime,
														)}
													</span>
												</div>
												<ComponentWrapepr
													moduleName="Appointment"
													permissionType={
														permissionTypes.CREATE
													}
												>
													<div className="w-100 df-center my-2">
														<Button
															className="white-rounded-button"
															onClick={() => {
																addAppointment(
																	o,
																);
															}}
														>
															Register Appointment
														</Button>
													</div>
												</ComponentWrapepr>
											</div>
										)}
									</div>
								);
							})}
					</div>
				</div>
			</div>
			{openModal && (
				<>
					<Modal
						centered
						show={openModal}
						onHide={() => {
							setItemToDel(null);

							setOpenModal(!openModal);
						}}
					>
						<Modal.Body className="font-18">
							Confirm Appointment Deletion
							<div className="align-self-end m-3 d-flex">
								<Button
									className="me-3 delete-no ms-auto"
									onClick={() => {
										setOpenModal(false);
									}}
								>
									No
								</Button>
								<Button
									className="delete-yes"
									onClick={() => {
										onRowDelete(itemToDel);
										setOpenModal(false);
									}}
								>
									Yes
								</Button>
							</div>
						</Modal.Body>
					</Modal>
				</>
			)}
		</>
	);
}
