import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getSalesReportByStaff } from "../../../apis/customTemplate";
import { getStaff } from "../../../apis/staff";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { setTableSearch } from "../../../redux/slices/commonSlice";

import {
  customDateSort,
  downloadCsv,
  formatDate,
  getInvoiceId,
} from "../../../helperFunctions/utils";
import MultiSelectComponent from "../../SharedComponents/MultiSelctCompoenent";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function SalesReportByStaff() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

  const { tableSearch } = useSelector((state) => state.commonData);

  const [users, setUsers] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(tableSearch[fieldPathname]?.salesByStaff?.selectedStaff ? tableSearch[fieldPathname]?.salesByStaff?.selectedStaff : []);
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.salesByStaff?.startDate ? tableSearch[fieldPathname]?.salesByStaff?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.salesByStaff?.endDate ? tableSearch[fieldPathname]?.salesByStaff?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (valueStartDate, valueEndDate, valueStaff) => {
    dispatch(
      setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          ...tableSearch[fieldPathname],
          salesByStaff: {
            startDate: valueStartDate,
            endDate: valueEndDate,
            selectedStaff: valueStaff,
          },
        },
      })
    );
  };

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.salesByStaff?.startDate ? tableSearch[fieldPathname]?.salesByStaff?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.salesByStaff?.endDate ? tableSearch[fieldPathname]?.salesByStaff?.endDate : new Date());
    setSelectedStaff(tableSearch[fieldPathname]?.salesByStaff?.selectedStaff ? tableSearch[fieldPathname]?.salesByStaff?.selectedStaff : []);

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.salesByStaff?.startDate || 
      tableSearch[fieldPathname]?.salesByStaff?.endDate ||
      tableSearch[fieldPathname]?.salesByStaff?.selectedStaff
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    getStaff().then((res) => {
      let data = res.filter((e) => !e?.role?.includes("superAdmin"));
      setUsers(data);
    });
  }, []);

  useEffect(() => {
    getSalesReportByStaff({
      startDate: startDate,
      endDate: endDate,
      staff: selectedStaff,
    }).then((res) => {
      if (res?.result?.data) {
        setFilteredData(res.result.data);
      }
    });
  }, [refetch]);

  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row.date),
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "date"),
    },
    {
      name: "Invoice No",
      selector: (row) => getInvoiceId(row.invoiceNo),
      sortable: true,
    },
    {
      name: "Staff",
      selector: (row) => "",
      cell: (row) => {
        return row.session?.staffDetails?.map((staff) => staff.username).join(", ");
      }
    },
    {
      name: "Patient Name",
      selector: (row) => row.patient?.patientProfile?.fullName,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
    },
    {
      name: "GST",
      selector: (row) => row.gst,
      sortable: true,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    //   sortable: true,
    //   cell: (row) => {
    //     let classNameBtn = "";
    //     if (row.status === "Paid") {
    //       classNameBtn = "green-rounded-button";
    //     } else if (row.status === "Outstanding") {
    //       classNameBtn = "yellow-rounded-button";
    //     } else {
    //       classNameBtn = "red-rounded-button";
    //     }

    //     return (
    //       <div className={classNameBtn}>{row.status}</div>
    //     )
    //   }
    // },
  ];

  const data = filteredData?.map((e, index) => {
    return {
      ...e,
      status: getStatusPayment(e),
      index,
      key: index,
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data?.map((e) => {
      return {
        Date: formatDate(e.date),
        "Invoice No": getInvoiceId(e.invoiceNo),
        "Patient Name": e.patient?.patientProfile?.fullName,
        "Total Amount": e.totalAmount,
        GST: e.gst,
        // Status: e.status
      };
    });

    downloadCsv(csvData, "Sales Report by Staff");
  };

  return (
    <div className="p-0">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "18px",
          }}
        >
          <FilterWrapper className="d-flex">
            <Form.Group className="me-3" style={{ width: "260px" }}>
              <Form.Label className="headings2" style={{ color: "#000" }}>
                Filter by Staff
              </Form.Label>
              <MultiSelectComponent
                singleSelect
                searchEnabled
                array={selectedStaff}
                options={
                  users?.length > 0
                    ? [
                        ...users.map((item) => {
                          return {
                            label: item.username,
                            value: item._id,
                          };
                        }),
                      ]
                    : []
                }
                onChange={(e) => {
                  setSelectedStaff(e);
                }}
              />
            </Form.Group>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(startDate, endDate, selectedStaff);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button className="addItem" onClick={() => exportCsv()}>
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default SalesReportByStaff;
