import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import patientReducer from "../slices/patientSlice";
import datatemplateReducer from "../slices/DataTableTemplateSlice";
import staffFormSlice from "../slices/StaffFormsSlices";
import adminSlice from "../slices/adminSlices";
import commonSlice from "../slices/commonSlice";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

const persistConfig = {
	key: "root",
	storage,
	blacklist: ["templateData", "staffForm", "adminData"],
};

const rootReducer = combineReducers({
	commonData: commonSlice,
	userData: userReducer,
	patientData: patientReducer,
	templateData: datatemplateReducer,
	staffForm: staffFormSlice,
	adminData: adminSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: [thunk],
});

export const persistor = persistStore(store);
