import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { useState } from "react";
import { Button } from "react-bootstrap";
import Papa from "papaparse";
import FilterComponent from "../../SharedComponents/FilterComponent";
import Filters from "./Filters";
import { getInvoiceId } from "../../../helperFunctions/utils";

export default function PreviewTemplate() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  let { id } = params;
  const { allTemplates, previewTemplate, inPreview } = useSelector(
    (state) => state.templateData
  );

  const [previewTemplateData, setPreviewTemplateData] =
    useState(previewTemplate);
  const [templateTableData, setTemplateTableData] = useState([]);
  const [templateFilteredData, setTemplateFilteredData] = useState([]);
  const [templateTableColumns, setTemplateTableColumn] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [model, setModel] = useState("");

  const [tableName, setTableName] = useState("Preview");
  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  let keys = _.union(
    ...previewTemplateData?.map((object) => Object.keys(object))
  );

  keys = keys?.filter((o) => o !== "_id");

  const columns = keys?.map((o) => {
    return {
      name: o,
      selector: (row) => row[o],
    };
  });

  const tableData = (
    filteredArray?.length > 0 ? filteredArray : previewTemplateData
  )?.map((o) => {
    let obj = {};
    Object.entries(o).forEach((item) => {
      obj = {
        ...obj,
        [item[0]]:
          typeof item[1] == "boolean"
            ? JSON.stringify(item[1])
            : JSON.stringify(item[1]),
      };
    });

    return obj;
  });
  const getTemplate = async (id) => {
    let query = {};

    if (id != 0) {
      query.id = id;
    } else {
      if (!location?.state?.table_name) return;
      query.table_name = location?.state?.table_name;
      query.columns = location?.state?.columns;
    }
    const { result, error } = await authorizedRequests({
      url: `collections/templates-data/?query=${JSON.stringify(query)}`,
      method: "get",
    });
    if (error) {
      toastify(error?.message ?? "Unknown error occured", "error");
      return;
    }
    setTemplateTableColumn(
      id !== 0 ? result?.data?.template?.columns : location?.state?.columns
    );
    setFilterColumns(
      id !== 0 ? result?.data?.template?.columns.filter((d) => d.filter === true) : location?.state?.columns
    );

    const tempData = result?.data?.table_data;
    tempData?.map((o) => {
      if (o.envoiceId && o.envoiceId.length > 0) {
        o.envoiceId[0].invoiceNo = getInvoiceId(o.envoiceId[0].invoiceNo);
      }

      if (o.paymentRecord && o.paymentRecord.length > 0) {
        o.paymentRecord = o.paymentRecord[o.paymentRecord.length - 1];
      }

      return false;
    })

    setTemplateTableData([...tempData]);
    setModel(result?.data?.template?.table_name);
    console.log({ result });
    setTableName(result.data.template.name);
    return result.data;
  };

  useEffect(() => {
    if (id) getTemplate(id);
  }, [id, location?.state?.table_name]);

  const table_columns = templateTableColumns?.map((o) => {
    if (o?.refColumn && o?.refColumn?.toLowerCase() === "date") {
      return {
        name: o.name,
        selector: (row) =>
          new Date(row[o?.column][o?.refColumn]).toLocaleDateString(),
      };
    }
    if (o.ref) {
      return {
        name: (() => {
          return (
            <div className="d-flex align-items-center" id="ovly2">
              <span className="me-2">{o.name}</span>
              <FilterComponent
                withRef={o.refColumn}
                isNested
                type="search"
                array={templateFilteredData}
                setArray={setFilteredArray}
                value={o.column}
              />
            </div>
          );
        })(),
        selector: (row) => {
          let value = row;
          if (o.column.includes(".")) {
            const key = o.column;
            const keys = key.split(".");

            keys.forEach((k) => {
              value = value[k];
            });
          } else {
            value = value[o.column];
          }
          if (Array.isArray(value)) {
            return (
              <>
                {value.length > 0 &&
                  value.map((k) => {
                    let refValue = k;
                    if (o?.refColumn?.includes(".")) {
                      const key = o.refColumn;
                      const keys = key.split(".");

                      keys.forEach((refKey) => {
                        refValue = refValue[refKey];
                      });
                    } else {
                      refValue = refValue[o.refColumn];
                    }
                    if (Array.isArray(refValue)) {
                      {
                        refValue?.length > 0 &&
                          refValue?.map((r) => {
                            return (
                              <div className="text-center">
                                {typeof refValue !== "string"
                                  ? JSON.stringify(refValue)
                                  : refValue}
                              </div>
                            );
                          });
                      }
                    } else {
                      return (
                        <div className="text-center">
                          {typeof refValue !== "string"
                            ? JSON.stringify(refValue)
                            : refValue}
                        </div>
                      );
                    }
                  })}
              </>
            );
          } else {
            let refValue = value;
            if (o?.refColumn?.includes(".")) {
              const key = o.refColumn;
              const keys = key.split(".");

              keys.forEach((refKey) => {
                refValue = refValue[refKey];
              });
            } else {
              refValue = refValue[o.refColumn];
            }
            if (Array.isArray(refValue)) {
              {
                refValue?.length > 0 &&
                  refValue?.map((r) => {
                    return (
                      <div className="text-center">
                        {typeof refValue !== "string"
                          ? JSON.stringify(refValue)
                          : refValue}
                      </div>
                    );
                  });
              }
            } else {
              return (
                <div className="text-center">
                  {typeof refValue !== "string"
                    ? JSON.stringify(refValue)
                    : refValue}
                </div>
              );
            }
          }
        },
        sortable: true,
      };
    }
    if (o.column.toLowerCase() == "date") {
      return {
        name: o.name,
        selector: (row) => new Date(row[o.column]).toLocaleDateString(),
        sortable: true,
      };
    }
    return {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">{o.name}</span>
          {!o.column.includes(".") ? (
            <FilterComponent
              dynamicFilter
              type="search"
              array={templateFilteredData}
              setArray={setFilteredArray}
              value={o.column}
            />
          ) : (
            <>
              <FilterComponent
                isNested
                dynamicFilter
                type="search"
                array={templateFilteredData}
                setArray={setFilteredArray}
                value={o.column}
              />
            </>
          )}
        </div>
      ),
      selector: (row) => {
        if (o.column.includes(".")) {
          const key = o.column;
          const keys = key.split(".");
          let value = row;

          keys?.forEach((k) => {
            if (value && k) {
              value = value[k] || null;
            }
          });
          if (Array.isArray(value)) {
            return (
              <>
                {value.length > 0 &&
                  value.map((k) => {
                    return (
                      <div className="text-center">
                        {typeof k !== "string" ? JSON.stringify(k) : k}
                      </div>
                    );
                  })}
              </>
            );
          }
          return value;
        }
        return typeof row[o.column] !== "string"
          ? JSON.stringify(row[o.column])
          : row[o.column];
      },
      sortable: true,
    };
  });

  useEffect(() => {
    if (
      location?.state?.startDate &&
      location?.state?.endDate &&
      templateTableData.length > 0
    ) {
      console.log(location?.state?.startDate);
      const tempArr = templateTableData.filter(
        (o) =>
          new Date(o.created_at).getTime() >
            location?.state?.startDate?.getTime() &&
          new Date(o.created_at)?.getTime() <
            location?.state?.endDate?.getTime()
      );
      setTemplateFilteredData([...tempArr]);
    } else {
      setTemplateFilteredData([...templateTableData]);
    }
  }, [location?.state?.startDate, location?.state?.endDate, templateTableData]);

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                if (id != 0) navigate(-1);
                navigate("/add-template", {
                  state: location.state,
                });
              }}
            />
            {tableName}
          </h1>
        </div>
        <div className="mb-3 w-100 d-flex justify-content-between px-4">
          <Button
            className="addItem px-5 ms-auto"
            style={{ height: "45px" }}
            onClick={() => {
              var csv = Papa.unparse(tableData, [
                {
                  quotes: false,
                  quoteChar: '"',
                  escapeChar: '"',
                  delimiter: ",",
                  header: true,
                  newline: "\r\n",
                  skipEmptyLines: false,
                  columns: null,
                },
              ]);
              var blob = new Blob([csv], { type: "text/csv" });
              var url = URL.createObjectURL(blob);
              var pom = document.createElement("a");
              pom.href = url;
              pom.setAttribute("download", `${tableName}.csv`);
              pom.click();
            }}
          >
            Export
          </Button>
        </div>
        <Filters
          columns={filterColumns}
          model={model}
          array={templateFilteredData}
          setArray={setFilteredArray}
          filteredArray={filteredArray}
        />
        <DataTable
          columns={table_columns}
          customStyles={customStyles}
          data={
            filteredArray?.length > 0 ? filteredArray : templateFilteredData
          }
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
        <div style={{ marginBottom: "20px" }}></div>
      </div>
    </>
  );
}
