import { useState } from "react";
import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { PDFViewer } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { permissionTypes } from "../../../helperFunctions/routes";
import { toastify } from "../../../helperFunctions/toastify";
import {
  customDateSort,
  customNumberSort,
  customStringSort,
  exportData,
  getInvoiceId,
} from "../../../helperFunctions/utils";
import { resetValue } from "../../../redux/slices/StaffFormsSlices";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import InvoicePDF from "../../SharedComponents/InvoicePDF";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import FilterComponent from "../../SharedComponents/FilterComponent";
import DateInput from "../../SharedComponents/DateInput";
import { getSetting } from "../../../apis/generalSetting";

export default function Billing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [billingData, setBillingData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [itemToDel, setItemToDel] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState({});
  const [stateDate, setStateDate] = useState(tableSearch[fieldPathname]?.currentDate ? tableSearch[fieldPathname]?.currentDate : new Date());
  const [setting, setSetting] = useState(null);
  const [usePointVal, setUsePointVal] = useState(0);

  const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
				currentDate: value
			}
		}))
  }

  const getDefaultFilteredData = (values) => {
		let list = [...values];
		const fieldPathname = pathname.replace("/", "");
	
		if (tableSearch[fieldPathname]) {
			if (tableSearch[fieldPathname].patientName) {
				list = values.filter(v => v.patientName?.toLowerCase()?.includes(tableSearch[fieldPathname].patientName.toLowerCase()));
			}
		}
	
		return list;
	}

  useEffect(() => {
    getSetting().then((res) => {
      if (res.status !== "Fail") {
        setSetting(res);
      }
    });

    authorizedRequests({
      url: "membershipSetting/listOfMembershipSetting",
      method: "get",
    }).then((res) => {
      setUsePointVal(res.result?.data[0]?.rewardPoint ?? 0);
    });
  }, []);

  const getBillingsList = async () => {
    const { result, error } = await authorizedRequests({
      url: "payment/getConversion",
      method: "get",
      params: {
        date: stateDate,
      },
    });
    if (error) {
      toastify(error?.message ?? "Failed to get billing details", "error");
      return;
    }

    const tempArr = result.data.map((o) => {
      return {
        ...o,
        patientName: o?.patient?.patientProfile?.fullName || "No Name",
        tableData: {
          date: o.date,
          patientId: o.patient?._id,
          paymentId: o.paymentId,
          refundId: o.refundId,
          id: o._id,
          name: o?.patient?.patientProfile?.fullName || "No Name",
          patient: o?.patient,
          newamount: o.totalAmount,
          oldamount: o.prevTotalAmount,
          type: o.isType,
          treatment: o?.treatment,
          package: o.package,
          product: o.product,
          location: o?.location,
          branch: o?.branch,
        },
      };
    });

    setBillingData([...tempArr]);
  };

  const getStatusPayment = (o) => {
    const paidAmount =
      o?.paymentRecord && o?.paymentRecord?.length > 0
        ? o?.paymentRecord[o?.paymentRecord?.length - 1].payed
        : 0;
    const gstAmount = o?.gst;

    if (paidAmount < gstAmount && paidAmount !== 0) {
      return "Outstanding";
    }

    if (o?.isPaymentCompleted === true) {
      return "Paid";
    }

    return "Unpaid";
  };

  const onRowEdit = async (id) => {
    let session = billingData.find((o) => o._id == id);

    navigate("/add-conversion", {
      state: {
        date: session.date,
        branch: session?.branch,
        isType: session?.isType,
        location: session?.location,
        preInvoice: session?.preInvoice,
        patient: session.patient,
        formStatus: "Edit",
        dataId: session?._id,
        totalAmount: session.totalAmount,
        prevTotalAmount: session.prevTotalAmount,
        package: [...session?.package],
        product: [...session?.product],
        treatment: [...session?.treatment],
        others: [...session?.others],
        paymentAdjustments: [...session?.paymentAdjustments],
      },
    });
  };

  const onPayment = async (id) => {
    let session = billingData.find((o) => o._id == id);

    navigate("/add-billing", {
      state: {
        converssionId: session?._id,
        location: session?.location,
        branch: session?.branch,
        subtotalAmount: session.totalAmount,
        totalAmount: session.totalAmount,
        isConversion: true,
        patient: session.patient,
        package: [...session?.package],
        product: [...session?.product],
        treatment: [...session?.treatment],
        others: [...session?.others],
        paymentAdjustments: [...session?.paymentAdjustments],
        preInvoice: session?.preInvoice,
      },
    });
  };
  const onRefund = async (id) => {
    let session = billingData.find((o) => o._id == id);

    navigate("/add-refund", {
      state: {
        converssionId: session?._id,
        location: session?.location,
        branch: session?.branch,
        subtotalAmount: session.totalAmount,
        totalAmount: session.totalAmount,
        prevTotalAmount: session.prevTotalAmount,
        isConversion: true,
        patient: session.patient,
        package: [...session?.package],
        product: [...session?.product],
        treatment: [...session?.treatment],
        preInvoice: session?.preInvoice,
      },
    });
  };

  const onRowDelete = async (item) => {
    const { result, error } = await authorizedRequests({
      url: "payment/deleteConversion",
      method: "post",
      data: {
        id: item.id,
      },
    });
    if (error) {
      toastify(error.message ?? "Failed to delete", "error");
      return;
    }
    // const tempArr = result.data.map((o) => {
    //   return {
    //     ...o,
    //     tableData: {
    //       date: new Date(o.created_at).toLocaleDateString("en-GB", {
    //         day: "numeric",
    //         month: "numeric",
    //         year: "numeric",
    //       }),
    //       patientId: o.patient?._id,
    //       paymentId: o.paymentId,
    //       refundId: o.refundId,
    //       id: o._id,
    //       name: o?.patient?.patientProfile?.fullName || "No Name",
    //       patientData: o?.patient,
    //       newamount: o.totalAmount,
    //       oldamount: o.prevTotalAmount,
    //       type: o.isType,
    //       treatment: o?.treatment,
    //       package: o.package,
    //       product: o.product,
    //       location: o?.location,
    //       branch: o?.branch,
    //     },
    //   };
    // });

    // setBillingData([...tempArr]);
    toastify("Converssion deleted succesfully", "success");
    getBillingsList();
  };

  useEffect(() => {
    getBillingsList();
    dispatch(resetValue("billingForm"));
    dispatch(resetValue("selectedSession"));
  }, [stateDate]);

  const column = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Patient Name</span>
          {
            <FilterComponent
              type="search"
              array={billingData}
              setArray={setFilteredData}
              value="patientName"
              searchValue={tableSearch[fieldPathname]?.patientName}
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "New Amount",
      selector: (row) => row.newamount?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amount"),
    },
    {
      name: "Old Amount",
      selector: (row) => row.oldamount?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amount"),
    },
    {
      name: "Difference",
      selector: (row) => row.difference?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amount"),
    },
    {
      name: "Billing Adjustment",
      selector: (row) => row.next?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amount"),
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.statusButton,
    //   wrap: true,
    // },
    {
      name: "Action",
      selector: (row) => row.action,
      wrap: true,
    },
  ];

  const data = getDefaultFilteredData(billingData)
    ?.map((o, i) => {
      let classNameBtn = "";
      if (
        !o.paymentId &&
        !o.refundId &&
        o?.tableData?.newamount != o?.tableData?.oldamount
      ) {
        classNameBtn = "red-rounded-button";
      } else {
        classNameBtn = "green-rounded-button";
      }
      return {
        index: i,
        invoice: o?.tableData?.invoice ?? "-",
        date: o?.tableData?.date,
        id: o?.tableData?.id,
        name: o?.tableData?.name,
        newamount: o?.tableData?.newamount,
        oldamount: o?.tableData?.oldamount,
        difference: o?.tableData?.newamount - o?.tableData?.oldamount,
        next:
          o?.tableData?.newamount > o?.tableData?.oldamount
            ? "Additional Charge"
            : o?.tableData?.newamount < o?.tableData?.oldamount
            ? "Refund"
            : "Conversion",
        gst: o?.tableData?.gst ?? "-",
        paidAmount: o?.tableData?.paidAmount ?? 0,
        // isFree: o?.tableData?.isFree ? "True" : "False",

        statusButton: (
          <>
            <div className={classNameBtn}>
              {!o.paymentId &&
              !o.refundId &&
              o?.tableData?.newamount != o?.tableData?.oldamount
                ? "Pending"
                : "Completed"}
            </div>
          </>
        ),
        action: (
          <div className="d-flex ">
            {
              <ComponentWrapepr
                moduleName="Finance"
                permissionType={permissionTypes.UPDATE}
              >
                {
                  <div
                    className="cursor-pointer icon-width"
                    onClick={() => {
                      onRowEdit(o._id);
                    }}
                  >
                    <i className="fa fa-pen"></i>
                  </div>
                }
              </ComponentWrapepr>
            }

            {
              <ComponentWrapepr
                moduleName="Finance"
                permissionType={permissionTypes.DELETE}
              >
                <div
                  className=" cursor-pointer icon-width"
                  onClick={() => {
                    setItemToDel(o?.tableData);
                    setOpenModal(true);
                    // onRowDelete(o?.tableData?.id);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </div>
              </ComponentWrapepr>
            }
          </div>
        ),
      };
    })
    .reverse();

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];
  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => {
                navigate(-1);
              }}
            ></i>

            <span className="400-28-32">List Conversion</span>
          </h1>
          <span className="d-flex">
            <div className="me-3" style={{ width: "200px" }}>
              <DateInput
                value={stateDate}
                placeholder="Date"
                shouldDisableDates={true}
                onChange={(e) => {
                  setStateDate(e);
                  onSubmitRedux(e);
                }}
              />
            </div>
            <ComponentWrapepr
              moduleName="Finance"
              permissionType={permissionTypes.CREATE}
            >
              <Button
                className="addItem"
                onClick={() => {
                  dispatch(resetValue("billingForm"));
                  navigate("/add-conversion", { state: { newBilling: true } });
                }}
              >
                + Add New
              </Button>
            </ComponentWrapepr>
          </span>
        </div>
        <DataTable
          columns={column}
          data={data || []}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
      {openModal && (
        <>
          <Modal
            centered
            show={openModal}
            onHide={() => {
              setItemToDel(null);

              setOpenModal(!openModal);
            }}
          >
            <Modal.Body className="font-18">
              Confirm Conversion Deletion
              <div className="align-self-end m-3 d-flex">
                <Button
                  className="me-3 delete-no ms-auto"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  No
                </Button>
                <Button
                  className="delete-yes"
                  onClick={() => {
                    onRowDelete(itemToDel);
                    setOpenModal(false);
                  }}
                >
                  Yes
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}

      {showInvoice && (
        <Modal
          centered
          show={showInvoice}
          onHide={() => {
            setShowInvoice(false);
          }}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Invoice
            </Modal.Title>
          </Modal.Header>

          <div className="pdf-btn">
            <PDFViewer style={{ width: "100%", height: "800px" }}>
              <InvoicePDF
                billing={selectedBilling}
                setting={setting}
                usePointVal={usePointVal}
              />
            </PDFViewer>
            {/* <PDFDownloadLink document={<InvoicePDF billing={selectedBilling} />} fileName="Invoice.pdf">
								{({ blob, url, loading, error }) =>
									loading ? 'Loading document...' : 'Download PDF'
								}
							</PDFDownloadLink> */}
          </div>
        </Modal>
      )}
    </>
  );
}
