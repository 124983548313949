import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import {
	Button,
	Form,
	InputGroup,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import {
	resetValue,
	setData,
	setForm,
} from "../../../redux/slices/adminSlices";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function AddReminderTemplate() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [options, setOptions] = useState({
		via: [
			{
				label: "Whatsapp",
				value: "whatsapp",
			},
			{
				label: "Gmail",
				value: "email",
			},
			{
				label: "SMS",
				value: "sms",
			},
		],
		viaDetail: [
			{
				label: "Phone Number",
				value: "phone_number",
			},
			{
				label: "Email",
				value: "email",
			},
			{
				label: "SMS",
				value: "sms",
			},
		],
	});
	const { reminderTemplateForm } = useSelector((state) => state.adminData);

	const onFormChange = (e) => {
		const { value, name } = e.target;
		dispatch(
			setForm({ parent: "reminderTemplateForm", child: name, value }),
		);
	};

	const submitForm = async (e) => {
		e.preventDefault();
		if (!reminderTemplateForm.name) {
			toastify("Name is missing", "error");
			return;
		}
		if (!reminderTemplateForm.message) {
			toastify("Message is missing", "error");
			return;
		}
		if (
			reminderTemplateForm.via === "whatsapp" &&
			reminderTemplateForm.contactNumbers?.length === 0
		) {
			toastify("Please add at least 1 contact number", "error");
			return;
		}
		var contacts = [...reminderTemplateForm.contactNumbers];

		if (reminderTemplateForm.formStatus === "New") {
			const { result, error } = await authorizedRequests({
				url: "reminder/createReminder",
				method: "post",
				data: {
					name: reminderTemplateForm.name,
					message: reminderTemplateForm.message,
					via: reminderTemplateForm.via,
					viaDetail: reminderTemplateForm.viaDetail,
					phoneNumberList:
						reminderTemplateForm.via === "whatsapp" ? contacts : [],
					// emailNumberList:
					// 	reminderTemplateForm.via === "whatsapp" ? [] : contacts,
				},
			});

			if (error) {
				toastify(error.message ?? "Unknown error occured", "error");
				return;
			}
			dispatch(resetValue("reminderTemplateForm"));
			toastify("Added succesfully", "success");

			navigate("/reminder-template");
		} else {
			const { result, error } = await authorizedRequests({
				url: "reminder/updateReminder",
				method: "post",
				data: {
					name: reminderTemplateForm.name,
					message: reminderTemplateForm.message,
					via: reminderTemplateForm.via,
					viaDetail: reminderTemplateForm.viaDetail,
					phoneNumberList:
						reminderTemplateForm.via === "whatsapp" ? contacts : [],
					// emailNumberList:
					// 	reminderTemplateForm.via === "whatsapp" ? [] : contacts,
					dataId: reminderTemplateForm.dataId,
				},
			});

			if (error) {
				toastify(error.message ?? "Unknown error occured", "error");
				return;
			}
			dispatch(resetValue("reminderTemplateForm"));
			toastify("Added succesfully", "success");

			navigate("/reminder-template");
		}
	};
	useMemo(() => {
		if (location?.state?.valueObj) {
			console.log(
				"🚀 ~ file: AddEditReminder.jsx:124 ~ useMemo ~ location?.state?.valueObj",
				location?.state?.valueObj,
			);
			dispatch(
				setData({
					parent: "reminderTemplateForm",
					value: location?.state?.valueObj,
				}),
			);
		}
	}, [location?.state?.valueObj]);

	return (
		<>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => {
							navigate("/reminder-template");
						}}
					></i>
					{`${
						reminderTemplateForm.formStatus == "New"
							? "Add "
							: "Edit "
					}`}
					Reminder Template
				</h1>
			</div>
			<div className="pb-4 px-4">
				<div className="content">
					<div className="addEdit pb-4">
						<div className="row">
							<div>
								<Form.Group className="col-md-6">
									<Form.Label className="black-label-text required">
										Name
									</Form.Label>
									<InputGroup className="mb-3 w-75">
										<Form.Control
											name={"name"}
											placeholder="Write..."
											onChange={(e) => {
												onFormChange(e);
											}}
											value={reminderTemplateForm.name}
										/>
									</InputGroup>
								</Form.Group>
								<Form.Group className="col-md-6">
									<Form.Label className="black-label-text required">
										Message
										<OverlayTrigger
											placement="right"
											delay={{ show: 400, hide: 400 }}
											overlay={(props) => {
												return (
													<Tooltip {...props}>
														<div
															className="w-100 mb-2"
															style={{
																textAlign:
																	"start",
															}}
														>
															Write{" "}
															{`{{fullName}}`} for
															Patient Name
														</div>
														<div
															className="w-100 mb-2"
															style={{
																textAlign:
																	"start",
															}}
														>
															Write{" "}
															{`{{startTime}}`}{" "}
															for Appointment
															Start Time
														</div>
														<div
															className="w-100 mb-2"
															style={{
																textAlign:
																	"start",
															}}
														>
															Write{" "}
															{`{{endTime}}`} for
															Appointment End Time
														</div>
														<div
															className="w-100"
															style={{
																textAlign:
																	"start",
															}}
														>
															Write {`{{date}}`}{" "}
															for Appointment Date
														</div>
													</Tooltip>
												);
											}}
										>
											<i
												className="fa pointer fa-question-circle ms-2"
												style={{
													color: "white",
													background: "black",
													borderRadius: "50%",
													border: "2px solid black",
												}}
											></i>
										</OverlayTrigger>
									</Form.Label>
									<InputGroup className="mb-3 w-75">
										<Form.Control
											name={"message"}
											as={"textarea"}
											rows={3}
											placeholder="Write..."
											style={{ resize: "none" }}
											onChange={(e) => {
												onFormChange(e);
											}}
											value={reminderTemplateForm.message}
										/>
									</InputGroup>
								</Form.Group>

								<Form.Group className="col-md-6">
									<Form.Label className="black-label-text">
										Via
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										nullDisabled
										className="w-75"
										options={options.via}
										onChange={(e) => {
											// onFormChange(e);
											onFormChange({
												target: {
													name: "contactNumbers",
													value: [],
												},
											});
											onFormChange({
												target: {
													name: "via",
													value: e.value,
												},
											});
											// console.log(e.target.value);
											onFormChange({
												target: {
													name: "viaDetail",
													value:
														e.value == "whatsapp"
															? "phone_number"
															: e.value == "sms"
															? "sms"
															: "email",
												},
											});
										}}
										name={"via"}
										selectedValue={reminderTemplateForm.via}
									/>
								</Form.Group>
								<Form.Group className="col-md-6">
									<Form.Label className="black-label-text">
										Number / Email
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										nullDisabled
										className="w-75"
										options={options.viaDetail}
										onChange={(e) => {
											onFormChange(e);
										}}
										name={"viaDetail"}
										isDisabled={true}
										selectedValue={
											reminderTemplateForm.viaDetail
										}
									/>
								</Form.Group>
								<>
									{reminderTemplateForm?.contactNumbers
										?.length > 0 &&
										reminderTemplateForm?.contactNumbers?.map(
											(o, i) => {
												return (
													<>
														<div className="d-flex">
															<Form.Group className="col-md-6">
																<Form.Label className="black-label-text required">
																	{reminderTemplateForm.via ==
																	"whatsapp"
																		? "Contact "
																		: "Email "}
																	{i + 1}
																</Form.Label>
																<div className="d-flex">
																	<InputGroup className="mb-3 w-75">
																		<Form.Control
																			name={
																				"contact"
																			}
																			placeholder="Write..."
																			type={
																				reminderTemplateForm.via ==
																				"whatsapp"
																					? "number"
																					: "text"
																			}
																			onChange={(
																				e,
																			) => {
																				let tempArr =
																					[
																						...reminderTemplateForm.contactNumbers,
																					];
																				tempArr[
																					i
																				] =
																					e.target.value;
																				dispatch(
																					setForm(
																						{
																							parent: "reminderTemplateForm",
																							child: "contactNumbers",
																							value: tempArr,
																						},
																					),
																				);
																			}}
																			value={
																				reminderTemplateForm
																					.contactNumbers[
																					i
																				]
																			}
																		/>
																	</InputGroup>
																	<i
																		className="fa fa-xl fa-trash align-self-center ms-3 mb-2 pointer"
																		onClick={() => {
																			let tempArr =
																				reminderTemplateForm.contactNumbers?.filter(
																					(
																						k,
																						idx,
																					) => {
																						return (
																							idx !==
																							i
																						);
																					},
																				);
																			dispatch(
																				setForm(
																					{
																						parent: "reminderTemplateForm",
																						child: "contactNumbers",
																						value: tempArr,
																					},
																				),
																			);
																		}}
																	></i>
																</div>
															</Form.Group>
														</div>
													</>
												);
											},
										)}
									{reminderTemplateForm?.via ==
										"whatsapp" && (
										<>
											<Button
												className="my-add-button mb-3"
												onClick={() => {
													const tempArr = [
														...reminderTemplateForm.contactNumbers,
													];
													tempArr.push("");
													dispatch(
														setForm({
															parent: "reminderTemplateForm",
															child: "contactNumbers",
															value: tempArr,
														}),
													);
												}}
											>
												Add Number
											</Button>
										</>
									)}
								</>
							</div>
						</div>
						<Button
							className="addItem mt-2"
							style={{ width: "250px" }}
							onClick={submitForm}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</>
	);
}
