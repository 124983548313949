import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.scss";

import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import BarGraph from "./BarGraph";
import ClientGrowth from "./ClientGrowth";
import DemographicReport from "./DemographicReport";
import HearAboutUs from "./HearAboutUs";
import {
  exportData,
  getAgeFromDob,
  getInvoiceId,
  removeDuplicatesFromArray,
} from "../../../helperFunctions/utils";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { Button, Form } from "react-bootstrap";
import { yearsOptions } from "../../../constants";

export const filterTypes = [
  {
    label: "Date Range",
    value: "date",
  },
  {
    label: "By Month",
    value: "month",
  },
  {
    label: "By Quarter",
    value: "quarter",
  },
];

const ClientProfileReports = () => {
  const [selectedTab, setSelectedTab] = useState("clientGrowth");
  const [selectedQuaterStart, setSelectedQuaterStart] = useState(null);
  const [quarterStartYear, setQuarterStartYear] = useState();
  const [quarterEndYear, setQuarterEndYear] = useState();
  const [selectedQuarterEnd, setSelectedQuaterEnd] = useState();
  const [range, setRange] = useState({
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(),
  });
  const [filterType, setFilterType] = useState("date");

  return (
    <div className="client-profile-page min-vh-100">
      <div className="px-4 py-2">
        <div className="mainHeading">
          <h1>Dashboard / Client Profile</h1>
        </div>
      </div>
      <div className="page-header col-9 d-flex px-4 w-100">
        <div className="sections-tabs">
          <div
            className={`tab-item ${
              selectedTab === "clientGrowth" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("clientGrowth")}
          >
            Client Growth
          </div>
          <div
            className={`tab-item ${
              selectedTab === "demographic" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("demographic")}
          >
            Demographic
          </div>
          <div
            className={`tab-item ${
              selectedTab === "aboutUs" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("aboutUs")}
          >
            How Did You Hear About Us
          </div>
        </div>
      </div>
      <div className="content-block">
        {selectedTab === "clientGrowth" ? (
          <ClientGrowth />
        ) : selectedTab === "demographic" ? (
          <DemographicReport />
        ) : selectedTab === "aboutUs" ? (
          <HearAboutUs />
        ) : null}
      </div>
    </div>
  );
};

export default ClientProfileReports;
