import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getSuppliers, updateSupplier } from "../../../apis/supplier";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function Supplier() {
	const navigate = useNavigate();
	const [suppliers, setSuppliers] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getSuppliersList = async () => {
		const response = await getSuppliers();
		if (response?.status !== "Fail") {
			setSuppliers(response.reverse());
		} else {
			if (response?.message === "No data Found") {
				setSuppliers([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const removeSupplier = async () => {
		setShow(false);
		let response = await updateSupplier(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			getSuppliersList();
			toastify("Supplier deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	const editSupplier = (e) => {
		navigate("/add-supplier", {
			state: {
				supplier: e,
			},
		});
	};

	const addSupplier = () => {
		navigate("/add-supplier");
	};

	useEffect(() => {
		getSuppliersList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly12">
					<span className="me-2">Name</span>
					{
						<FilterComponent
							type="search"
							array={suppliers}
							setArray={setFilteredData}
							value="Name"
						/>
					}
				</div>
			),
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Contact No",
			selector: (row) => row.contactNumber,
			wrap: true,
			sortable: true,
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly13">
					<span className="me-2">Email</span>
					{
						<FilterComponent
							type="search"
							array={suppliers}
							setArray={setFilteredData}
							value="Email"
						/>
					}
				</div>
			),
			selector: (row) => row.email,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "emailText"),
		},
		{
			name: (
				<div className="d-flex align-items-center" id="ovly14">
					<span className="me-2">Address</span>
					{
						<FilterComponent
							type="search"
							array={suppliers}
							setArray={setFilteredData}
							value="Address"
						/>
					}
				</div>
			),
			selector: (row) => row.address,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "address"),
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? suppliers : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				name: e.name,
				index: index,
				address: e.address,
				emailText: e.email,
				email: <span className="color-blue">{e.email}</span>,
				contactNumber: e.contactNumber,
				action: (
					<div className="d-flex">
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								editSupplier(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Supplier</h1>
					<Button className="addItem" onClick={addSupplier}>
						+ New Supplier
					</Button>
				</div>

				<DeleteModal
					content="Confirm Supplier Deletion?"
					onYes={removeSupplier}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}
