import api from "../axios/axios-config";

export const getPurchaseList = async (query) => {
  try {
    let response = await api.get("purchaseList/listOfPurchaseList", {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
      params: query,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addPurchaseList = async (data) => {
  try {
    let response = await api.post("purchaseList/createPurchaseList", {
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updatePurchaseList = async (dataId, data) => {
  try {
    let response = await api.post("purchaseList/updatePurchaseList", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const migratePurchaseList = async (data) => {
  try {
    let response = await api.post("migrate/purchaselist", data);
    return response?.data?.message;
  } catch (error) {
    return error?.response?.data;
  }
};
