import api from "../axios/axios-config";

export const getVendorContact = async () => {
  try {
    let response = await api.get("vendorContact/listOfVendorContact");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createVendorContact = async (data) => {
  console.log({ data });
  try {
    let response = await api.post("vendorContact/createVendorContact", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateVendorContact = async (dataId, data) => {
  try {
    let response = await api.post("vendorContact/updateVendorContact", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteVendorContact = async (vendorId) => {
  try {
    let response = await api.delete(
      `vendorContact/deleteVendorContact?vendorId=${vendorId}`
    );
    return response?.data;
  } catch (error) {
    //
    return error?.response?.data;
  }
};

export const migrateVendorContact = async (data) => {
  try {
    let response = await api.post("migrate/vendorcontacts", data);
    return response?.data?.message;
  } catch (error) {
    return error?.response?.data;
  }
};
