import React, { useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import DateInput from "../../SharedComponents/DateInput";
import { toastify } from "../../../helperFunctions/toastify";
import { getAttendances } from "../../../apis/common";
import {
  convertDateTime,
  calculateTimeDiff,
  downloadCsv
} from "../../../helperFunctions/utils";
import { setTableSearch } from "../../../redux/slices/commonSlice";

import ModalDetail from "./ModalDetail";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function HumanResource({
  hideTitle
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [attendances, setAttendances] = useState([]);
  const [open, setOpen] = useState(false);
  const [detail, setDetail] = useState(null);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.humanResource?.startDate ? tableSearch[fieldPathname]?.humanResource?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.humanResource?.endDate ? tableSearch[fieldPathname]?.humanResource?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (valueStartDate, valueEndDate) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
        humanResource: {
          startDate: valueStartDate,
          endDate: valueEndDate,
        }
			}
		}))
  }

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.humanResource?.startDate ? tableSearch[fieldPathname]?.humanResource?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.humanResource?.endDate ? tableSearch[fieldPathname]?.humanResource?.endDate : new Date());

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.humanResource?.startDate ||
      tableSearch[fieldPathname]?.humanResource?.endDate
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          if (buttonRef.current) {
          buttonRef.current.click();
        }
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset();
    getAttendances({
      filterBy: "DR",
      dateFrom: startDate,
      dateTill: endDate,
      status: "pending",
      timezoneOffset,
    }).then((res) => {
      setAttendances(res);
    });
  }, [refetch]);

  const columns = [
    {
      name: "Username",
      selector: (row) => row.user?.userName,
      wrap: true,
      width: "250px",
      sortable: true,
    },
    {
      name: "Punch-in",
      selector: (row) => convertDateTime(row.punchInDateTime),
      wrap: true,
      sortable: true,
    },
    {
      name: "Punch-out",
      selector: (row) => convertDateTime(row.punchOutDateTime),
      wrap: true,
      sortable: true,
    },
    {
      name: "Total Hours",
      selector: (row) =>
        calculateTimeDiff(row.punchOutDateTime, row.punchInDateTime),
      wrap: true,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex">
          <div
            className="mx-3  cursor-pointer icon-width"
            onClick={() => {
              setDetail(row);
              setOpen(true);
            }}
          >
            <i className="fa fa-eye"></i>
          </div>
        </div>
      ),
    },
  ];

  const data = attendances?.map((e, index) => {
    return {
      ...e,
      index: index,
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data?.map((row) => {
      return {
        UserName: row.user?.userName,
        "Punch In": row.punchInDateTime,
        "Punch Out": row.punchOutDateTime,
        "Total Hours": calculateTimeDiff(row.punchOutDateTime, row.punchInDateTime),
      };
    });

    downloadCsv(csvData ? csvData : [], "Time In & Out");
  }

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          {
            !hideTitle && (
              <h1>Human Resource</h1>
            )
          }
          <FilterWrapper className="d-flex">
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(startDate, endDate);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button
                className="addItem"
                onClick={() => exportCsv()}
              >
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          highlightOnHover
          pointerOnHover
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>

      <ModalDetail open={open} setOpen={setOpen} detail={detail} />
    </>
  );
}

export default HumanResource;
