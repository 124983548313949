const { authorizedRequests } = require("../axios/axios-config");

const getSalesReport = async (data) => {
  const response = await authorizedRequests({
    url: "reports/newSalesReport",
    method: "post",
    data,
  });
  return response;
};

const getReferralReport = async (data) => {
  const response = await authorizedRequests({
    url: "reports/referralReport",
    method: "post",
    data,
  });
  return response;
};

const getSalesReportByStaff = async (data) => {
  const response = await authorizedRequests({
    url: "reports/salesReportStaff",
    method: "post",
    data,
  });
  return response;
};

const getTopSpenderReport = async (data) => {
  const response = await authorizedRequests({
    url: "reports/topSpenderReport",
    method: "post",
    data,
  });
  return response;
};

const getDrugMovementReport = async (data) => {
  const response = await authorizedRequests({
    url: "reports/drugMovementReport",
    method: "post",
    data,
  });
  return response;
};
const getPaymentReport = async (data) => {
  const response = await authorizedRequests({
    url: "reports/staff/finance/paymentMethod",
    method: "post",
    data,
  });
  return response;
};

const getPackageExpiryReport = async (data) => {
  const response = await authorizedRequests({
    url: "reports/packageExpiryReport",
    method: "post",
    data,
  });
  return response;
};

export {
  getSalesReport,
  getReferralReport,
  getSalesReportByStaff,
  getTopSpenderReport,
  getDrugMovementReport,
  getPackageExpiryReport,
  getPaymentReport,
};
