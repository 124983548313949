import api, { authorizedRequests } from "../axios/axios-config";

export const getCaseFoldersByPatient = async (id) => {
	const response = await authorizedRequests({
		url: "caseFolder/listCaseFolders",
		method: "post",
		data: {
			dataId: id,
		},
	});
	return response;
};

export const updateFolderDocuments = async (data) => {
	try {
		const response = await api.post(
			`caseFolder/updateFolderDocuments`,
			data,
		);
		return { result: response.data };
	} catch (error) {
		console.log(error);
		return { error: error?.response?.data };
	}
};

export const updateCaseFolder = async (data) => {
	const response = await authorizedRequests({
		url: "caseFolder/updateCaseFolder",
		method: "post",
		data,
	});
	return response;
};

export const createCaseFolder = async (data) => {
	const response = await authorizedRequests({
		url: "caseFolder/createCaseFolder",
		method: "post",
		data,
	});
	return response;
};
// export const updateFolderDocuments = async (data) => {

// };
