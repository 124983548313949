import React from "react";
import { Button, Modal } from "react-bootstrap";
import Camera from 'react-html5-camera-photo';
import styled from "styled-components";

import 'react-html5-camera-photo/build/css/index.css';

const CameraWrapper = styled.div`
  .react-html5-camera-photo>video, img {
    width: 100%;
    height: 350px;
  }
`;

export default function ModalCamera({
  openModal,
  setOpenModal,
  handleTakePhoto,
  handleSave,
  photo,
  existingPhoto,
}) {
  return (
    <Modal
      centered
      show={openModal}
      onHide={() => {
        handleTakePhoto(existingPhoto);
        setOpenModal(!openModal);
      }}
    >
      <Modal.Body className="font-18">
        <CameraWrapper>
          {
            photo ? (
              <img 
                src={photo}
                alt="photo"
              />
            ) : (
              <Camera
                onTakePhotoAnimationDone={ (dataUri) => { handleTakePhoto(dataUri); } }
                isFullscreen={false}
              />
            )
          }
        </CameraWrapper>
        <div className="align-self-end m-3 d-flex">
          <Button
            className="me-3 delete-no ms-auto"
            style={{width: "170px"}}
            disabled={photo === ""}
            onClick={() => {
              handleTakePhoto("");
            }}
          >
            Recapture Photo
          </Button>
          <Button
            className="delete-yes"
            style={{width: "120px"}}
            disabled={photo === ""}
            onClick={() => {
              handleSave();
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}