import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Button } from "react-bootstrap";
import Page from "../Page/Page";
import SidebarStaff from "../Sidebar/SidebarStaff";
import SidebarAdmin from "../Sidebar/SidebarAdmin";
import Dropdown from "react-bootstrap/Dropdown";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "../../Admin/SignIn/SignIn";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/slices/userSlice";
import { setTableSearch, setFormData } from "../../../redux/slices/commonSlice";

import Questionnaire from "../../Staff/Questionnaire/Questionnaire";
import HealthDeclaration from "../../Staff/HealthDeclaration";
import ForgetPassword from "../../Admin/ForgetPassword/ForgetPassword";
import ResetPassword from "../../Admin/ForgetPassword/ResetPassword";
import VerifyOTP from "../../Admin/ForgetPassword/VerifyOTP";
import FingerPrintSVG from "../../../assets/images/fingerprint.svg";
import { getApps } from "../../../apis/common";

function MainLayout() {
  const [sidebar, setSidebar] = useState(true);
  const [activePage, setActivePage] = useState("/");
  const [isLogin, setIsLogin] = useState(false);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.value);
  const [localToken, setLocalToken] = useState("");
  const [localOrgId, setLocalOrgId] = useState("");
  const [attdApp, setAttdApp] = useState(null);
  const currentRole = localStorage.getItem("currentRole");

  useEffect(() => {
    let token = localStorage.getItem("token");
    let orgId = localStorage.getItem("orgid");
    if (orgId) {
      setLocalOrgId(orgId);
    }
    if (token) {
      setLocalToken(token);
      setIsLogin(true);
    }
  }, [userData]);

  useEffect(() => {
    setActivePage(window.location.pathname);
  }, [window.location.pathname]);

  useEffect(() => {
    getApps().then((res) => {
      setAttdApp(res);
    });
  }, [window.location.pathname]);

  const handleSwitch = () => {
    if (!currentRole || currentRole === "admin") {
      localStorage.setItem("currentRole", "staff");

      const firstPermission = userData?.department?.permissions?.[0];
      if (firstPermission?.moduleName === "Appointment" || firstPermission?.moduleName === "Reception" || firstPermission?.moduleName === "Rostering") {
        window.location.replace("/rostering");
      } else if (firstPermission?.moduleName === "Patient-Database" || firstPermission?.moduleName === "Patient") {
        window.location.replace("/patient");
      } else if (firstPermission?.moduleName === "Session") {
        window.location.replace("/session");
      } else if (firstPermission?.moduleName === "Note-Taking") {
        window.location.replace("/doctor-notes");
      } else if (firstPermission?.moduleName === "Dashboards") {
        window.location.replace("/client-profile-reports");
      } else {
        window.location.replace("");
      }

      // if (firstPermission?.moduleName === "Reception") {
      //   window.location.replace("/rostering");
      // } else if (firstPermission?.moduleName === "Appointment") {
      //   window.location.replace("/appointments");
      // } else if (firstPermission?.moduleName === "Patient-Database") {
      //   window.location.replace("/patient");
      // } else if (firstPermission?.moduleName === "Session") {
      //   window.location.replace("/session");
      // } else if (firstPermission?.moduleName === "Note-Taking") {
      //   window.location.replace("/doctor-notes");
      // } else {
      //   window.location.replace("/customized-reports");
      // }
    } else {
      localStorage.setItem("currentRole", "admin");
      window.location.replace("/staff");
    }
  };
  // console.log("Main Layout userData..", userData);

  const handleDirectToAttd = () => {
    if (attdApp) {
      window.open(
        `${attdApp.link}/middlepage?token=${localToken}&organizationId=${localOrgId}`,
        "_blank"
      );
    }
  };

  return (
    <Router>
      <ToastContainer autoClose={5000} />

      {!isLogin ? (
        <Routes>
          <Route path="*" element={<SignIn />} />
          <Route path="/" exact element={<SignIn />} />
          <Route path="/questions/:id" exact element={<Questionnaire />} />
          <Route
            path="/medicalform/:id"
            exact
            element={<HealthDeclaration />}
          />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/verify-otp" element={<VerifyOTP />} />
          {/* <Route path='/reset-password' element={<ResetPassword />} /> */}
        </Routes>
      ) : !window.location.pathname.includes("/questions") &&
        !window.location.pathname.includes("/medicalform") ? (
        <div>
          <div className="topHead">
            <div className="container-fluid">
              <div className="topSection">
                <div className="logo-toggle" id="toggler">
                  <img src="/imgs/logo.svg" className="dpImg" alt="" />
                  <i
                    className={`fa-solid fa-bars ${
                      sidebar ? "openSidebar" : ""
                    }`}
                    onClick={() => setSidebar(!sidebar)}
                  ></i>
                </div>
                <div className="userProfile">
                  {userData?.role?.length > 0 &&
                    userData?.role?.includes("staff") && (
                      <div
                        className="attendance-wrapper"
                        onClick={() => handleDirectToAttd()}
                      >
                        <img src={FingerPrintSVG} alt="attendance" />
                      </div>
                    )}

                  {userData?.role?.length > 1 &&
                    !userData?.role?.includes("superAdmin") && (
                      <React.Fragment>
                        <Button
                          className="addItem"
                          style={{ fontSize: "14px" }}
                          onClick={() => handleSwitch()}
                        >
                          Switch{" "}
                          {!currentRole || currentRole === "admin"
                            ? "Staff"
                            : "Admin"}
                        </Button>
                        <span className="line"></span>
                      </React.Fragment>
                    )}
                  <div className="name">
                    <h5>{userData?.username}</h5>
                    <p>
                      Logged as{" "}
                      {userData?.role?.length === 1 &&
                        (userData?.isAdmin === true ? "Admin" : "Staff")}
                      {userData?.role?.length > 1 &&
                        (currentRole === "admin" || !currentRole
                          ? "Admin"
                          : "Staff")}
                    </p>
                  </div>
                  <div className="userImg">
                    <img
                      src={
                        userData?.profile
                          ? userData?.profile
                          : "/imgs/userImg.png"
                      }
                      alt="UserImg"
                    />
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="light"
                      id="dropdown-basic"
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                      {/* {userData?.role?.includes("admin") &&
                        userData?.role?.includes("staff") && (
                          <Dropdown.Item>Switch Role</Dropdown.Item>
                        )} */}
                      {!userData?.role?.includes("superAdmin") && (
                        <Dropdown.Item href={`/account/${userData?._id}`}>
                          Account
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(setTableSearch({}));
                          dispatch(setFormData({}));
                          dispatch(login(null));
                          localStorage.removeItem("token");
                          localStorage.removeItem("orgid");
                          localStorage.removeItem("bucketList");
                          localStorage.removeItem("currentRole");
                          window.location.assign("/")
                        }}
                        href={"#"}
                      >
                        Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
          {userData?.role?.length === 1 &&
            (userData?.isAdmin === true ? (
              <SidebarAdmin sidebar={sidebar} setSidebar={setSidebar} />
            ) : (
              <SidebarStaff sidebar={sidebar} setSidebar={setSidebar} />
            ))}

          {userData?.role?.length > 1 &&
            (!currentRole || currentRole === "admin" ? (
              <SidebarAdmin sidebar={sidebar} setSidebar={setSidebar} />
            ) : (
              <SidebarStaff sidebar={sidebar} setSidebar={setSidebar} />
            ))}
          <Page sidebar={sidebar} />
        </div>
      ) : (
        <Routes>
          <Route path="/questions/:id" exact element={<Questionnaire />} />
          <Route
            path="/medicalform/:id"
            exact
            element={<HealthDeclaration />}
          />
        </Routes>
      )}
    </Router>
  );
}

export default MainLayout;
