import { useMemo, useState } from "react";
import Select from "react-select";

export default function MultiSelectComponent({
  options,
  isNullEnabled,
  nullDisabled,
  onChange,
  name,
  className,
  array, // This will hold the selected values for multi-select
  searchEnabled,
  selectedValue,
  isDisabled,
  adjustValues,
  selectCondition,
}) {
  const selectStyles = {
    input: (styles) => ({
      ...styles,
      margin: "3px",
      height: 31,
      zIndex: "1000",
    }),
    control: (styles) => ({
      ...styles,
      border: "1px solid #939393",
      borderRadius: "7px",
      outline: "none",
      height: "fit-content",
      minHeight: "47px",
      zIndex: "1",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1001,
    }),
  };

  const [selectOptions, setSelectOptions] = useState([]);

  useMemo(() => {
    if (searchEnabled && options?.length > 0) {
      const tempArray = adjustValues
        ? options.map((o) => ({
            value: o.value,
            label: o.label,
          }))
        : options.map((o) => {
            return {
              value: o.value,
              label: o.label,
            };
          });

      if (nullDisabled) {
        setSelectOptions([...tempArray]);
      } else {
        setSelectOptions([{ label: "Select", value: "all" }, ...tempArray]);
      }
    }
  }, [options, searchEnabled, array, adjustValues, nullDisabled]);

  const handleChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    // If "all" is selected, remove all other selections
    if (selectedValues.includes("all")) {
      onChange(["all"]);
    } else {
      // Remove "all" if any other option is selected
      const filteredValues = selectedValues.filter((val) => val !== "all");
      onChange(filteredValues);
    }
  };

  return (
    <>
      <Select
        closeMenuOnSelect={false}
        className={`basic-single mb-3 ${className}`}
        classNamePrefix="select"
        value={
          selectCondition ?? options.filter((o) => array?.includes(o.value))
        }
        styles={selectStyles}
        isMulti
        isLoading={options.length === 0}
        isClearable={true}
        isSearchable={searchEnabled}
        name={name}
        options={selectOptions}
        onChange={handleChange}
        placeholder="Select"
        isDisabled={isDisabled}
      />
    </>
  );
}
