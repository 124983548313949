import api from "../axios/axios-config";

export const getProductCategory = async () => {
  try {
    let response = await api.get("productCategory/listOfProductCategory");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createProductCategory = async (data) => {
  try {
    let response = await api.post(
      "productCategory/createProductCategory",
      data
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateProductCategory = async (dataId, data) => {
  try {
    let response = await api.post("productCategory/updateProductCategory", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
