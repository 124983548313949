import React, { useEffect } from "react";
import { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { modifyPatientMembership } from "../../../apis/patients";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import { setPatientData } from "../../../redux/slices/patientSlice";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function TabMembershipInfo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const patientData = useSelector((state) => state.patientData.value);
  const numberRegex = new RegExp(`^[0-9]*$`);

  const [points, setPoints] = useState("");
  const [expiry, setExpiry] = useState();
  const [show, setShow] = useState(false);

  const savePatient = async () => {
    if (!expiry) {
      missingPopup("Expiry Date");
    } else if (!points) {
      missingPopup("Membership Point");
    } else {
      let membershipInfo = {
        points: points,
        pointsExpiry: new Date(expiry),
      };
      const response = await modifyPatientMembership(
        patientData._id,
        membershipInfo
      );
      if (response?.status !== "Fail") {
        dispatch(setPatientData(response));
        toastify("Membership Info added successfully!", "success");
        // navigate("/patient");
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const populateData = () => {
    setPoints(patientData?.points || 0);
    setExpiry(new Date(patientData?.pointsExpiry));
  };

  useEffect(() => {
    if (patientData?.points) populateData();
  }, []);

  const handleClickAway = () => {
    setShow(false);
  };

  return (
    <div>
      <div className="d-flex">
        <Form.Group className="col-5 mt-3">
          <Form.Label className="headings2">Loyalty Points</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Write..."
              value={points}
              onChange={(e) => {
                setPoints(e.target.value);
              }}
            />
          </InputGroup>
        </Form.Group>

        <span className="col-1" />

        <ClickAwayListener onClickAway={handleClickAway}>
          <div className="col-12">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={expiry}
                onChange={(newValue) => {
                  setExpiry(newValue?.$d);
                  setShow(false);
                }}
                open={show}
                // minDate={new Date()}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Expiry Date *</Form.Label>
                    <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                      <Form.Control
                        ref={inputRef}
                        // {...inputProps}
                        value={
                          expiry
                            ? new Date(expiry).toLocaleDateString("en-GB", {
                                day: "numeric",
                                month: "numeric",
                                year: "numeric",
                              })
                            : null
                        }
                        onClick={() => setShow(!show)}
                        readOnly
                        placeholder="Select"
                      />
                      <div onClick={() => setShow(!show)}>
                        {InputProps?.endAdornment}
                      </div>
                    </InputGroup>
                  </Form.Group>
                )}
              />
            </LocalizationProvider>
          </div>
        </ClickAwayListener>
      </div>

      <Button className="addItem" onClick={savePatient}>
        Save
      </Button>
    </div>
  );
}
