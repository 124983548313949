import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addAdditionalCost,
  updateAdditionalCost,
} from "../../../apis/additionalCost";
import { MONTHS } from "../../../constants";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function AddEditAdditionalCost() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [month, setMonth] = useState();
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [year, setYear] = useState();
  const [totalYears, setTotalYears] = useState([]);
  const numberRegex = new RegExp(`^[0-9]*$`);

  const populateData = async () => {
    setName(state?.cost?.name);
    setDescription(state?.cost?.description);
    setMonth(state?.cost?.month);
    setAmount(state?.cost?.amount);
    console.log(
      "🚀 ~ file: AddEditAdditionalCost.js:30 ~ populateData ~ state",
      state
    );
  };

  const saveAdditionalCost = async () => {
    if (!name) missingPopup("Name");
    else if (!month) missingPopup("Month");
    else if (!year) missingPopup("Year");
    else if (!amount) missingPopup("Amount");
    else {
      let data = { name, description, month, amount, year };

      if (state?.cost) {
        const response = await updateAdditionalCost(state?.cost?._id, data);
        if (response?.status !== "Fail") {
          navigate("/additional-marketing-cost");
          toastify(
            "Additional/Marketing Cost updated successfully!",
            "success"
          );
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await addAdditionalCost(data);
        if (response?.status !== "Fail") {
          navigate("/additional-marketing-cost");
          toastify("Additional/Marketing Cost added successfully!", "success");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const getYears = () => {
    let arr = [];
    let currentYear = new Date().getFullYear();

    for (let i = 1999; i <= currentYear; i++) {
      arr.push(i);
    }
    setTotalYears(arr);
  };

  useEffect(() => {
    if (state?.cost) populateData();
    getYears();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.cost ? "Edit " : "New "}`}
          Additional / Marketing Cost
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Year *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={year}
                    onChange={(e) => {
                      let val = e.value;
                      if (val === "") {
                        setYear();
                      } else {
                        setYear(e.value);
                      }
                    }}
                    options={[
                      ...totalYears?.map((e) => {
                        return {
                          label: e,
                          value: e,
                        };
                      }),
                    ]}
                  />
                  {/* <select
										className="form-select mb-3"
										aria-label="Default select example"
										onChange={(e) => {
											let val = e.target.value;
											if (val === "") {
												setYear();
											} else {
												setYear(e.target.value);
											}
										}}
									>
										<option selected disabled value={""}>
											Select
										</option>
										{totalYears?.map((e) => (
											<option
												selected={year === e}
												value={e}
											>
												{e}
											</option>
										))}
									</select> */}
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Month *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={month}
                    onChange={(e) => {
                      let val = e.value;
                      if (val === "") {
                        setMonth();
                      } else {
                        setMonth(e.value);
                      }
                    }}
                    options={[
                      ...MONTHS?.map((e) => {
                        return {
                          label: e,
                          value: e,
                        };
                      }),
                    ]}
                  />
                  {/* <select
										className="form-select mb-3"
										aria-label="Default select example"
										onChange={(e) => {
											let val = e.target.value;
											if (val === "") {
												setMonth();
											} else {
												setMonth(e.target.value);
											}
										}}
									>
										<option selected disabled value={""}>
											Select
										</option>
										{MONTHS?.map((e) => (
											<option
												selected={month === e}
												value={e}
											>
												{e}
											</option>
										))}
									</select> */}
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Amount *</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      style={{ resize: "none" }}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveAdditionalCost}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
