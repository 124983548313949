import styled from "styled-components";

export const CheckBoxWrapper = styled.ul`
  .MuiFormControlLabel-root {
    margin: 0px !important;
  }

  .MuiTypography-root {
    color: black;
  }
`;

export const AutocompleteWrapper = styled.div`
  .MuiOutlinedInput-root {
    padding: 6px !important;
  }

  fieldset {
    border-color: #939393;
    border-radius: 8px;
  }
`;