import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from 'react-router-dom';
import { unAuthorizesRequests } from '../../../axios/axios-config';
import { toastify } from '../../../helperFunctions/toastify';

function ForgetPassword() {
	const emailRef = useRef();
	const navigate = useNavigate();

	const submitForm = async (e) => {
		e.preventDefault();
		try {
			const { result, error } = await unAuthorizesRequests({
				url: 'auth/forgotemail',
				method: 'Post',
				data: {
					email: emailRef.current.value,
				},
			});
			if (error) {
				toastify(error.message, 'error');
				return;
			}
			localStorage.setItem(
				'forgot_user',
				JSON.stringify({
					token: result.data.token,
					email: emailRef.current.value,
				}),
			);
			navigate('/verify-otp');
		} catch (error) {
			toastify(error.message, 'error');
		}
	};

	return (
		<div className='forgetPassword'>
			<div className='row mx-0 forgetPassword'>
				<div className='col-12 my-auto'>
					<div className='text-center py-5'>
						<img src='/imgs/logo.svg' alt='' />
					</div>
					<div className='row mx-0'>
						<div className='col-md-6 mx-auto'>
							<div className='loginBox'>
								<h3>Forgotten Password</h3>
								<div className='row'>
									<div className='col-md-12 mx-auto'>
										<Form
											className='mt-5'
											onSubmit={submitForm}
										>
											<Form.Group
												className='mb-0'
												controlId='formBasicPassword'
											>
												<Form.Label className='required'>
													Email
												</Form.Label>
												<InputGroup className='mb-4'>
													<Form.Control
														type='email'
														ref={emailRef}
														placeholder='Enter your email...'
													/>
													<InputGroup.Text id='basic-addon1'>
														<img
															src='/imgs/u_eye.svg'
															className='cursor-pointer'
															alt=''
														/>
													</InputGroup.Text>
												</InputGroup>
											</Form.Group>
											<div className='mt-5 mb-3'>
												<Button
													className='loginBtn'
													type='submit'
												>
													Send OTP
												</Button>
											</div>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgetPassword;
