import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";
import { getRefund, updateRefund } from "../../../apis/refund";
import ComponentWrapepr from "../../SharedComponents/ComponentWrapper";
import { permissionTypes } from "../../../helperFunctions/routes";
import {
  customDateSort,
  customNumberSort,
  getInvoiceId,
} from "../../../helperFunctions/utils";

export default function Refund() {
  const navigate = useNavigate();
  const [refunds, setRefunds] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const getRefundList = async () => {
    const response = await getRefund();
    if (response.status !== "Fail") {
      setRefunds(response.reverse());
      console.log({ response });
    } else {
      if (response?.message === "No data Found") {
        setRefunds([]);
      } else {
        toastify(response?.message, "error");
      }
    }
  };
  let abc = 100000;

  const removeRefund = async () => {
    setShow(false);
    let response = await updateRefund(deleting, { isDeleted: true });
    if (response?.status !== "Fail") {
      getRefundList();
      toastify("Refund deleted successfully!", "success");
    } else {
      toastify(response?.message, "error");
    }
  };

  const editRefund = (e) => {
    navigate("/add-refund", {
      state: {
        refund: e,
      },
    });
  };

  const addRefund = () => {
    navigate("/add-refund");
  };

  useEffect(() => {
    getRefundList();
  }, []);

  const columns = [
    {
      name: "Date",
      selector: (row) =>
        new Date(row.date).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "date", true),
    },
    {
      name: "Patient Name",
      selector: (row) => row?.patient?.patientProfile?.fullName,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) =>
        customDateSort(a, b, "patient.patientProfile.fullName", true),
    },
    {
      name: "Invoice No",
      selector: (row) => row.invoiceNo,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "invoiceNo"),
    },
    {
      name: "Amount Refund",
      selector: (row) => row.amountRefund?.toLocaleString(),
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customNumberSort(a, b, "amountRefund"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const data = (filteredData.length === 0 ? refunds : filteredData)?.map(
    (e, index) => {
      return {
        id: e._id,
        index: index,
        date: e.updated_at,
        invoiceNo: getInvoiceId(e?.envoiceId?.invoiceNo) ?? "-",
        patient: e.envoiceId?.patient,
        amountRefund: e.amountRefund,
        action: (
          <div className="d-flex">
            <ComponentWrapepr
              moduleName="Finance"
              permissionType={permissionTypes.UPDATE}
            >
              <div
                className="mx-3  cursor-pointer icon-width"
                onClick={() => {
                  editRefund(e);
                }}
              >
                <i className="fa fa-pen"></i>
              </div>
            </ComponentWrapepr>
            <ComponentWrapepr
              moduleName="Finance"
              permissionType={permissionTypes.DELETE}
            >
              <div
                className="mx-3  cursor-pointer icon-width"
                onClick={() => {
                  setDeleting(e._id);
                  setShow(true);
                }}
              >
                <i className="fa fa-trash"></i>
              </div>
            </ComponentWrapepr>
          </div>
        ),
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        borderWidth: "0px",
        backgroundColor: "#f8f8f8",
      },
    },
  ];

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Refund</h1>
          <ComponentWrapepr
            moduleName="Finance"
            permissionType={permissionTypes.CREATE}
          >
            <Button className="addItem" onClick={addRefund}>
              + Add Refund
            </Button>
          </ComponentWrapepr>
        </div>

        <DeleteModal
          content="Confirm Refund Deletion?"
          onYes={removeRefund}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={data || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
