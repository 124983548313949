import React from "react";
import { Routes, Route } from "react-router-dom";
import ForgetPassword from "../../Admin/ForgetPassword/ForgetPassword";
import SignIn from "../../Admin/SignIn/SignIn";
import User from "../../Admin/User/User";
import BucketList from "../../Staff/Rostering/BucketList";
import Departments from "../../Admin/Departments/Departments";
import AddEditDepartments from "../../Admin/Departments/AddEditDepartments";
import AddEditUser from "../../Admin/User/AddEditUser";
import Questionnaire from "../../Staff/Questionnaire/Questionnaire";
import HealthDeclaration from "../../Staff/HealthDeclaration";
import QuestionnaireSettings from "../../Admin/Questionnaire/QuestionnaireSettings";
import AddEditQuestions from "../../Admin/Questionnaire/AddEditQuestionnaire";
import AddEditHealthDeclaration from "../../Admin/HealthDeclaration/AddEdit";
import HealthDeclarationSetting from "../../Admin/HealthDeclaration/HealthDeclarationSetting";
import AddEditConsent from "../../Admin/Consents/AddEditConsent";
import ConsentSettings from "../../Admin/Consents/Consents";
import Patient from "../../Staff/Patient/Patient";
import AddEditPatient from "../../Staff/Patient/AddEditPatient";
import OptionKol from "../../Admin/OptionKol/OptionKol";
import AddEditOptionKol from "../../Admin/OptionKol/AddEditOptionKol";
import KOL from "../../Staff/Kol/Kol";
import AddEditKol from "../../Staff/Kol/AddEditKol";
import MembershipSettings from "../../Admin/Membership/MembershipSettings";
import ExpiredSettings from "../../Admin/ExpiredSettings/ExpiredSettings";
import DoctorNotes from "../../Staff/NoteTaking/DoctorNotes";
import PatientNotes from "../../Staff/NoteTaking/PatientNotes";
import DoctorNotesSelected from "../../Staff/NoteTaking/DoctorNotesSelected";
import PatientNotesSelected from "../../Staff/NoteTaking/PatientNotesSelected";
import PatientList from "../../Staff/NoteTaking/PatientList";
import { useSelector } from "react-redux";
import DotPhrases from "../../Admin/DotPhrases/DotPhrases";
import PatientPricriptionSelect from "../../Admin/PatientPricriptionSelect";
import AddEditDotPhrase from "../../Admin/DotPhrases/AddEditDotPhrase";
import DrawtoolTemplate from "../../Admin/Drawtool/DrawtoolTemplate";
import AddEditDrawtool from "../../Admin/Drawtool/AddEditDrawtool";
import UomInventory from "../../Admin/UomInventory/UomInventory";
import AddEditUom from "../../Admin/UomInventory/AddEditUom";
import AddEditProductCategory from "../../Admin/ProductCategory/AddEditProductCategory";
import ProductCategory from "../../Admin/ProductCategory/ProductCategory";
import CategoryCost from "../../Admin/CategoryCost/CategoryCost";
import AddEditCategoryCost from "../../Admin/CategoryCost/AddEditCategoryCost";
import AddEditAdditionalCost from "../../Admin/AdditionalMarketingCost/AddEditAdditionalCost";
import AdditionalCost from "../../Admin/AdditionalMarketingCost/AdditionalCost";
import TaxSettings from "../../Admin/TaxSettings.js/TaxSettings";
import AddEditTaxSettings from "../../Admin/TaxSettings.js/AddEditTaxSettings";
import Drawtool from "../../Staff/Drawtools/Drawtool";
import AddEditSupplier from "../../Admin/Supplier/AddEditSupplier";
import Supplier from "../../Admin/Supplier/Supplier";
import AddEditIpAccess from "../../Admin/IpAccess/AddEditIpAccess";
import IpAccess from "../../Admin/IpAccess/IpAccess";
import Products from "../../Staff/Inventory/Products/Products";
import Treatments from "../../Staff/Inventory/Treatments/Treatments";
import AddEditTreatments from "../../Staff/Inventory/Treatments/AddEditTreatments";
import VendorContact from "../../Staff/Inventory/Vendor Contact/VendorContact";
import AddEditVendorContact from "../../Staff/Inventory/Vendor Contact/AddEditVendorContact";
import AddEditProducts from "../../Staff/Inventory/Products/AddEditProducts";
import AddEditPackages from "../../Staff/Inventory/Packages/AddEditPackages";
import Packages from "../../Staff/Inventory/Packages/Packages";
import PurchaseList from "../../Staff/Inventory/PurchaseList/PurchaseList";
import AddEditPurchaseList from "../../Staff/Inventory/PurchaseList/AddEditPurchaseList";
import Branch from "../../Admin/Branch/Branch";
import AddEditBranch from "../../Admin/Branch/AddEditBranch";
import AdjustmentStock from "../../Staff/Inventory/AdjustmentStock/AdjustmentStock";
import AddEditAdjustmentStock from "../../Staff/Inventory/AdjustmentStock/AddEditAdjustmentStock";
import AddBulkStock from "../../Staff/Inventory/AdjustmentStock/AddBulkStock";
import ReceivingGoods from "../../Staff/Inventory/ReceivingGoods/ReceivingGoods";
import AddEditReceivingGoods from "../../Staff/Inventory/ReceivingGoods/AddEditReceivingGoods";
import ProductDetails from "../../Staff/Inventory/Products/ProductDetails";
import AdjustmentHistory from "../../Staff/Inventory/Products/AdjustmentHistory";
import ReceivingHistory from "../../Staff/Inventory/Products/ReceivingHistory";
import AddEditTags from "../../Admin/Tags/AddEditTags";
import Tags from "../../Admin/Tags/Tags";
import GeneralSetting from "../../Admin/GeneralSetting";
import CompanySettingForm from "../../Admin/GeneralSetting/companyForm";
import AddEditPromo from "../../Staff/Promo/AddEditPromo";
import Promo from "../../Staff/Promo/Promo";
import AddEditCampaign from "../../Staff/Campaign/AddEditCampaign";
import Campaign from "../../Staff/Campaign/Campaign";
import DataTableTemplateView from "../../Admin/DataTableTemplates/DataTableTemplateView";
import AddEditTemplate from "../../Admin/DataTableTemplates/AddEditTemplate";
import PreviewTemplate from "../../Admin/DataTableTemplates/PreviewTemplate";
import AppointmentsView from "../../Staff/Appointments/AppointmentsView";
import AddEditAppointment from "../../Staff/Appointments/AddEditAppointment";
import AddEditWaitingList from "../../Staff/Appointments/AddEditWaitingList";
import Rostering from "../../Staff/Rostering/Rostering";
import AddReminderTemplate from "../../Admin/Reminder/AddEditReminder";
import ReminderTemplateView from "../../Admin/Reminder/ReminerTemplateView";
import ExternalDrugTemplate from "../../Admin/ExternalDrugTemplate";
import AddEditCategory from "../../Admin/Category/AddEditCategory";
import CategoryReportView from "../../Admin/Category/CategoryReportView";
import AddEditSession from "../../Staff/Session/AddEditSession";
import PatientDetails from "../../Staff/Session/PatientDetails";
import SessionView from "../../Staff/Session/SessionView";
import EndSessionReport from "../../Staff/Session/EndSession";
import AppointmentReminder from "../../Staff/Appointments/AppointmentReminder";
import Schedular from "../../Staff/Rostering/Schedular";
import BillingView from "../../Staff/Billing/BillingView";
import ConversionView from "../../Staff/Billing/ConversionView";
import AddEditBilling from "../../Staff/Billing/AddEditBilling";
import AddPayment from "../../Staff/Billing/AddPayment";
import AddEditRefund from "../../Staff/Refund/AddEditRefund";
import AddEditConversion from "../../Staff/Billing/AddEditConversion";
import Refund from "../../Staff/Refund/Refund";
import DataTableView from "../../Staff/DataTable/DataTableView";
import CustomizedReportsView from "../../Staff/Reports/CustomizedReportsView";
import CategoryReportsView from "../../Staff/CategoryReports/CustomizedReportsView";
import WaitingListView from "../../Staff/Waiting/WaitingListView";
import CreateTemplates from "../../Staff/Mailchimp/Templates";
import SendMail from "../../Staff/Mailchimp/SendMail";
import RoutesWrapper from "./RoutesWrapper";
import KolDetails from "../../Staff/Kol/KolDetails";
import HearAboutUs from "../../Admin/HearAboutUs/AddEditHearAboutUs";
import HearAboutUsView from "../../Admin/HearAboutUs/HearAboutUsView";
import Occupation from "../../Admin/Occupation/AddEditOccupation";
import OccupationView from "../../Admin/Occupation/OccupationView";
import ProgramsView from "../../Admin/Program/ProgramsView";
import AddEditProgram from "../../Admin/Program/AddEditPrograms";
import PaymentMethodView from "../../Admin/PaymentMethod/PaymentMethodView";
import AddEditPaymentMethod from "../../Admin/PaymentMethod/AddEditPaymentMethod";
import AddEditHoliday from "../../Admin/Holiday/AddEditHoliday";
import HolidaysList from "../../Admin/Holiday/HolidaysList";
import AddCategoryReport from "../../Admin/Category/AddEditCategory";
import HumanResource from "../../Staff/HumanResource";
import ClientProfileReports from "../../Staff/Dashboard/ClientProfileReports";
import MarketingReportsPage from "../../Staff/Dashboard/MarketingReportsPage";
import DailyConsolidatedSalesReport from "../../Staff/Dashboard/DailyConsolidatedSalesReport";
import SalesReport from "../../Staff/Dashboard/SalesReport";

function Page({ sidebar }) {
  const userData = useSelector((state) => state.userData.value);
  console.log(userData);

  const renderWhichPage = () => {
    if (userData?.department?.permissions?.length > 0) {
      const firstPermission = userData?.department?.permissions?.[0];
      if (
        firstPermission?.moduleName === "Reception" ||
        firstPermission?.moduleName === "Rostering"
      ) {
        return <BucketList />;
      } else if (firstPermission?.moduleName === "Appointment") {
        return <AppointmentsView />;
      } else if (
        firstPermission?.moduleName === "Patient-Database" ||
        firstPermission?.moduleName === "Patient"
      ) {
        return <PatientList />;
      } else if (firstPermission?.moduleName === "Session") {
        return <SessionView />;
      } else if (firstPermission?.moduleName === "Note-Taking") {
        return <DoctorNotes />;
      } else {
        return "";
        // return <CustomizedReportsView />;
      }
    } else {
      return <BucketList />;
    }
  };

  return (
    <div className={`sidePage ${sidebar ? "openSidebar" : "closeSidebar"}`}>
      <div className={"innerPage"}>
        <RoutesWrapper>
          <Routes>
            <Route
              path="*"
              element={
                userData?.isAdmin === true ? <User /> : renderWhichPage()
              }
            />
            <Route path="/questions/:id" exact element={<Questionnaire />} />
            <Route
              path="/medicalform/:id"
              exact
              element={<HealthDeclaration />}
            />
            <Route path="/staff" exact element={<User />} />
            <Route path="/add-user" exact element={<AddEditUser />} />
            <Route path="/account/:userId" exact element={<AddEditUser />} />
            <Route path="/departments" exact element={<Departments />} />
            <Route
              path="/add-department"
              exact
              element={<AddEditDepartments />}
            />
            <Route path="/forget-password" exact element={<ForgetPassword />} />
            <Route
              path="/questionnaire-settings"
              exact
              element={<QuestionnaireSettings />}
            />
            <Route
              path="/healthDeclaration-settings"
              exact
              element={<HealthDeclarationSetting />}
            />
            <Route path="/patient" exact element={<Patient />} />
            <Route path="/kol" exact element={<KOL />} />
            <Route path="/kol-details/:id" exact element={<KolDetails />} />
            <Route path="/add-kol" exact element={<AddEditKol />} />
            <Route path="/option-kol" exact element={<OptionKol />} />
            <Route
              path="/add-option-kol"
              exact
              element={<AddEditOptionKol />}
            />

            <Route
              path="/membership-setting"
              exact
              element={<MembershipSettings />}
            />
            <Route
              path="/expired-setting"
              exact
              element={<ExpiredSettings />}
            />
            <Route path="/add-patient" exact element={<AddEditPatient />} />
            <Route path="/rostering" exact element={<BucketList />} />
            <Route path="/rostering-view" exact element={<Rostering />} />
            <Route path="/add-question" exact element={<AddEditQuestions />} />
            <Route
              path="/add-health-declaration"
              exact
              element={<AddEditHealthDeclaration />}
            />
            <Route path="/add-consent" exact element={<AddEditConsent />} />
            <Route path="/add-tags" exact element={<AddEditTags />} />
            <Route path="/tags" exact element={<Tags />} />
            <Route path="/status_color" exact element={<GeneralSetting />} />
            <Route
              path="/company_setting"
              exact
              element={<CompanySettingForm />}
            />
            <Route path="/add-refund" exact element={<AddEditRefund />} />
            <Route
              path="/add-conversion"
              exact
              element={<AddEditConversion />}
            />
            <Route path="/refund" exact element={<Refund />} />
            <Route path="/add-campaign" exact element={<AddEditCampaign />} />
            <Route path="/campaign" exact element={<Campaign />} />
            <Route path="/add-promo" exact element={<AddEditPromo />} />
            <Route path="/promo" exact element={<Promo />} />
            <Route path="/patient-list" exact element={<PatientList />} />
            <Route path="/note-taking-doctor" exact element={<DoctorNotes />} />
            {/* <Route
            path="/doctor-notes-selected"
            exact
            element={<DoctorNotesSelected />}
          /> */}
            <Route
              path="/note-taking-patient"
              exact
              element={<PatientNotes />}
            />
            <Route
              path="/doctor-notes"
              exact
              element={<PatientNotesSelected selectedRole={"DOCTOR"} />}
            />
            <Route
              path="/therapist-notes"
              exact
              element={<PatientNotesSelected selectedRole={"THERAPIST"} />}
            />
            <Route
              path="/nutritionist-notes"
              exact
              element={<PatientNotesSelected selectedRole={"NUTRITIONIST"} />}
            />
            <Route
              path="/patient-notes"
              exact
              element={
                <PatientNotesSelected selectedRole={"Patient-Database"} />
              }
            />
            <Route
              path="/consent-settings"
              exact
              element={<ConsentSettings />}
            />
            <Route path="/dot-phrases" exact element={<DotPhrases />} />
            <Route
              path="/patient-prescription-select-data"
              exact
              element={<PatientPricriptionSelect />}
            />
            <Route
              path="/add-dot-phrase"
              exact
              element={<AddEditDotPhrase />}
            />
            <Route path="/tax-setting" exact element={<TaxSettings />} />
            <Route
              path="/add-tax-setting"
              exact
              element={<AddEditTaxSettings />}
            />
            <Route
              path="/adjustment-history"
              exact
              element={<AdjustmentHistory />}
            />
            <Route
              path="/receiving-history"
              exact
              element={<ReceivingHistory />}
            />
            <Route path="/product-details" exact element={<ProductDetails />} />
            <Route path="/supplier" exact element={<Supplier />} />
            <Route path="/add-supplier" exact element={<AddEditSupplier />} />
            <Route path="/ipAccess" exact element={<IpAccess />} />
            <Route path="/add-ipAccess" exact element={<AddEditIpAccess />} />
            <Route path="/drawtool" exact element={<DrawtoolTemplate />} />
            <Route path="/add-draw-tool" exact element={<AddEditDrawtool />} />
            <Route path="/draw-tool" exact element={<Drawtool />} />
            <Route
              path="/additional-marketing-cost"
              exact
              element={<AdditionalCost />}
            />
            <Route
              path="/add-additional-marketing-cost"
              exact
              element={<AddEditAdditionalCost />}
            />
            <Route path="/uom-inventory" exact element={<UomInventory />} />
            <Route path="/add-uom-inventory" exact element={<AddEditUom />} />
            <Route path="/receiving-goods" exact element={<ReceivingGoods />} />
            <Route
              path="/add-receiving-goods"
              exact
              element={<AddEditReceivingGoods />}
            />
            <Route path="/packages" exact element={<Packages />} />
            <Route path="/add-packages" exact element={<AddEditPackages />} />
            <Route
              path="/adjustment-stock"
              exact
              element={<AdjustmentStock />}
            />
            <Route
              path="/add-adjustment-stock"
              exact
              element={<AddEditAdjustmentStock />}
            />
            <Route
              path="/bulk-adjustment-stock"
              exact
              element={<AddBulkStock />}
            />
            <Route path="/branch" exact element={<Branch />} />
            <Route path="/add-location" exact element={<AddEditBranch />} />
            <Route path="/purchase-list" exact element={<PurchaseList />} />
            <Route
              path="/add-purchase-list"
              exact
              element={<AddEditPurchaseList />}
            />
            <Route path="/inventory-products" exact element={<Products />} />
            <Route
              path="/add-inventory-products"
              exact
              element={<AddEditProducts />}
            />
            <Route
              path="/inventory-treatments"
              exact
              element={<Treatments />}
            />
            <Route
              path="/add-inventory-treatments"
              exact
              element={<AddEditTreatments />}
            />
            <Route path="/vendor-contact" exact element={<VendorContact />} />
            <Route
              path="/add-vendor-contact"
              exact
              element={<AddEditVendorContact />}
            />
            <Route path="/category-cost" exact element={<CategoryCost />} />
            <Route
              path="/add-category-cost"
              exact
              element={<AddEditCategoryCost />}
            />
            <Route
              path="/product-category"
              exact
              element={<ProductCategory />}
            />
            <Route
              path="/add-product-category"
              exact
              element={<AddEditProductCategory />}
            />
            <Route
              path="/data-table-template"
              element={<DataTableTemplateView />}
            />
            <Route path="/add-template" element={<AddEditTemplate />} />
            <Route path="/add-template/:id" element={<AddEditTemplate />} />
            <Route
              path="/preview-template/:id"
              exact
              element={<PreviewTemplate />}
            />
            <Route
              path="/preview-template"
              exact
              element={<PreviewTemplate />}
            />

            <Route path="/appointments" element={<AppointmentsView />} />
            <Route path="/add-appointment" element={<AddEditAppointment />} />
            <Route
              path="/appointment-reminder"
              element={<AppointmentReminder />}
            />
            <Route path="/add-waiting" element={<AddEditWaitingList />} />
            <Route
              path="/add-reminder-template"
              element={<AddReminderTemplate />}
            />
            <Route
              path="/reminder-template"
              element={<ReminderTemplateView />}
            />
            <Route
              path="/external-drug-template"
              element={<ExternalDrugTemplate />}
            />
            <Route path="/hr" element={<HumanResource />} />

            <Route path="/add-category-report" element={<AddEditCategory />} />
            <Route path="/category-report" element={<CategoryReportView />} />
            <Route path="/add-session" element={<AddEditSession />} />
            <Route path="/patient-details" element={<PatientDetails />} />
            <Route path="/session" element={<SessionView />} />
            <Route path="/end-session-report" element={<EndSessionReport />} />
            <Route path="/billing" element={<BillingView />} />
            <Route path="/conversion" element={<ConversionView />} />
            <Route path="/add-billing" element={<AddEditBilling />} />
            <Route path="/add-payment" element={<AddPayment />} />
            <Route path="/data-table" element={<DataTableView />} />
            <Route
              path="/customized-reports"
              element={<CustomizedReportsView />}
            />
            <Route
              path="/client-profile-reports"
              element={<ClientProfileReports />}
            />
            <Route
              path="/marketing-reports"
              element={<MarketingReportsPage />}
            />
            <Route
              path="/daily-consolidate-sales-reports"
              element={<DailyConsolidatedSalesReport />}
            />
            <Route path="/sales-reports" element={<SalesReport />} />

            <Route path="/waiting-list" element={<WaitingListView />} />

            <Route path="/create-templates" element={<CreateTemplates />} />
            <Route path="/send-mail" element={<SendMail />} />
            <Route path="/hear-about-us" element={<HearAboutUsView />} />
            <Route path="/add-hear-about-us" element={<HearAboutUs />} />
            <Route path="/occupation" element={<OccupationView />} />
            <Route path="/add-occupation" element={<Occupation />} />
            <Route path="/program" element={<ProgramsView />} />
            <Route path="/add-program/:id" exact element={<AddEditProgram />} />
            <Route path="/add-program" exact element={<AddEditProgram />} />
            <Route path="/payment-method" element={<PaymentMethodView />} />
            <Route
              path="/add-payment-method/:id"
              exact
              element={<AddEditPaymentMethod />}
            />
            <Route
              path="/add-payment-method"
              exact
              element={<AddEditPaymentMethod />}
            />
            <Route path="/add-holiday/:id" exact element={<AddEditHoliday />} />
            <Route path="/add-holiday" exact element={<AddEditHoliday />} />
            <Route path="/holidays" exact element={<HolidaysList />} />
            <Route
              path="/category-report-view/:id"
              exact
              element={<CategoryReportsView />}
            />
          </Routes>
        </RoutesWrapper>
      </div>
    </div>
  );
}

export default Page;
