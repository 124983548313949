import React, { useEffect, useState } from "react";

function PermissionsToggle(props) {
  const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened((isOpened) => !isOpened);
  }

  const toggleSelected = () => {
    if (
      props?.permissions?.find((e) => e.moduleName === props.data.moduleName)
    ) {
      setIsOpened(true);
    }
  };

  const editParentPermission = (bit) => {
    let permissions = [...props.permissions];

    let index = permissions.findIndex(
      (e) => e.moduleName === props.data.moduleName
    );

    let _data = props.permissions.find(
      (e) => e.moduleName === props.data.moduleName
    );

    if (index !== -1) {
      if (bit) {
        _data["permissionsList"] = props.data.permissionsList;
        permissions[index] = _data;
        props.setPermissions(permissions);
      } else {
        let _arr = props.permissions.filter(
          (e) => e.moduleName !== _data.moduleName
        );
        props.setPermissions(_arr);
      }
    } else {
      let _new = props.allPermissions.find(
        (e) => e.moduleName === props.data.moduleName
      );
      permissions.push(_new);
      props.setPermissions(permissions);
    }
  };

  const editPermission = (e, perm) => {
    let permissions = [...props.permissions];
    if (e.target.checked === true) {
      permissions.push({
        moduleName: props?.data?.moduleName,
        permissionsList: permissions?.permissionsList
          ? permissions?.permissionsList?.push(perm)
          : [perm],
      });
      props.setPermissions(permissions);
    } else {
      for (let i = 0; i < permissions.length; i++) {
        let _str = JSON.stringify(permissions[i]);
        if (_str.includes(props.data.moduleName) && _str.includes(perm)) {
          permissions.splice(i, 1);
        }
      }
      props.setPermissions(permissions);
    }
  };

  const checkAvailable = (perm) => {
    let _permissions = [...props.permissions];
    var responseBit = false;

    _permissions.forEach((e) => {
      if (
        e.moduleName === props.data.moduleName &&
        e.permissionsList.includes(perm)
      ) {
        responseBit = true;
      }
    });
    return responseBit;
  };

  const checkParentAvailable = () => {
    let ind = props.permissions.findIndex(
      (e) => e.moduleName === props.data.moduleName
    );
    if (ind > -1 && props?.permissions[ind]?.permissionsList?.length > 3) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    toggleSelected();
  }, [props]);

  return (
    <div className="box2">
      <div className="d-flex justify-content-center flex-column">
        <div className="d-flex align-items-center">
          <div
            className={isOpened ? "boxTitleColor" : "boxTitle"}
            onClick={toggle}
          >
            <span></span>
          </div>
          <div className="checkBox">
            <div className="form-check">
              {isOpened && (
                <input
                  className="form-check-input ms-1"
                  type="checkbox"
                  id="flexCheckDefault"
                  onChange={(e) => editParentPermission(e.target.checked)}
                  // checked={checkParentAvailable}
                />
              )}
              <label className="form-check-label m-0" for="flexCheckDefault">
                {props?.data?.moduleName}
              </label>
            </div>
          </div>
        </div>
        {isOpened &&
          props?.data?.permissionsList?.map((perm) => (
            <div className="boxContent ms-5">
              <div className="checkBoxChild">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    // defaultChecked={checkAvailable(perm)}
                    checked={checkAvailable(perm)}
                    onChange={(e) => editPermission(e, perm)}
                    id="flexCheckDefault"
                  />
                  <label
                    className="form-check-label m-0"
                    for="flexCheckDefault"
                  >
                    {perm}
                  </label>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
export default PermissionsToggle;
