import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { addDepartment } from "../../../apis/departments";
import { departmentDetails, updateDepartment } from "../../../apis/departments";
import PermissionsToggle from "./PermissionsToggle";
import { getStaff } from "../../../apis/staff";
import { toastify } from "../../../helperFunctions/toastify";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function AddEditDepartments() {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState();
  const [permissions, setPermissions] = useState([
    {
      moduleName: "Reception",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Patient-Database",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Note-Taking",
      permissionsList: ["create", "view", "delete", "update"],
    },

    {
      moduleName: "Finance",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Inventory",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Promo",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "KOL/Channel",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Human-Resource",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Reports",
      permissionsList: ["create", "view", "delete", "update"],
    },
    {
      moduleName: "Dashboards",
      permissionsList: ["create", "view", "delete", "update"],
    },

    // {
    //   moduleName: "Questionnaire",
    //   permissionsList: ["create", "view"],
    // },

    // {
    //   moduleName: "Appointment",
    //   permissionsList: ["create", "view", "delete", "update"],
    // },
    // {
    // 	moduleName: "Waiting List",
    // 	permissionsList: ["create", "view", "delete", "update"],
    // },
    // {
    //   moduleName: "Session",
    //   permissionsList: ["create", "view", "delete", "update"],
    // },

    // {
    // 	moduleName: "Campaign",
    // 	permissionsList: ["create", "view", "delete", "update"],
    // },

    // {
    // 	moduleName: "Refund",
    // 	permissionsList: ["create", "view", "delete", "update"],
    // },

    // {
    // 	moduleName: "Mail Chimp",
    // 	permissionsList: ["create", "view", "delete", "update"],
    // },
    // {
    //   moduleName: "Settings",
    //   permissionsList: ["create", "view", "delete", "update"],
    // },
  ]);

  const editDepartment = async () => {
    if (!name) {
      missingPopup("Name");
    } else if (!selectedPermissions.length > 0) {
      missingPopup("Permission");
    } else {
      let combinedPermissions = combinePermissions();

      let apiData = { name, description, manager: selectedStaff };
      const response = await updateDepartment(
        apiData,
        combinedPermissions,
        state.id
      );
      if (response.status !== "Fail") {
        toastify("Department updated successfully!", "success");
        navigate("/departments");
      } else {
        toastify(response?.message, "error");
      }
    }
  };

  const getDepartmentData = async () => {
    const response = await departmentDetails(state.id);
    if (response.status !== "Fail") {
      setName(response.name);
      setDescription(response.description);
      setSelectedPermissions(response.permissions);

      let _staff = await getStaff();

      // _staff.forEach(function (item, i) {
      // 	if (item._id === response.manager) {
      // 		_staff.splice(i, 1);
      // 		_staff.unshift(item);
      // 	}
      // });

      setStaff(_staff);
      setSelectedStaff(response.manager);
      if (response.manager) {
        // setSelectedStaff(_staff[0] || null);
      }
    }
  };

  const populateStaff = async () => {
    const res = await getStaff();
    if (res.status !== "Fail") {
      setStaff(res);
    }
  };

  useEffect(() => {
    populateStaff();
    if (state?.id) getDepartmentData();
  }, []);

  const combinePermissions = () => {
    let combinedPermissions = [];
    selectedPermissions.map((e) => {
      let check = combinedPermissions.findIndex(
        (dat) => dat.moduleName === e.moduleName
      );
      if (check != -1) {
        let dt = combinedPermissions[check].permissionsList;
        if (isArrayCheck(dt)) {
          dt.push(e.permissionsList[0]);
        }
        combinedPermissions[check] = {
          moduleName: e.moduleName,
          permissionsList: dt,
        };
      } else {
        combinedPermissions.push(e);
      }
    });
    return combinedPermissions;
  };

  const saveDepartment = async () => {
    if (!name) {
      missingPopup("Name");
    } else if (!selectedPermissions.length > 0) {
      missingPopup("Permission");
    } else {
      let combinedPermissions = combinePermissions();
      let apiData = {
        name,
        description,
        manager: selectedStaff,
      };
      const res = await addDepartment(apiData, combinedPermissions);
      if (res?.status !== "Fail") {
        toastify("Department updated successfully!", "success");
        navigate("/departments");
      } else {
        toastify(res?.message, "error");
      }
    }
  };

  const isArrayCheck = (arr) => {
    return Array.isArray(arr) && arr.length > 0;
  };

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.id ? "Edit " : "New "}`}
          Department
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="headings2">Designation *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2">Name</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={selectedStaff}
                    options={[
                      ...staff?.map((o) => {
                        return {
                          value: o._id,
                          label: o.username,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setSelectedStaff(e.value);
                    }}
                  />
                  {/* <select
										className="form-select mb-3"
										onChange={(e) => {
											setSelectedStaff(e.target.value);
										}}
									>
										{!selectedStaff && (
											<option defaultChecked>
												Select
											</option>
										)}
										{staff?.map((e) => (
											<option value={e._id}>
												{e.username}
											</option>
										))}
									</select> */}
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      style={{ resize: "none" }}
                      placeholder="Write..."
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>
          <div className="grayBack">
            <Form.Group>
              <Form.Label className="grayLabel">Permissions *</Form.Label>
              {permissions?.map((e) => (
                <PermissionsToggle
                  data={e}
                  setPermissions={setSelectedPermissions}
                  permissions={selectedPermissions}
                  allPermissions={permissions}
                />
              ))}
            </Form.Group>
          </div>
          <div className="addEdit">
            <Button
              className="addItem"
              onClick={() => {
                if (state?.id) editDepartment();
                else saveDepartment();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
