import React, { useState, useEffect, useMemo } from "react";
import {
  Accordion,
  Button,
  Form,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  resetValue,
  setData,
  setForm,
} from "../../../redux/slices/StaffFormsSlices";
import { useLocation, useNavigate } from "react-router-dom";
import { getBillingByPatient } from "../../../apis/billing";
import { getSessionByPatientId, getSessionById } from "../../../apis/session";
import { getMembershipCategories } from "../../../apis/membership";
import { getPatientNotes, updateNote } from "../../../apis/notes";
import { authorizedRequests } from "../../../axios/axios-config";
import { getPatientDetailsById } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import DeleteModal from "../../SharedComponents/DeleteModal";
import NoteRenderer from "../NoteTaking/NoteRenderer";
import EditIcon from "@mui/icons-material/Edit";
import { SearchIcon } from "../../../assets/images/index";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
} from "@react-pdf/renderer";

import { thousandAndDecimalFormat } from "../../../utils/common";

function GlanceView(props) {
  const [notes, setNotes] = useState([]);

  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const { state } = props;
  const userData = useSelector((state) => state.userData.value);
  const [date, setDate] = useState({
    start: "",
    end: "",
  });
  const [checkAllDate, setCheckAllDate] = useState(true);
  const [usePointVal, setUsePointVal] = useState(0);
  // const patientData = useSelector((state) => state.patientData.value);
  const [patientHistory, setPatientHistory] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [staffSearch, setStaffSearch] = useState("");

  const navigate = useNavigate();

  const getNotesList = async () => {
    const response = await getPatientNotes(state?.id);
    if (response.status !== "Fail") {
      setNotes(response);
    } else if (response?.message === "No data Found") {
      setNotes([]);
    } else {
      toastify(response?.message, "error");
    }
  };

  // let response = await getPatientDetailsById(patientData?._id);
  //   if (response?.status !== "Fail") {
  //     setNotes(
  //       response?.nurseNotesList?.reverse()?.filter((e) => !e.isDeleted)
  //     );
  //   } else {
  //     toastify(response?.message, "error");
  //   }

  useEffect(() => {
    getNotesList();
  }, []);

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const checkDate = (noteDate) => {
    let day1 = new Date().getDate();
    let day2 = new Date(noteDate).getDate();

    let month1 = new Date().getMonth();
    let month2 = new Date(noteDate).getMonth();

    let year1 = new Date().getFullYear();
    let year2 = new Date(noteDate).getFullYear();

    if (day1 === day2 && month1 === month2 && year1 === year2) return true;
    else return false;
  };

  const removeNote = async () => {
    let isSameDay = false;

    let ind = notes.find((e) => {
      if (deleting === e._id) {
        if (checkDate(e.created_at)) isSameDay = true;
        return e;
      }
    });

    setShow(false);

    if (ind?.staff?._id === userData._id) {
      if (isSameDay) {
        const response = await updateNote(deleting, {
          isDeleted: true,
        });

        if (response?.status !== "Fail") {
          toastify("Note deleted successfully!", "success");
          getNotesList();
        } else {
          toastify(response?.message, "error");
        }
      } else {
        toastify("Note can be deleted on the same day only.", "error");
      }
    } else {
      toastify("Only note maker can update/delete note.", "error");
    }
  };

  useEffect(() => {
    (async () => {
      const { result, error } = await getSessionByPatientId(state?.id);
      if (error) {
        toastify(error.message, "error");
        return;
      }

      const tempArr = result?.data?.map((e) => {
        const historyObj = {
          medicalHistory: e.patientMedicalHistory?.pastMedicalHistory,
          cosmeticHistory: e.patientMedicalHistory?.pastCosmeticHistory,
          adverseCosmeticHistory:
            e.patientMedicalHistory?.pastAdverseCosmeticHistory,
          socialHistory: e.patientMedicalHistory?.socialHistory,
        };
        const loc = e?.branch?.location?.find((o) => o._id === e?.location);

        let totalAmount = 0;
        const prescriptionDrugs = e?.patientPrescriptionSheet?.map((o) => {
          totalAmount +=
            o.type === "internal" ? o.quantity * o.unitPrice - o.discount : 0;

          return {
            name: o.drugName,
            price: o.unitPrice ? o.unitPrice : 0,
            qty: o.quantity,
            discount: o.discount ? o.discount : 0,
            total_price:
              o.type === "internal" ? o.quantity * o.unitPrice - o.discount : 0,
            instruction: o.instruction,
            totalDosage: o.totalDosage,
            dosage: o.dosage,
            period: o.period,
            periodDescription: o.periodDescription,
            takePeriod: o.takePeriod,
            takeType: o.takeType,
            type: o.type,
          };
        });

        const taxVal = e.prescriptionGSTAmount;
        const notesObj = {
          startTime: e.startTime,
          endTime: e.endTime,
          staff: e.staff?.map((o) => o?.username),
          soap: e.patientSOAP,
          location: loc,
          // service: [...packages, ...product, ...treatment, ...others],
          service: [...prescriptionDrugs],
          // remarks: e?.remarks,
          // usePoints: e?.usePoints,
          // promo: e?.promo,
          // tax: e?.tax,
          // promoDiscount: (e?.promo / 100) * e?.actualAmount,
          totalAmount: totalAmount,
          afterTaxValue: taxVal,
          // actualAmount: e?.actualAmount,
          // totalAmount: e?.totalAmount,
        };
        return {
          _id: e._id,
          date: new Date(e.date).toLocaleDateString(),
          history: historyObj,
          patientMedicalHistory: e.patientMedicalHistory,
          notes: notesObj,
        };
      });

      setPatientData(JSON.parse(JSON.stringify([...tempArr.reverse()])));
      setPatientHistory(JSON.parse(JSON.stringify([...tempArr])));
    })();
    (async () => {
      const res = await getMembershipCategories();
      if (res?.status !== "Fail") {
        setUsePointVal(0);
        return;
      }
      setUsePointVal(res?.point);
    })();
  }, [state?.id]);
  useMemo(() => {
    if (date?.start && date?.end) {
      setCheckAllDate(false);
      let tempArr = [...patientData];
      tempArr = tempArr.filter((e) => {
        if (
          new Date(e?.date) >= date?.start &&
          new Date(e?.date) <= date?.end
        ) {
          return true;
        }
      });
      if (tempArr.length === 0) {
        toastify("No result found for applied search", "error");
      }

      setFilteredData([...tempArr]);
    }
  }, [date?.start, date?.end]);

  const dispatch = useDispatch();

  const handleSessionDetail = async (session) => {
    console.log("DEBUG", session);
    const { result, error } = await getSessionById(session?._id);
    const valueObj = {
      dataId: result?.data?._id,
      appointment: result?.data?.appointment,
      branch: result?.data?.branch,
      date: result?.data?.date,
      location: result?.data?.location,
      room: result?.data?.room,
      consents: result?.data?.consents,
      staff: result?.data?.staff,
      status: result?.data?.status,
      startTime: result?.data?.startTime,
      endTime: result?.data?.endTime,
      formStatus: "Edit",
      patientID: state?.id,
      patientMedicalHistory: {
        ...result?.data?.patientMedicalHistory,
      },
      patientSOAP: {
        ...result?.data?.patientSOAP,
      },
      patientConsents: [...result?.data?.patientConsents],
      patientChart: [...result?.data?.patientChart],
      patientPrescriptionSheet: [...result?.data?.patientPrescriptionSheet],
      photoSession: result?.data?.photoSession,
    };

    dispatch(
      setData({
        parent: "sessionForm",
        value: valueObj,
      })
    );
    // dispatch(setPatientData(tempArr?.appointment?.patient));
    navigate("/patient-details", {
      state: { valueObj },
    });
  };

  const onChangeMedicalHistory = (index, type, value) => {
    setPatientHistory((prev) => {
      const prevHistory = [...prev];
      prevHistory[index].history[type] = value;

      return prevHistory;
    });
  };

  const onSaveNote = async (index) => {
    const savedData = patientHistory[index];
    const { result, error } = await authorizedRequests({
      url: "session/updateSession",
      method: "post",
      data: {
        dataId: savedData._id,
        patientMedicalHistory: {
          ...savedData.patientMedicalHistory,
          pastMedicalHistory: savedData.history?.medicalHistory,
          pastCosmeticHistory: savedData.history?.cosmeticHistory,
          pastAdverseCosmeticHistory: savedData.history?.adverseCosmeticHistory,
          socialHistory: savedData.history?.socialHistory,
        },
      },
    });

    if (error) {
      toastify(error?.message, "error");
      return;
    }

    toastify("Success", "success");
  };

  return (
    <div style={{height: "1070px"}}>
      <div className="d-flex">
        <div className=" col-md-4 me-4  grey-bg accordion-patient" style={{height: "1100px", overflowY: "auto"}}>
          <div className="bold patient-history white-background mt-0 py-2">
            Patient History
          </div>
          <div
            className="mb-3 white-background"
          >
            <div
              className="history mt-0 py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
                alignItems: "center",
              }}
            >
              <span>Medical History</span>
            </div>

            {patientHistory?.length > 0 &&
              patientHistory?.map(
                (e, i) => {
                  if (!e.history?.medicalHistory) {
                    return <></>;
                  }
                  return (
                    <>
                      <Accordion defaultActiveKey={"0"}>
                        <Accordion.Item
                          eventKey="0"
                          style={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Accordion.Header>
                            <div className="bold font-14">{e.date}</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                <div>
                                  <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start mb-2 border p-2 rounded"
                                  >
                                    <div className="ms-2 me-auto cursor-pointer">
                                      <div className="fw-bold">
                                        {e.history?.medicalHistory}
                                      </div>
                                    </div>
                                    <EditIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleSessionDetail(e)}
                                    />
                                  </ListGroup.Item>
                                  {/* <InputGroup className="mb-3">
                                    <Form.Control
                                      name={`medicalHistory${i}`}
                                      as="textarea"
                                      rows={2}
                                      placeholder="Write..."
                                      style={{ resize: "none" }}
                                      onChange={(e) => {
                                        onChangeMedicalHistory(
                                          i,
                                          "medicalHistory",
                                          e.target.value
                                        );
                                      }}
                                      value={e.history?.medicalHistory}
                                    />
                                  </InputGroup> */}
                                </div>
                              </div>

                              {/* <div className="mb-2" style={{float: "right"}}>
                                <Button
                                  className="addItem ms-2"
                                  onClick={() => onSaveNote(i)}
                                  style={{ height: "35px", width: "100px" }}
                                >
                                  Save
                                </Button>
                              </div> */}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                }
              )}
          </div>
          <div
            className="mb-3 white-background"
          >
            <div
              className="history py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
                alignItems: "center",
              }}
            >
              <span>Cosmetic History</span>
              {/* <i
                class="fa fa-plus-square"
                style={{ color: "#34556F", cursor: "pointer" }}
                aria-hidden="true"
              ></i> */}
            </div>

            {patientHistory?.length > 0 &&
              patientHistory?.map(
                (e, i) => {
                  if (!e.history?.cosmeticHistory) {
                    return <></>;
                  }
                  return (
                    <>
                      <Accordion defaultActiveKey={"0"}>
                        <Accordion.Item
                          eventKey="0"
                          style={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Accordion.Header>
                            <div className="bold font-14">{e.date}</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                <div>
                                  <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start mb-2 border p-2 rounded"
                                  >
                                    <div className="ms-2 me-auto cursor-pointer">
                                      <div className="fw-bold">
                                        {e.history?.cosmeticHistory}
                                      </div>
                                    </div>
                                    <EditIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleSessionDetail(e)}
                                    />
                                  </ListGroup.Item>
                                  {/* <InputGroup className="mb-3">
                                    <Form.Control
                                      name={`cosmeticHistory${i}`}
                                      as="textarea"
                                      rows={2}
                                      placeholder="Write..."
                                      style={{ resize: "none" }}
                                      onChange={(e) => {
                                        onChangeMedicalHistory(
                                          i,
                                          "cosmeticHistory",
                                          e.target.value
                                        );
                                      }}
                                      value={e.history?.cosmeticHistory}
                                    />
                                  </InputGroup> */}
                                </div>
                              </div>

                              {/* <div className="mb-2" style={{ float: "right" }}>
                                <Button
                                  className="addItem ms-2"
                                  onClick={() => onSaveNote(i)}
                                  style={{ height: "35px", width: "100px" }}
                                >
                                  Save
                                </Button>
                              </div> */}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                }
              )}
          </div>
          <div
            className="mb-3 white-background"
          >
            <div
              className="history py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
                alignItems: "center",
              }}
            >
              <span>Adverse Cosmetic History</span>
              {/* <i
                class="fa fa-plus-square"
                style={{ color: "#34556F", cursor: "pointer" }}
                aria-hidden="true"
              ></i> */}
            </div>

            {patientHistory?.length > 0 &&
              patientHistory?.map(
                (e, i) => {
                  if (!e.history?.adverseCosmeticHistory) {
                    return <></>;
                  }
                  return (
                    <>
                      <Accordion defaultActiveKey={"0"}>
                        <Accordion.Item
                          eventKey="0"
                          style={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Accordion.Header>
                            <div className="bold font-14">{e.date}</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                <div>
                                  <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start mb-2 border p-2 rounded"
                                  >
                                    <div className="ms-2 me-auto cursor-pointer">
                                      <div className="fw-bold">
                                        {e.history?.adverseCosmeticHistory}
                                      </div>
                                    </div>
                                    <EditIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleSessionDetail(e)}
                                    />
                                  </ListGroup.Item>
                                  {/* <InputGroup className="mb-3">
                                    <Form.Control
                                      name={`adverseCosmeticHistory${i}`}
                                      as="textarea"
                                      rows={2}
                                      placeholder="Write..."
                                      style={{ resize: "none" }}
                                      onChange={(e) => {
                                        onChangeMedicalHistory(
                                          i,
                                          "adverseCosmeticHistory",
                                          e.target.value
                                        );
                                      }}
                                      value={e.history?.adverseCosmeticHistory}
                                    />
                                  </InputGroup> */}
                                </div>
                              </div>

                              {/* <div className="mb-2" style={{ float: "right" }}>
                                <Button
                                  className="addItem ms-2"
                                  onClick={() => onSaveNote(i)}
                                  style={{ height: "35px", width: "100px" }}
                                >
                                  Save
                                </Button>
                              </div> */}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                }
              )}
          </div>
          <div
            className="mb-3 white-background"
          >
            <div
              className="history py-2"
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginRight: "20px",
                alignItems: "center",
              }}
            >
              <span>Social History</span>
              {/* <i
                class="fa fa-plus-square"
                style={{ color: "#34556F", cursor: "pointer" }}
                aria-hidden="true"
              ></i> */}
            </div>

            {patientHistory?.length > 0 &&
              patientHistory?.map(
                (e, i) => {
                  if (!e.history?.socialHistory) {
                    return <></>;
                  }
                  return (
                    <>
                      <Accordion defaultActiveKey={"0"}>
                        <Accordion.Item
                          eventKey="0"
                          style={{
                            border: "none",
                            outline: "none",
                          }}
                        >
                          <Accordion.Header>
                            <div className="bold font-14">{e.date}</div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div>
                              <div>
                                <div>
                                  <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start mb-2 border p-2 rounded"
                                  >
                                    <div className="ms-2 me-auto cursor-pointer">
                                      <div className="fw-bold">
                                        {e.history?.socialHistory}
                                      </div>
                                    </div>
                                    <EditIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => handleSessionDetail(e)}
                                    />
                                  </ListGroup.Item>
                                  {/* <InputGroup className="mb-3">
                                    <Form.Control
                                      name={`socialHistory${i}`}
                                      as="textarea"
                                      rows={2}
                                      placeholder="Write..."
                                      style={{ resize: "none" }}
                                      onChange={(e) => {
                                        onChangeMedicalHistory(
                                          i,
                                          "socialHistory",
                                          e.target.value
                                        );
                                      }}
                                      value={e.history?.socialHistory}
                                    />
                                  </InputGroup> */}
                                </div>
                              </div>

                              {/* <div className="mb-2" style={{ float: "right" }}>
                                <Button
                                  className="addItem ms-2"
                                  onClick={() => onSaveNote(i)}
                                  style={{ height: "35px", width: "100px" }}
                                >
                                  Save
                                </Button>
                              </div> */}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </>
                  );
                }
              )}
          </div>
        </div>
        <div className="white-background p-3 px-0 w-100" style={{height: "1100px", overflowY: "auto"}}>
          <div
            className="date-container d-flex"
            style={{ alignItems: "center" }}
          >
            <div
              className="d-flex"
              style={{
                width: "calc(100% - 130px)",
                minWidth: "330px",
              }}
            >
              <DateInput
                label={"Visit Date:"}
                value={date.start}
                placeholder="Start Date"
                onChange={(e) => {
                  setDate((val) => {
                    return { ...val, start: e };
                  });
                }}
              />

              <DateInput
                label={"To:"}
                placeholder="End Date"
                className="ms-2"
                value={date.end}
                onChange={(e) => {
                  if (!date?.start) {
                    toastify("Please select start date", "error");
                    return;
                  }
                  if (e < date?.start) {
                    toastify("End date can't be less than start date", "error");
                    return;
                  }
                  setDate((val) => {
                    return { ...val, end: e };
                  });
                }}
              />
            </div>
            {/* <PDFDownloadLink
							document={<MyDoc />}
							fileName="somename.pdf"
						>
							{({ blob, url, loading, error }) => {
								return loading
									? "Loading document..."
									: "Download now!";
							}}
						</PDFDownloadLink> */}
            {/* <div
							className="align-self-end ms-2"
							style={{ marginBottom: "13px" }}
						>
							<Form.Check
								type={"checkbox"}
								label={"All Date"}
								size={"lg"}
								checked={checkAllDate}
								onChange={() => {
									if (!checkAllDate) {
										setFilteredData([...patientData]);
									}
									setCheckAllDate(!checkAllDate);
								}}
							/>
						</div> */}
            <Button
              className="addItem ms-2"
              onClick={() => setCheckAllDate(!checkAllDate)}
              style={{ height: "45px", width: "130px", marginTop: "20px" }}
            >
              Search
            </Button>
          </div>
          {/* <div className="d-flex mb-3">
						<Form.Group
							className="position-relative"
							style={{
								width: "calc(50% - 67px)",
								minWidth: "175px",
							}}
						>
							<InputGroup
								className="p-0 search-group"
								style={{ borderRadius: "8px" }}
							>
								<Form.Control
									placeholder="All Selected"
									value={staffSearch}
									onChange={(e) => {
										if (e.target.value) {
											let tempArr = patientData?.filter(
												(o) => {
													if (
														o?.notes?.staff?.find(
															(k) =>
																k
																	.toLowerCase()
																	.includes(
																		e.target.value.toLowerCase(),
																	),
														)
													) {
														return true;
													}
												},
											);
											setFilteredData([...tempArr]);
										}
										setStaffSearch(e.target.value);
									}}
								/>
								<Button style={{ borderRadius: 0 }}>
									<img src={SearchIcon} />
								</Button>
							</InputGroup>
						</Form.Group>
					</div> */}
          {patientData?.length > 0 &&
            (filteredData?.length > 0 ? filteredData : patientData)
              // ?.reverse()
              ?.map((o) => {
                return (
                  <>
                    <div className="mb-3">
                      <NoteRenderer
                        role={"Doctor"}
                        notes_data={o.notes}
                        date={o.date}
                        pointVal={usePointVal}
                        sessionId={o._id}
                        handleSessionDetail={handleSessionDetail}
                      />
                    </div>
                  </>
                );
              })}

          {
            notes && (
              notes?.map((e) => (
                <NoteRenderer
                  note={e}
                  role={"Nurse"}
                  handleClose={handleClose}
                  show={show}
                  setShow={setShow}
                  setDeleting={setDeleting}
                  // editNote={editNote}
                />
              ))
            )
          }
          <DeleteModal
            content="Confirm Patient Note Deletion?"
            onYes={removeNote}
            onNo={handleClose}
            show={show}
          />
        </div>
      </div>
    </div>
  );
}

export default GlanceView;
