import api from "../axios/axios-config";

export const get = async () => {
  try {
    let response = await api.get("prescriptionTemplate/list");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const craete = async (data) => {
  try {
    let response = await api.post("prescriptionTemplate/create", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const update = async (dataId, data) => {
  try {
    let response = await api.post("prescriptionTemplate/update", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteData = async (data) => {
  try {
    let response = await api.post("prescriptionTemplate/deleteData", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
