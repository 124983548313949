import React, { useEffect, useState, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createProducts, updateProducts } from "../../../../apis/products";
import { missingPopup } from "../../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../../helperFunctions/toastify";
import { getSuppliers } from "../../../../apis/supplier";
import { getProductCategory } from "../../../../apis/productCategory";
import { getUOM } from "../../../../apis/uom";
import { getBranches } from "../../../../apis/branch";
import DateInput from "../../../SharedComponents/DateInput";
import SelectComponent from "../../../SharedComponents/SelectComponent";
import { setFormData } from "../../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../../apis/appointment";

export default function AddEditProducts() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [qty, setQty] = useState("");
  const [description, setDescription] = useState("");
  const [unitSellingPrice, setUnitSellingPrice] = useState("");
  const [totalSellingPrice, setTotalSellingPrice] = useState("");
  const [averageUnitCost, setAverageUnitCost] = useState("");
  const [type, setType] = useState("");
  const [supplier, setSupplier] = useState();
  const [totalCost, setTotalCost] = useState("");
  const [uom, setUom] = useState("");
  const [allUoms, setAllUoms] = useState([]);
  const [allTypes, setAllTypes] = useState([]);
  const [allSuppliers, setAllSuppliers] = useState([]);
  const [isIncludeMembership, setIncludeMembership] = useState(false);
  const [batchNo, setBatchNo] = useState("");
  const [branch, setBranch] = useState();
  const [location, setLocation] = useState();
  const [allBranches, setAllBranches] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [expiryDate, setExpiryDate] = useState();

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  const numberRegex = new RegExp(`^[0-9]*$`);

  useEffect(() => {
    if (!state?.product?._id) {
      formDataChangedRef.current = {
        name,
        code,
        description,
        qty: qty,
        unitSellingPrice: unitSellingPrice,
        totalSellingPrice: totalSellingPrice,
        averageUnitCost: averageUnitCost,
        totalCost: totalCost,
        uom,
        type,
        isIncludeMembership,
        supplier,
        batchNumber: batchNo,
        location: location,
        branch: branch,
        expDate: expiryDate,
      }
    }
  }, [name, code, description, qty, unitSellingPrice, 
    totalSellingPrice, averageUnitCost, totalCost, uom, type, 
    isIncludeMembership, supplier, batchNo, location, branch, expiryDate]);

  useEffect(() => {
    return () => {
      if (!state?.product?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.product?._id) {
      populateData();
    }
  }, []);

  const populateData = async () => {
    const responses = await Promise.all([
      getSuppliers(),
      getProductCategory(),
      getUOM(),
      getBranches(),
    ]);
    // if (responses[0].length) setAllSuppliers(responses[0]);
    // if (responses[1].length) setAllTypes(responses[1]);
    // if (responses[2].length) setAllUoms(responses[2]);
    // if (responses[3].length) setAllBranches(responses[3]);

    const reduxData = formData?.[pathname];
    if (reduxData && !state?.product?._id) {
      setName(reduxData?.name);
      setCode(reduxData?.code);
      setQty(reduxData?.qty ?? 0);
      setDescription(reduxData?.description);
      setUnitSellingPrice(reduxData?.unitSellingPrice ?? 0);
      setTotalSellingPrice(reduxData?.totalSellingPrice ?? 0);
      setAverageUnitCost(reduxData?.averageUnitCost ?? 0);
      setTotalCost(reduxData?.totalCost ?? 0);
      setUom(reduxData?.uom);
      setSupplier(reduxData?.supplier);
      setType(reduxData?.type);
      setBranch(reduxData?.branch?._id);
      setLocation(reduxData?.location);
      setIncludeMembership(reduxData?.isIncludeMembership);
      setBatchNo(reduxData?.batchNumber);
      setExpiryDate(reduxData?.expDate);
      
      setBranchLocation(
        responses[3],
        reduxData?.branch,
        reduxData?.location?._id
      );
    } else {
      setName(state?.product?.name);
      setCode(state?.product?.code);
      setQty(state?.product?.qty?.toString());
      setDescription(state?.product?.description);
      setUnitSellingPrice(state?.product?.unitSellingPrice.toString());
      setTotalSellingPrice(state?.product?.totalSellingPrice?.toString());
      setAverageUnitCost(state?.product?.averageUnitCost.toString());
      setTotalCost(state?.product?.totalCost.toString());
      setUom(state?.product?.uom?._id);
      setSupplier(state?.product?.supplier);
      setType(state?.product?.type?._id);
      setBranch(state?.product?.branch?._id);
      setLocation(state?.product?.location);
      setIncludeMembership(state?.product?.isIncludeMembership);
      setBatchNo(state?.product?.batchNumber);
      setExpiryDate(state?.product?.expDate);

      if (state?.product?.branch && state?.product?.location) {
        setBranchLocation(
          responses[3],
          state.product.branch,
          state.product.location
        );
      }
    }

    let _uoms = [...responses[2]];
    _uoms.forEach(function (item, i) {
      if (item?._id === state?.product?.uom?._id) {
        _uoms.splice(i, 1);
        _uoms.unshift(item);
      }
    });
    setAllUoms(_uoms);

    let _suppliers = [...responses[0]];
    _suppliers.forEach(function (item, i) {
      if (item?._id === state?.product?.supplier) {
        _suppliers.splice(i, 1);
        _suppliers.unshift(item);
      }
    });
    setAllSuppliers(_suppliers);

    let _types = [...responses[1]];
    _types.forEach(function (item, i) {
      if (item?._id === state?.product?.type?._id) {
        _types.splice(i, 1);
        _types.unshift(item);
      }
    });
    setAllTypes(_types);

    // let _locations = [...responses[3]];
    // _locations.forEach(function (item, i) {
    //   if (item?._id === state?.product?.location) {
    //     _locations.splice(i, 1);
    //     _locations.unshift(item);
    //   }
    // });
    // setAllBranches(_locations);
  };

  const setBranchLocation = (_branches, _branch, _location) => {
    let __branches = [..._branches];
    __branches.forEach(function (item, i) {
      if (item?._id === _branch) {
        __branches.splice(i, 1);
        __branches.unshift(item);
      }
    });
    setAllBranches(__branches);

    let selectedBranch = __branches?.find((e) => e._id === _branch._id);

    setBranch(selectedBranch);

    let __location = [...selectedBranch.location];
    __location.forEach(function (item, i) {
      if (item?._id === _location) {
        __location.splice(i, 1);
        __location.unshift(item);
      }
    });
    setAllLocations(__location);
    setLocation(selectedBranch?.location?.find((e) => e._id === _location));
  };

  useEffect(() => {
    if (allBranches?.length > 0 && allBranches[0].location?.length > 0) {
      if (state?.product?._id) {
        setLocation(allBranches[0].location[0]);
      }
    }
  }, [allBranches]);

  const saveProduct = async () => {
    if (!name) {
      missingPopup("Name");
    } else if (!code) {
      missingPopup("Code");
    } else if (!qty) {
      missingPopup("Quantity (Qty.)");
    } else if (!unitSellingPrice) {
      missingPopup("Unit Selling Price");
    } else if (!type) {
      missingPopup("Type");
    } else if (!branch) {
      missingPopup("Branch");
    } else if (!location) {
      missingPopup("Location");
    } else {
      let data = {
        name,
        code,
        description,
        qty: Number(qty),
        unitSellingPrice: Number(unitSellingPrice),
        totalSellingPrice: Number(totalSellingPrice),
        averageUnitCost: Number(averageUnitCost),
        totalCost: Number(totalCost),
        uom,
        type,
        isIncludeMembership,
        supplier,
        batchNumber: batchNo,
        location: location?._id || null,
        branch: branch?._id || null,
        expDate: expiryDate,
      };
      if (!state?.product) {
        const response = await createProducts(data);
        if (response?.status !== "Fail") {
          formDataChangedRef.current = null;
          dispatch(setFormData({
            ...formData,
            [pathname]: null
          }));
          toastify("Product added successfully!", "success");
          navigate("/inventory-products");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateProducts(state?.product?._id, data);
        if (response?.status !== "Fail") {
          toastify("Product updated successfully!", "success");
          navigate("/inventory-products");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const populateOptions = async () => {
    const responses = await Promise.all([
      getSuppliers(),
      getProductCategory(),
      getUOM(),
      getBranches(),
    ]);
    if (responses[0].length) setAllSuppliers(responses[0]);
    if (responses[1].length) setAllTypes(responses[1]);
    if (responses[2].length) setAllUoms(responses[2]);
    if (responses[3].length) setAllBranches(responses[3]);
  };

  useEffect(() => {
    setTotalSellingPrice(qty * unitSellingPrice);
  }, [qty, unitSellingPrice]);

  useEffect(() => {
    setTotalCost(qty * averageUnitCost);
  }, [qty, averageUnitCost]);

  useEffect(() => {
    if (branch && branch !== "") {
      setAllLocations(branch?.location);
    }
  }, [branch]);

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.product?._id,
      type: "Product",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  useEffect(() => {
    if (state?.product) populateData();
    else {
      populateOptions();
    }
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>

            {`${state?.product ? "Edit" : "New"} Product`}
          </h1>

          {state?.product && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Code *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setCode(e.target.value)}
                      value={code}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Qty *</Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Write..."
                      // disabled={state?.product}
                      onChange={(e) =>
                        numberRegex.test(e.target.value) ||
                        e.target.value === ""
                          ? setQty(e.target.value)
                          : null
                      }
                      value={qty}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">UOM *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={uom}
                    options={[
                      ...allUoms?.map((o) => {
                        return {
                          value: o._id,
                          label: o.name,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setUom(e.value);
                    }}
                    name={"uom"}
                  />
                  {/* <select
										className="form-select mb-3"
										onChange={(e) => {
											setUom(e.target.value);
										}}
									>
										{!state?.product && (
											<option defaultChecked>
												Select
											</option>
										)}
										{allUoms?.map((e) => (
											<option value={e._id}>
												{e.name}
											</option>
										))}
									</select> */}
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>

                <span className="col-1" />

                <div className="col-5">
                  <Form.Group>
                    <Form.Label className="headings2">
                      Unit Selling Price *
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setUnitSellingPrice(e.target.value)}
                        value={unitSellingPrice}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="headings2 mt-3">
                      Total Selling Price
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        disabled
                        placeholder="Write..."
                        // onChange={(e) =>
                        //   numberRegex.test(e.target.value) ||
                        //   e.target.value === ""
                        //     ? setTotalSellingPrice(e.target.value)
                        //     : null
                        // }
                        value={qty * unitSellingPrice}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>
              </div>
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2 mt-3">Type *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={type}
                    options={[
                      ...allTypes?.map((o) => {
                        return {
                          value: o._id,
                          label: o.name,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setType(e.value);
                    }}
                    name={"type"}
                  />
                  {/* <select
										className="form-select mb-3"
										onChange={(e) => {
											setType(e.target.value);
										}}
									>
										{!state?.product && (
											<option defaultChecked>
												Select
											</option>
										)}
										{allTypes?.map((e) => (
											<option
												value={e._id}
												selected={type == e._id}
											>
												{e.name}
											</option>
										))}
									</select> */}
                </Form.Group>

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2  mt-3">
                    Average Unit Cost
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setAverageUnitCost(e.target.value)}
                      value={averageUnitCost}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Branch *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={JSON.stringify(branch)}
                    options={[
                      ...allBranches?.map((o) => {
                        return {
                          value: JSON.stringify(o),
                          label: o.name,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setBranch(JSON.parse(e.value));
                      setLocation(null);
                    }}
                    name={"branch"}
                  />
                </Form.Group>
                {/* <Form.Group className="col-5">
									<Form.Label className="headings2">
										Supplier
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										selectedValue={supplier}
										options={[
											...allSuppliers?.map((o) => {
												return {
													value: o._id,
													label: o.name,
												};
											}),
										]}
										onChange={(e) => {
											setSupplier(e.value);
										}}
										name={"supplier"}
									/>
								</Form.Group> */}

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Total Cost</Form.Label>
                  <InputGroup>
                    <Form.Control
                      disabled
                      placeholder="Write..."
                      // onChange={(e) =>
                      //   numberRegex.test(e.target.value) ||
                      //   e.target.value === ""
                      //     ? setTotalCost(e.target.value)
                      //     : null
                      // }
                      value={qty * averageUnitCost}
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="d-flex">
                <Form.Group className="col-5">
                  <Form.Label className="headings2">Location *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    showConsole
                    selectedValue={
                      location ? JSON.stringify(location) : location
                    }
                    options={[
                      ...allLocations?.map((o) => {
                        return {
                          value: JSON.stringify(o),
                          label: o.name,
                        };
                      }),
                    ]}
                    onChange={(e) => {
                      setLocation(JSON.parse(e.value));
                    }}
                    name={"location"}
                  />
                </Form.Group>
                {/* <Form.Group className="col-5">
									<Form.Label className="headings2">
										Branch *
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										selectedValue={JSON.stringify(branch)}
										options={[
											...allBranches?.map((o) => {
												return {
													value: JSON.stringify(o),
													label: o.name,
												};
											}),
										]}
										onChange={(e) => {
											setBranch(JSON.parse(e.value));
											setLocation(null);
										}}
										name={"branch"}
									/>
								</Form.Group> */}

                <span className="col-1" />

                <Form.Group className="col-5">
                  <Form.Label className="headings2">Batch Number </Form.Label>
                  <InputGroup>
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setBatchNo(e.target.value)}
                      value={batchNo}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="d-flex">
                {/* <Form.Group className="col-5">
									<Form.Label className="headings2">
										Location *
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										showConsole
										selectedValue={
											location
												? JSON.stringify(location)
												: location
										}
										options={[
											...allLocations?.map((o) => {
												return {
													value: JSON.stringify(o),
													label: o.name,
												};
											}),
										]}
										onChange={(e) => {
											setLocation(JSON.parse(e.value));
										}}
										name={"location"}
									/>
								</Form.Group> */}
                <div className="col-5">
                  <DateInput
                    label={"Expiry Date"}
                    value={expiryDate}
                    placeholder="Select"
                    // placeholder="Expiry Date"
                    onChange={(e) => {
                      setExpiryDate(e);
                    }}
                  />
                </div>
              </div>

              {/* <div className="ms-1 mt-3">
								<Form.Check
									type={"checkbox"}
									label="Include Membership"
									onClick={() =>
										setIncludeMembership(
											!isIncludeMembership,
										)
									}
									checked={isIncludeMembership === true}
								/>
							</div> */}
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveProduct}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
