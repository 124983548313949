import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { updateCampaign, createCampaign } from "../../../apis/campaign";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import { Switch } from "antd";

export default function AddEditCampaign() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState(false);

  const populateData = async () => {
    setName(state?.campaign?.name);
    setRemarks(state?.campaign?.remarks);
    setStatus(state?.campaign?.isActive);
  };

  const saveCampaign = async () => {
    if (!name) {
      missingPopup("Name");
    } else {
      if (!state?.campaign) {
        const response = await createCampaign({
          name,
          remarks,
          isActive: status,
        });
        if (response?.status !== "Fail") {
          toastify("Campaign added successfully!", "success");
          navigate("/campaign");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updateCampaign(state?.campaign?._id, {
          name,
          remarks,
          isActive: status,
        });
        if (response?.status !== "Fail") {
          toastify("Campaign updated successfully!", "success");
          navigate("/campaign");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  useEffect(() => {
    if (state?.campaign) populateData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.campaign ? "Edit " : "New "}`}
          Campaign
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                <Form.Group>
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2">Remarks</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      placeholder="Write..."
                      style={{ resize: "none" }}
                      rows={4}
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                      className="large-text-area"
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="headings2 d-flex mt-3">
                    Status
                  </Form.Label>
                  <Switch checked={status} onChange={(e) => setStatus(e)} />
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={saveCampaign}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
