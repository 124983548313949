import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { createDotPhrase, updateDotPhrase } from "../../../apis/dotPhrases";
import { createDrawtool, updateDrawtool } from "../../../apis/drawtool";

export default function AddEditDrawtool() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const titleRegex = new RegExp("^[a-zA-Z]+$");

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState();
    const [imageSize, setImageSize] = useState({ width: undefined, height: undefined });
    const [preview, setPreview] = useState();

    useEffect(() => {
        if (state?.drawtool) populateDrawtool();
    }, []);

    // useEffect(() => {
    //     if (image) {
    //         if (state?.drawtool) {
    //             console.log(4);
    //             setPreview(state?.drawtool?.imageUrl);
    //         } else {
    //             console.log(1);
    //             if (!image) {
    //                 console.log(2);
    //                 setPreview(undefined);
    //                 return;
    //             }
    //             console.log(3);
    //             const objectUrl = URL.createObjectURL(image);
    //             setPreview(objectUrl);
    //             return () => URL.revokeObjectURL(objectUrl);
    //         }
    //     } else {
    //         setPreview(undefined);
    //     }
    // }, [image]);

    useEffect(() => {
        if (state?.drawtool) {
            setPreview(state?.drawtool?.imageUrl);
        }
    }, [state?.drawtool]);

    const populateDrawtool = () => {
        setName(state?.drawtool.title);
        setDescription(state?.drawtool.description);
        setImage(state.drawtool.imageUrl);
    };

    const saveDrawtool = async () => {
        if (!name) {
            missingPopup("Name");
        } else if (!description) {
            missingPopup("Description");
        } else if (!image) {
            missingPopup("Image");
        } else {
            if (state?.drawtool) {
                let fd = new FormData();
                fd.append("image", image);
                fd.append("title", name);
                fd.append("description", description);
                fd.append("dataId", state?.drawtool._id);

                const response = await updateDrawtool(fd);
                if (response?.status !== "Fail") {
                    toastify("Drawtool updated successfully!", "success");
                    navigate("/drawtool");
                } else {
                    toastify(response?.message, "error");
                }
            } else {
                let fd = new FormData();
                fd.append("image", image);
                fd.append("title", name);
                fd.append("description", description);

                const response = await createDrawtool(fd);
                if (response?.status !== "Fail") {
                    toastify("Drawtool added successfully!", "success");
                    navigate("/drawtool");
                } else {
                    toastify(response?.message, "error");
                }
            }
        }
    };

    return (
        <React.Fragment>
            <div className="mainHeading px-4">
                <h1>
                    <i
                        className="fa fa-arrow-left cursor-pointer"
                        onClick={() => navigate(-1)}
                    ></i>
                    {`${state?.drawtool ? "Edit " : "Add "}`}
                    Drawtool Template
                </h1>
            </div>
            <div className="pb-4 px-4">
                <div className="content">
                    <div className="addEdit pb-4">
                        <div className="row">
                            <div className="col-md-6">
                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Name *
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="Write..."
                                            onChange={(e) =>
                                                setName(e.target.value)
                                            }
                                            value={name}
                                        />
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group>
                                    <Form.Label className="headings2">
                                        Description *
                                    </Form.Label>
                                    <InputGroup>
                                        <Form.Control
                                            as="textarea"
                                            style={{ resize: "none" }}
                                            className="large-text-area"
                                            placeholder="Write..."
                                            onChange={(e) =>
                                                setDescription(e.target.value)
                                            }
                                            value={description}
                                        />
                                    </InputGroup>
                                </Form.Group>

                                <div className="d-flex flex-row align-items-center  mt-4">
                                    <Form.Label className="d-flex">
                                        Image (Recommended 2000 x 1800) *
                                    </Form.Label>
                                </div>

                                {image ? (
                                    <div className="">
                                        <img
                                            style={{ width: "100px" }}
                                            alt="img"
                                            src={preview}
                                            key={Math.random() * 10}
                                        />
                                        {image && (
                                            <div
                                                className="mt-2 font-red cursor-pointer bold"
                                                onClick={() => {
                                                    setImage();
                                                    setPreview();
                                                }}
                                            >
                                                Reupload
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <label class="myLabel">
                                        <input
                                            type="file"
                                            required
                                            accept={[".png", ".jpg"]}
                                            onChange={(e) => {
                                                setImage(e.target.files[0]);
                                                if (e.target.files[0]) {
                                                    const img = new Image();
                                                    img.onload = () => {
                                                        setImageSize({ width: img.width, height: img.height });
                                                    };
                                                    img.src = URL.createObjectURL(e.target.files[0]);
                                                }

                                                const objectUrl =
                                                    URL.createObjectURL(
                                                        e.target.files[0]
                                                    );
                                                setPreview(objectUrl);
                                                return () =>
                                                    URL.revokeObjectURL(
                                                        objectUrl
                                                    );
                                            }}
                                        />
                                        <img
                                            className="cursor-pointer"
                                            style={{ height: 80, width: 80 }}
                                            src={require("../../../assets/images/upload.png")}
                                        />
                                    </label>
                                )}
                                {imageSize.width && imageSize.height && (imageSize.width !== 2000 || imageSize.height !== 1800) && (
                                    <span>Current image size is not recommended</span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="addEdit">
                        <Button
                            className="addItem"
                            onClick={() => {
                                saveDrawtool();
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
