import api from "../axios/axios-config";

export const get = async () => {
  try {
    let response = await api.get("patientPrescriptionSelect/list");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const craete = async (data) => {
  try {
    let response = await api.post("patientPrescriptionSelect/create", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const update = async (dataId, data) => {
  try {
    let response = await api.post("patientPrescriptionSelect/update", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteData = async (data) => {
  try {
    let response = await api.post("patientPrescriptionSelect/deleteData", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
