export function requiredFieldValidation(...args) {
  var tempArr = [...args];
  console.log(
    "🚀 ~ file: validation.js:3 ~ requiredFieldValidation ~ tempArr",
    tempArr
  );
  for (let i = 0; i < tempArr.length; i++) {
    if (!tempArr[i].value) {
      return {
        status: false,
        message: `${tempArr[i].name} is missing.`,
      };
      break;
    }
  }
  // if (args.filter((o) => !o.value).length != 0) return { status: false, message: "Required fields can't be empty" };
  return { status: true };
}
