import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { createoccupation, updateoccupation } from "../../../apis/occupation";
import { toastify } from "../../../helperFunctions/toastify";

export default function HearAboutUs() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [name, setName] = useState("");
  const [dataId, setDataId] = useState();

  const submitForm = async () => {
    if (!name) {
      toastify("Name is missing", "error");
      return;
    }
    if (dataId) {
      const { result, error } = await updateoccupation({
        dataId: dataId,
        name: name,
      });
      if (error) {
        toastify("Failed to update", "error");
        return;
      }
      navigate("/occupation");
    } else {
      const { result, error } = await createoccupation({
        name: name,
        isActive: true,
      });
      if (error) {
        toastify("Failed to add", "error");
        return;
      }
      navigate("/occupation");
    }
  };
  useEffect(() => {
    if (state?.id) {
      setName(state.name);
      setDataId(state.id);
    }
  }, [state]);

  return (
    <>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          ></i>
          {dataId ? "Edit " : "Add "}Occupation
        </h1>
      </div>
      <div className="px-4">
        <Form.Group className="col-md-6">
          <Form.Label className="black-label-text">Name *</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              name={"name"}
              placeholder="Write..."
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
          </InputGroup>
        </Form.Group>
        <Button className="addItem" onClick={submitForm}>
          Save
        </Button>
      </div>
    </>
  );
}
