import api from "../axios/axios-config";

export const getPackages = async (params) => {
  try {
    let response = await api.get("package/listOfPackages", {
      params: params,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addPackage = async (data) => {
  try {
    let response = await api.post("package/createPackage", data, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const migratePackage = async (data) => {
  try {
    let response = await api.post("migrate/package", data);
    return response?.data?.message;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updatePackage = async (dataId, data) => {
  try {
    let response = await api.post("package/updatePackage", {
      dataId,
      ...data,
    });
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
