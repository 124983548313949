import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../../redux/slices/StaffFormsSlices";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import { getBranches } from "../../../apis/branch";
import _ from "lodash";
import DateInput from "../../SharedComponents/DateInput";
import { AddOrSubractDays, adjustDateFormat } from "../../../helperFunctions/utils";
import {
	createActiveStaff,
	getActiveStaffByDate,
} from "../../../apis/activeStaff";

function BucketList() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let location = useLocation();
	const { tableSearch } = useSelector((state) => state.commonData);
  	const currentDate = tableSearch?.dashboard?.currentDate ? tableSearch?.dashboard?.currentDate : adjustDateFormat(new Date());

	// const [allLocations, setAllLocations] = useState([]);

	const { bucketList, allStaff } = useSelector((state) => state.staffForm);
	const [date, setDate] = useState(new Date(currentDate));
	const [isChangeDate, setIsChangeDate] = useState(false);
	const [checkedStaff, setCheckedStaff] = useState([]);
	const [isFromRosteringPage, setIsFromRosteringPage] = useState(false);

	const isAllSelected = () => {
		const isAllSelected = allStaff.find((o) => {
			if (o?.staff?.length == 0 || !o?.staff) return false;
			if (o?.staff?.find((e) => !e.isChecked)) {
				return true;
			} else {
				return false;
			}
		});
		if (isAllSelected) return true;
		return false;
	};
	const onSelectAll = () => {
		const allSelected = isAllSelected();
		var tempArr = allStaff.map((o) => {
			if (o?.staff?.length > 0) {
				return {
					...o,
					staff: o.staff.map((e) => {
						return { ...e, isChecked: allSelected };
					}),
				};
			}
			return o;
		});
		dispatch(setData({ parent: "allStaff", value: [...tempArr] }));
		var tempBucketList = [];
		tempArr.forEach((data) => {
			if (!data.staff || data.staff.length == 0) {
				tempBucketList.push({ ...data, staff: undefined });
			} else {
				data.staff.forEach((o, i) => {
					if (o.isChecked) {
						tempBucketList.push({ ...data, staff: o });
					} else {
						tempBucketList.push({ ...data, staff: undefined });
					}
				});
			}
		});
		dispatch(setData({ parent: "bucketList", value: tempBucketList }));
	};

	const onStaffSave = async () => {
		let staffArr = bucketList
			.filter((o) => o?.staff?.isChecked)
			?.map((o) => o?.staff?.id);
		const { result, error } = await createActiveStaff({
			date,
			staff: staffArr,
		});
		if (error) {
			toastify(error?.message, "error");
			return;
		}
		toastify("Save successfully", "success");
	};

	const getAllStaff = async (checkedStaff, isFromRostering) => {
		console.log(checkedStaff, isFromRostering)
		let allLocations = [];
		const response = await getBranches();

		if (response?.status !== "Fail") {
			let tempArr = response?.map((branch) => {
				return branch?.location?.map((o) => ({
					id: o._id,
					location: o.name,
				}));
			});
			tempArr = _.flattenDeep(tempArr);
			allLocations = [...tempArr];
			// setAllLocations(tempArr);
		} else {
			if (response?.message === "No data Found") {
				// setAllLocations([]);
			} else {
				toastify(response?.message, "error");
			}
		}
		if (allLocations.length === 0) return;
		const { result, error } = await authorizedRequests({
			url: "users/staffList",
			method: "get",
		});
		if (error) {
			toastify(error.message ?? "Failed to fetch staff list", "error");
			return;
		}

		allLocations = allLocations.map((o) => {
			const staffWithLocation = result.data?.filter(
				(staff) => staff.location == o.id,
			);
			if (staffWithLocation.length > 0) {
				return {
					...o,
					staff: [
						...staffWithLocation.map((s) => {
							let isChecked = false;
							if (isFromRostering) {
								isChecked = bucketList.find(
									(k) => k?.staff && k?.staff?.id == s?._id,
								)
									? true
									: false;
							}
							if (checkedStaff?.length) {
								isChecked = checkedStaff.indexOf(s?._id) != -1;
							}
							return {
								name: s.username,
								department: s.departmentName,
								id: s._id,
								isChecked: isChecked,
							};
						}),
					],
				};
			}
			return { ...o, staff: [] };
		});

		dispatch(setData({ parent: "allStaff", value: [...allLocations] }));

		var tempBucketList = [];
		allLocations.forEach((data) => {
			if (!data.staff || data.staff.length == 0) {
				tempBucketList.push({ ...data, staff: undefined });
			} else {
				data?.staff?.forEach((o, i) => {
					if (o.isChecked) {
						tempBucketList.push({ ...data, staff: o });
					} else {
						tempBucketList.push({ ...data, staff: undefined });
					}
				});
			}
		});
		dispatch(setData({ parent: "bucketList", value: tempBucketList }));

		const isEmpty = tempBucketList.filter(x => x.staff === undefined).length === tempBucketList.length;
		if (isFromRostering !== true && isEmpty !== true && isChangeDate === false) {
			dispatch(
				setData({
					parent: "currentDate",
					value: adjustDateFormat(date),
				}),
			);
			onSubmitRedux(adjustDateFormat(date));

			navigate("/rostering-view", {
				state: { bucketList: tempBucketList },
			});
		}
	};

	const getActiveStaff = async (isFromRostering) => {
		const { result, error } = await getActiveStaffByDate({
			date,
		});
		if (error) {
			toastify(error.message, "error");
			getAllStaff([], isFromRostering);
		} else {
			getAllStaff(result?.data?.staff?.map(x => x._id) ?? [], isFromRostering);
		}
	};

	const onSelect = (loc, id) => {
		const tempArr = allStaff.map((item) => {
			if (item.id === loc) {
				return {
					...item,
					staff: item.staff.map((o) => {
						if (o.id === id) {
							return { ...o, isChecked: !o.isChecked };
						}
						return o;
					}),
				};
			}
			return { ...item };
		});

		var tempBucketList = [];
		tempArr.forEach((data) => {
			if (!data.staff || data.staff.length == 0) {
				tempBucketList.push({ ...data, staff: undefined });
			} else {
				data?.staff?.forEach((o, i) => {
					if (o.isChecked) {
						tempBucketList.push({ ...data, staff: o });
					} else {
						tempBucketList.push({ ...data, staff: undefined });
					}
				});
			}
		});

		dispatch(setData({ parent: "allStaff", value: [...tempArr] }));
		dispatch(setData({ parent: "bucketList", value: tempBucketList }));
	};

	// useEffect(() => {
	// 	if (!location?.state?.showBucket) {
	// 		const bucket = JSON.parse(localStorage.getItem("bucketList"));
	// 		if (bucket?.length > 0) {
	// 			navigate("/rostering-view", {
	// 				state: { bucketList: bucket },
	// 			});
	// 		}
	// 	}
	// }, [localStorage.getItem("bucketList"), location?.state?.showBucket]);

	useEffect(() => {
		if (location?.state?.isFromRostering) {
			setIsFromRosteringPage(true);
			getActiveStaff(true);
			// navigate("/rostering", {
			// 	state: { 
			// 		...location?.state,
			// 		isFromRostering: false
			// 	},
			// });
		} else {
			getActiveStaff(false);
		}

		// if (location?.state?.isFromRostering && !isFromRosteringPage) {
		// 	getAllStaff([], true);
		// 	setIsFromRosteringPage(true);
		// } else if (date) {
		// }
	}, [date]);

	const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
		  dashboard: {
			currentDate: value
		  }
		}))
	  }

	return (
		<div className="container p-0 ps-5">
			<div className="mainHeading">
				<h1>On Active Staff</h1>
				{/* <Button className="addItem" onClick={addUser}>
          + New User
        </Button> */}
			</div>
			<div
				className="datesButton d-flex bg-theme mb-2"
				style={{ borderRadius: "10px", width: "fit-content" }}
			>
				<Button
					className="  white-600-24 bg-theme border-0"
					style={{ fontWeight: 400 }}
					onClick={() => {
						let currDate = date;

						const previousDate = AddOrSubractDays(
							new Date(currDate),
							1,
							false,
						);
						
						if (location?.state?.isFromRostering) {
							location.state.isFromRostering = false;
						}
						setIsChangeDate(true);
						setDate(previousDate);
					}}
				>
					{"<"}
				</Button>
				<div className="date align-self-center white px-2 ">
					<div style={{ width: "150px", height: "45px" }}>
						<DateInput
							background={"#34556f"}
							calenderClass={"whiteCalender"}
							inputClassName="themeInput"
							value={date}
							onChange={(e) => {
								if (location?.state?.isFromRostering) {
									location.state.isFromRostering = false;
								}
								setIsChangeDate(true);
								setDate(e);
							}}
							shouldDisableDates={true}
						/>
					</div>
				</div>
				<Button
					className="white-600-24 bg-theme border-0"
					style={{ fontWeight: 400 }}
					onClick={() => {
						const nextDate = AddOrSubractDays(
							new Date(date),
							1,
							true,
						);
						if (location?.state?.isFromRostering) {
							location.state.isFromRostering = false;
						}
						setIsChangeDate(true);
						setDate(nextDate);
					}}
				>
					{">"}
				</Button>
			</div>
			<div className="grey">
				Selected : {bucketList?.filter((o) => o.staff).length}
			</div>
			<div className="d-flex mt-3">
				<input
					type="checkbox"
					className="pointer"
					// value={}
					checked={isAllSelected() ? false : true}
					onChange={(e) => {
						onSelectAll();
					}}
				/>
				<span className="ms-2">Select All</span>
			</div>
			<div
				className="row py-4"
				style={{ minHeight: "calc( 100vh - 184px )" }}
			// style={{ minHeight: "80vh" }}
			>
				<div
					className="col-8 d-flex flex-column justify-content-between"
					style={{ minHeight: "100%" }}
				>
					<div>
						{allStaff.map((data) => (
							<Accordion
								className="mb-2"
								defaultActiveKey={() => {
									if (
										data?.staff?.length > 0 &&
										data?.staff?.find((e) => e.isChecked)
									) {
										return "0";
									}
								}}
							>
								<Accordion.Item eventKey="0">
									<Accordion.Header>
										{data.location}
									</Accordion.Header>
									<Accordion.Body>
										{data?.staff?.length > 0 &&
											data?.staff?.map((e) => (
												<div className="align-items-center flex">
													<input
														type="checkbox"
														className="pointer"
														value={e.id}
														checked={e.isChecked}
														onChange={(e) => {
															onSelect(
																data.id,
																e.target.value,
															);
														}}
													/>
													<label className="form-check-label mx-3 font-black">
														<p className="">
															{e.name +
																" (" +
																e.department +
																")"}
														</p>
													</label>
												</div>
											))}
									</Accordion.Body>
								</Accordion.Item>
							</Accordion>
						))}
					</div>
					<div className="">
						<Button
							className="addItem ms-auto mb-3"
							style={{ width: "250px", height: "50px" }}
							onClick={() => {
								onStaffSave();
							}}
						>
							Save
						</Button>
						<Button
							className="addItem ms-auto"
							style={{ width: "250px", height: "50px" }}
							onClick={() => {
								if (bucketList.length < 1) {
									toastify(
										"Please select atleast 1 staff",
										"error",
									);
									return;
								}
								onStaffSave();
								dispatch(
									setData({
										parent: "currentDate",
										value: adjustDateFormat(date),
									}),
								);
								onSubmitRedux(adjustDateFormat(date));
								navigate("/rostering-view", {
									state: { bucketList },
								});
							}}
						>
							Continue
						</Button>
					</div>
				</div>
				<div className="col-3 ms-3 selected-user-container">
					<div className="selected-user-heading white-600-24">
						Selected Users
					</div>
					<div className="selected-user-body">
						{bucketList.length > 0 &&
							bucketList.map((o) => {
								if (!o.staff) return <></>;
								return (
									<div className="selected-user-card d-flex justify-content-between">
										<div className="w-75">
											{o.staff.name} ({o.staff.department}
											)
										</div>
										<div
											className="ms-auto align-self-center pointer p-0 grey"
											style={{ fontSize: "16px" }}
											onClick={() => {
												onSelect(o.id, o.staff.id);
											}}
										>
											&#10005;
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
}

export default BucketList;
