import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getBranches, updateBranch, deleteBranch } from "../../../apis/branch";
import { toastify } from "../../../helperFunctions/toastify";
import {
	customNumberSort,
	customStringSort,
} from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function Branch() {
	const navigate = useNavigate();
	const [branches, setBranches] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getBranchesList = async () => {
		const response = await getBranches();
		if (response.status !== "Fail") {
			setBranches(response.filter((e) => !e.isDeleted).reverse());
		} else {
			if (response?.message === "No data Found") {
				setBranches([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const removeBranch = async () => {
		setShow(false);
		let response = await deleteBranch(deleting, { isDeleted: true });
		if (response?.status !== "Fail") {
			getBranchesList();
			toastify("Branch deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	const editBranch = (e) => {
		navigate("/add-location", {
			state: {
				branch: e,
			},
		});
	};

	const addBranch = () => {
		navigate("/add-location");
	};

	useEffect(() => {
		getBranchesList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly2">
					<span className="me-2">Name</span>
					{
						<FilterComponent
							type="search"
							array={branches}
							setArray={setFilteredData}
							value="Name"
						/>
					}
				</div>
			),
			selector: (row) => row.name,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "name"),
		},
		{
			name: "Address",
			selector: (row) => <div>{row.address}</div>,
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "address"),
		},
		{
			name: "Location Name",
			selector: (row) => (
				<div className="d-flex flex-column">
					{row.locations.map((e) => (
						<div className="my-1">{e.name}</div>
					))}
				</div>
			),
			wrap: true,
		},
		{
			name: "Location Description",
			selector: (row) =>
				row.locations.map((e) => (
					<div className="my-1">{e.descriptiuon}</div>
				)),
			wrap: true,
		},
		{
			name: "Total Rooms",
			selector: (row) => row.rooms?.toLocaleString(),
			wrap: true,
			sortable: true,
			sortFunction: (a, b) => customNumberSort(a, b, "rooms"),
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? branches : filteredData)?.map(
		(e, index) => {
			console.log(branches);
			let rooms = [];
			e.location.forEach((loc) => {
				rooms = [...rooms, ...loc.rooms];
			});
			return {
				id: e._id,
				name: e.name,
				index: index,
				address: e.address,
				rooms: rooms.length,
				locations: e.location,

				action: (
					<div className="d-flex">
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								editBranch(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3  cursor-pointer icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<>
			<div className="container p-0">
				<div className="mainHeading px-4">
					<h1>Branch</h1>
					<Button className="addItem" onClick={addBranch}>
						+ Add New
					</Button>
				</div>

				<DeleteModal
					content="Confirm Branch Deletion?"
					onYes={removeBranch}
					onNo={handleClose}
					show={show}
				/>

				<DataTable
					columns={columns}
					customStyles={customStyles}
					data={data || []}
					pagination
					conditionalRowStyles={conditionalRowStyles}
				/>
			</div>
		</>
	);
}
