import api from "../axios/axios-config";

export const getMembershipCategories = async () => {
  try {
    let response = await api.get("membershipCategory/listOfMembershipCategory");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getMembershipSettings = async () => {
  try {
    let response = await api.get("membershipSetting/listOfMembershipSetting");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createMembershipCategory = async (data) => {
  try {
    let response = await api.post(
      "membershipCategory/createMembershipCategory",
      data
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateMembershipCategory = async (dataId, data) => {
  try {
    let response = await api.post(
      "membershipCategory/updateMembershipCategory",
      {
        dataId,
        ...data,
      }
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateMembershipSettings = async (dataId, data) => {
  try {
    let response = await api.post("membershipSetting/updateMembershipSetting", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
