import React, { useEffect, useState } from "react";
import { Row, Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {
	getAllQuestions,
	addQuestion,
	updateQuestion,
} from "../../../apis/healthDeclaration";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";

export default function AddEditQuestions() {
	const { state } = useLocation();
	const navigate = useNavigate();

	const [active, setActive] = useState();
	const [enableIndicator, setEnableIndicator] = useState();
	const [enableRemark, setEnableRemark] = useState();
	const [checkboxOptions, setCheckboxOptions] = useState([]);
	const [question, setQuestion] = useState();
	const [description, setDescription] = useState();
	const [orderIndex, setOrderIndex] = useState(1);

	const editQuestion = async () => {
		if (active === undefined) {
			missingPopup("Active key");
		}  else if (!question) {
			missingPopup("Question");
		} else {
			const res = await updateQuestion(
        state.id,
        question,
        description,
        checkboxOptions,
        enableIndicator,
        enableRemark,
        active,
        orderIndex,
      );
      if (res.status !== "Fail") {
        toastify("Question updated successfully!", "success");
        navigate("/healthDeclaration-settings");
      } else {
        toastify(res.message, "error");
      }
		}
	};

	const saveQuestion = async () => {
		if (active === undefined) {
			missingPopup("Active key");
		}  else if (!question) {
			missingPopup("Question");
		} else {
			const res = await addQuestion(
        question,
        description,
        checkboxOptions,
        enableIndicator,
        enableRemark,
        active,
        orderIndex,
      );
      if (res.status !== "Fail") {
        toastify("Question added successfully!", "success");
        navigate("/healthDeclaration-settings");
      } else {
        toastify(res.message, "error");
      }
		}
	};

	const getQuestionDetails = async () => {
		const res = await getAllQuestions();
		let arrRes = res.data;
		const response = arrRes.find((e) => e._id === state.id);
    setActive(response.isActive);
    setEnableIndicator(response.enableIndicator);
    setEnableRemark(response.enableRemark);
		setQuestion(response.question);
		setDescription(response.description);
		setOrderIndex(response.orderIndex);
		setCheckboxOptions(response?.indicators);
	};

	useEffect(() => {
		if (state?.id) {
			getQuestionDetails();
		}
	}, []);

	return (
		<React.Fragment>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => navigate(-1)}
					></i>
					{`${state?.id ? "Edit " : "New "}`}
					Health Declaration Setting
				</h1>
			</div>
			<div className="pb-4 px-4">
				<div className="content">
					<div className="addEdit pb-4">
						<div className="row">
							<div>
                <Row>

								<Form.Group className="col-3 mt-3">
									<Form.Label className="headings2 mt-3 d-flex">
										Is Active
									</Form.Label>
									<div>
										<Form.Check
											inline
											label="Yes"
											type={"radio"}
											onClick={() => setActive(true)}
											checked={active === true}
											bsPrefix={"custom-radio"}
										/>
										<Form.Check
											bsPrefix={"custom-radio"}
											inline
											label="No"
											type={"radio"}
											onClick={() => setActive(false)}
											checked={active === false}
										/>
									</div>
								</Form.Group>

                <Form.Group className="col-3 mt-3">
									<Form.Label className="headings2 mt-3 d-flex">
                    Enable Children Option
									</Form.Label>
									<div>
										<Form.Check
											inline
											label="Yes"
											type={"radio"}
											onClick={() => setEnableIndicator(true)}
											checked={enableIndicator === true}
											bsPrefix={"custom-radio"}
										/>
										<Form.Check
											bsPrefix={"custom-radio"}
											inline
											label="No"
											type={"radio"}
											onClick={() => setEnableIndicator(false)}
											checked={enableIndicator === false}
										/>
									</div>
								</Form.Group>

                <Form.Group className="col-3 mt-3">
									<Form.Label className="headings2 mt-3 d-flex">
                    Enable Remark
									</Form.Label>
									<div>
										<Form.Check
											inline
											label="Yes"
											type={"radio"}
											onClick={() => setEnableRemark(true)}
											checked={enableRemark === true}
											bsPrefix={"custom-radio"}
										/>
										<Form.Check
											bsPrefix={"custom-radio"}
											inline
											label="No"
											type={"radio"}
											onClick={() => setEnableRemark(false)}
											checked={enableRemark === false}
										/>
									</div>
								</Form.Group>
                </Row>

								<div className="d-flex">
									<Form.Group className="col-10 mb-3">
										<Form.Label className="headings2 mt-3">
											Question
										</Form.Label>
										<InputGroup className="mb-3">
											<Form.Control
												placeholder="Write..."
												onChange={(e) =>
													setQuestion(e.target.value)
												}
												value={question}
											/>
										</InputGroup>
									</Form.Group>
								</div>

                <div className="d-flex">
									<Form.Group className="col-10 mb-3">
										<Form.Label className="headings2 mt-3">
											Description
										</Form.Label>
										<InputGroup className="mb-3">
											<Form.Control
                        placeholder="Write..."
                        multiline={true}
												as="textarea" 
												rows={4}
												onChange={(e) =>
													setDescription(e.target.value)
												}
												value={description}
											/>
										</InputGroup>
									</Form.Group>
								</div>
								
								<div className="d-flex">
									<Form.Group className="col-5 pe-2">
										<Form.Label className="headings2 mt-3">
											Order Index
										</Form.Label>
										<InputGroup className="mb-3">
											<Form.Control
												placeholder="Write..."
												onChange={(e) =>
													setOrderIndex(e.target.value)
												}
												value={orderIndex}
											/>
										</InputGroup>
									</Form.Group>
								</div>
							</div>
						</div>
					</div>

					{
						enableIndicator === true && (
							<div className="grayBack">
								<Form.Group>
									<Form.Label className="grayLabel">
										New Option
										<AddCircleIcon style={{marginLeft: "15px", cursor: "pointer"}} onClick={() => setCheckboxOptions([...checkboxOptions, ""])} />
									</Form.Label>

									<ul>
										{
											checkboxOptions?.map((option, index) => (
												<li key={index} style={{marginBottom: "10px"}}>
													<input style={{width: "250px"}} value={option} onChange={(e) => setCheckboxOptions(() => {
														const prevState = [...checkboxOptions];
														prevState[index] = e.target.value;

														setCheckboxOptions(prevState);
													})} />
													<DeleteForeverIcon style={{marginLeft: "15px", cursor: "pointer"}} onClick={() => {
														setCheckboxOptions(() => {
															const prevState = [...checkboxOptions];
															prevState.splice(index, 1);

															setCheckboxOptions(prevState)
														})
													}} />
												</li>
											))
										}
									</ul>
								</Form.Group>
							</div>
						)
					}

					<div className="addEdit">
						<Button
							className="addItem"
							onClick={() => {
								if (state?.id) editQuestion();
								else saveQuestion();
							}}
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
