import { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import {
	createProgram,
	getProgramById,
	updateProgram,
} from "../../../apis/programExpiration";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";

export default function AddEditProgram() {
	const params = useParams();
	const navigate = useNavigate();

	const [values, setValues] = useState({
		name: "",
		description: "",
		startDate: null,
		expiryDate: null,
	});

	const onChange = (name, value) => {
		setValues((val) => {
			return { ...val, [name]: value };
		});
	};

	const submitForm = async () => {
		if (!values?.name) {
			missingPopup("Name");
			return;
		}
		if (!values?.startDate) {
			missingPopup("Start Date");
			return;
		}
		if (!values?.expiryDate) {
			missingPopup("Expiry Date");
			return;
		}

		const data = {
			name: values.name,
			description: values.description,
			startDate: values.startDate,
			expiryDate: values.expiryDate,
		};

		if (!params.id) {
			const { result, error } = await createProgram(data);
			if (error) {
				toastify(error.message, "error");
				return;
			}
			toastify("Program created successfully", "success");
			navigate(-1);
		} else {
			const { result, error } = await updateProgram({
				...data,
				dataId: params.id,
			});
			if (error) {
				toastify(error.message, "error");
				return;
			}
			toastify("Program updated successfully", "success");
			navigate(-1);
		}
	};
	const getProgramDetails = async (id) => {
		const { error, result } = await getProgramById(id);
		if (error) {
			toastify(error.message, "error");
			return;
		}
		setValues({
			...values,
			name: result.data?.name,
			description: result.data?.description,
			startDate: new Date(result.data?.startDate),
			expiryDate: new Date(result.data?.expiryDate),
		});
	};

	useEffect(() => {
		if (params?.id) {
			getProgramDetails(params?.id);
		}
	}, [params?.id]);

	return (
		<>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => {
							navigate(-1);
						}}
					></i>
					{`${!params.id ? "Add " : "Edit "}`}
					Program
				</h1>
			</div>
			<div className="col-md-6 px-4 pb-3">
				<Form.Group className="">
					<Form.Label className="black-label-text required">
						Name
					</Form.Label>
					<InputGroup className="mb-3 ">
						<Form.Control
							placeholder="Write..."
							onChange={(e) => {
								onChange("name", e.target.value);
							}}
							value={values.name}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group className="">
					<Form.Label className="black-label-text">
						Description
					</Form.Label>
					<InputGroup className="mb-3">
						<Form.Control
							as="textarea"
							rows={3}
							placeholder="Write..."
							style={{ resize: "none" }}
							onChange={(e) => {
								onChange("description", e.target.value);
							}}
							value={values.description}
						/>
					</InputGroup>
				</Form.Group>
				<Form.Group>
					<Form.Label className="black-label-text required">
						Start Date
					</Form.Label>
					<DateInput
						className="col-md-6"
						value={values.startDate}
						placeholder="Start Date"
						onChange={(e) => {
							if (values.expiryDate && e > values.expiryDate) {
								toastify(
									"Start date can't be greater than expiry date",
									"error",
								);
								return;
							}
							onChange("startDate", e);
						}}
					/>
				</Form.Group>
				<Form.Group className="">
					<Form.Label className="black-label-text required">
						Expiry Date
					</Form.Label>
					<DateInput
						className="col-md-6"
						value={values.expiryDate}
						placeholder="Expiry Date"
						onChange={(e) => {
							if (e < values.startDate) {
								toastify(
									"Expiry date can't be less than start date",
									"error",
								);
								return;
							}
							onChange("expiryDate", e);
						}}
					/>
				</Form.Group>

				<Button
					className="mt-3 addItem"
					onClick={() => {
						submitForm();
					}}
				>
					Submit
				</Button>
			</div>
		</>
	);
}
