import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getSalesReport } from "../../../apis/customTemplate";
import { getStaff } from "../../../apis/staff";
import {
  loadOptionPatients,
  loadSelectedPatient2,
} from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import { setTableSearch } from "../../../redux/slices/commonSlice";
import MultiSelectComponent from "../../SharedComponents/MultiSelctCompoenent";
import {
  customDateSort,
  downloadCustomCsv,
  formatDate,
} from "../../../helperFunctions/utils";
import { thousandAndDecimalFormat } from "../../../utils/common";
import AsyncMultiSelect from "../../SharedComponents/AsyncMultiSelect";

const StyledDataTable = styled(DataTable)`
  max-height: 600px;
  overflow-y: auto;
`;

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

const customStyles = {
  headCells: {
    style: {
      justifyContent: "center",
    },
  },
  cells: {
    style: {
      justifyContent: "center",
    },
  },
};

const conditionalRowStyles = [
  {
    when: (row) => row,
    style: {
      borderWidth: "0px",
    },
  },
  {
    when: (row) => row.index % 2 === 1,
    style: {
      backgroundColor: "#f8f8f8",
      borderWidth: "0px",
    },
  },
];

function SalesReport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);

	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

  const { tableSearch } = useSelector((state) => state.commonData);

  const [users, setUsers] = useState([]);
  const [tags, setTags] = useState([]);
  const [overallTotal, setOverallTotal] = useState({});
  const [paymentDoctors, setPaymentDoctors] = useState([]);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.salesReport?.startDate ? tableSearch[fieldPathname]?.salesReport?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.salesReport?.endDate ? tableSearch[fieldPathname]?.salesReport?.endDate : new Date());
  const [selectedStaff, setSelectedStaff] = useState(tableSearch[fieldPathname]?.salesReport?.selectedStaff ? tableSearch[fieldPathname]?.salesReport?.selectedStaff : []);
  const [selectedOptPatient, setSelectedOptPatient] = useState(tableSearch[fieldPathname]?.salesReport?.selectedOptPatient ? tableSearch[fieldPathname]?.salesReport?.selectedOptPatient : []);
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (
    valueStartDate,
    valueEndDate,
    valueStaff,
    valuePatient
  ) => {
    dispatch(
      setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          ...tableSearch[fieldPathname],
          salesReport: {
            startDate: valueStartDate,
            endDate: valueEndDate,
            selectedStaff: valueStaff,
            selectedOptPatient: valuePatient,
          },
        },
      })
    );
  };

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.salesReport?.startDate ? tableSearch[fieldPathname]?.salesReport?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.salesReport?.endDate ? tableSearch[fieldPathname]?.salesReport?.endDate : new Date());
    setSelectedStaff(tableSearch[fieldPathname]?.salesReport?.selectedStaff ? tableSearch[fieldPathname]?.salesReport?.selectedStaff : []);
    setSelectedOptPatient(tableSearch[fieldPathname]?.salesReport?.selectedOptPatient ? tableSearch[fieldPathname]?.salesReport?.selectedOptPatient : []);

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.salesReport?.startDate || 
      tableSearch[fieldPathname]?.salesReport?.endDate ||
      tableSearch[fieldPathname]?.salesReport?.selectedStaff || 
      tableSearch[fieldPathname]?.salesReport?.selectedOptPatient
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    getStaff().then((res) => {
      let data = res.filter((e) => !e?.role?.includes("superAdmin"));
      setUsers(data);
    });
  }, []);

  useEffect(() => {
    getSalesReport({
      startDate: startDate,
      endDate: endDate,
      staff: selectedStaff,
      patient: selectedOptPatient.map((item) => item.value),
    }).then((res) => {
      if (res?.result?.data) {
        setTags(res.result.data.tags);
        setOverallTotal(res.result.data.overallTotal);
        setPaymentDoctors(res.result.data.paymentDoctors);
      }
    });
  }, [refetch]);

  const renderCell = (isTotal, value) => {
    return <span className={isTotal ? "fw-bold" : ""}>{value}</span>;
  };

  const getColumnDoctors = () => {
    return [
      {
        name: "Date",
        selector: (row) => row.date,
        sortable: true,
        sortFunction: (a, b) => customDateSort(a, b, "date"),
        width: "125px",
        cell: (row) =>
          renderCell(row.isTotal, row.isTotal ? "Total" : formatDate(row.date)),
      },
      {
        name: "Visit",
        selector: (row) => row.patient,
        width: "80px",
        cell: (row) => renderCell(row.isTotal, row.patient),
      },
      {
        name: "Sales",
        selector: (row) => row.totalAmount,
        width: "130px",
        cell: (row) =>
          renderCell(
            row.isTotal,
            thousandAndDecimalFormat(row.totalAmount, true, 2)
          ),
      },
      {
        name: "GST",
        selector: (row) => row.totalGst,
        width: "130px",
        cell: (row) =>
          renderCell(
            row.isTotal,
            thousandAndDecimalFormat(row.totalGst, true, 2)
          ),
      },
      ...(tags ? tags : []).map((tag) => ({
        name: tag.name,
        selector: (row) => row[tag.name],
        width: "130px",
        cell: (row) =>
          renderCell(
            row.isTotal,
            thousandAndDecimalFormat(row[tag.name], true, 2)
          ),
      })),
    ];
  };

  const getDataDoctors = (payments) => {
    return [
      ...payments.map((payment, index) => {
        const returnObject = {
          key: index,
          date: payment.date,
          patient: payment.patient,
          totalAmount: payment.totalAmount,
          totalGst: payment.totalGst,
          isTotal: payment.isTotal,
        };

        (tags ? tags : []).map((tag) => {
          returnObject[tag.name] = payment[tag.name];
          return false;
        });

        return returnObject;
      }),
    ];
  };

  const columnOverall = [
    {
      name: "Category",
      selector: (row) => row.category,
      width: "200px",
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      width: "200px",
    },
  ];

  const dataOverall = [
    ...(tags ? tags : []).map((tag) => {
      return {
        category: tag.name,
        totalAmount: thousandAndDecimalFormat(
          overallTotal[tag.name] ? overallTotal[tag.name] : 0,
          true,
          2
        ),
      };
    }),
  ];

  const exportCsv = () => {
    const rows = [];

    paymentDoctors.map((doctor, index) => {
      if (index === 0) {
        rows.push({
          [`${doctor.doctorName}`]: "",
        });
      } else {
        rows.push({
          "": doctor.doctorName,
        });
      }

      const header = {
        Date: "Date",
        Visit: "Visit",
        Sales: "Sales",
        GST: "GST",
      };

      (tags ? tags : []).map((tag) => {
        header[tag.name] = tag.name;
        return false;
      });

      rows.push(header);

      doctor.payments.map((payment) => {
        const objectP = {
          Date: payment.isTotal ? "Total" : formatDate(payment.date),
          Visit: payment.patient,
          Sales: payment.totalAmount,
          GST: payment.totalGst,
        };

        (tags ? tags : []).map((tag) => {
          objectP[tag.name] = payment[tag.name];
          return false;
        });

        rows.push(objectP);
        return false;
      });

      // for space between doctors
      rows.push({});
      rows.push({});
      return false;
    });

    rows.push({
      "": "Summary",
    });

    rows.push({
      1: "Total Visit",
      2: overallTotal.patient,
    });

    rows.push({
      1: "Total Sales",
      2: overallTotal.totalAmount,
    });

    rows.push({
      1: "Total GST",
      2: overallTotal.totalGst,
    });

    rows.push({});
    rows.push({});

    rows.push({
      Category: "Category",
      "Total Amount": "Total Amount",
    });

    (tags ? tags : []).map((tag) => {
      rows.push({
        Category: tag.name,
        "Total Amount": overallTotal[tag.name] ? overallTotal[tag.name] : 0,
      });

      return false;
    });

    // Function to convert data to CSV format
    const convertToCSV = (data) => {
      var csv = "";
      // Add headers to CSV
      csv += Object.keys(data[0]).join(",") + "\n";
      // Add rows to CSV
      data.forEach(function (row) {
        csv += Object.values(row).join(",") + "\n";
      });
      return csv;
    };

    // Convert data to CSV format
    var csvContent = convertToCSV(rows);
    downloadCustomCsv(csvContent, "Sales Report");
  };

  return (
    <div className="p-0 sticky-table">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          paddingTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FilterWrapper>
            <div className="d-flex">
              <Form.Group className="me-3" style={{ width: "200px" }}>
                <Form.Label className="headings2" style={{ color: "#000" }}>
                  Filter by Staff
                </Form.Label>
                <MultiSelectComponent
                  searchEnabled
                  array={selectedStaff}
                  options={
                    users?.length > 0
                      ? [
                          ...users
                            .filter(
                              (u) => u.departmentName.toLowerCase() === "doctor"
                            )
                            .map((item) => {
                              return {
                                label: item.username,
                                value: item._id,
                              };
                            }),
                        ]
                      : []
                  }
                  onChange={(e) => {
                    setSelectedStaff(e);
                  }}
                />
              </Form.Group>

              <Form.Group className="me-3" style={{ width: "200px" }}>
                <Form.Label className="headings2" style={{ color: "#000" }}>
                  Filter by Patient
                </Form.Label>
                <AsyncMultiSelect
                  placeholder="Select"
                  value={selectedOptPatient}
                  setValue={(value) => {
                    setSelectedOptPatient(value);
                  }}
                  loadOptions={(search, loadedOptions, { page }) =>
                    loadOptionPatients(search, loadedOptions, { page })
                  }
                  loadSelectedOption={loadSelectedPatient2}
                />
              </Form.Group>

              <div className="me-3" style={{ width: "180px" }}>
                <DateInput
                  value={startDate}
                  label="Start Date"
                  onChange={(e) => {
                    setStartDate(e);
                  }}
                />
              </div>
              <div className="me-3" style={{ width: "180px" }}>
                <DateInput
                  value={endDate}
                  label="End Date"
                  onChange={(e) => {
                    setEndDate(e);
                  }}
                />
              </div>
            </div>
            <div className="btn-wrapper" style={{ marginTop: "0px" }}>
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(
                    startDate,
                    endDate,
                    selectedStaff,
                    selectedOptPatient
                  );
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button className="addItem" onClick={() => exportCsv()}>
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        {paymentDoctors.map((doctor, index) => (
          <div key={index} style={{ marginBottom: "25px" }}>
            <p className="font-16 fw-bold">Doctor Name : {doctor.doctorName}</p>

            <StyledDataTable
              columns={getColumnDoctors()}
              data={getDataDoctors(doctor.payments)}
              pagination={false}
              fixedHeader={true}
              customStyles={customStyles}
              conditionalRowStyles={conditionalRowStyles}
            />
          </div>
        ))}
      </div>

      <div>
        <p className="font-16 fw-bold" style={{ textDecoration: "underline" }}>
          SUMMARY
        </p>
        <div className="grid-summary font-16 fw-bold">
          <div>Total Visits</div>
          <div>:</div>
          <div className="text-right">{overallTotal.patient}</div>

          <div>Total Sales</div>
          <div>:</div>
          <div className="text-right">
            {thousandAndDecimalFormat(overallTotal.totalAmount, true, 2)}
          </div>

          <div>Total GST</div>
          <div>:</div>
          <div className="text-right">
            {thousandAndDecimalFormat(overallTotal.totalGst, true, 2)}
          </div>
        </div>

        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
          <DataTable
            columns={columnOverall}
            data={dataOverall}
            pagination={false}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
    </div>
  );
}

export default SalesReport;
