import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getSessions } from "../../../apis/session";
import { toastify } from "../../../helperFunctions/toastify";

export default function TabVisitHistory() {
	const patientData = useSelector((state) => state.patientData.value);
	const [sessions, setSessions] = useState([]);
	const [remarksData, setRemarksData] = useState([]);
	const [showRemarks, setShowRemarks] = useState(false);
	const [referData, setReferData] = useState([]);
	const [showRefer, setShowRefer] = useState(false);

	const navigate = useNavigate();

	const getSessionsList = async () => {
		const response = await getSessions();
		if (response?.status !== "Fail") {
			let _response = response.filter(
				(e) => patientData?._id === e?.appointment?.patient?._id,
			);

			setSessions(_response);

			console.log({ _response });

			_response.forEach((element) => {
				let remData = [...remarksData];
				let refData = [...referData];

				remData.push({
					remarks: element.appointment.remarks,
					date: element.date,
				});
				setRemarksData(remData);

				refData.push({
					referral:
						element?.envoiceId?.recommend?.patientProfile?.fullName,
					date: element.date,
				});
				setReferData(refData);
			});
		} else {
			if (response?.message === "No data Found") {
				setSessions([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	useEffect(() => {
		getSessionsList();
	}, []);

	const columns = [
		{
			name: "Visit Date",
			selector: (row) => new Date(row.visitDate).toLocaleDateString(),
			wrap: true,
			// width: "180px",
		},
		// {
		//     name: "Packages",
		//     selector: (row) => row.packages.name,
		//     wrap: true,
		//     width: "180px",
		// },
		{
			name: "Products",
			selector: (row) => row.products.map((e) => <div>{e?.name}</div>),
			wrap: true,
			// width: "180px",
		},
		// {
		//     name: "Treatments",
		//     selector: (row) => row.treatments.map((e) => <div>{e?.name}</div>),
		//     wrap: true,
		//     width: "180px",
		// },
		{
			name: "Staff",
			selector: (row) => row.staff.map((e) => <div>{e?.username}</div>),
			// width: "180px",
		},

		{
			name: "Total Charges",
			selector: (row) => row.total,
			// width: "160px",
		},
	];

	const data = sessions?.map((e, index) => {
		return {
			index: index,
			id: e._id,
			visitDate: e?.date,
			// packages: e.appointment.package,
			products: e.appointment.products,
			// treatments: e.appointment.treatments,
			staff: e.appointment.staff,
			total: "N/A",
		};
	});

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				borderWidth: "0px",
				backgroundColor: "#f8f8f8",
			},
		},
	];

	return (
		<div>
			{/* <div className="d-flex justify-content-end flex-row mb-3">
				<Button className="visit-hist-buttons">Dispense History</Button>
				<Button
					disabled={sessions.length === 0}
					className="visit-hist-buttons mx-3"
					onClick={() => setShowRemarks(true)}
				>
					Visit Remarks History
				</Button>
				<Button
					disabled={sessions.length === 0}
					className="visit-hist-buttons"
					onClick={() => setShowRefer(true)}
				>
					Visit Referral Source History
				</Button>
			</div> */}

			<Modal
				centered
				show={showRemarks}
				onHide={() => setShowRemarks(false)}
			>
				<Modal.Header>
					<div className="font-18 bold">Remarks History</div>
				</Modal.Header>
				<Modal.Body className="">
					{remarksData?.map((e) => (
						<div>
							{`${e?.remarks} - ${new Date(
								e?.date,
							).toLocaleDateString()}`}
						</div>
					))}
				</Modal.Body>
			</Modal>

			<Modal centered show={showRefer} onHide={() => setShowRefer(false)}>
				<Modal.Header>
					<div className="font-18 bold">Referral History</div>
				</Modal.Header>
				<Modal.Body className="">
					{referData?.map((e) => (
						<div>
							{`${e?.referral} - ${new Date(
								e?.date,
							).toLocaleDateString()}`}
						</div>
					))}
				</Modal.Body>
			</Modal>

			<DataTable
				columns={columns}
				customStyles={customStyles}
				data={data || []}
				pagination
				highlightOnHover
				pointerOnHover
				conditionalRowStyles={conditionalRowStyles}
			/>
		</div>
	);
}
