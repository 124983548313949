import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getTopSpenderReport } from "../../../apis/customTemplate";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import { downloadCsv } from "../../../helperFunctions/utils";
import { setTableSearch } from "../../../redux/slices/commonSlice";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function TopSpenderReport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.topSpender?.startDate ? tableSearch[fieldPathname]?.topSpender?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.topSpender?.endDate ? tableSearch[fieldPathname]?.topSpender?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (valueStartDate, valueEndDate) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
        topSpender: {
          startDate: valueStartDate,
          endDate: valueEndDate,
        }
			}
		}))
  }

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.topSpender?.startDate ? tableSearch[fieldPathname]?.topSpender?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.topSpender?.endDate ? tableSearch[fieldPathname]?.topSpender?.endDate : new Date());

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.topSpender?.startDate || 
      tableSearch[fieldPathname]?.topSpender?.endDate
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    getTopSpenderReport({
      startDate: startDate,
      endDate: endDate,
    }).then((res) => {
      if (res?.result?.data) {
        setFilteredData(res.result.data);
      }
    });
  }, [refetch]);


  const columns = [
    {
      name: "Account No",
      selector: (row) => row.accountNo,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Contact No",
      selector: (row) => row.contactNo,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "Total Amount",
      selector: (row) => row.totalAmount,
      sortable: true,
    },
  ];

  const data = filteredData?.map(
    (e, index) => {
      return {
        ...e,
        index,
        key: index,
      };
    }
  );

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data.map((row) => {
      return {
        "Account No": row.accountNo,
        Name: row.name,
        "Contact No": row.contactNo,
        Email: row.email,
        Gender: row.gender,
        "Total Amount": row.totalAmount,
      };
    });

    downloadCsv(csvData, "Top Spender Report");
  }


  return (
    <div className="p-0">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "18px",
          }}
        >
          <FilterWrapper className="d-flex">
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;

                  }
                  onReduxFilter(startDate, endDate);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>
              <Button
                className="addItem"
                onClick={() => exportCsv()}
              >
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default TopSpenderReport;
