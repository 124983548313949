import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
};

export const counterSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    login: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { login } = counterSlice.actions;

export default counterSlice.reducer;
