import api from "../axios/axios-config";

export const getMemos = async (data) => {
  try {
    let response = await api.post("memo/listOfMemoByPatioentId", data);
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createMemos = async (data) => {
  try {
    let response = await api.post("memo/createMemo", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateMemo = async (dataId, data) => {
  try {
    let response = await api.post("memo/updateMemoByPatioentId", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
