import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import styled from "styled-components";

const CheckboxWrapper = styled.div`
  .form-check, .question-input {
    display: inline-block;
  }

  .question-input {
    width: 300px;
    margin-left: 20px;
  }

  .label-required label {
    color: #F44336;
  }
`;

export default function CheckboxQuestion({ question, grpQuestion, onChange, isSubmit }) {
	return (
		<Col className="mb-3" md={question.size ? question.size : 12} key={question.question}>
			<div className="question-header" style={{marginBottom: "15px"}}>
        {question?.question}
      </div>
      {question?.checkboxOptions?.map((checkbox, idx) => (
        <CheckboxWrapper>
          <Form.Check
            className={(isSubmit === true && checkbox.isRequired === true && question.patient_answer?.filter(x => x.value === checkbox.name).length === 0) ? "label-required" : ""}
            key={idx}
            type={"checkbox"}
            label={checkbox.isRequired ? `${checkbox.name}*` : checkbox.name}
            checked={question.patient_answer?.find(x => x.value === checkbox.name)}
            onChange={() => onChange(question._id, checkbox.name, question?.questionType, grpQuestion, checkbox.name, "value")}
          />

          {
            (checkbox.needRemark === true && question.patient_answer?.filter(x => x.value === checkbox.name).length > 0) && (
              <Form.Control
                as="input"
                placeholder="Remark"
                value={question.patient_answer?.find(x => x.value === checkbox.name)?.remark ?? ""}
                onChange={(e) => {
                  onChange(
                    question._id,
                    e?.target?.value,
                    question?.questionType,
                    grpQuestion,
                    checkbox.name,
                    "remark"
                  );
                }}
                className="question-input"
                style={{marginBottom: "10px"}}
              />
            )
          }
        </CheckboxWrapper>
      ))}
  </Col>
	);
}
