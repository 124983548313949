import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tableSearch: {},
  tablePaginate: {},
  formData: {},
};

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState,
  reducers: {
    setTableSearch: (state, action) => {
      state.tableSearch = action.payload;
    },
    setTablePaginate: (state, action) => {
      state.tablePaginate = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
    },
  },
});

export const { setTableSearch, setTablePaginate, setFormData } = commonSlice.actions;

export default commonSlice.reducer;
