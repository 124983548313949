import api from "../axios/axios-config";

export const getPromo = async (data) => {
  try {
    let response = await api.get("promoCode/listOfPromoCode");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createPromo = async (data) => {
  try {
    let response = await api.post("promoCode/createPromoCode", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updatePromo = async (dataId, data) => {
  console.log({ dataId, data });
  try {
    let response = await api.post("promoCode/updatePromoCode", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
