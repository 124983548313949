import React, { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

export default function YesNo({ question, grpQuestion, onChange }) {
	const [answer, setAnswer] = useState();

	return (
		<Col className="mb-3" md={question.size ? question.size : 12} key={question.question}>
			<div>
				<div className="question-header">
					{question?.question}
				</div>
				<div className="pt-2">
					<Form.Check
						inline
						label="Yes"
						type={"radio"}
						onClick={() => {
							onChange(
								question._id,
								"Yes",
								question?.questionType,
								grpQuestion
							);
						}}
						checked={question.patient_answer === "Yes"}
					/>
					<Form.Check
						inline
						label="No"
						type={"radio"}
						onClick={() => {
							onChange(
								question._id,
								"No",
								question?.questionType,
								grpQuestion
							);
						}}
						checked={question.patient_answer === "No"}
					/>
				</div>
			</div>
		</Col>
	);
}
