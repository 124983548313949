import { authorizedRequests } from "../axios/axios-config";

export const getApps = async () => {
  try {
    const response = await authorizedRequests({
      url: "common/listApps",
      method: "get",
    });
    return response?.result?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getAttendances = async (query) => {
  try {
    const response = await authorizedRequests({
      url: "common/listAttendances",
      method: "get",
      params: query
    });
    return response?.result?.data;
  } catch (error) {
    return error?.response?.data;
  }
}