import api from "../axios/axios-config";

export const getSetting = async () => {
  try {
    let response = await api.get("general/setting");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getExternalTemplate = async () => {
  try {
    let response = await api.get("externalDrugTemplate/listOfExternals");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateSetting = async (data) => {
  try {
    let response = await api.post(
      "general/updateSetting",
      {
        data,
      },
      
    );

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
