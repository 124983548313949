import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function ComponentWrapepr({
  children,
  moduleName,
  permissionType,
}) {
  const [isPermitted, setIsPermitted] = useState(false);
  const userData = useSelector((state) => state.userData.value);

  useEffect(() => {
    if (permissionType && moduleName && userData) {
      const shouldPermit = userData?.department?.permissions?.find(
        (e) => e.moduleName === moduleName
      );
      if (shouldPermit?.permissionsList?.indexOf(permissionType) !== -1) {
        setIsPermitted(true);
      } else {
        setIsPermitted(false);
      }
    } else {
      setIsPermitted(false);
    }
  }, [permissionType, userData, moduleName]);

  if (!isPermitted) {
    return <></>;
  }
  return <>{children}</>;
}
