import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./marketing-style.scss";

import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import BarGraph from "./BarGraph";

import RevenueReport from "./RevenueReport";
import SalesPaymentMethod from "./SalesPaymentMethod";
import MediaResponseReport from "./MediaResponseReport";

import SelectComponent from "../../SharedComponents/SelectComponent";
import { Button, Form } from "react-bootstrap";

export const filterTypes = [
  {
    label: "Date Range",
    value: "date",
  },
  {
    label: "By Month",
    value: "month",
  },
  {
    label: "By Quarter",
    value: "quarter",
  },
];
export const costFilterTypes = [
  {
    label: "KOL Sponsorship",
    value: "kolSponsorship",
  },
  {
    label: "sponsorship",
    value: "sponsorship",
  },
  {
    label: "KOL",
    value: "kol",
  },
];

const DailyConsolidatedSalesReport = () => {
  const [selectedTab, setSelectedTab] = useState("revenue");
  const [range, setRange] = useState({
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(),
  });
  const [filterType, setFilterType] = useState("date");
  const [selectedCostFilter, setSelectedCostFilter] =
    useState("kolSponsorship");
  return (
    <div className="marketing-reports-page min-vh-100">
      <div className="px-4 py-2">
        <div className="mainHeading">
          <h1>Dashboard / Daily Consolidated Sales Report</h1>
        </div>
      </div>
      <div className="page-header col-9 d-flex px-4 w-100">
        <div className="sections-tabs">
          <div
            className={`tab-item ${
              selectedTab === "revenue" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("revenue")}
          >
            Revenue
          </div>
          <div
            className={`tab-item ${
              selectedTab === "sales" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("sales")}
          >
            Sales Payment Method
          </div>
          <div
            className={`tab-item ${
              selectedTab === "mediaResponse" ? "active-tab" : ""
            }`}
            onClick={(e) => setSelectedTab("mediaResponse")}
          >
            Media Response
          </div>
        </div>
      </div>
      <div className="content-block">
        {selectedTab === "revenue" ? (
          <RevenueReport />
        ) : selectedTab === "sales" ? (
          <SalesPaymentMethod />
        ) : selectedTab === "mediaResponse" ? (
          <MediaResponseReport />
        ) : null}
      </div>
    </div>
  );
};

export default DailyConsolidatedSalesReport;
