import { authorizedRequests } from "../axios/axios-config";

export const getWaiting = async (params) => {
	let { result, error } = await authorizedRequests({
		url: "waitingList/listOfWaitings",
		method: "get",
		...params
	});
	if (error) return error;
	return result?.data;
};

export const editWaiting = async (data) => {
	const { result, error } = await authorizedRequests({
		url: "waitingList/updateWaitingList",
		method: "post",
		data: { ...data },
	});
	if (error) return error;
	return result?.data;
};
