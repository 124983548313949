import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
	allTemplates: [],
	allCollections: [],
	templateBeingEdited: {},
	previewTemplate: [],
	inPreview: false,
	newTemplate: {
		name: "",
		description: "",
		table_name: "",
		columns: [
			{
				name: "",
				column: null,
			},
		],
		total: false,
	},
};

export const templateSlice = createSlice({
	name: "templateSlice",
	initialState,
	reducers: {
		setTemplateToEdit: (state, action) => {
			state.templateBeingEdited = action.payload;
		},
		createNewTemplate: (state, action) => {
			const tempArr = [...state.allTemplates];
			tempArr.push(action.payload);
			state.allTemplates = _.cloneDeep(tempArr);
		},
		setPreviewTemplate: (state, action) => {
			state.previewTemplate = action.payload;
		},
		setAllCollections: (state, action) => {
			state.allCollections = action.payload;
		},
		setAllTemplates: (state, action) => {
			state.allTemplates = action.payload;
		},
		setNewTemplate: (state, action) => {
			state.newTemplate = action.payload;
		},
		setInPreview: (state, action) => {
			state.inPreview = action.payload;
		},
	},
});

export const { setTemplateToEdit, setInPreview, setAllCollections, setNewTemplate, setAllTemplates, createNewTemplate, setPreviewTemplate } =
	templateSlice.actions;

export default templateSlice.reducer;
