import styled from "styled-components";

export const PaymentWrapper = styled.div`
  background-color: white;

  .patient-detail {
    display: flex;

    > div {
      width: 500px;
      display: inline-block;
    }
  }

  .font-title {
    font-weight: 600;
  }

  .item-wrapper {
    display: flex;

    > div:first-child {
      display: inline-block;
      width: 70%;
    }

    > div:last-child {
      display: inline-block;
      width: 30%;
    }
  }

  .divider {
    padding: 10px 0px;
    border-top: 2px solid #ddd;
    margin-top: 20px;
  }
`;