import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URI,
});

export const adminLogin = async (username, password) => {
  try {
    let response = await api.post("auth/login", {
      username,
      password,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
