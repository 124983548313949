import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form, InputGroup } from "react-bootstrap";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchIcon } from "../../../assets/images/index";
import { getPatients } from "../../../apis/patients";
import { toastify } from "../../../helperFunctions/toastify";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { createInfluencer, updateInfluencer } from "../../../apis/influencers";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { createPromo, updatePromo } from "../../../apis/promos";
import { getTreatments } from "../../../apis/treatment";
import { getPackages } from "../../../apis/packages";
import { getProducts } from "../../../apis/products";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { setFormData } from "../../../redux/slices/commonSlice";

import moment from "moment";
import { getEditBy } from "../../../apis/appointment";

export default function AddEditPromo() {
  const dispatch = useDispatch();
  const locationRoute = useLocation();
  const formDataChangedRef = useRef();
  const { state } = locationRoute;
  const navigate = useNavigate();
  const numberRegex = new RegExp(`^[0-9]*$`);
  const percentRegex = new RegExp(
    `(0*100{1,1}\\.?((?<=\\.)0*)??$)|(^0*\\d{0,2}\\.?((?<=\\.)\\d*)??)$`
  );

  const [name, setName] = useState("");
  const [showED, setShowED] = useState(false);
  const [showED2, setShowED2] = useState(false);
  const [sValidity, setSValidity] = useState("");
  const [eValidity, setEValidity] = useState("");
  const [remarks, setRemarks] = useState("");
  const [usageLimit, setUsageLimit] = useState("");
  const [discountType, setDiscountType] = useState("");
  const [amount, setAmount] = useState("");
  const [treatments, setTreatments] = useState([]);
  const [packages, setPackages] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [treatmentsCheck, setTreatmentsCheck] = useState(false);
  const [packagesCheck, setPackagesCheck] = useState(false);
  const [productsCheck, setProductsCheck] = useState(false);

  const pathname = locationRoute.pathname;
  const { formData } = useSelector((state) => state.commonData);

  useEffect(() => {
    if (!state?.promo?._id) {
      let _sp = [];
      if (treatmentsCheck) _sp.push("treatment");
      if (packagesCheck) _sp.push("package");
      if (productsCheck) _sp.push("product");

      formDataChangedRef.current = {
        name,
        vaildStartDate: sValidity,
        vaildEndDate: eValidity,
        usageLimit,
        remarks,
        discountType,
        discountValue: amount,
        speacialities: _sp,
        packages: selectedPackages,
        treatments: selectedTreatments,
        products: selectedProducts,
      }
    }
  }, [name, sValidity, eValidity, usageLimit, remarks, discountType, amount, 
    selectedPackages, selectedTreatments, selectedProducts
  ]);

  useEffect(() => {
    return () => {
      if (!state?.promo?._id && formDataChangedRef.current) {
        dispatch(setFormData({
          ...formData,
          [pathname]: formDataChangedRef.current
        }))
      };
    }
  }, []);

  useEffect(() => {
    if (!state?.promo?._id) {
      populatePromo();
    }
  }, []);

  useEffect(() => {
    if (state?.promo) populatePromo();
    else populateData();
  }, []);

  const populateData = async () => {
    const responses = await Promise.all([
      getTreatments(),
      getPackages({ isDeleted: false }),
      getProducts(),
    ]);
    console.log("what is in responses", responses);
    setTreatments(responses[0] || []);
    setPackages(responses[1] || []);
    setProducts(responses[2] || []);
  };

  const savePromo = async () => {
    let _sp = [];
    if (treatmentsCheck) _sp.push("treatment");
    if (packagesCheck) _sp.push("package");
    if (productsCheck) _sp.push("product");

    if (!name) missingPopup("Name");
    else if (!sValidity) missingPopup("Validity Start Date");
    else if (!eValidity) missingPopup("Validity End Date");
    // else if (!usageLimit) missingPopup("Usage Limit");
    else if (!discountType) missingPopup("Discount Type");
    else if (!amount) missingPopup("Amount");
    else if (!amount) missingPopup("Amount");
    else if (!_sp.length > 0) toastify("Specialties are missing", "error");
    else if (treatmentsCheck && selectedTreatments?.length === 0)
      missingPopup("Treatment");
    else if (packagesCheck && selectedPackages.length === 0)
      missingPopup("Package");
    else if (productsCheck && selectedProducts.length === 0)
      missingPopup("Product");
    else {
      let data = {
        name,
        vaildStartDate: sValidity,
        vaildEndDate: eValidity,
        usageLimit,
        remarks,
        discountType,
        discountValue: amount,
        speacialities: _sp,
        packages: selectedPackages,
        treatments: selectedTreatments,
        products: selectedProducts,
      };
      if (!state?.promo) {
        const response = await createPromo(data);
        if (response?.status !== "Fail") {
          formDataChangedRef.current = null;
          dispatch(setFormData({
            ...formData,
            [pathname]: null
          }));
          toastify("Promo added successfully!", "success");
          navigate("/promo");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await updatePromo(state?.promo?._id, data);
        if (response?.status !== "Fail") {
          toastify("Promo updated successfully!", "success");
          navigate("/promo");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  const populatePromo = async () => {
    const responses = await Promise.all([
      getTreatments(),
      getPackages(),
      getProducts(),
    ]);

    setTreatments(responses[0] || []);
    setPackages(responses[1] || []);
    setProducts(responses[2] || []);

    let data = null;
    const reduxData = formData?.[pathname];
    if (reduxData && !state?.promo?._id) {
        data = {...reduxData};
    } else {
      data = state?.promo;
    }

    if (data) {
      setName(data.name);
      setSValidity(data.vaildStartDate ? new Date(data.vaildStartDate) : new Date());
      setEValidity(data.vaildEndDate ? new Date(data.vaildEndDate) : new Date());
      setUsageLimit(data.usageLimit);
      setRemarks(data?.remarks);
      setDiscountType(data.discountType);
      setAmount(data.discountValue);
      if (data.speacialities.includes("treatment")) setTreatmentsCheck(true);
      if (data.speacialities.includes("product")) setProductsCheck(true);
      if (data.speacialities.includes("package")) setPackagesCheck(true);
      
      if (!state?.promo?._id) {
        console.log("DEBUG", data)
        setSelectedPackages(data.packages);
        setSelectedProducts(data.products);
        setSelectedTreatments(data.treatments);
      } else {
        if (data.packages.length > 0) {
          let _packages = data.packages.map((e) => {
            return e._id;
          });
          setSelectedPackages(_packages);
        }
    
        if (data.products.length > 0) {
          let _products = data.products.map((e) => {
            return e._id;
          });
          setSelectedProducts(_products);
        }
    
        if (data.treatments.length > 0) {
          let _treatments = data.treatments.map((e) => {
            return e._id;
          });
          setSelectedTreatments(_treatments);
        }
      }
    }
  };

  const handleClickAway1 = () => {
    setShowED(false);
  };

  const handleClickAway2 = () => {
    setShowED2(false);
  };

  const getPackageName = (hash) => {
    console.log({ packages });
    return packages.find((e) => e._id === hash)?.name || "N/A";
  };

  const getProductName = (hash) => {
    console.log({ products });
    return products.find((e) => e._id === hash)?.name || "N/A";
  };

  const getTreatmentName = (hash) => {
    console.log({ treatments });
    return treatments.find((e) => e._id === hash)?.name || "N/A";
  };

  const [editLogs, setEditLogs] = useState();

  const getEditData = async () => {
    const response = await getEditBy({
      id: state?.promo?._id,
      type: "Promo",
    });
    setEditLogs(response);
  };

  useEffect(() => {
    getEditData();
  }, []);

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <div>
          <h1>
            <i
              className="fa fa-arrow-left cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            {`${state?.promo ? "Edit " : "New "}`}
            Packages/Promo
          </h1>
          {state?.promo && editLogs && (
            <div style={{ marginLeft: "5px" }}>
              {" "}
              By {editLogs?.userName} At{" "}
              {moment(editLogs?.created_at).format("DD/MM/YYYY hh:mm:ss")}
            </div>
          )}
        </div>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className=" d-flex flex-row">
              <div className="d-flex flex-column col-5">
                <Form.Group>
                  <Form.Label className="headings2">Name *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="d-flex flex-row ">
                  <div className="col-6">
                    <ClickAwayListener onClickAway={handleClickAway1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={sValidity}
                          onChange={(newValue) => {
                            setSValidity(newValue?.$d);
                            setShowED(false);
                          }}
                          componentsProps={{
                            actionBar: { actions: ["today"] },
                          }}
                          open={showED}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Form.Group className="col-11">
                              <Form.Label className="headings2">
                                Validity Date *
                              </Form.Label>
                              <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                <Form.Control
                                  ref={inputRef}
                                  // {...inputProps}
                                  value={
                                    sValidity
                                      ? new Date(sValidity).toLocaleDateString(
                                          "en-GB",
                                          {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                          }
                                        )
                                      : null
                                  }
                                  readOnly
                                  onClick={() => setShowED(!showED)}
                                  placeholder="Start Date"
                                />
                                <div onClick={() => setShowED(!showED)}>
                                  {InputProps?.endAdornment}
                                </div>
                              </InputGroup>
                            </Form.Group>
                          )}
                        />
                      </LocalizationProvider>
                    </ClickAwayListener>
                  </div>

                  <div className="col-6">
                    <ClickAwayListener onClickAway={handleClickAway2}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={eValidity}
                          onChange={(newValue) => {
                            setEValidity(newValue?.$d);
                            setShowED2(false);
                          }}
                          componentsProps={{
                            actionBar: { actions: ["today"] },
                          }}
                          open={showED2}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Form.Group className="col-12">
                              <Form.Label className="headings2 color-white">
                                Validity End *
                              </Form.Label>
                              <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                <Form.Control
                                  ref={inputRef}
                                  // {...inputProps}
                                  value={
                                    eValidity
                                      ? new Date(eValidity).toLocaleDateString(
                                          "en-GB",
                                          {
                                            day: "numeric",
                                            month: "numeric",
                                            year: "numeric",
                                          }
                                        )
                                      : null
                                  }
                                  onClick={() => setShowED2(!showED2)}
                                  readOnly
                                  placeholder="End Date"
                                />
                                <div onClick={() => setShowED2(!showED2)}>
                                  {InputProps?.endAdornment}
                                </div>
                              </InputGroup>
                            </Form.Group>
                          )}
                        />
                      </LocalizationProvider>
                    </ClickAwayListener>
                  </div>
                </Form.Group>

                {/* <Form.Group className="">
                  <Form.Label className="headings2">Usage Limit *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => {
                        if (
                          numberRegex.test(e.target.value) ||
                          e.target.value === ""
                        ) {
                          setUsageLimit(e.target.value);
                        }
                      }}
                      value={usageLimit}
                    />
                  </InputGroup>
                </Form.Group> */}

                <Form.Group className="">
                  <Form.Label className="headings2">Remarks</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      as="textarea"
                      style={{ resize: "none" }}
                      className="large-text-area"
                      onChange={(e) => setRemarks(e.target.value)}
                      value={remarks}
                    />
                  </InputGroup>
                </Form.Group>

                <Form.Group className="">
                  <Form.Label className="headings2">Discount Type *</Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={discountType}
                    options={[
                      {
                        label: "Percentage",
                        value: "Percentage",
                      },
                      {
                        label: "Exact Value",
                        value: "Exact Value",
                      },
                    ]}
                    onChange={(e) => {
                      setDiscountType(e.value);
                      setAmount("");
                    }}
                  />
                  {/* <select
                    className="form-select"
                    onChange={(e) => {
                      setDiscountType(e.target.value);
                      setAmount("");
                    }}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    <option
                      value="Percentage"
                      selected={discountType === "Percentage"}
                    >
                      Percentage
                    </option>
                    <option
                      value="Exact Value"
                      selected={discountType === "Exact Value"}
                    >
                      Exact Value
                    </option>
                  </select> */}
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Amount *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      disabled={discountType === ""}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      value={amount}
                    />
                  </InputGroup>
                </Form.Group>
              </div>

              <span className="col-1" />

              <div className="col-5">
                <Form.Group className="mt-3">
                  <Form.Label className="headings2">Specialties *</Form.Label>

                  <div className="d-flex align-items-center abc">
                    <Form.Check
                      type="checkbox"
                      label={
                        <div className="font-14 unbold me-5">Treatment</div>
                      }
                      onChange={(e) => {
                        console.log(
                          "what is Treatment check point",
                          e.target.checked
                        );
                        if (!e.target.checked) setSelectedTreatments([]);
                        setTreatmentsCheck(e.target.checked);
                      }}
                      checked={treatmentsCheck}
                    />

                    <Form.Check
                      type="checkbox"
                      className="p-0"
                      label={<div className="font-14 unbold me-5">Package</div>}
                      onChange={(e) => {
                        if (!e.target.checked) setSelectedPackages([]);
                        setPackagesCheck(e.target.checked);
                      }}
                      checked={packagesCheck}
                    />

                    <Form.Check
                      type="checkbox"
                      className="p-0"
                      label={
                        <div className="font-14 unbold me-5">Products</div>
                      }
                      onChange={(e) => {
                        if (!e.target.checked) setSelectedProducts([]);
                        setProductsCheck(e.target.checked);
                      }}
                      checked={productsCheck}
                    />
                  </div>
                </Form.Group>

                <div
                  className={`light-gray-background ${
                    treatmentsCheck || packagesCheck || productsCheck
                      ? "p-3"
                      : ""
                  }`}
                >
                  {treatmentsCheck && (
                    <Form.Group>
                      <Form.Label className="headings2">Treatment</Form.Label>
                      <select
                        className="form-select light-gray-background"
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            if (!selectedTreatments.includes(e.target.value)) {
                              let arr = [...selectedTreatments];
                              arr.push(e.target.value);
                              setSelectedTreatments(arr);
                            }
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {treatments?.map((e) => (
                          <option value={e._id}>{e.name}</option>
                        ))}
                      </select>

                      <div className="d-flex mb-3 flex-wrap">
                        {selectedTreatments?.map((hash) => (
                          <span
                            className="multi-select"
                            onClick={() => {
                              let arr = [...selectedTreatments].filter(
                                (e) => !e.includes(hash)
                              );
                              setSelectedTreatments(arr);
                            }}
                          >
                            {getTreatmentName(hash)}
                            <i
                              class="fa-solid fa-xmark ms-3"
                              style={{
                                color: "red",
                              }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </Form.Group>
                  )}

                  {packagesCheck && (
                    <Form.Group className="mt-3">
                      <Form.Label className="headings2">Package</Form.Label>
                      <select
                        className="form-select light-gray-background"
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            if (!selectedPackages.includes(e.target.value)) {
                              let arr = [...selectedPackages];
                              arr.push(e.target.value);
                              setSelectedPackages(arr);
                            }
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {packages.map((e) => (
                          <option value={e._id}>{e.name}</option>
                        ))}
                      </select>

                      <div className="d-flex mb-3 flex-wrap">
                        {selectedPackages?.map((hash) => (
                          <span
                            className="multi-select"
                            onClick={() => {
                              let arr = [...selectedPackages].filter(
                                (e) => !e.includes(hash)
                              );
                              setSelectedPackages(arr);
                            }}
                          >
                            {getPackageName(hash)}
                            <i
                              class="fa-solid fa-xmark ms-3"
                              style={{
                                color: "red",
                              }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </Form.Group>
                  )}

                  {productsCheck && (
                    <Form.Group className="mt-3">
                      <Form.Label className="headings2">Products</Form.Label>
                      <select
                        className="form-select light-gray-background"
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            if (!selectedProducts.includes(e.target.value)) {
                              let arr = [...selectedProducts];
                              arr.push(e.target.value);
                              setSelectedProducts(arr);
                            }
                          }
                        }}
                      >
                        <option value="" disabled selected>
                          Select
                        </option>
                        {products.map((e) => (
                          <option value={e._id}>{e.name}</option>
                        ))}
                      </select>

                      <div className="d-flex mb-3 flex-wrap">
                        {selectedProducts?.map((hash) => (
                          <span
                            className="multi-select"
                            onClick={() => {
                              let arr = [...selectedProducts].filter(
                                (e) => !e.includes(hash)
                              );
                              setSelectedProducts(arr);
                            }}
                          >
                            {getProductName(hash)}
                            <i
                              class="fa-solid fa-xmark ms-3"
                              style={{
                                color: "red",
                              }}
                            ></i>
                          </span>
                        ))}
                      </div>
                    </Form.Group>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button className="addItem" onClick={() => savePromo()}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
