import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ListItemText, MenuItem } from "@mui/material";
import { AsyncPaginate } from "react-select-async-paginate";

const AsyncMultiSelect = ({
  placeholder,
  value,
  setValue,
  loadOptions,
  loadSelectedOption,
  disabled,
}) => {
  const selectStyles = {
    input: (styles) => ({
      ...styles,
      margin: "3px",
      height: 31,
      zIndex: "1000",
    }),
    control: (styles) => ({
      ...styles,
      border: "1px solid #939393",
      borderRadius: "7px",
      outline: "none",
      height: "fit-content",
      minHeight: "47px",
      zIndex: "1",
    }),
    placeholder: (styles) => ({
      ...styles,
      fontSize: 16,
    }),
    menu: (styles) => ({
      ...styles,
      zIndex: 1001,
    }),
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (isMenuOpen) {
      const elDropdown = document.querySelectorAll(
        '[id^="react-select-"][id$="-listbox"]'
      );
      if (elDropdown.length > 0) {
        const spaceAbove = elDropdown[0].getBoundingClientRect().top;
        const spaceBelow =
          window.innerHeight -
          elDropdown[0].getBoundingClientRect().bottom -
          100;

        if (
          spaceBelow >= elDropdown[0].clientHeight ||
          spaceBelow >= spaceAbove
        ) {
          elDropdown[0].classList.add("dropdown-style");
          elDropdown[0].classList.add("dropdown-downward");
          elDropdown[0].classList.remove("dropdown-upward");
        } else {
          elDropdown[0].classList.add("dropdown-style");
          elDropdown[0].classList.add("dropdown-upward");
          elDropdown[0].classList.remove("dropdown-downward");
        }
      }
    }
  }, [isMenuOpen]);

  useEffect(() => {
    loadSelectedOption(value ?? [])?.then((res) => {
      console.log(res);
      setSelectedValues(res);
    });
  }, [value]);

  const customOption = ({ innerProps, label, data }) => {
    return (
      <MenuItem {...innerProps} value={data.value}>
        <ListItemText primary={label} />
      </MenuItem>
    );
  };

  const handleSelectChange = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);

    // If "all" is selected, remove all other selections and set only "all"
    if (selectedValues.includes("all")) {
      setValue([{ label: "Select", value: "all" }]);
      setSelectedValues([{ label: "Select", value: "all" }]);
    } else {
      // If any other option is selected, remove "all" from the selection
      const filteredOptions = selectedOptions.filter(
        (option) => option.value !== "all"
      );
      setValue(filteredOptions);
      setSelectedValues(filteredOptions);
    }
  };

  return (
    <div className="select-async">
      <AsyncPaginate
        value={selectedValues}
        placeholder={placeholder}
        loadOptions={loadOptions}
        onChange={handleSelectChange}
        menuIsOpen={isMenuOpen}
        onMenuOpen={() => setIsMenuOpen(true)}
        onMenuClose={() => setIsMenuOpen(false)}
        additional={{
          page: 1,
        }}
        components={{
          Option: customOption,
        }}
        isMulti
        disabled={disabled}
        styles={selectStyles}
      />
    </div>
  );
};

AsyncMultiSelect.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array, // Changed to array
  setOpenModal: PropTypes.func,
  setValue: PropTypes.func,
  loadOptions: PropTypes.func,
  loadSelectedOption: PropTypes.func,
  disabled: PropTypes.bool,
};

AsyncMultiSelect.defaultProps = {
  label: "",
  placeholder: "",
  setOpenModal: () => {},
  setValue: () => {},
  loadOptions: () => {},
  loadSelectedOption: () => {},
  disabled: false,
};

export default AsyncMultiSelect;
