import React, { useState, useEffect } from "react";
import { Accordion, Form, ListGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getBillingByPatient,
  getBillingByStaffId,
} from "../../../apis/billing";
import { getDepartments } from "../../../apis/departments";
import { getMembershipCategories } from "../../../apis/membership";
import { getSessionByPatientId } from "../../../apis/session";
import {
  getNurseNotes,
  getPatientNotes,
  updateNote,
} from "../../../apis/notes";
import { getPatients } from "../../../apis/patients";
import { getStaffByDepartment } from "../../../apis/staff";
import { toastify } from "../../../helperFunctions/toastify";
import DeleteModal from "../../SharedComponents/DeleteModal";
import SelectComponent from "../../SharedComponents/SelectComponent";
import NoteRenderer from "./NoteRenderer";
import { DOCTOR_DEPARTMENT_ID, NURSE_DEPARTMENT_ID } from "../../../constants";
import TabNurseNotes from "../Patient/TabNurseNotes";
import TabNutrionNotes from "../Patient/TabNutrionNotes";
import AsyncSelect from "../../SharedComponents/AsyncSelect";
import {
  loadOptionPatients,
  loadSelectedPatient,
} from "../../../apis/patients";
import { getMedicalInfo } from "../../../apis/medicalInfo";
import { setTableSearch } from "../../../redux/slices/commonSlice";

function PatientNotesSelected(props) {
	const dispatch = useDispatch();
  const location = useLocation();
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

	const { tableSearch } = useSelector((state) => state.commonData);

  const [notes, setNotes] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(tableSearch[fieldPathname]?.patient ? tableSearch[fieldPathname]?.patient : null);
  // const { state } = useLocation();
  const userData = useSelector((state) => state.userData.value);

  const [usePointVal, setUsePointVal] = useState(0);
  // const patientData = useSelector((state) => state.patientData.value);
  const [patientData, setPatientData] = useState();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(tableSearch[fieldPathname]?.patient?._id ? tableSearch[fieldPathname]?.patient?._id : "");
  const [ptData, setPtData] = useState();
  const navigate = useNavigate();

  console.log("DEBUG", tableSearch, selectedPatient, selectedOption)

  const onSubmitRedux = (value) => {
		dispatch(setTableSearch({
		  ...tableSearch,
			[fieldPathname]: {
				...tableSearch[fieldPathname],
        patient: value
			}
		}))
  }


  const getPatientNotesList = async (id) => {
    let typeNote = "";
    if (props?.selectedRole == "THERAPIST") {
      typeNote = "Therapist";
    }
    if (props?.selectedRole == "NUTRITIONIST") {
      typeNote = "Nutritionist";
    }

    const response = await getPatientNotes(id, typeNote);
    if (response.status !== "Fail") {
      setNotes(response);
    } else if (response?.message === "No data Found") {
      setNotes([]);
    } else {
      toastify(response?.message, "error");
    }
  };

  // const getNurseNotesList = async (id) => {
  // 	const response = await getNurseNotes(id);
  // 	if (response.status !== "Fail") {
  // 		setNotes(response);
  // 	} else if (response?.message === "No data Found") {
  // 		setNotes([]);
  // 	} else {
  // 		toastify(response?.message, "error");
  // 	}
  // };
  // let response = await getPatientDetailsById(patientData?._id);
  //   if (response?.status !== "Fail") {
  //     setNotes(
  //       response?.nurseNotesList?.reverse()?.filter((e) => !e.isDeleted)
  //     );
  //   } else {
  //     toastify(response?.message, "error");
  //   }

  useEffect(() => {
    // getNotesList();
    populateMedicalInfo(tableSearch[fieldPathname]?.patient?._id);
  }, []);

  const populateMedicalInfo = async (id) => {
    const response = await getMedicalInfo(id);
    if (response?.status !== "Fail") {
      setPtData(response);
    }
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };
  const getPatientsDetails = async (id) => {
    const { result, error } = await getSessionByPatientId(id);
    if (error) {
      toastify(error.message, "error");
      return;
    }

    const tempArr = result?.data?.map((e) => {
        const historyObj = {
          medicalHistory: e.patientMedicalHistory?.pastMedicalHistory,
          cosmeticHistory: e.patientMedicalHistory?.pastCosmeticHistory,
          adverseCosmeticHistory:
            e.patientMedicalHistory?.pastAdverseCosmeticHistory,
          socialHistory: e.patientMedicalHistory?.socialHistory,
        };
        const loc = e?.branch?.location?.find((o) => o._id === e?.location);

        // const packages = e?.package?.map((o) => {
        //   return {
        //     name: o?.package_id?.name,
        //     qty: o?.package_qty,
        //     price: o?.package_unit_price * o?.package_qty,
        //     discount: o?.package_discount,
        //     total_price: o?.package_price,
        //   };
        // });

        // const product = e?.product?.map((o) => {
        //   return {
        //     name: o?.product_id?.name,
        //     qty: o?.product_qty,
        //     price: o?.product_unit_price * o?.product_qty,
        //     discount: o?.product_discount,
        //     total_price: o?.product_price,
        //   };
        // });
        // const treatment = e?.treatment?.map((o) => {
        //   return {
        //     name: o?.service_id?.name,
        //     qty: o?.service_qty,
        //     price: o?.service_unit_price * o?.service_qty,
        //     discount: o?.service_discount,
        //     total_price: o?.service_price,
        //   };
        // });

        // const others = e?.others?.map((o) => {
        //   return {
        //     name: o?.description,
        //     qty: 1,
        //     price: o?.total_price,
        //     discount: 0,
        //     total_price: o?.total_price,
        //   };
        // });

        let totalAmount = 0;
        const prescriptionDrugs = e?.patientPrescriptionSheet?.map((o) => {
          totalAmount +=
            o.type === "internal" ? o.quantity * o.unitPrice - o.discount : 0;

          return {
            name: o.drugName,
            price: o.unitPrice ? o.unitPrice : 0,
            qty: o.quantity,
            discount: o.discount ? o.discount : 0,
            total_price:
              o.type === "internal" ? o.quantity * o.unitPrice - o.discount : 0,
            instruction: o.instruction,
            totalDosage: o.totalDosage,
            dosage: o.dosage,
            period: o.period,
            periodDescription: o.periodDescription,
            takePeriod: o.takePeriod,
            takeType: o.takeType,
            type: o.type,
          };
        });

        // const taxVal =
        //   e?.tax?.typeValue == "Percentage"
        //     ? (e.tax?.value / 100) * e?.actualAmount
        //     : e?.tax?.value;
        const taxVal = e.prescriptionGSTAmount;
        const notesObj = {
          startTime: e?.startTime,
          endTime: e?.endTime,
          staff: e?.staff?.map((o) => o?.username),
          soap: e.patientSOAP,
          location: loc,
          service: [...prescriptionDrugs],
          remarks: e?.remarks,
          usePoints: e?.usePoints,
          promo: e?.promo,
          tax: e?.tax,
          promoDiscount: (e?.promo / 100) * e?.actualAmount,
          afterTaxValue: taxVal,
          actualAmount: e?.actualAmount,
          totalAmount: totalAmount,
        };
        return {
          _id: e._id,
          date: new Date(e.date).toLocaleDateString(),
          history: historyObj,
          notes: notesObj,
          patientMedicalHistory: e.patientMedicalHistory,
          // sessionId: e?.session?._id,
        };
      });

    tempArr.reverse();
    setPatientData([...tempArr]);
  };
  // const getDoctorNotesDetails = async (id) => {
  // 	const { result, error } = await getBillingByStaffId(id);
  // 	if (error) {
  // 		toastify(error?.message ?? "Failed to get doctor notes", "error");
  // 		return;
  // 	}
  // 	const tempArr = result?.data?.map((e) => {
  // 		const historyObj = {
  // 			medicalHistory:
  // 				e?.session?.patientMedicalHistory?.pastMedicalHistory,
  // 			cosmeticHistory:
  // 				e?.session?.patientMedicalHistory?.pastCosmeticHistory,
  // 			adverseCosmeticHistory:
  // 				e?.session?.patientMedicalHistory
  // 					?.pastAdverseCosmeticHistory,
  // 			socialHistory: e?.session?.patientMedicalHistory?.socialHistory,
  // 		};
  // 		const loc = e?.branch?.location?.find((o) => o._id === e?.location);

  // 		const packages = e?.package?.map((o) => {
  // 			return {
  // 				name: o?.package_id?.name,
  // 				qty: o?.package_qty,
  // 				price: o?.package_unit_price * o?.package_qty,
  // 				discount: o?.package_discount,
  // 				total_price: o?.package_price,
  // 			};
  // 		});
  // 		const product = e?.product?.map((o) => {
  // 			return {
  // 				name: o?.product_id?.name,
  // 				qty: o?.product_qty,
  // 				price: o?.product_id?.unitSellingPrice * o?.product_qty,
  // 				discount: o?.product_discount,
  // 				total_price:
  // 					o?.product_id?.unitSellingPrice * o?.product_qty -
  // 					o?.product_discount,
  // 			};
  // 		});
  // 		const treatment = e?.treatment?.map((o) => {
  // 			return {
  // 				name: o?.service_id?.name,
  // 				qty: o?.service_qty,
  // 				price: o?.service_unit_price * o?.service_qty,
  // 				discount: o?.service_discount,
  // 				total_price: o?.service_price,
  // 			};
  // 		});
  // 		const taxVal =
  // 			e?.tax?.typeValue == "Percentage"
  // 				? (e.tax?.value / 100) * e?.actualAmount
  // 				: e?.tax?.value;
  // 		const notesObj = {
  // 			startTime: e?.session?.startTime,
  // 			endTime: e?.session?.endTime,
  // 			staff: e?.session?.staff?.map((o) => o?.username),
  // 			soap: e?.session?.patientSOAP,
  // 			location: loc,
  // 			service: [...packages, ...product, ...treatment],
  // 			remarks: e?.remarks,
  // 			usePoints: e?.usePoints,
  // 			promo: e?.promo,
  // 			tax: e?.tax,
  // 			promoDiscount: (e?.promo / 100) * e?.actualAmount,
  // 			afterTaxValue: taxVal,
  // 			actualAmount: e?.actualAmount,
  // 			totalAmount: e?.totalAmount,
  // 		};
  // 		return {
  // 			date: new Date(e.created_at).toLocaleDateString(),
  // 			history: historyObj,
  // 			notes: notesObj,
  // 			// sessionId: e?.session?._id,
  // 		};
  // 	});

  // 	setPatientData([...tempArr]);
  // };

  useEffect(() => {
    if (tableSearch[fieldPathname]?.patient?._id) {
      getPatientsDetails(tableSearch[fieldPathname]?.patient?._id);
    }
  }, [pathname, tableSearch[fieldPathname]?.patient?._id]);

  const callDesiredAPI = (val) => {
    setSelectedOption(val);
    populateMedicalInfo(val);
    if (props?.selectedRole == "PATIENT" && val) {
      // getPatientsDetails(val);
      getPatientNotesList(val);
    } else if (props?.selectedRole == "DOCTOR") {
      // getPatientsDetails(val);

      // getDoctorNotesDetails(val);
    } else if (props?.selectedRole == "THERAPIST") {
      getPatientNotesList(val);

      // getNurseNotesList(val);
    } else if (props?.selectedRole == "NUTRITIONIST") {
      getPatientNotesList(val);
    }
  };
  const checkDate = (noteDate) => {
    let day1 = new Date().getDate();
    let day2 = new Date(noteDate).getDate();

    let month1 = new Date().getMonth();
    let month2 = new Date(noteDate).getMonth();

    let year1 = new Date().getFullYear();
    let year2 = new Date(noteDate).getFullYear();

    if (day1 === day2 && month1 === month2 && year1 === year2) return true;
    else return false;
  };

  const removeNote = async () => {
    let isSameDay = false;

    let ind = notes.find((e) => {
      if (deleting === e._id) {
        if (checkDate(e.created_at)) isSameDay = true;
        return e;
      }
    });

    setShow(false);

    if (ind?.staff?._id === userData._id) {
      if (isSameDay) {
        const response = await updateNote(deleting, {
          isDeleted: true,
        });

        if (response?.status !== "Fail") {
          toastify("Note deleted successfully!", "success");
          // getPatientNotes(selectedOption);
          getPatientNotesList(selectedOption);
        } else {
          toastify(response?.message, "error");
        }
      } else {
        toastify("Note can be deleted on the same day only.", "error");
      }
    } else {
      toastify("Only note maker can update/delete note.", "error");
    }
  };
  const onNoteSave = async (e, id, callback) => {
    if (!e) {
      toastify("Notes is missing", "error");
      return;
    }
    let response = await updateNote(id, { description: e });
    if (response?.status !== "Fail") {
      toastify("Note updated successfully!", "success");
      getPatientNotesList(selectedPatient?._id);

      callback(true);
    } else {
      toastify(response?.message);
      callback(false);
    }
  };
  useEffect(() => {
    setOptions([]);
    setNotes([]);
    setPatientData([]);
    setSelectedOption(tableSearch[fieldPathname]?.patient?._id ? tableSearch[fieldPathname]?.patient?._id : null);
    // if (props?.selectedRole == "PATIENT") {
    // (async () => {
    // 	const response = await getPatients();
    // 	if (response.status !== "Fail") {
    // 		const tempArr = response?.list?.map((o) => {
    // 			return {
    // 				value: o._id,
    // 				label: o.patientProfile?.fullName,
    // 			};
    // 		});
    // 		setOptions(tempArr);
    // 	} else {
    // 		if (response?.message === "No data Found") {
    // 			setOptions([]);
    // 		} else {
    // 			toastify(response?.message, "error");
    // 		}
    // 	}
    // })();
    // } else {
    // 	(async () => {
    // 		const departID =
    // 			props?.selectedRole == "DOCTOR"
    // 				? DOCTOR_DEPARTMENT_ID
    // 				: NURSE_DEPARTMENT_ID;
    // 		const response = await getStaffByDepartment(departID);
    // 		if (response.status !== "Fail") {
    // 			const tempArr = response.map((o) => ({
    // 				value: o._id,
    // 				label: o?.username,
    // 			}));
    // 			setOptions([...tempArr]);
    // 			// setStaff(response);
    // 		} else {
    // 			toastify(response.message, "error");
    // 		}
    // 	})();
    // }
  }, [props.selectedRole]);
  useEffect(() => {
    (async () => {
      const res = await getMembershipCategories();
      if (res?.status !== "Fail") {
        setUsePointVal(0);
        return;
      }
      setUsePointVal(res?.point);
    })();
  }, []);

  const renderMedInfo = () => {
    console.log("DEBUG", ptData)
    return (
      <div>
        <Form.Label className="black-label-text" style={{marginLeft: "20px", marginTop: "10px"}}>
          Medical Information
        </Form.Label>
        {ptData?.length > 0 &&
          ptData?.map((e, i) => {
            return (
              <div className="accordion-patient">
                <Accordion defaultActiveKey={"0"}>
                  <Accordion.Item
                    eventKey="0"
                    style={{
                      border: "none",
                      outline: "none",
                    }}
                  >
                    <Accordion.Header>
                      <div className="bold font-14">{e.date}</div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <div>
                          <div>
                            <div className="me-auto cursor-pointer">
                              <div>{e.type} : {e.description}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div>
      <div className="mainHeading mx-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          Note
        </h1>
      </div>
      <Form.Group className="col-4 mx-4">
        <Form.Label className="font-400-20 grey">
          <b>
            Select Patient
            {/* {props?.selectedRole[0]?.toUpperCase() +
							props?.selectedRole?.slice(1)?.toLowerCase()} */}
          </b>
        </Form.Label>
        <AsyncSelect
          value={selectedOption}
          setValue={(value) => {
            setPatientData([]);
            setNotes([]);
            setSelectedPatient(value);
            callDesiredAPI(value._id);
            onSubmitRedux(value);
          }}
          loadOptions={(search, loadedOptions, { page }) =>
            loadOptionPatients(search, loadedOptions, { page })
          }
          loadSelectedOption={loadSelectedPatient}
        />
        {/* <SelectComponent
					singleSelect
					searchEnabled
					options={options}
					selectedValue={selectedOption}
					onChange={(e) => {
						setPatientData([]);
						setNotes([]);
						setSelectedPatient(e.value);
						callDesiredAPI(e.value);
					}}
				/> */}
      </Form.Group>
      {selectedOption && (
        <>
          <div
            className="d-flex"
            style={{ marginTop: "20px", marginLeft: "25px" }}
          >
            {props?.selectedRole === "DOCTOR" && (
              <>
                <div className="accordion-patient col-md-4 me-4">
                  <div
                    className="details-container white-background px-2 align-self-stretch "
                    style={{
                      width: "100%",
                      border: "1px solid #E9E9E9",
                      padding: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <div className=" addressDetail">
                      <div className="detailPadding d-flex">
                        <i className="fa fa-user"></i>
                        <h6 className="ms-2">
                          {selectedPatient?.patientProfile?.fullName
                            ? selectedPatient?.patientProfile?.fullName
                            : "-"}
                        </h6>
                      </div>
                      <div className="detailPadding d-flex">
                        <i className="fa fa-envelope"></i>
                        <h6 className="ms-2">
                          {selectedPatient?.patientProfile?.email
                            ? selectedPatient?.patientProfile?.email
                            : "-"}
                        </h6>
                      </div>
                      <div className="detailPadding d-flex">
                        <i className="fa fa-phone"></i>
                        <h6 className="ms-2">
                          {selectedPatient.patientProfile?.contactNo
                            ? selectedPatient.patientProfile?.contactNo
                            : "-"}
                        </h6>
                      </div>
                      <div className="detailPadding d-flex">
                        <i className="fa fa-book"></i>
                        <h6 className="ms-2">
                          {selectedPatient.patientProfile?.fullName
                            ? selectedPatient.patientProfile?.fullName
                            : "-"}
                        </h6>
                      </div>
                      <div className="detailPadding d-flex">
                        <i className="fa fa-map-marker"></i>
                        <p className="ms-2">
                          {selectedPatient.patientProfile?.country &&
                          selectedPatient.patientProfile?.address
                            ? `${selectedPatient.patientProfile?.country?.name}, ${selectedPatient.patientProfile?.address}`
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 white-background">
                    {/* <div className="history mt-0 py-2">Patient Medical Information</div> */}
                    {renderMedInfo()}
                  </div>
                  <div className="mb-3 white-background px-1">
                    <div
                      className="history mt-0 py-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "20px",
                        alignItems: "center",
                      }}
                    >
                      <span>Medical History</span>
                    </div>

                    {patientData?.length > 0 &&
                      patientData?.map((e, i) => {
                        if (!e.history?.medicalHistory) {
                          return <></>;
                        }
                        return (
                          <>
                            <Accordion defaultActiveKey={"0"}>
                              <Accordion.Item
                                eventKey="0"
                                style={{
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <Accordion.Header>
                                  <div className="bold font-14">{e.date}</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div>
                                    <div>
                                      <div>{e.history?.medicalHistory}</div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        );
                      })}
                  </div>
                  <div className="mb-3 white-background px-1">
                    <div
                      className="history mt-0 py-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "20px",
                        alignItems: "center",
                      }}
                    >
                      <span>Cosmetic History</span>
                    </div>

                    {patientData?.length > 0 &&
                      patientData?.map((e, i) => {
                        if (!e.history?.cosmeticHistory) {
                          return <></>;
                        }
                        return (
                          <>
                            <Accordion defaultActiveKey={"0"}>
                              <Accordion.Item
                                eventKey="0"
                                style={{
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <Accordion.Header>
                                  <div className="bold font-14">{e.date}</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div>
                                    <div>
                                      <div>{e.history?.cosmeticHistory}</div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        );
                      })}
                  </div>
                  <div className="mb-3 white-background px-1">
                    <div
                      className="history mt-0 py-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "20px",
                        alignItems: "center",
                      }}
                    >
                      <span>Adverse Cosmetic History</span>
                    </div>
                    {patientData?.length > 0 &&
                      patientData?.map((e, i) => {
                        if (!e.history?.adverseCosmeticHistory) {
                          return <></>;
                        }
                        return (
                          <>
                            <Accordion defaultActiveKey={"0"}>
                              <Accordion.Item
                                eventKey="0"
                                style={{
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <Accordion.Header>
                                  <div className="bold font-14">{e.date}</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div>
                                    <div>
                                      <div>
                                        {e.history?.adverseCosmeticHistory}
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        );
                      })}
                  </div>
                  <div className="mb-3 white-background px-1">
                    <div
                      className="history mt-0 py-2"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: "20px",
                        alignItems: "center",
                      }}
                    >
                      <span>Social History</span>
                    </div>

                    {patientData?.length > 0 &&
                      patientData?.map((e, i) => {
                        if (!e.history?.socialHistory) {
                          return <></>;
                        }
                        return (
                          <>
                            <Accordion defaultActiveKey={"0"}>
                              <Accordion.Item
                                eventKey="0"
                                style={{
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <Accordion.Header>
                                  <div className="bold font-14">{e.date}</div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div>
                                    <div>
                                      <div>{e.history?.socialHistory}</div>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </>
                        );
                      })}
                  </div>
                </div>
              </>
            )}
            <div
              className={`white-background p-3 ${
                props?.selectedRole === "DOCTOR" ? "col-md-7" : "w-100"
              }`}
            >
              {/* <div className="font-400-20 mb-3">
						<b>{state?.name ?? ""}</b>
					</div> */}
              {props?.selectedRole === "DOCTOR" &&
                patientData?.length > 0 &&
                patientData?.map((o) => {
                  return (
                    <>
                      <div className="mb-3">
                        <NoteRenderer
                          role={"Doctor"}
                          notes_data={o.notes}
                          date={o.date}
                          pointVal={usePointVal}
                        />
                      </div>
                    </>
                  );
                })}
              {props?.selectedRole == "THERAPIST" && (
                <>
                  <div
                    className="half-column-box"
                    style={{ marginBottom: "15px" }}
                  >
                    <div
                      className="details-container white-background px-2 align-self-stretch "
                      style={{
                        border: "1px solid #E9E9E9",
                        padding: "10px",
                      }}
                    >
                      <div className=" addressDetail">
                        <div className="detailPadding d-flex">
                          <i className="fa fa-user"></i>
                          <h6 className="ms-2">
                            {selectedPatient?.patientProfile?.fullName
                              ? selectedPatient?.patientProfile?.fullName
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-envelope"></i>
                          <h6 className="ms-2">
                            {selectedPatient?.patientProfile?.email
                              ? selectedPatient?.patientProfile?.email
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-phone"></i>
                          <h6 className="ms-2">
                            {selectedPatient.patientProfile?.contactNo
                              ? selectedPatient.patientProfile?.contactNo
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-book"></i>
                          <h6 className="ms-2">
                            {selectedPatient.patientProfile?.fullName
                              ? selectedPatient.patientProfile?.fullName
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-map-marker"></i>
                          <p className="ms-2">
                            {selectedPatient.patientProfile?.country &&
                            selectedPatient.patientProfile?.address
                              ? `${selectedPatient.patientProfile?.country?.name}, ${selectedPatient.patientProfile?.address}`
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ border: "1px solid #E9E9E9" }}>
                      {renderMedInfo()}
                    </div>
                  </div>
                  <TabNurseNotes patientId={selectedPatient?._id} />
                </>
              )}
              {props?.selectedRole == "NUTRITIONIST" && (
                <>
                  <div
                    className="half-column-box"
                    style={{ marginBottom: "15px" }}
                  >
                    <div
                      className="details-container white-background px-2 align-self-stretch "
                      style={{
                        border: "1px solid #E9E9E9",
                        padding: "10px",
                      }}
                    >
                      <div className=" addressDetail">
                        <div className="detailPadding d-flex">
                          <i className="fa fa-user"></i>
                          <h6 className="ms-2">
                            {selectedPatient?.patientProfile?.fullName
                              ? selectedPatient?.patientProfile?.fullName
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-envelope"></i>
                          <h6 className="ms-2">
                            {selectedPatient?.patientProfile?.email
                              ? selectedPatient?.patientProfile?.email
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-phone"></i>
                          <h6 className="ms-2">
                            {selectedPatient.patientProfile?.contactNo
                              ? selectedPatient.patientProfile?.contactNo
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-book"></i>
                          <h6 className="ms-2">
                            {selectedPatient.patientProfile?.fullName
                              ? selectedPatient.patientProfile?.fullName
                              : "-"}
                          </h6>
                        </div>
                        <div className="detailPadding d-flex">
                          <i className="fa fa-map-marker"></i>
                          <p className="ms-2">
                            {selectedPatient.patientProfile?.country &&
                            selectedPatient.patientProfile?.address
                              ? `${selectedPatient.patientProfile?.country?.name}, ${selectedPatient.patientProfile?.address}`
                              : "-"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style={{ border: "1px solid #E9E9E9" }}>
                      {renderMedInfo()}
                    </div>
                  </div>
                  <TabNutrionNotes patientId={selectedPatient?._id} />
                </>
              )}
              {/* {props?.selectedRole === "THERAPIST" &&
								notes?.map((e) => (
									<NoteRenderer
										note={e}
										role={"Nurse"}
										userId={userData._id}
										handleClose={handleClose}
										show={show}
										setShow={setShow}
										setDeleting={setDeleting}
										editable
										onNoteSave={onNoteSave}
										// editNote={editNote}
									/>
								))} */}
              <DeleteModal
                content="Confirm Patient Note Deletion?"
                onYes={removeNote}
                onNo={handleClose}
                show={show}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default PatientNotesSelected;
