import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Col,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import parse from "html-react-parser";
import _ from "lodash";
import { getFormatedTime } from "../../../helperFunctions/utils";
import { thousandAndDecimalFormat } from "../../../utils/common";
import EditIcon from "@mui/icons-material/Edit";

export default function NoteRenderer(props) {
  const { notes_data, editable, userId, pointVal } = props;
  const [noteInput, setNoteInput] = useState();
  const [isEdited, setIsEdited] = useState(false);

  const getMonth = (month) => {
    if (month === 1) return "Jan";
    if (month === 2) return "Feb";
    if (month === 3) return "Mar";
    if (month === 4) return "Apr";
    if (month === 5) return "May";
    if (month === 6) return "Jun";
    if (month === 7) return "Jul";
    if (month === 8) return "Aug";
    if (month === 9) return "Sep";
    if (month === 10) return "Oct";
    if (month === 11) return "Nov";
    if (month === 12) return "Dec";
  };

  const getDate = (date) => {
    if (date) {
      let _date = new Date(date);
      return `${_date.getDate()} ${getMonth(
        _date.getMonth()
      )} ${_date.getFullYear()}`;
    } else {
      return "N/A";
    }
  };

  const concatZero = (data) => {
    if (data < 10) return `0${data}`;
    else return data;
  };

  const getLabel = (string) => {
    if (string === "subjective") {
      return "Subjective";
    }
    if (string === "objective") {
      return "Objective";
    }
    if (string === "assesment") {
      return "Assessment";
    }
    if (string === "plan") {
      return "Plan";
    }
    if (string === "recommendedTreatment") {
      return "Recommended Treatment";
    }
    if (string === "treatmentDone") {
      return "Treatment Done";
    }

    return string;
  }

  const renderInstruction = (service) => {
    const result = (`${service?.instruction || ''} ${service?.totalDosage || ''} ${service?.dosage || ''} ${service?.periodDescription || ''} ${(service?.takePeriod && service?.takeType) ? `For ${service?.takePeriod} ${service?.takeType}` : ''}`).trim("");

    return result;
  }

  const renderBillingDetail = () => {
    return (
      <div>
        {props.isBilling === true ? (
          <div
            style={{
              background: "white",
            }}
          >
            <Table responsive size="sm">
              <thead
                style={{
                  background: "#E9E9E9",
                }}
              >
                <tr>
                  <th className="px-2">Service Center</th>
                  <th>Service</th>
                  {/* <th>Remarks</th> */}
                  <th>Quantity</th>
                  <th>Promo</th>
                  <th>Discount</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody
                style={{
                  borderBottom: "7px #E9E9E9 solid",
                }}
              >
                <tr>
                  <td className="px-2">{notes_data?.location?.name ?? "-"}</td>
                  <td>
                    {notes_data?.service?.map((service) => {
                      return (
                        <>
                          <div>
                            {service?.promoName
                              ? `${service?.name} (${service?.promoName})`
                              : `${service?.name}`}
                          </div>
                        </>
                      );
                    })}
                  </td>
                  {/* <td>{notes_data?.remarks}</td> */}
                  <td>
                    {notes_data?.service?.map((service) => {
                      return (
                        <>
                          <div>{service?.qty ?? "-"}</div>
                        </>
                      );
                    })}
                  </td>
                  <td>
                    {notes_data?.service
                      // ?.filter((ser) => ser.name)
                      ?.map((service) => {
                        return (
                          <>
                            <div>
                              {service?.promoDiscountType === "Percentage"
                                ? `${service.promoDiscountValue}%`
                                : `$${
                                    service.promoDiscountValue
                                      ? service.promoDiscountValue
                                      : "0"
                                  }`}
                            </div>
                          </>
                        );
                      })}
                  </td>
                  <td>
                    {notes_data?.service
                      // ?.filter((ser) => ser.name)
                      ?.map((service) => {
                        return (
                          <>
                            <div>
                              {service?.discountType === "Percentage"
                                ? `${thousandAndDecimalFormat(
                                    service.discount,
                                    true,
                                    0
                                  )}%`
                                : `$${
                                    service.discount ? service.discount : "0"
                                  }`}
                            </div>
                          </>
                        );
                      })}
                  </td>
                  <td>
                    {notes_data?.service
                      // ?.filter((ser) => ser.name)
                      ?.map((service) => {
                        return (
                          <>
                            <div>
                              $
                              {thousandAndDecimalFormat(
                                service?.total_price,
                                true,
                                0
                              ) ?? "-"}
                            </div>
                          </>
                        );
                      })}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="total-cont mt-0">
              <Row className="w-100" style={{ wordBreak: "break-all" }}>
                <Col md={5}>Sub Total:</Col>
                <Col md={3}></Col>
                <Col md={4}>
                  ${thousandAndDecimalFormat(notes_data?.actualAmount, true, 0)}
                </Col>
              </Row>
              {notes_data?.promoDiscount !== 0 && (
                <Row className="w-100">
                  <Col md={5}>Discount</Col>
                  <Col md={3}>
                    {notes_data?.promoType === "Amount"
                      ? `$${thousandAndDecimalFormat(
                          notes_data.promo,
                          true,
                          0
                        )}`
                      : `${notes_data.promo}%`}
                  </Col>
                  <Col md={4}>
                    ($
                    {thousandAndDecimalFormat(
                      notes_data.promoDiscount,
                      true,
                      0
                    )}
                    )
                  </Col>
                </Row>
              )}
              {notes_data?.paymentAdjustments?.map((adjustment, index) => (
                <Row key={index} className="w-100">
                  <Col md={5}>{adjustment.name}</Col>
                  <Col md={3}>
                    {adjustment?.discountType === "Amount"
                      ? `$${adjustment.discount}`
                      : `${adjustment.discount}%`}
                  </Col>
                  <Col md={4}>
                    {adjustment.discountType === "Percentage"
                      ? `($${
                          ((adjustment?.discount ?? 0) / 100) *
                          (notes_data?.actualAmount ?? 0)
                        })`
                      : `($${adjustment.discount})`}
                  </Col>
                </Row>
              ))}
              {notes_data?.usePoints !== 0 && (
                <Row className="w-100">
                  <Col md={5}>Points</Col>
                  <Col md={3}>{notes_data?.usePoints}</Col>
                  <Col md={4}>
                    ${(notes_data?.usePoints ?? 0) * (pointVal ? pointVal : 0)}
                  </Col>
                </Row>
              )}
              {/* <Row className="w-100">
									<Col md={7}>Payment Method</Col>
									<Col md={1}></Col>
									<Col md={4}>
										{notes_data?.paymentMethod
										}
									</Col>
								</Row> */}
              {/* {
									notes_data?.tax?.map((tax, taxIdx) => (
										<Row
											key={taxIdx}
											className="w-100"
											style={{
												wordBreak: "break-all",
											}}
										>
											<Col md={5}>
												{tax?.name}
											</Col>
											<Col
												md={3}
												style={{
													wordBreak: "break-all",
												}}
											>
												{tax?.value}
												{tax?.typeValue ==
													"Percentage"
													? "%"
													: ""}
											</Col>
											<Col md={4}>
												${tax?.afterTaxValue}
											</Col>
										</Row>
									))
								} */}

              <Row
                style={{
                  borderTop: "2px solid #E9E9E9",
                  wordBreak: "break-all",
                }}
                className="w-100 mt-2"
              >
                <Col md={5}>GST:</Col>
                <Col md={3}></Col>
                <Col md={4}>
                  $
                  {_.round(
                    (notes_data?.gstAmount ?? 0) -
                      (notes_data?.totalAmount ?? 0),
                    2
                  )}
                </Col>
              </Row>
              <Row
                style={{
                  wordBreak: "break-all",
                }}
                className="w-100"
              >
                <Col md={5}>Grant Total:</Col>
                <Col md={3}></Col>
                <Col md={4}>
                  ${thousandAndDecimalFormat(notes_data?.gstAmount, true, 2)}
                </Col>
              </Row>
            </div>
            {notes_data.installmentRecord && (
              <Table responsive size="sm">
                <thead
                  style={{
                    background: "#E9E9E9",
                  }}
                >
                  <tr>
                    <th className="px-2">Date</th>
                    <th>Payed Amount</th>
                    <th>Remaining Amount</th>
                    <th>Payment Method</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    borderBottom: "7px #E9E9E9 solid",
                  }}
                >
                  {notes_data.installmentRecord?.map((d) => (
                    <tr>
                      <td className="px-2">
                        {new Date(d?.date).toLocaleDateString()}
                      </td>
                      <td>${d.currentPaidAmount}</td>
                      <td>${d.remaining}</td>
                      <td>{d.paymentMethod}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        ) : (
          <div
            style={{
              background: "white",
            }}
          >
            {
              notes_data?.service?.length > 0 && (
                <div>
                  <Table responsive size="sm">
                    <thead
                      style={{
                        background: "#E9E9E9",
                      }}
                    >
                      <tr>
                        <th className="px-2">Type</th>
                        <th>Service</th>
                        <th width="250px">Instruction</th>
                        <th>Qty</th>
                        <th>Subtotal</th>
                        {/* <th>Discount</th> */}
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody
                      style={{
                        borderBottom: "7px #E9E9E9 solid",
                      }}
                    >
                      <tr>
                        <td className="px-2">
                          {notes_data?.service?.map((service) => {
                            return (
                              <>
                                <div>
                                  {service?.type?.charAt(0)?.toUpperCase() +
                                    service?.type?.slice(1) ?? "-"}
                                </div>
                              </>
                            );
                          })}
                        </td>
                        <td className="px-2">
                          {notes_data?.service?.map((service) => {
                            return (
                              <>
                                <div>{service?.name ?? "-"}</div>
                              </>
                            );
                          })}
                        </td>
                        <td width="250px">
                          {notes_data?.service?.map((service) => {
                            return (
                              <>
                                {/* <div>{service?.instruction ?? "-"}</div> */}
                                <div style={{ whiteSpace: "pre-wrap" }}>
                                  {renderInstruction(service)}
                                </div>
                              </>
                            );
                          })}
                        </td>
                        <td>
                          {notes_data?.service?.map((service) => {
                            return (
                              <>
                                <div>{service?.qty ?? 0}</div>
                              </>
                            );
                          })}
                        </td>
                        <td>
                          {notes_data?.service?.map((service) => {
                            return (
                              <>
                                <div>${service?.price ?? 0}</div>
                              </>
                            );
                          })}
                        </td>
                        {/* <td>
                            {notes_data?.service
                              ?.map((service) => {
                                return (
                                  <>
                                    <div>
                                      {service?.discount ?? 0}
                                    </div>
                                  </>
                                );
                              })}
                          </td> */}
                        <td>
                          {notes_data?.service?.map((service) => {
                            return (
                              <>
                                <div>${service?.total_price ?? 0}</div>
                              </>
                            );
                          })}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                  <div className="total-cont mt-0">
                    <Row className="w-100" style={{ wordBreak: "break-all" }}>
                      <Col md={5}>Total:</Col>
                      <Col md={3}></Col>
                      <Col md={4}>${notes_data?.totalAmount}</Col>
                    </Row>
                    <Row className="w-100">
                      <Col md={5}>GST Amount:</Col>
                      <Col md={3}></Col>
                      <Col md={4}>${notes_data?.afterTaxValue}</Col>
                    </Row>
                  </div>
                </div>
              )
            }
          </div>
        )}
      </div>
    );
  };

  if (props.role === "Doctor") {
    return props.isPatientBilling ? (
      renderBillingDetail()
    ) : (
      <Accordion defaultActiveKey={["0"]} alwaysOpen>
        {/* {console.log("aaaaa", notes_data)} */}
        <Accordion.Item eventKey="0">
          <Accordion.Header className="nurse-accordion-header">
            <div className="d-flex flex-row justify-content-between flex-fill">
              <div>
                Doctor Notes
                <span className="mx-3 bold">{props.date}</span>
              </div>
              {notes_data?.startTime && (
                <>
                  <div className="mx-2">
                    Start: {getFormatedTime(notes_data?.startTime)} End:
                    {getFormatedTime(notes_data?.endTime)}
                  </div>
                </>
              )}

              <div>
                {
                  props.handleSessionDetail && (
                    <EditIcon
                      sx={{ cursor: "pointer", marginRight: "10px" }}
                      onClick={(e) => {
                        e.preventDefault();
                        props.handleSessionDetail({
                          _id: props.sessionId
                        });
                      }}
                    />
                  )
                }
              </div>
            </div>
          </Accordion.Header>

          <Accordion.Body
            className="px-0"
            style={{
              backgroundColor: "#f1f1f1",
              whiteSpace: "pre-wrap"
            }}
          >
            {/* <div className="mb-2 px-2">
								{notes_data?.staff?.map((staff) => {
									return (
										<>
											<div>{staff}</div>
										</>
									);
								})}
							</div> */}
            <div className="mb-2 px-2">
              {notes_data?.soap &&
                Object.entries(notes_data?.soap)?.map((o) => {
                  if (o[1]) {
                    return (
                      <div className="mb-2">
                        <div className="bold">{getLabel(o[0])}</div>
                        <div>{o[1]}</div>
                      </div>
                    );
                  }
                })}
            </div>
            {renderBillingDetail()}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  } else {
    return (
      <div>
        <div className="d-flex align-items-center">
          <Accordion defaultActiveKey={["0"]} alwaysOpe className="my-3 w-100">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="nurse-accordion-header">
                {props.isNurse ? "Therapist Notes" : "Nutritionist Notes"}
                <span className="ms-5 bold">
                  {new Date(props?.note?.created_at).toLocaleDateString()}
                </span>
              </Accordion.Header>
              <Accordion.Body
                style={{
                  backgroundColor: "#f1f1f1",
                }}
              >
                <div>
                  <div className="bold">
                    {/* {new Date(
											props?.note?.created_at,
										).getHours() +
											":" +
											concatZero(
												new Date(
													props?.note?.created_at,
												).getMinutes(),
											)} */}
                    {new Date(props?.note?.created_at).toLocaleTimeString(
                      "en-US",
                      {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                    <span>
                      {" "}
                      -{" "}
                      {props.isNurse
                        ? "Therapist Notes"
                        : "Nutritionist Notes"}{" "}
                      -{" "}
                    </span>
                    {props?.note?.staff?.username}
                  </div>
                  <div>
                    {parse(props?.note?.description.replace(/\n/g, "<br />"))}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <div className="d-flex">
            {props.editNote && (
              <>
                <div
                  className="mx-1 cursor-pointer icon-width-2"
                  onClick={() => {
                    props.editNote(props.note);
                  }}
                >
                  <i className="fa fa-pen"></i>
                </div>
              </>
            )}
            {props.editable && props.note.staff._id == userId && (
              <>
                <div
                  className="mx-1 cursor-pointer icon-width-2"
                  onClick={() => {
                    if (!isEdited) {
                      const text = parse(
                        props?.note?.description.replace(/\n/g, "<br />")
                      );
                      console.log(text);

                      setNoteInput(props?.note?.description);
                    }
                    setIsEdited((val) => {
                      return !val;
                    });

                    // props.editNote(props.note);
                  }}
                >
                  <i className="fa fa-pen"></i>
                </div>
              </>
            )}
            <div
              className="mx-1 cursor-pointer icon-width-2"
              onClick={() => {
                props.setDeleting(props?.note?._id);
                props.setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        </div>
        {isEdited && (
          <div>
            <Form.Group className="">
              <InputGroup className="mb-3">
                <Form.Control
                  name={"remarks"}
                  as="textarea"
                  rows={3}
                  placeholder="Write..."
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    setNoteInput(e.target.value);
                  }}
                  value={noteInput}
                />
              </InputGroup>
            </Form.Group>
            <Button
              className="addItem"
              onClick={() => {
                // console.log("asdasd", props.note);
                props.onNoteSave(noteInput, props.note?._id, (isSuccess) => {
                  if (isSuccess) {
                    setIsEdited(false);
                    setNoteInput("");
                  }
                });
              }}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    );
  }
}
