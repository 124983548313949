import api from "../axios/axios-config";

export const getPatientPhotoComparison = async (dataId, beforeDate, afterDate) => {
  try {
    let response = await api.post(
      "patientPhotoComparison/listOfPhotoComparisonByPatientId",
      {
        dataId,
        beforeDate,
        afterDate,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const getNursePhotoComparison = async (nurseId, beforeDate, afterDate) => {
  try {
    let response = await api.post(
      "patientPhotoComparison/listOfPhotoComparisonByNurseId",
      {
        nurseId,
        beforeDate,
        afterDate,
      },
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
export const addPhotoComparison = async (postBodyData) => {
  try {
    let response = await api.post(
      "patientPhotoComparison/createPhotoComparison",
      postBodyData,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updatePhotoComparison = async (putBodyData) => {
  try {
    let response = await api.post(
      "patientPhotoComparison/updatePhotoComparison",
      putBodyData,
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
