import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { toastify } from "../../../helperFunctions/toastify";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { createDotPhrase, updateDotPhrase } from "../../../apis/dotPhrases";

export default function AddEditDotPhrase() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const titleRegex = new RegExp("^[a-zA-Z]+$");

  const [phrase, setPhrase] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState();

  useEffect(() => {
    if (state?.phrase) populateDotPhrase();
  }, []);

  const populateDotPhrase = () => {
    setPhrase(state.phrase.dot_phrases);
    setTitle(state.phrase.title);
    setDescription(state.phrase.description);
  };

  const savePhrase = async () => {
    if (!phrase) {
      missingPopup("Dot Phrase");
    } else if (!title) {
      missingPopup("Title");
    } else if (!description) {
      missingPopup("Description");
    } else {
      let _data = { dot_phrases: phrase, title, description };

      if (state?.phrase) {
        const response = await updateDotPhrase(state.phrase._id, _data);
        if (response?.status !== "Fail") {
          toastify("Dot Phrase updated successfully!", "success");
          navigate("/dot-phrases");
        } else {
          toastify(response?.message, "error");
        }
      } else {
        const response = await createDotPhrase(_data);
        if (response?.status !== "Fail") {
          toastify("Dot Phrase added successfully!", "success");
          navigate("/dot-phrases");
        } else {
          toastify(response?.message, "error");
        }
      }
    }
  };

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.phrase ? "Edit " : "New "}`}
          Dot Phrase
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div className="col-md-6">
                {/* <Form.Group>
                  <Form.Label className="headings2">Dot(.) Phrase *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => setPhrase(e.target.value)}
                      value={phrase}
                    />
                  </InputGroup>
                </Form.Group> */}
                <Form.Group>
                  <Form.Label className="headings2">Dot(.) Phrase *</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                      value={title}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="headings2">Description *</Form.Label>
                  <InputGroup>
                    <Form.Control
                      as="textarea"
                      style={{ resize: "none" }}
                      placeholder="Write..."
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
          </div>

          <div className="addEdit">
            <Button
              className="addItem"
              onClick={() => {
                savePhrase();
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
