import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import {
  setAllTemplates,
  setTemplateToEdit,
} from "../../../redux/slices/DataTableTemplateSlice";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function DataTableTemplateView() {
  //////////////////////////Declarations\\\\\\\\\\\\\\\\\\\\\
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allTemplates } = useSelector((state) => state.templateData);
  const [filteredArray, setFilteredArray] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  //////////////////////////function\\\\\\\\\\\\\\\\\\\\\\\\\

  const getAllTemplates = async () => {
    try {
      const { result, error } = await authorizedRequests({
        url: "collections/templates",
        method: "get",
      });
      if (error) {
        toastify(error.message ?? "Unknown error occured", "error");
        return;
      }
      dispatch(setAllTemplates(result.data));
    } catch (error) {
      toastify(error.message ?? "Unknown error occured", "error");
    }
  };

  const addNewTemplate = () => {
    dispatch(setTemplateToEdit({}));
    navigate("/add-template");
  };

  const editBranch = (item) => {
    navigate(`/add-template/${item._id}`);
  };

  const deleteBranch = async () => {
    setShow(false);

    const { result, error } = await authorizedRequests({
      url: `collections/templates/${deleting}`,
      method: "delete",
    });
    if (error) {
      toastify(error.message ?? "unable to delete this template", "error");
      return;
    }
    toastify("Template deleted successfully.", "success");

    getAllTemplates();
  };

  //////////////////////////tables Data\\\\\\\\\\\\\\\\\\\\\\\
  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly4">
          <span className="me-2">Category</span>
          {
            <FilterComponent
              type="search"
              array={allTemplates}
              setArray={setFilteredArray}
              value="category"
            />
          }
        </div>
      ),
      selector: (row) => row.category,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "category"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly4">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={allTemplates}
              setArray={setFilteredArray}
              value="Name"
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: "Description",
      selector: (row) => row.description,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "description"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];
  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const tableData = (filteredArray.length === 0 ? allTemplates : filteredArray)
    ?.map((o, i) => {
      return {
        ...o,
        index: i,
        action: (
          <div className="d-flex">
            <div
              className=" align-items-center d-flex cursor-pointer icon-width"
              onClick={() => {
                editBranch(o);
              }}
            >
              <i className="fa fa-pen"></i>
            </div>
            <div
              className="align-items-center d-flex cursor-pointer icon-width"
              // onClick={() => {
              //   deleteBranch(o._id);
              // }}
              onClick={() => {
                setDeleting(o._id);
                setShow(true);
              }}
            >
              <i className="fa fa-trash"></i>
            </div>
            <div className="">
              <Button
                className="preview-btn ms-3"
                onClick={() => {
                  navigate(`/preview-template/${o._id}`);
                }}
              >
                Preview
              </Button>
            </div>
          </div>
        ),
      };
    })
    .reverse();
  const addCollections = async () => {
    const { result, error } = await authorizedRequests({
      url: "collections",
      method: "patch",
    });
    if (error) {
      toastify("Failed to refres collections");
      return;
    }
    toastify("Collections refreshed successfully", "success");
    return;
  };
  ////////////////////////HOOKS\\\\\\\\\\\\\\\\\\\\\\\\\
  useEffect(() => {
    getAllTemplates();
  }, []);

  return (
    <>
      <div className="container p-0">
        <div className="mainHeading px-4">
          <h1>Report Template</h1>
          <div className="d-flex">
            {/* <Button
							className="addItem me-2"
							onClick={addCollections}
						>
							Refresh Collections
						</Button> */}
            <Button className="addItem" onClick={addNewTemplate}>
              + New Template
            </Button>
          </div>
        </div>

        <DeleteModal
          content="Confirm Template Deletion?"
          onYes={deleteBranch}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={tableData || []}
          pagination
          conditionalRowStyles={conditionalRowStyles}
        />
      </div>
    </>
  );
}
