import React, { useState, useEffect, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import styled from "styled-components";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { getProducts } from "../../../apis/products";
import { getDrugMovementReport } from "../../../apis/customTemplate";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { setTableSearch } from "../../../redux/slices/commonSlice";

import {
  customDateSort,
  formatDate,
  downloadCsv,
} from "../../../helperFunctions/utils";
import MultiSelectComponent from "../../SharedComponents/MultiSelctCompoenent";

const FilterWrapper = styled.div`
  label {
    color: inherit;
  }

  .btn-wrapper {
    align-items: center;
    display: flex;
    margin-top: 20px;
  }
`;

function DrugMovementReport() {
  const dispatch = useDispatch();
  const location = useLocation();
  const buttonRef = useRef(null);
	const pathname = location.pathname;
	const fieldPathname = pathname.replace("/", "");

  const { tableSearch } = useSelector((state) => state.commonData);

  const [drugs, setDrugs] = useState([]);
  const [selectedDrug, setSelectedDrug] = useState(tableSearch[fieldPathname]?.drugMovement?.selectedDrug ? tableSearch[fieldPathname]?.drugMovement?.selectedDrug : "");
  const [filteredData, setFilteredData] = useState([]);
  const [startDate, setStartDate] = useState(tableSearch[fieldPathname]?.drugMovement?.startDate ? tableSearch[fieldPathname]?.drugMovement?.startDate : new Date());
  const [endDate, setEndDate] = useState(tableSearch[fieldPathname]?.drugMovement?.endDate ? tableSearch[fieldPathname]?.drugMovement?.endDate : new Date());
  const [refetch, setRefetch] = useState(false);

  const onReduxFilter = (valueStartDate, valueEndDate, valueDrug) => {
    dispatch(
      setTableSearch({
        ...tableSearch,
        [fieldPathname]: {
          ...tableSearch[fieldPathname],
          drugMovement: {
            startDate: valueStartDate,
            endDate: valueEndDate,
            selectedDrug: valueDrug,
          },
        },
      })
    );
  };

  useEffect(() => {
    setStartDate(tableSearch[fieldPathname]?.drugMovement?.startDate ? tableSearch[fieldPathname]?.drugMovement?.startDate : new Date());
    setEndDate(tableSearch[fieldPathname]?.drugMovement?.endDate ? tableSearch[fieldPathname]?.drugMovement?.endDate : new Date());
    setSelectedDrug(tableSearch[fieldPathname]?.drugMovement?.selectedDrug ? tableSearch[fieldPathname]?.drugMovement?.selectedDrug : "");

    if (buttonRef.current && (
      tableSearch[fieldPathname]?.drugMovement?.startDate || 
      tableSearch[fieldPathname]?.drugMovement?.endDate ||
      tableSearch[fieldPathname]?.drugMovement?.selectedDrug
    )) {
      setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, [1000])
    }
  }, []);

  useEffect(() => {
    getProducts().then((res) => {
      setDrugs(res);
    });
  }, []);
  useEffect(() => {
    if (selectedDrug !== "" && startDate !== null && endDate !== null) {
      getDrugMovementReport({
        startDate: startDate,
        endDate: endDate,
        drug: selectedDrug,
      }).then((res) => {
        if (res?.result?.data) {
          setFilteredData(res.result.data);
        }
      });
    }
  }, [refetch]);

  const columns = [
    {
      name: "Date",
      selector: (row) => formatDate(row.date),
      width: "140px",
      sortable: true,
      sortFunction: (a, b) => customDateSort(a, b, "date"),
    },
    {
      name: "Drug",
      selector: (row) => row.name,
    },
    {
      name: "From",
      selector: (row) => row.from,
    },
    {
      name: "Added Qty",
      selector: (row) => row.qty,
    },
    {
      name: "Created By",
      selector: (row) => row.createdBy,
    },
    {
      name: "Remark",
      selector: (row) => row.remark,
    },
  ];

  const data = filteredData?.map((e, index) => {
    return {
      ...e,
      index,
      key: index,
    };
  });

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  const exportCsv = () => {
    const csvData = data.map((row) => {
      return {
        Date: formatDate(row.date),
        From: row.from,
        "Added Qty": row.qty,
        "Created By": row.createdBy,
        Remark: row.remark,
      };
    });

    downloadCsv(csvData, "Drug Movement Report");
  };

  return (
    <div className="p-0 table-cell-whitespace">
      <div
        className="mainHeading"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          paddingTop: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <FilterWrapper className="d-flex">
            <Form.Group className="me-3" style={{ width: "260px" }}>
              <Form.Label className="headings2" style={{ color: "#000" }}>
                Filter by Drug
              </Form.Label>
              <MultiSelectComponent
                searchEnabled
                array={selectedDrug}
                options={
                  drugs?.length > 0
                    ? [
                        ...drugs.map((item) => {
                          return {
                            label: item.name,
                            value: item._id,
                          };
                        }),
                      ]
                    : []
                }
                onChange={(e) => {
                  setSelectedDrug(e);
                }}
              />
            </Form.Group>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={startDate}
                label="Start Date"
                placeholder="Select"
                onChange={(e) => {
                  setStartDate(e);
                }}
              />
            </div>
            <div className="me-3" style={{ width: "260px" }}>
              <DateInput
                value={endDate}
                label="End Date"
                placeholder="Select"
                onChange={(e) => {
                  setEndDate(e);
                }}
              />
            </div>
            <div className="btn-wrapper">
              <Button
                ref={buttonRef}
                className="addItem me-3"
                onClick={() => {
                  if (endDate < startDate) {
                    toastify("End date is less than start date", "error");
                    return;
                  }
                  onReduxFilter(startDate, endDate, selectedDrug);
                  setRefetch(!refetch);
                }}
              >
                Search
              </Button>

              <Button className="addItem" onClick={() => exportCsv()}>
                Export
              </Button>
            </div>
          </FilterWrapper>
        </div>
      </div>

      <DataTable
        columns={columns}
        data={data || []}
        pagination
        customStyles={customStyles}
        conditionalRowStyles={conditionalRowStyles}
      />
    </div>
  );
}

export default DrugMovementReport;
