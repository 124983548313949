import api from "../axios/axios-config";

export const getAllQuestions = async () => {
  try {
    let response = await api.get("healthDeclaration/listOfHealthDeclaration");

		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const addQuestion = async (
  question, 
  description,
  indicators, 
  enableIndicator,
  enableRemark, 
  isActive, 
  orderIndex
) => {
  try {
    let response = await api.post(
      "healthDeclaration/createHealthDeclaration",
      {
        question,
        description,
        indicators,
        enableIndicator,
        enableRemark,
				isActive,
				orderIndex,
      },
    );

		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const updateQuestion = async (
  questionId,
	question,
  description,
  indicators,
  enableIndicator,
  enableRemark,
  isActive,
  orderIndex,
) => {
  try {
    let response = await api.post(
      "healthDeclaration/updateHealthDeclaration",
      {
        questionId,
        question,
        description,
        indicators,
        enableIndicator,
        enableRemark,
				isActive,
				orderIndex,
      },
    );

		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
};

export const deleteQuestion = async (questionId) => {
  try {
    let response = await api.post(
      "healthDeclaration/updateHealthDeclaration",
      {
        questionId,
        isDeleted: true,
      },
		);

		return response?.data;
	} catch (error) {
		return error?.response?.data;
	}
}