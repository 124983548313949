import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { departmentDetails, getDepartments } from "../../../apis/departments";
import { addUser, updateUser, checkIsDeletedUser } from "../../../apis/staff";
import { Country } from "country-state-city";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { toastify } from "../../../helperFunctions/toastify";
import { ROLES } from "../../../constants";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { capCaseString } from "../../../helperFunctions/utils";
import { getBranches } from "../../../apis/branch";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import SelectComponent from "../../SharedComponents/SelectComponent";
import ProfileSVG from "../../../assets/images/profile.svg";
import { login } from "../../../redux/slices/userSlice";

export default function AddEditUser() {
  const locationState = useLocation();
  let state = { ...locationState.state };
  const { userId, test } = useParams();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userData?.value);

  const navigate = useNavigate();
  const contactRegex = new RegExp("^[0-9]+[0-9]*$");

  const [departments, setDepartments] = useState();
  const [countries, setCountries] = useState();
  const [username, setUsername] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [email, setEmail] = useState();
  const [jobTitle, setJobTitle] = useState();
  const [password, setPassword] = useState();
  const [designation, setDesignation] = useState();
  const [contact, setContact] = useState("");
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [nationalID, setNationalID] = useState();
  const [dob, setDob] = useState();
  const [gender, setGender] = useState();
  const [address, setAddress] = useState();
  const [country, setCountry] = useState();
  const [postalCode, setPostalCode] = useState();
  const [roles, setRoles] = useState(ROLES);
  const [show, setShow] = useState(false);
  const [allBranches, setAllBranches] = useState([]);
  const [allLocations, setAllLocations] = useState([]);
  const [location, setLocation] = useState();
  const [branch, setBranch] = useState();
  const [defaultAppt, setDefaultAppt] = useState(false);
  const [files, setFiles] = useState(null);
  const [previewImg, setPreviewImg] = useState("");

  const usernameRegex = new RegExp(`^[a-z0-9_\-]+$`);

  useEffect(() => {
    if (userId && userData) {
      state = {
        user: {
          ...userData,
          id: userData._id,
          emailText: userData.email,
          createdAt: userData.created_at,
        },
      };

      populateUserData();
    }
  }, [userId]);

  const getDepartmentList = async () => {
    const response = await getDepartments();
    if (response.status !== "Fail") {
      setDepartments(response);
    } else {
      toastify(response?.message, "error");
    }
  };

  const saveUser = async () => {
    if (!username) {
      missingPopup("Username");
    } else if (!email) {
      missingPopup("Email");
    } else if (!validateEmail(email)) {
      toastify("Invalid Email", "error");
    } else if (!selectedDepartment) {
      missingPopup("Department");
    } else if (!password) {
      missingPopup("Password");
    } else if (!selectedRoles?.length > 0) {
      missingPopup("Role");
    }
    // else if (!city) {
    //   missingPopup("City");
    // }
    else {
      // if (
      //   username &&
      //   email &&
      //   selectedDepartment &&
      //   password &&
      //   contact &&
      //   gender &&
      //   designation &&
      //   city &&
      //   country &&
      //   selectedRoles?.length > 0
      // ) {
      let apiData = {
        username,
        email,
        department: selectedDepartment
          ? selectedDepartment._id
          : departments[0]?._id,
        jobTitle,
        password,
        designation,
        contactNumber: contact,
        nationalID,
        dob,
        gender,
        postalCode,
        // city: city ? city?.name : cities[0]?.name,
        country: country,
        address,
        role: JSON.stringify(selectedRoles),
        branch,
        location,
        defaultAppt: defaultAppt,
        profile: files,
      };

      const response = await addUser(apiData);

      if (response.status !== "Fail") {
        toastify("User added successfully!", "success");
        navigate("/staff");
      } else {
        toastify(response.message, "error");
      }
    }
  };
  console.log(selectedRoles);
  const modifyUser = async () => {
    if (!username) {
      missingPopup("Username");
    } else if (!email) {
      missingPopup("Email");
    } else if (!selectedDepartment && !departments) {
      missingPopup("Department");
    } else if (!selectedRoles?.length > 0) {
      missingPopup("Role");
    }
    // else if (!city && !cities) {
    //   missingPopup("City");
    // }
    else {
      // if (
      //   state.user._id &&
      //   username &&
      //   email &&
      //   designation &&
      //   departments &&
      //   contact &&
      //   gender &&
      //   selectedRoles?.length
      // ) {
      let apiData = {
        _id: userId ? userData._id : state.user._id,
        username,
        email,
        department: selectedDepartment
          ? selectedDepartment._id
          : departments[0]._id,
        jobTitle,
        password,
        designation,
        contactNumber: contact,
        nationalID,
        dob,
        gender,
        postalCode,
        // city: city ? city.name : cities[0].name,
        country: country,
        address,
        role: JSON.stringify(selectedRoles),
        branch,
        location,
        isDeleted: false,
        defaultAppt: defaultAppt,
        profile: files,
      };
      const response = await updateUser(apiData);
      if (response?.status !== "Fail") {
        toastify("User updated successfully!", "success");
        if (!userId) {
          navigate("/staff");
        } else {
          dispatch(
            login({
              ...userData,
              username: apiData.username,
              email: apiData.email,
              jobTitle: apiData.jobTitle,
              designation: apiData.designation,
              contactNumber: apiData.contactNumber,
              nationalID: apiData.nationalID,
              dob: apiData.dob,
              gender: apiData.gender,
              postalCode: apiData.postalCode,
              country: apiData.country,
              address: apiData.address,
            })
          );
        }
      } else {
        toastify(response.message, "error");
      }
    }
  };

  const getDepartmentDetail = async () => {
    const myDepartment = await departmentDetails(state.user.department);
    let _departments = await getDepartments();

    // _departments.forEach(function (item, i) {
    // 	if (item.name === myDepartment.name) {
    // 		_departments.splice(i, 1);
    // 		_departments.unshift(item);
    // 	}
    // });
    setDepartments(_departments);
    setSelectedDepartment(
      _departments?.find((o) => o.name === myDepartment.name)
    );
  };

  const setUserCountry = async () => {
    const myCountry = Country.getCountryByCode(state.user.country);
    const allCountries = Country.getAllCountries();

    // allCountries.forEach(function (item, i) {
    // 	if (item.name === myCountry.name) {
    // 		allCountries.splice(i, 1);
    // 		allCountries.unshift(item);
    // 	}
    // });

    setCountries(allCountries);
    setCountry(state.user?.country);
  };

  const populateUserData = async () => {
    setUsername(state.user.username);
    setDefaultAppt(state.user.defaultAppt);
    setEmail(state.user.email);
    setJobTitle(state.user.jobTitle);
    setDesignation(state.user.designation);
    setContact(state.user.contactNumber);
    setSelectedRoles(state.user.role);
    setNationalID(state.user.nationalID);
    setDob(
      state.user.dob && state.user.dob !== "" ? new Date(state.user.dob) : null
    );
    setGender(state.user.gender);
    setAddress(state.user.address);
    setPostalCode(state.user.postalCode);
    setPreviewImg(state?.user?.profile);
  
    getDepartmentDetail();
    setUserCountry();
  
    // Fetch branches first
    if (state?.user?.branch) {
      const response2 = await getBranches();
      if (response2?.status !== "Fail") {
        let _branch = [...response2];
        _branch.forEach((item, i) => {
          if (item?._id === state?.user?.branch) {
            _branch.splice(i, 1);
            _branch.unshift(item);
          }
        });
        setAllBranches(_branch);
        setBranch(state?.user?.branch?._id); // Set branch after fetching branches
  
        const locations = response2.find((e) => e._id === state?.user?.branch)?.location;
        setAllLocations(locations);
        setLocation(state?.user?.location); // Set location after setting locations
      } else {
        toastify(response2?.message, "error");
      }
    }
  };

  console.log('Branches', allBranches);
  console.log('Branch', branch);
  console.log('Locations', allLocations);
  console.log('Location', location);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const populateBranches = async () => {
    const response = await getBranches();
    if (response?.status !== "Fail") {
      setAllBranches(response);
    }
  };

  useEffect(() => {
    getDepartmentList();
    setCountries(Country.getAllCountries());
    populateBranches();
  }, []);

  useEffect(() => {
    if (state?.user && !userId) {
      populateUserData();
    }
  }, [state?.user]);


  useEffect(() => {
    if (branch && branch !== "") {
      const _locations = allBranches.find((e) => e._id === branch)?.location;
      setAllLocations(_locations || []);
    } else if (allBranches.length > 0) {
      // If branch is undefined, set the first branch's locations
      setBranch(allBranches[0]._id);
      setAllLocations(allBranches[0].location || []);
    } else {
      setAllLocations([]);
    }
  }, [branch, allBranches]);

  const handleClickAway = () => {
    setShow(false);
  };

  const uploadFileHandler = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = (e) => {
      setPreviewImg(reader.result);
    };

    setFiles(file);
  };

  return (
    <React.Fragment>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => navigate(-1)}
          ></i>
          {`${state?.user ? "Edit " : "New "}`}
          User
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit">
            <div className="row">
              <div>
                <div className="d-flex">
                  <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                    <img
                      src={previewImg ? previewImg : ProfileSVG}
                      style={{
                        width: "100px",
                        marginBottom: "10px",
                        height: "100px",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                      className="profileImg"
                      alt=""
                    />
                    <br />
                    <input type="file" onChange={uploadFileHandler}></input>
                  </div>
                </div>
                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Username *</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => {
                          setUsername(e.target.value);
                        }}
                        value={username}
                        required={true}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />

                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Department *</Form.Label>
                    <SelectComponent
                      isDisabled={userId !== undefined}
                      singleSelect
                      searchEnabled
                      selectedValue={
                        selectedDepartment
                          ? JSON.stringify(selectedDepartment)
                          : null
                      }
                      options={
                        departments?.length > 0
                          ? [
                              ...departments.map((item) => {
                                return {
                                  label: item.name,
                                  value: JSON.stringify(item),
                                };
                              }),
                            ]
                          : []
                      }
                      onChange={(e) => {
                        let val = e.value;
                        if (val === "") {
                          setSelectedDepartment();
                        } else {
                          setSelectedDepartment(JSON.parse(e.value));
                        }
                      }}
                    />
                    {/* <select
											className="form-select mb-3"
											onChange={(e) => {
												let val = e.target.value;
												if (val === "") {
													setSelectedDepartment();
												} else {
													setSelectedDepartment(
														JSON.parse(
															e.target.value,
														),
													);
												}
											}}
										>
											{!state?.user && (
												<option value={""}>
													Select
												</option>
											)}
											{departments?.map((e) => (
												<option
													value={JSON.stringify(e)}
												>
													{e.name}
												</option>
											))}
										</select> */}
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Email *</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required={true}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />

                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Job Title</Form.Label>
                    <InputGroup
                      className={`mb-3 ${
                        userId !== undefined ? "disabled-style" : ""
                      }`}
                    >
                      <Form.Control
                        disabled={userId !== undefined}
                        placeholder="Write..."
                        onChange={(e) => setJobTitle(e.target.value)}
                        value={jobTitle}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Password *</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        // disabled={state?.user}
                        type="password"
                        placeholder={state?.user ? "*****" : "Write..."}
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        required={true}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />

                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Designation</Form.Label>
                    <InputGroup
                      className={`mb-3 ${
                        userId !== undefined ? "disabled-style" : ""
                      }`}
                    >
                      <Form.Control
                        disabled={userId !== undefined}
                        placeholder="Write..."
                        onChange={(e) => setDesignation(e.target.value)}
                        value={designation}
                      />
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Contact No</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => {
                          if (contactRegex.test(e.target.value)) {
                            setContact(e.target.value);
                          }
                        }}
                        value={contact}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />

                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Role *</Form.Label>
                    <select
                      disabled={userId !== undefined}
                      className="form-select mb-3"
                      aria-label="Default select example"
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val !== "" && !selectedRoles.includes(val)) {
                          setSelectedRoles((oldArr) => [...oldArr, val]);
                        }
                      }}
                    >
                      <option value={""}>Select</option>
                      {roles?.map((e) => (
                        <option value={e}>{capCaseString(e)}</option>
                      ))}
                    </select>
                    <div className="d-flex flex-wrap">
                      {selectedRoles?.map((e) => (
                        <div className="multi-roles-parent">
                          {e}
                          {!userId && (
                            <div className="multi-roles-child">
                              <i
                                className="fa-solid fa-xmark"
                                onClick={() => {
                                  let _data = [...selectedRoles].filter(
                                    (f) => f !== e
                                  );
                                  setSelectedRoles(_data);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">National ID</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setNationalID(e.target.value)}
                        value={nationalID}
                        required={true}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />
                  <Form.Group className="col-5 mt-3">
                  <Form.Label className="headings2">Branch</Form.Label>
                  <SelectComponent
                    isDisabled={userId !== undefined}
                    singleSelect
                    searchEnabled
                    adjustValues
                    selectedValue={branch || allBranches?.[0]?._id} // Ensure using _id as value
                    options={allBranches}
                    onChange={(e) => {
                      setLocation(null);
                      setBranch(e.value);
                    }}
                  />
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div className="col-5 mt-3">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dob}
                          onChange={(newValue) => {
                            setDob(newValue?.$d);
                            setShow(false);
                          }}
                          open={show}
                          maxDate={new Date()}
                          renderInput={({
                            inputRef,
                            inputProps,
                            InputProps,
                          }) => (
                            <Form.Group className="">
                              <Form.Label className="headings2">
                                Date of Birth
                              </Form.Label>
                              <InputGroup className="mb-3 d-flex pe-3 align-items-center">
                                <Form.Control
                                  ref={inputRef}
                                  // {...inputProps}
                                  value={
                                    dob
                                      ? new Date(dob).toLocaleDateString()
                                      : null
                                  }
                                  onClick={() => setShow(!show)}
                                  readOnly
                                  placeholder="Select"
                                />
                                <div onClick={() => setShow(!show)}>
                                  {InputProps?.endAdornment}
                                </div>
                              </InputGroup>
                            </Form.Group>
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </ClickAwayListener>

                  <span className="col-1" />

                  <Form.Group className="col-5 mt-3">
                  <Form.Label className="headings2">Location</Form.Label>
                  <SelectComponent
                    isDisabled={userId !== undefined}
                    singleSelect
                    searchEnabled
                    adjustValues
                    selectedValue={location || allLocations?.[0]?._id} // Ensure using _id as value
                    options={allLocations}
                    onChange={(e) => {
                      setLocation(e.value);
                    }}
                  />
                    {/* <select
											className="form-select mb-3"
											onChange={(e) => {
												setLocation(e.target.value);
											}}
										>
											{!state?.user?.location && (
												<option value="" selected>
													Select Location
												</option>
											)}
											{allLocations?.map((e) => (
												<option
													value={e._id}
													selected={
														location === e._id
													}
												>
													{e.name}
												</option>
											))}
										</select> */}
                  </Form.Group>
                </div>

                <Form.Group className="col-5 mt-3">
                  <Form.Label className="headings2 d-flex">Gender</Form.Label>
                  <Form.Check
                    inline
                    label="Male"
                    type={"radio"}
                    onClick={() => setGender("Male")}
                    checked={gender === "Male"}
                  />
                  <Form.Check
                    inline
                    label="Female"
                    type={"radio"}
                    onClick={() => setGender("Female")}
                    checked={gender === "Female"}
                  />
                </Form.Group>

                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Address</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setAddress(e.target.value)}
                        value={address}
                        required={true}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />

                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Country</Form.Label>

                    <SelectComponent
                      singleSelect
                      searchEnabled
                      selectedValue={country}
                      options={
                        countries?.length > 0
                          ? [
                              ...countries?.map((item) => {
                                return {
                                  value: item.isoCode,
                                  label: item.name,
                                };
                              }),
                            ]
                          : []
                      }
                      onChange={(e) => {
                        setCountry(e.value);
                      }}
                    />
                    {/* <select
											className="form-select mb-3"
											onChange={(e) => {
												let val = e.target.value;
												if (val === "") {
													setCountry();
													// setCity();
													// setCities([]);
												} else {
													setCountry(
														JSON.parse(
															e.target.value,
														),
													);
												}
											}}
										>
											{!state?.user && (
												<option
													value={""}
													defaultChecked
												>
													Select
												</option>
											)}
											{countries?.map((e) => (
												<option
													value={JSON.stringify(e)}
												>
													{e.name}
												</option>
											))}
										</select> */}
                  </Form.Group>
                </div>

                <div className="d-flex">
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">Postal Code</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="Write..."
                        onChange={(e) => setPostalCode(e.target.value)}
                        value={postalCode}
                      />
                    </InputGroup>
                  </Form.Group>

                  <span className="col-1" />

                  {/* <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2">City *</Form.Label>

                    <select
                      className="form-select mb-3"
                      onChange={(e) => {
                        let val = e.target.value;
                        if (val === "") {
                          setCity();
                        } else {
                          setCity(JSON.parse(e.target.value));
                        }
                      }}
                    >
                      {!state?.user && (
                        <option value={""} defaultChecked>
                          Select
                        </option>
                      )}
                      {cities?.map((e, id) => (
                        <option value={JSON.stringify(e)}>{e.name}</option>
                      ))}
                    </select>
                  </Form.Group> */}
                </div>

                {/* {!userId && (
                  <Form.Group className="col-5 mt-3">
                    <Form.Label className="headings2 d-flex">
                      Default Selected for Appointment
                    </Form.Label>
                    <Form.Check
                      inline
                      label="Yes"
                      type={"radio"}
                      onClick={() => setDefaultAppt(true)}
                      checked={defaultAppt === true}
                    />
                    <Form.Check
                      inline
                      label="No"
                      type={"radio"}
                      onClick={() => setDefaultAppt(false)}
                      checked={defaultAppt === false}
                    />
                  </Form.Group>
                )} */}

                <Button
                  className="addItem mt-4"
                  onClick={async () => {
                    const deletedUser = await checkIsDeletedUser(
                      email,
                      username
                    );
                    if (deletedUser?.isDeleted === true && !state.user) {
                      state.user = { ...deletedUser };
                    }

                    if (
                      state?.user ||
                      userId !== undefined ||
                      deletedUser?.isDeleted === true
                    ) {
                      modifyUser();
                    } else {
                      saveUser();
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
