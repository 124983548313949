import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { getReceivingHistory } from "../../../../apis/products";
import {
    getReceivingGoods,
    updateReceivingGoods,
} from "../../../../apis/receivingGoods";
import { toastify } from "../../../../helperFunctions/toastify";
import DeleteModal from "../../../SharedComponents/DeleteModal";

export default function ReceivingHistory() {
    const navigate = useNavigate();
    const [history, setHistory] = useState([]);
    const [show, setShow] = useState(false);
    const [deleting, setDeleting] = useState("");
    const { state } = useLocation();

    const handleClose = () => {
        setShow(false);
        setDeleting("");
    };

    const populateData = async () => {
        const response = await getReceivingHistory(state?.product?._id);
        if (response?.status !== "Fail") {
            setHistory(response.data);
        } else {
            toastify(response?.message, "error");
        }
    };

    const removeReceivingGood = async () => {
        setShow(false);
        let response = await updateReceivingGoods(deleting, {
            isDeleted: true,
        });
        if (response?.status !== "Fail") {
            populateData();
            toastify("Receiving Goods deleted successfully!", "success");
        } else {
            toastify(response?.message, "error");
        }
    };

    useEffect(() => {
        populateData();
    }, []);

    const getTotalQty = (prod) => {
        let total = 0;
        prod.forEach((element) => {
            total += element.recivedQty;
        });
        return total;
    };

    const getBatchNo = (prod) => {
        console.log({ prod });
        return (
            <div>
                {prod
                    .filter((e) => e.product._id === state.product._id)
                    .map((e) => (
                        <div>{e.batchNo}</div>
                    ))}
            </div>
        );
    };

    const getExpiry = (prod) => {
        return (
            <div>
                {prod
                    .filter((e) => e.product._id === state.product._id)
                    .map((e) => (
                        <div>
                            {" "}
                            {new Date(e.expiryDate).toLocaleDateString()}
                        </div>
                    ))}
            </div>
        );
    };

    const columns = [
        {
            name: "Receiving Date",
            selector: (row) => new Date(row.receivedDate).toLocaleDateString(),
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Receiving Doc No",
            selector: (row) => row.receivedDocNo,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Batch No",
            selector: (row) => row.batchNo,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Expiry Date",
            selector: (row) => row.expiry,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Receiving Quantity",
            selector: (row) => getTotalQty(row.products),
            wrap: true,
            minWidth: "170px",
        },
        {
            name: "Total Cost",
            selector: (row) => row.totalCost,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Invoice GST (%)",
            selector: (row) => row.invoiceGST,
            wrap: true,
            minWidth: "160px",
        },
        {
            name: "Invoice Total",
            selector: (row) => row.invoiceTotal,
            wrap: true,
            minWidth: "160px",
        },
    ].reverse();

    const data = history?.map((e, index) => {
        return {
            index: index,
            id: e._id,
            receivedDate: e.receivedDate,
            receivedDocNo: e.receivedDocNo,
            products: e.products,
            totalCost: e.totalCost,
            invoiceGST: e.invoiceGST,
            invoiceTotal: e.invoiceTotal,
            batchNo: getBatchNo(e.products),
            expiry: getExpiry(e.products),
        };
    });

    const customStyles = {
        headCells: {
            style: {
                justifyContent: "center",
            },
        },
        cells: {
            style: {
                justifyContent: "center",
                textAlign: "center",
            },
        },
    };

    const conditionalRowStyles = [
        {
            when: (row) => row,
            style: {
                borderWidth: "0px",
            },
        },
        {
            when: (row) => row.index % 2 === 1,
            style: {
                borderWidth: "0px",
                backgroundColor: "#f8f8f8",
            },
        },
    ];

    return (
        <>
            <div className="container p-0 rev-table">
                <div className="mainHeading px-4">
                    <h1>
                        <i
                            className="fa fa-arrow-left cursor-pointer"
                            onClick={() => navigate(-1)}
                        ></i>
                        Product Details / Receiving History
                    </h1>
                </div>

                <DeleteModal
                    content="Confirm Purchase List Item Deletion?"
                    onYes={removeReceivingGood}
                    onNo={handleClose}
                    show={show}
                />

                <DataTable
                    columns={columns}
                    customStyles={customStyles}
                    data={data || []}
                    pagination
                    conditionalRowStyles={conditionalRowStyles}
                />
            </div>
        </>
    );
}
