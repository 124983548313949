import React from "react";
import { Col, Container, Form, Row } from "react-bootstrap";

export default function MultipleIndicator({ question, grpQuestion, onChange }) {
	return (
		<Col className="mb-3" md={question.size ? question.size : 12} key={question.question}>
			<div className="question-title">{question?.question}</div>
				{question?.indicators?.map((i, idx) => (
					<div>
						<div className="question-header">{i.indicator}</div>
						<div className=" mx-4">
							{i?.options?.map((o) => (
								<div className="mt-3">
									<Form.Check
										type={"checkbox"}
										label={o}
										size={"lg"}
										checked={
											question.patient_answer
												?.find(
													(q) =>
														q.indicator ===
														i.indicator,
												)
												?.answers?.indexOf(o) > -1
										}
										onChange={() => {
											onChange(
												question._id,
												{
													indicator: i.indicator,
													label: o,
												},
												question?.questionType,
												grpQuestion,
											);
										}}
									/>
								</div>
							))}
						</div>
					</div>
				))}
		</Col>
	);
}
