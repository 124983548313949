import React, { useEffect } from "react";
import { useState } from "react";
import Select from "react-select";
import {
  Button,
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { capCaseString } from "../../../helperFunctions/utils";
import {
  createNewTemplate,
  setAllCollections,
  setInPreview,
  setNewTemplate,
  setPreviewTemplate,
} from "../../../redux/slices/DataTableTemplateSlice";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function AddEditTemplate() {
  /////////////////Declarations\\\\\\\\\\\\\\\\
  const params = useParams();
  const { id } = params;

  const { allCollections, newTemplate, inPreview } = useSelector(
    (state) => state.templateData
  );
  console.log("what is in allCollections", allCollections);

  const [addNewTemplate, setAddNewTemplate] = useState(newTemplate);

  console.log("addNewTemplate...", addNewTemplate);
  const [selectedCategory, setSelectedCategory] = useState({
    orderId: "",
    name: "",
  });
  const [categoryList, setCategoryList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  /////////////////functions\\\\\\\\\\\\\\\\\\\\

  const onFormChange = (e) => {
    const { name, value } = e.target;
    setAddNewTemplate((val) => {
      return {
        ...val,
        [name]: value,
      };
    });
  };

  const formValidation = () => {
    if (!addNewTemplate.name || !addNewTemplate.table_name)
      return { status: false, messgae: "Required fields can't be empty" };
    if (
      addNewTemplate.columns.find(
        (o) => (!o.name && !o.column) || !o.name || !o.column
      )
    )
      return {
        status: false,
        messgae: "Please fill all column fields or remove empty fields",
      };
    if (
      addNewTemplate.columns.some(
        (obj, index, self) =>
          index !==
          self.findIndex(
            (t) => JSON.stringify(t.name) === JSON.stringify(obj.name)
          )
      )
    )
      return { status: false, messgae: "Column names can't be same" };
    return { status: true };
  };

  const getTemplate = async (id, table_name, columns) => {
    const query = {
      id: id,
      table_name,
      columns,
    };
    const { result, error } = await authorizedRequests({
      url: `collections/templates-data/?query=${JSON.stringify(query)}`,
      method: "get",
    });
    if (error) {
      toastify(error?.message ?? "Unknown error occured", "error");
      navigate(-1);
      return;
    }
    setAddNewTemplate(result.data.template);
    return result.data;
  };

  const getCategory = async () => {
    const { result, error } = await authorizedRequests({
      url: `category/listOfCategory`,
      method: "get",
    });

    if (error) {
      toastify(error?.message ?? "Unknown error occured", "error");
      navigate(-1);
      return;
    }
    setCategoryList(result?.data);
    return result.data;
  };

  const onColumnChange = (e, index, ref, items) => {
    const { name, value } = e.target;
    setAddNewTemplate({
      ...addNewTemplate,
      columns: addNewTemplate?.columns?.map((item, i) => {
        if (index === i)
          return {
            ...item,
            [name]: value,
            ref: name == "column" ? ref : item.ref,
            refColumn: name == "column" ? null : item?.refColumn,
            isItem: items?.length > 0 ? true : false,
          };
        return item;
      }),
    });
  };

  const addNewColumn = (e) => {
    const tempArr = [...addNewTemplate.columns];
    tempArr.push({
      name: "",
      column: null,
    });
    setAddNewTemplate({
      ...addNewTemplate,
      columns: tempArr,
    });
  };

  const deleteRow = (index) => {
    let tempArr = [...addNewTemplate.columns];
    if (addNewTemplate.columns.length === 1) {
      toastify("atleast 1 column is required", "error");
      return;
    }
    tempArr = tempArr.filter((o, i) => i !== index);
    setAddNewTemplate({
      ...addNewTemplate,
      columns: [...tempArr],
    });
  };

  const saveNewTemplate = async (e) => {
    e.preventDefault();

    const validation = formValidation();
    if (!validation.status) {
      toastify(validation.messgae, "error");
      return;
    }
    try {
      // console.log({ data: { ...addNewTemplate } });
      const { result, error } = await authorizedRequests({
        url: `${id ? "collections/templates/" + id : "collections"}`,
        method: `${id ? "patch" : "post"}`,
        data: { ...addNewTemplate },
      });
      if (error) {
        toastify(error.message ?? "Failed to save template", "error");
        return;
      }

      dispatch(createNewTemplate(result.data));
      dispatch(
        setNewTemplate({
          name: "",
          description: "",
          table_name: "",
          category: "",
          columns: [
            {
              name: "",
              column: null,
            },
          ],
          // filter: false,
          total: false,
        })
      );
      dispatch(setInPreview(false));

      navigate(-1);
    } catch (error) {
      toastify(error.message ?? "Failed to save template", "error");
      return;
    }
  };

  const checkFilter = (col) => {
    let table = addNewTemplate.table_name;
    let column = col.column;

    if (table && column) {
      switch (table) {
        case "patients":
          if (
            [
              "patientProfile.gender",
              "isMember",
              "patientProfile.country.name",
              "patientProfile.preferredContact",
              "patientProfile.hearAboutUs",
              "patientProfile.recommendFrom",
              "patientProfile.kol",
            ].includes(column)
          ) {
            return true;
          } else {
            return false;
          }

        case "payments":
          if (
            [
              "patient",
              "isPaymentCompleted",
              "categoryCosts",
              "recommend",
              "campaign",
              "isPayed",
            ].includes(column)
          ) {
            return true;
          } else {
            return false;
          }

        case "products":
          if (["expDate", "name", "uom", "type", "supplier"].includes(column)) {
            return true;
          } else {
            return false;
          }

        case "packages":
          if (["effectiveDate", "expiredDate", "isActive"].includes(column)) {
            return true;
          } else {
            return false;
          }

        case "treatments":
          if (["tag"].includes(column)) {
            return true;
          } else {
            return false;
          }

        case "stockadjustments":
          if (["isFinalized", "product"].includes(column)) {
            return true;
          } else {
            return false;
          }

        case "sessions":
          if (["date", "staff", "status", "appointment"].includes(column)) {
            return true;
          } else {
            return false;
          }

        case "appointments":
          if (["date", "staff", "status", "patient", "tag"].includes(column)) {
            return true;
          } else {
            return false;
          }

        case "waitinglists":
          if (["date"].includes(column)) {
            return true;
          } else {
            return false;
          }

        default:
          return false;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const { result, error } = await authorizedRequests({
          url: "collections",
          method: "get",
        });
        if (error)
          toastify("Failed to get Table names.Please reload the page", "error");
        dispatch(setAllCollections(result.data));
      } catch (error) {
        toastify("Failed to get Table names.Please reload the page", "error");
      }
    })();
  }, []);

  useEffect(() => {
    if (id && !inPreview) {
      getTemplate(id);
    }
  }, [id, inPreview]);

  useEffect(() => {
    dispatch(setPreviewTemplate([]));
    getCategory();
  }, []);

  useEffect(() => {
    const filteredObject = categoryList.find(
      (item) => item.name === addNewTemplate.category
    );
    setSelectedCategory(filteredObject);
  }, [addNewTemplate]);

  useEffect(() => {
    if (location.state) {
      setAddNewTemplate({
        table_name: location.state.table_name,
        columns: location.state.columns,
        // filter: location.state.filter,
      });
    }
  }, [location.state]);

  return (
    <>
      <div className="mainHeading px-4">
        <h1>
          <i
            className="fa fa-arrow-left cursor-pointer"
            onClick={() => {
              dispatch(
                setNewTemplate({
                  name: "",
                  description: "",
                  table_name: "",
                  columns: [
                    {
                      name: "",
                      column: null,
                    },
                  ],
                  // filter: false,
                  total: false,
                })
              );
              navigate(-1);
            }}
          ></i>
          {`${id ? "Edit " : "New "}`}
          Template
        </h1>
      </div>
      <div className="pb-4 px-4">
        <div className="content">
          <div className="addEdit pb-4">
            <div className="row">
              <div>
                <Form.Group className="col-md-6">
                  <Form.Label className="headings2 required">Name</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      name={"name"}
                      onChange={onFormChange}
                      value={addNewTemplate?.name}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label className="headings2 required">
                    Category Report
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={addNewTemplate?.category}
                    options={
                      categoryList?.length > 0
                        ? [
                            ...categoryList
                              ?.filter((a) => a.isDeleted !== true)
                              ?.map((o) => {
                                return {
                                  value: o.name,
                                  label: o.name,
                                };
                              }),
                          ]
                        : []
                    }
                    onChange={(e) => {
                      setAddNewTemplate((val) => {
                        return {
                          ...val,
                          category: e.label,
                        };
                      });
                    }}
                  />
                  {/* <Select
                    defaultValue={selectedCategory}
                    value={selectedCategory}
                    onChange={(e) => {
                      setAddNewTemplate((val) => {
                        return {
                          ...val,
                          category: e.name,
                        };
                      });
                    }}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.orderId}
                    options={categoryList}
                    className="select"
                    id="category"
                    name="category"
                    placeholder="Select Category"
                  /> */}
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label className="headings2">Description</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Write..."
                      name={"description"}
                      rows={4}
                      style={{ resize: "none" }}
                      as="textarea"
                      aria-label="With textarea"
                      onChange={onFormChange}
                      value={addNewTemplate?.description}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="col-md-6">
                  <Form.Label className="black-label-text required">
                    Collections
                  </Form.Label>
                  <SelectComponent
                    singleSelect
                    searchEnabled
                    selectedValue={addNewTemplate?.table_name}
                    options={
                      allCollections?.length > 0
                        ? [
                            ...allCollections
                              ?.filter((a) => a.display === true)
                              ?.map((o) => {
                                return {
                                  value: o.name,
                                  label: o.label,
                                };
                              }),
                          ]
                        : []
                    }
                    onChange={(e) => {
                      onFormChange({
                        target: {
                          name: "columns",
                          value: [
                            {
                              name: "",
                              column: "",
                            },
                          ],
                        },
                      });
                      onFormChange({
                        target: {
                          name: "table_name",
                          value: e.value,
                        },
                      });
                    }}
                  />

                  {/* <select
                    name="table_name"
                    className="form-select mb-3"
                    onChange={(e) => {
                      onFormChange({
                        target: {
                          name: "columns",
                          value: [
                            {
                              name: "",
                              column: "",
                            },
                          ],
                        },
                      });
                      onFormChange(e);
                    }}
                  >
                    <option value={null}></option>
                    {allCollections.length > 0 &&
                      allCollections?.map((e) => (
                        <>
                          <option
                            value={e.name}
                            selected={e.name == addNewTemplate?.table_name}
                          >
                            {e.name}
                          </option>
                        </>
                      ))}
                    {allCollections.length == 0 && (
                      <option value={null} disabled={true}>
                        Loading ....
                      </option>
                    )}
                  </select> */}
                </Form.Group>
                {/* <Form.Group className="col-md-6">
                                    <Form.Label className="black-label-text ">
                                        Enable Filters?
                                    </Form.Label>
                                    <div className="d-flex align-items-center">
                                        <input
                                            className="form-check-input me-3"
                                            type="checkbox"
                                            id="flexCheckDefault"
                                            onChange={(e) =>
                                                setAddNewTemplate({
                                                    ...addNewTemplate,
                                                    filter: e.target.checked,
                                                })
                                            }
                                            checked={addNewTemplate?.filter}
                                        />
                                        {addNewTemplate?.filter
                                            ? "Checked"
                                            : "Unchecked"}
                                    </div>
                                </Form.Group> */}
              </div>
            </div>
          </div>
          <div className="grayBack">
            <div className=" d-flex justify-content-between align-self-center">
              <div
                className="grayLabel font-400-20"
                style={{ fontWeight: 600 }}
              >
                Field
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 400, hide: 400 }}
                  overlay={(props) => {
                    return (
                      <Tooltip {...props}>
                        <div
                          className="w-100 mb-2"
                          style={{
                            textAlign: "start",
                          }}
                        >
                          Name: Table column name
                        </div>
                        <div
                          className="w-100 mb-2"
                          style={{
                            textAlign: "start",
                          }}
                        >
                          Field: Table column value
                        </div>
                        <div
                          className="w-100 mb-2"
                          style={{
                            textAlign: "start",
                          }}
                        >
                          Field of (Selected Ref): Data that refers to a
                          reference field / table.
                        </div>
                      </Tooltip>
                    );
                  }}
                >
                  <i
                    className="fa pointer fa-question-circle ms-2"
                    style={{
                      color: "white",
                      background: "black",
                      borderRadius: "50%",
                      border: "2px solid black",
                    }}
                  ></i>
                </OverlayTrigger>
              </div>
              <Button className="addItem" onClick={addNewColumn}>
                + Add Field
              </Button>
            </div>
            {addNewTemplate?.columns?.length > 0 &&
              addNewTemplate.columns.map((column, i) => {
                return (
                  <>
                    <div className="d-flex row align-items-center">
                      <Form.Group className="col-md-3">
                        <Form.Label className="black-label-text required">
                          Name
                        </Form.Label>
                        <InputGroup className="mb-3">
                          <Form.Control
                            placeholder="Write..."
                            name={"name"}
                            onChange={(e) => {
                              onColumnChange(e, i);
                            }}
                            value={column.name}
                          />
                        </InputGroup>
                      </Form.Group>
                      <Form.Group className="col-md-3">
                        <Form.Label className="black-label-text required">
                          Field
                        </Form.Label>
                        <SelectComponent
                          singleSelect
                          searchEnabled
                          selectedValue={column.column}
                          options={
                            addNewTemplate.table_name &&
                            allCollections.length > 0
                              ? [
                                  ...allCollections
                                    ?.find(
                                      (o) =>
                                        o.name === addNewTemplate.table_name
                                    )
                                    ?.schema?.map((e) => {
                                      let label = e.path.split(".");
                                      return {
                                        label: `${
                                          capCaseString(label[1]) ??
                                          capCaseString(label[0])
                                        }
                                                                                    ${
                                                                                      e.refCollection
                                                                                        ? " - Ref " +
                                                                                          capCaseString(
                                                                                            e.refCollection
                                                                                          )
                                                                                        : ""
                                                                                    }`,
                                        value: e.path,
                                        ref: e.refCollection,
                                        items: e.items,
                                      };
                                    }),
                                ]
                              : []
                          }
                          onChange={(e) => {
                            onColumnChange(
                              {
                                target: {
                                  name: "column",
                                  value: e.value,
                                },
                              },
                              i,
                              e.ref,
                              e.items
                            );
                          }}
                        />
                      </Form.Group>
                      {column?.isItem && (
                        <>
                          <Form.Group className="col-md-3">
                            <Form.Label className="black-label-text required">
                              Field of {capCaseString(column.column)}
                            </Form.Label>
                            <SelectComponent
                              singleSelect
                              searchEnabled
                              selectedValue={column.item}
                              options={
                                addNewTemplate.table_name &&
                                allCollections.length > 0
                                  ? [
                                      ...allCollections
                                        ?.find(
                                          (o) =>
                                            o.name === addNewTemplate.table_name
                                        )
                                        ?.schema?.find(
                                          (e) => e.path === column.column
                                        )
                                        ?.items?.map((e) => {
                                          let label = e.split(".");
                                          return {
                                            label: `${
                                              capCaseString(label[1]) ??
                                              capCaseString(label[0])
                                            }`,
                                            value: e,
                                            // ref: e.refCollection,
                                          };
                                        }),
                                    ]
                                  : []
                              }
                              onChange={(e) => {
                                const tempArr = [...addNewTemplate.columns];
                                tempArr[i] = {
                                  ...tempArr[i],
                                  item: e.value,
                                };
                                setAddNewTemplate({
                                  ...addNewTemplate,
                                  columns: [...tempArr],
                                });
                              }}
                            />
                          </Form.Group>
                        </>
                      )}
                      {column?.ref && (
                        <>
                          <Form.Group className="col-md-3">
                            <Form.Label className="black-label-text required">
                              Field of {capCaseString(column.ref)}
                            </Form.Label>
                            <SelectComponent
                              singleSelect
                              searchEnabled
                              selectedValue={column.refColumn}
                              options={
                                addNewTemplate.table_name &&
                                allCollections.length > 0
                                  ? [
                                      ...allCollections
                                        ?.find((o) => o.name === column.ref)
                                        ?.schema?.filter(
                                          (e) => !e.refCollection
                                        )
                                        ?.map((e) => {
                                          let label = e.path.split(".");
                                          return {
                                            label: `${
                                              capCaseString(label[1]) ??
                                              capCaseString(label[0])
                                            }`,
                                            value: e.path,
                                            // ref: e.refCollection,
                                          };
                                        }),
                                    ]
                                  : []
                              }
                              onChange={(e) => {
                                const tempArr = [...addNewTemplate.columns];
                                tempArr[i] = {
                                  ...tempArr[i],
                                  refColumn: e.value,
                                };
                                setAddNewTemplate({
                                  ...addNewTemplate,
                                  columns: [...tempArr],
                                });
                                // onColumnChange(
                                // 	{
                                // 		target: {
                                // 			name: "column",
                                // 			value: e.value,
                                // 			ref: e.ref,
                                // 			refColumn:
                                // 				e.path,
                                // 		},
                                // 	},
                                // 	i,
                                // );
                              }}
                            />
                          </Form.Group>
                        </>
                      )}
                      {checkFilter(column) ? (
                        <Form.Group className="col-md-3">
                          <div className="">
                            <input
                              className="form-check-input me-3"
                              type="checkbox"
                              id="flexCheckDefault"
                              checked={column?.filter || false}
                              onChange={(e) => {
                                onColumnChange(
                                  {
                                    target: {
                                      name: "filter",
                                      value: e.target.checked,
                                    },
                                  },
                                  i,
                                  e.ref
                                );
                              }}
                            />
                            Enable Filter
                          </div>
                          {
                            (column.column === "expDate" && addNewTemplate?.table_name === "products") && (
                              <div>
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  checked={addNewTemplate?.showExpired || false}
                                  onChange={(e) => {
                                    onFormChange({
                                      target: {
                                        name: "showExpired",
                                        value: e.target.checked
                                      }
                                    });
                                  }}
                                />
                                Show Expired
                              </div>
                            )
                          }
                          <div className="cursor-pointer align-self-center mt-3">
                            <i
                              onClick={() => {
                                deleteRow(i);
                              }}
                              className="fa fa-trash fa-2x "
                            ></i>
                          </div>
                        </Form.Group>
                      ) : (
                        <Form.Group className="col-md-3">
                          {
                            (column.column === "lastPayment" && addNewTemplate?.table_name === "patients") && (
                              <div>
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  checked={addNewTemplate?.isNewPatient || false}
                                  onChange={(e) => {
                                    onFormChange({
                                      target: {
                                        name: "isNewPatient",
                                        value: e.target.checked
                                      }
                                    });
                                  }}
                                />
                                Is New Patients
                              </div>
                            )
                          }
                          {
                            (column.column === "isActive" && addNewTemplate?.table_name === "patients") && (
                              <div>
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  checked={addNewTemplate?.isExpired || false}
                                  onChange={(e) => {
                                    onFormChange({
                                      target: {
                                        name: "isExpired",
                                        value: e.target.checked
                                      }
                                    });
                                  }}
                                />
                                Is Expired
                              </div>
                            )
                          }
                          {
                            (column.column === "paymentRecord" && column.item === "remaining") && (
                              <div>
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  checked={addNewTemplate?.isOutstanding || false}
                                  onChange={(e) => {
                                    onFormChange({
                                      target: {
                                        name: "isOutstanding",
                                        value: e.target.checked
                                      }
                                    });
                                  }}
                                />
                                Is Outstanding
                              </div>
                            )
                          }
                          {
                            (column.column === "paymentRecord" && column.item === "payed") && (
                              <div>
                                <input
                                  className="form-check-input me-3"
                                  type="checkbox"
                                  id="flexCheckDefault"
                                  checked={addNewTemplate?.isOverpay || false}
                                  onChange={(e) => {
                                    onFormChange({
                                      target: {
                                        name: "isOverpay",
                                        value: e.target.checked
                                      }
                                    });
                                  }}
                                />
                                Is Overpay
                              </div>
                            )
                          }
                          <div className="cursor-pointer align-self-center mt-3">
                            <i
                              onClick={() => {
                                deleteRow(i);
                              }}
                              className="fa fa-trash fa-2x "
                            ></i>
                          </div>
                        </Form.Group>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
          <div className="addEdit ">
            {/* <div>
							<Button
								className="my-add-button my-3"
								onClick={async () => {
									const validation = formValidation();
									if (!validation.status) {
										toastify(validation.messgae, "error");
										return;
									}
									// const previewData = await getTemplate(
									// 	undefined,
									// 	addNewTemplate.table_name,
									// 	addNewTemplate.columns,
									// );
									// dispatch(
									// 	setPreviewTemplate(
									// 		previewData.table_data,
									// 	),
									// );
									// dispatch(setInPreview(true));
									// dispatch(
									// 	setNewTemplate({ ...addNewTemplate }),
									// );

									navigate(`/preview-template/0`, {
										state: {
											table_name:
												addNewTemplate.table_name,
											columns: addNewTemplate.columns,
										},
									});
								}}
							>
								Preview
							</Button>
						</div> */}
            <div>
              <Button className="addItem mt-4" onClick={saveNewTemplate}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
