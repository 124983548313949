import { useNavigate } from "react-router-dom";
// import { ResponsiveBar } from "@nivo/bar";
import { useEffect, useMemo, useState } from "react";
import { toastify } from "../../../helperFunctions/toastify";
import DateInput from "../../SharedComponents/DateInput";
import { getPatientGeneralReports } from "../../../apis/reports";
import BarGraph from "./BarGraph";
import { Bar } from "@nivo/bar";
import DataTable from "react-data-table-component";
import PieChart from "./PieChart";
import {
  exportData,
  getAgeFromDob,
  getInvoiceId,
  removeDuplicatesFromArray,
} from "../../../helperFunctions/utils";
import SelectComponent from "../../SharedComponents/SelectComponent";
import { Button, Form } from "react-bootstrap";
import { yearsOptions } from "../../../constants";

export const filterTypes = [
  {
    label: "Date Range",
    value: "date",
  },
  {
    label: "By Month",
    value: "month",
  },
  {
    label: "By Quarter",
    value: "quarter",
  },
];

export default function PatientGeneralReport() {
  const navigate = useNavigate();

  const [generalGraphData, setGeneralGraphData] = useState([]);
  const [patientGrowthChart, setPatientGrowthChart] = useState([]);
  const [allData, setAllData] = useState([]);
  const [genderChart, setGenderChart] = useState([]);
  const [maritalChart, setMaritalChart] = useState([]);
  const [ageChart, setAgeChart] = useState([]);
  const [locationChart, setLocationChart] = useState([]);

  // const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedQuaterStart, setSelectedQuaterStart] = useState(null);
  const [quarterStartYear, setQuarterStartYear] = useState();
  const [quarterEndYear, setQuarterEndYear] = useState();
  const [selectedQuarterEnd, setSelectedQuaterEnd] = useState();
  const [range, setRange] = useState({
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(),
  });

  const [months, setMonths] = useState({
    start: new Date(new Date().getFullYear(), 0, 1),
    end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
  });

  const [filterType, setFilterType] = useState("date");

  const quaterOptions = [
    {
      label: "Jan - Mar",
      value: "January-March",
    },
    {
      label: "Apr - June",
      value: "April-June",
    },
    {
      label: "Jul - Sept",
      value: "July-September",
    },
    {
      label: "Oct - Dec",
      value: "October-December",
    },
  ];

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), 0, 1)
  );
  const [endDate, setEndDate] = useState(new Date());

  const onApply = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const onQuarterApply = () => {
    let sYear = quarterStartYear;
    let sQuarter = selectedQuaterStart;
    let eQuarter = selectedQuarterEnd;
    let eYear = quarterEndYear;

    if (sQuarter && eQuarter && sYear && eYear) {
      const startMonth = new Date(
        sYear,
        monthNames.indexOf(sQuarter.split("-")[0])
      );
      const endMonth = new Date(
        eYear,
        monthNames.indexOf(eQuarter.split("-")[1]) + 1,
        0
      );

      setStartDate(startMonth);
      setEndDate(endMonth);
    }
  };

  function getMonthsBetweenDates(startDate, endDate) {
    if (filterType !== "quarter") {
      const months = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        months.push(
          `${currentDate.getMonth() + 1} ${currentDate.getFullYear()}`
        );
        currentDate.setMonth(currentDate.getMonth() + 1);
      }
      return months;
    } else {
      const months = [];
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const month = currentDate.getMonth();
        if (month >= 0 && month < 3) {
          months.push(`0-2 ${currentDate.getFullYear()}`);
        }
        if (month >= 3 && month < 6) {
          months.push(`3-5 ${currentDate.getFullYear()}`);
        }
        if (month >= 6 && month < 9) {
          months.push(`6-8 ${currentDate.getFullYear()}`);
        }
        if (month >= 9) {
          months.push(`9-11 ${currentDate.getFullYear()}`);
        }

        currentDate.setMonth(currentDate.getMonth() + 3);
      }
      return months;
    }
  }

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    (async () => {
      const { result, error } = await getPatientGeneralReports({
        startDate,
        endDate,
      });
      if (error) {
        toastify(error.message, "error");
        return;
      }
      const months = getMonthsBetweenDates(startDate, endDate);
      const commonCondition = (curDate, year, startMonth, endMonth) => {
        if (
          filterType !== "quarter" &&
          new Date(curDate).getMonth() + 1 == startMonth &&
          new Date(curDate).getFullYear() == year
        ) {
          return true;
        } else if (
          filterType == "quarter" &&
          new Date(curDate).getMonth() >= startMonth &&
          new Date(curDate).getMonth() <= endMonth &&
          new Date(curDate).getFullYear() == year
        ) {
          return true;
        } else {
          return false;
        }
      };

      let tempGeneralDataArr = [];
      months.forEach((mon) => {
        let startMonth;
        let endMonth;
        let year;
        if (filterType !== "quarter") {
          startMonth = mon.split(" ")[0];
          year = mon.split(" ")[1];
        } else {
          startMonth = mon.split("-")[0];
          year = mon.split(" ")[1];
          endMonth = mon.split(" ")[0].split("-")[1];
        }

        const allPatients = result?.data.allPatients.filter(
          (ap) =>
            commonCondition(ap.created_at, year, startMonth, endMonth) &&
            new Date(ap.created_at).getFullYear() == year
        ).length;
        const allKols = result?.data.allPatients.filter(
          (ap) =>
            commonCondition(ap.created_at, year, startMonth, endMonth) &&
            new Date(ap.created_at).getFullYear() == year &&
            ap?.patientProfile?.kol[0]?._id
        ).length;
        const allReferrals = result?.data.allPatients.filter(
          (ap) =>
            commonCondition(ap.created_at, year, startMonth, endMonth) &&
            new Date(ap.created_at).getFullYear() == year &&
            ap?.patientProfile?.recommendFrom[0]?._id
        ).length;

        const recurringPatients = [
          ...new Set(
            removeDuplicatesFromArray(
              result?.data?.patientsWithBilling
                ?.filter(
                  (o) =>
                    commonCondition(o.created_at, year, startMonth, endMonth) &&
                    new Date(o.created_at).getFullYear() == year
                )
                ?.map((o) => o._id)
            )
          ),
        ].length;
        const existingPatients = result?.data?.patientsWithoutBilling?.filter(
          (ap) =>
            commonCondition(ap.created_at, year, startMonth, endMonth) &&
            new Date(ap.created_at).getFullYear() == year
        )?.length;
        const label =
          filterType !== "quarter"
            ? `${monthNames[startMonth - 1]} ${year}`
            : `${shortMonthNames[startMonth]}-${shortMonthNames[endMonth]} ${year}`;
        tempGeneralDataArr.push({
          label: label,
          month: mon,
          "New Patients": allPatients,
          Referrals: allReferrals,
          "Medial / KOL": allKols,
          "Recurring Patients": recurringPatients,
          "Existing Patients": existingPatients,
        });
      });

      const tempPatientGrowthChart = [];
      months.forEach((mon) => {
        const currentDataIndex = tempGeneralDataArr.findIndex(
          (o) => o.month == mon
        );
        if (currentDataIndex !== -1) {
          const count = tempGeneralDataArr[currentDataIndex]["New Patients"];
          const previousCount = tempGeneralDataArr[currentDataIndex - 1];
          let label =
            filterType !== "quarter"
              ? `${monthNames[mon.split(" ")[0] - 1]} ${mon.split(" ")[1]}`
              : `${shortMonthNames[mon.split("-")[0]]}-${
                  shortMonthNames[mon.split(" ")[0].split("-")[1]]
                } ${mon.split(" ")[1]}`;
          if (previousCount) {
            tempPatientGrowthChart.push({
              label: label,
              "Total Patient": count,
              Increased:
                count - previousCount["New Patients"] >= 0
                  ? count - previousCount["New Patients"]
                  : 0,
            });
          } else {
            tempPatientGrowthChart.push({
              label,
              "Total Patient": count,
              Increased: 0,
            });
          }
        }

        // let currentMonth;
        // let currentYear;
        // let currentMonthEnd; //In case of filter type quarter we have months range
        // if (filterType !== "quarter") {
        // 	currentMonth = mon.split(" ")[0];
        // 	currentYear = mon.split(" ")[1];
        // }
        // else{
        // 	currentMonth = mon.split("-")[0];
        // 	currentYear = mon.split(" ")[1];
        // 	currentMonthEnd = mon.split(" ")[0].split("-")[1];
        // }
        // const nextCount = tempGeneralDataArr.find((o) => {
        // 	if (currentMonth == 0) {
        // 		return o.month == 12;
        // 	} else {
        // 		return o.month == mon.split(" ")[1] - 1;
        // 	}
        // });
      });

      setAllData(result?.data);
      setGeneralGraphData([...tempGeneralDataArr]);
      setPatientGrowthChart([...tempPatientGrowthChart]);

      //////////////Pie charts\\\\\\\\\\\\\\\
      let maleCount = 0;
      let femaleCount = 0;

      let marriedCount = 0;
      let singleCount = 0;

      let count20s = 0;
      let count30s = 0;
      let count40s = 0;
      let count50s = 0;
      let count60s = 0;

      let tempLocationsArr = [];
      result?.data?.allPatients?.forEach((o) => {
        const age = getAgeFromDob(o?.patientProfile?.dob);
        if (o?.patientProfile?.gender == "Male") maleCount++;
        if (o?.patientProfile?.gender == "Female") femaleCount++;
        if (o?.patientProfile?.maritalStatus == "Single") singleCount++;
        if (o?.patientProfile?.maritalStatus == "Married") marriedCount++;
        if (age >= 20 && age <= 29) count20s++;
        if (age >= 30 && age <= 39) count30s++;
        if (age >= 40 && age <= 49) count40s++;
        if (age >= 50 && age <= 59) count50s++;
        if (age >= 60 && age <= 69) count60s++;

        if (o.branch[0]?._id) {
          const index = tempLocationsArr.findIndex(
            (loc) => loc.label == o.branch[0].name
          );
          if (index == -1) {
            tempLocationsArr.push({
              label: o.branch[0].name,
              id: o.branch[0].name,
              value: 1,
            });
          } else {
            tempLocationsArr[index] = {
              ...tempLocationsArr[index],
              value: tempLocationsArr[index].value + 1,
            };
          }
        }
      });
      setGenderChart([
        {
          id: "Men",
          label: "Men",
          value: maleCount,
        },
        {
          id: "Women",
          label: "Women",
          value: femaleCount,
        },
      ]);

      setMaritalChart([
        {
          id: "Married",
          label: "Married",
          value: marriedCount,
        },
        {
          id: "Single",
          label: "Single",
          value: singleCount,
        },
      ]);

      setAgeChart([
        {
          id: "20s",
          label: "20s",
          value: count20s,
        },
        {
          id: "30s",
          label: "30s",
          value: count30s,
        },
        {
          id: "40s",
          label: "40s",
          value: count40s,
        },
        {
          id: "50s",
          label: "50s",
          value: count50s,
        },
        {
          id: "60s",
          label: "60s",
          value: count60s,
        },
      ]);

      setLocationChart([...tempLocationsArr]);
    })();
  }, [startDate, endDate]);

  const columns = [
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
      // width: "160px",
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      wrap: true,
      // width: "160px",
    },
    // {
    // 	name: "Invoice No.",
    // 	selector: (row) => row.invoiceId,
    // 	wrap: true,
    // 	// width: "160px",
    // },
    {
      name: "Description/Remarks",
      selector: (row) => row.remarks,
      wrap: true,
      width: "200px",
    },
    {
      name: "Source/Recommended By",
      selector: (row) => row.recommendedBy,
      wrap: true,
      width: "230px",
    },
    {
      name: "How Did You Hear About Us",
      selector: (row) => row.hearAboutUs,
      wrap: true,
      width: "240px",
    },
  ];

  const columnRecurrings = [
    {
      name: "Date",
      selector: (row) => row.date,
      wrap: true,
      // width: "160px",
    },
    {
      name: "Patient Name",
      selector: (row) => row.patientName,
      wrap: true,
      // width: "160px",
    },
    {
      name: "Invoice No.",
      selector: (row) => row.invoiceId,
      wrap: true,
      // width: "160px",
    },
    {
      name: "Description/Remarks",
      selector: (row) => row.remarks,
      wrap: true,
      width: "200px",
    },
    {
      name: "Source/Recommended By",
      selector: (row) => row.recommendedBy,
      wrap: true,
      width: "230px",
    },
  ];

  const getSum = (arr, name) => {
    let sum = 0;
    arr.forEach((o) => {
      sum += o[name];
    });
    return sum;
  };
  const patientWithBillingData = allData?.patientsWithBilling?.map((o, i) => {
    return {
      index: i,
      date: new Date(o.created_at).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }),
      patientName: o?.patientProfile?.fullName,
      invoiceId: o?.paymentData?.invoiceNo
        ? getInvoiceId(o?.paymentData?.invoiceNo)
        : "-",
      remarks:
        o?.paymentData?.paymentRecord?.length > 0
          ? o?.paymentData?.paymentRecord[0]?.remarks
          : "-",
      recommendedBy:
        o?.patientProfile?.recommendFrom[0]?.patientProfile?.fullName ?? "-",
      hearAboutUs: o?.patientProfile?.hearAboutUs[0]?.name ?? "-",
    };
  });

  const patientWithoutBillingData = allData?.patientsWithoutBilling?.map(
    (o, i) => {
      return {
        index: i,
        date: new Date(o.created_at).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
        patientName: o?.patientProfile?.fullName,
        invoiceId: "-",
        remarks: "-",
        recommendedBy:
          o?.patientProfile?.recommendFrom[0]?.patientProfile?.fullName ?? "-",
        hearAboutUs: o?.patientProfile?.hearAboutUs[0]?.name ?? "-",
      };
    }
  );

  const mediaData = allData?.allPatients
    ?.filter((o) => o?.patientProfile?.kol[0]?._id)
    ?.map((o, i) => {
      return {
        index: i,

        date: new Date(o.created_at).toLocaleDateString("en-GB", {
          day: "numeric",
          month: "numeric",
          year: "numeric",
        }),
        patientName: o?.patientProfile?.fullName,
        invoiceId: o?.invoiceId ? getInvoiceId(o?.invoiceId) : "-",

        invoiceId: (
          <>
            {o.paymentData?.length > 0 ? (
              o.paymentData?.map((k) => {
                return (
                  <div>{o?.invoiceNo ? getInvoiceId(o?.invoiceNo) : "-"}</div>
                );
              })
            ) : (
              <>{"-"}</>
            )}
          </>
        ),
        remarks: (
          <>
            {o.paymentData?.length > 0 ? (
              o.paymentData?.map((k) => {
                return <div>{k.remarks}</div>;
              })
            ) : (
              <>{"-"}</>
            )}
          </>
        ),
        recommendedBy:
          o?.patientProfile?.recommendFrom[0]?.patientProfile?.fullName ?? "-",
        hearAboutUs: o?.patientProfile?.hearAboutUs[0]?.name ?? "-",
      };
    });
  const allPatientsData = allData?.allPatients?.map((o, i) => {
    return {
      index: i,
      date: new Date(o.created_at).toLocaleDateString("en-GB", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      }),
      patientName: o?.patientProfile?.fullName,
      invoiceId: (
        <>
          {o.paymentData?.length > 0 ? (
            o.paymentData?.map((k) => {
              return (
                <div>{k?.invoiceNo ? getInvoiceId(k?.invoiceNo) : "-"}</div>
              );
            })
          ) : (
            <>{"-"}</>
          )}
        </>
      ),
      remarks: (
        <>
          {o.paymentData?.length > 0 ? (
            o.paymentData?.map((k) => {
              return <div>{k.remarks}</div>;
            })
          ) : (
            <>{"-"}</>
          )}
        </>
      ),
      recommendedBy:
        o?.patientProfile?.recommendFrom[0]?.patientProfile?.fullName ?? "-",
      hearAboutUs: o?.patientProfile?.hearAboutUs[0]?.name ?? "-",
    };
  });
  const customStyles = {
    headCells: {
      style: {
        // justifyContent: "center",
      },
    },
    cells: {
      style: {
        // justifyContent: "center",
        // textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];
  return (
    <>
      <div className="d-flex flex-wrap" style={{ width: "calc(100% - 130px)" }}>
        {/* <div className="col-3 me-2">
						<Form.Group>
							<div className="font-400-20 mb-1">
								Filter Selection
							</div>
							<SelectComponent
								options={filterTypes}
								selectedValue={filterType}
								singleSelect
								searchEnabled
								nullDisabled
								onChange={(e) => {
									setFilterType(e.value);
								}}
							/>
						</Form.Group>
					</div> */}
        {filterType == "date" && (
          <>
            <div className="col-3 pe-2">
              <div className="font-400-20 mb-1">Start Date:</div>
              <DateInput
                value={range.start}
                placeholder="Start Date"
                onChange={(e) => {
                  if (e > range.end) {
                    toastify(
                      "Start date can't be greater than end date",
                      "error"
                    );
                    return;
                  }
                  setRange({ ...range, start: e });
                  // setStartDate(e);
                }}
              />
            </div>
            <div className="col-3 pe-2">
              <div className="font-400-20 mb-1">End Date:</div>
              <DateInput
                placeholder="End Date"
                value={range.end}
                onChange={(e) => {
                  if (e < range.start) {
                    toastify("End date can't be less than start date", "error");
                    return;
                  }
                  setRange({ ...range, end: e });
                  // setEndDate(e);
                }}
              />
            </div>
            <Button
              className="addItem mb-3 mx-2 align-self-end"
              style={{ height: "47px" }}
              onClick={() => {
                onApply(range.start, range.end);
              }}
            >
              Apply
            </Button>
          </>
        )}
        {filterType == "month" && (
          <>
            <div className="col-3 pe-2">
              <div className="font-400-20 mb-1">Start Month:</div>
              <DateInput
                placeholder="Start Month"
                views={["month", "year"]}
                value={months.start}
                onChange={(e) => {
                  // setStartDate(new Date());
                  setMonths({
                    ...months,
                    start: new Date(e.getFullYear(), e.getMonth()),
                  });
                }}
              />
            </div>
            <div className="col-3 pe-2">
              <div className="font-400-20 mb-1">End Month:</div>
              <DateInput
                placeholder="Start Month"
                views={["month", "year"]}
                value={months.end}
                onChange={(e) => {
                  if (months.start > e) {
                    toastify(
                      "End date cann't be less than start date",
                      "error"
                    );
                    return;
                  }

                  const date = new Date(e.getFullYear(), e.getMonth());
                  date.setMonth(date.getMonth() + 1);
                  date.setDate(0);
                  setMonths({
                    ...months,
                    end: date,
                  });
                  // setEndDate(date);
                }}
              />
            </div>
            <Button
              className="addItem mb-3 mx-2 align-self-end"
              style={{ height: "47px" }}
              onClick={() => {
                onApply(months.start, months.end);
              }}
            >
              Apply
            </Button>
          </>
        )}
        {filterType == "quarter" && (
          <>
            <div className="col-3">
              <div className="font-400-20 mb-1">Start Year:</div>
              <SelectComponent
                options={yearsOptions}
                selectedValue={quarterStartYear}
                singleSelect
                searchEnabled
                onChange={(e) => {
                  if (parseInt(e.value) > parseInt(quarterEndYear)) {
                    toastify(
                      "Start Year can't be greater than end year",
                      "error"
                    );
                    return;
                  }
                  setQuarterStartYear(e.value);
                }}
              />
            </div>
            <Form.Group className="col-3 mx-2">
              <div className="font-400-20 mb-1">Start Quarter</div>
              <SelectComponent
                options={quaterOptions}
                selectedValue={selectedQuaterStart}
                singleSelect
                searchEnabled
                onChange={(e) => {
                  setSelectedQuaterStart(e.value);
                }}
              />
            </Form.Group>
            <div className="col-3">
              <div className="font-400-20 mb-1">End Year:</div>
              <SelectComponent
                options={yearsOptions}
                selectedValue={quarterEndYear}
                singleSelect
                searchEnabled
                onChange={(e) => {
                  if (parseInt(e.value) < parseInt(quarterStartYear)) {
                    toastify("End Year can't be less than start year", "error");
                    return;
                  }
                  setQuarterEndYear(e.value);
                }}
              />
            </div>
            <Form.Group className="col-3 ms-2">
              <div className="font-400-20 mb-1">End Quarter</div>
              <SelectComponent
                options={quaterOptions}
                selectedValue={selectedQuarterEnd}
                singleSelect
                searchEnabled
                onChange={(e) => {
                  setSelectedQuaterEnd(e.value);
                }}
              />
            </Form.Group>
            <Button
              className="addItem mb-3 mx-2 align-self-end"
              style={{ height: "47px" }}
              onClick={() => {
                onQuarterApply();
              }}
            >
              Apply
            </Button>
          </>
        )}
        <Button
          className="addItem mb-3 align-self-end"
          style={{ height: "47px" }}
          onClick={() => {
            let tempDataArr = allData?.allPatients?.map((e) => {
              return {
                "Patient Name": e?.patientProfile?.fullName,
                Invoices: e.paymentData
                  ?.map((k) => getInvoiceId(k?.invoiceNo))
                  ?.filter((k) => k)
                  ?.join(","),
                "Total Amount": getSum(e?.paymentData, "totalAmount"),
                Remarks: e?.paymentData
                  ?.map((k) => k.remarks)
                  ?.filter((k) => k)
                  ?.join(","),
                recommendedBy:
                  e?.patientProfile?.recommendFrom[0]?.patientProfile
                    ?.fullName ?? "-",
                hearAboutUs: e?.patientProfile?.hearAboutUs[0]?.name ?? "-",
                [`Date : ${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`]:
                  "",
              };
            });
            exportData(tempDataArr, [], "Patient_report");
          }}
        >
          Export
        </Button>
      </div>

      <div className="mt-4 p-3" style={{ background: "white" }}>
        <div className="font-400-20">
          <b>Client Growth</b>
        </div>
        <BarGraph
          data={generalGraphData}
          groupMode={"grouped"}
          xLegend={"Count"}
          yLegend={"Months"}
          enableGridY={true}
          // isDate
          enableGridX={true}
          layout="horizontal"
          keys={[
            "New Patients",
            "Referrals",
            "Medial / KOL",
            "Recurring Patients",
            "Existing Patients",
          ]}
          indexBy="label"
        />
      </div>
      <div className="mt-4 p-3" style={{ background: "white" }}>
        <div className="font-400-20">
          <b>Patient Growth Chart</b>
        </div>
        <BarGraph
          data={patientGrowthChart}
          groupMode={"grouped"}
          xLegend={"Months"}
          yLegend={"Count"}
          enableGridY={true}
          enableGridX={false}
          keys={["Total Patient", "Increased"]}
          indexBy="label"
        />
      </div>
      <div className="mt-4" style={{ background: "white" }}>
        <div className="font-400-20 p-3">
          <b>New Patients</b>
        </div>
        <DataTable
          pagination
          columns={columns}
          data={allPatientsData}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
        />
      </div>
      <div className="mt-4" style={{ background: "white" }}>
        <div className="font-400-20 p-3 ">
          <b>Recurring Patients With Billing</b>
        </div>
        <DataTable
          pagination
          columns={columnRecurrings}
          data={patientWithBillingData}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
        />
      </div>
      <div className="mt-4" style={{ background: "white" }}>
        <div className="font-400-20 p-3 ">
          <b>Existing Patient (Without Billing)</b>
        </div>
        <DataTable
          pagination
          columns={columnRecurrings}
          data={patientWithoutBillingData}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
        />
      </div>
      <div className="mt-4" style={{ background: "white" }}>
        <div className="font-400-20 p-3">
          <b>Media / KolChannel</b>
        </div>
        <DataTable
          pagination
          columns={columns}
          data={mediaData}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={customStyles}
        />
      </div>
      <div
        className="mt-4 d-flex"
        style={{ background: "white", overflowX: "auto" }}
      >
        <div>
          <div className="font-400-20 p-3 ">
            <b>Patient Gender</b>
          </div>
          <PieChart data={genderChart} />
        </div>
        <div>
          <div className="font-400-20 ms-5 p-3">
            <b>Patient Marital Status</b>
          </div>
          <PieChart data={maritalChart} />
        </div>
      </div>
      {/* <div className="mt-4" style={{ background: "white" }}>
					<div className="font-400-20 p-3 ">
						<b>Patient Marital Status</b>
					</div>
					<PieChart data={maritalChart} />
				</div> */}
      <div className="mt-4" style={{ background: "white" }}>
        <div className="font-400-20 p-3 ">
          <b>Patient Location</b>
        </div>
        <PieChart data={locationChart} />
      </div>
    </>
  );
}
