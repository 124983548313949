import React, { useState, useEffect } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import {
	createMembershipCategory,
	getMembershipCategories,
	getMembershipSettings,
	updateMembershipCategory,
	updateMembershipSettings,
} from "../../../apis/membership";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import Modal from "react-bootstrap/Modal";
import DeleteModal from "../../SharedComponents/DeleteModal";

function MembershipSettings() {
	const [categories, setCategories] = useState([]);
	const [points, setPoints] = useState("");
	const [conversion, setConversion] = useState("");
	const [memberExpiry, setMemberExpiry] = useState("");
	const [nonMemberExpiry, setNonMemberExpiry] = useState("");
	const [startCondition, setStartCondition] = useState("");
	const [endCondition, setEndCondition] = useState("");
	const [title, setTitle] = useState("");
	const [isUpdating, setIsUpdating] = useState();
	const [modalShow, setModalShow] = useState(false);
	const [settingsId, setSettingsId] = useState("");
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");
	const [isDouble, setIsDouble] = useState(false);
	const [rewardAmount, setRewardAmount] = useState(0);
	const [rewardPoint, setRewardPoint] = useState(0);
	const [expiredPatient, setExpiredPatient] = useState(0);

	const numberRegex = new RegExp(`^[0-9]*$`);

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getMembershipDetails = async () => {
		const response = await Promise.all([
			getMembershipCategories(),
			getMembershipSettings(),
		]);
		if (response[1]) {
			setPoints(response[1][0]?.point);
			setConversion(response[1][0]?.conversion);
			setRewardAmount(response[1][0]?.rewardAmount);
			setRewardPoint(response[1][0]?.rewardPoint);
			setExpiredPatient(response[1][0]?.expiredPatient);
			setMemberExpiry(response[1][0]?.expForMember);
			setNonMemberExpiry(response[1][0]?.expForNonMember);
			setSettingsId(response[1][0]?._id);
			setIsDouble(response[1][0]?.isDouble ?? false);
			console.log({ ID: response[1][0]?._id });
		}

		if (response[0]?.status !== "Fail") {
			setCategories(response[0]);
		} else {
			if (response[0]?.message === "No data Found") {
				setCategories([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const save = async () => {
		if (!settingsId || settingsId === "" || settingsId === undefined) {
			toastify("Please add Membership settings first.", "error");
		} else {
			if (!startCondition) {
				missingPopup("Start Condition");
			} else if (!endCondition) {
				missingPopup("End Condition");
			} else if (!title) {
				missingPopup("Title");
			} else {
				let data = {
					name: title,
					startFrom: startCondition,
					endTo: endCondition,
				};
				if (isUpdating) {
					console.log("UPDATE");
					const response = await updateMembershipCategory(
						isUpdating,
						data,
					);
					setIsUpdating("");
					if (response?.status !== "Fail") {
						setModalShow(false);
						getMembershipDetails();
						toastify(
							"Membership Category updated successfully!",
							"success",
						);
						setTitle("");
						setStartCondition("");
						setEndCondition("");
					} else {
						toastify(response?.message, "error");
					}
				} else {
					setIsUpdating("");
					console.log("CREATING");

					const response = await createMembershipCategory(data);
					if (response?.status !== "Fail") {
						setModalShow(false);
						getMembershipDetails();
						toastify("Category added successfully!", "success");
						setTitle("");
						setStartCondition("");
						setEndCondition("");
					} else {
						toastify(response?.message, "error");
					}
				}
			}
		}
	};

	const removeCategory = async () => {
		const response = await updateMembershipCategory(deleting, {
			isDeleted: true,
		});
		setShow(false);
		if (response?.status !== "Fail") {
			toastify("Membership Category deleted successfully!", "success");
			getMembershipDetails();
			// setIsUpdating();
		} else {
			toastify(response?.message, "error");
		}
	};

	const editCategory = async (catData) => {
		setModalShow(true);
		setTitle(catData?.name);
		setStartCondition(catData?.startFrom);
		setEndCondition(catData?.endTo);
		setIsUpdating(catData?._id);
	};

	const onHide = () => {
		setModalShow(false);
		setTitle("");
		setStartCondition("");
		setEndCondition("");
		setIsUpdating("");
	};

	const setData = (data) => {
		// setIsUpdating(data._id);
		setPoints(data.point);
		setMemberExpiry(data.expForMember);
		setNonMemberExpiry(data.expForNonMember);
		setTitle(data.title);
		setStartCondition(data.startPoints);
		setEndCondition(data.endPoint);
	};

	const saveSettings = async () => {
		if (!points) {
			missingPopup("Points");
		} else if (!conversion) {
			missingPopup("Conversion");
		} else if (!memberExpiry) {
			missingPopup("Expired for Members");
		} else if (!nonMemberExpiry) {
			missingPopup("Expired for Non Members");
		} else {
			let data = {
				point: points,
				conversion: Number(conversion),
				expForMember: memberExpiry,
				expForNonMember: nonMemberExpiry,
				rewardAmount: rewardAmount,
				rewardPoint: rewardPoint,
				expiredPatient: Number(expiredPatient),
				isDouble,
			};
			const response = await updateMembershipSettings(settingsId, data);
			if (response?.status !== "Fail") {
				toastify(
					"Membership Settings updated successfully!",
					"success",
				);
				getMembershipDetails();
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	useEffect(() => {
		getMembershipDetails();
	}, []);

	const columns = [
		{
			name: "Name",
			selector: (row) => row.title,
		},
		{
			name: "Condition",
			selector: (row) => `${row.startPoints} - ${row.endPoints}`,
		},
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = categories?.map((e, index) => {
		return {
			id: e._id,
			index: index,
			title: e.name,
			startPoints: e.startFrom,
			endPoints: e.endTo,
			point: e.point,
			expForMember: e.expForMember,
			expForNonMember: e.expForNonMember,
			action: (
				<div className="d-flex">
					<div
						className="mx-3 cursor-pointer icon-width"
						onClick={() => editCategory(e)}
					>
						<i className="fa fa-pen"></i>
					</div>
					<div
						className="mx-3 cursor-pointer icon-width"
						onClick={() => {
							setDeleting(e._id);
							setShow(true);
						}}
					>
						<i className="fa fa-trash"></i>
					</div>
				</div>
			),
		};
	});

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
				textAlign: "center",
			},
		},
		tableWrapper: {
			style: {
				paddingLeft: 15,
				backgroundColor: "white",
			},
		},
	};

	return (
		<div className="container px-5" style={{ height: "100vh" }}>
			<div className="mainHeading">
				<h1>Membership Setting</h1>
			</div>

			<div className="white-background p-3">
				<div className="d-flex mb-4">
					<Form.Group className="col-md-5">
						<Form.Label className="membership-headings font-black ">
							Redeem Loyalty Points 
						</Form.Label>
						<div className="d-flex align-items-center">
						<InputGroup className="mb-3">
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									setPoints(e.target.value);
								}}
								value={points}
							/>
							<InputGroup.Text>Points</InputGroup.Text>
						</InputGroup>
						<span className="mb-3 mx-2">=</span>
						<InputGroup className="mb-3">
							<InputGroup.Text>$</InputGroup.Text>
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									setConversion(e.target.value);
								}}
								value={conversion}
							/>
						</InputGroup>
						</div>
					</Form.Group>

					<span className="col-md-1" />
					<Form.Group className="col-md-5">
						<Form.Label className="membership-headings font-black ">
							Expired for Member{" "}
							<span className="unbold">(Months)</span>
						</Form.Label>
						<InputGroup>
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									if (
										numberRegex.test(e.target.value) ||
										e.target.value === ""
									) {
										setMemberExpiry(e.target.value);
									}
								}}
								value={memberExpiry}
							/>
						</InputGroup>
					</Form.Group>
				</div>

				<div className="d-flex mb-4">
					<Form.Group className="col-md-5">
						<Form.Label className="membership-headings font-black ">
							Reward Point (Amount = Point)
						</Form.Label>
						<div className="d-flex align-items-center">
						<InputGroup className="mb-3">
							<InputGroup.Text>$</InputGroup.Text>
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									setRewardAmount(e.target.value);
								}}
								value={rewardAmount}
							/>
						</InputGroup>
						<span className="mb-3 mx-2">=</span>
						<InputGroup className="mb-3">
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									setRewardPoint(e.target.value);
								}}
								value={rewardPoint}
							/>
							<InputGroup.Text>Points</InputGroup.Text>
						</InputGroup>
						</div>
					</Form.Group>
					<span className="col-md-1" />

					<div className="d-flex my-3">
						<Form.Check
							className="my-3"
							type={"checkbox"}
							// label={"Double Point for Birthday"}
							size={"lg"}
							checked={isDouble}
							onChange={() => {
								setIsDouble(!isDouble);
							}}
						/>
						<Form.Label className="membership-headings font-black my-3 ms-3">
							Double Point for Birthday
						</Form.Label>
					</div>
				</div>

				{/* <div className="d-flex mb-4 align-items-end">
					<Form.Group className="col-md-5">
						<Form.Label className="membership-headings font-black ">
							Expired Patient
						</Form.Label>
						<InputGroup className="mb-3">
							<Form.Control
								placeholder="Write..."
								onChange={(e) => {
									if (
										numberRegex.test(e.target.value) ||
										e.target.value === ""
									) {
										setExpiredPatient(e.target.value);
									}
								}}
								value={expiredPatient}
							/>
						</InputGroup>
					</Form.Group>
					<span className="col-md-1" />
				</div> */}

				<div className="col-md-12">
					<Button
						className="addItem my-3 ms-auto me-5"
						onClick={saveSettings}
					>
						Save
					</Button>
				</div>
				<Modal centered show={modalShow} onHide={onHide}>
					<Modal.Header closeButton>
						<Modal.Title>Create Membership Category</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div>
							<Form.Group>
								<Form.Label className="membership-headings font-black ">
									Title
								</Form.Label>
								<InputGroup className="mb-3">
									<Form.Control
										placeholder="Write..."
										onChange={(e) =>
											setTitle(e.target.value)
										}
										value={title}
									/>
								</InputGroup>
							</Form.Group>

							<Form.Group className="mt-5">
								<Form.Label className="membership-headings font-black">
									Condition Became Member
								</Form.Label>
								<InputGroup>
									<Form.Control
										placeholder="Start Points"
										value={startCondition}
										onChange={(e) => {
											if (
												numberRegex.test(
													e.target.value,
												) ||
												e.target.value === ""
											) {
												setStartCondition(
													e.target.value,
												);
											}
										}}
									/>
									<Form.Control
										placeholder="End Points"
										value={endCondition}
										onChange={(e) => {
											if (
												numberRegex.test(
													e.target.value,
												) ||
												e.target.value === ""
											) {
												setEndCondition(e.target.value);
											}
										}}
									/>
								</InputGroup>
							</Form.Group>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="primary" onClick={save}>
							Save
						</Button>
					</Modal.Footer>
				</Modal>

				<DeleteModal
					content="Confirm Membership Category Deletion?"
					onYes={removeCategory}
					onNo={handleClose}
					show={show}
				/>
			</div>

			<div className="white-background d-flex p-3 justify-content-between pb-0">
				<p className="subheading">Membership Category</p>
				<Button
					className="addItem"
					onClick={() => {
						setModalShow(true);
					}}
				>
					+ Add New
				</Button>
			</div>

			<DataTable
				columns={columns}
				data={data || []}
				customStyles={customStyles}
				pagination
				conditionalRowStyles={conditionalRowStyles}
			/>
		</div>
	);
}

export default MembershipSettings;
