import api from "../axios/axios-config";

export const getInfluencers = async () => {
  try {
    let response = await api.get("influencer/listOfInfluencer");
    return response?.data?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const createInfluencer = async (data) => {
  try {
    let response = await api.post("influencer/createInfluencer", data);

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateInfluencer = async (dataId, data) => {
  try {
    let response = await api.post("influencer/updateInfluencer", {
      dataId,
      ...data,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};
