import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { getDotPhrases, updateDotPhrase } from "../../../apis/dotPhrases";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

function DotPhrases() {
	let navigate = useNavigate();
	const [phrases, setPhrases] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [show, setShow] = useState(false);
	const [deleting, setDeleting] = useState("");

	const handleClose = () => {
		setShow(false);
		setDeleting("");
	};

	const getDotPhrasesList = async () => {
		const response = await getDotPhrases();
		if (response?.status !== "Fail") {
			setPhrases(response);
		} else {
			if (response?.message === "No data Found") {
				setPhrases([]);
			} else {
				toastify(response?.message, "error");
			}
		}
	};

	const addPhrase = () => {
		navigate("/add-dot-phrase");
	};

	const editPhrase = (e) => {
		navigate("/add-dot-phrase", {
			state: {
				phrase: e,
			},
		});
	};

	const removePhrase = async () => {
		setShow(false);
		const response = await updateDotPhrase(deleting, { isDeleted: true });
		if (response) {
			getDotPhrasesList();
			toastify("Dot Phrase deleted successfully!", "success");
		} else {
			toastify(response?.message, "error");
		}
	};

	useEffect(() => {
		getDotPhrasesList();
	}, []);

	const columns = [
		{
			name: (
				<div className="d-flex align-items-center" id="ovly5">
					<span className="me-2">Dot(.) Phrase</span>
					{
						<FilterComponent
							type="search"
							array={phrases}
							setArray={setFilteredData}
							value="DotPhrases"
						/>
					}
				</div>
			),
			selector: (row) => row.title,
			sortable: true,
			sortFunction: (a, b) => customStringSort(a, b, "title"),
		},
		// {
		// 	name: (
		// 		<div className="d-flex align-items-center" id="ovly6">
		// 			<span className="me-2">Title</span>
		// 			{
		// 				<FilterComponent
		// 					type="search"
		// 					array={phrases}
		// 					setArray={setFilteredData}
		// 					value="DotTitle"
		// 				/>
		// 			}
		// 		</div>
		// 	),
		// 	selector: (row) => row.title,
		// 	wrap: true,
		// 	sortable: true,
		// 	sortFunction: (a, b) => customStringSort(a, b, "title"),
		// },
		// {
		//   name: "Description",
		//   selector: (row) => row.description,
		// },
		{
			name: "Action",
			selector: (row) => row.action,
		},
	];

	const data = (filteredData.length === 0 ? phrases : filteredData)?.map(
		(e, index) => {
			return {
				id: e._id,
				index: index,
				dot_phrases: e.dot_phrases,
				title: e.title,
				description: e.description,
				action: (
					<div className="d-flex">
						<div
							className="mx-3 cursor-pointer icon-width"
							onClick={() => {
								editPhrase(e);
							}}
						>
							<i className="fa fa-pen"></i>
						</div>
						<div
							className="mx-3 cursor-pointer icon-width"
							onClick={() => {
								setDeleting(e._id);
								setShow(true);
							}}
						>
							<i className="fa fa-trash"></i>
						</div>
					</div>
				),
			};
		},
	);

	const customStyles = {
		headCells: {
			style: {
				justifyContent: "center",
			},
		},
		cells: {
			style: {
				justifyContent: "center",
			},
		},
	};

	const conditionalRowStyles = [
		{
			when: (row) => row,
			style: {
				borderWidth: "0px",
			},
		},
		{
			when: (row) => row.index % 2 === 1,
			style: {
				backgroundColor: "#f8f8f8",
				borderWidth: "0px",
			},
		},
	];

	return (
		<div className="container p-0">
			<div className="mainHeading px-4">
				<h1>Dot Phrases</h1>
				<Button className="addItem" onClick={addPhrase}>
					+ Add New
				</Button>
			</div>

			<DeleteModal
				content="Confirm Dot Phrase Deletion?"
				onYes={removePhrase}
				onNo={handleClose}
				show={show}
			/>

			<DataTable
				columns={columns}
				data={data || []}
				pagination
				customStyles={customStyles}
				conditionalRowStyles={conditionalRowStyles}
			/>
		</div>
	);
}

export default DotPhrases;
