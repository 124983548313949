import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { createSupplier, updateSupplier } from "../../../apis/supplier";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import { SketchPicker } from "react-color";
import { Switch } from "antd";
import { createTags, updateTags } from "../../../apis/tags";

export default function AddEditTags() {
    const { state } = useLocation();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [color, setColor] = useState("#0092fe");
    const [selectedColor, setSelectedColor] = useState("#0092fe");
    const [show, setShow] = useState(false);
    const [status, setStatus] = useState(true);
    const [description, setDescription] = useState("");

    const populateData = async () => {
        setName(state?.tag?.name);
        setColor(state?.tag?.color);
        setDescription(state?.tag?.description);
        setStatus(state?.tag?.isActive);
    };

    const saveTag = async () => {
        if (!name) {
            missingPopup("Name");
        } else if (!color) {
            missingPopup("Color");
        } else {
            let data = { name, color, description, isActive: status };
            if (!state?.tag) {
                const response = await createTags(data);
                if (response?.status !== "Fail") {
                    toastify("Tag added successfully!", "success");
                    navigate("/tags");
                } else {
                    toastify(response?.message, "error");
                }
            } else {
                const response = await updateTags(state?.tag?._id, data);
                if (response?.status !== "Fail") {
                    toastify("Tag updated successfully!", "success");
                    navigate("/tags");
                } else {
                    toastify(response?.message, "error");
                }
            }
        }
    };

    useEffect(() => {
        if (state?.tag) populateData();
    }, []);

    return (
        <React.Fragment>
            <div className="mainHeading px-4">
                <h1>
                    <i
                        className="fa fa-arrow-left cursor-pointer"
                        onClick={() => navigate(-1)}
                    ></i>
                    {`${state?.tag ? "Edit " : "New "}`}
                    Tag
                </h1>
            </div>

            <Modal
                className="mt-5"
                centered
                show={show}
                size="sm"
                onHide={() => setShow(false)}
            >
                <div className="p-3 d-flex align-items-center justify-content-center flex-column">
                    <h5>Pick a Color</h5>
                    <div>
                        <SketchPicker
                            className="mt-2 mb-4"
                            color={color}
                            onChange={(e) => {
                                setColor(e.hex);
                            }}
                        />
                    </div>

                    <Button
                        className="select-button"
                        style={{ background: "#225e7a" }}
                        onClick={() => {
                            setShow(false);
                            setSelectedColor(color);
                        }}
                    >
                        Select
                    </Button>
                </div>
            </Modal>

            <div className="pb-4 px-4">
                <div className="content">
                    <div className="addEdit pb-4">
                        <div className="col-5">
                            <Form.Group>
                                <Form.Label className="headings2">
                                    Name *
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Write..."
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        value={name}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="headings2">
                                    Color *
                                </Form.Label>
                                <InputGroup className="mb-3 p-0">
                                    <div
                                        style={{
                                            background: selectedColor,
                                        }}
                                        className="color-div"
                                        onClick={() => setShow(true)}
                                    ></div>
                                    <Form.Control
                                        onClick={() => setShow(true)}
                                        placeholder="Write..."
                                        value={selectedColor}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="headings2">
                                    Description
                                </Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Write..."
                                        onChange={(e) =>
                                            setDescription(e.target.value)
                                        }
                                        value={description}
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="headings2 d-flex">
                                    Status
                                </Form.Label>
                                <Switch
                                    checked={status}
                                    onChange={(e) => setStatus(e)}
                                />
                            </Form.Group>
                        </div>
                    </div>

                    <div className="addEdit">
                        <Button className="addItem" onClick={saveTag}>
                            Save
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
