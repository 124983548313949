import api from "../axios/axios-config";
import { authorizedRequests } from "../axios/axios-config";

export const getAllQuestions = async () => {
  try {
    let response = await api.get("questionnaire/listOfQuestionnaire");

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addQuestionMultiple = async (
  question,
  questionType,
  indicators,
  isActive,
  checkboxOptions
) => {
  try {
    let response = await api.post("questionnaire/createQuestionnaire", {
      question,
      questionType,
      indicators,
      isActive,
      checkboxOptions,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const addQuestion = async (
  question,
  questionType,
  isActive,
  linkedTo,
  group,
  size,
  orderIndex,
  checkboxOptions
) => {
  try {
    let response = await api.post("questionnaire/createQuestionnaire", {
      question,
      questionType,
      isActive,
      linkedTo,
      group,
      size,
      orderIndex,
      checkboxOptions,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateQuestionMultiple = async (
  questionId,
  question,
  questionType,
  indicators,
  isActive,
  group,
  size,
  orderIndex,
  checkboxOptions
) => {
  try {
    let response = await api.post("questionnaire/updateQuestionnaire", {
      questionId,
      question,
      questionType,
      isActive,
      indicators,
      group,
      size,
      orderIndex,
      checkboxOptions,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const updateQuestion = async (
  questionId,
  question,
  questionType,
  isActive,
  linkedTo,
  group,
  size,
  orderIndex,
  checkboxOptions
) => {
  try {
    let response = await api.post("questionnaire/updateQuestionnaire", {
      questionId,
      question,
      questionType,
      isActive,
      linkedTo,
      group,
      size,
      orderIndex,
      checkboxOptions,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const deleteQuestion = async (questionId) => {
  try {
    let response = await api.post("questionnaire/updateQuestionnaire", {
      questionId,
      isDeleted: true,
    });

    return response?.data;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getQuestionnaireByPatientId = async (id) => {
  const response = await authorizedRequests({
    url: "usersQuestionnaire/getUserQuestionnaireByPatientId",
    method: "post",
    data: {
      patient: id,
    },
  });
  return response;
};
