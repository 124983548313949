import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authorizedRequests } from "../../../axios/axios-config";
import { toastify } from "../../../helperFunctions/toastify";
import { customStringSort } from "../../../helperFunctions/utils";
import { resetValue, setData } from "../../../redux/slices/adminSlices";
import DeleteModal from "../../SharedComponents/DeleteModal";
import FilterComponent from "../../SharedComponents/FilterComponent";

export default function CategoryReportView() {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);

  const [categories, setCategories] = useState([]);
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState("");

  const dispatch = useDispatch();

  const { reminderTemplateForm, allReminderTemplates } = useSelector(
    (state) => state.staffForm
  );

  const getList = async () => {
    const { result, error } = await authorizedRequests({
      url: "category/listOfCategory",
      method: "get",
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }

    if (result) {
      setCategories(result?.data);
    } else {
      if (!result?.status === "Success") {
        setCategories([]);
      } else {
        toastify(result?.message, "error");
      }
    }

    return;
  };

  const onRowEdit = async (e) => {
    const valueObj = {
      name: e.name,
      formStatus: "Edit",
      orderId: e.orderId,
      _id: e._id,
    };
    navigate("/add-category-report", { state: { valueObj } });
  };

  const handleClose = () => {
    setShow(false);
    setDeleting("");
  };

  const onRowDelete = async (id) => {
    // tempArr =
    setShow(false);
    const { result, error } = await authorizedRequests({
      url: "category/updateCategory",
      method: "post",
      data: {
        _id: deleting,
        isDeleted: true,
      },
    });
    if (error) {
      toastify(error.message ?? "unknown error occured", "error");
      return;
    }
    toastify("Reminder deleted successfully.", "success");
    getList();
  };

  useEffect(() => {
    getList();
  }, []);

  const columns = [
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Name</span>
          {
            <FilterComponent
              type="search"
              array={allReminderTemplates || []}
              setArray={setFilteredData}
              value="Name"
            />
          }
        </div>
      ),
      selector: (row) => row.name,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "name"),
    },
    {
      name: (
        <div className="d-flex align-items-center" id="ovly2">
          <span className="me-2">Order</span>
          {
            <FilterComponent
              type="search"
              array={allReminderTemplates}
              setArray={setFilteredData}
              value="via"
            />
          }
        </div>
      ),
      selector: (row) => row.order,
      wrap: true,
      sortable: true,
      sortFunction: (a, b) => customStringSort(a, b, "Order"),
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];
  const data = (filteredData.length === 0 ? categories : filteredData)
    ?.map((e, index) => {
      return {
        index: index,
        name: e.name,
        order: e.orderId,
        action: (
          <div className="d-flex">
            <button
              className="mx-3  cursor-pointer icon-width cleanbtn"
              onClick={() => {
                onRowEdit(e);
              }}
              disabled={
                e.name === "Human Resource" ||
                e.name === "HR" ||
                e.name === "Inventory" ||
                e.name === "Finance"
              }
            >
              <i className="fa fa-pen"></i>
            </button>
            <button
              className="mx-3  cursor-pointer icon-width cleanbtn"
              onClick={() => {
                setDeleting(e._id);
                setShow(true);
              }}
              disabled={
                e.name === "Human Resource" ||
                e.name === "HR" ||
                e.name === "Inventory" ||
                e.name === "Finance"
              }
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
        ),
      };
    })
    .reverse();

  const customStyles = {
    headCells: {
      style: {
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        justifyContent: "center",
        textAlign: "center",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row,
      style: {
        borderWidth: "0px",
      },
    },
    {
      when: (row) => row.index % 2 === 1,
      style: {
        backgroundColor: "#f8f8f8",
        borderWidth: "0px",
      },
    },
  ];

  return (
    <>
      <div className="container">
        <div className="mainHeading px-4">
          <h1>Category Report</h1>
          <Button
            className="addItem"
            onClick={() => {
              dispatch(resetValue("reminderTemplateForm"));
              navigate("/add-category-report");
            }}
          >
            + Add New
          </Button>
        </div>

        <DeleteModal
          content="Confirm Reminder Template Deletion?"
          onYes={onRowDelete}
          onNo={handleClose}
          show={show}
        />

        <DataTable
          columns={columns}
          data={data || []}
          pagination
          customStyles={customStyles}
          conditionalRowStyles={conditionalRowStyles}
          responsive="true"
        />
      </div>
    </>
  );
}
