import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { addKolOption, updateKolOption } from "../../../apis/optionKol";
import { addTaxSettings, updateTaxSettings } from "../../../apis/tax";
import { missingPopup } from "../../../helperFunctions/missingFieldToast";
import { toastify } from "../../../helperFunctions/toastify";
import SelectComponent from "../../SharedComponents/SelectComponent";

export default function AddEditTaxSettings() {
	const { state } = useLocation();
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [typeValue, setTypeValue] = useState();
	const [value, setValue] = useState("");
	const [type, setType] = useState();
	const numberRegex = new RegExp(`^[0-9]*$`);

	const populateData = async () => {
		setName(state?.tax?.name);
		setTypeValue(state?.tax?.typeValue);
		setValue(state?.tax?.value);
		setType(state?.tax?.taxType);
	};

	const saveTax = async () => {
		if (!name) missingPopup("Name");
		else if (!typeValue) missingPopup("Type Value");
		else if (!value) missingPopup("Value");
		else if (!type) missingPopup("Type");
		else {
			let data = { name, typeValue, value, taxType: type };

			if (state?.tax) {
				const response = await updateTaxSettings(state?.tax?._id, data);
				if (response?.status !== "Fail") {
					navigate("/tax-setting");
					toastify("Tax Setting updated successfully!", "success");
				} else {
					toastify(response?.message, "error");
				}
			} else {
				const response = await addTaxSettings(data);
				if (response?.status !== "Fail") {
					navigate("/tax-setting");
					toastify("Tax Setting added successfully!", "success");
				} else {
					toastify(response?.message, "error");
				}
			}
		}
	};

	useEffect(() => {
		if (state?.tax) populateData();
	}, []);

	return (
		<React.Fragment>
			<div className="mainHeading px-4">
				<h1>
					<i
						className="fa fa-arrow-left cursor-pointer"
						onClick={() => navigate(-1)}
					></i>
					{`${state?.tax ? "Edit " : "New "}`}
					Tax Setting
				</h1>
			</div>
			<div className="pb-4 px-4">
				<div className="content">
					<div className="addEdit pb-4">
						<div className="row">
							<div className="col-md-6">
								<Form.Group>
									<Form.Label className="headings2">
										Name *
									</Form.Label>
									<InputGroup>
										<Form.Control
											placeholder="Write..."
											onChange={(e) =>
												setName(e.target.value)
											}
											value={name}
										/>
									</InputGroup>
								</Form.Group>

								<Form.Group className="mt-3">
									<Form.Label className="headings2">
										Type Value *
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										options={[
											{
												label: "Exact Value",
												value: "Exact Value",
											},
											{
												label: "Percentage",
												value: "Percentage",
											},
										]}
										onChange={(e) => {
											let val = e.value;
											if (val === "") {
												setTypeValue();
											} else {
												setTypeValue(e.value);
											}
										}}
										selectedValue={typeValue}
									/>
									{/* <select
                    className="form-select mb-3"
                    onChange={(e) => {
                      let val = e.target.value;
                      if (val === "") {
                        setTypeValue();
                      } else {
                        setTypeValue(e.target.value);
                      }
                    }}
                  >
                    <option value={""} selected disabled>
                      Select (exact value, percentage)
                    </option>
                    <option
                      selected={typeValue === "Exact Value"}
                      value={"Exact Value"}
                    >
                      Exact Value
                    </option>
                    <option
                      selected={typeValue === "Percentage"}
                      value={"Percentage"}
                    >
                      Percentage
                    </option>
                  </select> */}
								</Form.Group>

								<Form.Group className="mt-3">
									<Form.Label className="headings2">
										Value *
									</Form.Label>
									<InputGroup>
										<Form.Control
											placeholder="Write..."
											onChange={(e) => {
												setValue(e.target.value);
											}}
											value={value}
										/>
									</InputGroup>
								</Form.Group>

								<Form.Group className="mt-3">
									<Form.Label className="headings2">
										Type *
									</Form.Label>
									<SelectComponent
										singleSelect
										searchEnabled
										options={[
											{
												label: "Billing",
												value: "Billing",
											},
											{
												label: "Purchase",
												value: "Purchase",
											},
											{
												label: "Billing & Purchase",
												value: "Billing & Purchase",
											},
										]}
										onChange={(e) => {
											let val = e.value;
											if (val === "") {
												setType();
											} else {
												setType(e.value);
											}
										}}
										selectedValue={type}
									/>
									{/* <select
										className="form-select mb-3"
										onChange={(e) => {
											let val = e.target.value;
											if (val === "") {
												setType();
											} else {
												setType(e.target.value);
											}
										}}
									>
										<option value={""} selected disabled>
											Select (Billing, Purchase)
										</option>
										<option
											selected={type === "Billing"}
											value={"Billing"}
										>
											Billing
										</option>
										<option
											selected={type === "Purchase"}
											value={"Purchase"}
										>
											Purchase
										</option>
									</select> */}
								</Form.Group>
							</div>
						</div>
					</div>

					<div className="addEdit">
						<Button className="addItem" onClick={saveTax}>
							Save
						</Button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}
